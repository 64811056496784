import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Decimal: { input: string; output: string };
  Timestamp: { input: number; output: number };
  Upload: { input: any; output: any };
};

export type AcceptBuyoutInput = {
  buyoutId: Scalars["ID"]["input"];
  version: Scalars["Int"]["input"];
};

export type AcceptQuoteInput = {
  /** Hand-pick each auxiliary quote item to accept */
  auxiliaryQuoteItemIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Create a buyout for the quote, upon acceptance. */
  createBuyout?: Scalars["Boolean"]["input"];
  /** Notify other vendors about their quote not being accepted. */
  notifyOtherVendors?: Scalars["Boolean"]["input"];
  /** Accepting a quote by ID is not supported if any item has multiple options. */
  quoteId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Hand-pick each quote item to accept. This allows selecting items from
   * competing sellers and options.
   */
  quoteItemIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  rfqId: Scalars["ID"]["input"];
};

export type AccountingSettings = Node & {
  __typename?: "AccountingSettings";
  additionalChargesCostCode?: Maybe<CostCode>;
  additionalChargesCostType?: Maybe<Scalars["String"]["output"]>;
  defaultPOFormat?: Maybe<PoFormat>;
  defaultToWarehouse?: Maybe<Scalars["Boolean"]["output"]>;
  defaultWarehouseNumber?: Maybe<Scalars["String"]["output"]>;
  distributeAdditionalCharges?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated use ReleaseType.taxMode instead */
  distributeSalesTax?: Maybe<Scalars["Boolean"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  integration: IntegrationType;
  inventoryReceiptLedgerAccount?: Maybe<Scalars["String"]["output"]>;
  materialCostType?: Maybe<Scalars["String"]["output"]>;
  postInventoryReceipts?: Maybe<Scalars["Boolean"]["output"]>;
  progress: IntegrationMappingProgress;
  salesTaxCostCode?: Maybe<CostCode>;
  salesTaxCostType?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use Organization->taxAuthorities instead */
  taxAuthorities: Array<TaxAuthority>;
  toJobLedgerAccount?: Maybe<Scalars["String"]["output"]>;
  toWarehouseLedgerAccount?: Maybe<Scalars["String"]["output"]>;
};

export type ActivateBuyoutInput = {
  buyoutId: Scalars["ID"]["input"];
};

export type AddAuxiliaryQuoteItemInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * Attach components to this auxiliary quote item upon creation. Must not have
   * a quote item ID or an auxiliary quote item ID.
   */
  components?: InputMaybe<Array<AddQuoteItemComponentInput>>;
  /** The description of the auxiliary item. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddComplianceGroupInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddCostCodeInput = {
  code: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AddCostTypeInput = {
  code: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
};

export type AddEquipmentItemInput = {
  code: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
};

export type AddEstimatedItemInput = {
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  item?: InputMaybe<ProjectItemInput>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  orgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AddOrgLocationInput = {
  address: AddressInput;
  /**
   * Location name is optional only for vendor locations created via
   * addVendorLocation.
   */
  name?: InputMaybe<Scalars["String"]["input"]>;
  orgId: Scalars["ID"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddOrgPreferredVendorInput = {
  contacts?: InputMaybe<Array<VendorContactInput>>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  defaultPaymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  defaultReleaseType?: InputMaybe<Scalars["ID"]["input"]>;
  externalCode?: InputMaybe<Scalars["String"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  mappings?: InputMaybe<Array<MapNodeInput>>;
  orgLocationID: Scalars["ID"]["input"];
  paymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  taxExempt?: Scalars["Boolean"]["input"];
};

export type AddOrgPreferredVendorsInput = {
  vendors: Array<AddOrgPreferredVendorInput>;
};

export type AddPaymentMethodInput = {
  default?: Scalars["Boolean"]["input"];
  description: Scalars["String"]["input"];
  number?: InputMaybe<Scalars["String"]["input"]>;
  type: PaymentMethodType;
};

export type AddQuoteItemComponentInput = {
  auxiliaryQuoteItemId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  quoteItemId?: InputMaybe<Scalars["ID"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddQuoteItemInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * Attach components to this quote item upon creation. Must not have a quote
   * item ID or an auxiliary quote item ID.
   */
  components?: InputMaybe<Array<AddQuoteItemComponentInput>>;
  /** The description of a quote item. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  rfqItemId: Scalars["ID"]["input"];
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type AddServiceCodeInput = {
  code: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
};

export type AddTagInput = {
  color: Scalars["String"]["input"];
  /** Must be unique within the project. */
  name: Scalars["String"]["input"];
  projectId: Scalars["ID"]["input"];
};

export type AddTaxCodeInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  rate: Scalars["Decimal"]["input"];
};

export type AddToBuyoutInput = {
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  buyoutId: Scalars["ID"]["input"];
  items: Array<AddToBuyoutItemInput>;
  mergeDuplicates?: Scalars["Boolean"]["input"];
};

export type AddToBuyoutItemInput = {
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  description: Scalars["String"]["input"];
  instructions?: InputMaybe<InstructionInput>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Input for creating a brand new org catalog SKU to be associated with a new
   * Project Item that will be referenced by the newly-added RFQ Item.
   *
   * The UOM of the Project Item will we set to the default UOM of the newly
   * created org catalog SKU.
   *
   * Mutually exclusive with `projectItem`.
   */
  orgCatalogSku?: InputMaybe<CreateOrgCatalogSkuInput>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * Input for selecting or creating a Project Item that uses an existing
   * underlying material.
   *
   * Mutually exclusive with `orgCatalogSku`.
   */
  projectItem?: InputMaybe<ProjectItemInput>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  requestedUnitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type AddToReleaseInput = {
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  items: Array<AddToReleaseItemInput>;
  releaseId: Scalars["ID"]["input"];
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Note that buyoutItemId, estimatedItemId and projectItem are mutually exclusive */
export type AddToReleaseItemInput = {
  /**
   * This must be a parent buyout item ID. Components are added automatically
   * when adding their parent buyout item.
   */
  buyoutItemId?: InputMaybe<Scalars["ID"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  costTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  deliveryDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  deliveryDateTBD?: Scalars["Boolean"]["input"];
  /**
   * If present, the newly added release item will have a corresponding
   * DeliveredReleaseItem created, assuming full coverage.
   *
   * The specified deliver slip must reference the updated release.
   */
  deliverySlipId?: InputMaybe<Scalars["ID"]["input"]>;
  estimatedItemId?: InputMaybe<Scalars["ID"]["input"]>;
  instructions?: InputMaybe<InstructionInput>;
  /**
   * If present, the newly added release item will have a corresponding
   * InvoicedReleaseItem created, assuming full coverage.
   * The specified invoice must reference the updated release.
   */
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  orgCatalogSku?: InputMaybe<CreateOrgCatalogSkuInput>;
  orgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  poItemExternalId?: InputMaybe<Scalars["ID"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  pricePrenegotiated?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectItem?: InputMaybe<ProjectItemInput>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  receivedQuantity?: InputMaybe<Scalars["Float"]["input"]>;
  receivedQuantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  taxable?: Scalars["Boolean"]["input"];
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AddToRfqInput = {
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  items: Array<AddToRfqItemInput>;
  rfqId: Scalars["ID"]["input"];
};

export type AddToRfqItemInput = {
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  description: Scalars["String"]["input"];
  instructions?: InputMaybe<InstructionInput>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Input for creating a brand new org catalog SKU to be associated with a new
   * Project Item that will be referenced by the newly-added RFQ Item.
   *
   * The UOM of the Project Item will we set to the default UOM of the newly
   * created org catalog SKU.
   *
   * Mutually exclusive with `projectItem`.
   */
  orgCatalogSku?: InputMaybe<CreateOrgCatalogSkuInput>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * Input for selecting or creating a Project Item that uses an existing
   * underlying material.
   *
   * Mutually exclusive with `orgCatalogSku`.
   */
  projectItem?: InputMaybe<ProjectItemInput>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type AddVendorOrgInput = {
  name: Scalars["String"]["input"];
  photoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddVendorPriceInput = {
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /**
   * An idempotency key generated on the client side when opening the form.
   *
   * In case that a vendor price with the same idemptency key already exists
   * within the same org, the existing model will be returned instead of creating
   * a new one.
   */
  idempotencyKey?: InputMaybe<Scalars["String"]["input"]>;
  leadTime?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * manufacturerId is required for all org materials, underlying material type
   * of which is not a master SKU.
   * In case of master SKU, the manufacturer field on the created VendorPrice
   * should equal that of the master SKU.
   */
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  minimumOrder?: InputMaybe<Scalars["Float"]["input"]>;
  orderIncrement?: InputMaybe<Scalars["Float"]["input"]>;
  orgMaterialId: Scalars["ID"]["input"];
  price: Scalars["Decimal"]["input"];
  sellerOrgLocationId: Scalars["ID"]["input"];
  uom: Scalars["String"]["input"];
};

export type AddVendorReleaseItemInput = {
  deliveryDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  deliveryDateTBD?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIncluded?: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  quantity?: InputMaybe<Scalars["Decimal"]["input"]>;
  sourceReleaseItemId: Scalars["ID"]["input"];
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  uomID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AddWarehouseInput = {
  address: AddressInput;
  externalCode?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyKey?: InputMaybe<Scalars["String"]["input"]>;
  managers?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name: Scalars["String"]["input"];
  trackInventory?: Scalars["Boolean"]["input"];
};

export type AddZoneInput = {
  /** Must be unique within the project and have length between 1 and 64 characters. */
  name: Scalars["String"]["input"];
  projectId: Scalars["ID"]["input"];
};

export type AddZonesInput = {
  names: Array<Scalars["String"]["input"]>;
  projectId: Scalars["ID"]["input"];
};

export type Address = Node & {
  __typename?: "Address";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  postalCode: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
};

export type AddressInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
};

export type AggregatedCostCode = {
  __typename?: "AggregatedCostCode";
  costCode?: Maybe<CostCode>;
  items: Array<AggregatedItem>;
};

/**
 * AggregatedItem represents a single line item in the project's estimated items
 * list.
 */
export type AggregatedItem = {
  __typename?: "AggregatedItem";
  /**
   * List of buyout items projected onto this aggregated item. This list is only
   * specified when resolving aggregated zones on a buyout and not on a project.
   *
   * The contents of this field translate into one of 3 states in the UI:
   * 1. empty array: item from outside of the buyout;
   * 2. array with a single item: item included in the buyout;
   * 3. multiple items: the listed buyout items were accepted as alternatives at
   *    the quote level and carried over to the buyout.
   */
  buyoutItems?: Maybe<Array<BuyoutItem>>;
  /**
   * List of other buyouts in which the project item of this aggregated item is
   * also available.
   * This list is only specified when resolving aggregated zones on a buyout and
   * not on a project.
   */
  buyouts?: Maybe<Array<Buyout>>;
  /**
   * List of all estimated items that share the same underlying Project Item and
   * belong to the same zone as the AggregatedItem (via parent AggregatedZone).
   */
  estimatedItems: Array<EstimatedItem>;
  projectItem: ProjectItem;
  /**
   * Total amount of all release items that share the same underlying Project
   * Item and belong to releases in REQUESTED, SCHEDULED or COMPLETED statues.
   *
   * In case of this AggregatedItem being nested inside AggregatedZone, items
   * belonging to releases in other zones are not counted towards this amount.
   */
  releasedAmount: Scalars["Decimal"]["output"];
};

/**
 * AggregatedZone represents a single zone with its items. It is used for showing
 * the list of estimated items together with additional items which were used
 * across the project.
 *
 * Note that AggregatedZone is not a node as it is treated as a DTO representing a
 * custom view.
 */
export type AggregatedZone = {
  __typename?: "AggregatedZone";
  costCodes: Array<AggregatedCostCode>;
  /** In case of Unspecified zone, this field will be null. */
  zone?: Maybe<Zone>;
};

/** This input is deprecated. Use ReleaseItem.deliveryDate instead. */
export type AlternativeFulfillmentInput = {
  /**
   * This field indicates the quantity of this item that will be fulfilled at
   * the alternative time (alternativeFulfillmentTime) or via the alternative
   * release (alternativeFulfillmentRelease).
   *
   * If this field is not specified this value will be cleared from the Release
   * Item.
   *
   * This field may not be 0 or negative.
   * This field may not indicate a quantity greater than the original release
   * item quantity.
   * This field may not indicate a quantity equal to the original release item
   * quantity - set this to null if the entire quantity will be fulfilled
   * outside of the original release.
   */
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
  time?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

/** An amount type containing tax and extra charges. */
export type Amount = {
  __typename?: "Amount";
  charges?: Maybe<Scalars["Decimal"]["output"]>;
  net?: Maybe<Scalars["Decimal"]["output"]>;
  tax?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ApproveInvoiceInput = {
  invoiceId: Scalars["ID"]["input"];
};

export type ApproveReleaseInput = {
  releaseId: Scalars["ID"]["input"];
  /**
   * Skips the vendor confirmation process placing the release directly into
   * SCHEDULED status.
   */
  skipConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Skips the vendor notification process */
  skipVendorNotification?: Scalars["Boolean"]["input"];
  version: Scalars["Int"]["input"];
};

export type ArchiveInventoryItemsInput = {
  inventoryItemIds: Array<Scalars["ID"]["input"]>;
};

export type ArchiveInvoiceEmailsInput = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type Asset = Node & {
  __typename?: "Asset";
  context: AssetContext;
  createdAt: Scalars["Timestamp"]["output"];
  /** Data resolves to a base64-encoded string containing the asset data. */
  data: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /** Whether the asset is private, this means it is visible only to the team members of the project. */
  isPrivate: Scalars["Boolean"]["output"];
  /** malformed reports whether this asset is malformed. */
  malformed: Scalars["Boolean"]["output"];
  /**
   * pages is resolved only on assets split by pages, such as PDFs.
   * This is not available on malformed assets.
   */
  pages?: Maybe<Array<AssetPage>>;
  path: Scalars["String"]["output"];
  /** thumbnailUrl is not available for malformed assets. */
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
  type: AssetType;
  url: Scalars["String"]["output"];
};

export enum AssetContext {
  /** @deprecated Use INSTRUCTION instead */
  BuyoutInstruction = "BUYOUT_INSTRUCTION",
  /** @deprecated Use INSTRUCTION instead */
  BuyoutItemInstruction = "BUYOUT_ITEM_INSTRUCTION",
  DeliveryImage = "DELIVERY_IMAGE",
  DeliveryIssue = "DELIVERY_ISSUE",
  DeliverySlip = "DELIVERY_SLIP",
  Instruction = "INSTRUCTION",
  Invoice = "INVOICE",
  Note = "NOTE",
  NoteDocument = "NOTE_DOCUMENT",
  OrgImage = "ORG_IMAGE",
  ProfileImage = "PROFILE_IMAGE",
  QuoteDocument = "QUOTE_DOCUMENT",
  /** @deprecated Use INSTRUCTION instead */
  ReleaseInstruction = "RELEASE_INSTRUCTION",
  /** @deprecated Use INSTRUCTION instead */
  RfqInstruction = "RFQ_INSTRUCTION",
}

export type AssetPage = {
  __typename?: "AssetPage";
  path: Scalars["String"]["output"];
  thumbnailUrl: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export enum AssetType {
  Document = "DOCUMENT",
  Image = "IMAGE",
}

export type AssignableBuyout = {
  __typename?: "AssignableBuyout";
  buyout: Buyout;
  itemCount: Scalars["Int"]["output"];
  matchCount: Scalars["Int"]["output"];
};

export type AssignableBuyoutsInput = {
  releaseId: Scalars["ID"]["input"];
  /**
   * When specified, the query results will be narrowed down to buyouts
   * placed with the specified vendor. This field supersedes the orgLocationId
   * of the release.
   */
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum AuthorizationStatus {
  Authorized = "AUTHORIZED",
  NotAuthorized = "NOT_AUTHORIZED",
  /**
   * User is not authorized to perform the requested operation, but could be
   * authorized if they self-assigned to the project.
   */
  SelfAssignable = "SELF_ASSIGNABLE",
}

export type AuxiliaryQuoteItem = Node & {
  __typename?: "AuxiliaryQuoteItem";
  accepted: Scalars["Boolean"]["output"];
  /** @deprecated use assets */
  assetUrls: Array<Scalars["String"]["output"]>;
  assets: Array<Asset>;
  components: Array<QuoteItemComponent>;
  description?: Maybe<Scalars["String"]["output"]>;
  expirationDate?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  /** Lead time is expressed as a number of days. */
  leadTimeDays?: Maybe<Scalars["Int"]["output"]>;
  manufacturer?: Maybe<Manufacturer>;
  notes?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use `quantityDecimal` instead */
  quantity?: Maybe<Scalars["Float"]["output"]>;
  quantityDecimal?: Maybe<Scalars["Decimal"]["output"]>;
  quote: Quote;
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  uom?: Maybe<Uom>;
};

export enum BatchType {
  Invoice = "INVOICE",
  PurchaseOrder = "PURCHASE_ORDER",
}

export type Blob = {
  __typename?: "Blob";
  data: Scalars["String"]["output"];
};

export type BudgetCombination = {
  __typename?: "BudgetCombination";
  costCode: CostCode;
  costType?: Maybe<CostType>;
  zone?: Maybe<Zone>;
};

/** BudgetLink represents a link between a project and its budget in an external source system. */
export type BudgetLink = Node & {
  __typename?: "BudgetLink";
  autoSync: Scalars["Boolean"]["output"];
  autoSyncError?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  project: Project;
  projectExternalID: Scalars["ID"]["output"];
  sourceSystem: SourceSystem;
  syncedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * It's the source system wbsTags configuration in the moment of the link creation. If it's true,
   * the tag allowances are synchronized with the project budget, otherwise, it's the cost code allowances.
   */
  wbsTags: Scalars["Boolean"]["output"];
};

export type Buyout = Node & {
  __typename?: "Buyout";
  /**
   * Additional charges imposed by the vendor on top of the price of the
   * materials.
   *
   * Quoted buyouts:
   * 	Copied from Quote, immutable.
   *
   * Non-quoted buyouts:
   * 	Agreed upon during the non-quoted buyout workflow.
   * 	Mutable   (vendor)     -- { REQUESTED }
   * 	Immutable (contractor) -- {}
   */
  additionalCharges: Array<Charge>;
  additionalChargesAllowance?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * Returns the optimized estimated items view of the parent project with the
   * items belonging to this buyout projected onto it.
   *
   * Returned aggregated items only reference the project items of parent buyout
   * items. Components are not included, as they can be resolved via the
   * BuyoutItem > components field.
   */
  aggregatedZones: Array<AggregatedZone>;
  /** @deprecated use subtotal instead */
  amount: Scalars["Decimal"]["output"];
  /**
   * asset urls when vendor or contactor uploads buyout assets (photos, videos, documents)
   * @deprecated use assets
   */
  assetUrls: Array<Scalars["String"]["output"]>;
  assets: Array<Asset>;
  clientIdentifier: Scalars["String"]["output"];
  completedAmount: Scalars["Decimal"]["output"];
  createdAt: Scalars["Timestamp"]["output"];
  /**
   * The contractor that created this buyout.
   *
   * For quoted buyouts, this is the contractor that created the RFQ through
   * which the quote was accepted.
   *
   * For non-quoted buyouts, this is the contractor that created the buyout
   * directly.
   */
  createdBy: User;
  /**
   * If specified, this value overrides the tax amount calculated from the
   * taxRate field.
   */
  customTaxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  exportedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  exportedBy?: Maybe<User>;
  exportedTo?: Maybe<IntegrationType>;
  exportedVersion?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  /**
   * Additional services provided by the vendor besides the materials included in
   * the buyout.
   *
   * For quoted buyouts, these get carried over.
   */
  includedServices: Array<ServiceType>;
  instructions?: Maybe<Instruction>;
  /** Count of items */
  itemCount: Scalars["Int"]["output"];
  /**
   * Quoted buyouts:
   * 	Returns all buyout items that originate from quote items and auxiliary
   * 	quote items. Use BuyoutItem > components to get the buyout items that
   * 	originate from quote item components.
   *
   * Non-quoted buyouts:
   * 	Returns all buyout items.
   */
  items: Array<BuyoutItem>;
  netAmount: Scalars["Decimal"]["output"];
  /**
   * Buyout notes from the vendor: usually terms and conditions.
   *
   * Quoted buyouts:
   * 	Copied from Quote, immutable.
   *
   * Non-quoted buyouts:
   * 	Agreed upon during the non-quoted buyout workflow.
   * 	Mutable   (vendor)     -- { REQUESTED }
   * 	Immutable (contractor) -- {}
   */
  notes?: Maybe<Scalars["String"]["output"]>;
  /**
   * Payment term for the buyout.
   *
   * Quoted buyouts:
   * 	Copied from Quote, immutable.
   *
   * Non-quoted buyouts:
   * 	Agreed upon during the non-quoted buyout workflow.
   * 	Mutable   (vendor)     -- { REQUESTED }
   * 	Immutable (contractor) -- {}
   * @deprecated use paymentTermDays
   */
  paymentTerm?: Maybe<PaymentTerm>;
  paymentTermDays?: Maybe<Scalars["Int"]["output"]>;
  permissions: BuyoutPermissions;
  /**
   * Purchase order associated with this buyout.
   * @deprecated Use poNumber instead
   */
  po?: Maybe<Po>;
  /**
   * Whether or not the PO with the specified number exists in the connected ERP.
   *
   * This edge is served from the external PO cache and therefore should not
   * increase query latency.
   */
  poExists?: Maybe<Scalars["Boolean"]["output"]>;
  poLink?: Maybe<PoLink>;
  poNumber?: Maybe<Scalars["String"]["output"]>;
  /**
   * The preferred vendor that the buyout is intended for.
   *
   * This field is optional to support sending RFQs to non-preferred vendors. In
   * such a case, an OrgPreferredVendor would not exist, so there would be
   * nothing to copy over from the accepted Quote.
   *
   * Quoted buyouts:
   * 	Copied from the Quote.
   * 	Immutable.
   *
   * Non-quoted buyouts:
   * 	Manually assigned during buyout creation.
   * 	Immutable.
   */
  preferredVendor?: Maybe<OrgPreferredVendor>;
  project: Project;
  /**
   * Parent quote that the buyout was created from.
   *
   * The quote on a buyout is relevant only in the context of an quoted
   * buyout, which means that its presence can be used to determine whether
   * the buyout is quoted or not. This is important because some
   * buyout-specific mutations can only be performed on non-quoted buyouts.
   *
   * Quoted buyouts:
   * 	The quote that was accepted to create the buyout.
   * 	Immutable.
   *
   * Non-quoted buyouts:
   * 	Not present.
   */
  quote?: Maybe<Quote>;
  /** Quote document, OCR results of which were used to create the buyout. */
  quoteDocument?: Maybe<QuoteDocument>;
  /**
   * releaseCount is the number of non-archived releases from this
   * buyout that are in REQUESTED, SCHEDULED or COMPLETED status.
   */
  releaseCount: Scalars["Int"]["output"];
  releaseType: ReleaseType;
  /**
   * releasedAmount returns the total value of buyout items included in releases
   * that are in SCHEDULED and COMPLETED statuses.
   * If at least one release contains an item with a missing price, then the
   * releasedAmount is null.
   */
  releasedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  releases: Array<Release>;
  /**
   * requestedAmount is the total value of buyout items included in releases that
   * are in the REQUESTED status.
   */
  requestedAmount: Scalars["Decimal"]["output"];
  scheduledAmount: Scalars["Decimal"]["output"];
  /**
   * The org location that the vendor belongs to.
   *
   * Quoted buyouts:
   * 	Copied from Quote.
   * 	Immutable.
   *
   * Non-quoted buyouts:
   * 	Manually assigned during buyout creation.
   * 	Immutable.
   */
  sellerOrgLocation: OrgLocation;
  /**
   * Additional services provided by the vendor besides the materials included in
   * the buyout.
   *
   * Quoted buyouts:
   * 	Copied from Quote, immutable.
   *
   * Non-quoted buyouts:
   * 	Agreed upon during the non-quoted buyout workflow.
   * 	Mutable   (vendor)     -- { REQUESTED }
   * 	Immutable (contractor) -- {}
   */
  servicePriceSheet: ServicePriceSheet;
  standaloneReleaseItems: Array<BuyoutStandaloneReleaseItem>;
  status: BuyoutStatus;
  submittedBy?: Maybe<User>;
  /** Net amount + charges (allowance, custom tax, tax rate) */
  subtotal: Scalars["Decimal"]["output"];
  /**
   * Buyout-wide tags.
   *
   * These are inherited by released items unless explicitly removed by the user.
   *
   *    Mutable (contractor).
   * @deprecated Tags are now specified at the buyout item level. See BuyoutItem.tags.
   */
  tags: Array<ProjectTag>;
  /**
   * Tax amount of the Buyout.
   * Equal to customTaxAmount if present. Otherwise, calculated from the taxRate
   * field. Null if neither customTaxAmount nor taxRate is specified.
   */
  taxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  taxCode?: Maybe<TaxCode>;
  taxMode?: Maybe<TaxMode>;
  taxRate?: Maybe<Scalars["Decimal"]["output"]>;
  taxType?: Maybe<TaxType>;
  /**
   * Signed URL for accessing the buyout as a vendor.
   *
   * This is not available for DRAFT buyouts.
   * Only accessible from super admins.
   */
  url?: Maybe<Scalars["String"]["output"]>;
  vendorContacts: Array<VendorContact>;
  /**
   * Buyout versioning is used to track changes to buyouts in the REQUESTED
   * status so as to ensure that the contractor and the vendor agree to the same
   * terms whenever a state transition is triggered from the REQUESTED status.
   *
   * Buyout version is incremented whenever an update is made to a REQUESTED
   * buyout. This version can be used to know if the current buyout that a user
   * may be seeing is the latest version of the buyout or not. The buyout version
   * is incremented both when updating the buyout itself as well as when updating
   * items in the buyout.
   *
   * Mutations that trigger a state transition starting from the REQUESTED state
   * require a buyout version in the input. If the supplied buyout version is
   * different from the current buyout version, the mutation will fail.
   *
   * The initial version of a buyout is 0 and while the version itself is always
   * available, it should only be taken into consideration whenever the buyout
   * is in the REQUESTED status.
   *
   * Quoted buyouts:
   * 	Always 0.
   *
   * Non-quoted buyouts:
   * 	Incremented whenever the buyout is updated in the REQUESTED buyout
   * 	status.
   */
  version: Scalars["Int"]["output"];
};

export type BuyoutReleasesArgs = {
  status?: InputMaybe<Array<ReleaseStatus>>;
};

export type BuyoutStandaloneReleaseItemsArgs = {
  costCodeID?: InputMaybe<Scalars["ID"]["input"]>;
  projectItemID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BuyoutConnection = Connection & {
  __typename?: "BuyoutConnection";
  edges: Array<BuyoutEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type BuyoutEdge = Edge & {
  __typename?: "BuyoutEdge";
  cursor: Scalars["String"]["output"];
  node: Buyout;
};

export enum BuyoutEvent {
  Activate = "ACTIVATE",
  ActivateNonquoted = "ACTIVATE_NONQUOTED",
  Cancel = "CANCEL",
  ChangedByContractor = "CHANGED_BY_CONTRACTOR",
  Depleted = "DEPLETED",
  Request = "REQUEST",
  VendorAccept = "VENDOR_ACCEPT",
  VendorDecline = "VENDOR_DECLINE",
}

export type BuyoutItem = Node & {
  __typename?: "BuyoutItem";
  /**
   * asset urls when vendor or contactor uploads buyout item assets (photos, videos, documents)
   * @deprecated use assets
   */
  assetUrls: Array<Scalars["String"]["output"]>;
  assets: Array<Asset>;
  auxiliaryQuoteItem?: Maybe<AuxiliaryQuoteItem>;
  buyout: Buyout;
  /**
   * Aggregation of all release item amounts for this buyout item that are in
   * COMPLETED releases.
   */
  completedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * Resolves the buyout items acting as components, as they originated from
   * quote item components. They will appear in the same order as the quote
   * item components appear under their parent quote item or auxiliary quote
   * item.
   *
   * Non-quoted buyouts:
   * 	Not available.
   */
  components?: Maybe<Array<BuyoutItem>>;
  /** The cost code associated with this item. */
  costCode?: Maybe<CostCode>;
  description: Scalars["String"]["output"];
  /** Buyout items have an expiration date only in the context of quoted buyouts. */
  expirationDate?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  instructions?: Maybe<Instruction>;
  /**
   * This flag indicates whether the item is included by the vendor in the
   * buyout.
   *
   * For quoted buyouts, this is always true.
   *
   * For non-quoted buyouts, this is true by default, but may be updated by the
   * vendor to signify that they do not wish to supply the item.
   */
  isIncluded: Scalars["Boolean"]["output"];
  /**
   * Returns the max release time that is earlier than the current time of
   * a non-archived release belonging to one of the release items of this
   * specifc buyout item.
   */
  lastOrderedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** Each item in an ACTIVE buyout is required to have a manufacturer. */
  manufacturer?: Maybe<Manufacturer>;
  masterSku?: Maybe<Sku>;
  notes?: Maybe<Scalars["String"]["output"]>;
  /**
   * Represents the sum of the release item quantities that:
   * are included by the vendor
   * are not deleted
   * belong to non-archived releases
   * belong to releases, status of which is one of listed in the statuses argument
   * reference the buyout item
   * have the same UOM as the buyout item
   */
  orderedQuantity?: Maybe<Scalars["Decimal"]["output"]>;
  orgCatalogSku?: Maybe<OrgCatalogSku>;
  /**
   * Resolves the parent buyout item if this is a component, as it originated
   * from quote item components.
   *
   * Non-quoted buyouts:
   * 	Not available.
   */
  parent?: Maybe<BuyoutItem>;
  /**
   * Item position on spreadsheet view
   * Avaliable only for non-quoted buyouts
   */
  position?: Maybe<Scalars["Int"]["output"]>;
  projectItem: ProjectItem;
  /** @deprecated use quantityDecimal */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  quoteItem?: Maybe<QuoteItem>;
  quoteItemComponent?: Maybe<QuoteItemComponent>;
  releaseItems: Array<ReleaseItem>;
  /** Aggregation of all release item amounts for this buyout item that are in */
  releasedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * The unit price of this item, as requested by the contractor. By default,
   * this value is 0.
   *
   * This is available only in the context of non-quoted buyouts.
   */
  requestedUnitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  rfqItem?: Maybe<RfqItem>;
  tags: Array<ProjectTag>;
  /**
   * The unit price for this item.
   *
   * For quoted buyouts, this is the unit price that the vendor has agreed to
   * supply the item at via the quote.
   *
   * For non-quoted buyouts, this is the unit price that the vendor has
   * agreed to supply the item at when accepting the buyout. If the vendor has
   * yet to set a unit price, this value defaults to requestedUnitPrice.
   */
  unitPrice: Scalars["Decimal"]["output"];
};

export type BuyoutItemReleaseItemsArgs = {
  status?: InputMaybe<Array<ReleaseStatus>>;
};

export type BuyoutPermissions = {
  __typename?: "BuyoutPermissions";
  activate: AuthorizationStatus;
  archive: AuthorizationStatus;
  cancel: AuthorizationStatus;
  edit: AuthorizationStatus;
  export: AuthorizationStatus;
  submit: AuthorizationStatus;
};

export type BuyoutStandaloneReleaseItem = {
  __typename?: "BuyoutStandaloneReleaseItem";
  costCode?: Maybe<CostCode>;
  orderedSoFar: Scalars["Decimal"]["output"];
  orgMaterial: OrgMaterial;
  receivedSoFar: Scalars["Decimal"]["output"];
  releaseItems: Array<ReleaseItem>;
  totalQuantity: Scalars["Decimal"]["output"];
  unitPrice: Scalars["Decimal"]["output"];
  uom: Uom;
};

export enum BuyoutStatus {
  /**
   * A buyout from which a CONTRACTOR can release materials.
   *
   * Available for both quoted and non-quoted buyouts.
   * This buyout is immutable.
   *
   * ACTIVE -> {
   * 	CANCELLED, (all buyouts)
   * 	DEPLETED,  (all buyouts)
   * }
   */
  Active = "ACTIVE",
  /**
   * A buyout that has been cancelled by the CONTRACTOR.
   *
   * Available for both quoted and non-quoted buyouts.
   * This buyout is mutable.
   * This buyout must validate state transitions to immutable buyouts.
   *
   * CANCELLED -> {
   * 	REQUESTED, (non-quoted buyouts)
   * }
   */
  Cancelled = "CANCELLED",
  /**
   * A REQUESTED buyout that has been DECLINED by the vendor.
   *
   * Available for non-quoted buyouts ONLY.
   * This buyout is mutable.
   * This buyout must validate state transitions to immutable buyouts.
   *
   * DECLINED -> {
   * 	REQUESTED, (non-quoted buyouts)
   * }
   */
  Declined = "DECLINED",
  /**
   * An ACTIVE buyout that has no remaining quantity to be released.
   *
   * Available to both quoted and non-quoted buyouts.
   * This buyout is immutable.
   * This buyout cannot transition to any other status.
   *
   * DEPLETED -> {}
   */
  Depleted = "DEPLETED",
  /**
   * Initial status of a buyout.
   *
   * For quoted buyouts, this is the status that the buyout will be in after
   * a quote is accepted by the CONTRACTOR. Before transitioning to ACTIVE, the
   * CONTRACTOR will have to supply the remaining set of information required for
   * the buyout to be actionable.
   *
   * For non-quoted buyouts, this is the status that the buyout will be in
   * after it is created. Before transitioning to REQUESTED, the CONTRACTOR will
   * have to supply the remaining set of information required for the buyout to
   * be actionable.
   *
   * Available for both quoted and non-quoted buyouts.
   * This buyout is mutable.
   * This buyout must validate state transitions to immutable buyouts.
   *
   * DRAFT -> {
   * 	REQUESTED, (non-quoted buyouts)
   * 	ACTIVE,    (quoted buyouts)
   * 	CANCELLED, (all buyouts)
   * }
   */
  Draft = "DRAFT",
  /**
   * A DRAFT buyout that has been submitted to the vendor for approval.
   *
   * Available for non-quoted buyouts ONLY.
   * This buyout is mutable.
   * This buyout must validate state transitions to immutable buyouts.
   * Buyout mutations are allowed for the latest buyout version only.
   * Version is mandatory and enforced as part of any updates while a buyout is
   * in this status.
   *
   * REQUESTED -> {
   * 	DECLINED,  (non-quoted buyouts)
   * 	ACTIVE,    (non-quoted buyouts)
   * 	CANCELLED, (non-quoted buyouts)
   * }
   */
  Requested = "REQUESTED",
}

export type CancelBuyoutInput = {
  buyoutId: Scalars["ID"]["input"];
  version: Scalars["Int"]["input"];
};

export type CancelReleaseInput = {
  releaseId: Scalars["ID"]["input"];
  version: Scalars["Int"]["input"];
};

export type Charge = Node & {
  __typename?: "Charge";
  amount: Scalars["Decimal"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export enum ChargesMode {
  /**
   * Blend additional charges amount proportionally between line items of the PO/invoice
   * by adjusting the unit price of each line item.
   */
  Blended = "BLENDED",
  /**
   * The additional charges will listed as a separate line item with a cost code that has
   * the highest dollar amount in the PO/Invoice.
   */
  Majority = "MAJORITY",
  /** List additional charges amount as a separate line item in the PO/invoice. */
  Separate = "SEPARATE",
}

export type CompleteReleaseInput = {
  deliveryPhotoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  deliverySlipIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  deliverySlipUrl?: InputMaybe<Scalars["String"]["input"]>;
  deliverySlipUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  issues?: InputMaybe<Array<ReleaseIssueInput>>;
  releaseId: Scalars["ID"]["input"];
  version: Scalars["Int"]["input"];
};

export type ComplianceGroup = Node & {
  __typename?: "ComplianceGroup";
  code: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  externalComplianceGroup?: Maybe<ExternalComplianceGroup>;
  id: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
};

export type ConfirmReleaseInput = {
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  releaseId: Scalars["ID"]["input"];
  taxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  version: Scalars["Int"]["input"];
};

export type ConnectNodeInput = {
  /** ID of the target entity in the external system. */
  externalId: Scalars["ID"]["input"];
  /** ID of the FM entity to be connected to an entity in an external system. */
  nodeId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type ConnectSourceSystemInput = {
  orgId: Scalars["ID"]["input"];
  /**
   * Should the existing source system connection be overwritten in case that
   * the company in the source system associated with the public token turns out
   * to be different from the previously connected company?
   *
   * If set to true and the company is different, the existing source system
   * connection will be overwritten. This results in all existing node mappings
   * to that source system being deleted. If set to false or unspecified, an error
   * will be returned in case of a company mismatch.
   */
  overwrite?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicToken: Scalars["String"]["input"];
  system: SourceSystem;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CopyEstimatedItemsInput = {
  destinationProjectId: Scalars["ID"]["input"];
  replace?: Scalars["Boolean"]["input"];
  sourceProjectId: Scalars["ID"]["input"];
};

export type CorrectInvoiceDocumentInput = {
  assetUrl: Scalars["String"]["input"];
  invoiceId: Scalars["ID"]["input"];
};

export type CostCode = Node & {
  __typename?: "CostCode";
  code: Scalars["String"]["output"];
  /** @deprecated cost codes have transitioned from archival to deletion semantics; this field will always be null */
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inUse: Scalars["Boolean"]["output"];
  ledgerAccount?: Maybe<ExternalLedgerAccount>;
  mappings: Array<SourceSystemMapping>;
  org: Organization;
  /**
   * Returns the cost code with the same name and code existing at the org level.
   * This edge will only return non-null results for project-level cost codes.
   */
  orgEquivalent?: Maybe<CostCode>;
  project?: Maybe<Project>;
};

export type CostCodeAllowance = Node & {
  __typename?: "CostCodeAllowance";
  amount: Scalars["Decimal"]["output"];
  costCode: CostCode;
  costTypes?: Maybe<Array<CostType>>;
  id: Scalars["ID"]["output"];
  position?: Maybe<Scalars["Int"]["output"]>;
  zone?: Maybe<Zone>;
};

export type CostCodeAllowanceInput = {
  amount: Scalars["Decimal"]["input"];
  costCodeId: Scalars["ID"]["input"];
  costTypeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum CostCodeIdentifier {
  Code = "CODE",
  Description = "DESCRIPTION",
}

export type CostType = Node & {
  __typename?: "CostType";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  mappings: Array<SourceSystemMapping>;
  org: Organization;
};

export type CreateBuyoutInput = {
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  clientCustomNumber?: InputMaybe<Scalars["String"]["input"]>;
  customTaxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  includeServices?: InputMaybe<Array<UpdateServiceInput>>;
  instructions?: InputMaybe<InstructionInput>;
  items?: InputMaybe<Array<AddToBuyoutItemInput>>;
  mergeDuplicates?: Scalars["Boolean"]["input"];
  paymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  projectId: Scalars["ID"]["input"];
  /**
   * ID of the quote document, OCR results of which were used to create this
   * buyout.
   * When specified, the buyout will be created in the REQUESTED status and a
   * vendor notification will be sent.
   */
  quoteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  releaseTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  /** When set to true, the vendor notification will be skipped. */
  skipVendorNotification?: Scalars["Boolean"]["input"];
  /**
   * When set to true, the buyout will be submitted to the vendor immediately
   * after creation.
   */
  submit?: Scalars["Boolean"]["input"];
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  taxCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  taxMode?: InputMaybe<TaxMode>;
  taxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxType?: InputMaybe<TaxType>;
  vendorContactIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  vendorLocationId: Scalars["ID"]["input"];
};

/** Input for the createCustomFileFormat mutation. */
export type CreateCustomFileFormatInput = {
  fileType: FileType;
  mappings: Array<FieldMappingInput>;
  name: Scalars["String"]["input"];
};

export type CreateDeliveredReleaseItemInput = {
  quantity: Scalars["Decimal"]["input"];
  releaseItemId: Scalars["ID"]["input"];
};

export type CreateDeliverySlipInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  async?: Scalars["Boolean"]["input"];
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  deliveryPhotoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  newRelease?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  orgLocationId: Scalars["ID"]["input"];
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  releaseID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateEnterpriseInput = {
  name: Scalars["String"]["input"];
};

export type CreateExternalBatchInput = {
  month: Scalars["String"]["input"];
  sourceSystem: SourceSystem;
  type: BatchType;
};

export type CreateInventoryAdjustmentInput = {
  date: Scalars["Timestamp"]["input"];
  instructions?: InputMaybe<InstructionInput>;
  items: Array<CreateInventoryAdjustmentItemInput>;
  warehouseId: Scalars["ID"]["input"];
};

export type CreateInventoryAdjustmentItemInput = {
  /** Specify either an inventoryItemId or an orgMaterialId and uom */
  inventoryItemId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  orgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  /** price must be set when the quantity is positive and cannot be set if the quantity is negative. */
  price?: InputMaybe<Scalars["Decimal"]["input"]>;
  /** quantity can be positive or negative, but not zero. */
  quantity: Scalars["Decimal"]["input"];
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateInventoryTransferInput = {
  date: Scalars["Timestamp"]["input"];
  destinationWarehouseId: Scalars["ID"]["input"];
  instructions?: InputMaybe<InstructionInput>;
  items: Array<CreateInventoryTransferItemInput>;
  originWarehouseId: Scalars["ID"]["input"];
};

export type CreateInventoryTransferItemInput = {
  inventoryItemId: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** quantity must be positive. */
  quantity: Scalars["Decimal"]["input"];
};

export type CreateInvoiceChargeInput = {
  amount: Scalars["Decimal"]["input"];
  description: Scalars["String"]["input"];
};

export type CreateInvoiceFolderInput = {
  name: Scalars["String"]["input"];
};

export type CreateInvoiceInput = {
  assetUrls: Array<Scalars["String"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  orgLocationId: Scalars["ID"]["input"];
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateInvoiceIssueInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description: Scalars["String"]["input"];
  invoiceId: Scalars["ID"]["input"];
};

export type CreateInvoiceItemInput = {
  UOM: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  partNumber?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  unitPrice: Scalars["Decimal"]["input"];
};

export type CreateInvoiceKickbackCommentInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  kickbackId: Scalars["ID"]["input"];
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateInvoiceKickbackInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  assigneeIds: Array<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

export type CreateInvoicedReleaseItemInput = {
  quantity: Scalars["Decimal"]["input"];
  releaseItemId: Scalars["ID"]["input"];
  /**
   * Unit price of the invoiced release item. If not set, the one from the release item is used in computing the totals.
   * Sometimes, copy prices from invoice are not desired or do not work well so allowing the user
   * to manually set unit price and discarding the unit price from the invoice is a good option.
   */
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type CreateInvoicesInput = {
  assetUrl: Scalars["String"]["input"];
  isDeliverySlip?: InputMaybe<Scalars["Boolean"]["input"]>;
  orgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  pageRanges: Array<PageRangeInput>;
};

export type CreateManufacturerInput = {
  name: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateMaterialConversionInput = {
  from: Scalars["String"]["input"];
  orgMaterialId: Scalars["ID"]["input"];
  quantityMultiplier: Scalars["Decimal"]["input"];
  to: Scalars["String"]["input"];
};

export type CreateNoteDocumentInput = {
  /**
   * When multiple images are provided, they are converted to a multi-page PDF,
   * similarly to delivery slips.
   */
  assetUrls: Array<Scalars["String"]["input"]>;
  async?: Scalars["Boolean"]["input"];
  orgLocationId: Scalars["ID"]["input"];
};

export type CreateOrgCatalogSkuInput = {
  customPartNumber?: InputMaybe<Scalars["String"]["input"]>;
  defaultManufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  defaultUom: Scalars["String"]["input"];
  materialCostCode?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  sectionCode?: InputMaybe<Scalars["String"]["input"]>;
  sectionName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateOrgInput = {
  currencyId?: InputMaybe<Scalars["ID"]["input"]>;
  ein?: InputMaybe<Scalars["String"]["input"]>;
  /** enterpriseId is required for contractor orgs. If it's not provided, a new enterprise will be created. */
  enterpriseId?: InputMaybe<Scalars["ID"]["input"]>;
  hqAddress?: InputMaybe<AddressInput>;
  localPart?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  type: OrgType;
};

export type CreateOrgMaterialInput = {
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  externalCode?: InputMaybe<Scalars["String"]["input"]>;
  masterProductId?: InputMaybe<Scalars["ID"]["input"]>;
  masterSkuId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Specifying newOrgCatalogSKU results in an idempotent operation
   * on the Org Catalog SKU, that is it will create it if not exist.
   */
  newOrgCatalogSKU?: InputMaybe<CreateOrgCatalogSkuInput>;
  orgCatalogSkuId?: InputMaybe<Scalars["ID"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateProjectInput = {
  address: AddressInput;
  budget?: InputMaybe<Scalars["Decimal"]["input"]>;
  jobNumber?: InputMaybe<Scalars["String"]["input"]>;
  locationId: Scalars["ID"]["input"];
  mappings?: InputMaybe<Array<MapNodeInput>>;
  name: Scalars["String"]["input"];
  poJobNumber?: InputMaybe<Scalars["String"]["input"]>;
  preferredInventoryInflows?: Scalars["Boolean"]["input"];
  siteContact?: InputMaybe<SiteContactInput>;
  startDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  status: ProjectStatus;
  tags?: InputMaybe<Array<CreateTagInput>>;
  taxExempt?: Scalars["Boolean"]["input"];
  team: Array<Scalars["ID"]["input"]>;
  templateProjectId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Whether to use project cost codes for estimated/RFQ/buyout/release items.
   * If this argument is not provided, the default value from the organization
   * settings is used.
   */
  usesProjectCostCodes?: InputMaybe<Scalars["Boolean"]["input"]>;
  zones?: InputMaybe<Array<CreateZoneInput>>;
};

export type CreateProjectsInput = {
  projects: Array<CreateProjectInput>;
};

export type CreateQuoteDocumentInput = {
  assetUrl: Scalars["String"]["input"];
  orgLocationId: Scalars["ID"]["input"];
};

export type CreateReceiptInput = {
  assetUrls: Array<Scalars["String"]["input"]>;
  async?: Scalars["Boolean"]["input"];
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  orgLocationId: Scalars["ID"]["input"];
  prePaid?: Scalars["Boolean"]["input"];
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateReceiptKickbackInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  assigneeIds: Array<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  receiptId: Scalars["ID"]["input"];
};

export type CreateReleaseGroupInput = {
  name: Scalars["String"]["input"];
};

export type CreateReleaseInput = {
  buyoutId: Scalars["ID"]["input"];
};

export type CreateReleaseIssuesInput = {
  issues: Array<ReleaseIssueInput>;
  releaseId: Scalars["ID"]["input"];
};

export type CreateReleaseTypeInput = {
  additionalChargesCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  additionalChargesCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  billable?: Scalars["Boolean"]["input"];
  chargesMode?: InputMaybe<ChargesMode>;
  costTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  default?: Scalars["Boolean"]["input"];
  defaultAutoSync?: Scalars["Boolean"]["input"];
  defaultOrgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  enableUpcomingNotifications?: Scalars["Boolean"]["input"];
  includeAdditionalCharges?: Scalars["Boolean"]["input"];
  includeTaxInInvoiceSubtotal?: Scalars["Boolean"]["input"];
  ledgerAccount?: InputMaybe<MapNodeInput>;
  ledgerSubaccount?: InputMaybe<MapNodeInput>;
  name: Scalars["String"]["input"];
  nonJobPO?: Scalars["Boolean"]["input"];
  poFormat?: InputMaybe<PoFormat>;
  poNumbering?: InputMaybe<PoNumberingMode>;
  poType?: InputMaybe<MapNodeInput>;
  requireDeliverySlip?: Scalars["Boolean"]["input"];
  salesTaxCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  salesTaxCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  salesTaxOrgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  taxMode?: InputMaybe<TaxMode>;
  trackInventory?: Scalars["Boolean"]["input"];
  transactionKind?: TransactionKind;
};

export type CreateReservedReleaseInput = {
  buyoutId: Scalars["ID"]["input"];
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  includedServices?: InputMaybe<Array<ServiceType>>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  taxCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  taxType?: InputMaybe<TaxType>;
  timeTBD?: Scalars["Boolean"]["input"];
  typeId?: InputMaybe<Scalars["ID"]["input"]>;
  vendorContactIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  /** IDs of users to be added to the release watchers. */
  watcherIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CreateRfqInput = {
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  clientCustomNumber?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  includeServices?: InputMaybe<Array<ServiceType>>;
  instructions?: InputMaybe<InstructionInput>;
  items?: InputMaybe<Array<AddToRfqItemInput>>;
  noteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  projectId: Scalars["ID"]["input"];
  requisitionId?: InputMaybe<Scalars["ID"]["input"]>;
  targets?: InputMaybe<Array<RfqPreferredVendorInput>>;
  vendorLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CreateSiteContactInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateStandaloneReleaseInput = {
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  /**
   * If the mapping is specified, assign zones to newly-inserted ReleaseItems
   * based on external sub-jobs associated with the imported PO items.
   */
  assignPOItemZones?: Scalars["Boolean"]["input"];
  basicInvoicing?: InputMaybe<Scalars["Boolean"]["input"]>;
  complianceGroupId?: InputMaybe<Scalars["ID"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  customTaxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  deliveryPhotoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * When specified and the status is RECEIVED, full coverage between the packing
   * slip and the release items is assumed.
   */
  deliverySlipId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  includeServices?: InputMaybe<Array<UpdateServiceInput>>;
  instructions?: InputMaybe<InstructionInput>;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  items?: InputMaybe<Array<AddToReleaseItemInput>>;
  mapping?: InputMaybe<MapNodeInput>;
  /**
   * If set to true and mapping is specified, it will result in an immutable PO
   * link being created.
   */
  mappingImmutable?: Scalars["Boolean"]["input"];
  noteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  orgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  paymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  quoteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  quoteId?: InputMaybe<Scalars["ID"]["input"]>;
  requiresInventoryReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  retroactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  /** When set to true, the receipt does not transition to APPROVED status, it stays in AWAITING_APPROVAL. */
  skipInvoiceApproval?: Scalars["Boolean"]["input"];
  /** When set to true, the vendor will not be notified about the release being submitted. */
  skipVendorNotification?: Scalars["Boolean"]["input"];
  sourceWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Status in which to create the release.
   *
   * Allowed values: DRAFT, REQUESTED, SCHEDULED, COMPLETED
   */
  status?: InputMaybe<ReleaseStatus>;
  taxCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  taxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxType?: InputMaybe<TaxType>;
  taxVariance?: InputMaybe<Scalars["Decimal"]["input"]>;
  time?: InputMaybe<Scalars["Timestamp"]["input"]>;
  timeTBD?: Scalars["Boolean"]["input"];
  typeId?: InputMaybe<Scalars["ID"]["input"]>;
  useSourceSystemPO?: Scalars["Boolean"]["input"];
  vendorContactIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  /** IDs of users to be added to the release watchers. */
  watcherIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * Unlike AddTagInput which creates a tag within an existing project, this input
 * is used to create a new tag while creating a new project.
 */
export type CreateTagInput = {
  color: Scalars["String"]["input"];
  /** Must be unique within the project. */
  name: Scalars["String"]["input"];
};

export type CreateUserInput = {
  cellPhone: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  enterpriseRoles?: InputMaybe<Array<EnterpriseRole>>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  locationRoles: Array<LocationRoleInput>;
  orgId?: InputMaybe<Scalars["ID"]["input"]>;
  orgRoles: Array<OrgRole>;
  systemRoles: Array<SystemRole>;
};

/**
 * Unlike AddZoneInput which creates a zone within an existing project, this input
 * is used to create a new zone while creating a new project.
 */
export type CreateZoneInput = {
  /** Must be unique within the project and have length between 1 and 64 characters. */
  name: Scalars["String"]["input"];
};

export type Currency = Node & {
  __typename?: "Currency";
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
};

/** A saved file format for custom field mapping. */
export type CustomFileFormat = Node & {
  __typename?: "CustomFileFormat";
  fileType: FileType;
  id: Scalars["ID"]["output"];
  mappings: Array<FieldMapping>;
  name: Scalars["String"]["output"];
};

export type CustomFileFormatInput = {
  fileType: FileType;
  mappings: Array<FieldMappingInput>;
};

export type CustomIntegrationInput = {
  /** Use a new file format. */
  fileFormat?: InputMaybe<CustomFileFormatInput>;
  /** Use an existing file format to execute this integration. */
  fileFormatID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CustomSkuInput = {
  categoryName: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CustomTemplateInput = {
  name: Scalars["String"]["input"];
  template: Scalars["String"]["input"];
};

export type DeclineBuyoutInput = {
  buyoutId: Scalars["ID"]["input"];
  version: Scalars["Int"]["input"];
};

export type DeliveredReleaseItem = Node & {
  __typename?: "DeliveredReleaseItem";
  deliverySlip: DeliverySlip;
  id: Scalars["ID"]["output"];
  /**
   * Assigned when a delivery slip open against a warehouse-directed order
   * is processed.
   *
   * For inflows, this array will always have one element.
   *
   * For outflows, this array may consist of multiple elements
   * (e.g. if items are released from the warehouse at different
   * prices).
   */
  inventoryTransactionItems?: Maybe<Array<InventoryTransactionItem>>;
  quantity: Scalars["Decimal"]["output"];
  /**
   * Sum of DeliveredReleaseItem.quantity for the same release item across all
   * PROCESSED, non-archived delivery slips other than the delivery slip of this
   * DeliveredReleaseItem.
   */
  quantitySoFar?: Maybe<Scalars["Decimal"]["output"]>;
  releaseItem: ReleaseItem;
};

export type DeliverySlip = Node & {
  __typename?: "DeliverySlip";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  archivedBy?: Maybe<User>;
  asset?: Maybe<Asset>;
  createdAt: Scalars["Timestamp"]["output"];
  createdBy: User;
  deliveredReleaseItems: Array<DeliveredReleaseItem>;
  deliveryPhotos: Array<Asset>;
  fulfillmentDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * Returns whether or not a release was matched to this delivery slip, regardless
   * of whether the user is authorized to access the release.
   */
  hasPredictedRelease: Scalars["Boolean"]["output"];
  hasRelease: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  /**
   * Assigned when a delivery slip open against a warehouse-directed order
   * is processed.
   */
  inventoryTransaction?: Maybe<InventoryTransaction>;
  items: Array<DeliverySlipItem>;
  notes?: Maybe<Scalars["String"]["output"]>;
  orgLocation: OrgLocation;
  permissions: DeliverySlipPermissions;
  /** OCR/matching results or updated via updateDeliverySlip mutation */
  poNumber?: Maybe<Scalars["String"]["output"]>;
  /**
   * The release that was matched to this delivery slip.
   *
   * Note: this edge returns null if the user is not authorized to access this release.
   * In order to check if a matched release was found, use the hasPredictedRelease field.
   */
  predictedRelease?: Maybe<Release>;
  predictedSellerOrgLocation?: Maybe<OrgLocation>;
  project?: Maybe<Project>;
  release?: Maybe<Release>;
  /** Compute hints for matching delivery slip items with release items. */
  releaseItemHints: Array<ReleaseItemHint>;
  reviewedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  reviewedBy?: Maybe<User>;
  status: DeliverySlipStatus;
};

export type DeliverySlipConnection = Connection & {
  __typename?: "DeliverySlipConnection";
  edges: Array<DeliverySlipEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeliverySlipEdge = Edge & {
  __typename?: "DeliverySlipEdge";
  cursor: Scalars["String"]["output"];
  node: DeliverySlip;
};

export type DeliverySlipItem = Node & {
  __typename?: "DeliverySlipItem";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  orderedQuantity?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated use quantityDecimal */
  quantity?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated Use orderedQuantity or receivedQuantity */
  quantityDecimal?: Maybe<Scalars["Decimal"]["output"]>;
  receivedQuantity?: Maybe<Scalars["Decimal"]["output"]>;
  uom?: Maybe<Scalars["String"]["output"]>;
};

export type DeliverySlipPermissions = {
  __typename?: "DeliverySlipPermissions";
  archive: AuthorizationStatus;
  edit: AuthorizationStatus;
};

export type DeliverySlipStats = {
  __typename?: "DeliverySlipStats";
  /**
   * Returns the number of delivery slips in the Pending Receipts that are
   * accessible to the current user.
   */
  pendingCount: Scalars["Int"]["output"];
};

export enum DeliverySlipStatus {
  Pending = "PENDING",
  Processed = "PROCESSED",
}

export type Device = Node & {
  __typename?: "Device";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  platform: DevicePlatform;
};

export enum DevicePlatform {
  Android = "ANDROID",
  Ios = "IOS",
}

export type DisconnectNodeInput = {
  /** ID of the FM entity to be disconnected from an entity in an external system. */
  nodeId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type DisconnectSourceSystemInput = {
  orgId: Scalars["ID"]["input"];
  system: SourceSystem;
};

export type Discount = {
  __typename?: "Discount";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  percentage?: Maybe<Scalars["Decimal"]["output"]>;
};

export type DiscountInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  clear?: Scalars["Boolean"]["input"];
  percentage?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type DisplaySettings = {
  __typename?: "DisplaySettings";
  appendExternalCode?: Maybe<Scalars["Boolean"]["output"]>;
  costCodeIdentifier: CostCodeIdentifier;
  currency: Currency;
  currencyId: Scalars["ID"]["output"];
  defaultItemized?: Maybe<Scalars["Boolean"]["output"]>;
  enableAdditionalCharges: Scalars["Boolean"]["output"];
  enableManufacturers: Scalars["Boolean"]["output"];
  enableTaxVariance: Scalars["Boolean"]["output"];
  /** Whether to show the cost type column at the line item level. */
  itemCostTypes: Scalars["Boolean"]["output"];
  receiptDefaultItemized?: Maybe<Scalars["Boolean"]["output"]>;
  /** Whether to require project assignment for receipts and delivery slips. */
  requireProjectAssignment: Scalars["Boolean"]["output"];
  /**
   * Whether to use project cost codes for estimated/RFQ/buyout/release items.
   * This flag stores the default behavior for new projects.
   */
  usesProjectCostCodes: Scalars["Boolean"]["output"];
};

export type DocumentReaderSettings = {
  __typename?: "DocumentReaderSettings";
  appendPartNumberToDescription: Scalars["Boolean"]["output"];
};

export type DownloadInvoiceDocumentInput = {
  invoiceId: Scalars["ID"]["input"];
  /**
   * Version of the invoice document to download.
   * If left unspecified, the latest version is returned.
   */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DownloadInvoiceDocumentsInput = {
  invoices: Array<DownloadInvoiceDocumentInput>;
  isReceipt?: Scalars["Boolean"]["input"];
};

export type DrywallProductDetail = Node &
  ProductDetail & {
    __typename?: "DrywallProductDetail";
    drywallTypes: Array<DrywallType>;
    id: Scalars["ID"]["output"];
    length: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    thickness: Scalars["Float"]["output"];
    width: Scalars["Int"]["output"];
  };

export type DrywallType = {
  __typename?: "DrywallType";
  name: Scalars["String"]["output"];
};

export type Edge = {
  cursor: Scalars["String"]["output"];
  node: Node;
};

export type Enterprise = Node & {
  __typename?: "Enterprise";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  orgPreferredVendors: Array<OrgPreferredVendor>;
  orgs: Array<Organization>;
  /**
   * Returns the list of users across all organizations in the enterprise.
   *
   * Only accessible to org and enterprise admins.
   */
  users: Array<User>;
};

export type EnterpriseOrgPreferredVendorsArgs = {
  excludedOrgIds: Array<Scalars["ID"]["input"]>;
};

export enum EnterpriseRole {
  EnterpriseAdmin = "ENTERPRISE_ADMIN",
}

export type Equipment = Node & {
  __typename?: "Equipment";
  id: Scalars["ID"]["output"];
  locationId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  type: EquipmentType;
};

export type EquipmentItem = Node & {
  __typename?: "EquipmentItem";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  externalEquipmentItem?: Maybe<ExternalEquipmentItem>;
  id: Scalars["ID"]["output"];
};

export enum EquipmentType {
  BoomTruck = "BOOM_TRUCK",
  FlatbedTruck = "FLATBED_TRUCK",
  ForkLift = "FORK_LIFT",
}

export type EstimatedItem = Node & {
  __typename?: "EstimatedItem";
  /** The cost code associated with this item. */
  costCode?: Maybe<CostCode>;
  /**
   * The number of EstimatedItems within the same project, which:
   * - have the same UOM as the EstimatedItem
   * - have the same CostCode assigned as the EstimatedItem (or lack thereof)
   * - have the same Zone assigned as the EstimatedItem (or lack thereof)
   * - reference the same OrgMaterial as the EstimatedItem
   * Returns 1 if the current EstimatedItem is the only one matching the spec within the project.
   * This edge is used to determine if the EstimatedItem.orderedQuantity can be reliably attributed
   * to the current EstimatedItem.
   */
  duplicateCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  /** @deprecated Use uom and material instead. */
  item: ProjectItem;
  manufacturer?: Maybe<Manufacturer>;
  material: OrgMaterial;
  notes?: Maybe<Scalars["String"]["output"]>;
  /**
   * This field returns the sum of quantities of ReleaseItems, which:
   * - belong to REQUESTED, SCHEDULED, RECEIVED and PARTIALLY_RECEIVED Releases
   * - have the same UOM as the EstimatedItem
   * - have the same CostCode assigned as the EstimatedItem (or lack thereof)
   * - have the same Zone assigned as the EstimatedItem (or lack thereof)
   * - reference the same OrgMaterial as the EstimatedItem
   */
  orderedQuantity: Scalars["Decimal"]["output"];
  /** Item position on spreadsheet view */
  position?: Maybe<Scalars["Int"]["output"]>;
  /** Preferred vendor for this item. */
  preferredVendor?: Maybe<OrgPreferredVendor>;
  /** The project that owns the estimate. */
  project: Project;
  /** @deprecated Use quantityDecimal instead. */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  /**
   * This field returns the total amount of the release item that:
   * - are part of Releases in the REQUESTED, SCHEDULED or COMPLETED status,
   * - share the same underlying Project Item as this Estimated Item
   * - have the same zone as this Estimated Item (or no zone if this Estimated
   *   Item has no zone)
   */
  releasedAmount: Scalars["Decimal"]["output"];
  sellerOrgLocation?: Maybe<OrgLocation>;
  tags: Array<ProjectTag>;
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  uom: Uom;
  zone?: Maybe<Zone>;
};

export type EstimatedItemGroup = {
  __typename?: "EstimatedItemGroup";
  costCode?: Maybe<CostCode>;
  estimatedItemCount: Scalars["Int"]["output"];
  estimatedItems: Array<EstimatedItem>;
};

export type ExportBuyoutOptionsInput = {
  buyoutID: Scalars["ID"]["input"];
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExportBuyoutsInput = {
  buyouts: Array<ExportBuyoutOptionsInput>;
  integration: IntegrationType;
};

export type ExportBuyoutsOutput = {
  __typename?: "ExportBuyoutsOutput";
  fileURLs?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ExportInvoiceInput = {
  GLExpense?: InputMaybe<Scalars["String"]["input"]>;
  chargesCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  includeCostCodes: Scalars["Boolean"]["input"];
  invoiceId: Scalars["ID"]["input"];
  taxCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  vendorExternalCode?: InputMaybe<Scalars["String"]["input"]>;
  voucherType?: InputMaybe<VoucherType>;
};

export type ExportInvoicesInput = {
  integration: IntegrationType;
  invoices: Array<ExportInvoiceInput>;
  /** (FOUNDATION) a date filled out in the Check Date/Payment Date column of the invoice template */
  postingDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type ExportInvoicesOutput = {
  __typename?: "ExportInvoicesOutput";
  fileURLs?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ExportQuoteSelectionInput = {
  /** The software the exported file must be compatbile with. */
  integration: IntegrationType;
  /**
   * The quote to export. If no quote items are selected, all items of this quote
   * will be exported, otherwise only the selected items will.
   * If this is provided, the exported data will contain vendor information aswell.
   */
  quoteID?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * The quote items selected for the export.
   * If no quote is specified, the items can be part of multiple quotes for the same RFQ,
   * otherwise the items must be part of the given quote's items. If all the items are
   * part of the same single quote, then vendor information will be included in the exported data.
   */
  quoteItemIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** The RFQ to export for. */
  rfqID: Scalars["ID"]["input"];
  /** Export target URL (only applicable to EQUOTES integration type). */
  targetURL?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExportReleaseInput = {
  GLExpense?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * (FOUNDATION) If it's true, items will be aggregated by the cost code.
   * Foundation's PO basic format is generated if selected, otherwise the itemized format.
   */
  aggregatedByCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  chargesCostCodeID?: InputMaybe<Scalars["ID"]["input"]>;
  integration: IntegrationType;
  jobItemsWithPartNumbers?: InputMaybe<Scalars["Boolean"]["input"]>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  releaseID: Scalars["ID"]["input"];
  /**
   * (FOUNDATION|SAGE300) the code of the tax authority with the applicable tax rate.
   * If unspecified, tax will not be included(for Foundation, it's only used on the itemized file format).
   */
  taxAuthorityCode?: InputMaybe<Scalars["String"]["input"]>;
  warehouseNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExportReleaseOptionsInput = {
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  releaseID: Scalars["ID"]["input"];
  taxAuthorityCode?: InputMaybe<Scalars["String"]["input"]>;
  vendorExternalCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExportReleaseOutput = {
  __typename?: "ExportReleaseOutput";
  fileURLs?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ExportReleasesInput = {
  GLExpense?: InputMaybe<Scalars["String"]["input"]>;
  additionalChargesCostType?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * (FOUNDATION) If it's true, items will be aggregated by the cost code.
   * Foundation's PO basic format is generated if selected, otherwise the itemized format.
   */
  aggregatedByCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  chargesCostCodeID?: InputMaybe<Scalars["ID"]["input"]>;
  integration: IntegrationType;
  jobItemsWithPartNumbers?: InputMaybe<Scalars["Boolean"]["input"]>;
  materialCostType?: InputMaybe<Scalars["String"]["input"]>;
  releases: Array<ExportReleaseOptionsInput>;
  warehouseNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExportedQuoteSelection = {
  __typename?: "ExportedQuoteSelection";
  /** Download URL is only present in case of ONCENTER integration type. */
  downloadURL?: Maybe<Scalars["String"]["output"]>;
};

export type ExternalAddress = {
  __typename?: "ExternalAddress";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type ExternalBankAccount = ExternalNode &
  Node & {
    __typename?: "ExternalBankAccount";
    bankName?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    number?: Maybe<Scalars["String"]["output"]>;
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    sourceUpdatedAt: Scalars["Timestamp"]["output"];
    type: Scalars["String"]["output"];
  };

export type ExternalBatch = ExternalNode &
  Node & {
    __typename?: "ExternalBatch";
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    month: Scalars["String"]["output"];
    number: Scalars["String"]["output"];
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    /**
     * Unlike other fields, which are cached, the status field is expensive, as it
     * requires a call to the source system.
     */
    status: Scalars["String"]["output"];
    type: BatchType;
  };

export type ExternalBatchQueryInput = {
  externalId?: InputMaybe<Scalars["ID"]["input"]>;
  month?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  sourceSystem: SourceSystem;
};

export type ExternalComplianceGroup = ExternalNode &
  Node & {
    __typename?: "ExternalComplianceGroup";
    code: Scalars["String"]["output"];
    description: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    notes: Scalars["String"]["output"];
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
  };

/**
 * ExternalConnection is used for returning paginated results from an external
 * data source.
 * Unlike regular Connection, ExternalConnection:
 * 1) does not support cursors;
 * 2) does not return the total results count.
 */
export type ExternalConnection = {
  currentPage: Scalars["Int"]["output"];
  hasNextPage: Scalars["Boolean"]["output"];
  nodes: Array<ExternalNode>;
};

export type ExternalCostCode = ExternalNode & {
  __typename?: "ExternalCostCode";
  code: Scalars["String"]["output"];
  /**
   * FM cost code that is not connected to this external cost code but has either
   * the same code or name (but not both).
   * As a result of the importCostCodes mutation, the cost code specified in this
   * field will be updated so that it matches the external cost code.
   */
  collidesWith?: Maybe<CostCode>;
  costCode?: Maybe<CostCode>;
  externalId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalCostCodeConnection = ExternalConnection & {
  __typename?: "ExternalCostCodeConnection";
  currentPage: Scalars["Int"]["output"];
  hasNextPage: Scalars["Boolean"]["output"];
  nodes: Array<ExternalCostCode>;
};

export type ExternalCostType = ExternalNode & {
  __typename?: "ExternalCostType";
  code: Scalars["String"]["output"];
  /**
   * FM cost type that is not connected to this external cost type but has either
   * the same code or name (but not both).
   * As a result of the importCostTypes mutation, the cost type specified in this
   * field will be updated so that it matches the external cost type.
   */
  collidesWith?: Maybe<CostType>;
  costType?: Maybe<CostType>;
  externalId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalEquipmentItem = ExternalNode &
  Node & {
    __typename?: "ExternalEquipmentItem";
    code: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
  };

export type ExternalEstimate = ExternalNode & {
  __typename?: "ExternalEstimate";
  description: Scalars["String"]["output"];
  externalId: Scalars["ID"]["output"];
  items: Array<ExternalEstimatedItem>;
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalEstimatedItem = ExternalNode & {
  __typename?: "ExternalEstimatedItem";
  costCodeSourceId?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  externalId: Scalars["ID"]["output"];
  itemSourceId?: Maybe<Scalars["String"]["output"]>;
  quantity: Scalars["Decimal"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
  unitCost?: Maybe<Scalars["Decimal"]["output"]>;
  uom: Scalars["String"]["output"];
  vendorSourceId?: Maybe<Scalars["String"]["output"]>;
};

export type ExternalExpense = ExternalNode & {
  __typename?: "ExternalExpense";
  bankAccountId: Scalars["ID"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  externalId: Scalars["ID"]["output"];
  number?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalIdQueryInput = {
  externalId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type ExternalInsurance = ExternalNode &
  Node & {
    __typename?: "ExternalInsurance";
    company?: Maybe<Scalars["String"]["output"]>;
    endDate?: Maybe<Scalars["Timestamp"]["output"]>;
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    limit?: Maybe<Scalars["Decimal"]["output"]>;
    policyNumber?: Maybe<Scalars["String"]["output"]>;
    required: Scalars["Boolean"]["output"];
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    startDate?: Maybe<Scalars["Timestamp"]["output"]>;
    type?: Maybe<Scalars["String"]["output"]>;
  };

export type ExternalInvoice = ExternalNode & {
  __typename?: "ExternalInvoice";
  description: Scalars["String"]["output"];
  externalId: Scalars["ID"]["output"];
  number: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

/**
 * ExternalItem represents an inventory item from an external source system that is
 * also cached in Field Materials to support filtering with cursor-based pagination.
 */
export type ExternalItem = ExternalNode &
  Node & {
    __typename?: "ExternalItem";
    defaultCostCode?: Maybe<Scalars["String"]["output"]>;
    deliveryUom?: Maybe<Scalars["String"]["output"]>;
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    orgMaterials: Array<OrgMaterial>;
    purchasingUom?: Maybe<Scalars["String"]["output"]>;
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    sourceUpdatedAt: Scalars["Timestamp"]["output"];
    type?: Maybe<Scalars["String"]["output"]>;
    unitCost?: Maybe<Scalars["Decimal"]["output"]>;
    unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  };

export type ExternalItemConnection = Connection & {
  __typename?: "ExternalItemConnection";
  edges: Array<ExternalItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ExternalItemEdge = Edge & {
  __typename?: "ExternalItemEdge";
  cursor: Scalars["String"]["output"];
  node: ExternalItem;
};

export type ExternalLedgerAccount = ExternalNode & {
  __typename?: "ExternalLedgerAccount";
  externalId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
  subaccounts?: Maybe<Array<ExternalLedgerSubaccount>>;
};

export type ExternalLedgerSubaccount = ExternalNode & {
  __typename?: "ExternalLedgerSubaccount";
  externalId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalNode = {
  externalId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalNotification = Node & {
  __typename?: "ExternalNotification";
  buyout?: Maybe<Buyout>;
  channel: ExternalNotificationChannel;
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  invoice?: Maybe<Invoice>;
  project?: Maybe<Project>;
  quote?: Maybe<Quote>;
  recipient: Scalars["String"]["output"];
  release?: Maybe<Release>;
  subject: Scalars["String"]["output"];
};

export enum ExternalNotificationChannel {
  Email = "EMAIL",
  Push = "PUSH",
  Sms = "SMS",
}

export type ExternalNotificationConnection = Connection & {
  __typename?: "ExternalNotificationConnection";
  edges: Array<ExternalNotificationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ExternalNotificationEdge = Edge & {
  __typename?: "ExternalNotificationEdge";
  cursor: Scalars["String"]["output"];
  node: ExternalNotification;
};

/**
 * ExternalPO represents a purchase order in an external source system to which
 * a release has been exported.
 */
export type ExternalPo = ExternalNode & {
  __typename?: "ExternalPO";
  amount: Scalars["Decimal"]["output"];
  date: Scalars["Timestamp"]["output"];
  description: Scalars["String"]["output"];
  externalId: Scalars["ID"]["output"];
  format: PoFormat;
  itemGroups: ExternalPoItemGroups;
  items: Array<ExternalPoItem>;
  name: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
  poLinks: Array<PoLink>;
  project?: Maybe<ExternalProject>;
  projectExternalId?: Maybe<Scalars["ID"]["output"]>;
  releaseType?: Maybe<ReleaseType>;
  /**
   * ID of a release type that was used to interpret the contents of the PO.
   *
   * This field is only populated in the results of the externalPO query and not
   * in the results of the externalPOs query.
   *
   * If the releaseTypeId is specified in the ExternalPOQueryInput, the release
   * type is not inferred from the PO, but defaults to the one specified in the
   * input.
   */
  releaseTypeId?: Maybe<Scalars["String"]["output"]>;
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
  status: Scalars["String"]["output"];
  taxAmount: Scalars["Decimal"]["output"];
  /** type could be used on SAGE100 source systems */
  type?: Maybe<ExternalPoType>;
  typeName?: Maybe<Scalars["String"]["output"]>;
  vendor?: Maybe<ExternalVendor>;
  vendorExternalId?: Maybe<Scalars["ID"]["output"]>;
};

export type ExternalPoConnection = ExternalConnection & {
  __typename?: "ExternalPOConnection";
  currentPage: Scalars["Int"]["output"];
  hasNextPage: Scalars["Boolean"]["output"];
  nodes: Array<ExternalPo>;
};

export enum ExternalPoImportPossibility {
  Error = "ERROR",
  Full = "FULL",
  Immutable = "IMMUTABLE",
}

export type ExternalPoItem = ExternalNode & {
  __typename?: "ExternalPOItem";
  amount: Scalars["Decimal"]["output"];
  /** Field Materials equivalent of the external cost code (if present). */
  costCode?: Maybe<CostCode>;
  costType?: Maybe<ExternalCostType>;
  description: Scalars["String"]["output"];
  externalCostCode?: Maybe<ExternalCostCode>;
  externalCostCodeId?: Maybe<Scalars["ID"]["output"]>;
  externalCostTypeId?: Maybe<Scalars["ID"]["output"]>;
  externalId: Scalars["ID"]["output"];
  externalSubJob?: Maybe<ExternalSubJob>;
  externalSubJobId?: Maybe<Scalars["ID"]["output"]>;
  externalTaxCode?: Maybe<ExternalTaxCode>;
  externalTaxCodeId?: Maybe<Scalars["ID"]["output"]>;
  invoicedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** notes is a Vista specific field */
  notes?: Maybe<Scalars["String"]["output"]>;
  /**
   * Project associated with a given PO item used for looking up project-scoped
   * WBS (phase code) tags.
   */
  project?: Maybe<Project>;
  quantity: Scalars["Decimal"]["output"];
  remainingAmount?: Maybe<Scalars["Decimal"]["output"]>;
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
  taxAmount: Scalars["Decimal"]["output"];
  taxType?: Maybe<TaxType>;
  /**
   * The sum of dollar amounts of invoiced release items from unexported invoices
   * covering this PO item.
   * This field is only populated in the results of the invoiceValidation query.
   */
  unexportedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  unitCost: Scalars["Decimal"]["output"];
  uom: Scalars["String"]["output"];
  wbsTag?: Maybe<ProjectTag>;
  zone?: Maybe<Zone>;
};

export type ExternalPoItemGroups = {
  __typename?: "ExternalPOItemGroups";
  /**
   * Items identified as additional charges based on their cost code and cost
   * type.
   */
  additionalCharges: Array<ExternalPoItem>;
  /**
   * Items of a basic (cost code-aggregated) PO.
   * Mutually exclusive with all other groups.
   */
  aggregatedItems: Array<ExternalPoItem>;
  importPossibility: ExternalPoImportPossibility;
  /** Items that can be imported as-is into a release. */
  importable: Array<ExternalPoItem>;
  /** Items, cost code of which is not connected to an FM cost code. */
  missingCostCode: Array<ExternalPoItem>;
  /**
   * Items, cost code x cost type combination of which does not have a corresponding
   * project tag.
   */
  missingTag: Array<ExternalPoItem>;
  /**
   * Items identified as additional charges based on their cost code and cost
   * type.
   */
  salesTax: Array<ExternalPoItem>;
  taxAmount: Scalars["Decimal"]["output"];
  taxCode?: Maybe<TaxCode>;
  taxCodeId?: Maybe<Scalars["ID"]["output"]>;
  taxType?: Maybe<TaxType>;
  /**
   * Items, cost type x cost code combination of which is not supported given the
   * source system settings.
   */
  unsupportedCostType: Array<ExternalPoItem>;
};

export type ExternalPoQueryInput = {
  externalId?: InputMaybe<Scalars["ID"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  /** Some source systems (e.g. Procore) require the project ID to be present. */
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * ID of the release type used for interpreting contents of the POs.
   *
   * When unspecified, the org-level default release type is used.
   */
  releaseTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceSystem: SourceSystem;
};

export type ExternalPoType = ExternalNode & {
  __typename?: "ExternalPOType";
  externalId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type ExternalPOsQueryInput = {
  includeNonJobPOs?: Scalars["Boolean"]["input"];
  maxDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /** The page number to return. */
  page: Scalars["Int"]["input"];
  /** The number of items per page. */
  perPage: Scalars["Int"]["input"];
  /**
   * ID of a FM project.
   *
   * Required for PROCORE and CMiC.
   */
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sellerOrgLocationID?: InputMaybe<Scalars["ID"]["input"]>;
  sourceSystem: SourceSystem;
};

export type ExternalPaymentTerms = ExternalNode &
  Node & {
    __typename?: "ExternalPaymentTerms";
    daysUntilDue?: Maybe<Scalars["Int"]["output"]>;
    description: Scalars["String"]["output"];
    discountAddedDays?: Maybe<Scalars["Int"]["output"]>;
    discountAddedMonths?: Maybe<Scalars["Int"]["output"]>;
    discountCutoffDay?: Maybe<Scalars["Int"]["output"]>;
    discountDay?: Maybe<Scalars["Int"]["output"]>;
    discountPercentage?: Maybe<Scalars["Decimal"]["output"]>;
    discountType: ExternalPaymentTermsDiscountType;
    dueCutoffDay?: Maybe<Scalars["Int"]["output"]>;
    dueDay?: Maybe<Scalars["Int"]["output"]>;
    dueType: ExternalPaymentTermsDueType;
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    monthsUntilDue?: Maybe<Scalars["Int"]["output"]>;
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    sourceUpdatedAt: Scalars["Timestamp"]["output"];
  };

export enum ExternalPaymentTermsDiscountType {
  AddNDays = "ADD_N_DAYS",
  AddNMonths = "ADD_N_MONTHS",
  Never = "NEVER",
  NthDayCurrentMonth = "NTH_DAY_CURRENT_MONTH",
  NthDayNextMonth = "NTH_DAY_NEXT_MONTH",
}

export enum ExternalPaymentTermsDueType {
  AddNDays = "ADD_N_DAYS",
  AddNMonths = "ADD_N_MONTHS",
  NthDayCurrentMonth = "NTH_DAY_CURRENT_MONTH",
  NthDayNextMonth = "NTH_DAY_NEXT_MONTH",
  UponReceipt = "UPON_RECEIPT",
}

export type ExternalProject = ExternalNode &
  Node & {
    __typename?: "ExternalProject";
    address?: Maybe<ExternalAddress>;
    amount?: Maybe<Scalars["Decimal"]["output"]>;
    /**
     * Indicates that a given external project has been connected to a project in
     * Field Materials (even if the user does not have access to it).
     */
    connected: Scalars["Boolean"]["output"];
    /**
     * Returns the list of estimates associated with this external project.
     *
     * WARNING: this edge is expensive and should only be used for resolving a
     * single external project.
     */
    estimates: Array<ExternalEstimate>;
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    number?: Maybe<Scalars["String"]["output"]>;
    /**
     * FM project that is connected (mapped) to this external project.
     * Returns null if an external project is not connected or the user does not
     * have access to the connected project.
     */
    project?: Maybe<Project>;
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    sourceUpdatedAt: Scalars["Timestamp"]["output"];
    startDate?: Maybe<Scalars["Timestamp"]["output"]>;
    status?: Maybe<Scalars["String"]["output"]>;
  };

export type ExternalProjectConnection = Connection & {
  __typename?: "ExternalProjectConnection";
  edges: Array<ExternalProjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ExternalProjectEdge = Edge & {
  __typename?: "ExternalProjectEdge";
  cursor: Scalars["String"]["output"];
  node: ExternalProject;
};

export type ExternalQueryInput = {
  /** The page number to return. */
  page: Scalars["Int"]["input"];
  /** The number of items per page. */
  perPage: Scalars["Int"]["input"];
  sourceSystem: SourceSystem;
};

export type ExternalServiceCode = ExternalNode &
  Node & {
    __typename?: "ExternalServiceCode";
    code: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
  };

export type ExternalSubJob = ExternalNode &
  Node & {
    __typename?: "ExternalSubJob";
    code: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    projectExternalId: Scalars["ID"]["output"];
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
  };

export type ExternalTaxCode = ExternalNode & {
  __typename?: "ExternalTaxCode";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  externalId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
  totalRate: Scalars["Decimal"]["output"];
};

export type ExternalVendor = ExternalNode &
  Node & {
    __typename?: "ExternalVendor";
    address?: Maybe<ExternalAddress>;
    alternateName?: Maybe<Scalars["String"]["output"]>;
    /**
     * daysUntilDue is a SAGE100 specific field
     * @deprecated use externalPaymentTerms
     */
    daysUntilDue?: Maybe<Scalars["Int"]["output"]>;
    deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
    email?: Maybe<Scalars["String"]["output"]>;
    externalId: Scalars["ID"]["output"];
    externalPaymentTerms?: Maybe<ExternalPaymentTerms>;
    id: Scalars["ID"]["output"];
    insurances: Array<ExternalInsurance>;
    name: Scalars["String"]["output"];
    /** @deprecated use orgPreferredVendors */
    orgPreferredVendor?: Maybe<OrgPreferredVendor>;
    orgPreferredVendors: Array<OrgPreferredVendor>;
    /** the default terms for the vendor */
    paymentTermsDescription?: Maybe<Scalars["String"]["output"]>;
    paymentTermsExternalId?: Maybe<Scalars["ID"]["output"]>;
    phone?: Maybe<Scalars["String"]["output"]>;
    sourceId: Scalars["String"]["output"];
    sourceSystem: SourceSystem;
    sourceUpdatedAt: Scalars["Timestamp"]["output"];
  };

export type ExternalVendorConnection = Connection & {
  __typename?: "ExternalVendorConnection";
  edges: Array<ExternalVendorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ExternalVendorEdge = Edge & {
  __typename?: "ExternalVendorEdge";
  cursor: Scalars["String"]["output"];
  node: ExternalVendor;
};

export type ExternalWarehouse = ExternalNode & {
  __typename?: "ExternalWarehouse";
  address?: Maybe<ExternalAddress>;
  code: Scalars["String"]["output"];
  externalId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type Facet = Node & {
  __typename?: "Facet";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  values: Array<FacetValue>;
};

export type FacetValue = Node & {
  __typename?: "FacetValue";
  count?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type FavoriteCombination = Node & {
  __typename?: "FavoriteCombination";
  id: Scalars["ID"]["output"];
  items: Array<FavoriteItem>;
  name: Scalars["String"]["output"];
  organizationId: Scalars["ID"]["output"];
};

export type FavoriteItem = Node & {
  __typename?: "FavoriteItem";
  combinationId?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  masterProduct: Product;
  masterSku?: Maybe<Sku>;
};

/** Custom field mapping for an input file. */
export type FieldMapping = {
  __typename?: "FieldMapping";
  /** The sequential index of this field, as retrieved from the preview. */
  index: Scalars["Int"]["output"];
  /**
   * The name of this field, as retrieved from the preview.
   *
   * This value is preferred over the index, when available.
   */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The property being mapped. */
  property: FieldMappingTargetProperty;
};

/** Custom field mapping for an input file. */
export type FieldMappingInput = {
  /** The sequential index of this field, as retrieved from the preview. */
  index: Scalars["Int"]["input"];
  /**
   * The name of this field, as retrieved from the preview.
   *
   * This value is preferred over the index, when available.
   */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** The property being mapped. */
  property: FieldMappingTargetProperty;
};

/** A material property conveyed via a particular field. */
export enum FieldMappingTargetProperty {
  CostCode = "COST_CODE",
  Manufacturer = "MANUFACTURER",
  Name = "NAME",
  Notes = "NOTES",
  PartNumber = "PART_NUMBER",
  Quantity = "QUANTITY",
  SectionName = "SECTION_NAME",
  Tag = "TAG",
  UnitPrice = "UNIT_PRICE",
  Uom = "UOM",
  Vendor = "VENDOR",
  Zone = "ZONE",
}

export type FieldPreview = {
  __typename?: "FieldPreview";
  /**
   * A hint towards mapping this field to a property, as identified by the
   * server.
   */
  hint?: Maybe<FieldMappingTargetProperty>;
  /**
   * The name of this field.
   *
   * If one field has a header, all fields will have a header.
   *
   * If this is missing the client can use any convention for dummy field names
   * to be presented to the user.
   */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Records for this field. Header not included. */
  records: Array<Scalars["String"]["output"]>;
};

export type FieldsPreviewInput = {
  /** The file that is to be previewd. */
  file: Scalars["Upload"]["input"];
  /** The type of the file to be previewed. */
  fileType: FileType;
  /** How many records to preview from the file? */
  records: Scalars["Int"]["input"];
};

/** An input file type. */
export enum FileType {
  Csv = "CSV",
  Excel = "EXCEL",
  Image = "IMAGE",
  Pdf = "PDF",
}

export type FireproofingApplication = {
  __typename?: "FireproofingApplication";
  name: Scalars["String"]["output"];
};

export type FireproofingColor = {
  __typename?: "FireproofingColor";
  name: Scalars["String"]["output"];
};

export type FireproofingComposition = {
  __typename?: "FireproofingComposition";
  name: Scalars["String"]["output"];
};

export type FireproofingIndustry = {
  __typename?: "FireproofingIndustry";
  name: Scalars["String"]["output"];
};

export type FireproofingMounting = {
  __typename?: "FireproofingMounting";
  name: Scalars["String"]["output"];
};

export type FireproofingOpening = {
  __typename?: "FireproofingOpening";
  name: Scalars["String"]["output"];
};

export type FireproofingProductDetail = Node &
  ProductDetail & {
    __typename?: "FireproofingProductDetail";
    applications: Array<FireproofingApplication>;
    caseHeight?: Maybe<Scalars["Float"]["output"]>;
    caseLength?: Maybe<Scalars["Float"]["output"]>;
    caseWidth?: Maybe<Scalars["Float"]["output"]>;
    colors: Array<FireproofingColor>;
    compositions: Array<FireproofingComposition>;
    fireRating?: Maybe<Scalars["Int"]["output"]>;
    grossWeight: Scalars["Float"]["output"];
    height?: Maybe<Scalars["Float"]["output"]>;
    id: Scalars["ID"]["output"];
    inServiceTemperature?: Maybe<Scalars["String"]["output"]>;
    industries: Array<FireproofingIndustry>;
    length?: Maybe<Scalars["Float"]["output"]>;
    mountings: Array<FireproofingMounting>;
    openings: Array<FireproofingOpening>;
    packaging?: Maybe<Scalars["String"]["output"]>;
    productId: Scalars["ID"]["output"];
    properties: Array<FireproofingProperty>;
    shape?: Maybe<Scalars["String"]["output"]>;
    width?: Maybe<Scalars["Float"]["output"]>;
  };

export type FireproofingProperty = {
  __typename?: "FireproofingProperty";
  name: Scalars["String"]["output"];
};

export type FoundationSettings = Node & {
  __typename?: "FoundationSettings";
  GLExpense?: Maybe<Scalars["String"]["output"]>;
  defaultCostCode?: Maybe<CostCode>;
  defaultPOFormat?: Maybe<PoFormat>;
  defaultToWarehouse?: Maybe<Scalars["Boolean"]["output"]>;
  defaultWarehouseNumber?: Maybe<Scalars["String"]["output"]>;
  distributeAdditionalCharges?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  progress: IntegrationMappingProgress;
  taxAuthorities: Array<FoundationTaxAuthority>;
  warehouseGLExpense?: Maybe<Scalars["String"]["output"]>;
};

export type FoundationTaxAuthority = {
  __typename?: "FoundationTaxAuthority";
  code: Scalars["String"]["output"];
  rate: Scalars["Decimal"]["output"];
};

export type FoundationTaxAuthorityInput = {
  code: Scalars["String"]["input"];
  rate: Scalars["Decimal"]["input"];
};

export type ImportAllowanceInput = {
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type ImportBuyoutInput = {
  file: Scalars["Upload"]["input"];
  integration: IntegrationType;
  metadata: CreateBuyoutInput;
};

export type ImportComplianceGroupsInput = {
  externalComplianceGroupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportComplianceGroupsOutput = {
  __typename?: "ImportComplianceGroupsOutput";
  created: Array<ComplianceGroup>;
  mapped: Array<ComplianceGroup>;
  skipped: Array<ComplianceGroup>;
};

export type ImportCostCodesInput = {
  /**
   * External IDs of cost codes to be imported
   *
   * When unspecified, all cost codes from the specified source system will be
   * imported.
   */
  externalCostCodeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportCostCodesOutput = {
  __typename?: "ImportCostCodesOutput";
  /** List of newly created cost codes. */
  created: Array<CostCode>;
  /** List of imported cost codes that were mapped to existing cost codes. */
  mapped: Array<CostCode>;
  /**
   * List of cost codes that were skipped, because they were already imported and
   * mapped.
   */
  skipped: Array<CostCode>;
};

export type ImportCostTypesInput = {
  externalCostTypeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportCostTypesOutput = {
  __typename?: "ImportCostTypesOutput";
  created: Array<CostType>;
  mapped: Array<CostType>;
  skipped: Array<CostType>;
};

export type ImportEquipmentItemsInput = {
  externalEquipmentItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportEquipmentItemsOutput = {
  __typename?: "ImportEquipmentItemsOutput";
  created: Array<EquipmentItem>;
  mapped: Array<EquipmentItem>;
  skipped: Array<EquipmentItem>;
};

/**
 * An error that occured during the import of a file. This is specific to a line
 * in the file.
 */
export type ImportError = {
  __typename?: "ImportError";
  /** The error message. */
  errorMessage: Scalars["String"]["output"];
  /** The line number of the item in the input file. */
  lineNumber: Scalars["Int"]["output"];
};

export type ImportEstimateInput = {
  clearAllEstimatedItems: Scalars["Boolean"]["input"];
  estimateExternalId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
  tags: Array<Scalars["ID"]["input"]>;
  /** Zone ID to to specify the target zone to which all items should be imported. */
  zoneId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImportOrgCatalogInput = {
  file: Scalars["Upload"]["input"];
  orgCatalogFormat: IntegrationType;
  orgId: Scalars["ID"]["input"];
};

export type ImportOrgMaterialsInput = {
  /**
   * External IDs of org materials to be imported
   *
   * When unspecified, all org materials from the specified source system will be
   * imported.
   */
  externalItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportOrgMaterialsOutput = {
  __typename?: "ImportOrgMaterialsOutput";
  /** List of newly created org materials. */
  created: Array<OrgMaterial>;
  /** List of imported org materials that were mapped to existing org materials. */
  mapped: Array<OrgMaterial>;
  /**
   * List of org materials that were skipped, because they were already imported
   * and mapped.
   */
  skipped: Array<OrgMaterial>;
};

export type ImportPaymentMethodsInput = {
  externalBankAccountIds: Array<Scalars["ID"]["input"]>;
  sourceSystem: SourceSystem;
};

export type ImportPaymentMethodsOutput = {
  __typename?: "ImportPaymentMethodsOutput";
  created: Array<PaymentMethod>;
  mapped: Array<PaymentMethod>;
  skipped: Array<PaymentMethod>;
};

export type ImportProjectTagsInput = {
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type ImportServiceCodesInput = {
  externalServiceCodeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportServiceCodesOutput = {
  __typename?: "ImportServiceCodesOutput";
  created: Array<ServiceCode>;
  mapped: Array<ServiceCode>;
  skipped: Array<ServiceCode>;
};

export type ImportTaxCodesInput = {
  externalTaxCodeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceSystem: SourceSystem;
};

export type ImportTaxCodesOutput = {
  __typename?: "ImportTaxCodesOutput";
  created: Array<TaxCode>;
  mapped: Array<TaxCode>;
  skipped: Array<TaxCode>;
};

export type ImportVendorInput = {
  /**
   * If specified, overrides the address specified at the ExternalVendor level.
   * Required if the ExternalVendor address is missing or incomplete.
   */
  address?: InputMaybe<AddressInput>;
  externalVendorId: Scalars["ID"]["input"];
};

export type ImportVendorsInput = {
  system: SourceSystem;
  vendors: Array<ImportVendorInput>;
};

export type ImportVendorsOutput = {
  __typename?: "ImportVendorsOutput";
  created: Array<OrgPreferredVendor>;
  mapped: Array<OrgPreferredVendor>;
  skipped: Array<OrgPreferredVendor>;
};

export type ImportZonesInput = {
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type InitializeSourceSystemConnectionInput = {
  orgId: Scalars["ID"]["input"];
  system: SourceSystem;
};

export type InstallationType = {
  __typename?: "InstallationType";
  name: Scalars["String"]["output"];
};

export type Instruction = {
  __typename?: "Instruction";
  assetUrls: Array<Scalars["String"]["output"]>;
  assets: Array<Asset>;
  text: Scalars["String"]["output"];
};

export type InstructionInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type InsulationApplication = {
  __typename?: "InsulationApplication";
  name: Scalars["String"]["output"];
};

export type InsulationProductDetail = Node &
  ProductDetail & {
    __typename?: "InsulationProductDetail";
    applications: Array<InsulationApplication>;
    id: Scalars["ID"]["output"];
    length?: Maybe<Scalars["Int"]["output"]>;
    productId: Scalars["ID"]["output"];
    rValue: Scalars["Int"]["output"];
    thickness: Scalars["Float"]["output"];
    type: Scalars["String"]["output"];
    width?: Maybe<Scalars["Float"]["output"]>;
  };

export type IntegrationMappingProgress = {
  __typename?: "IntegrationMappingProgress";
  costCodes: MappingProgress;
  costTypes: MappingProgress;
  orgMaterials: MappingProgress;
  projects: MappingProgress;
  releaseTypes: MappingProgress;
  vendors: MappingProgress;
};

export type IntegrationSettings = {
  __typename?: "IntegrationSettings";
  accounting: Array<AccountingSettings>;
  /** @deprecated use accounting instead */
  foundation: FoundationSettings;
  org: Organization;
  sourceSystems: Array<SourceSystemSettings>;
};

export enum IntegrationType {
  /** Let the user map fields to properties. */
  Custom = "CUSTOM",
  Edge = "EDGE",
  Equotes = "EQUOTES",
  Foundation = "FOUNDATION",
  Oncenter = "ONCENTER",
  Sage300 = "SAGE300",
}

/**
 * InventoryItem defines an org material + UOM combination that is tracked
 * in the inventory.
 */
export type InventoryItem = Node & {
  __typename?: "InventoryItem";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  orgMaterial: OrgMaterial;
  /**
   * Items with the same UOM and org material as the InventoryItem belonging
   * to warehouse-directed releases in REQUESTED, SCHEDULED or
   * PARTIALLY_RECEIVED status.
   *
   * In case of PARTIALLY_RECEIVED releases, only such items are returned,
   * received quantities of which (computed using PROCESSED delivery slips)
   * are lower than ReleaseItem.quantity.
   */
  pendingInflows: Array<ReleaseItem>;
  /**
   * Items with the same UOM and org material as the InventoryItem belonging
   * to warehouse-sourced releases in REQUESTED, SCHEDULED or PARTIALLY_RECEIVED
   * status.
   *
   * In case of PARTIALLY_RECEIVED releases, only such items are returned,
   * received quantities of which (computed using PROCESSED delivery slips)
   * are lower than ReleaseItem.quantity.
   */
  pendingOutflows: Array<ReleaseItem>;
  /**
   * Returns all current InventoryItemState entries for a given inventory item,
   * optionally filtered by a specific warehouse.
   * The results returned by this edge are sorted by inventory FIFO order.
   */
  state: Array<InventoryItemState>;
  uom: Uom;
};

/**
 * InventoryItem defines an org material + UOM combination that is tracked
 * in the inventory.
 */
export type InventoryItemPendingInflowsArgs = {
  warehouseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/**
 * InventoryItem defines an org material + UOM combination that is tracked
 * in the inventory.
 */
export type InventoryItemPendingOutflowsArgs = {
  warehouseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/**
 * InventoryItem defines an org material + UOM combination that is tracked
 * in the inventory.
 */
export type InventoryItemStateArgs = {
  warehouseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type InventoryItemConnection = Connection & {
  __typename?: "InventoryItemConnection";
  edges: Array<InventoryItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InventoryItemEdge = Edge & {
  __typename?: "InventoryItemEdge";
  cursor: Scalars["String"]["output"];
  node: InventoryItem;
};

export type InventoryItemState = Node & {
  __typename?: "InventoryItemState";
  id: Scalars["ID"]["output"];
  /**
   * The inflow that resulted in this InventoryItemState entry
   * being recorded.
   */
  inflowTransactionItem: InventoryTransactionItem;
  item: InventoryItem;
  /**
   * The position of the inflowTransactionItem within its parent
   * InventoryTransaction item.
   *
   * This field is used to sort InventoryItemState entries for a given inventory
   * item by (transactionNumber, position), achieving FIFO outflow ordering.
   */
  itemPosition: Scalars["Int"]["output"];
  /**
   * Original quantity of the inflow InventoryTransactionItem that resulted in this
   * InventoryItemState being recorded.
   */
  originalQuantity: Scalars["Decimal"]["output"];
  /** The associated outflow operations. */
  outflowTransactionItems?: Maybe<Array<InventoryTransactionItem>>;
  /**
   * Quantity of the inflow InventoryTransactionItem minus what was already
   * released via outflow operation(s).
   */
  remainingQuantity: Scalars["Decimal"]["output"];
  /**
   * The number of the associated inventory transaction, that resulted in
   * this InventoryItemState entry being recorded.
   */
  transactionNumber: Scalars["Int"]["output"];
  unitPrice: Scalars["Decimal"]["output"];
  warehouse: Warehouse;
};

export type InventorySettings = {
  __typename?: "InventorySettings";
  enabled: Scalars["Boolean"]["output"];
};

export type InventoryTransaction = Node & {
  __typename?: "InventoryTransaction";
  /**
   * Creation timestamp, which is also a timestamp at which the transaction
   * got recorded in the ledger.
   */
  createdAt: Scalars["Timestamp"]["output"];
  createdBy: User;
  date: Scalars["Timestamp"]["output"];
  /**
   * Delivery slip, processing of which resulted in this InventoryTransaction
   * being recorded.
   * Only applicable to INFLOW and OUTFLOW types.
   */
  deliverySlip?: Maybe<DeliverySlip>;
  /** When empty the transaction is an OUTFLOW */
  destination?: Maybe<Warehouse>;
  id: Scalars["ID"]["output"];
  instructions?: Maybe<Instruction>;
  items: Array<InventoryTransactionItem>;
  /** Auto-incremented, org-scoped sequence number. */
  number: Scalars["Int"]["output"];
  /** When empty the transaction is an INFLOW */
  origin?: Maybe<Warehouse>;
  /**
   * When type is:
   * INFLOW - origin is empty and destination is specified
   * OUTFLOW - origin is specified and destination is empty
   * TRANSFER - both origin and destination are specified
   * ADJUSTMENT - both origin and destination are specified and are pointing to the same warehouse
   */
  type: InventoryTransactionType;
};

export type InventoryTransactionItemsArgs = {
  inventoryItemId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InventoryTransactionConnection = Connection & {
  __typename?: "InventoryTransactionConnection";
  edges: Array<InventoryTransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InventoryTransactionEdge = Edge & {
  __typename?: "InventoryTransactionEdge";
  cursor: Scalars["String"]["output"];
  node: InventoryTransaction;
};

export type InventoryTransactionItem = Node & {
  __typename?: "InventoryTransactionItem";
  destination?: Maybe<Warehouse>;
  id: Scalars["ID"]["output"];
  item: InventoryItem;
  notes?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<Warehouse>;
  position: Scalars["Int"]["output"];
  quantity: Scalars["Decimal"]["output"];
  transaction: InventoryTransaction;
  unitPrice: Scalars["Decimal"]["output"];
};

export enum InventoryTransactionType {
  Adjustment = "ADJUSTMENT",
  Inflow = "INFLOW",
  Outflow = "OUTFLOW",
  Transfer = "TRANSFER",
}

export type Invoice = Node & {
  __typename?: "Invoice";
  accountingDate?: Maybe<Scalars["Timestamp"]["output"]>;
  approvedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  approvedBy?: Maybe<User>;
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  archivedBy?: Maybe<User>;
  /** @deprecated use duplicates instead */
  archivedDuplicates: Array<Invoice>;
  /**
   * The autoMatched flag is set to true if the invoice was automatically matched
   * to a release.
   *
   * It is reset to false when the invoice is updated.
   */
  autoMatched: Scalars["Boolean"]["output"];
  charges: Array<InvoiceCharge>;
  chargesAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** Dynamically computed fields */
  computedSubtotal: Scalars["Decimal"]["output"];
  /** Only for receipts. */
  costCode?: Maybe<CostCode>;
  createdAt: Scalars["Timestamp"]["output"];
  createdBy?: Maybe<User>;
  /** a text description used on export */
  description?: Maybe<Scalars["String"]["output"]>;
  discountAmount?: Maybe<Scalars["Decimal"]["output"]>;
  discountDate?: Maybe<Scalars["Timestamp"]["output"]>;
  discountPercentage?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated use discountedTotal instead */
  discountedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** The computed total amount but with discounts applied. */
  discountedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  /** invoice due date read by OCR */
  documentDueDate?: Maybe<Scalars["Timestamp"]["output"]>;
  documents: Array<InvoiceDocument>;
  downloadedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  downloadedBy?: Maybe<User>;
  dueDate?: Maybe<Scalars["Timestamp"]["output"]>;
  duplicates: Array<Invoice>;
  email?: Maybe<InvoiceEmail>;
  equipmentItem?: Maybe<EquipmentItem>;
  exportStatus?: Maybe<InvoiceExportStatus>;
  exportedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  exportedBy?: Maybe<User>;
  exportedTo?: Maybe<IntegrationType>;
  folders: Array<InvoiceFolder>;
  hasRelease: Scalars["Boolean"]["output"];
  headerOnly: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  invoicedReleaseItems: Array<InvoicedReleaseItem>;
  isDeliverySlip: Scalars["Boolean"]["output"];
  issueDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /** @deprecated use issues instead */
  issueDescription?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use issues instead */
  issueReportDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /** @deprecated use issues instead */
  issueResponseDate?: Maybe<Scalars["Timestamp"]["output"]>;
  issues: Array<InvoiceIssue>;
  /** Invoice items populated with OCR results. */
  items: Array<InvoiceItem>;
  /**
   * The user that created the internal kickback report.
   * @deprecated use kickbacks instead
   */
  kickbackBy?: Maybe<User>;
  /** @deprecated use kickbacks instead */
  kickbackDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /** @deprecated use kickbacks instead */
  kickbackDescription?: Maybe<Scalars["String"]["output"]>;
  /**
   * A list of users within the org location of the invoice that are eligible to
   * be assigned the internal kickback report.
   */
  kickbackEligibleUsers: Array<User>;
  /** @deprecated use kickbacks instead */
  kickbackResolutionDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /** @deprecated use kickbacks instead */
  kickbackResolutionDescription?: Maybe<Scalars["String"]["output"]>;
  /**
   * The invoice can be kickbacked to multiple users. KickbackResolvedBy stores the user
   * that resolved the kickback.
   * @deprecated use kickbacks instead
   */
  kickbackResolvedBy?: Maybe<User>;
  /**
   * The user that the internal kickback report was assigned to.
   * @deprecated use kickbacks instead
   */
  kickbackTo?: Maybe<Array<User>>;
  kickbacks: Array<InvoiceKickback>;
  link?: Maybe<InvoiceLink>;
  notes?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  org: Organization;
  /**
   * Whether or not the invoice appears to be a duplicate of another invoice that
   * is not assigned to a release.
   * May only return true for invoices not linked to a release.
   */
  orgDuplicate: Scalars["Boolean"]["output"];
  orgLocation?: Maybe<OrgLocation>;
  paidAt?: Maybe<Scalars["Timestamp"]["output"]>;
  paidBy?: Maybe<User>;
  paymentMethod?: Maybe<PaymentMethod>;
  permissions: InvoicePermissions;
  /**
   * Whether or not the PO with the specified number exists in the connected ERP.
   *
   * This edge is served from the external PO cache and therefore should not
   * increase query latency.
   */
  poExists?: Maybe<Scalars["Boolean"]["output"]>;
  poNumber?: Maybe<Scalars["String"]["output"]>;
  postingDate?: Maybe<Scalars["Timestamp"]["output"]>;
  prePaid: Scalars["Boolean"]["output"];
  /** Hints computed based on matching invoice OCR results with existing models. */
  predictedProject?: Maybe<Project>;
  predictedSellerOrgLocation?: Maybe<OrgLocation>;
  project?: Maybe<Project>;
  /** @deprecated use issues instead */
  rejectionReason?: Maybe<Scalars["String"]["output"]>;
  release?: Maybe<Release>;
  /** Compute hints for matching invoice items with release items. */
  releaseItemHints: Array<ReleaseItemHint>;
  /** @deprecated use issues instead */
  resolutionDescription?: Maybe<Scalars["String"]["output"]>;
  /** When specified, takes precedence over retentionPercentage. */
  retentionAmount?: Maybe<Scalars["Decimal"]["output"]>;
  retentionPercentage?: Maybe<Scalars["Decimal"]["output"]>;
  sellerOrgLocation?: Maybe<OrgLocation>;
  serviceCode?: Maybe<ServiceCode>;
  status: InvoiceStatus;
  subtotal?: Maybe<Scalars["Decimal"]["output"]>;
  taxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** Total amount of the invoice computed as subtotal + chargesAmount + taxAmount. */
  total?: Maybe<Scalars["Decimal"]["output"]>;
  type: InvoiceType;
  /**
   * Returns true if an invoice with the same number exists in the org and is
   * associated with the same vendor.
   * May only return true for invoices linked to a release.
   */
  vendorDuplicate: Scalars["Boolean"]["output"];
};

export type InvoiceApprovalRole = {
  __typename?: "InvoiceApprovalRole";
  maxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  notificationThreshold?: Maybe<Scalars["Decimal"]["output"]>;
  role: OrgLocationRole;
};

export type InvoiceApprovalRoleInput = {
  maxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  notificationThreshold?: InputMaybe<Scalars["Decimal"]["input"]>;
  role: OrgLocationRole;
};

export type InvoiceApprovalSettings = {
  __typename?: "InvoiceApprovalSettings";
  roles: Array<InvoiceApprovalRole>;
};

export type InvoiceCharge = Node & {
  __typename?: "InvoiceCharge";
  amount: Scalars["Decimal"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type InvoiceConnection = Connection & {
  __typename?: "InvoiceConnection";
  edges: Array<InvoiceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InvoiceDocument = Node & {
  __typename?: "InvoiceDocument";
  asset: Asset;
  /** @deprecated use asset instead */
  assetUrl: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invoice: Invoice;
  pageRange: PageRange;
  version: Scalars["Int"]["output"];
};

export type InvoiceEdge = Edge & {
  __typename?: "InvoiceEdge";
  cursor: Scalars["String"]["output"];
  node: Invoice;
};

export type InvoiceEmail = Node & {
  __typename?: "InvoiceEmail";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** @deprecated use attachments instead */
  attachmentUrls: Array<Scalars["String"]["output"]>;
  attachments: Array<InvoiceEmailAttachment>;
  content: Scalars["String"]["output"];
  from: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /** List of invoices that were created from the incoming email. */
  invoices: Array<Invoice>;
  messageId: Scalars["String"]["output"];
  /** Org preferred vendor matched with the incoming invoice email. */
  orgPreferredVendor?: Maybe<OrgPreferredVendor>;
  receivedAt: Scalars["Timestamp"]["output"];
  status: InvoiceEmailStatus;
  subject: Scalars["String"]["output"];
  to: Scalars["String"]["output"];
};

export type InvoiceEmailAttachment = Node & {
  __typename?: "InvoiceEmailAttachment";
  asset: Asset;
  id: Scalars["ID"]["output"];
  invoiceDocuments: Array<InvoiceDocument>;
  rejectedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type InvoiceEmailConnection = Connection & {
  __typename?: "InvoiceEmailConnection";
  edges: Array<InvoiceEmailEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InvoiceEmailEdge = Edge & {
  __typename?: "InvoiceEmailEdge";
  cursor: Scalars["String"]["output"];
  node: InvoiceEmail;
};

export type InvoiceEmailStats = {
  __typename?: "InvoiceEmailStats";
  /**
   * It's the number of distinct non-archived invoice emails that are either in AWAITING_APPROVAL status or
   * has at least one non-archived invoice that does not have a release assigned to it
   */
  requireAttention: Scalars["Int"]["output"];
};

export enum InvoiceEmailStatus {
  AwaitingApproval = "AWAITING_APPROVAL",
  Processed = "PROCESSED",
}

export enum InvoiceEvent {
  Approved = "APPROVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  IssuesCorrected = "ISSUES_CORRECTED",
  IssuesRejected = "ISSUES_REJECTED",
  IssuesReported = "ISSUES_REPORTED",
  IssuesUpdated = "ISSUES_UPDATED",
  KickbackCommentCreated = "KICKBACK_COMMENT_CREATED",
  KickbackCommentRemoved = "KICKBACK_COMMENT_REMOVED",
  KickbackCommentUpdated = "KICKBACK_COMMENT_UPDATED",
  KickbackRequested = "KICKBACK_REQUESTED",
  KickbackResolved = "KICKBACK_RESOLVED",
  KickbackUpdated = "KICKBACK_UPDATED",
  Paid = "PAID",
  PaymentDue = "PAYMENT_DUE",
}

export enum InvoiceExportStatus {
  Exported = "EXPORTED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  NotExported = "NOT_EXPORTED",
}

export type InvoiceFolder = Node & {
  __typename?: "InvoiceFolder";
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type InvoiceIssue = Node & {
  __typename?: "InvoiceIssue";
  contractorAssets: Array<Asset>;
  createdAt: Scalars["Timestamp"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  rejectionReason?: Maybe<Scalars["String"]["output"]>;
  /** The user that created the issue report. */
  reportedBy: User;
  resolutionDescription?: Maybe<Scalars["String"]["output"]>;
  respondedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  vendorAssets: Array<Asset>;
};

/** Invoice item represents a single line item on an invoice. */
export type InvoiceItem = Node & {
  __typename?: "InvoiceItem";
  UOM: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  partNumber?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use quantityDecimal */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  unitPrice: Scalars["Decimal"]["output"];
};

export type InvoiceKickback = Node & {
  __typename?: "InvoiceKickback";
  assets: Array<Asset>;
  /** The users that the internal kickback report was assigned to. */
  assignees: Array<User>;
  comments: Array<InvoiceKickbackComment>;
  createdAt: Scalars["Timestamp"]["output"];
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /** The user that created the internal kickback report. */
  reportedBy: User;
  resolutionAssets?: Maybe<Array<Asset>>;
  resolutionDescription?: Maybe<Scalars["String"]["output"]>;
  resolvedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * The invoice can be kickbacked to multiple users. KickbackResolvedBy stores the user
   * that resolved the kickback.
   */
  resolvedBy?: Maybe<User>;
};

export type InvoiceKickbackComment = Node & {
  __typename?: "InvoiceKickbackComment";
  assets: Array<Asset>;
  createdAt: Scalars["Timestamp"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  text?: Maybe<Scalars["String"]["output"]>;
};

export type InvoiceLink = Node & {
  __typename?: "InvoiceLink";
  batch?: Maybe<ExternalBatch>;
  batchId?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["Timestamp"]["output"];
  createdBy?: Maybe<User>;
  exportedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * External Expense that was created as a result of exporting the receipt-type invoice.
   * This resolver does a request to the external system to get the entity.
   */
  externalExpense?: Maybe<ExternalExpense>;
  externalExpenseId?: Maybe<Scalars["ID"]["output"]>;
  externalInvoice?: Maybe<ExternalInvoice>;
  externalInvoiceId?: Maybe<Scalars["ID"]["output"]>;
  failedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  failedMessage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoice: Invoice;
  invoiceId: Scalars["ID"]["output"];
  sourceSystem: SourceSystem;
};

export type InvoicePermissions = {
  __typename?: "InvoicePermissions";
  approve: AuthorizationStatus;
  archive: AuthorizationStatus;
  edit: AuthorizationStatus;
  export: AuthorizationStatus;
  respond: AuthorizationStatus;
};

export type InvoicePermissionsApproveArgs = {
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InvoiceSettings = {
  __typename?: "InvoiceSettings";
  approval?: Maybe<InvoiceApprovalSettings>;
  /** default payment term in days. */
  defaultPaymentTerm?: Maybe<Scalars["Int"]["output"]>;
  /**
   * Invoice receiver address domain (non-mutable by the client).
   *
   * This value will be the same for all tenants in the environment
   * (e.g. invoices.fieldmaterials.com).
   */
  domain: Scalars["String"]["output"];
  /**
   * Domain of the invoice receiver address, after the @ sign
   * (e.g. fieldmaterials.com)
   */
  emailDomain?: Maybe<Scalars["String"]["output"]>;
  /**
   * Local part of the invoice receiver address, before the @ sign
   * (e.g. invoices)
   */
  localPart?: Maybe<Scalars["String"]["output"]>;
  requireCostCodesDuringApproval: Scalars["Boolean"]["output"];
  requireValidationDuringApproval: Scalars["Boolean"]["output"];
  vendorTermsOverrideDueDate: Scalars["Boolean"]["output"];
};

export type InvoiceStats = {
  __typename?: "InvoiceStats";
  emails: InvoiceEmailStats;
  pendingReceipts: Scalars["Int"]["output"];
};

export enum InvoiceStatus {
  Approved = "APPROVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  /** @deprecated this is no more used */
  InternalKickback = "INTERNAL_KICKBACK",
  IssuesReported = "ISSUES_REPORTED",
  Paid = "PAID",
}

export enum InvoiceType {
  Invoice = "INVOICE",
  Receipt = "RECEIPT",
}

export type InvoiceValidation = {
  __typename?: "InvoiceValidation";
  additionalChargesRemainingAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * PO item links of release items covered by the invoice, for which the cost
   * code of the release item does not match the cost codes of the PO item.
   * Only applicable if the PO is itemized.
   */
  costCodeMismatches: Array<PoItemLink>;
  /**
   * Cost codes from the release associated with the invoice, amounts of which
   * exceed the amounts of the corresponding cost codes in the PO.
   */
  costCodesExceedingPOAmounts: Array<CostCode>;
  /**
   * Cost codes present in the release associated with the invoice that are not
   * present in the PO.
   */
  costCodesMissingInPO: Array<CostCode>;
  /**
   * Cost codes present in the release associated with the invoice that are not
   * connected to their equivalents in the source system.
   */
  costCodesNotConnected: Array<CostCode>;
  /**
   * PO item links of release items covered by the invoice, for which the
   * description of the release item does not match the description of the PO item.
   * Treated as a warning that does not prevent the invoice from being exported.
   * Only applicable if the PO is itemized.
   */
  descriptionMismatches: Array<PoItemLink>;
  errors: Array<InvoiceValidationError>;
  /**
   * Release items, for which the corresponding PO items don't have enough
   * remaining amount left to be invoiced.
   */
  itemsExceedingRemainingAmount: Array<ReleaseItem>;
  /**
   * Release items, for which the corresponding PO items don't have enough
   * remaining amount minus the amount covered by unexported FM invoices
   * left to be invoiced.
   * Unlike itemsExceedingRemainingAmount, this is treated as a warning and not
   * a hard error.
   */
  itemsExceedingRemainingUnexportedAmount: Array<ReleaseItem>;
  /** Release items that are missing a cost code. */
  itemsMissingCostCodes: Array<ReleaseItem>;
  /**
   * Release items that are missing tags containing cost code + cost type info.
   * Only applicable when SourceSystemSettings.wbsTags is set to true.
   */
  itemsMissingTags: Array<ReleaseItem>;
  /**
   * Release items covered by the invoice that are missing POItemLinks.
   * Only applicable if the PO is itemized.
   */
  itemsNotConnected: Array<ReleaseItem>;
  /**
   * List of materials included in the release associated with the invoice that
   * are missing a cost code.
   * @deprecated use itemsMissingCostCodes
   */
  materialsMissingCostCodes: Array<OrgMaterial>;
  /**
   * Budget combinations that are not allowed for the project in the ERP.
   *
   * Only applicable to standalone invoice exports.
   */
  missingBudgetCombinations: Array<BudgetCombination>;
  /** External PO against which the invoice was validated (if present). */
  po?: Maybe<ExternalPo>;
  salesTaxRemainingAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** POItemLinks that are missing in the source system. */
  stalePOItemLinks: Array<PoItemLink>;
  /**
   * PO item links of release items covered by the invoice, for which the
   * UOM of the release item does not match the UOM of the PO item.
   * This also takes into account:
   * - UOM descriptions/mnemonics/alternative refs;
   * - material-specific UOM conversions;
   * - situations where the UOM is written in the item description (in brackets).
   * Treated as a warning that does not prevent the invoice from being exported.
   * Only applicable if the PO is itemized.
   */
  uomMismatches: Array<PoItemLink>;
  warnings: Array<InvoiceValidationWarning>;
  /**
   * Project tags representing WBS (cost code + cost type) combinations from the
   * release associated with the invoice, amounts of which exceed the amounts of
   * the corresponding WBS combinations in the PO.
   * Only applicable if the integration is configured to use WBS tags.
   */
  wbsExceedingPOAmounts: Array<ProjectTag>;
  /**
   * Project tags representing WBS (cost code + cost type) combinations that are
   * present in the release associated with the invoice, but are not present in
   * the PO.
   * Only applicable if the integration is configured to use WBS tags.
   */
  wbsMissingInPO: Array<ProjectTag>;
};

export enum InvoiceValidationError {
  /**
   * The item representing additional charges in the PO does not have enough
   * uninvoiced amount left.
   */
  AdditionalChargesItemExceedsRemainingAmount = "ADDITIONAL_CHARGES_ITEM_EXCEEDS_REMAINING_AMOUNT",
  /**
   * A line item representing additional charges is missing in the PO.
   * Applicable only if the integration is configured to list additional charges
   * as a separate line item.
   */
  AdditionalChargesItemMissing = "ADDITIONAL_CHARGES_ITEM_MISSING",
  /**
   * The amount of the exported invoice does not match the amount of the linked
   * PO in the source system.
   * Only applicable to invoices that reference itemized POs.
   */
  AmountMismatch = "AMOUNT_MISMATCH",
  /** The invoice does not have any InvoicedReleaseItems. */
  CoverageMissing = "COVERAGE_MISSING",
  /** The integration associated with the selected source system is not configured. */
  IntegrationNotConfigured = "INTEGRATION_NOT_CONFIGURED",
  /** Tax mode not configured correctly (ie Tax mode Column on a system that don't support it) */
  InvalidTaxMode = "INVALID_TAX_MODE",
  /** All release items covered by the invoice are non-taxable and it has a non-zero tax amount */
  InvoicedItemsNonTaxable = "INVOICED_ITEMS_NON_TAXABLE",
  /**
   * The invoice issue date is before the PO date.
   *
   * Only returned if the source system prohibits invoices with issue dates
   * preceding the PO date.
   */
  InvoiceIssueDateBeforePoDate = "INVOICE_ISSUE_DATE_BEFORE_PO_DATE",
  /** The invoice issue date is invalid, e.g. is far in the past or future. */
  InvoiceIssueDateInvalid = "INVOICE_ISSUE_DATE_INVALID",
  /** The invoice issue date is missing. */
  InvoiceIssueDateMissing = "INVOICE_ISSUE_DATE_MISSING",
  /** The invoice number is missing. */
  InvoiceNumberMissing = "INVOICE_NUMBER_MISSING",
  /** The invoice number is too long for the source system. */
  InvoiceNumberTooLong = "INVOICE_NUMBER_TOO_LONG",
  /**
   * An invoice with the same number for the same vendor has already been posted
   * in the source system and its total does not match the amount of the exported
   * invoice.
   */
  InvoicePostedMismatch = "INVOICE_POSTED_MISMATCH",
  /**
   * There is an unposted invoice with the same number for the same vendor in the
   * source system and its total does not match the amount of the exported invoice.
   * Only returned for source systems that don't support programmatic deletes of
   * unposted invoices.
   */
  InvoiceUnpostedMismatch = "INVOICE_UNPOSTED_MISMATCH",
  /** The receipt-type invoice is missing a payment method. */
  PaymentMethodMissing = "PAYMENT_METHOD_MISSING",
  /** The selected payment method is not connected to an external bank account. */
  PaymentMethodNotConnected = "PAYMENT_METHOD_NOT_CONNECTED",
  /**
   * A PO number cannot be specified when linking the invoice, because the associated
   * release is already linked to a PO.
   */
  PoAlreadyLinked = "PO_ALREADY_LINKED",
  /** The PO with the specified number is itemized and not cost code-aggregated. */
  PoNotBasic = "PO_NOT_BASIC",
  /** A PO with the specified number does not exist in the source system. */
  PoNotFound = "PO_NOT_FOUND",
  /**
   * The release associated with the exported invoice is not lined to a PO in the
   * source system.
   * Only returned in case of source systems that don't support standalone invoices.
   */
  PoNotLinked = "PO_NOT_LINKED",
  /**
   * The PO associated with the exported invoice is in a state that does not
   * allow invoices to be opened against it.
   */
  PoNotPosted = "PO_NOT_POSTED",
  /**
   * The PO number specified in the invoice does not match the PO number of the
   * release.
   */
  PoNumberMismatch = "PO_NUMBER_MISMATCH",
  /**
   * A PO with the specified number is assigned to a different project than the
   * one associated with the order of the exported invoice.
   */
  ProjectMismatch = "PROJECT_MISMATCH",
  /**
   * The project to which the exported invoice belongs (via assigned release) is
   * not connected to an external project in the source system.
   */
  ProjectNotConnected = "PROJECT_NOT_CONNECTED",
  /** The exported invoice does not have an associated release. */
  ReleaseNotAssigned = "RELEASE_NOT_ASSIGNED",
  /**
   * A line item representing sales tax is missing in the PO.
   * Applicable only if the integration is configured to list sales tax as a
   * separate line item.
   */
  SalesTaxItemMissing = "SALES_TAX_ITEM_MISSING",
  /** Tax code is not assigned to a Release. */
  TaxCodeNotAssigned = "TAX_CODE_NOT_ASSIGNED",
  /** Tax code is not connected to an ExternalTaxCode. */
  TaxCodeNotConnected = "TAX_CODE_NOT_CONNECTED",
  /**
   * The item representing sales tax in the PO does not have enough uninvoiced
   * amount left.
   */
  TaxItemExceedsRemainingAmount = "TAX_ITEM_EXCEEDS_REMAINING_AMOUNT",
  /** There is no org preferred vendor associated with this release. */
  VendorMissing = "VENDOR_MISSING",
  /**
   * The org preferred vendor associated with the exported invoice (via release)
   * is not connected to an external vendor in the source system.
   */
  VendorNotConnected = "VENDOR_NOT_CONNECTED",
}

export enum InvoiceValidationWarning {
  /**
   * A line item representing additional charges is missing in the PO.
   * Applicable only if the integration is configured to list additional charges
   * as a separate line item.
   */
  AdditionalChargesItemMissing = "ADDITIONAL_CHARGES_ITEM_MISSING",
  /**
   * The invoice has no due date and it will be defaulted to 30 days after the
   * issue date.
   */
  InvoiceDueDateMissing = "INVOICE_DUE_DATE_MISSING",
  /**
   * There is a posted invoice with the same number for the same vendor in the
   * source system.
   * Upon export, an InvoiceLink will be created retroactively.
   */
  InvoicePostedDuplicateLink = "INVOICE_POSTED_DUPLICATE_LINK",
  /**
   * There is an unposted invoice with the same number for the same vendor in the
   * source system.
   * Upon export, an InvoiceLink will be created retroactively.
   */
  InvoiceUnpostedDuplicateLink = "INVOICE_UNPOSTED_DUPLICATE_LINK",
  /**
   * There is an unposted invoice with the same number for the same vendor in the
   * source system.
   * Upon export, the duplicated invoice will be removed and a new one will be
   * created.
   */
  InvoiceUnpostedDuplicateReexport = "INVOICE_UNPOSTED_DUPLICATE_REEXPORT",
  /** The PO with the specified number is itemized and not cost code-aggregated. */
  PoNotBasic = "PO_NOT_BASIC",
  /**
   * The release associated with the invoice is not linked to a PO in the source
   * system.
   * The invoice will be exported as a direct job cost.
   * @deprecated not used anymore
   */
  PoNotLinked = "PO_NOT_LINKED",
  /**
   * The PO associated with the exported invoice is not synchronized with the
   * latest version of the release.
   */
  PoOutdated = "PO_OUTDATED",
  /**
   * A line item representing sales tax is missing in the PO.
   * Applicable only if the integration is configured to list sales tax as a
   * separate line item.
   */
  SalesTaxItemMissing = "SALES_TAX_ITEM_MISSING",
  /** The invoice will be exported as a job cost, without referencing a PO. */
  Standalone = "STANDALONE",
}

export type InvoicedReleaseItem = Node & {
  __typename?: "InvoicedReleaseItem";
  id: Scalars["ID"]["output"];
  invoice: Invoice;
  quantity: Scalars["Decimal"]["output"];
  /**
   * Sum of InvoicedReleaseItem.quantity for the same release item across all
   * non-archived invoices other than the invoice of this InvoicedReleaseItem.
   *
   * Returns null if a given release item is not invoiced in any other approved
   * and non-archived invoices.
   */
  quantitySoFar?: Maybe<Scalars["Decimal"]["output"]>;
  releaseItem: ReleaseItem;
  /**
   * Sum of InvoicedReleaseItem.quantity for the same release item across all
   * non-archived, not-yet exported invoices other than the invoice of this
   * InvoicedReleaseItem.
   *
   * Returns null if a given release item is not invoiced in any other approved
   * and non-archived invoices.
   */
  unexportedQuantitySoFar?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * Unit price of the invoiced release item. If not set, the one from the release item is used.
   * Sometimes, copy prices from invoice are not desired or do not work well so allowing the user
   * to manually set unit price and discarding the unit price from the invoice is a good option.
   */
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum KickbackFilter {
  Anyone = "ANYONE",
  Me = "ME",
}

export type LinkBudgetInput = {
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
  /**
   * If set to true and project-level cost codes are enabled at the org level,
   * it enables project-scoped cost codes for the project only after having imported project-scoped cost codes,
   * so that cost code references that can be salvaged via equivalence logic are updated
   */
  useProjectCostCodes?: Scalars["Boolean"]["input"];
};

export type LinkInvoiceInput = {
  /**
   * Accounting date under which the invoice should be recorded.
   *
   * Only supported for SAGE300.
   */
  accountingDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  batchId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
  /**
   * Month in which to post the invoice.
   *
   * Only supported for Vista. Mutually exclusive with batchId.
   */
  month?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * When specified, the invoice and the release and will have their PO numbers
   * updated and the invoice will be exported against the specified PO.
   */
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  sourceSystem: SourceSystem;
};

export type LinkPoInput = {
  async?: Scalars["Boolean"]["input"];
  /**
   * When set to true, subsequent updates made the the release will be
   * automatically synced to the source system.
   */
  autoSync: Scalars["Boolean"]["input"];
  batchId?: InputMaybe<Scalars["ID"]["input"]>;
  buyoutId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * ID of the warehouse to which PO items should be distributed. Only
   * applicable to DETAIL PO format exports.
   */
  externalWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  format: PoFormat;
  poNumber: Scalars["String"]["input"];
  /**
   * "
   * either releaseId or buyoutId must be specified
   */
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceSystem: SourceSystem;
};

export type LinkPOsInput = {
  links: Array<LinkPoInput>;
};

export type LocationRole = {
  __typename?: "LocationRole";
  orgLocID: Scalars["ID"]["output"];
  role: OrgLocationRole;
};

export type LocationRoleInput = {
  orgLocationId: Scalars["ID"]["input"];
  role: OrgLocationRole;
};

export type Manufacturer = Node & {
  __typename?: "Manufacturer";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

/**
 * MapNodeInput is used to create a mapping between a newly created FM entity (one
 * that does not have an ID yet) and an entity in an external system.
 */
export type MapNodeInput = {
  externalId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type MappingProgress = {
  __typename?: "MappingProgress";
  mapped: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type MarkMessageThreadAsReadInput = {
  context: MessageContextInput;
  isPrivate: Scalars["Boolean"]["input"];
};

export type MarkReleaseItemNotesAsReadInput = {
  releaseItemId: Scalars["ID"]["input"];
};

export type MasterCatalogConnection = {
  facets: Array<Facet>;
  searchContext?: Maybe<SearchContext>;
};

/**
 * Material forms the basis for material items throughout the platform. It may be
 * one of the three types of materials: Org Catalog SKU (org-scoped), Master SKU
 * (global), or Master Product (global).
 */
export type Material = OrgCatalogSku | Product | Sku;

/**
 * MaterialConversions represents a conversion between a UOM pair applicable to a
 * specific org material.
 */
export type MaterialConversion = Node & {
  __typename?: "MaterialConversion";
  from: Uom;
  id: Scalars["ID"]["output"];
  material: OrgMaterial;
  /** Conversion multiplier applied to item quantity in the from->to direction. */
  quantityMultiplier: Scalars["Decimal"]["output"];
  to: Uom;
};

export type MaterialFileForRfqInput = {
  /** Required input for a CUSTOM material file format. */
  custom?: InputMaybe<CustomIntegrationInput>;
  file: Scalars["Upload"]["input"];
  materialFileFormat: IntegrationType;
  rfqId: Scalars["ID"]["input"];
};

export type MaterialReferenceCounts = {
  __typename?: "MaterialReferenceCounts";
  buyoutItems: Scalars["Int"]["output"];
  estimatedItems: Scalars["Int"]["output"];
  releaseItems: Scalars["Int"]["output"];
  rfqItems: Scalars["Int"]["output"];
};

export type Message = Node & {
  __typename?: "Message";
  /** Author is the sender. */
  author: User;
  buyout?: Maybe<Buyout>;
  id: Scalars["ID"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  message: Scalars["String"]["output"];
  /** The quote in which's context the message was sent. Is null if the message is buyout or release-scoped. */
  quote?: Maybe<Quote>;
  release?: Maybe<Release>;
  timeSent: Scalars["Timestamp"]["output"];
};

/**
 * MessageConnection is a page of messages, sorted in ascending order by time sent - least recent first, most last.
 * By default, queries return the last page of messages, use the `last` and `before` parameters
 * to page through the messages from the most recent to the least recent ones.
 */
export type MessageConnection = Connection & {
  __typename?: "MessageConnection";
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageContextInput = {
  id: Scalars["ID"]["input"];
  kind: MessageContextKind;
};

export enum MessageContextKind {
  Buyout = "BUYOUT",
  Quote = "QUOTE",
  Release = "RELEASE",
  Rfq = "RFQ",
}

export type MessageEdge = Edge & {
  __typename?: "MessageEdge";
  cursor: Scalars["String"]["output"];
  node: Message;
};

export type MessageStats = {
  __typename?: "MessageStats";
  unreadPrivate: Scalars["Int"]["output"];
  unreadPublic: Scalars["Int"]["output"];
};

export type MessageStatsInput = {
  context: MessageContextInput;
};

export type MutateOrgCatalogSkuEquivalentInput = {
  masterProductID?: InputMaybe<Scalars["ID"]["input"]>;
  masterSkuID?: InputMaybe<Scalars["ID"]["input"]>;
  orgCatalogSkuID: Scalars["ID"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  /**
   * Accept a REQUESTED buyout, as a vendor.
   *
   * State transition:
   * 	REQUESTED -> ACTIVE
   *
   * This mutation is callable only in the context of non-quoted buyouts.
   *
   * Notifications, from buyout status to notification receiver:
   * 	{ ACTIVE } -> contractor
   */
  acceptBuyout: Buyout;
  /**
   * Accept a quote or a selection of quoted items, as a contractor.
   *
   * Quote Items:
   * 	The corresponding buyout item will use the same project item ID but will
   * 	use the description of the quote item instead of the name of the
   * 	material.
   *
   * Auxiliary Items:
   * 	The corresponding buyout items will use a new (or existing) Org Catalog
   * 	SKU with the name of the auxiliary item and the corresponding Project
   * 	Items will use the UOM of the auxiliary item as the Estimate UOM.
   */
  acceptQuote: Rfq;
  /**
   * Activate an quoted buyout, as a contractor.
   *
   * State transition:
   * 	DRAFT -> ACTIVE
   *
   * This mutation is callable only in the context of quoted buyouts.
   *
   * Notifications, from buyout status to notification receiver:
   * 	{ ACTIVE } -> vendor
   */
  activateBuyout: Buyout;
  /** Adds a new org cost code. Returns the updated list of org's cost codes. */
  addCostCode: Array<CostCode>;
  /**
   * Adds a new estimated item to a project.
   * This mutation may create a new ProjectItem and a new OrgMaterial under the hood.
   * To set the cost code on the underlying OrgMaterial, the caller will need
   * to retrieve EstimatedItem -> item -> material and invoke updateOrgMaterial on it.
   * Returns the created estimated item.
   */
  addEstimatedItem: EstimatedItem;
  addNewLocation?: Maybe<OrgLocation>;
  addOrgCatalogSkuEquivalent?: Maybe<OrgCatalogSku>;
  /**
   * Adds the org location identified by the given org location ID
   * as a preferred vendor to the authenticated viewer's org.
   * The viewer must be part of a contractor org, and the provided
   * org location must be part of a vendor org.
   * Returns the updated preferred vendors list.
   */
  addOrgPreferredVendor: Array<OrgPreferredVendor>;
  /** Creates OrgPreferredVendors in bulk. */
  addOrgPreferredVendors: Array<OrgPreferredVendor>;
  /**
   * Adds a new tag to a project.
   * Returns the updated list of project's tags.
   * @deprecated use updateTags instead
   */
  addTag: Array<ProjectTag>;
  /**
   * Add items to a buyout, as a contractor.
   *
   * This mutation is callable only in the context of non-quoted buyouts and
   * follows the same state requirements as the updateContractorBuyout mutation.
   */
  addToBuyout: Array<BuyoutItem>;
  /**
   * Add items to an existing release, as a contractor.
   * Can be done on Releases in DRAFT, REQUESTED, or SCHEDULED status.
   * If Release is in REQUESTED or SCHEDULED status, vendor notification won't be triggered until
   * updateContractorRelease mutation is invoked.
   * If Release is currently in SCHEDULED status, it will transition back to REQUESTED status.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns the updated release items.
   * @deprecated use updateContractorRelease instead
   */
  addToRelease: Array<ReleaseItem>;
  addToRfq: Array<RfqItem>;
  /** Contractor adds a new vendor location */
  addVendorLocation?: Maybe<OrgLocation>;
  /** Contractor adds a new vendor organization */
  addVendorOrg: Organization;
  addVendorPrice: VendorPrice;
  addWarehouse: Warehouse;
  /**
   * Adds a new zone to a project.
   * Returns the updated list of project's zones.
   */
  addZone: Array<Zone>;
  /**
   * Adds zones to a project in bulk.
   * Returns the added zones in the same order as their names were passed in the
   * input.
   * This mutation is idempotent and handles duplicate names specified in the
   * input gracefully.
   */
  addZones: Array<Zone>;
  /** Approve an invoice, as a contractor. Only possible from AWAITING_APPROVAL status. */
  approveInvoice: Invoice;
  /** Approve a receipt, as a contractor. Only possible from AWAITING_APPROVAL status. */
  approveReceipt: Invoice;
  /**
   * Approve a release, as a contractor. Only possible from AWAITING_APPROVAL status.
   * @deprecated Use updateContractorRelease + operation: APPROVE instead
   */
  approveRelease: Release;
  /** Archive a buyout, as a contractor. */
  archiveBuyout: Buyout;
  archiveBuyouts: Array<Buyout>;
  archiveDeliverySlip: DeliverySlip;
  archiveInventoryItems: Array<InventoryItem>;
  /**
   * Archive an invoice as a contractor. Only available for AWAITING_APPROVAL
   * invoices.
   */
  archiveInvoice: Invoice;
  /**
   * Archive an invoice email as a contractor.
   * @deprecated use archiveInvoiceEmails instead
   */
  archiveInvoiceEmail: InvoiceEmail;
  archiveInvoiceEmails: Array<InvoiceEmail>;
  archiveInvoices: Array<Invoice>;
  archiveOrgMaterial: OrgMaterial;
  archiveProjects: Array<Project>;
  /** Archive a receipt as a contractor. Only available for AWAITING_APPROVAL status. */
  archiveReceipt: Invoice;
  /** Archive a release, as a contractor. Only possible for DRAFT or CANCELED releases. */
  archiveRelease: Release;
  archiveReleaseType: ReleaseType;
  archiveReleases: Array<Release>;
  archiveRfqs: Array<Rfq>;
  archiveSiteContact: SiteContact;
  /**
   * Archive an existing org-scoped UOM.
   *
   * This will archive the UOM and it will only be available to resources that
   * are already using it.
   * UOMs cannot be unarchived, but a new one can be created with the same
   * properties.
   */
  archiveUOM: Uom;
  archiveWarehouse: Warehouse;
  /**
   * Cancel a buyout, as a contractor.
   *
   * State transition:
   * 	{ DRAFT, REQUESTED, ACTIVE } -> CANCELLED
   *
   * This mutation is callable in the context of any buyout.
   *
   * Notifications, from buyout status to notification receiver:
   * 	{ CANCELLED } -> vendor
   */
  cancelBuyout: Buyout;
  cancelRFQBuyouts: Array<Buyout>;
  /**
   * Cancel a release, as a contractor. Only possible from DRAFT, REQUESTED, or SCHEDULED status.
   * Once release is canceled, release cannot transition into any other state.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns the updated release.
   * @deprecated Use updateContractorRelease + operation: CANCEL instead
   */
  cancelRelease: Release;
  cancelRfq?: Maybe<Scalars["String"]["output"]>;
  /** Clears PO number of the invoice and the order (if present). */
  clearPoNumber: Invoice;
  /**
   * Complete a release, as a contractor. Make the release transition from REQUESTED or SCHEDULED to RECEIVED or PARTIALLY_RECEIVED status.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns the updated release.
   * @deprecated use updateContractorRelease instead
   */
  completeRelease: Release;
  /**
   * Confirm a release, as a vendor. Make the release transition from REQUESTED to SCHEDULED status.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns the updated release and newly created releases (if any).
   * Refer to the documentation of ReleaseItem.time field for more details on how a confirmed release may be split into multiple releases.
   * @deprecated use updateVendorRelease with confirm flag instead
   */
  confirmRelease: Array<Release>;
  /** Connect a cost code to an external ledger account. */
  connectCostCodeLedgerAccount: CostCode;
  /** Match release items that don't have a POItemLink to PO items. */
  connectMissingPOItems: Release;
  /** Connect an org material to an item in an external source system. */
  connectOrgMaterial: OrgMaterial;
  /** Connect an OPV to a vendor in an external source system. */
  connectOrgPreferredVendor: OrgPreferredVendor;
  /** Connect (match) an existing project a project in an external source system. */
  connectProject: Project;
  /**
   * connectSourceSystem should be called after the user has authenticated
   * against the source system (exchanging the link token for a public token).
   */
  connectSourceSystem: SourceSystemSettings;
  copyEstimatedItems: Array<EstimatedItem>;
  /** Corrects the invoice document, the corrected document being previously uploaded. */
  correctInvoiceDocument: Invoice;
  /**
   * Upload an asset, resize it if it's an image based on context, and return it.
   * This mutation allows us to upload assets (images, documents, etc.) one by one and actual mutations
   * that deal with business logic don't have to concern themselves with upload logic and don't end
   * up taking too long to process/return.
   */
  createAsset: Asset;
  /** Create a buyout, as a contractor. */
  createBuyout: Buyout;
  /** Create a new custom file format. */
  createCustomFileFormat: CustomFileFormat;
  createDeliverySlip: DeliverySlip;
  createEditCopyRfqForChanges: Rfq;
  createEnterprise: Enterprise;
  createExternalBatch: ExternalBatch;
  createInventoryAdjustment: InventoryTransaction;
  createInventoryTransfer: InventoryTransaction;
  createInvoice: Invoice;
  createInvoiceFolder: InvoiceFolder;
  /** Create an issue report for an invoice. */
  createInvoiceIssue: Invoice;
  /** Create an internal kickback report for an invoice. */
  createInvoiceKickback: Invoice;
  createInvoiceKickbackComment: InvoiceKickback;
  /**
   * Create a set of invoices from a single, multi-page document (as a contractor).
   *
   * This mutation will attempt to compute a matching release for each invoice
   * based on the OCR results.
   *
   * It returns a list of created invoices in the same order as the page ranges
   * specified in the input.
   */
  createInvoices: Array<Invoice>;
  /**
   * Adds a new manufacturer to the org catalog.
   * Returns the created manufacturer.
   */
  createManufacturer: Manufacturer;
  /**
   * Adds new manufacturers to the org catalog.
   * Returns the created manufacturers in the same order as the input.
   * In case of a name conflict, the existing manufacturer is returned.
   */
  createManufacturers: Array<Manufacturer>;
  /**
   * Bulk-create material conversions.
   *
   * Returns an index-compatible array of the created material conversions.
   *
   * This mutation is idempotent. If a material conversion already exists for
   * a given material + from->to UOM combination, it will be updated with the
   * specified multiplier.
   */
  createMaterialConversions: Array<MaterialConversion>;
  createNewProject: Project;
  createNoteDocument: NoteDocument;
  createOrg: Organization;
  /**
   * Create a new org catalog sku.
   *
   * In case of a name and a custom part number conflict the existing Org Catalog
   * SKU will be returned.
   *
   * In case of a name conflict, without the custom part number specified, the
   * existing Org Catalog SKU will be returned. If there are multiple Org Catalog
   * SKU matching the specified name, the Org Catalog SKU that does not have a
   * custom part number will be returned, otherwise the oldest one.
   */
  createOrgCatalogSku: OrgCatalogSku;
  createOrgMaterial: OrgMaterial;
  createProjects: Array<Project>;
  createQuoteDocument: QuoteDocument;
  /** Create a receipt from either a pdf or a set of images (as a contractor). */
  createReceipt: Invoice;
  /** Create an internal kickback report for a receipt. */
  createReceiptKickback: Invoice;
  /**
   * Create a new release for a buyout, as a contractor.
   * PO field on the new release should be prefilled with buyout's PO if such is specified.
   * Returns the created release.
   * @deprecated Use createReservedRelease instead
   */
  createRelease: Release;
  createReleaseGroup: ReleaseGroup;
  /**
   * Create a release issue, as a contractor. Only possible for RECEIVED or PARTIALLY_RECEIVED releases.
   * Returns the created release issue.
   * @deprecated use updateContractorRelease instead
   */
  createReleaseIssue: ReleaseIssue;
  /**
   * Create multiple issues on items belonging to a given release
   * Returns release items on which the issues were added
   * @deprecated use updateContractorRelease instead
   */
  createReleaseIssues: Array<ReleaseItem>;
  createReleaseType: ReleaseType;
  /** Create a new release for a buyout (reserved release), as a contractor. */
  createReservedRelease: Release;
  createRfq: Rfq;
  createSiteContact: SiteContact;
  /** Create a new release without a buyout (standalone release), as a contractor. */
  createStandaloneRelease: Release;
  /**
   * Create a new UOM.
   *
   * The UOM will be created at the org scope of the current user.
   */
  createUOM: Uom;
  createUser?: Maybe<User>;
  /**
   * Decline a REQUESTED buyout, as a vendor.
   *
   * State transition:
   * 	REQUESTED -> DECLINED
   *
   * This mutation is callable only in the context of non-quoted buyouts.
   *
   * Notifications, from buyout status to notification receiver:
   * 	{ DECLINED } -> contractor
   */
  declineBuyout: Buyout;
  /** Delete a custom file format */
  deleteCustomFileFormat?: Maybe<CustomFileFormat>;
  deleteInvoiceFolder: InvoiceFolder;
  /** Delete an issue report for an invoice, as contractor */
  deleteInvoiceIssue: Invoice;
  /** Delete an internal kickback report for an invoice. */
  deleteInvoiceKickback: Invoice;
  deleteInvoiceKickbackComment: InvoiceKickback;
  deleteNotification?: Maybe<Scalars["String"]["output"]>;
  /** Delete an internal kickback report for a receipt. */
  deleteReceiptKickback: Invoice;
  deleteReleaseGroup: ReleaseGroup;
  deleteUser?: Maybe<User>;
  /**
   * Deletes a vendor price and returns the deleted model. This operation is
   * idempotent.
   */
  deleteVendorPrice: VendorPrice;
  deregisterDevice: Device;
  /** Disconnect a cost code from an external ledger account. */
  disconnectCostCodeLedgerAccount: CostCode;
  /** Disconnect an org material from an item in an external source system. */
  disconnectOrgMaterial: OrgMaterial;
  /** Disconnect an OPV from a vendor in an external source system. */
  disconnectOrgPreferredVendor: OrgPreferredVendor;
  /** Disconnect a project from an external source system. */
  disconnectProject: Project;
  /**
   * disconnectSourceSystem disables a source system connection (sets the connected
   * connected parameter in SourceSystemSetting to false.
   */
  disconnectSourceSystem: SourceSystemSettings;
  /** Returns a signed URL to download the invoice document. */
  downloadInvoiceDocument: Scalars["String"]["output"];
  /** Returns a signed URL to download an archive of invoice documents. */
  downloadInvoiceDocuments: Scalars["String"]["output"];
  exportBuyouts: ExportBuyoutsOutput;
  exportInvoices: ExportInvoicesOutput;
  exportQuoteSelection: ExportedQuoteSelection;
  exportReceipts: ExportInvoicesOutput;
  /** @deprecated use exportReleases instead */
  exportRelease: ExportReleaseOutput;
  exportReleases: ExportReleaseOutput;
  /**
   * Import cost code allowance (budget) for the project defined in the ERP.
   *
   * This mutation populates the Project->allowance->costCodes. It also overrides
   * Project->allowance->amount if null.
   * @deprecated use linkBudget
   */
  importAllowance: Project;
  importBuyout: Buyout;
  importComplianceGroups: ImportComplianceGroupsOutput;
  /**
   * Import cost codes from an external source system.
   *
   * In case of a duplicate code, a new mapping will be created for the existing
   * cost code.
   */
  importCostCodes: ImportCostCodesOutput;
  importCostTypes: ImportCostTypesOutput;
  importEquipmentItems: ImportEquipmentItemsOutput;
  importEstimate: Project;
  importOrgCatalog?: Maybe<Organization>;
  /** Import org materials from an external source system. */
  importOrgMaterials: ImportOrgMaterialsOutput;
  /** Import Payment Methods from an external source system. */
  importPaymentMethods: ImportPaymentMethodsOutput;
  /**
   * Import job-specific cost code + cost type combinations (WBS) as project tags.
   * @deprecated use linkBudget
   */
  importProjectTags: Project;
  importServiceCodes: ImportServiceCodesOutput;
  importTaxCodes: ImportTaxCodesOutput;
  /** Import vendors from an external source system. */
  importVendors: ImportVendorsOutput;
  /** Import project-specific phases/sub-jobs from the ERP as zones. */
  importZones: Project;
  initializeSMSVerification: SmsVerification;
  /**
   * initializeSourceSystemConnection generates a link token for a specified
   * source system. The link token should then be used for the FE to authenticate
   * against that source system.
   */
  initializeSourceSystemConnection: Scalars["String"]["output"];
  linkBudget: BudgetLink;
  linkInvoice: Invoice;
  /** Export (link) a release or a buyout as a PO to an external source system. */
  linkPO: PoLink;
  /** linkPOs allows exporting a set of links in bulk. It requires async=true for every PO link. */
  linkPOs: PoLinkGroup;
  linkReceipt: Invoice;
  markMessageThreadAsRead: Array<Message>;
  markReleaseItemNotesAsRead: ReleaseItem;
  previewCustomInvoiceTemplates?: Maybe<Array<Scalars["String"]["output"]>>;
  previewCustomPoTemplates?: Maybe<Array<Scalars["String"]["output"]>>;
  previewInvoiceTemplate: Scalars["String"]["output"];
  previewPoTemplate: Scalars["String"]["output"];
  /**
   * ReadNotification marks the given notification as read.
   * It returns an error if the notification does not exist,
   * and null if it was read already. Calling readNotification
   * for an already read notification is a no-op.
   */
  readNotification?: Maybe<Notification>;
  /**
   * Trigger a refresh of external items pulled from the specified source system.
   *
   * Returns the number of items refreshed.
   */
  refreshExternalItems: Scalars["Int"]["output"];
  refreshExternalPOs: Scalars["Int"]["output"];
  refreshExternalProjects: Scalars["Int"]["output"];
  refreshExternalVendors: Scalars["Int"]["output"];
  registerDevice: Device;
  /**
   * Reject a release, as a contractor. Only possible from AWAITING_APPROVAL status.
   * @deprecated use updateContractorRelease + operation: REJECT instead
   */
  rejectRelease: Release;
  /** Attempt to re-match an invoice to a release. */
  rematchInvoice: Invoice;
  /**
   * Remove asset from storage: should be used by frontend whenever user cancels/removes an uploaded
   * asset.
   * Returns URL of the removed asset if asset was found and was removed successfully.
   */
  removeAsset?: Maybe<Scalars["String"]["output"]>;
  /**
   * Removes an org cost code that has a specific ID.
   * Returns the updated list of org's cost codes.
   */
  removeCostCode: Array<CostCode>;
  /**
   * Removes an estimated item of a specified ID from a project.
   * Returns the project's updated list of estimated items.
   */
  removeEstimatedItem: Array<EstimatedItem>;
  /**
   * Removes estimated items with specified IDs.
   *
   * Returns the project's updated list of estimated items (aggregated).
   */
  removeEstimatedItems: Array<AggregatedZone>;
  /**
   * Remove items from a buyout, as a contractor.
   *
   * This mutation is callable only in the context of non-quoted buyouts and
   * follows the same state requirements as the updateContractorBuyout mutation.
   */
  removeFromBuyout: Array<Maybe<Scalars["ID"]["output"]>>;
  /**
   * Remove release items with the provided IDs from the release.
   * Can be done on Releases in DRAFT, REQUESTED, or SCHEDULED status.
   * If Release is in REQUESTED or SCHEDULED status, vendor notification won't be triggered until
   * updateContractorRelease mutation is invoked.
   * If Release is currently in SCHEDULED status, it will transition back to REQUESTED status.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns IDs of the removed release items.
   * @deprecated use updateContractorRelease instead
   */
  removeFromRelease: Array<Maybe<Scalars["ID"]["output"]>>;
  removeFromRfq: Array<Scalars["ID"]["output"]>;
  removeLocation?: Maybe<Scalars["String"]["output"]>;
  removeOrgCatalogSku?: Maybe<Scalars["ID"]["output"]>;
  removeOrgCatalogSkuEquivalent?: Maybe<OrgCatalogSku>;
  /**
   * Removes the preferred vendor identified with the given ID
   * from the authenticated viewer's org location, if it exists.
   * The viewer must be part of a contractor org.
   * Returns the updated preferred vendors list.
   */
  removeOrgPreferredVendor: Array<OrgPreferredVendor>;
  removeProject?: Maybe<Scalars["String"]["output"]>;
  removeRfq?: Maybe<Scalars["ID"]["output"]>;
  /**
   * Removes the project tag with the specified ID.
   * If a tag has assigned EstimatedItems or ReleaseItems, those EstimatedItems and ReleaseItems
   * get automatically unassigned when the tag is removed.
   * Returns the updated list of project's tags.
   * @deprecated use updateTags instead
   */
  removeTag: Array<ProjectTag>;
  /**
   * Removes the zone of a specified ID from a project.
   * A zone can only be deleted if it does not have any EstimatedItems or ReleaseItems.
   * Returns the updated list of project's zones.
   */
  removeZone: Array<Zone>;
  /**
   * Rescans the delivery slip and re-generates the delivered coverage information.
   *    Always returns true.
   */
  rescanDeliverySlip: Scalars["Boolean"]["output"];
  /**
   * rescans the current invoice and re-generates the invoice coverage information
   * this works for non-approved invoices only
   */
  rescanInvoice: Scalars["Boolean"]["output"];
  resendExternalNotification: ExternalNotification;
  resendQuote: Quote;
  resendSMSVerification: SmsVerification;
  /** Resolve an issue report for an invoice. */
  resolveInvoiceIssue: Invoice;
  /**
   * Resolve invoice issues as a vendor. Can be invoked on invoices in
   * ISSUES_REPORTED and AWAITING_APPROVAL statuses and transitions the invoice
   * into AWAITING_APPROVAL status.
   * @deprecated use resolveInvoiceIssue instead
   */
  resolveInvoiceIssues: Invoice;
  /** Resolve an internal kickback report for an invoice. */
  resolveInvoiceKickback: Invoice;
  /** Resolve an internal kickback report for a receipt. */
  resolveReceiptKickback: Invoice;
  /**
   * Resolve release issues for the given release, as a vendor.
   * Only possible for RECEIVED or PARTIALLY_RECEIVED releases.
   * Returns the updated release.
   */
  resolveReleaseIssues: Release;
  /** Propagate changes from an immutable PO back to the release. */
  reverseSyncPO: Release;
  /**
   * Schedule a release, as a contractor. Make the release transition from REQUESTED to SCHEDULED status.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns the updated release.
   * @deprecated Use updateContractorRelease + operation: SCHEDULE instead
   */
  scheduleRelease: Release;
  sendMessage: Array<Message>;
  /**
   * setVendorPrices saves a list of vendor prices overriding the existing ones.
   * It returns the list of saved vendor prices.
   */
  setVendorPrices: Array<Maybe<VendorPrice>>;
  /**
   * Performs the OCR process to detect invoices within a given document (as a
   * contractor). It returns a list of page ranges that contain invoices.
   */
  splitInvoiceDocument: Array<PageRange>;
  /**
   * Submit a buyout, as a contractor, to be approved by the vendor.
   *
   * State transition:
   * 	{ DRAFT, DECLINED, CANCELLED } -> REQUESTED
   *
   * This mutation is callable only in the context of non-quoted buyouts.
   *
   * Notifications, from buyout status to notification receiver:
   * 	{ REQUESTED } -> vendor
   */
  submitBuyout: Buyout;
  submitQuote: Quote;
  /**
   * Submit a release, as a contractor, for confirmation by the vendor.
   * Release has to be in DRAFT status and will transition to the REQUESTED status if this mutation succeeds.
   * Returns the updated release.
   * If the argument reserve is true, the release will be transitioned to RESERVED status instead of REQUESTED.
   * @deprecated use updateContractorRelease + operation: SUBMIT instead
   */
  submitRelease: Release;
  /**
   * Submitting an RFQ will try to transfer over data from previously-submitted
   * quotes if this is a change request. Auxiliary quote items are copied over
   * as well.
   */
  submitRfq: Rfq;
  submitSMSVerification: SmsVerification;
  syncBudget: Project;
  /** Trigger synchronization of a release to an external source system. */
  syncPO: Release;
  /**
   * Unarchive a list of invoices.
   *
   * This mutation will unarchive releases archived by the corresponding call to
   * archiveInvoices, if applicable, and it will unarchive the invoice coverage
   * information.
   */
  unarchiveInvoices: Array<Invoice>;
  unarchiveOrgMaterial: OrgMaterial;
  unarchiveReleaseType: ReleaseType;
  unarchiveReleases: Array<Release>;
  unlinkBudget: Project;
  unlinkInvoice: Invoice;
  /**
   * Disconnect a release from a PO in an external source system.
   *
   * When auto-sync is enabled, changes made to the release will no longer be
   * synced to the source system.
   */
  unlinkPO: PoLink;
  unlinkReceipt: Invoice;
  /** Update an asset.For now, only the isPrivate field can be updated. */
  updateAsset: Asset;
  updateBudgetLink: BudgetLink;
  /**
   * Update the service price sheet of a buyout, as a vendor.
   *
   * This mutation is callable if the buyout is { REQUESTED }, which makes it
   * unavailable to quoted buyouts.
   */
  updateBuyoutServicePrices: Buyout;
  /**
   * Update the buyout payments, notes, and additional charges, as a vendor.
   *
   * This mutation is callable if the buyout is { REQUESTED }, which makes it
   * unavailable to quoted buyouts.
   * @deprecated Use updateVendorBuyout directly
   */
  updateBuyoutTerms: Buyout;
  updateComplianceGroups: Array<ComplianceGroup>;
  /**
   * Update a buyout, as a contractor.
   *
   * This mutation is callable if the buyout is { DRAFT, REQUESTED, ACTIVE, DECLINED, CANCELLED }.
   *
   * Notifications, from buyout status to notification receiver:
   * 	{ REQUESTED } -> vendor
   */
  updateContractorBuyout: Buyout;
  /**
   * Update given release, as a contractor.
   * Can be done on Releases in DRAFT, REQUESTED, or SCHEDULED status.
   * When release is updated in the REQUESTED or SCHEDULED status, this mutation will trigger a notification to the vendor.
   * If Release is currently in SCHEDULED status, it will transition back to REQUESTED status.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * Returns the updated release.
   */
  updateContractorRelease: Release;
  updateContractorReleases: Array<Release>;
  /**
   * Updates an org cost code.
   * Returns the updated cost code.
   */
  updateCostCode: CostCode;
  /**
   * Updates the cost codes.
   * Returns all cost codes within the org.
   */
  updateCostCodes: Array<CostCode>;
  /**
   * Updates the cost codes using an uploaded file.
   * Returns the updated cost codes.
   */
  updateCostCodesWithFile: Array<CostCode>;
  updateCostTypes: Array<CostType>;
  updateDeliverySlip: DeliverySlip;
  updateEnterprise: Enterprise;
  updateEquipmentItems: Array<EquipmentItem>;
  /**
   * Updates an existing project's estimated item.
   * Returns the updated list of project's estimated items.
   * @deprecated Use updateEstimatedItems instead.
   */
  updateEstimatedItem: Array<EstimatedItem>;
  /**
   * Updates multiple existing project's estimated items.
   * Returns a list of all project's items from specified project
   */
  updateEstimatedItems: Array<AggregatedZone>;
  /**
   * Updates the list of project's estimated items using an uploaded file.
   * Returns an object that contains the updated list of estimated items and the
   * list of items that were imported from the file.
   */
  updateEstimatesWithFile: UpdateEstimatesWithFileOutput;
  /** Update an invoice (as a contractor). */
  updateInvoice: Invoice;
  updateInvoiceEmailAttachment: InvoiceEmailAttachment;
  updateInvoiceFolder: InvoiceFolder;
  /** Update an issue report for an invoice, as contractor */
  updateInvoiceIssue: Invoice;
  /** Update an internal kickback report for an invoice. */
  updateInvoiceKickback: Invoice;
  updateInvoiceKickbackComment: InvoiceKickback;
  /** Bulk update invoices (as a contractor). */
  updateInvoices: Array<Invoice>;
  updateLocation?: Maybe<OrgLocation>;
  updateOrg: Organization;
  updateOrgCatalogSku: OrgCatalogSku;
  /**
   * Updates existing org materials.
   *
   * When inputs is specified, it returns the updated org materials in the same
   * order as they appear in the array.
   *
   * When input is specified, it returns all created and updated org materials.
   */
  updateOrgMaterials: Array<OrgMaterial>;
  /**
   * Modifies the contact information of the preferred vendor
   * under the authenticated viewer's org identified by the given ID.
   * The viewer must be part of a contractor org.
   * This endpoint does full updates following SET semantics, that is
   * update existing vendor contacts, add new ones and remove the ones that
   * does not exist in the supplied input.
   * Returns the updated preferred vendor, if the org has a vendor
   * identified by the given ID, else it returns null.
   */
  updateOrgPreferredVendor?: Maybe<OrgPreferredVendor>;
  updateOrgSettings: OrgSettings;
  /** Update a PO link with a new configuration. */
  updatePOLink: PoLink;
  /**
   * Updates the payment methods.
   * Returns all payment methods in the org.
   */
  updatePaymentMethods: Array<PaymentMethod>;
  updateProject: Project;
  /**
   * Update existing project items.
   *
   * Returns the updated project items in the same order as the input.
   */
  updateProjectItems: Array<ProjectItem>;
  updateQuote: Quote;
  updateQuoteServicePriceSheet: Quote;
  /** @deprecated Use updateQuote instead */
  updateQuoteTerms: Quote;
  /** Update a receipts (as a contractor). */
  updateReceipt: Invoice;
  /** Update an internal kickback report for a receipt. */
  updateReceiptKickback: Invoice;
  updateReleaseGroup: ReleaseGroup;
  updateReleaseType: ReleaseType;
  updateRfq: Rfq;
  updateServiceCodes: Array<ServiceCode>;
  updateSiteContact: SiteContact;
  /**
   * Updates a tag of a project.
   * Returns the updated tag.
   * @deprecated use updateTags instead
   */
  updateTag: ProjectTag;
  updateTags: Array<ProjectTag>;
  updateTaxCodes: Array<TaxCode>;
  /** Update an existing org-scoped UOM. */
  updateUOM: Uom;
  updateUser?: Maybe<User>;
  /**
   * Update a buyout, as a vendor.
   *
   * This mutation is callable if the buyout is { REQUESTED }, which makes it
   * unavailable to quoted buyouts.
   */
  updateVendorBuyout: Buyout;
  updateVendorLocation?: Maybe<OrgLocation>;
  /** Contractor updates a vendor organization */
  updateVendorOrg: Organization;
  updateVendorPrice: VendorPrice;
  /**
   * Update given release, as a vendor.
   * Can be done on Releases in REQUESTED or SCHEDULED status.
   * When release is updated in the SCHEDULED status, this mutation will trigger a notification to the contractor.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * In case that the release is in SCHEDULED status and changes are made to alternativeFulfillmentTime or alternativeFulfillmentRelease fields,
   * of any of the release items, the release will be split into multiple releases (see description of alternativeFulfillmentTime field on ReleaseItem).
   * Returns the updated release and newly created releases (if any).
   */
  updateVendorRelease: Array<Release>;
  updateViewerProfile?: Maybe<User>;
  updateWarehouse: Warehouse;
  /**
   * Update given release, as a warehouse manager.
   * Can be done on Releases in REQUESTED or SCHEDULED status.
   * When release is updated in the SCHEDULED status, this mutation will trigger a notification to the contractor.
   * Also takes version as input: if version in the database is different from the one supplied, an error is returned.
   * In case that the release is in SCHEDULED status and changes are made to alternativeFulfillmentTime or alternativeFulfillmentRelease fields,
   * of any of the release items, the release will be split into multiple releases (see description of alternativeFulfillmentTime field on ReleaseItem).
   * Returns the updated release and newly created releases (if any).
   */
  updateWarehouseRelease: Array<Release>;
  /**
   * Updates a zone of a project.
   * Returns the updated zone.
   */
  updateZone: Zone;
  /**
   * Upload an asset, resize it if it's an image based on context, and return URL of the uploaded asset.
   * This mutation allows us to upload assets (images, documents, etc.) one by one and actual mutations
   * that deal with business logic don't have to concern themselves with upload logic and don't end
   * up taking too long to process/return.
   * @deprecated Use createAsset instead
   */
  uploadAsset: Scalars["String"]["output"];
  withdrawQuote: Quote;
};

export type MutationAcceptBuyoutArgs = {
  input: AcceptBuyoutInput;
};

export type MutationAcceptQuoteArgs = {
  input: AcceptQuoteInput;
};

export type MutationActivateBuyoutArgs = {
  input: ActivateBuyoutInput;
};

export type MutationAddCostCodeArgs = {
  input: AddCostCodeInput;
};

export type MutationAddEstimatedItemArgs = {
  input: AddEstimatedItemInput;
};

export type MutationAddNewLocationArgs = {
  input: AddOrgLocationInput;
};

export type MutationAddOrgCatalogSkuEquivalentArgs = {
  input: MutateOrgCatalogSkuEquivalentInput;
};

export type MutationAddOrgPreferredVendorArgs = {
  input: AddOrgPreferredVendorInput;
};

export type MutationAddOrgPreferredVendorsArgs = {
  input: AddOrgPreferredVendorsInput;
};

export type MutationAddTagArgs = {
  input: AddTagInput;
};

export type MutationAddToBuyoutArgs = {
  input: AddToBuyoutInput;
};

export type MutationAddToReleaseArgs = {
  input: AddToReleaseInput;
};

export type MutationAddToRfqArgs = {
  input: AddToRfqInput;
};

export type MutationAddVendorLocationArgs = {
  input: AddOrgLocationInput;
};

export type MutationAddVendorOrgArgs = {
  input: AddVendorOrgInput;
};

export type MutationAddVendorPriceArgs = {
  input: AddVendorPriceInput;
};

export type MutationAddWarehouseArgs = {
  input: AddWarehouseInput;
};

export type MutationAddZoneArgs = {
  input: AddZoneInput;
};

export type MutationAddZonesArgs = {
  input: AddZonesInput;
};

export type MutationApproveInvoiceArgs = {
  input: ApproveInvoiceInput;
};

export type MutationApproveReceiptArgs = {
  input: ApproveInvoiceInput;
};

export type MutationApproveReleaseArgs = {
  input: ApproveReleaseInput;
};

export type MutationArchiveBuyoutArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveBuyoutsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationArchiveDeliverySlipArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveInventoryItemsArgs = {
  input?: InputMaybe<ArchiveInventoryItemsInput>;
};

export type MutationArchiveInvoiceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveInvoiceEmailArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveInvoiceEmailsArgs = {
  input: ArchiveInvoiceEmailsInput;
};

export type MutationArchiveInvoicesArgs = {
  invoiceIds: Array<Scalars["ID"]["input"]>;
};

export type MutationArchiveOrgMaterialArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveProjectsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationArchiveReceiptArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveReleaseArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveReleaseTypeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveReleasesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationArchiveRfqsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationArchiveSiteContactArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveUomArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationArchiveWarehouseArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelBuyoutArgs = {
  input: CancelBuyoutInput;
};

export type MutationCancelRfqBuyoutsArgs = {
  rfqID: Scalars["ID"]["input"];
};

export type MutationCancelReleaseArgs = {
  input: CancelReleaseInput;
};

export type MutationCancelRfqArgs = {
  rfqID: Scalars["ID"]["input"];
};

export type MutationClearPoNumberArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationCompleteReleaseArgs = {
  input: CompleteReleaseInput;
};

export type MutationConfirmReleaseArgs = {
  input: ConfirmReleaseInput;
};

export type MutationConnectCostCodeLedgerAccountArgs = {
  input: ConnectNodeInput;
};

export type MutationConnectMissingPoItemsArgs = {
  poLinkId: Scalars["ID"]["input"];
};

export type MutationConnectOrgMaterialArgs = {
  input: ConnectNodeInput;
};

export type MutationConnectOrgPreferredVendorArgs = {
  input: ConnectNodeInput;
};

export type MutationConnectProjectArgs = {
  input: ConnectNodeInput;
};

export type MutationConnectSourceSystemArgs = {
  input: ConnectSourceSystemInput;
};

export type MutationCopyEstimatedItemsArgs = {
  input: CopyEstimatedItemsInput;
};

export type MutationCorrectInvoiceDocumentArgs = {
  input: CorrectInvoiceDocumentInput;
};

export type MutationCreateAssetArgs = {
  input: UploadAssetInput;
};

export type MutationCreateBuyoutArgs = {
  input: CreateBuyoutInput;
};

export type MutationCreateCustomFileFormatArgs = {
  input: CreateCustomFileFormatInput;
};

export type MutationCreateDeliverySlipArgs = {
  input: CreateDeliverySlipInput;
};

export type MutationCreateEditCopyRfqForChangesArgs = {
  rfqID: Scalars["ID"]["input"];
};

export type MutationCreateEnterpriseArgs = {
  input: CreateEnterpriseInput;
};

export type MutationCreateExternalBatchArgs = {
  input: CreateExternalBatchInput;
};

export type MutationCreateInventoryAdjustmentArgs = {
  input: CreateInventoryAdjustmentInput;
};

export type MutationCreateInventoryTransferArgs = {
  input: CreateInventoryTransferInput;
};

export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};

export type MutationCreateInvoiceFolderArgs = {
  input: CreateInvoiceFolderInput;
};

export type MutationCreateInvoiceIssueArgs = {
  input: CreateInvoiceIssueInput;
};

export type MutationCreateInvoiceKickbackArgs = {
  input: CreateInvoiceKickbackInput;
};

export type MutationCreateInvoiceKickbackCommentArgs = {
  input: CreateInvoiceKickbackCommentInput;
};

export type MutationCreateInvoicesArgs = {
  input: CreateInvoicesInput;
};

export type MutationCreateManufacturerArgs = {
  input: CreateManufacturerInput;
};

export type MutationCreateManufacturersArgs = {
  input: Array<CreateManufacturerInput>;
};

export type MutationCreateMaterialConversionsArgs = {
  input: Array<CreateMaterialConversionInput>;
};

export type MutationCreateNewProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateNoteDocumentArgs = {
  input: CreateNoteDocumentInput;
};

export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};

export type MutationCreateOrgCatalogSkuArgs = {
  input: CreateOrgCatalogSkuInput;
};

export type MutationCreateOrgMaterialArgs = {
  input?: InputMaybe<CreateOrgMaterialInput>;
};

export type MutationCreateProjectsArgs = {
  input: CreateProjectsInput;
};

export type MutationCreateQuoteDocumentArgs = {
  input: CreateQuoteDocumentInput;
};

export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};

export type MutationCreateReceiptKickbackArgs = {
  input: CreateReceiptKickbackInput;
};

export type MutationCreateReleaseArgs = {
  input: CreateReleaseInput;
};

export type MutationCreateReleaseGroupArgs = {
  input: CreateReleaseGroupInput;
};

export type MutationCreateReleaseIssueArgs = {
  input: ReleaseIssueInput;
};

export type MutationCreateReleaseIssuesArgs = {
  input: CreateReleaseIssuesInput;
};

export type MutationCreateReleaseTypeArgs = {
  input: CreateReleaseTypeInput;
};

export type MutationCreateReservedReleaseArgs = {
  input: CreateReservedReleaseInput;
};

export type MutationCreateRfqArgs = {
  input: CreateRfqInput;
};

export type MutationCreateSiteContactArgs = {
  input: CreateSiteContactInput;
};

export type MutationCreateStandaloneReleaseArgs = {
  input: CreateStandaloneReleaseInput;
};

export type MutationCreateUomArgs = {
  input: UomInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeclineBuyoutArgs = {
  input: DeclineBuyoutInput;
};

export type MutationDeleteCustomFileFormatArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInvoiceFolderArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInvoiceIssueArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInvoiceKickbackArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInvoiceKickbackCommentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteNotificationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteReceiptKickbackArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteReleaseGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteVendorPriceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeregisterDeviceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDisconnectCostCodeLedgerAccountArgs = {
  input: DisconnectNodeInput;
};

export type MutationDisconnectOrgMaterialArgs = {
  input: DisconnectNodeInput;
};

export type MutationDisconnectOrgPreferredVendorArgs = {
  input: DisconnectNodeInput;
};

export type MutationDisconnectProjectArgs = {
  input: DisconnectNodeInput;
};

export type MutationDisconnectSourceSystemArgs = {
  input: DisconnectSourceSystemInput;
};

export type MutationDownloadInvoiceDocumentArgs = {
  input: DownloadInvoiceDocumentInput;
};

export type MutationDownloadInvoiceDocumentsArgs = {
  input: DownloadInvoiceDocumentsInput;
};

export type MutationExportBuyoutsArgs = {
  input: ExportBuyoutsInput;
};

export type MutationExportInvoicesArgs = {
  input: ExportInvoicesInput;
};

export type MutationExportQuoteSelectionArgs = {
  input: ExportQuoteSelectionInput;
};

export type MutationExportReceiptsArgs = {
  input: ExportInvoicesInput;
};

export type MutationExportReleaseArgs = {
  input: ExportReleaseInput;
};

export type MutationExportReleasesArgs = {
  input: ExportReleasesInput;
};

export type MutationImportAllowanceArgs = {
  input: ImportAllowanceInput;
};

export type MutationImportBuyoutArgs = {
  input: ImportBuyoutInput;
};

export type MutationImportComplianceGroupsArgs = {
  input: ImportComplianceGroupsInput;
};

export type MutationImportCostCodesArgs = {
  input: ImportCostCodesInput;
};

export type MutationImportCostTypesArgs = {
  input: ImportCostTypesInput;
};

export type MutationImportEquipmentItemsArgs = {
  input: ImportEquipmentItemsInput;
};

export type MutationImportEstimateArgs = {
  input: ImportEstimateInput;
};

export type MutationImportOrgCatalogArgs = {
  input: ImportOrgCatalogInput;
};

export type MutationImportOrgMaterialsArgs = {
  input: ImportOrgMaterialsInput;
};

export type MutationImportPaymentMethodsArgs = {
  input: ImportPaymentMethodsInput;
};

export type MutationImportProjectTagsArgs = {
  input: ImportProjectTagsInput;
};

export type MutationImportServiceCodesArgs = {
  input: ImportServiceCodesInput;
};

export type MutationImportTaxCodesArgs = {
  import: ImportTaxCodesInput;
};

export type MutationImportVendorsArgs = {
  input: ImportVendorsInput;
};

export type MutationImportZonesArgs = {
  input: ImportZonesInput;
};

export type MutationInitializeSourceSystemConnectionArgs = {
  input: InitializeSourceSystemConnectionInput;
};

export type MutationLinkBudgetArgs = {
  input: LinkBudgetInput;
};

export type MutationLinkInvoiceArgs = {
  input: LinkInvoiceInput;
};

export type MutationLinkPoArgs = {
  input: LinkPoInput;
};

export type MutationLinkPOsArgs = {
  input: LinkPOsInput;
};

export type MutationLinkReceiptArgs = {
  input: LinkInvoiceInput;
};

export type MutationMarkMessageThreadAsReadArgs = {
  input: MarkMessageThreadAsReadInput;
};

export type MutationMarkReleaseItemNotesAsReadArgs = {
  input: MarkReleaseItemNotesAsReadInput;
};

export type MutationPreviewCustomInvoiceTemplatesArgs = {
  input?: InputMaybe<PreviewCustomInvoiceTemplateInput>;
};

export type MutationPreviewCustomPoTemplatesArgs = {
  input?: InputMaybe<PreviewCustomPoTemplateInput>;
};

export type MutationPreviewInvoiceTemplateArgs = {
  input?: InputMaybe<PreviewInvoiceTemplateInput>;
};

export type MutationPreviewPoTemplateArgs = {
  input?: InputMaybe<PreviewPoTemplateInput>;
};

export type MutationReadNotificationArgs = {
  input: ReadNotificationInput;
};

export type MutationRefreshExternalItemsArgs = {
  sourceSystem: SourceSystem;
};

export type MutationRefreshExternalPOsArgs = {
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type MutationRefreshExternalProjectsArgs = {
  sourceSystem: SourceSystem;
};

export type MutationRefreshExternalVendorsArgs = {
  sourceSystem: SourceSystem;
};

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};

export type MutationRejectReleaseArgs = {
  input: RejectReleaseInput;
};

export type MutationRematchInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationRemoveAssetArgs = {
  url: Scalars["String"]["input"];
};

export type MutationRemoveCostCodeArgs = {
  costCodeID: Scalars["ID"]["input"];
};

export type MutationRemoveEstimatedItemArgs = {
  itemId: Scalars["ID"]["input"];
};

export type MutationRemoveEstimatedItemsArgs = {
  input: RemoveEstimatedItemsInput;
};

export type MutationRemoveFromBuyoutArgs = {
  input: RemoveFromBuyoutInput;
};

export type MutationRemoveFromReleaseArgs = {
  input: RemoveFromReleaseInput;
};

export type MutationRemoveFromRfqArgs = {
  input: RemoveFromRfqInput;
};

export type MutationRemoveLocationArgs = {
  input: RemoveOrgLocationInput;
};

export type MutationRemoveOrgCatalogSkuArgs = {
  input: RemoveOrgCatalogSkuInput;
};

export type MutationRemoveOrgCatalogSkuEquivalentArgs = {
  input: MutateOrgCatalogSkuEquivalentInput;
};

export type MutationRemoveOrgPreferredVendorArgs = {
  vendorID: Scalars["ID"]["input"];
};

export type MutationRemoveProjectArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveRfqArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveTagArgs = {
  tagId: Scalars["ID"]["input"];
};

export type MutationRemoveZoneArgs = {
  zoneId: Scalars["ID"]["input"];
};

export type MutationRescanDeliverySlipArgs = {
  deliverySlipId: Scalars["ID"]["input"];
};

export type MutationRescanInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type MutationResendExternalNotificationArgs = {
  input: ResendExternalNotificationInput;
};

export type MutationResendQuoteArgs = {
  input: ResendQuoteInput;
};

export type MutationResendSmsVerificationArgs = {
  smsVerificationId: Scalars["ID"]["input"];
};

export type MutationResolveInvoiceIssueArgs = {
  input: ResolveInvoiceIssueInput;
};

export type MutationResolveInvoiceIssuesArgs = {
  input: ResolveInvoiceIssuesInput;
};

export type MutationResolveInvoiceKickbackArgs = {
  input: ResolveInvoiceKickbackInput;
};

export type MutationResolveReceiptKickbackArgs = {
  input: ResolveReceiptKickbackInput;
};

export type MutationResolveReleaseIssuesArgs = {
  input: ResolveReleaseIssuesInput;
};

export type MutationReverseSyncPoArgs = {
  poLinkId: Scalars["ID"]["input"];
};

export type MutationScheduleReleaseArgs = {
  input: ScheduleReleaseInput;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationSetVendorPricesArgs = {
  input: SetVendorPricesInput;
};

export type MutationSplitInvoiceDocumentArgs = {
  input: SplitInvoiceDocumentInput;
};

export type MutationSubmitBuyoutArgs = {
  input: SubmitBuyoutInput;
};

export type MutationSubmitQuoteArgs = {
  input: SubmitQuoteInput;
};

export type MutationSubmitReleaseArgs = {
  input: SubmitReleaseInput;
};

export type MutationSubmitRfqArgs = {
  input: SubmitRfqInput;
};

export type MutationSubmitSmsVerificationArgs = {
  input: SubmitSmsVerificationInput;
};

export type MutationSyncBudgetArgs = {
  budgetLinkId: Scalars["ID"]["input"];
};

export type MutationSyncPoArgs = {
  poLinkId: Scalars["ID"]["input"];
};

export type MutationUnarchiveInvoicesArgs = {
  invoiceIds: Array<Scalars["ID"]["input"]>;
};

export type MutationUnarchiveOrgMaterialArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUnarchiveReleaseTypeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUnarchiveReleasesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationUnlinkBudgetArgs = {
  budgetLinkId: Scalars["ID"]["input"];
};

export type MutationUnlinkInvoiceArgs = {
  invoiceLinkId: Scalars["ID"]["input"];
};

export type MutationUnlinkPoArgs = {
  poLinkId: Scalars["ID"]["input"];
};

export type MutationUnlinkReceiptArgs = {
  invoiceLinkId: Scalars["ID"]["input"];
};

export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};

export type MutationUpdateBudgetLinkArgs = {
  input: UpdateBudgetLinkInput;
};

export type MutationUpdateBuyoutServicePricesArgs = {
  input: UpdateBuyoutServicePricesInput;
};

export type MutationUpdateBuyoutTermsArgs = {
  input: UpdateBuyoutTermsInput;
};

export type MutationUpdateComplianceGroupsArgs = {
  input: UpdateComplianceGroupsInput;
};

export type MutationUpdateContractorBuyoutArgs = {
  input: UpdateContractorBuyoutInput;
};

export type MutationUpdateContractorReleaseArgs = {
  input: UpdateContractorReleaseInput;
};

export type MutationUpdateContractorReleasesArgs = {
  input: UpdateContractorReleasesInput;
};

export type MutationUpdateCostCodeArgs = {
  input: UpdateCostCodeInput;
};

export type MutationUpdateCostCodesArgs = {
  input: UpdateCostCodesInput;
};

export type MutationUpdateCostCodesWithFileArgs = {
  input: UpdateCostCodesWithFileInput;
};

export type MutationUpdateCostTypesArgs = {
  input: UpdateCostTypesInput;
};

export type MutationUpdateDeliverySlipArgs = {
  input: UpdateDeliverySlipInput;
};

export type MutationUpdateEnterpriseArgs = {
  input: UpdateEnterpriseInput;
};

export type MutationUpdateEquipmentItemsArgs = {
  input: UpdateEquipmentItemsInput;
};

export type MutationUpdateEstimatedItemArgs = {
  input: UpdateEstimatedItemInput;
};

export type MutationUpdateEstimatedItemsArgs = {
  input: UpdateEstimatedItemsInput;
};

export type MutationUpdateEstimatesWithFileArgs = {
  input: UpdateEstimatesWithFileInput;
};

export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};

export type MutationUpdateInvoiceEmailAttachmentArgs = {
  input: UpdateInvoiceEmailAttachmentInput;
};

export type MutationUpdateInvoiceFolderArgs = {
  input: UpdateInvoiceFolderInput;
};

export type MutationUpdateInvoiceIssueArgs = {
  input: UpdateInvoiceIssueInput;
};

export type MutationUpdateInvoiceKickbackArgs = {
  input: UpdateInvoiceKickbackInput;
};

export type MutationUpdateInvoiceKickbackCommentArgs = {
  input: UpdateInvoiceKickbackCommentInput;
};

export type MutationUpdateInvoicesArgs = {
  input?: InputMaybe<UpdateInvoicesInput>;
};

export type MutationUpdateLocationArgs = {
  input: UpdateOrgLocationInput;
};

export type MutationUpdateOrgArgs = {
  input: UpdateOrgInput;
};

export type MutationUpdateOrgCatalogSkuArgs = {
  input: UpdateOrgCatalogSkuInput;
};

export type MutationUpdateOrgMaterialsArgs = {
  input?: InputMaybe<UpdateOrgMaterialsInput>;
};

export type MutationUpdateOrgPreferredVendorArgs = {
  input: UpdateOrgPreferredVendorInput;
};

export type MutationUpdateOrgSettingsArgs = {
  input: UpdateOrgSettingsInput;
};

export type MutationUpdatePoLinkArgs = {
  input: UpdatePoLinkInput;
};

export type MutationUpdatePaymentMethodsArgs = {
  input: UpdatePaymentMethodsInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationUpdateProjectItemsArgs = {
  input: UpdateProjectItemsInput;
};

export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteInput;
};

export type MutationUpdateQuoteServicePriceSheetArgs = {
  input: UpdateServicePriceSheetInput;
};

export type MutationUpdateQuoteTermsArgs = {
  input: UpdateQuoteTermsInput;
};

export type MutationUpdateReceiptArgs = {
  input: UpdateInvoiceInput;
};

export type MutationUpdateReceiptKickbackArgs = {
  input: UpdateReceiptKickbackInput;
};

export type MutationUpdateReleaseGroupArgs = {
  input: UpdateReleaseGroupInput;
};

export type MutationUpdateReleaseTypeArgs = {
  input: UpdateReleaseTypeInput;
};

export type MutationUpdateRfqArgs = {
  input: UpdateRfqInput;
};

export type MutationUpdateServiceCodesArgs = {
  input: UpdateServiceCodesInput;
};

export type MutationUpdateSiteContactArgs = {
  input: UpdateSiteContactInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationUpdateTagsArgs = {
  input: UpdateTagsInput;
};

export type MutationUpdateTaxCodesArgs = {
  input: UpdateTaxCodesInput;
};

export type MutationUpdateUomArgs = {
  input: UpdateUomInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVendorBuyoutArgs = {
  input: UpdateVendorBuyoutInput;
};

export type MutationUpdateVendorLocationArgs = {
  input: UpdateOrgLocationInput;
};

export type MutationUpdateVendorOrgArgs = {
  input: UpdateVendorOrgInput;
};

export type MutationUpdateVendorPriceArgs = {
  input: UpdateVendorPriceInput;
};

export type MutationUpdateVendorReleaseArgs = {
  input: UpdateVendorReleaseInput;
};

export type MutationUpdateViewerProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateWarehouseArgs = {
  input: UpdateWarehouseInput;
};

export type MutationUpdateWarehouseReleaseArgs = {
  input: UpdateWarehouseReleaseInput;
};

export type MutationUpdateZoneArgs = {
  input: UpdateZoneInput;
};

export type MutationUploadAssetArgs = {
  input: UploadAssetInput;
};

export type MutationWithdrawQuoteArgs = {
  input: WithdrawQuoteInput;
};

export type Node = {
  id: Scalars["ID"]["output"];
};

/**
 * NoteDocuments is a contractor-uploaded document that is OCR'd and can
 * be used to create releases.
 */
export type NoteDocument = Node & {
  __typename?: "NoteDocument";
  asset: Asset;
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  items: Array<NoteDocumentItem>;
  orgLocation: OrgLocation;
  /**
   * scannedAt is the last timestamp when this note document has been scanned
   * from the asset. A null value indicates that the note document has not been
   * scanned yet.
   */
  scannedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type NoteDocumentItem = Node & {
  __typename?: "NoteDocumentItem";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  uom?: Maybe<Scalars["String"]["output"]>;
};

export type Notification = Node & {
  __typename?: "Notification";
  /** Context holds all notification data. */
  context: NotificationContext;
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  readAt?: Maybe<Scalars["Timestamp"]["output"]>;
  reminder: Scalars["Boolean"]["output"];
};

export type NotificationConnection = Connection & {
  __typename?: "NotificationConnection";
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type NotificationContext = {
  /** Type is used to discriminate between the context types a notification may have. */
  type: NotificationType;
};

export type NotificationContextBuyoutStatusChange = Node &
  NotificationContext & {
    __typename?: "NotificationContextBuyoutStatusChange";
    buyout: Buyout;
    event: BuyoutEvent;
    id: Scalars["ID"]["output"];
    initiatedBy?: Maybe<User>;
    /** The new status of the buyout at the time of the notification. */
    newStatus: BuyoutStatus;
    /** The old status of the buyout at the time of the notification. */
    oldStatus: BuyoutStatus;
    /** Type is always BUYOUT_STATUS_CHANGE. */
    type: NotificationType;
  };

export type NotificationContextInvoiceStatusChange = Node &
  NotificationContext & {
    __typename?: "NotificationContextInvoiceStatusChange";
    event: InvoiceEvent;
    id: Scalars["ID"]["output"];
    initiatedBy?: Maybe<User>;
    invoice: Invoice;
    /** The new status of the invoice at the time of the notification. */
    newStatus: InvoiceStatus;
    /** The old status of the invoice at the time of the notification. */
    oldStatus: InvoiceStatus;
    /** Type is always INVOICE_STATUS_CHANGE. */
    type: NotificationType;
  };

export type NotificationContextMessage = Node &
  NotificationContext & {
    __typename?: "NotificationContextMessage";
    id: Scalars["ID"]["output"];
    message: Message;
    /** Type is always MESSAGE. */
    type: NotificationType;
  };

export type NotificationContextQuoteStatusChange = Node &
  NotificationContext & {
    __typename?: "NotificationContextQuoteStatusChange";
    event: QuoteEvent;
    id: Scalars["ID"]["output"];
    initiatedBy?: Maybe<User>;
    quote: Quote;
    rfqOwner: OrgLocation;
    /**
     * The status of the quote at the time of notification.
     * Do not use the status field of the quote itself, as
     * that will change in time and the notification won't
     * show correct information.
     */
    status: QuoteStatus;
    /** Type is always QUOTE_STATUS_CHANGE. */
    type: NotificationType;
  };

export type NotificationContextReleaseStatusChange = Node &
  NotificationContext & {
    __typename?: "NotificationContextReleaseStatusChange";
    event: ReleaseEvent;
    id: Scalars["ID"]["output"];
    initiatedBy?: Maybe<User>;
    newStatus: ReleaseStatus;
    /**
     * We need to keep both the old and new status of the release in order to
     * disambiguate state transitions that have the same new (target) status.
     */
    oldStatus: ReleaseStatus;
    release: Release;
    /** Type is always RELEASE_STATUS_CHANGE. */
    type: NotificationType;
  };

export type NotificationEdge = Edge & {
  __typename?: "NotificationEdge";
  cursor: Scalars["String"]["output"];
  node: Notification;
};

export type NotificationSettings = {
  __typename?: "NotificationSettings";
  excludedBuyoutEvents: Array<BuyoutEvent>;
  excludedInvoiceEvents: Array<InvoiceEvent>;
  excludedMessageContexts: Array<MessageContextKind>;
  excludedQuoteEvents: Array<QuoteEvent>;
  /** A contractor organization's excluded notification events. */
  excludedReleaseEvents: Array<ReleaseEvent>;
};

export enum NotificationType {
  BuyoutStatusChange = "BUYOUT_STATUS_CHANGE",
  InvoiceStatusChange = "INVOICE_STATUS_CHANGE",
  Message = "MESSAGE",
  QuoteStatusChange = "QUOTE_STATUS_CHANGE",
  ReleaseStatusChange = "RELEASE_STATUS_CHANGE",
}

export type OrgCatalogSku = Node & {
  __typename?: "OrgCatalogSku";
  asSearchTerms: Array<Scalars["String"]["output"]>;
  createdAt: Scalars["Timestamp"]["output"];
  customPartNumber?: Maybe<Scalars["String"]["output"]>;
  defaultManufacturer?: Maybe<Manufacturer>;
  defaultUom: Uom;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  equivalentsCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  mappedCategories: Array<ProductCategory>;
  masterProducts: Array<Product>;
  masterSkus: Array<Sku>;
  materialCostCode?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  orgID: Scalars["ID"]["output"];
  sectionCode?: Maybe<Scalars["String"]["output"]>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
  sellerPrice: Array<UnitPrice>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type OrgCatalogSkuSellerPriceArgs = {
  sellerOrgLocation: Scalars["ID"]["input"];
};

export type OrgCatalogSkuConnection = Connection & {
  __typename?: "OrgCatalogSkuConnection";
  edges: Array<OrgCatalogSkuEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OrgCatalogSkuEdge = Edge & {
  __typename?: "OrgCatalogSkuEdge";
  cursor: Scalars["String"]["output"];
  node: OrgCatalogSku;
};

export type OrgCatalogSkuMappingFilter = {
  categoryID?: InputMaybe<Scalars["ID"]["input"]>;
  selection?: InputMaybe<OrgCatalogSkuMappingFilterSelection>;
};

export enum OrgCatalogSkuMappingFilterSelection {
  All = "ALL",
  Archived = "ARCHIVED",
  Mapped = "MAPPED",
  NotMapped = "NOT_MAPPED",
}

export type OrgCatalogSortColumn = {
  column: OrgCatalogSortColumnName;
  descending?: Scalars["Boolean"]["input"];
};

export enum OrgCatalogSortColumnName {
  Name = "NAME",
  Recency = "RECENCY",
}

export type OrgLocation = Node & {
  __typename?: "OrgLocation";
  address?: Maybe<Address>;
  assignedUserCount: Scalars["Int"]["output"];
  contractorOrg?: Maybe<Organization>;
  divisionCode?: Maybe<Scalars["String"]["output"]>;
  equipment: Array<Equipment>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  org: Organization;
  permissions: OrgLocationPermissions;
  phone?: Maybe<Scalars["String"]["output"]>;
  projects: Array<Project>;
};

export type OrgLocationConnection = Connection & {
  __typename?: "OrgLocationConnection";
  edges: Array<OrgLocationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OrgLocationDefaultProjectTeam = {
  __typename?: "OrgLocationDefaultProjectTeam";
  orgLocation: OrgLocation;
  users: Array<User>;
};

export type OrgLocationDefaultProjectTeamInput = {
  orgLocationId: Scalars["ID"]["input"];
  userIds: Array<Scalars["ID"]["input"]>;
};

export type OrgLocationEdge = Edge & {
  __typename?: "OrgLocationEdge";
  cursor: Scalars["String"]["output"];
  node: OrgLocation;
};

export type OrgLocationPermissions = {
  __typename?: "OrgLocationPermissions";
  createBuyout: AuthorizationStatus;
  createDeliverySlip: AuthorizationStatus;
  createInvoice: AuthorizationStatus;
  createProject: AuthorizationStatus;
  createQuoteDocument: AuthorizationStatus;
  createRelease: AuthorizationStatus;
  createRfq: AuthorizationStatus;
  reserveRelease: AuthorizationStatus;
  submitReleaseDirectly: AuthorizationStatus;
};

export enum OrgLocationRole {
  LocationAccountant = "LOCATION_ACCOUNTANT",
  LocationAdmin = "LOCATION_ADMIN",
  LocationEstimator = "LOCATION_ESTIMATOR",
  LocationForeman = "LOCATION_FOREMAN",
  LocationOwner = "LOCATION_OWNER",
  LocationProjectEngineer = "LOCATION_PROJECT_ENGINEER",
  LocationProjectManager = "LOCATION_PROJECT_MANAGER",
  LocationPurchasingAgent = "LOCATION_PURCHASING_AGENT",
  LocationReceivingStaff = "LOCATION_RECEIVING_STAFF",
  LocationSuperintendent = "LOCATION_SUPERINTENDENT",
  LocationWarehouseManager = "LOCATION_WAREHOUSE_MANAGER",
}

/**
 * The org-scoped version of the Material type. This provides additional general
 * customization options for the material at the scope of the organization.
 */
export type OrgMaterial = Node & {
  __typename?: "OrgMaterial";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * Conversions applicable to this material.
   *
   * When toDefault is set to true, only such conversions are returned, that
   * have to-UOM equal to the default UOM of the underlying material.
   */
  conversions: Array<MaterialConversion>;
  /**
   * The default cost code associated with this material. If a project ID is
   * provided, the cost code will be scoped to the project.
   */
  costCode?: Maybe<CostCode>;
  /**
   * The default estimate UOM used by this material at the scope of this
   * organization.
   *
   * This returns the default estimate UOM of the underlying material (Org
   * Catalog SKU, Master SKU, or Master Product).
   */
  defaultEstimateUom: Uom;
  /** Optional field used for accounting software integration. */
  externalCode?: Maybe<Scalars["String"]["output"]>;
  /** ExternalItems that are connected (mapped) to this org material */
  externalItems: Array<ExternalItem>;
  /** Unique identifier for the material. */
  id: Scalars["ID"]["output"];
  /**
   * A list of project-scoped materials that reference this org-scoped material.
   * Use the `projectID` argument to filter by project.
   */
  items: Array<ProjectItem>;
  /** The material referenced by this node. */
  material: Material;
  /** Organization that owns the material. */
  organization: Organization;
  /** Item position on spreadsheet view */
  position?: Maybe<Scalars["Int"]["output"]>;
  referenceCounts: MaterialReferenceCounts;
  vendorPrices: Array<VendorPrice>;
};

/**
 * The org-scoped version of the Material type. This provides additional general
 * customization options for the material at the scope of the organization.
 */
export type OrgMaterialConversionsArgs = {
  toDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * The org-scoped version of the Material type. This provides additional general
 * customization options for the material at the scope of the organization.
 */
export type OrgMaterialCostCodeArgs = {
  projectID?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * The org-scoped version of the Material type. This provides additional general
 * customization options for the material at the scope of the organization.
 */
export type OrgMaterialItemsArgs = {
  projectID?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * The org-scoped version of the Material type. This provides additional general
 * customization options for the material at the scope of the organization.
 */
export type OrgMaterialVendorPricesArgs = {
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrgMaterialConnection = Connection & {
  __typename?: "OrgMaterialConnection";
  edges: Array<OrgMaterialEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OrgMaterialEdge = Edge & {
  __typename?: "OrgMaterialEdge";
  cursor: Scalars["String"]["output"];
  node: OrgMaterial;
};

export type OrgPreferredVendor = Node & {
  __typename?: "OrgPreferredVendor";
  contacts: Array<VendorContact>;
  costCode?: Maybe<CostCode>;
  createdAt: Scalars["Timestamp"]["output"];
  /** default payment term in days. This value is imported from the external vendor(Vista) */
  defaultPaymentTerm?: Maybe<Scalars["Int"]["output"]>;
  defaultReleaseType?: Maybe<ReleaseType>;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** Optional field used for accounting software integration. */
  externalCode?: Maybe<Scalars["String"]["output"]>;
  externalVendors: Array<ExternalVendor>;
  id: Scalars["ID"]["output"];
  internal: Scalars["Boolean"]["output"];
  /** @deprecated use externalVendors instead */
  mappings: Array<SourceSystemMapping>;
  org: Organization;
  sellerOrgLocation: OrgLocation;
  taxExempt: Scalars["Boolean"]["output"];
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type OrgPreferredVendorConnection = Connection & {
  __typename?: "OrgPreferredVendorConnection";
  edges: Array<OrgPreferredVendorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OrgPreferredVendorEdge = Edge & {
  __typename?: "OrgPreferredVendorEdge";
  cursor: Scalars["String"]["output"];
  node: OrgPreferredVendor;
};

export enum OrgRole {
  OrgAccountant = "ORG_ACCOUNTANT",
  OrgAdmin = "ORG_ADMIN",
  OrgMember = "ORG_MEMBER",
  OrgRestrictedAdmin = "ORG_RESTRICTED_ADMIN",
  OrgRestrictedMember = "ORG_RESTRICTED_MEMBER",
  OrgWarehouseManager = "ORG_WAREHOUSE_MANAGER",
}

export type OrgSettings = {
  __typename?: "OrgSettings";
  display?: Maybe<DisplaySettings>;
  documentReader: DocumentReaderSettings;
  integrations: IntegrationSettings;
  inventory: InventorySettings;
  invoices: InvoiceSettings;
  notifications: NotificationSettings;
  org: Organization;
  projectSettings: ProjectSettings;
  releaseApproval: ReleaseApprovalSettings;
  releases?: Maybe<ReleaseSettings>;
};

export enum OrgType {
  Contractor = "CONTRACTOR",
  Distributor = "DISTRIBUTOR",
}

export type Organization = Node & {
  __typename?: "Organization";
  /**
   * All the product categories of the SKUs in the org's catalog.
   * Query this field to get the relevant categories that the user can
   * filter the catalog by.
   */
  catalogCategories: Array<ProductCategory>;
  complianceGroups: Array<ComplianceGroup>;
  /**
   * An organization that is contractor scoped can have a list of preferred vendors.
   * This field will be null for global scoped organizations.
   */
  contractorOrg?: Maybe<Organization>;
  costCodes: Array<CostCode>;
  costTypes: Array<CostType>;
  ein?: Maybe<Scalars["String"]["output"]>;
  enterprise?: Maybe<Enterprise>;
  equipmentItems: Array<EquipmentItem>;
  favoriteCombinations: Array<FavoriteCombination>;
  hqAddress?: Maybe<Address>;
  id: Scalars["ID"]["output"];
  inventoryItems: Array<InventoryItem>;
  invoiceFolders: Array<InvoiceFolder>;
  locations: Array<OrgLocation>;
  /** @deprecated use photoUrl instead */
  logoImageUrl?: Maybe<Scalars["String"]["output"]>;
  materials: Array<OrgMaterial>;
  name: Scalars["String"]["output"];
  /**
   * Retrieve the number of unread notifications for the corresponding org.
   * Returns zero if the user is not assigned to the org.
   *
   * The limit specifies out of how many of the latest notifications should the unread ones be count.
   * This is important to stay consistent with the UI — that is, if the UI only shows the latest 10
   * notifications only, it would be confusing and inconsistent to count unread notifications from
   * a set larger than 10 (and as a consequence to possibly show more than 10 unread notifications
   * which wouldn't appear in the UI).
   */
  notificationCount: Scalars["Int"]["output"];
  /** Organization number. This is a unique identifier for the organization in an enterprise. */
  number?: Maybe<Scalars["Int"]["output"]>;
  paymentMethods: Array<PaymentMethod>;
  phone?: Maybe<Scalars["String"]["output"]>;
  photoUrl?: Maybe<Scalars["String"]["output"]>;
  predictedJobNumber?: Maybe<Scalars["String"]["output"]>;
  predictedReleaseSequenceNumber: Scalars["Int"]["output"];
  releaseGroups: Array<ReleaseGroup>;
  releaseTypes: Array<ReleaseType>;
  serviceCodes: Array<ServiceCode>;
  /** Organization settings (CONTRACTOR orgs only). */
  settings?: Maybe<OrgSettings>;
  taxCodes: Array<TaxCode>;
  type: OrgType;
  uoms: Array<Uom>;
  userCount: Scalars["Int"]["output"];
  vendorPrices: Array<VendorPrice>;
  warehouses: Array<Warehouse>;
};

export type OrganizationCostCodesArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrganizationInventoryItemsArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrganizationNotificationCountArgs = {
  limit: Scalars["Int"]["input"];
};

export type OrganizationReleaseTypesArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrganizationTaxCodesArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrganizationWarehousesArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * This type is deprecated. Use poNumber at Release/Buyout level before exporting
 * the purchase order or POLink post-export.
 */
export type Po = Node & {
  __typename?: "PO";
  id: Scalars["ID"]["output"];
  number: Scalars["String"]["output"];
  org: Organization;
};

export enum PoFormat {
  Basic = "BASIC",
  Detail = "DETAIL",
}

export type PoItemLink = Node & {
  __typename?: "POItemLink";
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  poItem: ExternalPoItem;
  poItemExternalId: Scalars["ID"]["output"];
  poLink: PoLink;
  releaseItem: ReleaseItem;
};

/**
 * POLink represents a link between a release/buyout and a PO in an external source system.
 * This type also supports synchronization of a release to the source system.
 */
export type PoLink = Node & {
  __typename?: "POLink";
  /**
   * When autoSync is set to true, updates made the the release will be
   * automatically synced to the source system.
   */
  autoSync: Scalars["Boolean"]["output"];
  /**
   * Error that occurred during the last auto-sync attempt.
   *
   * Present only if the last sync attempt failed.
   *
   * Example scenario:
   * 1. A release (version 20 is connected to the source system (DETAIL, to
   * warehouse) and synced successfully.
   * 2. A new item was added to a release (bumping the version to 21), underlying
   * OrgMaterial of which does not have an external item connected to it.
   * 3. When the sync job picks up the release, it will fail due to a validation
   * error, thus setting this field to VALIDATION_ERROR.
   */
  autoSyncError?: Maybe<Scalars["String"]["output"]>;
  /** Number of consecutive auto-sync failures. */
  autoSyncFailureCount: Scalars["Int"]["output"];
  batch?: Maybe<ExternalBatch>;
  batchId?: Maybe<Scalars["ID"]["output"]>;
  buyout?: Maybe<Buyout>;
  buyoutId?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["Timestamp"]["output"];
  createdBy?: Maybe<User>;
  externalPoId?: Maybe<Scalars["ID"]["output"]>;
  externalWarehouseId?: Maybe<Scalars["ID"]["output"]>;
  failedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  failedMessage?: Maybe<Scalars["String"]["output"]>;
  format: PoFormat;
  /** Indicates the data mapping format version used to populate the PO contents. */
  formatVersion: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  /**
   * Release is imported from an ERP integration and should be treated as
   * immutable.
   */
  immutable: Scalars["Boolean"]["output"];
  po: ExternalPo;
  release?: Maybe<Release>;
  releaseId?: Maybe<Scalars["ID"]["output"]>;
  /**
   * This flag indicates that the PO was linked to the release retroactively when
   * exporting an invoice.
   */
  retroactive: Scalars["Boolean"]["output"];
  sourceSystem: SourceSystem;
  /** Timestamp of the last successful sync. */
  syncedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** Version of the release that has been successfully synced to the source system. */
  syncedVersion: Scalars["Int"]["output"];
  /** Syncing flag indicates that the release is currently locked for synchronization. */
  syncing: Scalars["Boolean"]["output"];
  /** In case of async linking, validation errors will be stored in this field. */
  validationErrors: Array<PoValidationError>;
};

export type PoLinkGroup = Node & {
  __typename?: "POLinkGroup";
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  links: Array<PoLink>;
};

export type PoMaterialConversion = {
  __typename?: "POMaterialConversion";
  fulfillmentUom: Uom;
  material: OrgMaterial;
  purchasingUom: Scalars["String"]["output"];
  /**
   * Quantity conversion multiplier between the release item UOM and the
   * purchasing of the connected external item.
   * Null in case that the conversion does not exist (see materialConversions
   * field of POValidation).
   */
  quantityMultiplier?: Maybe<Scalars["Decimal"]["output"]>;
};

export enum PoNumberingMode {
  Always = "ALWAYS",
  Never = "NEVER",
  Optional = "OPTIONAL",
}

export type PoValidation = {
  __typename?: "POValidation";
  /** Buyout items that are missing a cost code. */
  buyoutItemsMissingCostCodes: Array<BuyoutItem>;
  /** Buyout items that have a zero price in the exported buyout. */
  buyoutItemsMissingPrices: Array<BuyoutItem>;
  /**
   * Cost codes that exist at the org level, but are not explicitly assigned to
   * the exported release.
   * This is treated as a warning.
   * @deprecated use missingBudgetCombinations instead
   */
  costCodesNotAssignedToProject: Array<CostCode>;
  /**
   * Cost codes referenced by release items in the exported release that are
   * not connected to an external cost code in the source system.
   */
  costCodesNotConnected: Array<CostCode>;
  errors: Array<PoValidationError>;
  /** Release items that are missing a cost code. */
  itemsMissingCostCodes: Array<ReleaseItem>;
  /** Release items that don't have a price set in the exported release. */
  itemsMissingPrices: Array<ReleaseItem>;
  /**
   * Release items that are missing tags containing cost code + cost type info.
   * Only applicable when SourceSystemSettings.wbsTags is set to true.
   */
  itemsMissingTags: Array<ReleaseItem>;
  /**
   * Returns material conversions for all distinct org material + release item UOM
   * combinations present within the validated release.
   * Only includes such org materials that are mapped to external items in the
   * source system.
   * Should be treated as a validation error in case that at least one result
   * has a null quantityMultiplier field. In such case, missing conversions can
   * be created via createMaterialConversions.
   */
  materialConversions: Array<PoMaterialConversion>;
  /**
   * Org materials referenced by release items in the exported release that are
   * missing a cost code.
   * @deprecated use itemsMissingCostCodes
   */
  materialsMissingCostCodes: Array<OrgMaterial>;
  /**
   * Org materials referenced by release items in the exported release that are
   * not connected to an external item in the source system.
   * Only applicable to DETAIL PO exports with externalWarehouseId set.
   */
  materialsNotConnected: Array<OrgMaterial>;
  /** Budget combinations that are not allowed for the project in the ERP. */
  missingBudgetCombinations: Array<BudgetCombination>;
  warnings: Array<PoValidationWarning>;
};

export enum PoValidationError {
  /** All release items are non-taxable and the release has a non-zero custom tax amount */
  AllItemsNonTaxable = "ALL_ITEMS_NON_TAXABLE",
  /** Only buyouts with the ACTIVE status can be linked to a PO. */
  BuyoutNotActive = "BUYOUT_NOT_ACTIVE",
  CostCodesNotConnected = "COST_CODES_NOT_CONNECTED",
  /**
   * A PO with the specified number already exists in the source system, but its
   * format (BASIC/DETAIL) is different than the one with which it is being
   * exported.
   */
  FormatMismatch = "FORMAT_MISMATCH",
  /** The integration associated with the selected source system is not configured. */
  IntegrationNotConfigured = "INTEGRATION_NOT_CONFIGURED",
  /** Tax mode not configured correctly (ie Tax mode Column on a system that don't support it) */
  InvalidTaxMode = "INVALID_TAX_MODE",
  MaterialsMissingConversions = "MATERIALS_MISSING_CONVERSIONS",
  MaterialsNotConnected = "MATERIALS_NOT_CONNECTED",
  /** The releases parent buyout has a PO already linked to it */
  PoBuyoutBasedAlreadyExists = "PO_BUYOUT_BASED_ALREADY_EXISTS",
  /**
   * A PO with the specified number is already in use in the source system and
   * is in an immutable state, thus cannot be updated.
   */
  PoImmutable = "PO_IMMUTABLE",
  /** The specified PO number contains invalid characters for the source system. */
  PoNumberInvalid = "PO_NUMBER_INVALID",
  /** The specified PO number is too long for the source system. */
  PoNumberTooLong = "PO_NUMBER_TOO_LONG",
  /** Some release associated with the buyout has a PO already linked to it */
  PoReleaseBasedAlreadyExists = "PO_RELEASE_BASED_ALREADY_EXISTS",
  /**
   * A PO with the specified number is already in use in the source system and
   * is assigned to a different project than the one associated with the exported
   * release.
   */
  ProjectMismatch = "PROJECT_MISMATCH",
  /** The order has no project assigned. */
  ProjectNotAssigned = "PROJECT_NOT_ASSIGNED",
  /**
   * The project to which the exported release belongs is not connected to an
   * external project in the source system.
   */
  ProjectNotConnected = "PROJECT_NOT_CONNECTED",
  ReleaseItemsMissingCostCodes = "RELEASE_ITEMS_MISSING_COST_CODES",
  ReleaseItemsMissingPrices = "RELEASE_ITEMS_MISSING_PRICES",
  /**
   * The PO format of the associated Release Type is not supported. For Buyouts, only
   * the BASIC format is supported.
   */
  ReleaseTypePoFormatNotSupported = "RELEASE_TYPE_PO_FORMAT_NOT_SUPPORTED",
  /**
   * A PO with the specified number already exists in the source system and the
   * following conditions are met:
   * 1. the PO contains at least one item from outside of the exported release;
   * 2. the sales tax rate associated with the exported release is different than
   * the one associated with the PO.
   * In case that 2. is met, but 1. is not, the PO will be updated with the tax
   * code of the exported release.
   */
  TaxCodeMismatch = "TAX_CODE_MISMATCH",
  /**
   * The tax code corresponding to sales tax rate of the exported release is not
   * present in the source system.
   */
  TaxCodeMissing = "TAX_CODE_MISSING",
  /** Tax code is not connected to a Release. */
  TaxCodeNotAssigned = "TAX_CODE_NOT_ASSIGNED",
  /** Tax code is not connected to an ExternalTaxCode. */
  TaxCodeNotConnected = "TAX_CODE_NOT_CONNECTED",
  /**
   * A PO with the specified number is already in use in the source system and
   * is assigned to a different vendor than the one associated with the exported
   * release.
   */
  VendorMismatch = "VENDOR_MISMATCH",
  /** There is no org preferred vendor associated with this release. */
  VendorMissing = "VENDOR_MISSING",
  /**
   * The org preferred vendor associated with the exported release is not
   * connected to an external vendor in the source system.
   */
  VendorNotConnected = "VENDOR_NOT_CONNECTED",
}

export enum PoValidationWarning {
  /**
   * The PO with the specified number is already in use in the source system and
   * is not directly mutable. A Change Order will be created and applied against
   * the existing PO.
   */
  ChangeOrder = "CHANGE_ORDER",
}

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor: Scalars["String"]["output"];
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor: Scalars["String"]["output"];
};

/**
 * Represents a range of pages in a document.
 * Pages are numbered starting from 1.
 */
export type PageRange = {
  __typename?: "PageRange";
  end: Scalars["Int"]["output"];
  start: Scalars["Int"]["output"];
};

export type PageRangeInput = {
  end: Scalars["Int"]["input"];
  start: Scalars["Int"]["input"];
};

export type PaymentMethod = Node & {
  __typename?: "PaymentMethod";
  default: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  externalBankAccount?: Maybe<ExternalBankAccount>;
  id: Scalars["ID"]["output"];
  number?: Maybe<Scalars["String"]["output"]>;
  type: PaymentMethodType;
};

export enum PaymentMethodType {
  BankAccount = "BANK_ACCOUNT",
  Cash = "CASH",
  CheckingAccount = "CHECKING_ACCOUNT",
  CreditCard = "CREDIT_CARD",
  Eft = "EFT",
}

export type PaymentTerm = Node & {
  __typename?: "PaymentTerm";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type PlasterPackaging = {
  __typename?: "PlasterPackaging";
  recipient: Scalars["String"]["output"];
  size: Scalars["Float"]["output"];
  uom: Uom;
};

export type PlasterProductDetail = Node &
  ProductDetail & {
    __typename?: "PlasterProductDetail";
    id: Scalars["ID"]["output"];
    packaging: PlasterPackaging;
    productId: Scalars["ID"]["output"];
  };

export type PredictedTaxInput = {
  projectId: Scalars["ID"]["input"];
  sellerOrgLocationId: Scalars["ID"]["input"];
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  willCall: Scalars["Boolean"]["input"];
};

export type PredictedTaxOutput = {
  __typename?: "PredictedTaxOutput";
  taxRate?: Maybe<Scalars["Decimal"]["output"]>;
};

export type PreviewCustomInvoiceTemplateInput = {
  approver?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  buyout?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  externalVendor?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  invoice?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  project?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  release?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  templates?: InputMaybe<Array<CustomTemplateInput>>;
};

export type PreviewCustomInvoiceTemplateInputField = {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type PreviewCustomPoTemplateInput = {
  buyout?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  externalVendor?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  project?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  release?: InputMaybe<Array<PreviewCustomInvoiceTemplateInputField>>;
  templates?: InputMaybe<Array<CustomTemplateInput>>;
};

export type PreviewInvoiceTemplateInput = {
  ApproverEmail?: InputMaybe<Scalars["String"]["input"]>;
  ApproverName?: InputMaybe<Scalars["String"]["input"]>;
  BuyoutClientIdentifier?: Scalars["String"]["input"];
  BuyoutDescription?: InputMaybe<Scalars["String"]["input"]>;
  InvoiceDescription?: InputMaybe<Scalars["String"]["input"]>;
  InvoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  InvoicePONumber?: InputMaybe<Scalars["String"]["input"]>;
  ProjectJobNumber?: InputMaybe<Scalars["String"]["input"]>;
  ProjectName?: Scalars["String"]["input"];
  ReleaseDescription?: InputMaybe<Scalars["String"]["input"]>;
  ReleaseSeqNumber?: InputMaybe<Scalars["Int"]["input"]>;
  Template: Scalars["String"]["input"];
  VendorAlternateName?: InputMaybe<Scalars["String"]["input"]>;
  VendorName?: Scalars["String"]["input"];
};

export type PreviewPoTemplateInput = {
  BuyoutClientIdentifier?: Scalars["String"]["input"];
  BuyoutDescription?: InputMaybe<Scalars["String"]["input"]>;
  ProjectJobNumber?: InputMaybe<Scalars["String"]["input"]>;
  ProjectName?: Scalars["String"]["input"];
  ReleaseDescription?: InputMaybe<Scalars["String"]["input"]>;
  ReleasePONumber?: InputMaybe<Scalars["String"]["input"]>;
  ReleaseSeqNumber?: InputMaybe<Scalars["Int"]["input"]>;
  Template: Scalars["String"]["input"];
  VendorAlternateName?: InputMaybe<Scalars["String"]["input"]>;
  VendorName?: Scalars["String"]["input"];
};

export type Product = Node & {
  __typename?: "Product";
  categories: Array<ProductCategory>;
  description: Scalars["String"]["output"];
  detail: ProductDetail;
  id: Scalars["ID"]["output"];
  imageUrl: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  skus: Array<Sku>;
  uom: Uom;
};

export type ProductCategory = Node & {
  __typename?: "ProductCategory";
  id: Scalars["ID"]["output"];
  l1Name: Scalars["String"]["output"];
  l2Name?: Maybe<Scalars["String"]["output"]>;
  l3Name?: Maybe<Scalars["String"]["output"]>;
  l4Name?: Maybe<Scalars["String"]["output"]>;
};

export type ProductConnection = Connection &
  MasterCatalogConnection & {
    __typename?: "ProductConnection";
    edges: Array<ProductEdge>;
    facets: Array<Facet>;
    pageInfo: PageInfo;
    searchContext?: Maybe<SearchContext>;
    totalCount: Scalars["Int"]["output"];
  };

export type ProductDetail = {
  productId: Scalars["ID"]["output"];
};

export type ProductEdge = Edge & {
  __typename?: "ProductEdge";
  cursor: Scalars["String"]["output"];
  node: Product;
};

export type Project = Node & {
  __typename?: "Project";
  address: Address;
  aggregatedZones: Array<AggregatedZone>;
  allowance: ProjectAllowance;
  /**
   * Returns a list of cost codes assigned to this project via ProjectAllowance.
   * All cost codes are returned if restrictCostCodes is false.
   */
  allowedCostCodes?: Maybe<Array<CostCode>>;
  /** @deprecated use allowance instead */
  budget?: Maybe<Scalars["Decimal"]["output"]>;
  budgetLink?: Maybe<BudgetLink>;
  buyoutCount: Scalars["Int"]["output"];
  buyouts: Array<Buyout>;
  /**
   * Returns a list of cost codes available to this project. If the project
   * has usesProjectCostCodes set to true, it returns the project specific cost
   * codes, else it returns the org cost codes.
   */
  costCodes?: Maybe<Array<CostCode>>;
  createdAt: Scalars["Timestamp"]["output"];
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  estimatedItemGroups: Array<EstimatedItemGroup>;
  estimatedItemsCount: Scalars["Int"]["output"];
  externalProjects: Array<ExternalProject>;
  id: Scalars["ID"]["output"];
  items: Array<ProjectItem>;
  jobNumber?: Maybe<Scalars["String"]["output"]>;
  location: OrgLocation;
  /** @deprecated use externalProjects instead */
  mappings: Array<SourceSystemMapping>;
  materialCount: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  /**
   * The total amount of SCHEDULED and COMPLETED releases in this project.
   * @deprecated use orderedAmounts instead
   */
  orderedAmount: Scalars["Decimal"]["output"];
  orderedAmounts: ProjectOrderedAmounts;
  /**
   * How much of the budget was spent on buyouts.
   * Null if the project has no buyouts. This value is the result
   * of the division between totalAmount and budget: will be 1
   * if the whole allocated amount was spent, 0.5 if 50% of it was spent,
   * 1.3 if 130% of it was spent etc.
   */
  percentageOfBudgetSpent?: Maybe<Scalars["Decimal"]["output"]>;
  permissions: ProjectPermissions;
  /** Used to override a job code with one used solely for PO # generation */
  poJobNumber?: Maybe<Scalars["String"]["output"]>;
  /**
   * Returns the same value as Release.predictedPoNumber of a release belonging
   * to the project.
   */
  predictedPoNumber?: Maybe<Scalars["String"]["output"]>;
  /** Only used for pre-filling dropdowns in the UI */
  preferredInventoryInflows: Scalars["Boolean"]["output"];
  releaseCount: Scalars["Int"]["output"];
  releaseInstructions?: Maybe<Instruction>;
  releases: Array<Release>;
  /**
   * When set to true, only cost codes assigned via allowance->costCodes are
   * allowed to be assigned to estimated/RFQ/buyout/release items in the project.
   */
  restrictCostCodes: Scalars["Boolean"]["output"];
  rfqCount: Scalars["Int"]["output"];
  siteContact?: Maybe<SiteContact>;
  startDate?: Maybe<Scalars["Timestamp"]["output"]>;
  status: ProjectStatus;
  tags: Array<ProjectTag>;
  taxExempt: Scalars["Boolean"]["output"];
  team: Array<User>;
  /**
   * How much was spent on buyouts, in total.
   * 0 if the project has no buyouts.
   */
  totalAmount: Scalars["Decimal"]["output"];
  /**
   * Whether to use project cost codes for estimated/RFQ/buyout/release items.
   * The usage of project cost codes is mutually exclusive with org cost codes.
   */
  usesProjectCostCodes: Scalars["Boolean"]["output"];
  zones: Array<Zone>;
};

export type ProjectAggregatedZonesArgs = {
  excludePhantoms?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProjectExternalProjectsArgs = {
  sourceSystem?: InputMaybe<SourceSystem>;
};

export type ProjectItemsArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProjectTagsArgs = {
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProjectAllowance = {
  __typename?: "ProjectAllowance";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  costCodes: Array<CostCodeAllowance>;
  tags: Array<TagAllowance>;
};

export type ProjectConnection = Connection & {
  __typename?: "ProjectConnection";
  edges: Array<ProjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ProjectEdge = Edge & {
  __typename?: "ProjectEdge";
  cursor: Scalars["String"]["output"];
  node: Project;
};

/**
 * The project-scoped variant of the Org Material type. This provides additional
 * customization options for the material at the scope of the project, on top of
 * the customization options provided by the org-scoped material.
 *
 * The Project Item is a combination of an Org Material used within a certain
 * Project and with a specific Estimate UOM.
 */
export type ProjectItem = Node & {
  __typename?: "ProjectItem";
  buyoutItems: Array<BuyoutItem>;
  /** The estimate UOM used by this material, in the scope of this project. */
  estimateUom: Uom;
  estimatedItems: Array<EstimatedItem>;
  /**
   * Generated based on estimates. This is null if no estimates exist.
   * @deprecated Use estimatedQuantityDecimal instead.
   */
  estimatedQuantity?: Maybe<Scalars["Float"]["output"]>;
  estimatedQuantityDecimal?: Maybe<Scalars["Decimal"]["output"]>;
  /** Unique identifier for the material. */
  id: Scalars["ID"]["output"];
  /**
   * The manufacturer set on a project item serves as a blueprint for new RFQ
   * Items and Buyout Items that reference it.
   * @deprecated Use EstimatedItem->manufacturer instead.
   */
  manufacturer?: Maybe<Manufacturer>;
  /** The underlying org-scoped material. */
  material: OrgMaterial;
  /** The project that owns the material. */
  project: Project;
  releaseItems: Array<ReleaseItem>;
  /**
   * This flag indicates whether the user has set this Project Item to use the
   * same UOMs both for Estimates and Releases.
   *
   * true:
   * `releaseUomConversions` will return the applicable UOM conversions of the
   * underlying Org Material that have `fromUOM == estimateUom`.
   * false:
   * `releaseUomConversions` will return a single conversion    with the same
   * `fromUOM` and `toUOM` and a multiplier of 1.
   */
  releaseUomIsEstimateUom: Scalars["Boolean"]["output"];
  requisitionItems: Array<RequisitionItem>;
  rfqItems: Array<RfqItem>;
};

/**
 * The project-scoped variant of the Org Material type. This provides additional
 * customization options for the material at the scope of the project, on top of
 * the customization options provided by the org-scoped material.
 *
 * The Project Item is a combination of an Org Material used within a certain
 * Project and with a specific Estimate UOM.
 */
export type ProjectItemReleaseItemsArgs = {
  status?: InputMaybe<Array<ReleaseStatus>>;
};

/**
 * ProjectItemInput is a helper input which contains information identifying a
 * particular project's material and its UOM.
 * That's why ProjectItemInput is embedded in EstimatedItem mutations' inputs.
 */
export type ProjectItemInput = {
  estimateUom: Scalars["String"]["input"];
  masterProductId?: InputMaybe<Scalars["ID"]["input"]>;
  masterSkuId?: InputMaybe<Scalars["ID"]["input"]>;
  orgCatalogSkuId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProjectOrderedAmounts = {
  __typename?: "ProjectOrderedAmounts";
  gross?: Maybe<Scalars["Decimal"]["output"]>;
  net?: Maybe<Scalars["Decimal"]["output"]>;
  tax?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProjectPermissions = {
  __typename?: "ProjectPermissions";
  addEstimatedItems: AuthorizationStatus;
  archive: AuthorizationStatus;
  assignUsers: AuthorizationStatus;
  createBuyout: AuthorizationStatus;
  createRFQ: AuthorizationStatus;
  createRelease: AuthorizationStatus;
  createTag: AuthorizationStatus;
  edit: AuthorizationStatus;
  removeEstimatedItems: AuthorizationStatus;
  report: AuthorizationStatus;
  updateEstimatedItems: AuthorizationStatus;
};

export type ProjectReportCostCode = {
  __typename?: "ProjectReportCostCode";
  allowance?: Maybe<Scalars["Decimal"]["output"]>;
  costCode?: Maybe<CostCode>;
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  invoicedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  materials: Array<ProjectReportMaterial>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  orderedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  overUnder?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  paidTotal?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  receivedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  reserved?: Maybe<Scalars["Decimal"]["output"]>;
  unallotted?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProjectReportCostCodeVendor = {
  __typename?: "ProjectReportCostCodeVendor";
  allowance?: Maybe<Scalars["Decimal"]["output"]>;
  costCode?: Maybe<CostCode>;
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  invoicedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  orderedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  overUnder?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  paidTotal?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  receivedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  reserved?: Maybe<Scalars["Decimal"]["output"]>;
  unallotted?: Maybe<Scalars["Decimal"]["output"]>;
  vendors: Array<ProjectReportVendor>;
};

export type ProjectReportMaterial = {
  __typename?: "ProjectReportMaterial";
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  material: OrgMaterial;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  quoted?: Maybe<Scalars["Boolean"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  reserved?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProjectReportPerMaterial = {
  __typename?: "ProjectReportPerMaterial";
  allowance?: Maybe<Scalars["Decimal"]["output"]>;
  costCodes: Array<ProjectReportCostCode>;
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  invoicedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  orderedCharges?: Maybe<Scalars["Decimal"]["output"]>;
  orderedNet?: Maybe<Scalars["Decimal"]["output"]>;
  orderedTax?: Maybe<Scalars["Decimal"]["output"]>;
  overUnder?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  paidTotal?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  receivedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  reserved?: Maybe<Scalars["Decimal"]["output"]>;
  unallotted?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProjectReportPerMaterialInput = {
  costCodeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  endDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  projectId: Scalars["ID"]["input"];
  sellerOrgIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  startDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type ProjectReportPerMaterialZone = {
  __typename?: "ProjectReportPerMaterialZone";
  allowance?: Maybe<Scalars["Decimal"]["output"]>;
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  invoicedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  orderedCharges?: Maybe<Scalars["Decimal"]["output"]>;
  orderedNet?: Maybe<Scalars["Decimal"]["output"]>;
  orderedTax?: Maybe<Scalars["Decimal"]["output"]>;
  overUnder?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  paidTotal?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  receivedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  unallotted?: Maybe<Scalars["Decimal"]["output"]>;
  zones: Array<ProjectReportZone>;
};

export type ProjectReportPerMaterialZoneInput = {
  costCodeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  endDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  projectId: Scalars["ID"]["input"];
  sellerOrgIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  startDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type ProjectReportPerVendor = {
  __typename?: "ProjectReportPerVendor";
  allowance?: Maybe<Scalars["Decimal"]["output"]>;
  costCodes: Array<ProjectReportCostCodeVendor>;
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  invoicedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  orderedCharges?: Maybe<Scalars["Decimal"]["output"]>;
  orderedNet?: Maybe<Scalars["Decimal"]["output"]>;
  orderedTax?: Maybe<Scalars["Decimal"]["output"]>;
  overUnder?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  paidTotal?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  receivedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  reserved?: Maybe<Scalars["Decimal"]["output"]>;
  unallotted?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProjectReportPerVendorInput = {
  costCodeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  endDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  projectId: Scalars["ID"]["input"];
  sellerOrgIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  startDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type ProjectReportReleases = {
  __typename?: "ProjectReportReleases";
  costCode?: Maybe<CostCode>;
  elements: Array<ProjectReportReleasesElement>;
  orgMaterial?: Maybe<OrgMaterial>;
  project: Project;
  sellerOrg?: Maybe<Organization>;
  sellerOrgLocation?: Maybe<OrgLocation>;
  zone?: Maybe<Zone>;
};

export type ProjectReportReleasesElement = {
  __typename?: "ProjectReportReleasesElement";
  release: Release;
  releaseItems: Array<ReleaseItem>;
};

export type ProjectReportReleasesInput = {
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  /** Maximum number of release items to return. The default is one thousand */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Either orgMaterial or costCode must be specified. */
  orgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  projectId: Scalars["ID"]["input"];
  sellerOrgId?: InputMaybe<Scalars["ID"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProjectReportVendor = {
  __typename?: "ProjectReportVendor";
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  reserved?: Maybe<Scalars["Decimal"]["output"]>;
  vendor: Organization;
};

export type ProjectReportZone = {
  __typename?: "ProjectReportZone";
  allowance?: Maybe<Scalars["Decimal"]["output"]>;
  costCodes: Array<ProjectReportCostCode>;
  estimated?: Maybe<Scalars["Decimal"]["output"]>;
  invoiced?: Maybe<Scalars["Decimal"]["output"]>;
  invoicedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  ordered?: Maybe<Scalars["Decimal"]["output"]>;
  orderedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  overUnder?: Maybe<Scalars["Decimal"]["output"]>;
  paid?: Maybe<Scalars["Decimal"]["output"]>;
  paidTotal?: Maybe<Scalars["Decimal"]["output"]>;
  received?: Maybe<Scalars["Decimal"]["output"]>;
  receivedTotal?: Maybe<Scalars["Decimal"]["output"]>;
  unallotted?: Maybe<Scalars["Decimal"]["output"]>;
  zone?: Maybe<Zone>;
};

export type ProjectSettings = {
  __typename?: "ProjectSettings";
  defaultTeam: Array<OrgLocationDefaultProjectTeam>;
  org: Organization;
};

export enum ProjectStatus {
  Active = "ACTIVE",
  Awarded = "AWARDED",
  Completed = "COMPLETED",
  Lost = "LOST",
}

export type ProjectTag = Node & {
  __typename?: "ProjectTag";
  buyouts: Array<Buyout>;
  color: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  estimatedItems: Array<EstimatedItem>;
  hasMapping: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  mapping?: Maybe<ProjectTagMapping>;
  name: Scalars["String"]["output"];
  project: Project;
  releaseItems: Array<ReleaseItem>;
};

export type ProjectTagMapping = {
  __typename?: "ProjectTagMapping";
  externalCostCode?: Maybe<ExternalCostCode>;
  externalCostCodeId: Scalars["String"]["output"];
  externalCostType?: Maybe<ExternalCostType>;
  externalCostTypeId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type Query = {
  __typename?: "Query";
  asset: Asset;
  /** Returns a list of buyouts that can be assigned to a release. */
  assignableBuyouts: Array<AssignableBuyout>;
  buyout?: Maybe<Buyout>;
  buyouts: BuyoutConnection;
  categories: Array<ProductCategory>;
  currencies: Array<Currency>;
  /** Get a custom file format by ID. */
  customFileFormat: CustomFileFormat;
  /** Get a list of all custom file formats available. */
  customFileFormats: Array<CustomFileFormat>;
  deliverySlip: DeliverySlip;
  deliverySlipStats: DeliverySlipStats;
  deliverySlips: DeliverySlipConnection;
  /**
   * downloadAsset downloads asset data in a base64-encoded string. It can
   * download from the Field Materials CDN or the Public Uploads bucket.
   */
  downloadAsset?: Maybe<Blob>;
  /**
   * Search through the enterprises on the platform,
   * by name. The endpoint does wildcard
   * matching by prefix on name, so partial input is supported.
   * Providing no filters will return all enterprises.
   */
  enterprises: Array<Enterprise>;
  externalBankAccounts: Array<ExternalBankAccount>;
  externalBatch?: Maybe<ExternalBatch>;
  externalComplianceGroups: Array<ExternalComplianceGroup>;
  externalCostCode: ExternalCostCode;
  externalCostCodes: Array<ExternalCostCode>;
  externalCostType: ExternalCostType;
  externalCostTypes: Array<ExternalCostType>;
  externalEquipmentItems: Array<ExternalEquipmentItem>;
  externalItem: ExternalItem;
  /**
   * Unlike other external nodes, external items are periodically pulled from the
   * source system and cached. This allows them to be queried using regular
   * (cursor-based) pagination with support for search and filtering.
   */
  externalItems: ExternalItemConnection;
  externalLedgerAccount: ExternalLedgerAccount;
  externalLedgerAccounts: Array<ExternalLedgerAccount>;
  /**
   * ExternalNotifications returns the notifications sent by the current user,
   * in descending order by the time they were dispatched.
   */
  externalNotifications: ExternalNotificationConnection;
  externalPO?: Maybe<ExternalPo>;
  externalPOTypes?: Maybe<Array<ExternalPoType>>;
  externalPOs: ExternalPoConnection;
  externalPaymentTerms: Array<ExternalPaymentTerms>;
  externalProject: ExternalProject;
  externalProjects: ExternalProjectConnection;
  externalServiceCodes: Array<ExternalServiceCode>;
  externalTaxCode: ExternalTaxCode;
  externalTaxCodes: Array<ExternalTaxCode>;
  externalVendor: ExternalVendor;
  externalVendors: ExternalVendorConnection;
  externalWarehouse: ExternalWarehouse;
  externalWarehouses: Array<ExternalWarehouse>;
  /** Get a preview of the fields in an input file. */
  fieldsPreview: Array<FieldPreview>;
  inventoryItem: InventoryItem;
  inventoryItemTransactions?: Maybe<InventoryTransactionConnection>;
  inventoryItems: InventoryItemConnection;
  inventoryTransaction: InventoryTransaction;
  inventoryTransactions?: Maybe<InventoryTransactionConnection>;
  invoice: Invoice;
  invoiceEmails: InvoiceEmailConnection;
  invoiceStats: InvoiceStats;
  invoiceValidation: InvoiceValidation;
  invoices: InvoiceConnection;
  /** Returns the organization locations with respect to current user organization */
  locations: OrgLocationConnection;
  manufacturers: Array<Manufacturer>;
  masterProduct?: Maybe<Product>;
  masterProducts: ProductConnection;
  masterSku?: Maybe<Sku>;
  masterSkus: SkuConnection;
  messageStats: MessageStats;
  messages: MessageConnection;
  /**
   * node retrieves the node from the graph identified by the given ID.
   * Returns null if no node is found.
   */
  node?: Maybe<Node>;
  noteDocument: NoteDocument;
  /**
   * Notifications returns the notifications received by the current user,
   * in descending order by the time they were notified.
   */
  notifications: NotificationConnection;
  /** Returns the organization identified by the given ID, if any. */
  org?: Maybe<Organization>;
  orgCatalogSku?: Maybe<OrgCatalogSku>;
  orgCatalogSkus: OrgCatalogSkuConnection;
  orgMaterial: OrgMaterial;
  orgMaterials: OrgMaterialConnection;
  /**
   * Returns the list of preferred vendors for the
   * authenticated viewer's org. Must be a contractor org -
   * for vendor orgs, the list will be empty.
   * @deprecated use orgPreferredVendorsConnection instead
   */
  orgPreferredVendors: Array<OrgPreferredVendor>;
  orgPreferredVendorsConnection: OrgPreferredVendorConnection;
  /**
   * Search through the organizations on the platform,
   * by name or/and by type. The endpoint does wildcard
   * matching by prefix on name, so partial input is supported.
   * Providing no filters will return all orgs.
   */
  orgs: Array<Organization>;
  /** @deprecated use paymentTermDays */
  paymentTermOptions: Array<PaymentTerm>;
  poValidation: PoValidation;
  /**
   * This query is used to predict the receipt number for a given project and org location.
   * Returns null if the project is missing a job number.
   */
  predictedReceiptNumber?: Maybe<Scalars["String"]["output"]>;
  predictedTax: PredictedTaxOutput;
  project?: Maybe<Project>;
  projectReportPerMaterial: ProjectReportPerMaterial;
  projectReportPerMaterialZone: ProjectReportPerMaterialZone;
  projectReportPerVendor: ProjectReportPerVendor;
  projectReportReleases: ProjectReportReleases;
  projects: ProjectConnection;
  quote?: Maybe<Quote>;
  quoteDocument: QuoteDocument;
  receipt: Invoice;
  receiptValidation: InvoiceValidation;
  receipts: InvoiceConnection;
  release?: Maybe<Release>;
  releases: ReleaseConnection;
  requisitions: RequisitionConnection;
  rfq?: Maybe<Rfq>;
  rfqs: RfqConnection;
  serviceProductCategories: Array<ServiceProductCategory>;
  siteContacts: Array<SiteContact>;
  systemReport: Scalars["String"]["output"];
  /**
   * Get a single UOM by ID.
   *
   * This query will return a global UOM if the ID is a global UOM ID, or an
   * org-scoped UOM if the ID is an org-scoped UOM ID. If the org-scoped UOM
   * does not belong to the current user's organization, no UOM will be returned
   * (essentially a Not Found).
   */
  uom?: Maybe<Uom>;
  /**
   * Returns a list UOMs available to the user.
   *
   * Use `searchPrefix` to search for UOMs that have that prefix.
   * Use `orgSpecific` to indicate what UOMs should be returned.
   * 	null  -> All UOMs
   * 	true  -> Org-specific UOMs
   * 	false -> Global UOMs
   */
  uoms: Array<Uom>;
  /**
   * Returns the list of users the viewer has access to.
   * If the viewer is a system/org/org location admin,
   * all users from their org will be returned, or the users
   * from the specified org location, if any. If the viewer
   * is not an admin, an org location must be specified,
   * and they must be part of that location.
   */
  users: Array<User>;
  viewer?: Maybe<User>;
};

export type QueryAssetArgs = {
  url: Scalars["String"]["input"];
};

export type QueryAssignableBuyoutsArgs = {
  input: AssignableBuyoutsInput;
};

export type QueryBuyoutArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBuyoutsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryBuyoutsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCustomFileFormatArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDeliverySlipArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDeliverySlipsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryDeliverySlipsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<QueryDeliverySlipsSort>;
};

export type QueryDownloadAssetArgs = {
  url: Scalars["String"]["input"];
};

export type QueryEnterprisesArgs = {
  filter?: InputMaybe<QueryEnterprisesFilter>;
};

export type QueryExternalBankAccountsArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalBatchArgs = {
  input: ExternalBatchQueryInput;
};

export type QueryExternalComplianceGroupsArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalCostCodeArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalCostCodesArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalCostTypeArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalCostTypesArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalEquipmentItemsArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalItemArgs = {
  id: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
};

export type QueryExternalItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryExternalItemsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sourceSystem: SourceSystem;
};

export type QueryExternalLedgerAccountArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalLedgerAccountsArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryNotificationsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryExternalPoArgs = {
  input?: InputMaybe<ExternalPoQueryInput>;
};

export type QueryExternalPoTypesArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalPOsArgs = {
  input: ExternalPOsQueryInput;
};

export type QueryExternalPaymentTermsArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalProjectArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryExternalProjectsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sourceSystem: SourceSystem;
};

export type QueryExternalServiceCodesArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalTaxCodeArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalTaxCodesArgs = {
  sourceSystem: SourceSystem;
};

export type QueryExternalVendorArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalVendorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryExternalVendorsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sourceSystem: SourceSystem;
};

export type QueryExternalWarehouseArgs = {
  input: ExternalIdQueryInput;
};

export type QueryExternalWarehousesArgs = {
  sourceSystem: SourceSystem;
};

export type QueryFieldsPreviewArgs = {
  input: FieldsPreviewInput;
};

export type QueryInventoryItemArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInventoryItemTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: QueryInventoryItemTransactionsFilter;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInventoryItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInventoryItemsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInventoryTransactionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInventoryTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInventoryTransactionsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInvoiceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInvoiceEmailsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInvoiceEmailsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInvoiceValidationArgs = {
  input: LinkInvoiceInput;
};

export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInvoicesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<QueryInvoicesSort>;
};

export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryManufacturersArgs = {
  searchPrefix: Scalars["String"]["input"];
};

export type QueryMasterProductArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMasterProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: QueryProductFilterInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMasterSkuArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMasterSkusArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: QueryProductFilterInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMessageStatsArgs = {
  input: MessageStatsInput;
};

export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  input: QueryMessagesInput;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNoteDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryNotificationsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrgArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrgCatalogSkuArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrgCatalogSkusArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  input: QueryOrgCatalogSkusInput;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrgMaterialArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrgMaterialsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryOrgMaterialsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrgPreferredVendorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryOrgPreferredVendorsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrgsArgs = {
  filter?: InputMaybe<QueryOrgsFilter>;
};

export type QueryPoValidationArgs = {
  input: LinkPoInput;
};

export type QueryPredictedReceiptNumberArgs = {
  projectId: Scalars["ID"]["input"];
  sellerOrgLocationId: Scalars["ID"]["input"];
};

export type QueryPredictedTaxArgs = {
  input: PredictedTaxInput;
};

export type QueryProjectArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProjectReportPerMaterialArgs = {
  input?: InputMaybe<ProjectReportPerMaterialInput>;
};

export type QueryProjectReportPerMaterialZoneArgs = {
  input?: InputMaybe<ProjectReportPerMaterialZoneInput>;
};

export type QueryProjectReportPerVendorArgs = {
  input?: InputMaybe<ProjectReportPerVendorInput>;
};

export type QueryProjectReportReleasesArgs = {
  input?: InputMaybe<ProjectReportReleasesInput>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryProjectsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryQuoteArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryQuoteDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryReceiptArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryReceiptValidationArgs = {
  input: LinkInvoiceInput;
};

export type QueryReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInvoicesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<QueryInvoicesSort>;
};

export type QueryReleaseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryReleasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryReleasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<QueryReleasesSort>;
};

export type QueryRequisitionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryRfqArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRfqsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryRfQsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerySiteContactsArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerySystemReportArgs = {
  excludedOrgIDs?: InputMaybe<Array<Scalars["String"]["input"]>>;
  from: Scalars["String"]["input"];
  to: Scalars["String"]["input"];
};

export type QueryUomArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUomsArgs = {
  orgSpecific?: InputMaybe<Scalars["Boolean"]["input"]>;
  searchPrefix?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryUsersArgs = {
  orgLocationID?: InputMaybe<Scalars["ID"]["input"]>;
  projectID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryBuyoutsFilter = {
  closedProjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  exported?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Whether or not the PO with the specified number exists in the connected ERP.
   *
   * This filter is applied using the external PO cache and therefore should not
   * increase query latency.
   */
  poExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** When specified, it performs a text search on the clientIdentifier field */
  search?: InputMaybe<Scalars["String"]["input"]>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<BuyoutStatus>>;
};

export type QueryDeliverySlipsFilter = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  closedProjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdBy?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  maxCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  maxFulfillmentDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minFulfillmentDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  newRelease?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** When specified it performs a text search on the PO and release sequence numbers */
  search?: InputMaybe<Scalars["String"]["input"]>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceWarehouseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<DeliverySlipStatus>>;
};

export type QueryDeliverySlipsSort = {
  by: QueryDeliverySlipsSortBy;
  descending?: Scalars["Boolean"]["input"];
};

export enum QueryDeliverySlipsSortBy {
  CreatedAt = "CREATED_AT",
  FulfillmentDate = "FULFILLMENT_DATE",
}

export type QueryEnterprisesFilter = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryExternalItemsFilter = {
  mapped?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryExternalProjectsFilter = {
  mapped?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryExternalVendorsFilter = {
  mapped?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFacetInput = {
  id: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
};

export type QueryInventoryItemTransactionsFilter = {
  inventoryItemId: Scalars["ID"]["input"];
  maxTransactionCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minTransactionCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<InventoryTransactionType>;
  warehouseID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryInventoryItemsFilter = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  depleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  quantityThreshold?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  warehouseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryInventoryTransactionsFilter = {
  destination?: InputMaybe<Scalars["ID"]["input"]>;
  maxCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  origin?: InputMaybe<Scalars["ID"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<InventoryTransactionType>;
  warehouseID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryInvoiceEmailsFilter = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<InvoiceEmailStatus>>;
};

export type QueryInvoicesFilter = {
  /** It filters(Yes|No|All semantic) archived invoices */
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  batchMonth?: InputMaybe<Scalars["String"]["input"]>;
  batchNumber?: InputMaybe<Scalars["String"]["input"]>;
  closedProjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdBy?: InputMaybe<Scalars["ID"]["input"]>;
  createdByIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  downloaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  equipmentItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** It filters(Yes|No|All semantic) exported invoices */
  exported?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filters exported invoices according to the associated (if any) invoice link */
  exportedStatus?: InputMaybe<InvoiceExportStatus>;
  /**
   * IDs of invoice folders assigned to invoices.
   *
   * When [] is specified, the query only returns invoices not assigned to any folder.
   */
  folderIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Kickback filter with special semantics:
   * YES: Shows invoices that have/had kickbacks (resolved or unresolved)
   * NO: Shows invoices without kickbacks or where all kickbacks are resolved
   * No Filter: No constraints applied
   */
  hasKickback?: InputMaybe<Scalars["Boolean"]["input"]>;
  invoiceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * KickbackBy filters invoices that were kickbacked by the current user (me) or
   * by anyone (anyone).
   */
  kickbackBy?: InputMaybe<KickbackFilter>;
  /**
   * KickbackByUserIds filters invoices that were kickbacked by specific users.
   * It is mutually exclusive with kickbackBy.
   */
  kickbackByUserIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Resolved filter:
   * YES: Shows only invoices where all kickbacks are resolved
   * NO: Shows only invoices with at least one unresolved kickback
   * No Filter: No constraints applied
   * Only applicable when hasKickback is true
   */
  kickbackResolved?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * KickbackResolvedBy filters invoices whose kickback was resolved by the current
   * user (me) or by anyone (anyone).
   */
  kickbackResolvedBy?: InputMaybe<KickbackFilter>;
  /**
   * KickbackTo filters invoices that were kickbacked to the current user (me) or
   * to anyone (anyone).
   */
  kickbackTo?: InputMaybe<KickbackFilter>;
  /** it returns such invoices in which the user running the query was specified as a kickback assignee. */
  kickbackToMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Filter by users assigned to review kickbacks
   * Only applicable when hasKickback is true
   * Empty list means any user
   */
  kickbackToUserIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  maxApprovedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  maxCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  maxDueDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  maxIssueDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  maxPostingDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minApprovedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minDueDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minIssueDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minPostingDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  orderAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderHasDeliverySlips?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** It filters(Yes|No|All semantic) invoices linked to an exported release */
  poExported?: InputMaybe<Scalars["Boolean"]["input"]>;
  prePaid?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  projectMemberUserIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** It filters(Yes|No|All semantic) invoices linked to a release that has a receipt posted. */
  receiptPosted?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseTransactionKinds?: InputMaybe<Array<TransactionKind>>;
  releaseTypeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<InvoiceStatus>>;
  type?: InputMaybe<InvoiceType>;
};

export type QueryInvoicesSort = {
  by: QueryInvoicesSortBy;
  descending?: Scalars["Boolean"]["input"];
};

export enum QueryInvoicesSortBy {
  CreatedAt = "CREATED_AT",
  IssueDate = "ISSUE_DATE",
  Number = "NUMBER",
  SupplierName = "SUPPLIER_NAME",
  Total = "TOTAL",
  /** @deprecated use SUPPLIER_NAME instead */
  VendorName = "VENDOR_NAME",
}

/**
 * QueryMessagesInput is the input parameter to the `messages` query.
 * One of the ID fields must be specified, so the server knows for which
 * entity to fetch the messages.
 */
export type QueryMessagesInput = {
  context: MessageContextInput;
  /**
   * IsPrivate can be set to false, to retrieve messages
   * that are sent to users at the other end of communication
   * (e.g. contractor to distributor, distributor to contractor),
   * or true, to retrieve messages that are sent to users from the same organization.
   * If not provided, defaults to false.
   */
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryNotificationsFilter = {
  buyoutId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  maxDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  projectIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
  rfqId?: InputMaybe<Scalars["ID"]["input"]>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryOrgCatalogSkusInput = {
  filterBy?: InputMaybe<OrgCatalogSkuMappingFilter>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: OrgCatalogSortColumn;
};

export type QueryOrgMaterialsFilter = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * If connected is true, the query returns only such org materials that are connected to external items in an
   * active source system;
   * If connected is false, the query returns the opposite, that is only such org materials that are not connected
   * to external items in an active source system.
   */
  connected?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  priceCondition?: InputMaybe<VendorPricesCondition>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * sellerOrgLocationId and priceCondition work together to filter OrgMaterials
   * by additional criteria evaluated at the level of VendorPrices.
   * For example, if condition is set to EXPIRED and sellerOrgLocationId is set
   * to an ID of a given vendor org location, the orgMaterials query will only
   * return such OrgMaterials, for which there is at least one VendorPrice with
   * the expiration date in the past assigned to a given seller org location.
   */
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryOrgPreferredVendorsFilter = {
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryOrgsFilter = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<OrgType>;
};

export type QueryProductFilterInput = {
  categoryId?: InputMaybe<Scalars["ID"]["input"]>;
  facets?: InputMaybe<Array<QueryFacetInput>>;
  search?: InputMaybe<SearchInput>;
};

export type QueryProjectsFilter = {
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxStartDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minStartDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  orgLocationID?: InputMaybe<Scalars["ID"]["input"]>;
  statuses?: InputMaybe<Array<ProjectStatus>>;
};

export type QueryRfQsFilter = {
  closedProjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  quotesReceived?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<RfqStatus>>;
};

export type QueryReleasesFilter = {
  buyoutIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  closedProjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** When specified, it filters releases by Release->createdBy. */
  createdBy?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Match releases that either have exportedAt set or have a poLink to a connected source system */
  exported?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * IDs of release groups assigned to releases.
   * When [] is specified, the query only returns releases not assigned to any group.
   */
  groupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  hasInvoices?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Match releases with non-null receiptPostedAt */
  hasReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Releases with at least one back-ordered item are returned if the field value is true, and
   * releases without back-ordered items are returned if the field value is false.
   */
  isBackorder?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  maxTime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minCreatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  minTime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /**
   * Whether or not the PO with the specified number exists in the connected ERP.
   *
   * This filter is applied using the external PO cache and therefore should not
   * increase query latency.
   */
  poExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * When specified, it performs a case-insensitive exact match search on release
   * PO numbers.
   */
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  projectIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  releaseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * When specified, it filters releases by Release->submittedBy with a fallback
   * to ->createdBy if null.
   */
  requestedBy?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Matches releases that have been created retroactively (RECEIVED or
   * PARTIALLY_RECEIVED with null submittedAt).
   */
  retroactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * When specified, it performs a text search on the PO numbers,
   * release sequence numbers, site contact name and custom internal description.
   */
  search?: InputMaybe<Scalars["String"]["input"]>;
  sellerOrgLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceWarehouseIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  statuses?: InputMaybe<Array<ReleaseStatus>>;
  /** Filter releases on release TBD value. */
  timeTBD?: InputMaybe<Scalars["Boolean"]["input"]>;
  transactionKinds?: InputMaybe<Array<TransactionKind>>;
  typeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * When set to true, the minTime and maxTime filters are also applied to the delivery
   * date of the release items, returning releases having such items.
   */
  useItemsDeliveryDate?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter releases based on a list of warehouse IDs. */
  warehouseIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Filter releases based on the includedServices association.
   * A value of true is equivalent to releases with no included services.
   */
  willCall?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryReleasesSort = {
  by: QueryReleasesSortBy;
  descending?: Scalars["Boolean"]["input"];
};

export enum QueryReleasesSortBy {
  CreatedAt = "CREATED_AT",
  DeliveryDate = "DELIVERY_DATE",
  PoNumber = "PO_NUMBER",
  SupplierName = "SUPPLIER_NAME",
  Total = "TOTAL",
  /** @deprecated use SUPPLIER_NAME instead */
  VendorName = "VENDOR_NAME",
}

export type Quote = Node & {
  __typename?: "Quote";
  additionalCharges: Array<Charge>;
  /**
   * URLs of assets uploaded by the vendor.
   * @deprecated use assets
   */
  assetUrls: Array<Scalars["String"]["output"]>;
  /** assets uploaded by the vendor. */
  assets: Array<Asset>;
  auxiliaryItems: Array<AuxiliaryQuoteItem>;
  buyout?: Maybe<Buyout>;
  clientIdentifier: Scalars["String"]["output"];
  discount?: Maybe<Discount>;
  expirationDate?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  itemGroups: Array<QuoteItemGroup>;
  items: Array<QuoteItem>;
  /**
   * Net price is the price of the quote if it is accepted
   * with all its items and discounts applied, if any.
   * It is null for quotes which have non-withdrawn items without unit prices or
   * quantities (usually requested/draft quotes).
   * To get the total price of an accepted quote, query `buyout.amount`.
   */
  netPrice?: Maybe<Scalars["Decimal"]["output"]>;
  nextRevision?: Maybe<Quote>;
  notes?: Maybe<Scalars["String"]["output"]>;
  /**
   * The number of items provided by the vendor in this quote.
   * To get the number of accepted items, count the items of the buyout.
   * Withdrawn items are not counted.
   */
  numItemsProvided: Scalars["Int"]["output"];
  /** @deprecated use paymentTermDays */
  paymentTerm?: Maybe<PaymentTerm>;
  paymentTermDays?: Maybe<Scalars["Int"]["output"]>;
  preferredVendor?: Maybe<OrgPreferredVendor>;
  previousRevision?: Maybe<Quote>;
  /** releases is an inverse of Release→quote */
  releases: Array<Release>;
  rfq: Rfq;
  /**
   * Sequence number of a quote within the parent RFQ.
   * This field is only accessible to contractors.
   */
  rfqSequenceNumber: Scalars["Int"]["output"];
  sellerOrgLocation: OrgLocation;
  servicePriceSheet?: Maybe<ServicePriceSheet>;
  status: QuoteStatus;
  submittedDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * Signed URL for accessing the quote as a vendor.
   *
   * Only accessible from super admins.
   */
  url: Scalars["String"]["output"];
  vendorContacts: Array<VendorContact>;
};

/**
 * payment_term int,
 * QuoteDocument represents a contractor-uploaded document that is OCR'd and can
 * be used to create buyouts/releases.
 */
export type QuoteDocument = Node & {
  __typename?: "QuoteDocument";
  asset: Asset;
  createdAt: Scalars["Timestamp"]["output"];
  expirationDate?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * Returns whether or not a project was matched to this quote document,
   * regardless of whether the user is authorized to access the release.
   */
  hasPredictedProject: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  /** Items representing line item OCR results for the quote document. */
  items: Array<QuoteDocumentItem>;
  orgLocation: OrgLocation;
  paymentTerm?: Maybe<Scalars["Int"]["output"]>;
  /**
   * A project that was matched with this quote document based on OCR results.
   *
   * Note: this edge returns null if the user is not authorized to access the
   * project. In order to check if a matched project exists regardless of
   * authorization, use the hasPredictedProject field.
   */
  predictedProject?: Maybe<Project>;
  /**
   * The seller org location (vendor) that was matched with this quote document
   * based on OCR results.
   */
  predictedSellerOrgLocation?: Maybe<OrgLocation>;
  /**
   * Mappings from the items of this quote document to the items of the release
   * with which this document is associated.
   * Empty if the quote document is not associated with any release.
   * A quote document can be associated with a single release only.
   */
  releaseItemHints: Array<ReleaseItemHint>;
  subtotal?: Maybe<Scalars["Decimal"]["output"]>;
  taxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  total?: Maybe<Scalars["Decimal"]["output"]>;
};

export type QuoteDocumentItem = Node & {
  __typename?: "QuoteDocumentItem";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  partNumber?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  uom?: Maybe<Scalars["String"]["output"]>;
};

export enum QuoteEvent {
  Accept = "ACCEPT",
  Cancel = "CANCEL",
  ChangedByContractor = "CHANGED_BY_CONTRACTOR",
  RejectedByContractor = "REJECTED_BY_CONTRACTOR",
  Requested = "REQUESTED",
  VendorSubmit = "VENDOR_SUBMIT",
  VendorWithdraw = "VENDOR_WITHDRAW",
}

export type QuoteItem = Node & {
  __typename?: "QuoteItem";
  /** @deprecated use assets */
  assetUrls: Array<Scalars["String"]["output"]>;
  assets: Array<Asset>;
  components?: Maybe<Array<QuoteItemComponent>>;
  /**
   * The description of this quote item, by default copied over from
   * rfqItem.description.
   */
  description?: Maybe<Scalars["String"]["output"]>;
  expirationDate?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  /** Lead time is expressed as a number of days. */
  leadTimeDays?: Maybe<Scalars["Int"]["output"]>;
  manufacturer?: Maybe<Manufacturer>;
  /** @deprecated Options are now represented by quote items */
  masterSku?: Maybe<Sku>;
  notes?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Options are now represented by quote items */
  orgCatalogSku?: Maybe<OrgCatalogSku>;
  /** @deprecated Use `quantityDecimal` instead */
  quantity?: Maybe<Scalars["Float"]["output"]>;
  quantityDecimal?: Maybe<Scalars["Decimal"]["output"]>;
  quote: Quote;
  rfqItem: RfqItem;
  status: QuoteStatus;
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
};

export type QuoteItemComponent = Node & {
  __typename?: "QuoteItemComponent";
  auxiliaryQuoteItem?: Maybe<AuxiliaryQuoteItem>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /** Lead time is expressed as a number of days. */
  leadTimeDays?: Maybe<Scalars["Int"]["output"]>;
  manufacturer?: Maybe<Manufacturer>;
  /** @deprecated Use `quantityDecimal` instead */
  quantity?: Maybe<Scalars["Float"]["output"]>;
  quantityDecimal?: Maybe<Scalars["Decimal"]["output"]>;
  quoteItem?: Maybe<QuoteItem>;
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  uom?: Maybe<Uom>;
};

/**
 * QuoteItemGroup is a group of quote items for the same RFQ item. RFQ items have
 * at least one quote item.
 */
export type QuoteItemGroup = {
  __typename?: "QuoteItemGroup";
  quoteItems: Array<QuoteItem>;
  rfqItem: RfqItem;
};

export enum QuoteStatus {
  Accepted = "ACCEPTED",
  CancelledByContractor = "CANCELLED_BY_CONTRACTOR",
  ChangeRequested = "CHANGE_REQUESTED",
  Draft = "DRAFT",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  Submitted = "SUBMITTED",
  Withdrawn = "WITHDRAWN",
}

export type ReadNotificationInput = {
  id: Scalars["ID"]["input"];
};

export type RegisterDeviceInput = {
  name: Scalars["String"]["input"];
  platform: DevicePlatform;
  token: Scalars["String"]["input"];
};

export type RejectReleaseInput = {
  reason?: InputMaybe<Scalars["String"]["input"]>;
  releaseId: Scalars["ID"]["input"];
  version: Scalars["Int"]["input"];
};

export type Release = Node & {
  __typename?: "Release";
  additionalCharges: Array<Charge>;
  /**
   * Total price of all items included in a release calculated by multiplying
   * unitPrice specified in the corresponding buyout item by the quantity
   * of the release item.
   * Note that since the release item's quantity and not requestedQuantity
   * is used to calculate the this field, its value may be misleading on
   * contractor-facing screens when the release is in REQUESTED status.
   * @deprecated Use total instead
   */
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  approvalProgress: ReleaseApprovalProgress;
  approvals: Array<ReleaseApproval>;
  /** @deprecated Use approvals instead */
  approvedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** @deprecated Use approvals instead */
  approvedBy?: Maybe<User>;
  /**
   * asset urls when vendor or contactor uploads release assets (photos, videos, documents)
   * @deprecated use assets
   */
  assetUrls: Array<Scalars["String"]["output"]>;
  /** asset when vendor or contactor uploads release assets (photos, videos, documents) */
  assets: Array<Asset>;
  /** List of distinct orders to which backorderFromRelease edges on line items point to. */
  backorderFrom: Array<Release>;
  /** Count of backordered items in the release. */
  backorderedItemCount: Scalars["Int"]["output"];
  buyout?: Maybe<Buyout>;
  /**
   * The sum of each additional charge.
   * Zero if there are no additional charges.
   */
  chargesAmount: Scalars["Decimal"]["output"];
  /**
   * Whether all the included items of the release have unit price specified or not.
   * True if the release has no items.
   */
  complete: Scalars["Boolean"]["output"];
  complianceGroup?: Maybe<ComplianceGroup>;
  /** @deprecated Use subtotal instead */
  contractorSubtotal?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated Use taxAmount instead */
  contractorTaxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated Use total instead */
  contractorTotal?: Maybe<Scalars["Decimal"]["output"]>;
  costCode?: Maybe<CostCode>;
  createdAt: Scalars["Timestamp"]["output"];
  createdBy: User;
  /**
   * If specified, this value overrides the tax amount calculated from the
   * taxRate and taxVariance fields.
   */
  customTaxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * If the release is a will call ( does not have DELIVERY among included services) then
   * this is nil. Otherwise it is the warehouse address if a warehouse is specified, otherwise
   * it is the project's address.
   */
  deliveryAddress?: Maybe<Address>;
  /**
   * uploaded delivery photos when release was received
   * @deprecated use deliveryPhotos
   */
  deliveryPhotoUrls: Array<Scalars["String"]["output"]>;
  /** @deprecated use DeliverySlip.deliveryPhotos */
  deliveryPhotos: Array<Asset>;
  /**
   * URL of the uploaded delivery slip
   * @deprecated Use deliverySlips
   */
  deliverySlipUrl?: Maybe<Scalars["String"]["output"]>;
  /**
   * URLs of the uploaded delivery slips
   * @deprecated Use deliverySlips
   */
  deliverySlipUrls: Array<Scalars["String"]["output"]>;
  deliverySlips: Array<DeliverySlip>;
  /** a text description used on export */
  description?: Maybe<Scalars["String"]["output"]>;
  exportedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  exportedBy?: Maybe<User>;
  exportedTo?: Maybe<IntegrationType>;
  exportedVersion?: Maybe<Scalars["Int"]["output"]>;
  /** Groups to which the release belongs. */
  groups: Array<ReleaseGroup>;
  /** This flag is true if there is at least one approved invoice for the release. */
  hasApprovedInvoices: Scalars["Boolean"]["output"];
  /** Whether or not the release contains any items with missing cost codes. */
  hasMissingCostCodes: Scalars["Boolean"]["output"];
  /** Whether or not the release contains any items with missing external codes. */
  hasMissingExternalCodes: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  includeServices: Array<ServiceType>;
  instructions?: Maybe<Instruction>;
  /** @deprecated Use invoices instead */
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  /**
   * informs if the release contains at least one non-deleted item backordered from another release
   * @deprecated check release items that have a deliveryDate instead
   */
  isBackorder: Scalars["Boolean"]["output"];
  isInvoiced: Scalars["Boolean"]["output"];
  issues: Array<ReleaseIssue>;
  /** Query a specific release item by its ID. */
  item: ReleaseItem;
  /** Components are not included in this count. */
  itemCount: Scalars["Int"]["output"];
  /**
   * Items included in this release. Components are not included - they are
   * resolved via ReleaseItem > components.
   */
  items: Array<ReleaseItem>;
  /**
   * Returns the material conversion for each unique org material referenced by
   * the release items contained in this release.
   */
  materialConversions: Array<PoMaterialConversion>;
  /**
   * The sum of each individual item price.
   * Zero if there are no items or no items with unit price specified.
   */
  netAmount: Scalars["Decimal"]["output"];
  noteDocument?: Maybe<NoteDocument>;
  /** Notes from the vendor (related to payment information). */
  notes?: Maybe<Scalars["String"]["output"]>;
  orgLocation?: Maybe<OrgLocation>;
  /**
   * Original release from which the current release was created as a result of
   * some items being assigned to a different vendor or source warehouse.
   */
  originalReassignedRelease?: Maybe<Release>;
  /**
   * Original release from which the current release was created as a result of
   * some items being assigned to a different vendor.
   * @deprecated use originalReassignedRelease
   */
  originalVendorReassignmentRelease?: Maybe<Release>;
  /** Payment term in days. */
  paymentTerm: Scalars["Int"]["output"];
  permissions: ReleasePermissions;
  /** @deprecated Use poNumber instead */
  po?: Maybe<Po>;
  /**
   * Whether or not the PO with the specified number exists in the connected ERP.
   *
   * This edge is served from the external PO cache and therefore should not
   * increase query latency.
   */
  poExists?: Maybe<Scalars["Boolean"]["output"]>;
  poLink?: Maybe<PoLink>;
  poNumber?: Maybe<Scalars["String"]["output"]>;
  /**
   * Predicted PO number that will be auto-assigned to the release if one is not
   * specified upon the release being submitted.
   *
   * Null if:
   * - the release already has a PO number;
   * - the release is in a status indicating that it already submitted;
   * - the project is missing a job number.
   */
  predictedPoNumber?: Maybe<Scalars["String"]["output"]>;
  preferredVendor?: Maybe<OrgPreferredVendor>;
  project?: Maybe<Project>;
  quote?: Maybe<Quote>;
  /**
   * A quote document from which the release was created
   * or was subsequently uploaded for matching and adding new items
   * present in the document but not in the release.
   */
  quoteDocument?: Maybe<QuoteDocument>;
  /**
   * Releases that were created as a result of some items from this release being
   * reassigned to a different vendor or source warehouse.
   */
  reassignedReleases: Array<Release>;
  receiptPostedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  receiptPostedBy?: Maybe<User>;
  receipts: Array<Invoice>;
  rejectedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  rejectedBy?: Maybe<User>;
  rejectionReason?: Maybe<Scalars["String"]["output"]>;
  /**
   * Requested time by the contractor
   * @deprecated Use time instead
   */
  requestedTime?: Maybe<Scalars["Timestamp"]["output"]>;
  requiresInventoryReceipt?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * sellerOrgLocation and preferredVendor are not required in DRAFT,
   * AWAITING_APPROVAL and REJECTED statuses.
   */
  sellerOrgLocation?: Maybe<OrgLocation>;
  sequenceNumber?: Maybe<Scalars["Int"]["output"]>;
  siteContact?: Maybe<SiteContact>;
  /** Order is fulfilled by the warehouse */
  sourceWarehouse?: Maybe<Warehouse>;
  status: ReleaseStatus;
  submittedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  submittedBy?: Maybe<User>;
  /**
   * The net amount of the release plus the charges.
   * Null only when disallowing incomplete releases and the releases is incomplete.
   */
  subtotal?: Maybe<Scalars["Decimal"]["output"]>;
  syncVersion: Scalars["Int"]["output"];
  /**
   * Tax amount of the release.
   * Equal to customTaxAmount if present. Otherwise, calculated from taxRate and
   * taxVariance fields. Null if neither customTaxAmount nor taxRate is specified.
   */
  taxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  taxCode?: Maybe<TaxCode>;
  taxRate?: Maybe<Scalars["Decimal"]["output"]>;
  taxType?: Maybe<TaxType>;
  /** Tax amount of the release without the tax variance (if specified). */
  taxUnadjustedAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * Additional dollar amount to be added to the tax amount computed based on the
   * taxRate. Ineffectual if customTaxAmount is specified.
   */
  taxVariance?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * The sum of each individual item price that is taxable.
   * Zero if there are no items or no items with unit price specified.
   */
  taxableNetAmount: Scalars["Decimal"]["output"];
  /**
   * The taxable net amount of the release plus the charges.
   * Null only when disallowing incomplete releases and the releases is incomplete.
   */
  taxableSubtotal?: Maybe<Scalars["Decimal"]["output"]>;
  /** Delivery date */
  time?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * For release type materials this is equivalent to hold for release.
   * If true then the time field is ignored.
   */
  timeTBD: Scalars["Boolean"]["output"];
  /**
   * The sum of the subtotal and the tax amount (custom or based on tax rate).
   * Null only when disallowing incomplete releases and the releases is incomplete.
   */
  total?: Maybe<Scalars["Decimal"]["output"]>;
  type: ReleaseType;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  useSourceSystemPO: Scalars["Boolean"]["output"];
  vendorContacts: Array<VendorContact>;
  /**
   * Releases that were created as a result of some items from this release being
   * reassigned to a different vendor.
   * @deprecated use reassignedReleases
   */
  vendorReassignmentReleases: Array<Release>;
  /** @deprecated Use subtotal instead */
  vendorSubtotal?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated Use taxAmount instead */
  vendorTaxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated Use total instead */
  vendorTotal?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * Release version is used to track changes to releases in the REQUESTED status so as to
   * reconcile changes made by the contractor and the vendor at the same time.
   *
   * The initial version of a release is 0 and while the version itself is always
   * available, it should only be taken into consideration whenever the release
   * is in the REQUESTED or SCHEDULED status.
   *
   * Version is also a field on UpdateContractorReleaseInput, UpdateVendorReleaseInput,
   * ConfirmReleaseInput, AddToReleaseInput, RemoveFromReleaseInput, CompleteReleaseInput,
   * and CancelReleaseInput: if supplied version does not match the current version
   * in the database, an error will be returned by the corresponding mutations.
   */
  version: Scalars["Int"]["output"];
  warehouse?: Maybe<Warehouse>;
  watchers: Array<User>;
  zone?: Maybe<Zone>;
};

export type ReleaseItemArgs = {
  itemId: Scalars["String"]["input"];
};

export type ReleaseSubtotalArgs = {
  allowIncomplete?: Scalars["Boolean"]["input"];
};

export type ReleaseTaxAmountArgs = {
  allowIncomplete?: Scalars["Boolean"]["input"];
};

export type ReleaseTaxUnadjustedAmountArgs = {
  allowIncomplete?: Scalars["Boolean"]["input"];
};

export type ReleaseTaxableSubtotalArgs = {
  allowIncomplete?: Scalars["Boolean"]["input"];
};

export type ReleaseTotalArgs = {
  allowIncomplete?: Scalars["Boolean"]["input"];
};

export type ReleaseApproval = Node & {
  __typename?: "ReleaseApproval";
  approver: User;
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  /** List of org location roles that the approver had at the time of approval. */
  roles: Array<OrgLocationRole>;
};

export type ReleaseApprovalProgress = {
  __typename?: "ReleaseApprovalProgress";
  /** Whether or not the user can approve the release in its current state. */
  canApprove: Scalars["Boolean"]["output"];
  /**
   * When true, the user's approval will result in the order being sent to the
   * vendor.
   */
  canApproveImmediately: Scalars["Boolean"]["output"];
  /** Whether or not the user can reject the release in its current state. */
  canReject: Scalars["Boolean"]["output"];
  slots: Array<ReleaseApprovalSlot>;
};

export type ReleaseApprovalSettings = {
  __typename?: "ReleaseApprovalSettings";
  approverRoles: Array<OrgLocationRole>;
  enabled: Scalars["Boolean"]["output"];
  exemptRoles: Array<OrgLocationRole>;
  /**
   * Number of approvals required for a release to be approved.
   *
   * Defaults to 1.
   */
  requiredApprovals: Scalars["Int"]["output"];
  /**
   * When specified, the release will only be approved if for each org location
   * role in the list, at least one user with that role has approved the release.
   *
   * When empty, approver roles are not considered.
   */
  requiredApproverRoles: Array<OrgLocationRole>;
  /** Require approval of Releases going into RESERVED status */
  reserved: Scalars["Boolean"]["output"];
  /** Require approval of Warehouse Orders */
  sourceWarehouse: Scalars["Boolean"]["output"];
  /**
   * Only initiate the approval flow if the release contains at least one
   * standalone item.
   */
  standaloneOnly: Scalars["Boolean"]["output"];
  submitterRoles: Array<OrgLocationRole>;
};

export type ReleaseApprovalSlot = {
  __typename?: "ReleaseApprovalSlot";
  approvals: Array<ReleaseApproval>;
  /**
   * When set to true, this release approval slot groups release approvals that
   * are no longer counted towards the quorum.
   *
   * This may happen when ReleaseApprovalSettings->requiredApproverRoles is
   * changed after some approvals have already been submitted.
   */
  ineffectual: Scalars["Boolean"]["output"];
  role?: Maybe<OrgLocationRole>;
};

export type ReleaseConnection = Connection & {
  __typename?: "ReleaseConnection";
  edges: Array<ReleaseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ReleaseEdge = Edge & {
  __typename?: "ReleaseEdge";
  cursor: Scalars["String"]["output"];
  node: Release;
};

export enum ReleaseEvent {
  ApprovalRequested = "APPROVAL_REQUESTED",
  Approved = "APPROVED",
  BackorderCreated = "BACKORDER_CREATED",
  Canceled = "CANCELED",
  CanceledInternal = "CANCELED_INTERNAL",
  ChangedByContractor = "CHANGED_BY_CONTRACTOR",
  ChangedByContractorInternal = "CHANGED_BY_CONTRACTOR_INTERNAL",
  ChangedByContractorSkipConfirmation = "CHANGED_BY_CONTRACTOR_SKIP_CONFIRMATION",
  ChangedByVendor = "CHANGED_BY_VENDOR",
  Confirmed = "CONFIRMED",
  IssuesReported = "ISSUES_REPORTED",
  IssuesResolved = "ISSUES_RESOLVED",
  ItemDue = "ITEM_DUE",
  NeedsToBeReceived = "NEEDS_TO_BE_RECEIVED",
  PartiallyReceived = "PARTIALLY_RECEIVED",
  PartiallyReceivedInternal = "PARTIALLY_RECEIVED_INTERNAL",
  Received = "RECEIVED",
  ReceivedInternal = "RECEIVED_INTERNAL",
  RedeliveryCreatedIssueResolved = "REDELIVERY_CREATED_ISSUE_RESOLVED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  RequestedFromWarehouse = "REQUESTED_FROM_WAREHOUSE",
  RequestedInternal = "REQUESTED_INTERNAL",
  RequestedSkipConfirmation = "REQUESTED_SKIP_CONFIRMATION",
  Upcoming = "UPCOMING",
}

export type ReleaseGroup = Node & {
  __typename?: "ReleaseGroup";
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type ReleaseIssue = Node & {
  __typename?: "ReleaseIssue";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  issueType: ReleaseIssueType;
  /** @deprecated use photos */
  photoUrls: Array<Scalars["String"]["output"]>;
  photos: Array<Asset>;
  /** @deprecated use quantityDecimal */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  releaseItem: ReleaseItem;
  resolution?: Maybe<ReleaseIssueResolution>;
};

export type ReleaseIssueInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  issueType: ReleaseIssueType;
  photoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  releaseItemId: Scalars["ID"]["input"];
};

export type ReleaseIssueResolution = Node & {
  __typename?: "ReleaseIssueResolution";
  comments?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  issue: ReleaseIssue;
  redeliveryReleaseItem?: Maybe<ReleaseItem>;
  resolutionType: ReleaseIssueResolutionType;
};

export type ReleaseIssueResolutionInput = {
  comments?: InputMaybe<Scalars["String"]["input"]>;
  issueId: Scalars["ID"]["input"];
  redelivery?: InputMaybe<AlternativeFulfillmentInput>;
  resolutionType: ReleaseIssueResolutionType;
};

export enum ReleaseIssueResolutionType {
  AdjustInvoice = "ADJUST_INVOICE",
  Other = "OTHER",
  Redeliver = "REDELIVER",
}

export enum ReleaseIssueType {
  Damaged = "DAMAGED",
  LessThanOrdered = "LESS_THAN_ORDERED",
  MoreThanOrdered = "MORE_THAN_ORDERED",
  Other = "OTHER",
  WrongItem = "WRONG_ITEM",
}

export type ReleaseItem = Node & {
  __typename?: "ReleaseItem";
  /** Whether the item was added by the vendor. */
  addedByVendor: Scalars["Boolean"]["output"];
  /**
   * alternativeFulfillmentQuantity represents the quantity of this item that
   * will be fulfilled at the alternative time (alternativeFulfillmentTime) or
   * via the alternative release (alternativeFulfillmentRelease).
   *
   * This field may not be 0 or negative.
   * This field may not indicate a quantity greater than the original release
   * item quantity.
   * This field may not indicate a quantity equal to the original release item
   * quantity - set this to null if the entire quantity will be fulfilled
   * outside of the original release.
   *
   * This field is null if no alternative fulfillment is specified or if the
   * entire quantity will be fulfilled outside of the original release.
   * @deprecated use alternativeFulfillmentQuantityDecimal instead
   */
  alternativeFulfillmentQuantity?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated no longer used */
  alternativeFulfillmentQuantityDecimal?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * alternativeFulfillmentRelease field specifies that a given release item should be moved to a different release.
   * When release transitions into the SCHEDULED state, the item will be moved to the release specified by this field.
   * Note that for a given release item, alternativeFulfillmentRelease and alternativeFulfillmentTime can't be both set.
   * @deprecated use deliveryDate instead
   */
  alternativeFulfillmentRelease?: Maybe<Release>;
  /**
   * alternativeFulfillmentTime field is used to save fulfillment date specified by the vendor before the release goes into SCHEDULED state.
   * When the release gets accepted by the vendor (goes into SCHEDULED status), all release items such that
   * alternativeFulfillmentTime is different from Release.time should be grouped together by time and a new Release should be created
   * for each such group with the Release.time = alternativeFulfillmentTime.
   * If all release items have a different time from Release.requestedTime, original release object can be recycled
   * (used for one of the groups) and its Release.time will be set to the new time.
   * @deprecated use deliveryDate instead
   */
  alternativeFulfillmentTime?: Maybe<Scalars["Timestamp"]["output"]>;
  /**
   * Asset urls when vendor or contactor uploads release item assets (photos,
   * videos, documents).
   *
   * Components:
   * 	Not available.
   * @deprecated use assets
   */
  assetUrls: Array<Scalars["String"]["output"]>;
  assets: Array<Asset>;
  backorderFromRelease?: Maybe<Release>;
  /**
   * buyoutItem is no longer a required field since we are now allowing
   * contractors to add items to a release that were not included in the
   * original buyout.
   */
  buyoutItem?: Maybe<BuyoutItem>;
  /**
   * compatibleBuyoutItems returns all buyout items from the buyout of the release
   * such that the underlying org material is the same.
   */
  compatibleBuyoutItems: Array<Maybe<BuyoutItem>>;
  /** Only available on parent items. */
  components?: Maybe<Array<ReleaseItem>>;
  /** The cost code associated with this item. */
  costCode?: Maybe<CostCode>;
  /** The cost type associated with this item. */
  costType?: Maybe<CostType>;
  deliveredItems: Array<DeliveredReleaseItem>;
  deliveryDate?: Maybe<Scalars["Timestamp"]["output"]>;
  deliveryDateTBD: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  /**
   * Components:
   * 	Not available.
   */
  instructions?: Maybe<Instruction>;
  invoiceItems: Array<InvoicedReleaseItem>;
  /** Invoiced amount, calculated across all invoices. */
  invoicedAmount: Amount;
  invoicedQuantity: Scalars["Decimal"]["output"];
  /**
   * Returns the adjustment amount that should be subtracted from release item
   * ext price or PO item remaining amount when calculating the Rem. column in
   * the UI.
   */
  invoicedRemainingAdjustment?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * This value is applicable to the entire assembly (parent item and
   * components).
   */
  isIncluded: Scalars["Boolean"]["output"];
  /** @deprecated Use issues instead */
  issue?: Maybe<ReleaseIssue>;
  /** ReleaseIssues if this item has a reported issue. */
  issues: Array<ReleaseIssue>;
  /** Manufacturer specified/confirmed by distributor */
  manufacturer?: Maybe<Manufacturer>;
  name: Scalars["String"]["output"];
  /**
   * Notes from the vendor.
   *
   * Components:
   * 	Not available.
   */
  notes?: Maybe<Scalars["String"]["output"]>;
  /**
   * The time at which the release note was read by the contractor.
   *
   * The value of this field is reset whenever the vendor changes release
   * item notes or assets. In case of reserved release items, for which the
   * notes are copied over from the parent buyout item, the value of this
   * field will be equal to release item creation date.
   *
   * Components:
   * 	Not available.
   */
  notesReadAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** Ordered amount, calculated across all releases. */
  orderedAmount: Amount;
  /** the release_item's material. */
  orgMaterial?: Maybe<OrgMaterial>;
  /** Only available on components. */
  parent?: Maybe<ReleaseItem>;
  /**
   * Only populated when the release item is retrieved via
   * InvoiceValidation->itemsExceedingRemainingAmount.
   */
  poItem?: Maybe<ExternalPoItem>;
  /**
   * POItemLink is present when the release item is connected to a PO item.
   *
   * If present, it must point to a PO item that is connected to the same PO as
   * the release header (Release->poLink).
   *
   * A POItemLink can either by created upon the creation of the release item
   * (via externalPOItemId) or as a result of the PO sync process.
   *
   * Release items that have a retroactive Release->poLink cannot have the
   * following fields updated:
   * - description
   * - uom
   * - cost code.
   */
  poItemLink?: Maybe<PoItemLink>;
  /** Item position on spreadsheet view */
  position?: Maybe<Scalars["Int"]["output"]>;
  preferredVendor?: Maybe<OrgPreferredVendor>;
  /**
   * This value is applicable to the entire assembly (parent item and
   * components).
   */
  prevIsIncluded: Scalars["Boolean"]["output"];
  /**
   * This flag indicates if the item price has been prefilled from the
   * estimated item price.
   */
  priceEstimated: Scalars["Boolean"]["output"];
  /** @deprecated not used anymore */
  pricePrefilled: Scalars["Boolean"]["output"];
  /**
   * This flag indicates if the item price has been prefilled from a VendorPrice.
   * Note that similarly to pricePrefilled, this flag gets cleared when a price
   * adjustment is made to the item.
   */
  pricePrenegotiated: Scalars["Boolean"]["output"];
  /**
   * In case of release items with parent buyout items, this field will be set to
   * equal buyoutItem.projectItem. Otherwise, it will be set to the project item,
   * details of which were specified in the AddToReleaseInput.
   * @deprecated use orgMaterial instead
   */
  projectItem?: Maybe<ProjectItem>;
  /**
   * This is the actual fulfilled quantity (specified by the vendor). This is
   * measured in the uom.
   * @deprecated use quantityDecimal instead
   */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  /**
   * The received quantity of a release item.
   *
   * This field is always present when in RECEIVED or PARTIALLY_RECEIVED status.
   * It defaults to zero and is specified in the ->RECEIVED or PARTIALLY_RECEIVED state transitions.
   * @deprecated use receivedQuantityDecimal instead
   */
  receivedQuantity: Scalars["Float"]["output"];
  /**
   * Sum of DeliveredReleaseItem.quantity for this item across all PROCESSED,
   * non-archived delivery slips.
   */
  receivedQuantityDecimal: Scalars["Decimal"]["output"];
  /**
   * Redelivery will be present only if the item was added to a release as a
   * result of an issue resolution with resolutionType = REDELIVER.
   */
  redelivery?: Maybe<ReleaseIssueResolution>;
  release: Release;
  /** @deprecated use manufacturer instead */
  requestedManufacturer?: Maybe<Manufacturer>;
  /** @deprecated use quantity instead */
  requestedQuantity: Scalars["Float"]["output"];
  /** @deprecated use unitPrice instead */
  requestedUnitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  /** @deprecated use uom instead */
  requestedUom?: Maybe<Uom>;
  /**
   * When set, this item is planned to be placed in a separate release with a
   * different vendor.
   * Items with non-null sellerOrgLocation will be grouped together and moved
   * to newly created releases upon the parent release being submitted.
   *
   * This can only be specified for standalone release items and therefore,
   * parent release with components will always have null sellerOrgLocation
   * and preferredVendor.
   */
  sellerOrgLocation?: Maybe<OrgLocation>;
  sourceWarehouse?: Maybe<Warehouse>;
  stockQuantity?: Maybe<Scalars["Decimal"]["output"]>;
  /**
   * Components:
   * 	Not available.
   */
  tags: Array<ProjectTag>;
  taxable: Scalars["Boolean"]["output"];
  /**
   * Unit price of the release item per the uom specified by the vendor.
   *
   * In DRAFT releases, value of this field will be initialized to equal
   * that of requestedUnitPrice (nil or calculated from buyout item).
   *
   * When the requestedUom is changed by the contractor in a DRAFT or REQUESTED
   * release (resulting in requestedUnitPrice being adjusted), the unitPrice
   * will also be updated (similarly to requestedQuanity and quantity fields).
   *
   * When the vendor changes UOM of a release item, without changing the unit
   * price, the unit price will be recalculated using the conversion multiplier.
   */
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
  /** The fulfillment UOM. */
  uom?: Maybe<Uom>;
  /**
   * Components:
   * 	Not available.
   */
  zone?: Maybe<Zone>;
};

export type ReleaseItemDeliveredItemsArgs = {
  archivedDeliverySlips?: InputMaybe<Scalars["Boolean"]["input"]>;
  deliverySlipId?: InputMaybe<Scalars["ID"]["input"]>;
  deliverySlipStatuses?: InputMaybe<Array<DeliverySlipStatus>>;
};

export type ReleaseItemInvoiceItemsArgs = {
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus>>;
};

export type ReleaseItemInvoicedAmountArgs = {
  excludedInvoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  exported?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ReleaseItemInvoicedRemainingAdjustmentArgs = {
  excludedInvoiceId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ReleaseItemHint = {
  __typename?: "ReleaseItemHint";
  deliverySlipItem?: Maybe<DeliverySlipItem>;
  invoiceItem?: Maybe<InvoiceItem>;
  quoteDocumentItem?: Maybe<QuoteDocumentItem>;
  releaseItem: ReleaseItem;
};

export enum ReleaseNotificationsMode {
  ProjectMembers = "PROJECT_MEMBERS",
  SubmitterOnly = "SUBMITTER_ONLY",
}

export type ReleasePermissions = {
  __typename?: "ReleasePermissions";
  approve: AuthorizationStatus;
  archive: AuthorizationStatus;
  /** @deprecated use approve */
  canApprove: Scalars["Boolean"]["output"];
  /** @deprecated use archive */
  canArchive: Scalars["Boolean"]["output"];
  /** @deprecated use cancel */
  canCancel: Scalars["Boolean"]["output"];
  /** @deprecated use complete */
  canComplete: Scalars["Boolean"]["output"];
  /** @deprecated use edit */
  canEdit: Scalars["Boolean"]["output"];
  /** @deprecated use export */
  canExport: Scalars["Boolean"]["output"];
  /** @deprecated use submit */
  canSubmit: Scalars["Boolean"]["output"];
  /** @deprecated use submitDirectly */
  canSubmitDirectly: Scalars["Boolean"]["output"];
  cancel: AuthorizationStatus;
  complete: AuthorizationStatus;
  edit: AuthorizationStatus;
  export: AuthorizationStatus;
  reserve: AuthorizationStatus;
  submit: AuthorizationStatus;
  submitDirectly: AuthorizationStatus;
};

export type ReleaseSettings = {
  __typename?: "ReleaseSettings";
  defaultWarehouse?: Maybe<Warehouse>;
  instructions?: Maybe<Instruction>;
  notifications: ReleaseNotificationsMode;
  poNumberDelimiter: Scalars["String"]["output"];
  poNumberPickFirst: Scalars["Boolean"]["output"];
  poNumberSequenceDigits: Scalars["Int"]["output"];
  poNumberStartingSequence: Scalars["Int"]["output"];
  poNumberSuffixPreview: Scalars["String"]["output"];
  /**
   * it supports the following values:
   * - NEVER: we would hide the PO input and associated labels
   * - OPTIONAL: we would let the user to provide a PO by hand or auto-generate by user's choice
   * - ALWAYS: we always auto-generate if not specified
   */
  poNumbering: PoNumberingMode;
  /**
   * Controls the ERP PO integration.
   * When disabled:
   * - POs from the ERP won’t be accessible.
   * - There will be no option to export the PO.
   * - The user will be able to export the invoice while ignoring the lack of a
   * PO ERP equivalent.
   */
  useSourceSystemPOs: Scalars["Boolean"]["output"];
};

export enum ReleaseStatus {
  AwaitingApproval = "AWAITING_APPROVAL",
  Canceled = "CANCELED",
  Draft = "DRAFT",
  PartiallyReceived = "PARTIALLY_RECEIVED",
  Received = "RECEIVED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  Reserved = "RESERVED",
  Scheduled = "SCHEDULED",
}

export type ReleaseType = Node & {
  __typename?: "ReleaseType";
  additionalChargesCostCode?: Maybe<CostCode>;
  additionalChargesCostType?: Maybe<CostType>;
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  /** Billable vs Non-Billable switch, used in QuickBooks Online and Desktop. */
  billable: Scalars["Boolean"]["output"];
  chargesMode?: Maybe<ChargesMode>;
  /** Cost type to be used for PO items and invoice items for this release type. */
  costType?: Maybe<CostType>;
  createdAt: Scalars["Timestamp"]["output"];
  default: Scalars["Boolean"]["output"];
  defaultAutoSync: Scalars["Boolean"]["output"];
  /** Used in QuickBooks Desktop with the Specific Items mapping variant. */
  defaultOrgMaterial?: Maybe<OrgMaterial>;
  /**
   * When set to true, notifications about upcoming fulfillment times will be
   * sent.
   *
   * Defaults to true.
   */
  enableUpcomingNotifications: Scalars["Boolean"]["output"];
  /** @deprecated use Invoice.headerOnly */
  headerOnlyInvoices: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  /**
   * When set to false, releases of this type will not be allowed to have
   * additional charges.
   *
   * Defaults to true.
   */
  includeAdditionalCharges: Scalars["Boolean"]["output"];
  includeTaxInInvoiceSubtotal: Scalars["Boolean"]["output"];
  /**
   * General ledger accounts which PO items and invoice items will be
   * posted for this release type.
   *
   * Only applicable to SAGE100 and FOUNDATION source systems.
   */
  ledgerAccount?: Maybe<ExternalLedgerAccount>;
  /**
   * General ledger subaccount.
   *
   * Only applicable to SAGE100.
   */
  ledgerSubaccount?: Maybe<ExternalLedgerSubaccount>;
  name: Scalars["String"]["output"];
  /** When set to true, the release will be exported as a non-job PO in Foundation. */
  nonJobPO: Scalars["Boolean"]["output"];
  /** Purchase order format used when exporting POs */
  poFormat?: Maybe<PoFormat>;
  /** if specified, poNumbering overrides org-level poNumbering */
  poNumbering?: Maybe<PoNumberingMode>;
  /** PO type to be used for PO exports. Only applicable to SAGE100. */
  poType?: Maybe<ExternalPoType>;
  /**
   * When set to true, delivery slips will be required when receiving the release.
   *
   * Defaults to true.
   */
  requireDeliverySlip: Scalars["Boolean"]["output"];
  salesTaxCostCode?: Maybe<CostCode>;
  salesTaxCostType?: Maybe<CostType>;
  salesTaxOrgMaterial?: Maybe<OrgMaterial>;
  taxMode?: Maybe<TaxMode>;
  /** When set to true, it allows for a replenishment order in a warehouse. */
  trackInventory: Scalars["Boolean"]["output"];
  /** transactionKind defaults to PURCHASE when unspecified. */
  transactionKind: TransactionKind;
};

export type RemoveEstimatedItemsInput = {
  estimatedItemIds: Array<Scalars["ID"]["input"]>;
  projectId: Scalars["ID"]["input"];
};

export type RemoveFromBuyoutInput = {
  buyoutId: Scalars["ID"]["input"];
  buyoutItemIds: Array<Scalars["ID"]["input"]>;
};

export type RemoveFromReleaseInput = {
  releaseId: Scalars["ID"]["input"];
  /**
   * This must be the ID of parent release items. Components are removed
   * automatically when removing their parent release item.
   */
  releaseItemIds: Array<Scalars["ID"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RemoveFromRfqInput = {
  rfqId: Scalars["ID"]["input"];
  rfqItemIds: Array<Scalars["ID"]["input"]>;
};

export type RemoveOrgCatalogSkuInput = {
  id: Scalars["ID"]["input"];
};

export type RemoveOrgLocationInput = {
  id: Scalars["ID"]["input"];
};

export type Requisition = Node & {
  __typename?: "Requisition";
  address?: Maybe<Address>;
  clientIdentifier: Scalars["String"]["output"];
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  items: Array<RequisitionItem>;
  project: Project;
  rfq?: Maybe<Rfq>;
  siteContact?: Maybe<SiteContact>;
  status: RequisitionStatus;
};

export type RequisitionConnection = Connection & {
  __typename?: "RequisitionConnection";
  edges: Array<RequisitionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RequisitionEdge = Edge & {
  __typename?: "RequisitionEdge";
  cursor: Scalars["String"]["output"];
  node: Requisition;
};

export type RequisitionItem = Node & {
  __typename?: "RequisitionItem";
  id: Scalars["ID"]["output"];
  projectItem: ProjectItem;
  /** @deprecated Use `quantityDecimal` instead */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  requisitionId: Scalars["ID"]["output"];
  status: RequisitionItemStatus;
  supportDocs: Array<Scalars["String"]["output"]>;
  supportInstructions?: Maybe<Scalars["String"]["output"]>;
};

export enum RequisitionItemStatus {
  Approved = "APPROVED",
  Draft = "DRAFT",
  PendingReview = "PENDING_REVIEW",
  Rejected = "REJECTED",
}

export enum RequisitionStatus {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
  PendingReview = "PENDING_REVIEW",
  Rejected = "REJECTED",
}

export type ResendExternalNotificationInput = {
  id: Scalars["ID"]["input"];
};

export type ResendQuoteInput = {
  quoteId: Scalars["ID"]["input"];
};

export type ResolveInvoiceIssueInput = {
  /** assetUrl is an invoice document that will be attached to the invoice */
  assetUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Issue id */
  id: Scalars["ID"]["input"];
  /** issueAssetUrls are vendor assets that will be attached to the invoice issue. (set semantics) */
  issueAssetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  rejectionReason?: InputMaybe<Scalars["String"]["input"]>;
  resolutionDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResolveInvoiceIssuesInput = {
  assetUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** invoice id */
  id: Scalars["ID"]["input"];
  rejectionReason?: InputMaybe<Scalars["String"]["input"]>;
  /** setting resolutionDescription clears the rejectionReason field */
  resolutionDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResolveInvoiceKickbackInput = {
  /** resolution assets */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id: Scalars["ID"]["input"];
  resolutionDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResolveReceiptKickbackInput = {
  /** resolution assets */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id: Scalars["ID"]["input"];
  resolutionDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResolveReleaseIssuesInput = {
  issueResolutions?: InputMaybe<Array<ReleaseIssueResolutionInput>>;
  releaseId: Scalars["ID"]["input"];
};

export type Rfq = Node & {
  __typename?: "Rfq";
  clientIdentifier: Scalars["String"]["output"];
  createdAt: Scalars["Timestamp"]["output"];
  createdBy: User;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  includeServices: Array<ServiceType>;
  instructions?: Maybe<Instruction>;
  items: Array<RfqItem>;
  /** The version after this one for this RFQ, one set of changes ahead. Null if this is the newest version available. */
  nextRevision?: Maybe<Rfq>;
  noteDocument?: Maybe<NoteDocument>;
  numBiddersRfqSentTo: Scalars["Int"]["output"];
  numBiddersRfqViewedBy: Scalars["Int"]["output"];
  permissions: RfqPermissions;
  /** The version before this one for this RFQ, one set of changes behind. Null if this is the oldest version available. */
  previousRevision?: Maybe<Rfq>;
  project: Project;
  quotes: Array<Quote>;
  requisition?: Maybe<Requisition>;
  status: RfqStatus;
  /** @deprecated use targets instead */
  targetSellersOrgLocations: Array<OrgLocation>;
  targets: Array<RfqPreferredVendor>;
};

export type RfqQuotesArgs = {
  status?: InputMaybe<Array<QuoteStatus>>;
};

export type RfqConnection = Connection & {
  __typename?: "RfqConnection";
  edges: Array<RfqEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RfqEdge = Edge & {
  __typename?: "RfqEdge";
  cursor: Scalars["String"]["output"];
  node: Rfq;
};

export type RfqItem = Node & {
  __typename?: "RfqItem";
  /** The cost code associated with this item. */
  costCode?: Maybe<CostCode>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  instructions?: Maybe<Instruction>;
  manufacturer?: Maybe<Manufacturer>;
  /** Item position on spreadsheet view */
  position?: Maybe<Scalars["Int"]["output"]>;
  projectItem: ProjectItem;
  /** @deprecated Use `quantityDecimal` instead */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  rfqId: Scalars["ID"]["output"];
  /** @deprecated Use `instructions` instead. */
  supportInstructions?: Maybe<Scalars["String"]["output"]>;
};

export type RfqPermissions = {
  __typename?: "RfqPermissions";
  archive: AuthorizationStatus;
  cancel: AuthorizationStatus;
  edit: AuthorizationStatus;
  exportQuotes: AuthorizationStatus;
  submit: AuthorizationStatus;
};

export type RfqPreferredVendor = Node & {
  __typename?: "RfqPreferredVendor";
  contacts: Array<VendorContact>;
  id: Scalars["ID"]["output"];
  sellerOrgLocation: OrgLocation;
};

export type RfqPreferredVendorInput = {
  contactIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sellerOrgLocationId: Scalars["ID"]["input"];
};

export enum RfqStatus {
  Active = "ACTIVE",
  Awarded = "AWARDED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
}

/**
 * SMS verification is a two-step process. First, the user requests a code to be
 * sent to their phone. Then, they submit the code to verify their phone number.
 * Optionally, the code may be re-sent in case that the user did not receive it.
 */
export type SmsVerification = Node & {
  __typename?: "SMSVerification";
  completedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  phone: Scalars["String"]["output"];
  /** How many times the user can submit the code. */
  remainingAttempts: Scalars["Int"]["output"];
  /** How many times the user can request the code to be resent. */
  remainingDeliveries: Scalars["Int"]["output"];
};

export type ScheduleReleaseInput = {
  releaseId: Scalars["ID"]["input"];
  /** If specified, updates the release time. */
  time?: InputMaybe<Scalars["Timestamp"]["input"]>;
  version: Scalars["Int"]["input"];
};

export type SearchContext = {
  __typename?: "SearchContext";
  inferredCategoryId: Scalars["ID"]["output"];
  query: Scalars["String"]["output"];
  terms: Array<Scalars["String"]["output"]>;
};

export type SearchInput = {
  query?: InputMaybe<Scalars["String"]["input"]>;
  refineToDominantCategory: Scalars["Boolean"]["input"];
  terms?: InputMaybe<SearchTermsInput>;
};

export type SearchTermsInput = {
  removeTokensWithEmptyResults: Scalars["Boolean"]["input"];
  tokens: Array<Scalars["String"]["input"]>;
};

export type SendMessageInput = {
  context: MessageContextInput;
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  message: Scalars["String"]["input"];
  teamMembers?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Service = {
  __typename?: "Service";
  name: ServiceName;
  productCategory: ServiceProductCategory;
  type: ServiceType;
};

export type ServiceCode = Node & {
  __typename?: "ServiceCode";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  externalServiceCode?: Maybe<ExternalServiceCode>;
  id: Scalars["ID"]["output"];
};

export enum ServiceName {
  Crane = "CRANE",
  FivePersonCrew = "FIVE_PERSON_CREW",
  Flagman = "FLAGMAN",
  Gradall = "GRADALL",
  GradallOperator = "GRADALL_OPERATOR",
  HandPack = "HAND_PACK",
  LocalDeliveryWithoutForklift = "LOCAL_DELIVERY_WITHOUT_FORKLIFT",
  LocalDeliveryWithForklift = "LOCAL_DELIVERY_WITH_FORKLIFT",
  RollInElevator = "ROLL_IN_ELEVATOR",
  RollInGroundFloor = "ROLL_IN_GROUND_FLOOR",
  ThreePersonCrew = "THREE_PERSON_CREW",
}

export type ServicePrice = Node & {
  __typename?: "ServicePrice";
  id: Scalars["ID"]["output"];
  name: ServiceName;
  offHoursAmount?: Maybe<Scalars["Decimal"]["output"]>;
  offHoursUOM: Uom;
  productCategory: ServiceProductCategoryName;
  regularHoursAmount?: Maybe<Scalars["Decimal"]["output"]>;
  regularHoursUOM: Uom;
  type: ServiceType;
};

export type ServicePriceSheet = Node & {
  __typename?: "ServicePriceSheet";
  createdAt: Scalars["Timestamp"]["output"];
  discount?: Maybe<Discount>;
  id: Scalars["ID"]["output"];
  prices: Array<ServicePrice>;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type ServiceProductCategory = {
  __typename?: "ServiceProductCategory";
  name: ServiceProductCategoryName;
  /** Applicable UOMs for services are managed by Field Materials. */
  uomOptions: Array<Uom>;
};

export enum ServiceProductCategoryName {
  All = "ALL",
  Drywall = "DRYWALL",
  LightGaugeSteel = "LIGHT_GAUGE_STEEL",
}

export enum ServiceType {
  Delivery = "DELIVERY",
  Stocking = "STOCKING",
}

export type SetProjectAllowanceInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  costCodes?: InputMaybe<Array<CostCodeAllowanceInput>>;
  tags?: InputMaybe<Array<TagAllowanceInput>>;
};

export type SetVendorPriceInput = {
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /**
   * if the id is present, an existing vendor price will be updated, otherwise a
   * new one will be created.
   */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  leadTime?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  minimumOrder?: InputMaybe<Scalars["Float"]["input"]>;
  orderIncrement?: InputMaybe<Scalars["Float"]["input"]>;
  orgMaterialId: Scalars["ID"]["input"];
  price: Scalars["Decimal"]["input"];
  sellerOrgLocationId: Scalars["ID"]["input"];
  uom: Scalars["String"]["input"];
};

export type SetVendorPricesInput = {
  items: Array<SetVendorPriceInput>;
};

export type SiteContact = Node & {
  __typename?: "SiteContact";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
};

export type SiteContactInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
};

export type Sku = Node & {
  __typename?: "Sku";
  brand: Scalars["String"]["output"];
  constraints?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageUrl: Scalars["String"]["output"];
  manufacturer: Manufacturer;
  manufacturerCode?: Maybe<Scalars["String"]["output"]>;
  manufacturerSkuUrl: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  product: Product;
  safetyDataSheetUrl?: Maybe<Scalars["String"]["output"]>;
  specsUrl: Scalars["String"]["output"];
};

export type SkuConnection = Connection &
  MasterCatalogConnection & {
    __typename?: "SkuConnection";
    edges: Array<SkuEdge>;
    facets: Array<Facet>;
    pageInfo: PageInfo;
    searchContext?: Maybe<SearchContext>;
    totalCount: Scalars["Int"]["output"];
  };

export type SkuEdge = Edge & {
  __typename?: "SkuEdge";
  cursor: Scalars["String"]["output"];
  node: Sku;
};

export enum SourceSystem {
  Cmic = "CMIC",
  Foundation = "FOUNDATION",
  Procore = "PROCORE",
  /**
   * Specific Items variant of QuickBooks Desktop implementation:
   * - classes -> cost codes
   * - items -> Item Database
   * - cost types disabled in FM
   */
  QbDesktop = "QB_DESKTOP",
  /**
   * Generic Items variant of QuickBooks Desktop implementation:
   * - items -> cost codes
   * - classes -> cost types
   * - Item Database entries not importable
   */
  QbDesktopAlt = "QB_DESKTOP_ALT",
  QbOnline = "QB_ONLINE",
  Sage100 = "SAGE100",
  Sage300 = "SAGE300",
  SageIntacct = "SAGE_INTACCT",
  Vista = "VISTA",
}

export type SourceSystemMapping = {
  __typename?: "SourceSystemMapping";
  externalId: Scalars["String"]["output"];
  sourceSystem: SourceSystem;
};

export type SourceSystemSettingCustomTemplate = {
  __typename?: "SourceSystemSettingCustomTemplate";
  name: Scalars["String"]["output"];
  template: Scalars["String"]["output"];
};

export type SourceSystemSettings = {
  __typename?: "SourceSystemSettings";
  accountsPayableLedgerAccount?: Maybe<ExternalLedgerAccount>;
  accountsPayableLedgerAccountExternalId?: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated use ReleaseType.additionalChargesCostCode instead */
  additionalChargesCostCode?: Maybe<ExternalCostCode>;
  additionalChargesCostCodeExternalId?: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated use ReleaseType.additionalChargesCostType instead */
  additionalChargesCostType?: Maybe<ExternalCostType>;
  additionalChargesCostTypeExternalId?: Maybe<Scalars["ID"]["output"]>;
  /** When set to True, the system will automatically close the project when the job status is set to closed. */
  autoCloseProjectsByJobStatus: Scalars["Boolean"]["output"];
  autoExportApprovedInvoices?: Maybe<Scalars["Boolean"]["output"]>;
  autoPostInvoices?: Maybe<Scalars["Boolean"]["output"]>;
  autoPostPOs?: Maybe<Scalars["Boolean"]["output"]>;
  companyId: Scalars["String"]["output"];
  companyName: Scalars["String"]["output"];
  connected: Scalars["Boolean"]["output"];
  /**
   * Custom invoice templates.
   *
   * The available data is different from the preview data, and it consists
   * of the following models:
   * - System (SourceSystem)
   * - Project
   * - Buyout
   * - Release
   * - Invoice
   * - Vendor
   * - Approver (User)
   */
  customInvoiceTemplates: Array<SourceSystemSettingCustomTemplate>;
  /**
   * Custom PO templates.
   *
   * The available data is different from the preview data, and it consists
   * of the following models:
   * - System (SourceSystem)
   * - Project
   * - Buyout
   * - Release
   * - Vendor
   */
  customPOTemplates: Array<SourceSystemSettingCustomTemplate>;
  defaultAutoSync?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Default purchase order format used when exporting POs to this source system.
   * @deprecated use ReleaseType.poFormat instead
   */
  defaultPOFormat?: Maybe<PoFormat>;
  /**
   * Whether to distribute PO line items to warehouse by default, if the PO
   * format is set to DETAIL.
   */
  defaultToWarehouse?: Maybe<Scalars["Boolean"]["output"]>;
  distributeAdditionalCharges?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Whether or not to blend the invoice/PO sales tax with other line items.
   *
   * Setting this to true will result in invoices and POs being exported in a
   * cost code-aggregated format, without listing individual materials.
   * @deprecated use taxMode instead
   */
  distributeSalesTax?: Maybe<Scalars["Boolean"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  errorLastChecked?: Maybe<Scalars["Timestamp"]["output"]>;
  errorSince?: Maybe<Scalars["Timestamp"]["output"]>;
  fiscalYearStartMonth: Scalars["Int"]["output"];
  /** When set to true, tax will be ignored when exporting/syncing POs. */
  ignorePOTax: Scalars["Boolean"]["output"];
  inventoryReceiptLedgerAccount?: Maybe<ExternalLedgerAccount>;
  inventoryReceiptLedgerAccountExternalId?: Maybe<Scalars["ID"]["output"]>;
  /** Template for the Invoice description. */
  invoiceDescriptionTemplate?: Maybe<Scalars["String"]["output"]>;
  materialCostType?: Maybe<ExternalCostType>;
  materialCostTypeExternalId?: Maybe<Scalars["ID"]["output"]>;
  /** Template for the PO description */
  poDescriptionTemplate?: Maybe<Scalars["String"]["output"]>;
  /**
   * When set to true, the ledger account will be included in the PO line items
   * when exporting to this source system.(Sage 100 only)
   */
  poLineItemsIncludeLedgerAccount: Scalars["Boolean"]["output"];
  /** Template for the PO name */
  poNameTemplate?: Maybe<Scalars["String"]["output"]>;
  /**
   * Whether or not the source system allows the invoiced amount to exceed the PO
   * item amount.
   */
  poStrictAmounts: Scalars["Boolean"]["output"];
  poType?: Maybe<ExternalPoType>;
  poTypeExternalId?: Maybe<Scalars["ID"]["output"]>;
  postInventoryReceipts?: Maybe<Scalars["Boolean"]["output"]>;
  progress: IntegrationMappingProgress;
  /** @deprecated use ReleaseType.salesTaxCostCode instead */
  salesTaxCostCode?: Maybe<ExternalCostCode>;
  salesTaxCostCodeExternalId?: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated use ReleaseType.salesTaxCostType instead */
  salesTaxCostType?: Maybe<ExternalCostType>;
  salesTaxCostTypeExternalId?: Maybe<Scalars["ID"]["output"]>;
  skipBudgetValidation: Scalars["Boolean"]["output"];
  /** Read-only fields populated during the integration setup. */
  system: SourceSystem;
  taxMode?: Maybe<TaxMode>;
  toJobLedgerAccount?: Maybe<ExternalLedgerAccount>;
  toJobLedgerAccountExternalId?: Maybe<Scalars["ID"]["output"]>;
  toJobLedgerSubaccount?: Maybe<ExternalLedgerSubaccount>;
  toJobLedgerSubaccountExternalId?: Maybe<Scalars["ID"]["output"]>;
  toWarehouseLedgerAccount?: Maybe<ExternalLedgerAccount>;
  toWarehouseLedgerAccountExternalId?: Maybe<Scalars["ID"]["output"]>;
  /**
   * Whether or not to source cost code + cost type (work breakdown structure)
   * information from project tags.
   * If set to true, only cost code-aggregated PO/invoice exports will be supported
   * with sales tax and additional charges blended with line items.
   * Only supported by VISTA.
   */
  wbsTags?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SpeculativeReleaseItemState = {
  __typename?: "SpeculativeReleaseItemState";
  id: Scalars["ID"]["output"];
  /** @deprecated not used anymore */
  pricePrefilled: Scalars["Boolean"]["output"];
  pricePrenegotiated: Scalars["Boolean"]["output"];
  /** @deprecated use quantityDecimal */
  quantity: Scalars["Float"]["output"];
  quantityDecimal: Scalars["Decimal"]["output"];
  unitPrice?: Maybe<Scalars["Decimal"]["output"]>;
};

export type SpeculativeReleaseState = {
  __typename?: "SpeculativeReleaseState";
  customTaxAmount?: Maybe<Scalars["Decimal"]["output"]>;
  items?: Maybe<Array<SpeculativeReleaseItemState>>;
  taxRate?: Maybe<Scalars["Decimal"]["output"]>;
};

export type SplitInvoiceDocumentInput = {
  assetUrl: Scalars["String"]["input"];
};

export type SteelAccessoryAngleProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryAngleProductDetail";
    coating: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    ksi: Scalars["Int"]["output"];
    length: Scalars["Int"]["output"];
    mil: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    width: Scalars["Float"]["output"];
  };

export type SteelAccessoryControlJointProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryControlJointProductDetail";
    coating?: Maybe<Scalars["Int"]["output"]>;
    ground?: Maybe<Scalars["Float"]["output"]>;
    id: Scalars["ID"]["output"];
    length: Scalars["Int"]["output"];
    materials: Array<SteelMaterial>;
    productId: Scalars["ID"]["output"];
  };

export type SteelAccessoryFlatStrapProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryFlatStrapProductDetail";
    coating: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    ksi: Scalars["Int"]["output"];
    length: Scalars["Int"]["output"];
    mil: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    strapWidth: Scalars["Float"]["output"];
  };

export type SteelAccessoryFurringChannelProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryFurringChannelProductDetail";
    coating: Scalars["Int"]["output"];
    height: Scalars["Float"]["output"];
    id: Scalars["ID"]["output"];
    ksi: Scalars["Int"]["output"];
    length: Scalars["Int"]["output"];
    mil: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    ssma: Scalars["String"]["output"];
  };

export type SteelAccessoryTrimCornerbeadProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryTrimCornerbeadProductDetail";
    applicationWalls: Array<SteelApplicationWall>;
    coating?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    leg1?: Maybe<Scalars["Float"]["output"]>;
    leg2?: Maybe<Scalars["Float"]["output"]>;
    length: Scalars["Int"]["output"];
    materials: Array<SteelMaterial>;
    productId: Scalars["ID"]["output"];
  };

export type SteelAccessoryUChannelProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryUChannelProductDetail";
    coating: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    ksi: Scalars["Int"]["output"];
    length: Scalars["Int"]["output"];
    mil: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    ssma: Scalars["String"]["output"];
    width: Scalars["Float"]["output"];
  };

export type SteelAccessoryZFurringChannelProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelAccessoryZFurringChannelProductDetail";
    coating: Scalars["Int"]["output"];
    depth: Scalars["Float"]["output"];
    id: Scalars["ID"]["output"];
    leg1: Scalars["Float"]["output"];
    leg2: Scalars["Float"]["output"];
    length: Scalars["Int"]["output"];
    mil: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
  };

export type SteelApplicationWall = {
  __typename?: "SteelApplicationWall";
  name: Scalars["String"]["output"];
};

export type SteelLathAccessoryCornerProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelLathAccessoryCornerProductDetail";
    coating?: Maybe<Scalars["Int"]["output"]>;
    depth?: Maybe<Scalars["Float"]["output"]>;
    flange?: Maybe<Scalars["Float"]["output"]>;
    id: Scalars["ID"]["output"];
    length: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    types: Array<SteelLathType>;
  };

export type SteelLathProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelLathProductDetail";
    coating?: Maybe<Scalars["Int"]["output"]>;
    depth?: Maybe<Scalars["Float"]["output"]>;
    id: Scalars["ID"]["output"];
    length?: Maybe<Scalars["Int"]["output"]>;
    productId: Scalars["ID"]["output"];
    types: Array<SteelLathType>;
    width?: Maybe<Scalars["Float"]["output"]>;
  };

export type SteelLathType = {
  __typename?: "SteelLathType";
  name: Scalars["String"]["output"];
};

export type SteelMaterial = {
  __typename?: "SteelMaterial";
  name: Scalars["String"]["output"];
};

export type SteelProductDetail = Node &
  ProductDetail & {
    __typename?: "SteelProductDetail";
    coating: Scalars["Int"]["output"];
    flange: Scalars["Float"]["output"];
    id: Scalars["ID"]["output"];
    ksi?: Maybe<Scalars["Int"]["output"]>;
    length: Scalars["Int"]["output"];
    mil: Scalars["Int"]["output"];
    productId: Scalars["ID"]["output"];
    returnLip?: Maybe<Scalars["Float"]["output"]>;
    ssma: Scalars["String"]["output"];
    webDepth: Scalars["Float"]["output"];
  };

export type SubmitBuyoutInput = {
  buyoutId: Scalars["ID"]["input"];
  skipConfirmation?: Scalars["Boolean"]["input"];
  /** Skips the vendor notification process */
  skipVendorNotification?: Scalars["Boolean"]["input"];
};

export type SubmitQuoteInput = {
  quoteId: Scalars["ID"]["input"];
};

export type SubmitReleaseInput = {
  /** Delivery photos can only be provided when submitting a release retroactively. */
  deliveryPhotoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  deliverySlipIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Delivery slip is required when submitting a release retroactively. */
  deliverySlipUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Delivery slip is required when submitting a release retroactively. */
  deliverySlipUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  releaseId: Scalars["ID"]["input"];
  reserve?: Scalars["Boolean"]["input"];
  /**
   * Indicates that the release is submitted retroactively, placing it directly
   * in RECEIVED or PARTIALLY_RECEIVED statuses, bypassing vendor-facing notifications entirely.
   * When placing an order retroactively, we should set receivedQuantity = quantity for all items.
   */
  retroactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Skips the vendor confirmation process placing the release directly into
   * SCHEDULED status.
   * Ineffectual if the release is required to transition to AWAITING_APPROVAL.
   */
  skipConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Skips the vendor notification process, even if skipConfirmation is set to true.
   * For example, if this is set to true and skipConfirmation is set to false,
   * then the vendor will be notified that the release has been scheduled.
   */
  skipVendorNotification?: Scalars["Boolean"]["input"];
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmitRfqInput = {
  rfqId: Scalars["ID"]["input"];
};

export type SubmitSmsVerificationInput = {
  code: Scalars["String"]["input"];
  smsVerificationId: Scalars["ID"]["input"];
};

export enum SystemRole {
  FmAdmin = "FM_ADMIN",
  FmUser = "FM_USER",
}

export type TagAllowance = Node & {
  __typename?: "TagAllowance";
  amount: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  tag: ProjectTag;
};

export type TagAllowanceInput = {
  amount: Scalars["Decimal"]["input"];
  tagId: Scalars["ID"]["input"];
};

/** This type is deprecated. Use org-level TaxCode instead. */
export type TaxAuthority = {
  __typename?: "TaxAuthority";
  code: Scalars["String"]["output"];
  rate: Scalars["Decimal"]["output"];
};

export type TaxAuthorityInput = {
  code: Scalars["String"]["input"];
  rate: Scalars["Decimal"]["input"];
};

export type TaxCode = Node & {
  __typename?: "TaxCode";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  code: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  externalTaxCode?: Maybe<ExternalTaxCode>;
  id: Scalars["ID"]["output"];
  rate: Scalars["Decimal"]["output"];
};

export enum TaxMode {
  /**
   * Blend sales/use tax amount proportionally between line items of the PO/invoice
   * by adjusting the unit price of each line item.
   */
  Blended = "BLENDED",
  /**
   * Blend sales/use tax amount proportionally between line items of the PO or
   * invoice and place the resulting amount in the tax column of each item.
   */
  Column = "COLUMN",
  /**
   * The sales tax will listed as a separate line item with a cost code that has
   * the highest dollar amount in the PO/Invoice.
   */
  Majority = "MAJORITY",
  /** List sales/use tax amount as a separate line item in the PO/invoice. */
  Separate = "SEPARATE",
}

export enum TaxType {
  Sales = "SALES",
  Use = "USE",
}

export enum TransactionKind {
  Purchase = "PURCHASE",
  Rental = "RENTAL",
  Services = "SERVICES",
}

export type Uom = Node & {
  __typename?: "UOM";
  /**
   * A list of alternative mnemonics associated with this UOM.
   * @deprecated Use alternativeRefs instead
   */
  alternativeMnemonics: Array<Scalars["String"]["output"]>;
  /** A list of alternative refs associated with this UOM. */
  alternativeRefs: Array<Scalars["String"]["output"]>;
  /** The duration of this UOM, ex "30 days". */
  duration?: Maybe<Scalars["String"]["output"]>;
  /** The ID of this UOM. */
  id: Scalars["ID"]["output"];
  /**
   * A short abbreviation for this UOM.
   *
   * Global UOMs:
   * 	This is a required field.
   *
   * Org-scoped UOMs:
   * 	This is an optional field. Use `pluralDescription` if not set.
   */
  mnemonic?: Maybe<Scalars["String"]["output"]>;
  /** The parent organization of this UOM. This is null for global UOMs. */
  org?: Maybe<Organization>;
  /** The plural description is the primary textual representation of this UOM. */
  pluralDescription: Scalars["String"]["output"];
  /**
   * A description of this UOM in the singular form, used when quantities are
   * equal to 1.
   *
   * Global UOMs:
   * 	This is a required field.
   *
   * Org-scoped UOMs:
   * 	This is an optional field. Use `pluralDescription` if not set.
   */
  singularDescription?: Maybe<Scalars["String"]["output"]>;
};

export type UomInput = {
  /** The duration of this UOM, ex "30 days". */
  duration?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Flag that indicates whether the created UOM should be global or not. `null`
   * is treated as `false`.
   *
   * This requires the FM_ADMIN role.
   */
  global?: InputMaybe<Scalars["Boolean"]["input"]>;
  mnemonic?: InputMaybe<Scalars["String"]["input"]>;
  pluralDescription: Scalars["String"]["input"];
  singularDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnitPrice = {
  __typename?: "UnitPrice";
  unitPrice: Scalars["Decimal"]["output"];
  uom: Uom;
};

export type UpdateAccountingSettingsInput = {
  additionalChargesCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  additionalChargesCostType?: InputMaybe<Scalars["String"]["input"]>;
  defaultPOFormat?: InputMaybe<PoFormat>;
  defaultToWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultWarehouseNumber?: InputMaybe<Scalars["String"]["input"]>;
  distributeAdditionalCharges?: InputMaybe<Scalars["Boolean"]["input"]>;
  distributeSalesTax?: InputMaybe<Scalars["Boolean"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration: IntegrationType;
  inventoryReceiptLedgerAccount?: InputMaybe<Scalars["String"]["input"]>;
  materialCostType?: InputMaybe<Scalars["String"]["input"]>;
  postInventoryReceipts?: InputMaybe<Scalars["Boolean"]["input"]>;
  salesTaxCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  salesTaxCostType?: InputMaybe<Scalars["String"]["input"]>;
  taxAuthorities?: InputMaybe<Array<TaxAuthorityInput>>;
  toJobLedgerAccount?: InputMaybe<Scalars["String"]["input"]>;
  toWarehouseLedgerAccount?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateAssetInput = {
  isPrivate: Scalars["Boolean"]["input"];
  url: Scalars["String"]["input"];
};

export type UpdateAuxiliaryQuoteItemInput = {
  /** Asset URLs as returned by the uploadAsset mutation. SET semantics. */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  auxiliaryItemId: Scalars["ID"]["input"];
  clearUnitPrice?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update the description of this auxiliary item. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Setting expirationDays to 1-1-1 00:00:00 UTC (January 1, year 1, 00:00:00
   * UTC) resets the expiration date to the quote's, if any.
   */
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /** Setting lead time to 0 clears the value. */
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateBudgetLinkInput = {
  autoSync?: InputMaybe<Scalars["Boolean"]["input"]>;
  budgetLinkID: Scalars["ID"]["input"];
};

export type UpdateBuyoutServicePricesInput = {
  buyoutId: Scalars["ID"]["input"];
  servicePrice: UpdateServicePriceInput;
  version: Scalars["Int"]["input"];
};

export type UpdateBuyoutTermsInput = {
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  buyoutId: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentTermDays?: InputMaybe<Scalars["Int"]["input"]>;
  version: Scalars["Int"]["input"];
};

export type UpdateChargeInput = {
  amount: Scalars["Decimal"]["input"];
  description: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateComplianceGroupInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateComplianceGroupsInput = {
  added?: InputMaybe<Array<AddComplianceGroupInput>>;
  deleted?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updated?: InputMaybe<Array<UpdateComplianceGroupInput>>;
};

export type UpdateContractorBuyoutInput = {
  addedItems?: InputMaybe<Array<AddToBuyoutItemInput>>;
  additionalChargesAllowance?: InputMaybe<Scalars["Decimal"]["input"]>;
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  buyoutId: Scalars["ID"]["input"];
  clearAdditionalChargesAllowance?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearCustomTaxAmount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearQuoteDocument?: Scalars["Boolean"]["input"];
  clearTaxCode?: Scalars["Boolean"]["input"];
  clientCustomNumber?: InputMaybe<Scalars["String"]["input"]>;
  customTaxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  includeServices?: InputMaybe<Array<UpdateServiceInput>>;
  instructions?: InputMaybe<InstructionInput>;
  mergeDuplicates?: Scalars["Boolean"]["input"];
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  quoteDocumentAssetUrl?: InputMaybe<Scalars["String"]["input"]>;
  releaseTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  removedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  taxCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  taxMode?: InputMaybe<TaxMode>;
  taxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxType?: InputMaybe<TaxType>;
  updates?: InputMaybe<Array<UpdateContractorBuyoutItemInput>>;
  vendorContactIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Updating the vendor of a buyout is allowed only if the buyout has never been
   * submitted before.
   */
  vendorLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  version: Scalars["Int"]["input"];
};

export type UpdateContractorBuyoutItemInput = {
  buyoutItemId: Scalars["ID"]["input"];
  clearCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  instructions?: InputMaybe<InstructionInput>;
  /**
   *    Updating the manufacturer ID might have the side effect of updating the
   *    underlying material as well, as follows:
   *
   * - if the material is a master product and there is an available SKU with a
   *   matching product and manufacturer then the material will be changed to
   *   that SKU
   * - if the material is a SKU and there is no matching SKU with the same base
   *   master product but with this new manufacturer then the material will be
   *   switched to that base master product
   */
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  requestedUnitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateContractorReleaseInput = {
  addedItems?: InputMaybe<Array<AddToReleaseItemInput>>;
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  basicInvoicing?: InputMaybe<Scalars["Boolean"]["input"]>;
  buyoutId?: InputMaybe<Scalars["ID"]["input"]>;
  clearBuyout?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearComplianceGroup?: Scalars["Boolean"]["input"];
  clearCostCode?: Scalars["Boolean"]["input"];
  clearCustomTaxAmount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearNoteDocument?: Scalars["Boolean"]["input"];
  clearQuoteDocument?: Scalars["Boolean"]["input"];
  clearSiteContact?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearTaxCode?: Scalars["Boolean"]["input"];
  clearTaxRate?: Scalars["Boolean"]["input"];
  clearTaxVariance?: Scalars["Boolean"]["input"];
  clearWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearZone?: Scalars["Boolean"]["input"];
  complianceGroupId?: InputMaybe<Scalars["ID"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  customTaxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  deliveryPhotoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  deliverySlipIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  deliverySlipUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Assigns the release to the specified group(s).
   * This field uses set semantics.
   */
  groupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  includeServices?: InputMaybe<Array<UpdateServiceInput>>;
  instructions?: InputMaybe<InstructionInput>;
  issues?: InputMaybe<Array<ReleaseIssueInput>>;
  noteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  operation?: InputMaybe<UpdateContractorReleaseOperation>;
  paymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** Controls price pre-filling of added and updated items */
  prefillPrices?: Scalars["Boolean"]["input"];
  quoteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  receiptPosted?: InputMaybe<Scalars["Boolean"]["input"]>;
  receive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Rejection reason for the release. Used in conjunction with operation = REJECT. */
  rejectionReason?: InputMaybe<Scalars["String"]["input"]>;
  releaseId: Scalars["ID"]["input"];
  removedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  requestedTime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  requiresInventoryReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Indicates that the release is submitted retroactively, placing it directly
   * in RECEIVED or PARTIALLY_RECEIVED statuses, bypassing vendor-facing notifications entirely.
   * Applicable only if operation = SUBMIT.
   */
  retroactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * When set to true, the release will transition to SCHEDULED status.
   * Ineffectual if the release in status other than REQUESTED.
   */
  schedule?: Scalars["Boolean"]["input"];
  /**
   * Seller org location can only be updated in DRAFT, AWAITING_APPROVAL and
   * REJECTED statuses.
   */
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  siteContact?: InputMaybe<SiteContactInput>;
  siteContactId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Skip vendor confirmation process and prevent the release from transitioning
   * from SCHEDULED back to REQUESTED.
   * Ineffective if the mutated release is in status other than SCHEDULED.
   */
  skipConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  skipVendorNotification?: Scalars["Boolean"]["input"];
  /**
   * Source warehouse can only be updated in DRAFT, AWAITING_APPROVAL and
   * REJECTED statuses.
   */
  sourceWarehouseID?: InputMaybe<Scalars["ID"]["input"]>;
  taxCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  taxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxType?: InputMaybe<TaxType>;
  taxVariance?: InputMaybe<Scalars["Decimal"]["input"]>;
  timeTBD?: InputMaybe<Scalars["Boolean"]["input"]>;
  typeId?: InputMaybe<Scalars["ID"]["input"]>;
  updates?: InputMaybe<Array<UpdateContractorReleaseItemInput>>;
  useSourceSystemPO?: InputMaybe<Scalars["Boolean"]["input"]>;
  vendorContactIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** required in SCHEDULED and REQUESTED */
  version?: InputMaybe<Scalars["Int"]["input"]>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  /** IDs of users to be added to the release watchers. */
  watcherIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateContractorReleaseItemInput = {
  /**
   * Update the referenced buyout item.
   *
   * Valid only when the buyout item comes from the same buyout and references
   * the same org material.
   */
  buyoutItemId?: InputMaybe<Scalars["ID"]["input"]>;
  /** Clear the cost code associated with this item. */
  clearCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Clear the cost type associated with this item. */
  clearCostType?: Scalars["Boolean"]["input"];
  clearDeliveryDate?: Scalars["Boolean"]["input"];
  clearManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Components:
   * 	Not available.
   */
  clearSellerOrgLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearSourceWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearUnitPrice?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  /** The cost type associated with this item. */
  costTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  deliveryDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  deliveryDateTBD?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * This will update the instructions.
   *
   * Components:
   * 	Not available.
   */
  instructions?: InputMaybe<InstructionInput>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  pricePrenegotiated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * This will update the requestedQuantity specified in the requestedUom.
   *
   * Parent item:
   * 	A 0.00 excludes the parent from the release.
   * Components:
   * 	A 0.00 excludes the component from the release.
   */
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  /**
   * In case of partial completion, this field will be set to the quantity
   * that was fulfilled.
   */
  receivedQuantity?: InputMaybe<Scalars["Float"]["input"]>;
  receivedQuantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  releaseItemId: Scalars["ID"]["input"];
  /**
   * Seller org location of the vendor chosen for fulfillment of this item.
   *
   * Components:
   * 	Not available.
   */
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  sourceWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Tags will have set semantics (if specified, all previously set tags are
   * erased and replaced with the new ones)
   *
   * Components:
   * 	Not available.
   */
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  /**
   * Use an existing UOM via it's plural / singular description or mnemonic. If
   * none is found, a new UOM will be created with this plural description.
   */
  uom?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * There is a single Zone field in the Release UI whenever Estimated Items are
   * added with Group By Zone = No. In such cases, Zone ID specified in the UI should be
   * propagated to every UpdateContractorReleaseItemInput in the release.
   *
   * Components:
   * 	Not available.
   */
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum UpdateContractorReleaseOperation {
  Approve = "APPROVE",
  Cancel = "CANCEL",
  Receive = "RECEIVE",
  /**
   * Create and process a delivery record with full coverage and transition the
   * release to RECEIVED. It will record an inventory transaction.
   */
  ReceiveFull = "RECEIVE_FULL",
  Reject = "REJECT",
  Reserve = "RESERVE",
  Schedule = "SCHEDULE",
  Submit = "SUBMIT",
}

export type UpdateContractorReleasesInput = {
  assignedGroupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  releaseIds: Array<Scalars["ID"]["input"]>;
  unassignedGroupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  useSourceSystemPO?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateCostCodeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateCostCodesInput = {
  addedCostCodes?: InputMaybe<Array<AddCostCodeInput>>;
  removedCostCodes?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updates?: InputMaybe<Array<UpdateCostCodeInput>>;
};

export type UpdateCostCodesWithFileInput = {
  custom?: InputMaybe<CustomIntegrationInput>;
  file: Scalars["Upload"]["input"];
  fileType: FileType;
};

export type UpdateCostTypeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateCostTypesInput = {
  addedCostTypes?: InputMaybe<Array<AddCostTypeInput>>;
  removedCostTypes?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updates?: InputMaybe<Array<UpdateCostTypeInput>>;
};

export type UpdateDeliveredReleaseItemInput = {
  id: Scalars["ID"]["input"];
  quantity?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UpdateDeliverySlipInput = {
  addedDeliveredReleaseItems?: InputMaybe<
    Array<CreateDeliveredReleaseItemInput>
  >;
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  async?: Scalars["Boolean"]["input"];
  clearAssets?: Scalars["Boolean"]["input"];
  clearFulfillmentDate?: Scalars["Boolean"]["input"];
  clearRelease?: Scalars["Boolean"]["input"];
  deliveryPhotoUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fulfillmentDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  id: Scalars["ID"]["input"];
  keepStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  receive?: Scalars["Boolean"]["input"];
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
  removedDeliveredReleaseItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updatedDeliveredReleaseItems?: InputMaybe<
    Array<UpdateDeliveredReleaseItemInput>
  >;
};

export type UpdateDisplaySettingsInput = {
  appendExternalCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearAppendExternalCode?: Scalars["Boolean"]["input"];
  clearDefaultItemized?: Scalars["Boolean"]["input"];
  clearReceiptDefaultItemized?: Scalars["Boolean"]["input"];
  costCodeIdentifier?: InputMaybe<CostCodeIdentifier>;
  currencyId?: InputMaybe<Scalars["ID"]["input"]>;
  defaultItemized?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableAdditionalCharges?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableManufacturers?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableTaxVariance?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemCostTypes?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiptDefaultItemized?: InputMaybe<Scalars["Boolean"]["input"]>;
  requireProjectAssignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesProjectCostCodes?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateDocumentReaderSettingsInput = {
  appendPartNumberToDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateEnterpriseInput = {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type UpdateEquipmentItemInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateEquipmentItemsInput = {
  added?: InputMaybe<Array<AddEquipmentItemInput>>;
  deleted?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updated?: InputMaybe<Array<UpdateEquipmentItemInput>>;
};

export type UpdateEstimatedItemInput = {
  clearCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearNotes?: Scalars["Boolean"]["input"];
  clearSellerOrgLocation?: Scalars["Boolean"]["input"];
  clearUnitPrice?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearZone?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  /**
   * Updating the UOM of an Estimated Item will get or create a Project Item with
   * the same material but with the new UOM, without affecting other existing
   * estimates.
   */
  uom?: InputMaybe<Scalars["String"]["input"]>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateEstimatedItemsInput = {
  addedItems?: InputMaybe<Array<AddEstimatedItemInput>>;
  projectId: Scalars["ID"]["input"];
  removedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updates: Array<UpdateEstimatedItemInput>;
};

export type UpdateEstimatesWithFileInput = {
  clearAllEstimatedItems: Scalars["Boolean"]["input"];
  /** Required input for a CUSTOM file format. */
  custom?: InputMaybe<CustomIntegrationInput>;
  file: Scalars["Upload"]["input"];
  fileFormat: IntegrationType;
  /**
   * This flag indicates whether manufacturers from line items should be imported
   * (created) if they do not exist.
   */
  importManufacturers: Scalars["Boolean"]["input"];
  /**
   * This flag indicates whether zones from material items in the file should be
   * imported as zones in the project if they did not exist before.
   *
   * By default, zones are not imported.
   */
  importZones: Scalars["Boolean"]["input"];
  projectId: Scalars["ID"]["input"];
  tags: Array<Scalars["ID"]["input"]>;
  /**
   * Zone ID to to specify the target zone to which all items should be imported.
   *
   * This is mutually exclusive with the importZones flag and when specified
   * the zone field within individual line item is ignored.
   */
  zoneId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateEstimatesWithFileOutput = {
  __typename?: "UpdateEstimatesWithFileOutput";
  /** A list of errors that occurred during the import. */
  errors: Array<ImportError>;
  /**
   * The updated list of estimated items.
   * @deprecated use project->aggregatedZones instead to reduce over-fetching
   */
  estimatedItems: Array<EstimatedItem>;
  /**
   * This field should be returned by the mutation and used by resolvers	of other
   * fields.
   */
  project: Project;
  /**
   * The updated list of project items.
   * @deprecated use project->aggregatedZones instead to reduce over-fetching
   */
  projectItems: Array<ProjectItem>;
};

export type UpdateFoundationSettingsInput = {
  GLExpense?: InputMaybe<Scalars["String"]["input"]>;
  clearDefaultCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  defaultPOFormat?: InputMaybe<PoFormat>;
  defaultToWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultWarehouseNumber?: InputMaybe<Scalars["String"]["input"]>;
  distributeAdditionalCharges?: InputMaybe<Scalars["Boolean"]["input"]>;
  postInventoryReceipts?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxAuthorities?: InputMaybe<Array<FoundationTaxAuthorityInput>>;
  warehouseGLExpense?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateIntegrationsSettingsInput = {
  accounting?: InputMaybe<UpdateAccountingSettingsInput>;
  foundation?: InputMaybe<UpdateFoundationSettingsInput>;
  sourceSystems?: InputMaybe<UpdateSourceSystemsSettingsInput>;
};

export type UpdateInventorySettingsInput = {
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateInvoiceApprovalSettingsInput = {
  /** This field uses set semantics. */
  roles?: InputMaybe<Array<InvoiceApprovalRoleInput>>;
};

export type UpdateInvoiceChargeInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateInvoiceEmailAttachmentInput = {
  id: Scalars["ID"]["input"];
  /** Setting this field to true will mark the attachment as rejected. */
  rejected?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateInvoiceFolderInput = {
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateInvoiceInput = {
  accountingDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  addedCharges?: InputMaybe<Array<CreateInvoiceChargeInput>>;
  addedInvoicedReleaseItems?: InputMaybe<Array<CreateInvoicedReleaseItemInput>>;
  addedItems?: InputMaybe<Array<CreateInvoiceItemInput>>;
  archiveClearedRelease?: Scalars["Boolean"]["input"];
  /** If specified then the invoice document will be replaced with the specified asset. */
  assetUrl?: InputMaybe<Scalars["String"]["input"]>;
  chargesAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  clearAccountingDate?: Scalars["Boolean"]["input"];
  clearCostCode?: Scalars["Boolean"]["input"];
  clearDiscountAmount?: Scalars["Boolean"]["input"];
  clearDiscountDate?: Scalars["Boolean"]["input"];
  clearEquipmentItem?: Scalars["Boolean"]["input"];
  clearPaymentMethod?: Scalars["Boolean"]["input"];
  clearProject?: Scalars["Boolean"]["input"];
  clearRelease?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearRetentionAmount?: Scalars["Boolean"]["input"];
  clearSellerOrgLocation?: Scalars["Boolean"]["input"];
  clearServiceCode?: Scalars["Boolean"]["input"];
  /** Works for receipts only. */
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  discountDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  discountPercentage?: InputMaybe<Scalars["Decimal"]["input"]>;
  dueDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  equipmentItemId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Assigns the invoice to the specified folder(s).
   *
   * This field uses set semantics.
   */
  folderIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  headerOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  issueDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  issueDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Specifying kickback description changes the invoice status to INTERNAL_KICKBACK. */
  kickbackDescription?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Specifying kickback resolution description changes the invoice status to AWAITING_APPROVAL.
   * Can be set only if the invoice is in INTERNAL_KICKBACK or AWAITING_APPROVAL status.
   */
  kickbackResolutionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * If specified then the invoice kickback will be assigned to the specified user.
   * If kickbackDescription is specified then it is mandatory to specify kickbackReportToUserIds.
   */
  kickbackToUserIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  /** Specifying the updated page range re-slices the document. */
  pageRange?: InputMaybe<PageRangeInput>;
  /**
   * When set to true, transitions the invoice to PAID status.
   * When set to false, transitions to invoice to APPROVED, AWAITING_APPROVAL,
   * ISSUES_REPORTED or INTERNAL_KICKBACK status.
   */
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Assign the payment method */
  paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
  poNumber?: InputMaybe<Scalars["String"]["input"]>;
  prePaid?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  releaseId?: InputMaybe<Scalars["ID"]["input"]>;
  removedCharges?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  removedInvoicedReleaseItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  removedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  retentionAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  retentionPercentage?: InputMaybe<Scalars["Decimal"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  serviceCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  skipAutoMatching?: Scalars["Boolean"]["input"];
  subtotal?: InputMaybe<Scalars["Decimal"]["input"]>;
  taxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  updatedCharges?: InputMaybe<Array<UpdateInvoiceChargeInput>>;
  updatedInvoicedReleaseItems?: InputMaybe<
    Array<UpdateInvoicedReleaseItemInput>
  >;
  updatedItems?: InputMaybe<Array<UpdateInvoiceItemInput>>;
};

export type UpdateInvoiceIssueInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateInvoiceItemInput = {
  UOM?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  partNumber?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UpdateInvoiceKickbackCommentInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clearText?: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateInvoiceKickbackInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  assigneeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  clearDescription?: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateInvoiceSettingsInput = {
  approval?: InputMaybe<UpdateInvoiceApprovalSettingsInput>;
  clearDefaultPaymentTerm?: Scalars["Boolean"]["input"];
  defaultPaymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  localPart?: InputMaybe<Scalars["String"]["input"]>;
  requireCostCodesDuringApproval?: InputMaybe<Scalars["Boolean"]["input"]>;
  requireValidationDuringApproval?: InputMaybe<Scalars["Boolean"]["input"]>;
  vendorTermsOverrideDueDate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateInvoicedReleaseItemInput = {
  /** Setting this field to true will clear the unit price. This happens when the Specify Invoice Price toggle is set to false. */
  clearUnitPrice?: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  quantity?: InputMaybe<Scalars["Decimal"]["input"]>;
  /**
   * Unit price of the invoiced release item. If not set, the one from the release item is used in computing the totals.
   * Sometimes, copy prices from invoice are not desired or do not work well so allowing the user
   * to manually set unit price and discarding the unit price from the invoice is a good option.
   */
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UpdateInvoicesInput = {
  assignedFolderIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  invoiceIds: Array<Scalars["ID"]["input"]>;
  unassignedFolderIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type UpdateNotificationSettingsInput = {
  excludedBuyoutEvents?: InputMaybe<Array<BuyoutEvent>>;
  excludedInvoiceEvents?: InputMaybe<Array<InvoiceEvent>>;
  excludedMessageContexts?: InputMaybe<Array<MessageContextKind>>;
  excludedQuoteEvents?: InputMaybe<Array<QuoteEvent>>;
  excludedReleaseEvents?: InputMaybe<Array<ReleaseEvent>>;
};

export type UpdateOrgCatalogSkuInput = {
  clearDefaultManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  customPartNumber?: InputMaybe<Scalars["String"]["input"]>;
  defaultManufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  defaultUom?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  materialCostCode?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  sectionCode?: InputMaybe<Scalars["String"]["input"]>;
  sectionName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateOrgInput = {
  ein?: InputMaybe<Scalars["String"]["input"]>;
  hqAddress?: InputMaybe<AddressInput>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["Upload"]["input"]>;
  settings?: InputMaybe<UpdateOrgSettingsInput>;
};

export type UpdateOrgLocationInput = {
  address: AddressInput;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type UpdateOrgMaterialInput = {
  clearCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearExternalCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  externalCode?: InputMaybe<Scalars["String"]["input"]>;
  orgCatalogSku?: InputMaybe<UpdateOrgCatalogSkuInput>;
  orgMaterialId: Scalars["ID"]["input"];
  position?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateOrgMaterialsInput = {
  addedMaterials?: InputMaybe<Array<CreateOrgMaterialInput>>;
  removedMaterials?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updates?: InputMaybe<Array<UpdateOrgMaterialInput>>;
};

export type UpdateOrgPreferredVendorInput = {
  clearCostCode?: Scalars["Boolean"]["input"];
  clearDefaultPaymentTerm?: Scalars["Boolean"]["input"];
  clearDefaultReleaseType?: Scalars["Boolean"]["input"];
  contacts?: InputMaybe<Array<VendorContactInput>>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  defaultPaymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  defaultReleaseType?: InputMaybe<Scalars["ID"]["input"]>;
  externalCode?: InputMaybe<Scalars["String"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxExempt?: InputMaybe<Scalars["Boolean"]["input"]>;
  vendorID: Scalars["ID"]["input"];
};

export type UpdateOrgSettingsInput = {
  display?: InputMaybe<UpdateDisplaySettingsInput>;
  documentReader?: InputMaybe<UpdateDocumentReaderSettingsInput>;
  /** id is not required when this input is nested inside UpdateOrgInput. */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  integrations?: InputMaybe<UpdateIntegrationsSettingsInput>;
  inventory?: InputMaybe<UpdateInventorySettingsInput>;
  invoices?: InputMaybe<UpdateInvoiceSettingsInput>;
  notifications?: InputMaybe<UpdateNotificationSettingsInput>;
  project?: InputMaybe<UpdateProjectSettingsInput>;
  releaseApproval?: InputMaybe<UpdateReleaseApprovalSettingsInput>;
  releases?: InputMaybe<UpdateReleaseSettingsInput>;
};

export type UpdatePoLinkInput = {
  autoSync?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * ID of the warehouse to which PO items should be distributed. Only
   * applicable to DETAIL PO format exports.
   */
  externalWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  poLinkID: Scalars["ID"]["input"];
};

export type UpdatePaymentMethodInput = {
  default?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  number?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<PaymentMethodType>;
};

export type UpdatePaymentMethodsInput = {
  added?: InputMaybe<Array<AddPaymentMethodInput>>;
  deleted?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updated?: InputMaybe<Array<UpdatePaymentMethodInput>>;
};

export type UpdateProfileInput = {
  cellPhone?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  excludedBuyoutEvents?: InputMaybe<Array<BuyoutEvent>>;
  excludedInvoiceEvents?: InputMaybe<Array<InvoiceEvent>>;
  excludedMessageContexts?: InputMaybe<Array<MessageContextKind>>;
  excludedQuoteEvents?: InputMaybe<Array<QuoteEvent>>;
  excludedReleaseEvents?: InputMaybe<Array<ReleaseEvent>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  getEmailNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  getSmsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["Upload"]["input"]>;
  preferences?: InputMaybe<Array<UserPreferenceInput>>;
};

export type UpdateProjectInput = {
  address?: InputMaybe<AddressInput>;
  budget?: InputMaybe<Scalars["Decimal"]["input"]>;
  budgetAllowance?: InputMaybe<SetProjectAllowanceInput>;
  clearStartDate?: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  jobNumber?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poJobNumber?: InputMaybe<Scalars["String"]["input"]>;
  preferredInventoryInflows?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseInstructions?: InputMaybe<InstructionInput>;
  restrictCostCodes?: InputMaybe<Scalars["Boolean"]["input"]>;
  siteContact?: InputMaybe<SiteContactInput>;
  startDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  status?: InputMaybe<ProjectStatus>;
  taxExempt?: InputMaybe<Scalars["Boolean"]["input"]>;
  team?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  usesProjectCostCodes?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateProjectItemInput = {
  clearManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  projectItemId: Scalars["ID"]["input"];
};

export type UpdateProjectItemsInput = {
  projectId: Scalars["ID"]["input"];
  updates: Array<UpdateProjectItemInput>;
};

export type UpdateProjectSettingsInput = {
  defaultTeam?: InputMaybe<Array<OrgLocationDefaultProjectTeamInput>>;
};

export type UpdateQuoteInput = {
  /** Create auxiliary items in the quote. */
  addedAuxiliaryItems?: InputMaybe<Array<AddAuxiliaryQuoteItemInput>>;
  /**
   * Add components to quote items or to auxiliary quote items. Must have either
   * a quote item ID or an auxiliary quote item ID.
   */
  addedComponents?: InputMaybe<Array<AddQuoteItemComponentInput>>;
  /** Create additional quote items for RFQ items, acting as options. */
  addedItems?: InputMaybe<Array<AddQuoteItemInput>>;
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Delete auxiliary items. */
  deletedAuxiliaryItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Delete components of quote items or of auxiliary quote items. */
  deletedComponents?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Delete quote items.
   *
   * Deleting all quote items is prohibited.
   */
  deletedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  discount?: InputMaybe<DiscountInput>;
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentTermDays?: InputMaybe<Scalars["Int"]["input"]>;
  quoteId: Scalars["ID"]["input"];
  /** Update auxiliary items. */
  updatedAuxiliaryItems?: InputMaybe<Array<UpdateAuxiliaryQuoteItemInput>>;
  /** Update components of quote items or of auxiliary quote items. */
  updatedComponents?: InputMaybe<Array<UpdateQuoteItemComponentInput>>;
  /** Update quote items. */
  updates?: InputMaybe<Array<UpdateQuoteItemInput>>;
};

export type UpdateQuoteItemComponentInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerID?: InputMaybe<Scalars["ID"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  quoteItemComponentID?: InputMaybe<Scalars["ID"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateQuoteItemInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clearUnitPrice?: InputMaybe<Scalars["Boolean"]["input"]>;
  customSku?: InputMaybe<CustomSkuInput>;
  /**
   * Update the description of this quote item.
   *
   * Quote items must have unique descriptions if they reference the same RFQ
   * item.
   */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Setting expirationDays to 1-1-1 00:00:00 UTC (January 1, year 1, 00:00:00 UTC)
   * resets the expiration date to the quote's, if any.
   */
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /**
   * Updates to this flag propagate to all quote items that reference the same
   * RFQ item.
   */
  isIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Setting lead time to 0 clears the value. */
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  masterSkuId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  orgCatalogSkuId?: InputMaybe<Scalars["ID"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  quoteItemId: Scalars["ID"]["input"];
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UpdateQuoteTermsInput = {
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  discount?: InputMaybe<DiscountInput>;
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentTermDays: Scalars["Int"]["input"];
  quoteId: Scalars["ID"]["input"];
};

export type UpdateReceiptKickbackInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  assigneeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  clearDescription?: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateReleaseApprovalSettingsInput = {
  approverRoles?: InputMaybe<Array<OrgLocationRole>>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  exemptRoles?: InputMaybe<Array<OrgLocationRole>>;
  requiredApprovals?: InputMaybe<Scalars["Int"]["input"]>;
  requiredApproverRoles?: InputMaybe<Array<OrgLocationRole>>;
  reserved?: InputMaybe<Scalars["Boolean"]["input"]>;
  sourceWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  standaloneOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  submitterRoles?: InputMaybe<Array<OrgLocationRole>>;
};

export type UpdateReleaseGroupInput = {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type UpdateReleaseSettingsInput = {
  clearDefaultWarehouse?: Scalars["Boolean"]["input"];
  defaultWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  instructions?: InputMaybe<InstructionInput>;
  notifications?: InputMaybe<ReleaseNotificationsMode>;
  poNumbering?: InputMaybe<PoNumberingMode>;
  useSourceSystemPOs?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateReleaseTypeInput = {
  additionalChargesCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  additionalChargesCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  billable?: InputMaybe<Scalars["Boolean"]["input"]>;
  chargesMode?: InputMaybe<ChargesMode>;
  clearAdditionalChargesCostCode?: Scalars["Boolean"]["input"];
  clearAdditionalChargesCostType?: Scalars["Boolean"]["input"];
  clearCostType?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearDefaultOrgMaterial?: Scalars["Boolean"]["input"];
  clearLedgerAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearLedgerSubaccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearPoNumbering?: Scalars["Boolean"]["input"];
  clearPoType?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearSalesTaxCostCode?: Scalars["Boolean"]["input"];
  clearSalesTaxCostType?: Scalars["Boolean"]["input"];
  clearSalesTaxOrgMaterial?: Scalars["Boolean"]["input"];
  costTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  default?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultAutoSync?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultOrgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  enableUpcomingNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  includeAdditionalCharges?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeTaxInInvoiceSubtotal?: InputMaybe<Scalars["Boolean"]["input"]>;
  ledgerAccount?: InputMaybe<MapNodeInput>;
  ledgerSubaccount?: InputMaybe<MapNodeInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nonJobPO?: InputMaybe<Scalars["Boolean"]["input"]>;
  poFormat?: InputMaybe<PoFormat>;
  poNumbering?: InputMaybe<PoNumberingMode>;
  poType?: InputMaybe<MapNodeInput>;
  requireDeliverySlip?: InputMaybe<Scalars["Boolean"]["input"]>;
  salesTaxCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  salesTaxCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  salesTaxOrgMaterialId?: InputMaybe<Scalars["ID"]["input"]>;
  taxMode?: InputMaybe<TaxMode>;
  trackInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
  transactionKind?: InputMaybe<TransactionKind>;
};

export type UpdateRfqInput = {
  addedItems?: InputMaybe<Array<AddToRfqItemInput>>;
  assignDefaultCostCodes?: Scalars["Boolean"]["input"];
  clearNoteDocument?: Scalars["Boolean"]["input"];
  clientCustomNumber?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  includeServices?: InputMaybe<Array<UpdateServiceInput>>;
  instructions?: InputMaybe<InstructionInput>;
  noteDocumentId?: InputMaybe<Scalars["ID"]["input"]>;
  removedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  rfqId: Scalars["ID"]["input"];
  targets?: InputMaybe<Array<RfqPreferredVendorInput>>;
  updates?: InputMaybe<Array<UpdateRfqItemInput>>;
  vendorLocationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type UpdateRfqItemInput = {
  clearCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  costCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  instructions?: InputMaybe<InstructionInput>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  rfqItemId: Scalars["ID"]["input"];
  supportInstructions?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Updating the UOM of an RFQ Item will get or create a Project Item with the
   * same material but with the new UOM, without affecting other existing RFQ
   * Items.
   */
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateServiceCodeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type UpdateServiceCodesInput = {
  added?: InputMaybe<Array<AddServiceCodeInput>>;
  deleted?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updated?: InputMaybe<Array<UpdateServiceCodeInput>>;
};

export type UpdateServiceInput = {
  /**
   * If true, the service is removed from the required services list,
   * otherwise it is added.
   */
  remove?: InputMaybe<Scalars["Boolean"]["input"]>;
  type: ServiceType;
};

export type UpdateServicePriceInput = {
  offHoursAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  offHoursUom?: InputMaybe<Scalars["String"]["input"]>;
  regularHoursAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  regularHoursUom?: InputMaybe<Scalars["String"]["input"]>;
  servicePriceID: Scalars["ID"]["input"];
};

export type UpdateServicePriceSheetInput = {
  discount?: InputMaybe<DiscountInput>;
  quoteID: Scalars["ID"]["input"];
  updates?: InputMaybe<Array<UpdateServicePriceInput>>;
};

export type UpdateSiteContactInput = {
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateSourceSystemsSettingsInput = {
  accountsPayableLedgerAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  additionalChargesCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  additionalChargesCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  autoCloseProjectsByJobStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoExportApprovedInvoices?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoPostInvoices?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoPostPOs?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearAccountsPayableLedgerAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearAdditionalChargesCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearAdditionalChargesCostType?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearInventoryReceiptLedgerAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearMaterialCostType?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearPoType?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearSalesTaxCostCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearSalesTaxCostType?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearToJobLedgerAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearToJobLedgerSubaccountId?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearToWarehouseLedgerAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  customInvoiceTemplates?: InputMaybe<Array<CustomTemplateInput>>;
  customPOTemplates?: InputMaybe<Array<CustomTemplateInput>>;
  defaultAutoSync?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultPOFormat?: InputMaybe<PoFormat>;
  defaultToWarehouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  distributeAdditionalCharges?: InputMaybe<Scalars["Boolean"]["input"]>;
  distributeSalesTax?: InputMaybe<Scalars["Boolean"]["input"]>;
  fiscalYearStartMonth?: InputMaybe<Scalars["Int"]["input"]>;
  ignorePOTax?: InputMaybe<Scalars["Boolean"]["input"]>;
  inventoryReceiptLedgerAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceDescriptionTemplate?: InputMaybe<Scalars["String"]["input"]>;
  materialCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  poDescriptionTemplate?: InputMaybe<Scalars["String"]["input"]>;
  poLineItemsIncludeLedgerAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  poNameTemplate?: InputMaybe<Scalars["String"]["input"]>;
  poStrictAmounts?: InputMaybe<Scalars["Boolean"]["input"]>;
  poTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  postInventoryReceipts?: InputMaybe<Scalars["Boolean"]["input"]>;
  salesTaxCostCodeId?: InputMaybe<Scalars["ID"]["input"]>;
  salesTaxCostTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  system: SourceSystem;
  /** All of the following fields use external IDs (Agave IDs). */
  toJobLedgerAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  toJobLedgerSubaccountId?: InputMaybe<Scalars["String"]["input"]>;
  toWarehouseLedgerAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  wbsTags?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateTagInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  /** Must be unique within the project. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateTagsInput = {
  addedTags?: InputMaybe<Array<CreateTagInput>>;
  projectId: Scalars["ID"]["input"];
  removedTags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updates?: InputMaybe<Array<UpdateTagInput>>;
};

export type UpdateTaxCodeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  rate?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UpdateTaxCodesInput = {
  added?: InputMaybe<Array<AddTaxCodeInput>>;
  archived?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  unarchived?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  updated?: InputMaybe<Array<UpdateTaxCodeInput>>;
};

export type UpdateUomInput = {
  clearMnemonic?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearSingularDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The duration of this UOM, ex "3 days". */
  duration?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  mnemonic?: InputMaybe<Scalars["String"]["input"]>;
  pluralDescription?: InputMaybe<Scalars["String"]["input"]>;
  singularDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserInput = {
  cellPhone?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enterpriseRoles?: InputMaybe<Array<EnterpriseRole>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  locationRoles?: InputMaybe<Array<LocationRoleInput>>;
  orgRoles?: InputMaybe<Array<OrgRole>>;
  systemRoles?: InputMaybe<Array<SystemRole>>;
};

export type UpdateVendorBuyoutInput = {
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  /** asset urls when vendor or contactor uploads buyout assets (photos, videos, documents) */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  buyoutId: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentTermDays?: InputMaybe<Scalars["Int"]["input"]>;
  updates?: InputMaybe<Array<UpdateVendorBuyoutItemInput>>;
  version: Scalars["Int"]["input"];
};

export type UpdateVendorBuyoutItemInput = {
  /** asset urls when vendor or contactor uploads buyout item assets (photos, videos, documents) */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  buyoutItemId: Scalars["ID"]["input"];
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  isIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Updating the manufacturer ID might have the side effect of updating the
   * underlying material as well, as follows:
   *
   * - if the material is a master product and there is an available SKU with a
   *   matching product and manufacturer then the material will be changed to
   *   that SKU
   * - if the material is a SKU and there is no matching SKU with the same base
   *   master product but with this new manufacturer then the material will be
   *   switched to that base master product
   */
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UpdateVendorOrgInput = {
  clearPhotoUrl?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  photoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateVendorPriceInput = {
  clearExpirationDate?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearManufacturer?: InputMaybe<Scalars["Boolean"]["input"]>;
  expirationDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  leadTime?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * Manufacturer updates are only allowed for org materials, underlying material
   * type of which is not a master SKU.
   */
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  minimumOrder?: InputMaybe<Scalars["Float"]["input"]>;
  orderIncrement?: InputMaybe<Scalars["Float"]["input"]>;
  price?: InputMaybe<Scalars["Decimal"]["input"]>;
  sellerOrgLocationId?: InputMaybe<Scalars["ID"]["input"]>;
  uom?: InputMaybe<Scalars["String"]["input"]>;
  vendorPriceId: Scalars["ID"]["input"];
};

export type UpdateVendorReleaseInput = {
  addedItems?: InputMaybe<Array<AddVendorReleaseItemInput>>;
  additionalCharges?: InputMaybe<Array<UpdateChargeInput>>;
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clearCustomTaxAmount?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * When set to true, the release will transition to SCHEDULED status.
   * Ineffectual if the release status is other than REQUESTED.
   */
  confirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  customTaxAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentTerm?: InputMaybe<Scalars["Int"]["input"]>;
  releaseId: Scalars["ID"]["input"];
  removedItems?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  taxRate?: InputMaybe<Scalars["Decimal"]["input"]>;
  time?: InputMaybe<Scalars["Timestamp"]["input"]>;
  timeTBD?: InputMaybe<Scalars["Boolean"]["input"]>;
  updates?: InputMaybe<Array<UpdateVendorReleaseItemInput>>;
  version: Scalars["Int"]["input"];
};

export type UpdateVendorReleaseItemInput = {
  /**
   * Must be URLs returned by the uploadAsset mutation.
   * Has set semantics (if specified, all previously set asset URLs are
   * erased and replaced with the new ones).
   *
   * Components:
   * 	Not available.
   */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clearDeliveryDate?: Scalars["Boolean"]["input"];
  deliveryDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  deliveryDateTBD?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Only available on parent items. Components are also excluded. */
  isIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Components:
   * 	Not available.
   */
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** This will update the quantity (actual fulfillment quantity). */
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  releaseItemId: Scalars["ID"]["input"];
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Components:
   * 	Placeholder parent and siblings (items with 0.00 requestedQuantity) are
   * 	also created in the destination release.
   */
  time?: InputMaybe<AlternativeFulfillmentInput>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  /**
   * Use an existing UOM via it's plural / singular description or mnemonic. If
   * none is found, an error will be returned.
   */
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateWarehouseInput = {
  address?: InputMaybe<AddressInput>;
  externalCode?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  managers?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  trackInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateWarehouseReleaseInput = {
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * When set to true, the release will transition to SCHEDULED status.
   * Ineffectual if the release status is other than REQUESTED.
   */
  confirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  releaseId: Scalars["ID"]["input"];
  time?: InputMaybe<Scalars["Timestamp"]["input"]>;
  updates?: InputMaybe<Array<UpdateWarehouseReleaseItemInput>>;
  version: Scalars["Int"]["input"];
};

export type UpdateWarehouseReleaseItemInput = {
  /**
   * Must be URLs returned by the uploadAsset mutation.
   * Has set semantics (if specified, all previously set asset URLs are
   * erased and replaced with the new ones).
   *
   * Components:
   * Not available.
   */
  assetUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Only available on parent items. Components are also excluded. */
  isIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Components:
   * Not available.
   */
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** This will update the quantity (actual fulfillment quantity). */
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantityDecimal?: InputMaybe<Scalars["Decimal"]["input"]>;
  releaseItemId: Scalars["ID"]["input"];
  /**
   * Components:
   * Placeholder parent and siblings (items with 0.00 requestedQuantity) are
   * also created in the destination release.
   */
  time?: InputMaybe<AlternativeFulfillmentInput>;
  unitPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  /**
   * Use an existing UOM via it's plural / singular description or mnemonic. If
   * none is found, an error will be returned.
   */
  uom?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateZoneInput = {
  id: Scalars["ID"]["input"];
  /** Must be unique within the project and have length between 1 and 64 characters. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UploadAssetInput = {
  context: AssetContext;
  file: Scalars["Upload"]["input"];
  isPrivate?: Scalars["Boolean"]["input"];
  projectId?: InputMaybe<Scalars["String"]["input"]>;
  type: AssetType;
};

export type User = Node & {
  __typename?: "User";
  authId: Scalars["String"]["output"];
  cellPhone: Scalars["String"]["output"];
  createdAt: Scalars["Timestamp"]["output"];
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  devices: Array<Device>;
  email: Scalars["String"]["output"];
  enterprise?: Maybe<Enterprise>;
  enterpriseRoles: Array<EnterpriseRole>;
  excludedBuyoutEvents: Array<BuyoutEvent>;
  excludedInvoiceEvents: Array<InvoiceEvent>;
  excludedMessageContexts: Array<MessageContextKind>;
  excludedQuoteEvents: Array<QuoteEvent>;
  excludedReleaseEvents: Array<ReleaseEvent>;
  firstName: Scalars["String"]["output"];
  getEmailNotifications: Scalars["Boolean"]["output"];
  getSmsNotifications: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  isEmailValidated: Scalars["Boolean"]["output"];
  /** Only accessible to org admins and enterprise admins. */
  isEnterpriseAdmin: Scalars["Boolean"]["output"];
  /**
   * This flag reports whether the user is logged into an organization that they
   * are not a member of.
   */
  isExternal: Scalars["Boolean"]["output"];
  isOrgAdmin?: Maybe<Scalars["Boolean"]["output"]>;
  isPhoneValidated: Scalars["Boolean"]["output"];
  lastLogin: Scalars["Timestamp"]["output"];
  lastName: Scalars["String"]["output"];
  locationRoles: Array<LocationRole>;
  org: Organization;
  orgRoles: Array<OrgRole>;
  /**
   * Returns all organizations within the enterprise to which the user is assigned.
   *
   * Only accessible to org and enterprise admins.
   */
  orgs: Array<Organization>;
  photoUrl?: Maybe<Scalars["String"]["output"]>;
  preferences: Array<UserPreference>;
  sessionId?: Maybe<Scalars["ID"]["output"]>;
  systemRoles: Array<SystemRole>;
};

export type UserPreferencesArgs = {
  keys?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UserPreference = Node & {
  __typename?: "UserPreference";
  createdAt: Scalars["Timestamp"]["output"];
  id: Scalars["ID"]["output"];
  key: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  value: Scalars["String"]["output"];
};

export type UserPreferenceInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type VendorContact = Node & {
  __typename?: "VendorContact";
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  cellPhone: Scalars["String"]["output"];
  cellPhoneExtension?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  receivesBuyoutNotifications: Scalars["Boolean"]["output"];
  receivesInvoiceNotifications: Scalars["Boolean"]["output"];
  receivesOrderNotifications: Scalars["Boolean"]["output"];
  receivesQuoteNotifications: Scalars["Boolean"]["output"];
};

export type VendorContactInput = {
  cellPhone: Scalars["String"]["input"];
  cellPhoneExtension?: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  receivesBuyoutNotifications: Scalars["Boolean"]["input"];
  receivesInvoiceNotifications: Scalars["Boolean"]["input"];
  receivesOrderNotifications: Scalars["Boolean"]["input"];
  receivesQuoteNotifications: Scalars["Boolean"]["input"];
};

export type VendorPrice = Node & {
  __typename?: "VendorPrice";
  createdAt: Scalars["Timestamp"]["output"];
  expirationDate?: Maybe<Scalars["Timestamp"]["output"]>;
  id: Scalars["ID"]["output"];
  leadTime?: Maybe<Scalars["Int"]["output"]>;
  manufacturer?: Maybe<Manufacturer>;
  minimumOrder?: Maybe<Scalars["Float"]["output"]>;
  orderIncrement?: Maybe<Scalars["Float"]["output"]>;
  orgMaterialId: Scalars["ID"]["output"];
  price: Scalars["Decimal"]["output"];
  sellerOrgLocation: OrgLocation;
  uom: Uom;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  vendor: OrgPreferredVendor;
};

export enum VendorPricesCondition {
  /**
   * Returns OrgMaterials, for which there is at least one VendorPrice with
   * expiration date in the past.
   */
  Expired = "EXPIRED",
  /** Returns OrgMaterials, for which there are no VendorPrices. */
  WithoutPrices = "WITHOUT_PRICES",
  /** Returns OrgMaterials, for which there is at least one VendorPrice. */
  WithPrices = "WITH_PRICES",
}

export enum VoucherType {
  Prepaid = "PREPAID",
  Regular = "REGULAR",
}

export type Warehouse = Node & {
  __typename?: "Warehouse";
  address: Address;
  archivedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  externalCode?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  inventoryItemCount: Scalars["Int"]["output"];
  inventoryValue: Scalars["Decimal"]["output"];
  managers: Array<User>;
  name: Scalars["String"]["output"];
  /** @deprecated not used anymore */
  trackInventory: Scalars["Boolean"]["output"];
};

export type WithdrawQuoteInput = {
  quoteId: Scalars["ID"]["input"];
};

export type Zone = Node & {
  __typename?: "Zone";
  externalSubJob?: Maybe<ExternalSubJob>;
  id: Scalars["ID"]["output"];
  inUse: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  project: Project;
};

export type CurrencyFieldsFragment = {
  __typename?: "Currency";
  id: string;
  code: string;
  name: string;
  symbol: string;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = {
  __typename?: "Query";
  currencies: Array<{
    __typename?: "Currency";
    id: string;
    code: string;
    name: string;
    symbol: string;
  }>;
};

export type OrgNotificationCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrgNotificationCountQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      enterprise?: {
        __typename?: "Enterprise";
        id: string;
        orgs: Array<{
          __typename?: "Organization";
          id: string;
          notificationCount: number;
        }>;
      } | null;
    };
  } | null;
};

export type UpdateInvoiceOptionsMutationVariables = Exact<{
  input: UpdateInvoiceInput;
}>;

export type UpdateInvoiceOptionsMutation = {
  __typename?: "Mutation";
  updateInvoice: {
    __typename?: "Invoice";
    id: string;
    retentionPercentage?: string | null;
    retentionAmount?: string | null;
    accountingDate?: number | null;
    discountPercentage?: string | null;
    discountAmount?: string | null;
    discountDate?: number | null;
    dueDate?: number | null;
    paymentMethod?: { __typename?: "PaymentMethod"; id: string } | null;
  };
};

export type MarkMessageThreadAsReadMutationVariables = Exact<{
  input: MarkMessageThreadAsReadInput;
}>;

export type MarkMessageThreadAsReadMutation = {
  __typename?: "Mutation";
  markMessageThreadAsRead: Array<{ __typename?: "Message"; id: string }>;
};

export type MessageStatsQueryVariables = Exact<{
  input: MessageStatsInput;
}>;

export type MessageStatsQuery = {
  __typename?: "Query";
  messageStats: {
    __typename?: "MessageStats";
    unreadPrivate: number;
    unreadPublic: number;
  };
};

export type ExternalNotificationFieldsFragment = {
  __typename?: "ExternalNotification";
  id: string;
  channel: ExternalNotificationChannel;
  subject: string;
  recipient: string;
  createdAt: number;
  release?: {
    __typename?: "Release";
    id: string;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
        receivesInvoiceNotifications: boolean;
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
      }>;
    } | null;
  } | null;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
        receivesInvoiceNotifications: boolean;
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
      }>;
    } | null;
  } | null;
  quote?: {
    __typename?: "Quote";
    id: string;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
        receivesInvoiceNotifications: boolean;
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
      }>;
    } | null;
  } | null;
  invoice?: {
    __typename?: "Invoice";
    id: string;
    release?: {
      __typename?: "Release";
      id: string;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
    } | null;
  } | null;
};

export type NotificationBuyoutChangeContextFieldsFragment = {
  __typename?: "NotificationContextBuyoutStatusChange";
  id: string;
  type: NotificationType;
  newBuyoutStatus: BuyoutStatus;
  oldBuyoutStatus: BuyoutStatus;
  buyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    project: { __typename?: "Project"; id: string; name: string };
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    } | null;
  };
  initiatedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type NotificationFieldsFragment = {
  __typename?: "Notification";
  id: string;
  createdAt: number;
  readAt?: number | null;
  reminder: boolean;
  context:
    | {
        __typename?: "NotificationContextBuyoutStatusChange";
        type: NotificationType;
        id: string;
        newBuyoutStatus: BuyoutStatus;
        oldBuyoutStatus: BuyoutStatus;
        buyout: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
          project: { __typename?: "Project"; id: string; name: string };
          createdBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
            }>;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              name: string;
              org: { __typename?: "Organization"; id: string; name: string };
            };
          } | null;
        };
        initiatedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      }
    | {
        __typename?: "NotificationContextInvoiceStatusChange";
        type: NotificationType;
        id: string;
        newInvoiceStatus: InvoiceStatus;
        oldInvoiceStatus: InvoiceStatus;
        invoiceNotificationEvent: InvoiceEvent;
        invoiceNotificationInitiatedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        invoice: {
          __typename?: "Invoice";
          id: string;
          number?: string | null;
          type: InvoiceType;
          approvedBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          } | null;
          release?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
            preferredVendor?: {
              __typename?: "OrgPreferredVendor";
              id: string;
              sellerOrgLocation: {
                __typename?: "OrgLocation";
                id: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              };
            } | null;
          } | null;
          project?: {
            __typename?: "Project";
            id: string;
            name: string;
            location: {
              __typename?: "OrgLocation";
              id: string;
              org: { __typename?: "Organization"; id: string; name: string };
            };
          } | null;
        };
      }
    | {
        __typename?: "NotificationContextMessage";
        type: NotificationType;
        id: string;
        message: {
          __typename?: "Message";
          id: string;
          message: string;
          timeSent: number;
          quote?: {
            __typename?: "Quote";
            id: string;
            rfq: {
              __typename?: "Rfq";
              id: string;
              clientIdentifier: string;
              project: { __typename?: "Project"; id: string; name: string };
            };
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: { __typename?: "Organization"; id: string; name: string };
            };
          } | null;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: { __typename?: "Organization"; id: string; name: string };
            };
            quote?: { __typename?: "Quote"; id: string } | null;
            project: { __typename?: "Project"; id: string; name: string };
          } | null;
          release?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
            buyout?: {
              __typename?: "Buyout";
              id: string;
              clientIdentifier: string;
              quote?: { __typename?: "Quote"; id: string } | null;
            } | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              org: { __typename?: "Organization"; id: string; name: string };
            } | null;
            project?: {
              __typename?: "Project";
              id: string;
              name: string;
            } | null;
          } | null;
          author: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        };
      }
    | {
        __typename?: "NotificationContextQuoteStatusChange";
        type: NotificationType;
        id: string;
        quoteStatus: QuoteStatus;
        quote: {
          __typename?: "Quote";
          id: string;
          status: QuoteStatus;
          rfq: {
            __typename?: "Rfq";
            id: string;
            clientIdentifier: string;
            project: { __typename?: "Project"; id: string; name: string };
          };
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            org: { __typename?: "Organization"; id: string; name: string };
          };
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
            }>;
          } | null;
        };
        rfqOwner: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: { __typename?: "Organization"; id: string; name: string };
        };
        initiatedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      }
    | {
        __typename?: "NotificationContextReleaseStatusChange";
        type: NotificationType;
        id: string;
        newStatus: ReleaseStatus;
        oldStatus: ReleaseStatus;
        event: ReleaseEvent;
        release: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          itemCount: number;
          status: ReleaseStatus;
          time?: number | null;
          noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
          quoteDocument?: { __typename?: "QuoteDocument"; id: string } | null;
          project?: { __typename?: "Project"; id: string; name: string } | null;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            createdBy: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
            };
          } | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            org: { __typename?: "Organization"; id: string; name: string };
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
            }>;
          } | null;
          submittedBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          } | null;
          approvals: Array<{
            __typename?: "ReleaseApproval";
            id: string;
            roles: Array<OrgLocationRole>;
            createdAt: number;
            approver: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
            };
          }>;
          rejectedBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          } | null;
          warehouse?: {
            __typename?: "Warehouse";
            id: string;
            name: string;
          } | null;
        };
        initiatedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      };
};

export type NotificationInvoiceChangeContextFieldsFragment = {
  __typename?: "NotificationContextInvoiceStatusChange";
  id: string;
  type: NotificationType;
  newInvoiceStatus: InvoiceStatus;
  oldInvoiceStatus: InvoiceStatus;
  invoiceNotificationEvent: InvoiceEvent;
  invoiceNotificationInitiatedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  invoice: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    type: InvoiceType;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        };
      } | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      location: {
        __typename?: "OrgLocation";
        id: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    } | null;
  };
};

export type NotificationMessageContextFieldsFragment = {
  __typename?: "NotificationContextMessage";
  id: string;
  type: NotificationType;
  message: {
    __typename?: "Message";
    id: string;
    message: string;
    timeSent: number;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        project: { __typename?: "Project"; id: string; name: string };
      };
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
      quote?: { __typename?: "Quote"; id: string } | null;
      project: { __typename?: "Project"; id: string; name: string };
    } | null;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        quote?: { __typename?: "Quote"; id: string } | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      project?: { __typename?: "Project"; id: string; name: string } | null;
    } | null;
    author: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
  };
};

export type NotificationQuoteChangeContextFieldsFragment = {
  __typename?: "NotificationContextQuoteStatusChange";
  id: string;
  type: NotificationType;
  quoteStatus: QuoteStatus;
  quote: {
    __typename?: "Quote";
    id: string;
    status: QuoteStatus;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      project: { __typename?: "Project"; id: string; name: string };
    };
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      org: { __typename?: "Organization"; id: string; name: string };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
    } | null;
  };
  rfqOwner: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: { __typename?: "Organization"; id: string; name: string };
  };
  initiatedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type NotificationReleaseChangeContextFieldsFragment = {
  __typename?: "NotificationContextReleaseStatusChange";
  id: string;
  type: NotificationType;
  newStatus: ReleaseStatus;
  oldStatus: ReleaseStatus;
  event: ReleaseEvent;
  release: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    itemCount: number;
    status: ReleaseStatus;
    time?: number | null;
    noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
    quoteDocument?: { __typename?: "QuoteDocument"; id: string } | null;
    project?: { __typename?: "Project"; id: string; name: string } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: { __typename?: "Organization"; id: string; name: string };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
    } | null;
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    warehouse?: { __typename?: "Warehouse"; id: string; name: string } | null;
  };
  initiatedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteNotificationMutation = {
  __typename?: "Mutation";
  deleteNotification?: string | null;
};

export type ReadNotificationMutationVariables = Exact<{
  input: ReadNotificationInput;
}>;

export type ReadNotificationMutation = {
  __typename?: "Mutation";
  readNotification?: {
    __typename?: "Notification";
    id: string;
    readAt?: number | null;
  } | null;
};

export type ResendExternalNotificationMutationVariables = Exact<{
  input: ResendExternalNotificationInput;
}>;

export type ResendExternalNotificationMutation = {
  __typename?: "Mutation";
  resendExternalNotification: {
    __typename?: "ExternalNotification";
    id: string;
  };
};

export type ExternalNotificationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryNotificationsFilter>;
}>;

export type ExternalNotificationsQuery = {
  __typename?: "Query";
  externalNotifications: {
    __typename?: "ExternalNotificationConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "ExternalNotificationEdge";
      node: {
        __typename?: "ExternalNotification";
        id: string;
        channel: ExternalNotificationChannel;
        subject: string;
        recipient: string;
        createdAt: number;
        release?: {
          __typename?: "Release";
          id: string;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              receivesQuoteNotifications: boolean;
              receivesBuyoutNotifications: boolean;
              receivesOrderNotifications: boolean;
              receivesInvoiceNotifications: boolean;
              id: string;
              name: string;
              cellPhone: string;
              cellPhoneExtension?: string | null;
              email: string;
            }>;
          } | null;
        } | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          };
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              receivesQuoteNotifications: boolean;
              receivesBuyoutNotifications: boolean;
              receivesOrderNotifications: boolean;
              receivesInvoiceNotifications: boolean;
              id: string;
              name: string;
              cellPhone: string;
              cellPhoneExtension?: string | null;
              email: string;
            }>;
          } | null;
        } | null;
        quote?: {
          __typename?: "Quote";
          id: string;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          };
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              receivesQuoteNotifications: boolean;
              receivesBuyoutNotifications: boolean;
              receivesOrderNotifications: boolean;
              receivesInvoiceNotifications: boolean;
              id: string;
              name: string;
              cellPhone: string;
              cellPhoneExtension?: string | null;
              email: string;
            }>;
          } | null;
        } | null;
        invoice?: {
          __typename?: "Invoice";
          id: string;
          release?: {
            __typename?: "Release";
            id: string;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              address?: {
                __typename?: "Address";
                id: string;
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                postalCode: string;
                country: string;
              } | null;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
            preferredVendor?: {
              __typename?: "OrgPreferredVendor";
              id: string;
              contacts: Array<{
                __typename?: "VendorContact";
                id: string;
                name: string;
                cellPhone: string;
                cellPhoneExtension?: string | null;
                email: string;
                receivesQuoteNotifications: boolean;
                receivesBuyoutNotifications: boolean;
                receivesOrderNotifications: boolean;
                receivesInvoiceNotifications: boolean;
              }>;
            } | null;
          } | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
  };
};

export type NotificationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<QueryNotificationsFilter>;
}>;

export type NotificationsQuery = {
  __typename?: "Query";
  notifications: {
    __typename?: "NotificationConnection";
    edges: Array<{
      __typename?: "NotificationEdge";
      node: {
        __typename?: "Notification";
        id: string;
        createdAt: number;
        readAt?: number | null;
        reminder: boolean;
        context:
          | {
              __typename?: "NotificationContextBuyoutStatusChange";
              type: NotificationType;
              id: string;
              newBuyoutStatus: BuyoutStatus;
              oldBuyoutStatus: BuyoutStatus;
              buyout: {
                __typename?: "Buyout";
                id: string;
                clientIdentifier: string;
                project: { __typename?: "Project"; id: string; name: string };
                createdBy: {
                  __typename?: "User";
                  id: string;
                  firstName: string;
                  lastName: string;
                };
                preferredVendor?: {
                  __typename?: "OrgPreferredVendor";
                  id: string;
                  contacts: Array<{
                    __typename?: "VendorContact";
                    id: string;
                    name: string;
                  }>;
                  sellerOrgLocation: {
                    __typename?: "OrgLocation";
                    id: string;
                    name: string;
                    org: {
                      __typename?: "Organization";
                      id: string;
                      name: string;
                    };
                  };
                } | null;
              };
              initiatedBy?: {
                __typename?: "User";
                id: string;
                firstName: string;
                lastName: string;
              } | null;
            }
          | {
              __typename?: "NotificationContextInvoiceStatusChange";
              type: NotificationType;
              id: string;
              newInvoiceStatus: InvoiceStatus;
              oldInvoiceStatus: InvoiceStatus;
              invoiceNotificationEvent: InvoiceEvent;
              invoiceNotificationInitiatedBy?: {
                __typename?: "User";
                id: string;
                firstName: string;
                lastName: string;
              } | null;
              invoice: {
                __typename?: "Invoice";
                id: string;
                number?: string | null;
                type: InvoiceType;
                approvedBy?: {
                  __typename?: "User";
                  id: string;
                  firstName: string;
                  lastName: string;
                  photoUrl?: string | null;
                } | null;
                release?: {
                  __typename?: "Release";
                  id: string;
                  sequenceNumber?: number | null;
                  preferredVendor?: {
                    __typename?: "OrgPreferredVendor";
                    id: string;
                    sellerOrgLocation: {
                      __typename?: "OrgLocation";
                      id: string;
                      org: {
                        __typename?: "Organization";
                        id: string;
                        name: string;
                        photoUrl?: string | null;
                      };
                    };
                  } | null;
                } | null;
                project?: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  location: {
                    __typename?: "OrgLocation";
                    id: string;
                    org: {
                      __typename?: "Organization";
                      id: string;
                      name: string;
                    };
                  };
                } | null;
              };
            }
          | {
              __typename?: "NotificationContextMessage";
              type: NotificationType;
              id: string;
              message: {
                __typename?: "Message";
                id: string;
                message: string;
                timeSent: number;
                quote?: {
                  __typename?: "Quote";
                  id: string;
                  rfq: {
                    __typename?: "Rfq";
                    id: string;
                    clientIdentifier: string;
                    project: {
                      __typename?: "Project";
                      id: string;
                      name: string;
                    };
                  };
                  sellerOrgLocation: {
                    __typename?: "OrgLocation";
                    id: string;
                    org: {
                      __typename?: "Organization";
                      id: string;
                      name: string;
                    };
                  };
                } | null;
                buyout?: {
                  __typename?: "Buyout";
                  id: string;
                  clientIdentifier: string;
                  sellerOrgLocation: {
                    __typename?: "OrgLocation";
                    id: string;
                    org: {
                      __typename?: "Organization";
                      id: string;
                      name: string;
                    };
                  };
                  quote?: { __typename?: "Quote"; id: string } | null;
                  project: { __typename?: "Project"; id: string; name: string };
                } | null;
                release?: {
                  __typename?: "Release";
                  id: string;
                  sequenceNumber?: number | null;
                  buyout?: {
                    __typename?: "Buyout";
                    id: string;
                    clientIdentifier: string;
                    quote?: { __typename?: "Quote"; id: string } | null;
                  } | null;
                  sellerOrgLocation?: {
                    __typename?: "OrgLocation";
                    id: string;
                    org: {
                      __typename?: "Organization";
                      id: string;
                      name: string;
                    };
                  } | null;
                  project?: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                  } | null;
                } | null;
                author: {
                  __typename?: "User";
                  id: string;
                  firstName: string;
                  lastName: string;
                };
              };
            }
          | {
              __typename?: "NotificationContextQuoteStatusChange";
              type: NotificationType;
              id: string;
              quoteStatus: QuoteStatus;
              quote: {
                __typename?: "Quote";
                id: string;
                status: QuoteStatus;
                rfq: {
                  __typename?: "Rfq";
                  id: string;
                  clientIdentifier: string;
                  project: { __typename?: "Project"; id: string; name: string };
                };
                sellerOrgLocation: {
                  __typename?: "OrgLocation";
                  id: string;
                  org: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                  };
                };
                preferredVendor?: {
                  __typename?: "OrgPreferredVendor";
                  id: string;
                  contacts: Array<{
                    __typename?: "VendorContact";
                    id: string;
                    name: string;
                  }>;
                } | null;
              };
              rfqOwner: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: { __typename?: "Organization"; id: string; name: string };
              };
              initiatedBy?: {
                __typename?: "User";
                id: string;
                firstName: string;
                lastName: string;
              } | null;
            }
          | {
              __typename?: "NotificationContextReleaseStatusChange";
              type: NotificationType;
              id: string;
              newStatus: ReleaseStatus;
              oldStatus: ReleaseStatus;
              event: ReleaseEvent;
              release: {
                __typename?: "Release";
                id: string;
                sequenceNumber?: number | null;
                itemCount: number;
                status: ReleaseStatus;
                time?: number | null;
                noteDocument?: {
                  __typename?: "NoteDocument";
                  id: string;
                } | null;
                quoteDocument?: {
                  __typename?: "QuoteDocument";
                  id: string;
                } | null;
                project?: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                } | null;
                buyout?: {
                  __typename?: "Buyout";
                  id: string;
                  clientIdentifier: string;
                  createdBy: {
                    __typename?: "User";
                    id: string;
                    firstName: string;
                    lastName: string;
                  };
                } | null;
                sellerOrgLocation?: {
                  __typename?: "OrgLocation";
                  id: string;
                  org: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                  };
                } | null;
                preferredVendor?: {
                  __typename?: "OrgPreferredVendor";
                  id: string;
                  contacts: Array<{
                    __typename?: "VendorContact";
                    id: string;
                    name: string;
                  }>;
                } | null;
                submittedBy?: {
                  __typename?: "User";
                  id: string;
                  firstName: string;
                  lastName: string;
                  photoUrl?: string | null;
                } | null;
                approvals: Array<{
                  __typename?: "ReleaseApproval";
                  id: string;
                  roles: Array<OrgLocationRole>;
                  createdAt: number;
                  approver: {
                    __typename?: "User";
                    id: string;
                    firstName: string;
                    lastName: string;
                  };
                }>;
                rejectedBy?: {
                  __typename?: "User";
                  id: string;
                  firstName: string;
                  lastName: string;
                  photoUrl?: string | null;
                } | null;
                warehouse?: {
                  __typename?: "Warehouse";
                  id: string;
                  name: string;
                } | null;
              };
              initiatedBy?: {
                __typename?: "User";
                id: string;
                firstName: string;
                lastName: string;
              } | null;
            };
      };
    }>;
  };
};

export type OrderTypeSummaryFieldsFragment = {
  __typename?: "ReleaseType";
  id: string;
  name: string;
  transactionKind: TransactionKind;
  requireDeliverySlip: boolean;
  trackInventory: boolean;
  includeAdditionalCharges: boolean;
  poFormat?: PoFormat | null;
  default: boolean;
  taxMode?: TaxMode | null;
  defaultAutoSync: boolean;
};

export type OrderTypeSummariesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type OrderTypeSummariesQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Organization";
    id: string;
    releaseTypes: Array<{
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    }>;
  } | null;
};

export type TaxCodeSummaryFieldsFragment = {
  __typename?: "TaxCode";
  id: string;
  code: string;
  description?: string | null;
  rate: string;
};

export type TaxCodesSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type TaxCodesSummaryQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      taxCodes: Array<{
        __typename?: "TaxCode";
        id: string;
        code: string;
        description?: string | null;
        rate: string;
      }>;
    };
  } | null;
};

export type ExternalInsuranceFieldsFragment = {
  __typename?: "ExternalInsurance";
  id: string;
  externalId: string;
  sourceId: string;
  sourceSystem: SourceSystem;
  type?: string | null;
  startDate?: number | null;
  endDate?: number | null;
  required: boolean;
  company?: string | null;
  policyNumber?: string | null;
  limit?: string | null;
};

export type OrgPreferredVendorsFieldsFragment = {
  __typename?: "OrgPreferredVendor";
  id: string;
  externalCode?: string | null;
  taxExempt: boolean;
  defaultPaymentTerm?: number | null;
  defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
    } | null;
  } | null;
  contacts: Array<{
    __typename?: "VendorContact";
    id: string;
    name: string;
    cellPhone: string;
    cellPhoneExtension?: string | null;
    email: string;
    receivesInvoiceNotifications: boolean;
    receivesQuoteNotifications: boolean;
    receivesBuyoutNotifications: boolean;
    receivesOrderNotifications: boolean;
  }>;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  };
  externalVendors: Array<{
    __typename?: "ExternalVendor";
    id: string;
    sourceSystem: SourceSystem;
    insurances: Array<{
      __typename?: "ExternalInsurance";
      id: string;
      externalId: string;
      sourceId: string;
      sourceSystem: SourceSystem;
      type?: string | null;
      startDate?: number | null;
      endDate?: number | null;
      required: boolean;
      company?: string | null;
      policyNumber?: string | null;
      limit?: string | null;
    }>;
  }>;
};

export type PreferredVendorExternalVendorFieldsFragment = {
  __typename?: "ExternalVendor";
  id: string;
  sourceSystem: SourceSystem;
  insurances: Array<{
    __typename?: "ExternalInsurance";
    id: string;
    externalId: string;
    sourceId: string;
    sourceSystem: SourceSystem;
    type?: string | null;
    startDate?: number | null;
    endDate?: number | null;
    required: boolean;
    company?: string | null;
    policyNumber?: string | null;
    limit?: string | null;
  }>;
};

export type OrgPreferredVendorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryOrgPreferredVendorsFilter>;
}>;

export type OrgPreferredVendorsQuery = {
  __typename?: "Query";
  orgPreferredVendorsConnection: {
    __typename?: "OrgPreferredVendorConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "OrgPreferredVendorEdge";
      cursor: string;
      node: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        taxExempt: boolean;
        defaultPaymentTerm?: number | null;
        defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          ledgerAccount?: {
            __typename?: "ExternalLedgerAccount";
            externalId: string;
          } | null;
        } | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesInvoiceNotifications: boolean;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
        }>;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        };
        externalVendors: Array<{
          __typename?: "ExternalVendor";
          id: string;
          sourceSystem: SourceSystem;
          insurances: Array<{
            __typename?: "ExternalInsurance";
            id: string;
            externalId: string;
            sourceId: string;
            sourceSystem: SourceSystem;
            type?: string | null;
            startDate?: number | null;
            endDate?: number | null;
            required: boolean;
            company?: string | null;
            policyNumber?: string | null;
            limit?: string | null;
          }>;
        }>;
      };
    }>;
  };
};

export type InvoicedReleaseItemFieldsFragment = {
  __typename?: "InvoicedReleaseItem";
  id: string;
  quantity: string;
  unitPrice?: string | null;
  quantitySoFar?: string | null;
  unexportedQuantitySoFar?: string | null;
};

export type AdditionalChargesFieldsFragment = {
  __typename?: "Charge";
  id: string;
  description: string;
  amount: string;
};

export type AddressFieldsFragment = {
  __typename?: "Address";
  id: string;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export type AggregatedCostCodeFieldsFragment = {
  __typename?: "AggregatedCostCode";
  costCode?: { __typename?: "CostCode"; id: string } | null;
  items: Array<{
    __typename?: "AggregatedItem";
    projectItem: { __typename?: "ProjectItem"; id: string };
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      quantityDecimal: string;
      orderedQuantity: string;
      duplicateCount: number;
      unitPrice?: string | null;
      position?: number | null;
      notes?: string | null;
      manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      tags: Array<{ __typename?: "ProjectTag"; id: string }>;
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      uom: { __typename?: "UOM"; id: string };
    }>;
    buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
  }>;
};

export type AggregatedItemFieldsFragment = {
  __typename?: "AggregatedItem";
  projectItem: { __typename?: "ProjectItem"; id: string };
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    position?: number | null;
    notes?: string | null;
    manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
    tags: Array<{ __typename?: "ProjectTag"; id: string }>;
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    uom: { __typename?: "UOM"; id: string };
  }>;
  buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
};

export type AggregatedZoneFieldsFragment = {
  __typename?: "AggregatedZone";
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  costCodes: Array<{
    __typename?: "AggregatedCostCode";
    costCode?: { __typename?: "CostCode"; id: string } | null;
    items: Array<{
      __typename?: "AggregatedItem";
      projectItem: { __typename?: "ProjectItem"; id: string };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        position?: number | null;
        notes?: string | null;
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        tags: Array<{ __typename?: "ProjectTag"; id: string }>;
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        uom: { __typename?: "UOM"; id: string };
      }>;
      buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
    }>;
  }>;
};

export type AssetFieldsFragment = {
  __typename?: "Asset";
  id: string;
  url: string;
  type: AssetType;
  context: AssetContext;
  thumbnailUrl?: string | null;
  createdAt: number;
  isPrivate: boolean;
  pages?: Array<{
    __typename?: "AssetPage";
    url: string;
    thumbnailUrl: string;
  }> | null;
};

export type BuyoutDistributorMessagesContextFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  quote?: {
    __typename?: "Quote";
    id: string;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
    } | null;
    rfq: {
      __typename?: "Rfq";
      id: string;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
  } | null;
};

export type BuyoutMessagesContextFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  quote?: {
    __typename?: "Quote";
    id: string;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    } | null;
    rfq: {
      __typename?: "Rfq";
      id: string;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
  } | null;
};

export type BuyoutQuoteFieldsFragment = {
  __typename?: "Quote";
  id: string;
  notes?: string | null;
  expirationDate?: number | null;
  paymentTermDays?: number | null;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  discount?: {
    __typename?: "Discount";
    amount?: string | null;
    percentage?: string | null;
  } | null;
  rfq: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        status: BuyoutStatus;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        };
      } | null;
    }>;
  };
};

export type BuyoutReleaseDetailsFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  status: ReleaseStatus;
  version: number;
  poNumber?: string | null;
  items: Array<{
    __typename?: "ReleaseItem";
    id: string;
    quantityDecimal: string;
    name: string;
    unitPrice?: string | null;
    alternativeFulfillmentTime?: number | null;
    deliveryDate?: number | null;
    notes?: string | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    alternativeFulfillmentRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    backorderFromRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    redelivery?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      issue: {
        __typename?: "ReleaseIssue";
        id: string;
        releaseItem: {
          __typename?: "ReleaseItem";
          id: string;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        };
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    buyoutItem?: {
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      description: string;
      unitPrice: string;
    } | null;
    projectItem?: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    } | null;
    issue?: {
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
  }>;
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
  };
};

export type BuyoutReleaseDetailsItemFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  quantityDecimal: string;
  name: string;
  unitPrice?: string | null;
  alternativeFulfillmentTime?: number | null;
  deliveryDate?: number | null;
  notes?: string | null;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  alternativeFulfillmentRelease?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
  } | null;
  backorderFromRelease?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  redelivery?: {
    __typename?: "ReleaseIssueResolution";
    id: string;
    issue: {
      __typename?: "ReleaseIssue";
      id: string;
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      };
    };
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  buyoutItem?: {
    __typename?: "BuyoutItem";
    id: string;
    quantityDecimal: string;
    description: string;
    unitPrice: string;
  } | null;
  projectItem?: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      quantityDecimal: string;
      orderedQuantity: string;
      duplicateCount: number;
      unitPrice?: string | null;
      notes?: string | null;
      position?: number | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
  } | null;
  issue?: {
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  } | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
};

export type BuyoutReleaseFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  includeServices: Array<ServiceType>;
  total?: string | null;
  deletedAt?: number | null;
  time?: number | null;
  timeTBD: boolean;
  status: ReleaseStatus;
  version: number;
  hasMissingCostCodes: boolean;
  poNumber?: string | null;
  project?: {
    __typename?: "Project";
    id: string;
    location: { __typename?: "OrgLocation"; id: string };
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
  poLink?: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  deliverySlips: Array<{
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    createdAt: number;
    reviewedAt?: number | null;
    archivedAt?: number | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    inventoryTransaction?: {
      __typename?: "InventoryTransaction";
      id: string;
    } | null;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
  }>;
  deliveryPhotos: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  permissions: {
    __typename?: "ReleasePermissions";
    archive: AuthorizationStatus;
  };
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    default: boolean;
    requireDeliverySlip: boolean;
    billable: boolean;
    enableUpcomingNotifications: boolean;
    includeAdditionalCharges: boolean;
    transactionKind: TransactionKind;
    taxMode?: TaxMode | null;
    chargesMode?: ChargesMode | null;
    poFormat?: PoFormat | null;
    archivedAt?: number | null;
    defaultAutoSync: boolean;
    nonJobPO: boolean;
    trackInventory: boolean;
    includeTaxInInvoiceSubtotal: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    ledgerSubaccount?: {
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    poType?: {
      __typename?: "ExternalPOType";
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      name: string;
    } | null;
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    additionalChargesCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    defaultOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
    salesTaxOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
  };
};

export type BuyoutReleaseItemFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  quantityDecimal: string;
  zone?: { __typename?: "Zone"; id: string; name: string } | null;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
  }>;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
    url?: string | null;
  } | null;
};

export type BuyoutStockingPriceFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  status: BuyoutStatus;
  version: number;
  clientIdentifier: string;
  subtotal: string;
  createdAt: number;
  poNumber?: string | null;
  includedServices: Array<ServiceType>;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  quote?: {
    __typename?: "Quote";
    id: string;
    notes?: string | null;
    expirationDate?: number | null;
    paymentTermDays?: number | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
          status: BuyoutStatus;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          };
        } | null;
      }>;
    };
  } | null;
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    startDate?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{ __typename?: "VendorContact"; id: string; name: string }>;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  releases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    sequenceNumber?: number | null;
    time?: number | null;
  }>;
  servicePriceSheet: {
    __typename?: "ServicePriceSheet";
    id: string;
    prices: Array<{
      __typename?: "ServicePrice";
      id: string;
      name: ServiceName;
      productCategory: ServiceProductCategoryName;
      type: ServiceType;
      regularHoursAmount?: string | null;
      offHoursAmount?: string | null;
      regularHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      offHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
  };
};

export type BuyoutsBuyoutFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  subtotal: string;
  description?: string | null;
  createdAt: number;
  deletedAt?: number | null;
  completedAmount: string;
  requestedAmount: string;
  scheduledAmount: string;
  exportedAt?: number | null;
  poExists?: boolean | null;
  status: BuyoutStatus;
  includedServices: Array<ServiceType>;
  releases: Array<{
    __typename?: "Release";
    id: string;
    deletedAt?: number | null;
  }>;
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
  };
  quote?: {
    __typename?: "Quote";
    id: string;
    rfq: {
      __typename?: "Rfq";
      id: string;
      status: RfqStatus;
      clientIdentifier: string;
    };
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    contacts: Array<{
      __typename?: "VendorContact";
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
    }>;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  quoteDocument?: { __typename?: "QuoteDocument"; id: string } | null;
  releaseType: { __typename?: "ReleaseType"; id: string; name: string };
  poLink?: {
    __typename?: "POLink";
    id: string;
    syncedAt?: number | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    } | null;
  } | null;
};

export type CostCodeSummaryFieldsFragment = {
  __typename?: "CostCode";
  id: string;
  code: string;
  description: string;
};

export type DistributorBuyoutFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  additionalChargesAllowance?: string | null;
  customTaxAmount?: string | null;
  taxRate?: string | null;
  taxAmount?: string | null;
  subtotal: string;
  createdAt: number;
  status: BuyoutStatus;
  version: number;
  includedServices: Array<ServiceType>;
  paymentTermDays?: number | null;
  notes?: string | null;
  poNumber?: string | null;
  netAmount: string;
  taxType?: TaxType | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  releases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    sequenceNumber?: number | null;
    time?: number | null;
  }>;
  quote?: {
    __typename?: "Quote";
    id: string;
    notes?: string | null;
    expirationDate?: number | null;
    paymentTermDays?: number | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
    };
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    taxExempt: boolean;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
    }>;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    startDate?: number | null;
    status: ProjectStatus;
    taxExempt: boolean;
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        settings?: {
          __typename?: "OrgSettings";
          display?: {
            __typename?: "DisplaySettings";
            enableManufacturers: boolean;
            currency: {
              __typename?: "Currency";
              id: string;
              code: string;
              name: string;
              symbol: string;
            };
          } | null;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
  items: Array<{
    __typename?: "BuyoutItem";
    id: string;
    quantityDecimal: string;
    unitPrice: string;
    requestedUnitPrice?: string | null;
    expirationDate?: number | null;
    notes?: string | null;
    isIncluded: boolean;
    description: string;
    position?: number | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    };
    rfqItem?: {
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
    } | null;
    orgCatalogSku?: {
      __typename?: "OrgCatalogSku";
      id: string;
      name: string;
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    masterSku?: {
      __typename?: "Sku";
      id: string;
      name: string;
      manufacturer: {
        __typename?: "Manufacturer";
        url?: string | null;
        id: string;
        name: string;
      };
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
  }>;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  quoteDocument?: {
    __typename?: "QuoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  taxCode?: {
    __typename?: "TaxCode";
    id: string;
    code: string;
    description?: string | null;
    rate: string;
  } | null;
  releaseType: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
  };
};

export type DistributorBuyoutItemFieldsFragment = {
  __typename?: "BuyoutItem";
  id: string;
  quantityDecimal: string;
  unitPrice: string;
  requestedUnitPrice?: string | null;
  expirationDate?: number | null;
  notes?: string | null;
  isIncluded: boolean;
  description: string;
  position?: number | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  projectItem: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  };
  rfqItem?: {
    __typename?: "RfqItem";
    id: string;
    description: string;
    quantityDecimal: string;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    };
  } | null;
  orgCatalogSku?: {
    __typename?: "OrgCatalogSku";
    id: string;
    name: string;
  } | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  masterSku?: {
    __typename?: "Sku";
    id: string;
    name: string;
    manufacturer: {
      __typename?: "Manufacturer";
      url?: string | null;
      id: string;
      name: string;
    };
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
};

export type DistributorBuyoutQuoteFieldsFragment = {
  __typename?: "Quote";
  id: string;
  notes?: string | null;
  expirationDate?: number | null;
  paymentTermDays?: number | null;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  discount?: {
    __typename?: "Discount";
    amount?: string | null;
    percentage?: string | null;
  } | null;
  rfq: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
  };
};

export type DistributorBuyoutReleaseFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  total?: string | null;
  time?: number | null;
  status: ReleaseStatus;
  version: number;
  includeServices: Array<ServiceType>;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
  deliverySlips: Array<{
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    createdAt: number;
    reviewedAt?: number | null;
    archivedAt?: number | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    inventoryTransaction?: {
      __typename?: "InventoryTransaction";
      id: string;
    } | null;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
  }>;
  deliveryPhotos: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
};

export type DistributorBuyoutReleasesFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  status: BuyoutStatus;
  requestedAmount: string;
  scheduledAmount: string;
  completedAmount: string;
  poNumber?: string | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesOrderNotifications: boolean;
    }>;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
  };
  releases: Array<{
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    total?: string | null;
    time?: number | null;
    status: ReleaseStatus;
    version: number;
    includeServices: Array<ServiceType>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  }>;
};

export type DistributorBuyoutStockingPriceFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  status: BuyoutStatus;
  version: number;
  clientIdentifier: string;
  subtotal: string;
  createdAt: number;
  paymentTermDays?: number | null;
  poNumber?: string | null;
  includedServices: Array<ServiceType>;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  quote?: {
    __typename?: "Quote";
    id: string;
    notes?: string | null;
    expirationDate?: number | null;
    paymentTermDays?: number | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
          status: BuyoutStatus;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          };
        } | null;
      }>;
    };
  } | null;
  releases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    sequenceNumber?: number | null;
    time?: number | null;
  }>;
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    startDate?: number | null;
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{ __typename?: "VendorContact"; id: string; name: string }>;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  servicePriceSheet: {
    __typename?: "ServicePriceSheet";
    id: string;
    prices: Array<{
      __typename?: "ServicePrice";
      id: string;
      name: ServiceName;
      productCategory: ServiceProductCategoryName;
      type: ServiceType;
      regularHoursAmount?: string | null;
      offHoursAmount?: string | null;
      regularHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      offHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
  };
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
};

export type DistributorCostCodeFieldsFragment = {
  __typename?: "CostCode";
  id: string;
  code: string;
  description: string;
};

export type DistributorProjectItemFieldsFragment = {
  __typename?: "ProjectItem";
  id: string;
  material: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  };
  estimateUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
};

export type DistributorProjectMaterialFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  material:
    | {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        customPartNumber?: string | null;
        sectionName?: string | null;
        defaultUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        defaultManufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: "Product";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }
    | {
        __typename?: "Sku";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        brand: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
        product: {
          __typename?: "Product";
          id: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      };
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
};

export type DistributorQuotesAuxiliaryQuoteItemFieldsFragment = {
  __typename?: "AuxiliaryQuoteItem";
  id: string;
  unitPrice?: string | null;
  description?: string | null;
  notes?: string | null;
  expirationDate?: number | null;
  quantityDecimal?: string | null;
  leadTimeDays?: number | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
};

export type DistributorQuoteCommonFieldsFragment = {
  __typename?: "Quote";
  id: string;
  clientIdentifier: string;
  status: QuoteStatus;
  notes?: string | null;
  expirationDate?: number | null;
  submittedDate?: number | null;
  paymentTermDays?: number | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  rfq: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
      org: { __typename?: "Organization"; id: string; name: string };
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          hqAddress?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesQuoteNotifications: boolean;
    }>;
  } | null;
  previousRevision?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    url: string;
  } | null;
  nextRevision?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    url: string;
  } | null;
};

export type DistributorQuoteFieldsFragment = {
  __typename?: "Quote";
  id: string;
  clientIdentifier: string;
  status: QuoteStatus;
  notes?: string | null;
  expirationDate?: number | null;
  submittedDate?: number | null;
  paymentTermDays?: number | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  discount?: {
    __typename?: "Discount";
    amount?: string | null;
    percentage?: string | null;
  } | null;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  itemGroups: Array<{
    __typename?: "QuoteItemGroup";
    rfqItem: {
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                skus: Array<{
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  imageUrl: string;
                  description: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                }>;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                product: {
                  __typename?: "Product";
                  id: string;
                  skus: Array<{
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    imageUrl: string;
                    description: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                  }>;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    };
    quoteItems: Array<{
      __typename?: "QuoteItem";
      id: string;
      unitPrice?: string | null;
      status: QuoteStatus;
      expirationDate?: number | null;
      description?: string | null;
      notes?: string | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        brand: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
        product: {
          __typename?: "Product";
          id: string;
          categories: Array<{ __typename?: "ProductCategory"; id: string }>;
        };
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        type?: string | null;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
    }>;
  }>;
  auxiliaryItems: Array<{
    __typename?: "AuxiliaryQuoteItem";
    id: string;
    unitPrice?: string | null;
    description?: string | null;
    notes?: string | null;
    expirationDate?: number | null;
    quantityDecimal?: string | null;
    leadTimeDays?: number | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
  }>;
  rfq: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          settings?: {
            __typename?: "OrgSettings";
            display?: {
              __typename?: "DisplaySettings";
              enableManufacturers: boolean;
              currency: {
                __typename?: "Currency";
                id: string;
                code: string;
                name: string;
                symbol: string;
              };
            } | null;
          } | null;
          hqAddress?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
      org: { __typename?: "Organization"; id: string; name: string };
    };
  };
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesQuoteNotifications: boolean;
    }>;
  } | null;
  previousRevision?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    url: string;
  } | null;
  nextRevision?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    url: string;
  } | null;
};

export type DistributorQuoteItemFieldsFragment = {
  __typename?: "QuoteItem";
  id: string;
  unitPrice?: string | null;
  status: QuoteStatus;
  expirationDate?: number | null;
  description?: string | null;
  notes?: string | null;
  quantityDecimal?: string | null;
  leadTimeDays?: number | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  masterSku?: {
    __typename?: "Sku";
    id: string;
    name: string;
    description: string;
    imageUrl: string;
    brand: string;
    manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
    product: {
      __typename?: "Product";
      id: string;
      categories: Array<{ __typename?: "ProductCategory"; id: string }>;
    };
  } | null;
  orgCatalogSku?: {
    __typename?: "OrgCatalogSku";
    id: string;
    name: string;
    type?: string | null;
  } | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
};

export type DistributorQuoteItemGroupFieldsFragment = {
  __typename?: "QuoteItemGroup";
  rfqItem: {
    __typename?: "RfqItem";
    id: string;
    description: string;
    quantityDecimal: string;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              skus: Array<{
                __typename?: "Sku";
                id: string;
                name: string;
                imageUrl: string;
                description: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
              }>;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              product: {
                __typename?: "Product";
                id: string;
                skus: Array<{
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  imageUrl: string;
                  description: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                }>;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    };
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  };
  quoteItems: Array<{
    __typename?: "QuoteItem";
    id: string;
    unitPrice?: string | null;
    status: QuoteStatus;
    expirationDate?: number | null;
    description?: string | null;
    notes?: string | null;
    quantityDecimal?: string | null;
    leadTimeDays?: number | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    masterSku?: {
      __typename?: "Sku";
      id: string;
      name: string;
      description: string;
      imageUrl: string;
      brand: string;
      manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
      product: {
        __typename?: "Product";
        id: string;
        categories: Array<{ __typename?: "ProductCategory"; id: string }>;
      };
    } | null;
    orgCatalogSku?: {
      __typename?: "OrgCatalogSku";
      id: string;
      name: string;
      type?: string | null;
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
  }>;
};

export type DistributorQuoteItemGroupRfqItemFieldsFragment = {
  __typename?: "RfqItem";
  id: string;
  description: string;
  quantityDecimal: string;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  projectItem: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            skus: Array<{
              __typename?: "Sku";
              id: string;
              name: string;
              imageUrl: string;
              description: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
            }>;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            product: {
              __typename?: "Product";
              id: string;
              skus: Array<{
                __typename?: "Sku";
                id: string;
                name: string;
                imageUrl: string;
                description: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
              }>;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  };
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
};

export type DistributorQuoteMessageContextFieldsFragment = {
  __typename?: "Quote";
  id: string;
  clientIdentifier: string;
  rfq: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      org: { __typename?: "Organization"; id: string; name: string };
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{ __typename?: "VendorContact"; id: string; name: string }>;
  } | null;
};

export type DistributorReleaseFieldsFragment = {
  __typename?: "Release";
  id: string;
  version: number;
  createdAt: number;
  sequenceNumber?: number | null;
  total?: string | null;
  poNumber?: string | null;
  includeServices: Array<ServiceType>;
  status: ReleaseStatus;
  time?: number | null;
  timeTBD: boolean;
  requestedTime?: number | null;
  taxRate?: string | null;
  taxAmount?: string | null;
  taxUnadjustedAmount?: string | null;
  customTaxAmount?: string | null;
  netAmount: string;
  taxableNetAmount: string;
  notes?: string | null;
  paymentTerm: number;
  taxVariance?: string | null;
  updatedAt?: number | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
  submittedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    additionalChargesAllowance?: string | null;
    customTaxAmount?: string | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    subtotal: string;
    createdAt: number;
    status: BuyoutStatus;
    version: number;
    includedServices: Array<ServiceType>;
    paymentTermDays?: number | null;
    notes?: string | null;
    poNumber?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          settings?: {
            __typename?: "OrgSettings";
            display?: {
              __typename?: "DisplaySettings";
              enableManufacturers: boolean;
              currency: {
                __typename?: "Currency";
                id: string;
                code: string;
                name: string;
                symbol: string;
              };
            } | null;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      description: string;
      position?: number | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    taxExempt: boolean;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
    }>;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    startDate?: number | null;
    status: ProjectStatus;
    taxExempt: boolean;
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
    }>;
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  deliverySlips: Array<{
    __typename?: "DeliverySlip";
    id: string;
    archivedAt?: number | null;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
  }>;
  deliveryPhotos: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  quoteDocument?: {
    __typename?: "QuoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  items: Array<{
    __typename?: "ReleaseItem";
    id: string;
    name: string;
    quantityDecimal: string;
    receivedQuantityDecimal: string;
    requestedQuantity: number;
    unitPrice?: string | null;
    isIncluded: boolean;
    position?: number | null;
    notes?: string | null;
    alternativeFulfillmentTime?: number | null;
    alternativeFulfillmentQuantityDecimal?: string | null;
    pricePrefilled: boolean;
    deliveryDate?: number | null;
    pricePrenegotiated: boolean;
    addedByVendor: boolean;
    deliveryDateTBD: boolean;
    taxable: boolean;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    buyoutItem?: {
      __typename?: "BuyoutItem";
      id: string;
      description: string;
      quantityDecimal: string;
      unitPrice: string;
      notes?: string | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
    } | null;
    projectItem?: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    } | null;
    backorderFromRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    redelivery?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      issue: {
        __typename?: "ReleaseIssue";
        id: string;
        releaseItem: {
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        };
      };
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    requestedUom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    alternativeFulfillmentRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      time?: number | null;
    } | null;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
  }>;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
  warehouse?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  siteContact?: {
    __typename?: "SiteContact";
    id: string;
    name?: string | null;
    phone?: string | null;
  } | null;
  deliveryAddress?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  invoice?: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      pageRange: { __typename?: "PageRange"; start: number; end: number };
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    }>;
  } | null;
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
  };
  orgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    phone?: string | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      settings?: {
        __typename?: "OrgSettings";
        display?: {
          __typename?: "DisplaySettings";
          enableManufacturers: boolean;
          currency: {
            __typename?: "Currency";
            id: string;
            code: string;
            name: string;
            symbol: string;
          };
        } | null;
      } | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
  vendorContacts: Array<{
    __typename?: "VendorContact";
    id: string;
    name: string;
    cellPhone: string;
    cellPhoneExtension?: string | null;
    email: string;
    receivesQuoteNotifications: boolean;
    receivesBuyoutNotifications: boolean;
    receivesOrderNotifications: boolean;
    receivesInvoiceNotifications: boolean;
  }>;
  taxCode?: {
    __typename?: "TaxCode";
    id: string;
    code: string;
    description?: string | null;
    rate: string;
  } | null;
  quote?: {
    __typename?: "Quote";
    id: string;
    rfq: { __typename?: "Rfq"; id: string; clientIdentifier: string };
  } | null;
};

export type DistributorReleaseInvoiceFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  documents: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    pageRange: { __typename?: "PageRange"; start: number; end: number };
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  }>;
};

export type DistributorReleaseItemFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  name: string;
  quantityDecimal: string;
  receivedQuantityDecimal: string;
  requestedQuantity: number;
  unitPrice?: string | null;
  isIncluded: boolean;
  position?: number | null;
  notes?: string | null;
  alternativeFulfillmentTime?: number | null;
  alternativeFulfillmentQuantityDecimal?: string | null;
  pricePrefilled: boolean;
  deliveryDate?: number | null;
  pricePrenegotiated: boolean;
  addedByVendor: boolean;
  deliveryDateTBD: boolean;
  taxable: boolean;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  buyoutItem?: {
    __typename?: "BuyoutItem";
    id: string;
    description: string;
    quantityDecimal: string;
    unitPrice: string;
    notes?: string | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
  } | null;
  projectItem?: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  } | null;
  backorderFromRelease?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
  } | null;
  redelivery?: {
    __typename?: "ReleaseIssueResolution";
    id: string;
    issue: {
      __typename?: "ReleaseIssue";
      id: string;
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      };
    };
  } | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  requestedUom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  alternativeFulfillmentRelease?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    time?: number | null;
  } | null;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
};

export type EstimatedItemExtendedFieldsFragment = {
  __typename?: "EstimatedItem";
  id: string;
  quantityDecimal: string;
  orderedQuantity: string;
  duplicateCount: number;
  unitPrice?: string | null;
  notes?: string | null;
  position?: number | null;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
};

export type EstimatedItemFieldsFragment = {
  __typename?: "EstimatedItem";
  id: string;
  quantityDecimal: string;
  orderedQuantity: string;
  duplicateCount: number;
  unitPrice?: string | null;
  notes?: string | null;
  position?: number | null;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
};

export type ExternalPoBaseFieldsFragment = {
  __typename?: "ExternalPO";
  sourceSystem: SourceSystem;
  number: string;
  externalId: string;
  amount: string;
  date: number;
  project?: {
    __typename?: "ExternalProject";
    id: string;
    externalId: string;
    name: string;
    project?: {
      __typename?: "Project";
      id: string;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
  } | null;
  vendor?: {
    __typename?: "ExternalVendor";
    id: string;
    name: string;
    orgPreferredVendors: Array<{
      __typename?: "OrgPreferredVendor";
      id: string;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
        };
      };
    }>;
  } | null;
  poLinks: Array<{
    __typename?: "POLink";
    id: string;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      deletedAt?: number | null;
    } | null;
  }>;
};

export type ExternalPoFieldsFragment = {
  __typename?: "ExternalPO";
  sourceSystem: SourceSystem;
  number: string;
  date: number;
  externalId: string;
  description: string;
  name: string;
  amount: string;
  releaseType?: { __typename?: "ReleaseType"; id: string } | null;
  project?: {
    __typename?: "ExternalProject";
    id: string;
    externalId: string;
    name: string;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
  } | null;
  vendor?: {
    __typename?: "ExternalVendor";
    id: string;
    name: string;
    orgPreferredVendors: Array<{
      __typename?: "OrgPreferredVendor";
      id: string;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
        };
      };
    }>;
  } | null;
  itemGroups: {
    __typename?: "ExternalPOItemGroups";
    taxCodeId?: string | null;
    importPossibility: ExternalPoImportPossibility;
    taxAmount: string;
    taxType?: TaxType | null;
    importable: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    missingCostCode: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    unsupportedCostType: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    additionalCharges: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    salesTax: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    missingTag: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    aggregatedItems: Array<{
      __typename?: "ExternalPOItem";
      sourceSystem: SourceSystem;
      externalId: string;
      description: string;
      quantity: string;
      unitCost: string;
      amount: string;
      invoicedAmount?: string | null;
      remainingAmount?: string | null;
      uom: string;
      notes?: string | null;
      externalCostTypeId?: string | null;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        collidesWith?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      wbsTag?: {
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      } | null;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
    } | null;
  };
  poLinks: Array<{
    __typename?: "POLink";
    id: string;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      deletedAt?: number | null;
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    } | null;
  }>;
};

export type ExternalPoItemFieldsFragment = {
  __typename?: "ExternalPOItem";
  sourceSystem: SourceSystem;
  externalId: string;
  description: string;
  quantity: string;
  unitCost: string;
  amount: string;
  invoicedAmount?: string | null;
  remainingAmount?: string | null;
  uom: string;
  notes?: string | null;
  externalCostTypeId?: string | null;
  externalCostCode?: {
    __typename?: "ExternalCostCode";
    externalId: string;
    sourceId: string;
    code: string;
    name: string;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    collidesWith?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  costType?: {
    __typename?: "ExternalCostType";
    externalId: string;
    sourceId: string;
    code: string;
    name: string;
    collidesWith?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
    } | null;
  } | null;
  wbsTag?: {
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  } | null;
};

export type InstructionFieldsFragment = {
  __typename?: "Instruction";
  text: string;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
};

export type ManufacturerFieldsFragment = {
  __typename?: "Manufacturer";
  id: string;
  name: string;
};

export type MasterProductsProductFieldsFragment = {
  __typename?: "Product";
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  categories: Array<{
    __typename?: "ProductCategory";
    id: string;
    l1Name: string;
  }>;
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
};

export type MasterSkuSkuFieldsFragment = {
  __typename?: "Sku";
  id: string;
  name: string;
  imageUrl: string;
  description: string;
};

export type MasterSkusByKeywordSkuFieldsFragment = {
  __typename?: "Sku";
  id: string;
  name: string;
  imageUrl: string;
  description: string;
  manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
};

export type MasterSkusFacetFieldsFragment = {
  __typename?: "Facet";
  id: string;
  name: string;
  values: Array<{
    __typename?: "FacetValue";
    id: string;
    name: string;
    value: string;
    count?: number | null;
  }>;
};

export type MasterSkusProductFieldsFragment = {
  __typename?: "Product";
  id: string;
  name: string;
};

export type MasterSkusSkuFieldsFragment = {
  __typename?: "Sku";
  id: string;
  name: string;
  imageUrl: string;
  specsUrl: string;
  manufacturerSkuUrl: string;
  brand: string;
  product: {
    __typename?: "Product";
    id: string;
    name: string;
    description: string;
    imageUrl: string;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  };
  manufacturer: {
    __typename?: "Manufacturer";
    url?: string | null;
    id: string;
    name: string;
  };
};

export type MessageFieldsFragment = {
  __typename?: "Message";
  id: string;
  isPrivate: boolean;
  message: string;
  timeSent: number;
  author: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl?: string | null;
    org: { __typename?: "Organization"; id: string; name: string };
  };
};

export type OrgFieldsFragment = {
  __typename?: "Organization";
  id: string;
  name: string;
  locations: Array<{
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  }>;
};

export type OrgLocationFieldsFragment = {
  __typename?: "OrgLocation";
  id: string;
  name: string;
  phone?: string | null;
  address?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  org: {
    __typename?: "Organization";
    id: string;
    name: string;
    photoUrl?: string | null;
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  contractorOrg?: { __typename?: "Organization"; id: string } | null;
};

export type OrgMaterialSummaryFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  material:
    | {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        customPartNumber?: string | null;
        sectionName?: string | null;
        defaultUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        defaultManufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: "Product";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }
    | {
        __typename?: "Sku";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        brand: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
        product: {
          __typename?: "Product";
          id: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      };
};

export type OrgSkuFieldsFragment = {
  __typename?: "OrgCatalogSku";
  id: string;
  name: string;
  customPartNumber?: string | null;
  sectionName?: string | null;
  materialCostCode?: string | null;
  type?: string | null;
  createdAt: number;
  deletedAt?: number | null;
  equivalentsCount: number;
  asSearchTerms: Array<string>;
  defaultUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  mappedCategories: Array<{
    __typename?: "ProductCategory";
    id: string;
    l1Name: string;
  }>;
};

export type OrganizationFieldsFragment = {
  __typename?: "Organization";
  id: string;
  name: string;
  phone?: string | null;
  type: OrgType;
  photoUrl?: string | null;
  hqAddress?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
};

export type OrgsOrgLocationFieldsFragment = {
  __typename?: "OrgLocation";
  id: string;
  name: string;
  phone?: string | null;
  address?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  org: {
    __typename?: "Organization";
    id: string;
    name: string;
    photoUrl?: string | null;
  };
};

export type PageInfoFieldsFragment = {
  __typename?: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
};

export type PageRangeFieldsFragment = {
  __typename?: "PageRange";
  start: number;
  end: number;
};

export type PartialProjectItemFieldsFragment = {
  __typename?: "ProjectItem";
  id: string;
  estimateUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  material: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  };
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    notes?: string | null;
    position?: number | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  }>;
  releaseItems: Array<{
    __typename?: "ReleaseItem";
    id: string;
    name: string;
    quantityDecimal: string;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    buyoutItem?: {
      __typename?: "BuyoutItem";
      id: string;
      buyout: { __typename?: "Buyout"; id: string; clientIdentifier: string };
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    release: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      time?: number | null;
      sequenceNumber?: number | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
    };
  }>;
};

export type PartialProjectItemReleaseItemFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  name: string;
  quantityDecimal: string;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  buyoutItem?: {
    __typename?: "BuyoutItem";
    id: string;
    buyout: { __typename?: "Buyout"; id: string; clientIdentifier: string };
  } | null;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  release: {
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    time?: number | null;
    sequenceNumber?: number | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
  };
};

export type PaymentMethodFieldsFragment = {
  __typename?: "PaymentMethod";
  id: string;
  number?: string | null;
  type: PaymentMethodType;
  description: string;
  default: boolean;
  externalBankAccount?: {
    __typename?: "ExternalBankAccount";
    id: string;
    name: string;
  } | null;
};

export type ProjectExtendedFieldsFragment = {
  __typename?: "Project";
  usesProjectCostCodes: boolean;
  id: string;
  name: string;
  status: ProjectStatus;
  budget?: string | null;
  deletedAt?: number | null;
  startDate?: number | null;
  percentageOfBudgetSpent?: string | null;
  jobNumber?: string | null;
  taxExempt: boolean;
  poJobNumber?: string | null;
  restrictCostCodes: boolean;
  preferredInventoryInflows: boolean;
  releaseInstructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  aggregatedZones: Array<{
    __typename?: "AggregatedZone";
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    costCodes: Array<{
      __typename?: "AggregatedCostCode";
      costCode?: { __typename?: "CostCode"; id: string } | null;
      items: Array<{
        __typename?: "AggregatedItem";
        projectItem: { __typename?: "ProjectItem"; id: string };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          position?: number | null;
          notes?: string | null;
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          tags: Array<{ __typename?: "ProjectTag"; id: string }>;
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
          uom: { __typename?: "UOM"; id: string };
        }>;
        buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
      }>;
    }>;
  }>;
  mappings: Array<{
    __typename?: "SourceSystemMapping";
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
  externalProjects: Array<{
    __typename?: "ExternalProject";
    id: string;
    sourceSystem: SourceSystem;
  }>;
  costCodes?: Array<{
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  }> | null;
  estimatedItemGroups: Array<{
    __typename?: "EstimatedItemGroup";
    estimatedItemCount: number;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  }>;
  team: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl?: string | null;
  }>;
  address: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  location: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      costCodes: Array<{
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }>;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  items: Array<{
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
    buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
  }>;
  zones: Array<{
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  }>;
  permissions: {
    __typename?: "ProjectPermissions";
    edit: AuthorizationStatus;
    createTag: AuthorizationStatus;
    report: AuthorizationStatus;
  };
};

export type ProjectExternalProjectsWithEstimatesFieldsFragment = {
  __typename?: "Project";
  id: string;
  externalProjects: Array<{
    __typename?: "ExternalProject";
    id: string;
    sourceSystem: SourceSystem;
    estimates: Array<{
      __typename?: "ExternalEstimate";
      externalId: string;
      sourceId: string;
      sourceSystem: SourceSystem;
      description: string;
    }>;
  }>;
};

export type ProjectFieldsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  budget?: string | null;
  deletedAt?: number | null;
  startDate?: number | null;
  percentageOfBudgetSpent?: string | null;
  jobNumber?: string | null;
  taxExempt: boolean;
  poJobNumber?: string | null;
  restrictCostCodes: boolean;
  usesProjectCostCodes: boolean;
  preferredInventoryInflows: boolean;
  estimatedItemGroups: Array<{
    __typename?: "EstimatedItemGroup";
    estimatedItemCount: number;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  }>;
  team: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl?: string | null;
  }>;
  address: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  location: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      costCodes: Array<{
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }>;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  items: Array<{
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
    buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
  }>;
  zones: Array<{
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  }>;
  mappings: Array<{
    __typename?: "SourceSystemMapping";
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
  permissions: {
    __typename?: "ProjectPermissions";
    edit: AuthorizationStatus;
    createTag: AuthorizationStatus;
    report: AuthorizationStatus;
  };
};

export type ProjectItemExtendedFieldsFragment = {
  __typename?: "ProjectItem";
  id: string;
  material: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  };
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    notes?: string | null;
    position?: number | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  }>;
  estimateUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
};

export type ProjectItemFieldsFragment = {
  __typename?: "ProjectItem";
  id: string;
  material: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  };
  estimateUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    notes?: string | null;
    position?: number | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  }>;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
};

export type ProjectListOptionFieldsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  jobNumber?: string | null;
  status: ProjectStatus;
  startDate?: number | null;
  taxExempt: boolean;
  preferredInventoryInflows: boolean;
  location: { __typename?: "OrgLocation"; id: string; name: string };
  address: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  team: Array<{ __typename?: "User"; id: string }>;
  externalProjects: Array<{
    __typename?: "ExternalProject";
    id: string;
    sourceSystem: SourceSystem;
  }>;
};

export type ProjectPredictedPoNumberFieldsFragment = {
  __typename?: "Project";
  id: string;
  predictedPoNumber?: string | null;
};

export type QuoteMessageContextFieldsFragment = {
  __typename?: "Quote";
  id: string;
  clientIdentifier: string;
  rfq: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
        }>;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          org: { __typename?: "Organization"; id: string; name: string };
        };
      } | null;
    }>;
  };
};

export type QuoteStockingPriceFieldsFragment = {
  __typename?: "Quote";
  id: string;
  servicePriceSheet?: {
    __typename?: "ServicePriceSheet";
    id: string;
    prices: Array<{
      __typename?: "ServicePrice";
      id: string;
      name: ServiceName;
      productCategory: ServiceProductCategoryName;
      type: ServiceType;
      regularHoursAmount?: string | null;
      offHoursAmount?: string | null;
      regularHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      offHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
  } | null;
};

export type ReleaseApprovalFieldsFragment = {
  __typename?: "ReleaseApproval";
  id: string;
  roles: Array<OrgLocationRole>;
  createdAt: number;
  approver: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type ReleaseApprovalProgressFieldsFragment = {
  __typename?: "ReleaseApprovalProgress";
  canApprove: boolean;
  canApproveImmediately: boolean;
  canReject: boolean;
  slots: Array<{
    __typename?: "ReleaseApprovalSlot";
    role?: OrgLocationRole | null;
    ineffectual: boolean;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  }>;
};

export type ReleaseApprovalSlotFieldsFragment = {
  __typename?: "ReleaseApprovalSlot";
  role?: OrgLocationRole | null;
  ineffectual: boolean;
  approvals: Array<{
    __typename?: "ReleaseApproval";
    id: string;
    roles: Array<OrgLocationRole>;
    createdAt: number;
    approver: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
};

export type ReleaseDeliverySlipFieldsFragment = {
  __typename?: "DeliverySlip";
  id: string;
  status: DeliverySlipStatus;
  createdAt: number;
  reviewedAt?: number | null;
  archivedAt?: number | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  inventoryTransaction?: {
    __typename?: "InventoryTransaction";
    id: string;
  } | null;
  asset?: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  } | null;
};

export type ReleaseDistributorMessagesContextFieldsFragment = {
  __typename?: "Release";
  id: string;
};

export type ReleaseFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  deletedAt?: number | null;
  createdAt: number;
  syncVersion: number;
  total?: string | null;
  receiptPostedAt?: number | null;
  poExists?: boolean | null;
  useSourceSystemPO: boolean;
  poNumber?: string | null;
  submittedAt?: number | null;
  rejectedAt?: number | null;
  rejectionReason?: string | null;
  status: ReleaseStatus;
  time?: number | null;
  timeTBD: boolean;
  includeServices: Array<ServiceType>;
  taxRate?: string | null;
  taxAmount?: string | null;
  taxUnadjustedAmount?: string | null;
  customTaxAmount?: string | null;
  taxVariance?: string | null;
  taxType?: TaxType | null;
  netAmount: string;
  taxableNetAmount: string;
  notes?: string | null;
  description?: string | null;
  paymentTerm: number;
  hasMissingCostCodes: boolean;
  hasMissingExternalCodes: boolean;
  version: number;
  exportedAt?: number | null;
  exportedVersion?: number | null;
  requiresInventoryReceipt?: boolean | null;
  updatedAt?: number | null;
  receiptPostedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  originalReassignedRelease?: {
    __typename?: "Release";
    id: string;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
  } | null;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    createdAt: number;
    poNumber?: string | null;
    poLink?: { __typename?: "POLink"; id: string } | null;
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    externalCode?: string | null;
    taxExempt: boolean;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      email: string;
      receivesOrderNotifications: boolean;
    }>;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    taxExempt: boolean;
    preferredInventoryInflows: boolean;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: { __typename?: "OrgLocation"; id: string };
    team: Array<{ __typename?: "User"; id: string }>;
    permissions: {
      __typename?: "ProjectPermissions";
      report: AuthorizationStatus;
    };
  } | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  submittedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  rejectedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  permissions: {
    __typename?: "ReleasePermissions";
    edit: AuthorizationStatus;
    submit: AuthorizationStatus;
    submitDirectly: AuthorizationStatus;
    approve: AuthorizationStatus;
    archive: AuthorizationStatus;
    cancel: AuthorizationStatus;
  };
  siteContact?: {
    __typename?: "SiteContact";
    id: string;
    name?: string | null;
    phone?: string | null;
    role?: string | null;
    archivedAt?: number | null;
  } | null;
  items: Array<{
    __typename?: "ReleaseItem";
    id: string;
    name: string;
    quantityDecimal: string;
    receivedQuantityDecimal: string;
    notes?: string | null;
    unitPrice?: string | null;
    alternativeFulfillmentTime?: number | null;
    priceEstimated: boolean;
    pricePrenegotiated: boolean;
    isIncluded: boolean;
    deliveryDate?: number | null;
    position?: number | null;
    invoicedQuantity: string;
    invoicedRemainingAdjustment?: string | null;
    deliveryDateTBD: boolean;
    taxable: boolean;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
      mapping?: {
        __typename?: "ProjectTagMapping";
        sourceSystem: SourceSystem;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          code: string;
          name: string;
        } | null;
        externalCostType?: {
          __typename?: "ExternalCostType";
          code: string;
        } | null;
      } | null;
    }>;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    alternativeFulfillmentRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    backorderFromRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        city: string;
        state: string;
      } | null;
      org: { __typename?: "Organization"; id: string; name: string };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
    } | null;
    redelivery?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      issue: {
        __typename?: "ReleaseIssue";
        id: string;
        releaseItem: {
          __typename?: "ReleaseItem";
          id: string;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        };
      };
    } | null;
    buyoutItem?: {
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      description: string;
      unitPrice: string;
      isIncluded: boolean;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        release: { __typename?: "Release"; id: string };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
      }>;
    } | null;
    projectItem?: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
    } | null;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    invoiceItems: Array<{
      __typename?: "InvoicedReleaseItem";
      id: string;
      quantity: string;
      unitPrice?: string | null;
      quantitySoFar?: string | null;
      unexportedQuantitySoFar?: string | null;
    }>;
    poItemLink?: {
      __typename?: "POItemLink";
      id: string;
      poItemExternalId: string;
    } | null;
  }>;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
  deliverySlips: Array<{
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    createdAt: number;
    reviewedAt?: number | null;
    archivedAt?: number | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    inventoryTransaction?: {
      __typename?: "InventoryTransaction";
      id: string;
    } | null;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
  }>;
  deliveryPhotos: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  taxCode?: {
    __typename?: "TaxCode";
    id: string;
    code: string;
    description?: string | null;
    rate: string;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  warehouse?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  sourceWarehouse?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  deliveryAddress?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  quoteDocument?: {
    __typename?: "QuoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  quote?: {
    __typename?: "Quote";
    id: string;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      status: RfqStatus;
    };
  } | null;
  noteDocument?: {
    __typename?: "NoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  poLink?: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  reassignedReleases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
  }>;
  invoices: Array<{
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    number?: string | null;
    vendorDuplicate: boolean;
    orgDuplicate: boolean;
    total?: string | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    archivedAt?: number | null;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    }>;
  }>;
  receipts: Array<{
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    createdAt: number;
    prePaid: boolean;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    }>;
  }>;
  approvals: Array<{
    __typename?: "ReleaseApproval";
    id: string;
    roles: Array<OrgLocationRole>;
    createdAt: number;
    approver: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
  approvalProgress: {
    __typename?: "ReleaseApprovalProgress";
    canApprove: boolean;
    canApproveImmediately: boolean;
    canReject: boolean;
    slots: Array<{
      __typename?: "ReleaseApprovalSlot";
      role?: OrgLocationRole | null;
      ineffectual: boolean;
      approvals: Array<{
        __typename?: "ReleaseApproval";
        id: string;
        roles: Array<OrgLocationRole>;
        createdAt: number;
        approver: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
    costType?: {
      __typename?: "CostType";
      id: string;
      description: string;
    } | null;
  };
  vendorContacts: Array<{
    __typename?: "VendorContact";
    id: string;
    name: string;
    cellPhone: string;
    cellPhoneExtension?: string | null;
    email: string;
    receivesQuoteNotifications: boolean;
    receivesBuyoutNotifications: boolean;
    receivesOrderNotifications: boolean;
    receivesInvoiceNotifications: boolean;
  }>;
  orgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      photoUrl?: string | null;
      name: string;
    };
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  groups: Array<{
    __typename?: "ReleaseGroup";
    id: string;
    name: string;
    createdAt: number;
  }>;
  watchers: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  }>;
  complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
};

export type ReleaseIssueResolutionFieldsFragment = {
  __typename?: "ReleaseIssueResolution";
  id: string;
  resolutionType: ReleaseIssueResolutionType;
  comments?: string | null;
  redeliveryReleaseItem?: {
    __typename?: "ReleaseItem";
    id: string;
    alternativeFulfillmentTime?: number | null;
    deliveryDate?: number | null;
    alternativeFulfillmentRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    release: {
      __typename?: "Release";
      id: string;
      time?: number | null;
      sequenceNumber?: number | null;
    };
  } | null;
};

export type ReleaseIssuesFieldsFragment = {
  __typename?: "ReleaseIssue";
  id: string;
  issueType: ReleaseIssueType;
  description?: string | null;
  quantityDecimal: string;
  releaseItem: { __typename?: "ReleaseItem"; id: string };
  photos: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  resolution?: {
    __typename?: "ReleaseIssueResolution";
    id: string;
    resolutionType: ReleaseIssueResolutionType;
    comments?: string | null;
    redeliveryReleaseItem?: {
      __typename?: "ReleaseItem";
      id: string;
      alternativeFulfillmentTime?: number | null;
      deliveryDate?: number | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      release: {
        __typename?: "Release";
        id: string;
        time?: number | null;
        sequenceNumber?: number | null;
      };
    } | null;
  } | null;
};

export type ReleaseItemFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  name: string;
  quantityDecimal: string;
  receivedQuantityDecimal: string;
  notes?: string | null;
  unitPrice?: string | null;
  alternativeFulfillmentTime?: number | null;
  priceEstimated: boolean;
  pricePrenegotiated: boolean;
  isIncluded: boolean;
  deliveryDate?: number | null;
  position?: number | null;
  invoicedQuantity: string;
  invoicedRemainingAdjustment?: string | null;
  deliveryDateTBD: boolean;
  taxable: boolean;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
    mapping?: {
      __typename?: "ProjectTagMapping";
      sourceSystem: SourceSystem;
      externalCostCode?: {
        __typename?: "ExternalCostCode";
        code: string;
        name: string;
      } | null;
      externalCostType?: {
        __typename?: "ExternalCostType";
        code: string;
      } | null;
    } | null;
  }>;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  alternativeFulfillmentRelease?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
  } | null;
  backorderFromRelease?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    address?: {
      __typename?: "Address";
      id: string;
      city: string;
      state: string;
    } | null;
    org: { __typename?: "Organization"; id: string; name: string };
  } | null;
  sourceWarehouse?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
  } | null;
  redelivery?: {
    __typename?: "ReleaseIssueResolution";
    id: string;
    issue: {
      __typename?: "ReleaseIssue";
      id: string;
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      };
    };
  } | null;
  buyoutItem?: {
    __typename?: "BuyoutItem";
    id: string;
    quantityDecimal: string;
    description: string;
    unitPrice: string;
    isIncluded: boolean;
    releaseItems: Array<{
      __typename?: "ReleaseItem";
      id: string;
      quantityDecimal: string;
      release: { __typename?: "Release"; id: string };
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
    }>;
  } | null;
  projectItem?: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      quantityDecimal: string;
      orderedQuantity: string;
      duplicateCount: number;
      unitPrice?: string | null;
      notes?: string | null;
      position?: number | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    }>;
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  costType?: {
    __typename?: "CostType";
    id: string;
    code: string;
    description: string;
  } | null;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  invoiceItems: Array<{
    __typename?: "InvoicedReleaseItem";
    id: string;
    quantity: string;
    unitPrice?: string | null;
    quantitySoFar?: string | null;
    unexportedQuantitySoFar?: string | null;
  }>;
  poItemLink?: {
    __typename?: "POItemLink";
    id: string;
    poItemExternalId: string;
  } | null;
};

export type ReleaseMessagesContextFieldsFragment = {
  __typename?: "Release";
  id: string;
};

export type ReleasePartialFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  poNumber?: string | null;
  description?: string | null;
  syncVersion: number;
  poExists?: boolean | null;
  isInvoiced: boolean;
  useSourceSystemPO: boolean;
  createdAt: number;
  rejectionReason?: string | null;
  status: ReleaseStatus;
  time?: number | null;
  timeTBD: boolean;
  version: number;
  includeServices: Array<ServiceType>;
  deletedAt?: number | null;
  taxRate?: string | null;
  total?: string | null;
  hasMissingCostCodes: boolean;
  exportedAt?: number | null;
  exportedVersion?: number | null;
  receiptPostedAt?: number | null;
  requiresInventoryReceipt?: boolean | null;
  isBackorder: boolean;
  itemCount: number;
  submittedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  rejectedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  permissions: {
    __typename?: "ReleasePermissions";
    edit: AuthorizationStatus;
    submit: AuthorizationStatus;
    submitDirectly: AuthorizationStatus;
    approve: AuthorizationStatus;
    archive: AuthorizationStatus;
  };
  siteContact?: {
    __typename?: "SiteContact";
    id: string;
    name?: string | null;
    phone?: string | null;
  } | null;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    createdAt: number;
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    externalCode?: string | null;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      receivesOrderNotifications: boolean;
    }>;
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    location: { __typename?: "OrgLocation"; id: string };
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      photoUrl?: string | null;
      name: string;
    };
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  issues: Array<{ __typename?: "ReleaseIssue"; id: string }>;
  poLink?: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  backorderFrom: Array<{
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
  }>;
  quoteDocument?: {
    __typename?: "QuoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  approvalProgress: {
    __typename?: "ReleaseApprovalProgress";
    canApprove: boolean;
    canApproveImmediately: boolean;
    canReject: boolean;
    slots: Array<{
      __typename?: "ReleaseApprovalSlot";
      role?: OrgLocationRole | null;
      ineffectual: boolean;
      approvals: Array<{
        __typename?: "ReleaseApproval";
        id: string;
        roles: Array<OrgLocationRole>;
        createdAt: number;
        approver: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
  approvals: Array<{
    __typename?: "ReleaseApproval";
    id: string;
    roles: Array<OrgLocationRole>;
    createdAt: number;
    approver: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
  };
  deliverySlips: Array<{
    __typename?: "DeliverySlip";
    id: string;
    archivedAt?: number | null;
  }>;
  noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
  invoices: Array<{
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    issueDate?: number | null;
    archivedAt?: number | null;
  }>;
  receipts: Array<{
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    issueDate?: number | null;
    archivedAt?: number | null;
  }>;
  groups: Array<{ __typename?: "ReleaseGroup"; id: string; name: string }>;
  warehouse?: { __typename?: "Warehouse"; id: string; name: string } | null;
  sourceWarehouse?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
  } | null;
};

export type ReleaseProjectFieldsFragment = {
  __typename?: "Project";
  usesProjectCostCodes: boolean;
  id: string;
  name: string;
  status: ProjectStatus;
  budget?: string | null;
  deletedAt?: number | null;
  startDate?: number | null;
  percentageOfBudgetSpent?: string | null;
  jobNumber?: string | null;
  taxExempt: boolean;
  poJobNumber?: string | null;
  restrictCostCodes: boolean;
  preferredInventoryInflows: boolean;
  items: Array<{
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      quantityDecimal: string;
      orderedQuantity: string;
      duplicateCount: number;
      unitPrice?: string | null;
      notes?: string | null;
      position?: number | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    }>;
    buyoutItems: Array<{
      __typename?: "BuyoutItem";
      id: string;
      expirationDate?: number | null;
      description: string;
      quantityDecimal: string;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      buyout: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
          };
        };
      };
    }>;
  }>;
  releaseInstructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  aggregatedZones: Array<{
    __typename?: "AggregatedZone";
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    costCodes: Array<{
      __typename?: "AggregatedCostCode";
      costCode?: { __typename?: "CostCode"; id: string } | null;
      items: Array<{
        __typename?: "AggregatedItem";
        projectItem: { __typename?: "ProjectItem"; id: string };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          position?: number | null;
          notes?: string | null;
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          tags: Array<{ __typename?: "ProjectTag"; id: string }>;
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
          uom: { __typename?: "UOM"; id: string };
        }>;
        buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
      }>;
    }>;
  }>;
  mappings: Array<{
    __typename?: "SourceSystemMapping";
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
  externalProjects: Array<{
    __typename?: "ExternalProject";
    id: string;
    sourceSystem: SourceSystem;
  }>;
  costCodes?: Array<{
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  }> | null;
  estimatedItemGroups: Array<{
    __typename?: "EstimatedItemGroup";
    estimatedItemCount: number;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  }>;
  team: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl?: string | null;
  }>;
  address: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  location: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      costCodes: Array<{
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }>;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  zones: Array<{
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  }>;
  permissions: {
    __typename?: "ProjectPermissions";
    edit: AuthorizationStatus;
    createTag: AuthorizationStatus;
    report: AuthorizationStatus;
  };
};

export type ReleaseProjectItemFieldsFragment = {
  __typename?: "ProjectItem";
  id: string;
  buyoutItems: Array<{
    __typename?: "BuyoutItem";
    id: string;
    expirationDate?: number | null;
    description: string;
    quantityDecimal: string;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    buyout: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
        };
      };
    };
  }>;
  material: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  };
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    notes?: string | null;
    position?: number | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  }>;
  estimateUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
};

export type ReleaseReassignmentFieldsFragment = {
  __typename?: "Release";
  id: string;
  status: ReleaseStatus;
};

export type ReleaseSelectorFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  poNumber?: string | null;
  time?: number | null;
  netAmount: string;
  total?: string | null;
  status: ReleaseStatus;
  version: number;
  taxRate?: string | null;
  taxAmount?: string | null;
  customTaxAmount?: string | null;
  taxVariance?: string | null;
  taxType?: TaxType | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
  } | null;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      photoUrl?: string | null;
      name: string;
    };
  } | null;
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
  };
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  taxCode?: {
    __typename?: "TaxCode";
    id: string;
    code: string;
    description?: string | null;
    rate: string;
  } | null;
};

export type RfqFieldsFragment = {
  __typename?: "Rfq";
  id: string;
  createdAt: number;
  clientIdentifier: string;
  includeServices: Array<ServiceType>;
  status: RfqStatus;
  deletedAt?: number | null;
  description?: string | null;
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    budget?: string | null;
    deletedAt?: number | null;
    startDate?: number | null;
    percentageOfBudgetSpent?: string | null;
    jobNumber?: string | null;
    taxExempt: boolean;
    poJobNumber?: string | null;
    restrictCostCodes: boolean;
    usesProjectCostCodes: boolean;
    preferredInventoryInflows: boolean;
    aggregatedZones: Array<{
      __typename?: "AggregatedZone";
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      costCodes: Array<{
        __typename?: "AggregatedCostCode";
        costCode?: { __typename?: "CostCode"; id: string } | null;
        items: Array<{
          __typename?: "AggregatedItem";
          projectItem: { __typename?: "ProjectItem"; id: string };
          estimatedItems: Array<{
            __typename?: "EstimatedItem";
            id: string;
            quantityDecimal: string;
            orderedQuantity: string;
            duplicateCount: number;
            unitPrice?: string | null;
            position?: number | null;
            notes?: string | null;
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            tags: Array<{ __typename?: "ProjectTag"; id: string }>;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
            } | null;
            uom: { __typename?: "UOM"; id: string };
          }>;
          buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
        }>;
      }>;
    }>;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
    }>;
    estimatedItemGroups: Array<{
      __typename?: "EstimatedItemGroup";
      estimatedItemCount: number;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    team: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    }>;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        costCodes: Array<{
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }>;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    items: Array<{
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    }>;
    zones: Array<{
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    }>;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
    permissions: {
      __typename?: "ProjectPermissions";
      edit: AuthorizationStatus;
      createTag: AuthorizationStatus;
      report: AuthorizationStatus;
    };
  };
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  requisition?: { __typename?: "Requisition"; id: string } | null;
  items: Array<{
    __typename?: "RfqItem";
    id: string;
    description: string;
    quantityDecimal: string;
    position?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    };
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  }>;
  targets: Array<{
    __typename?: "RfqPreferredVendor";
    id: string;
    sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    contacts: Array<{ __typename?: "VendorContact"; id: string }>;
  }>;
  nextRevision?: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
    status: RfqStatus;
  } | null;
  quotes: Array<{
    __typename?: "Quote";
    id: string;
    status: QuoteStatus;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      subtotal: string;
      poExists?: boolean | null;
      description?: string | null;
      createdAt: number;
      status: BuyoutStatus;
      includedServices: Array<ServiceType>;
      poNumber?: string | null;
      version: number;
      paymentTermDays?: number | null;
      notes?: string | null;
      taxAmount?: string | null;
      taxRate?: string | null;
      netAmount: string;
      taxType?: TaxType | null;
      customTaxAmount?: string | null;
      additionalChargesAllowance?: string | null;
      exportedAt?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      releases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
      quote?: {
        __typename?: "Quote";
        id: string;
        notes?: string | null;
        expirationDate?: number | null;
        paymentTermDays?: number | null;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        discount?: {
          __typename?: "Discount";
          amount?: string | null;
          percentage?: string | null;
        } | null;
        rfq: {
          __typename?: "Rfq";
          id: string;
          clientIdentifier: string;
          includeServices: Array<ServiceType>;
          status: RfqStatus;
          createdBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          };
          quotes: Array<{
            __typename?: "Quote";
            id: string;
            buyout?: {
              __typename?: "Buyout";
              id: string;
              clientIdentifier: string;
              status: BuyoutStatus;
              sellerOrgLocation: {
                __typename?: "OrgLocation";
                id: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              };
            } | null;
          }>;
        };
      } | null;
      quoteDocument?: {
        __typename?: "QuoteDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        taxExempt: boolean;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesBuyoutNotifications: boolean;
        }>;
      } | null;
      releaseType: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        status: ProjectStatus;
        startDate?: number | null;
        jobNumber?: string | null;
        taxExempt: boolean;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: { __typename?: "OrgLocation"; id: string };
        permissions: {
          __typename?: "ProjectPermissions";
          report: AuthorizationStatus;
        };
      };
      items: Array<{
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        unitPrice: string;
        requestedUnitPrice?: string | null;
        releasedAmount?: string | null;
        completedAmount?: string | null;
        expirationDate?: number | null;
        notes?: string | null;
        isIncluded: boolean;
        position?: number | null;
        description: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          estimatedItems: Array<{
            __typename?: "EstimatedItem";
            id: string;
            quantityDecimal: string;
            orderedQuantity: string;
            duplicateCount: number;
            unitPrice?: string | null;
            notes?: string | null;
            position?: number | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
            tags: Array<{
              __typename?: "ProjectTag";
              id: string;
              name: string;
              color: string;
              deletedAt?: number | null;
              hasMapping: boolean;
            }>;
            manufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
              inUse: boolean;
              mappings: Array<{
                __typename?: "SourceSystemMapping";
                externalId: string;
                sourceSystem: SourceSystem;
              }>;
            } | null;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
            } | null;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
        };
        rfqItem?: {
          __typename?: "RfqItem";
          id: string;
          description: string;
          quantityDecimal: string;
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          manufacturer: {
            __typename?: "Manufacturer";
            url?: string | null;
            id: string;
            name: string;
          };
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          unitPrice?: string | null;
          release: {
            __typename?: "Release";
            id: string;
            status: ReleaseStatus;
            sequenceNumber?: number | null;
            time?: number | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              name: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
          };
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
        }>;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      }>;
      standaloneReleaseItems: Array<{
        __typename?: "BuyoutStandaloneReleaseItem";
        totalQuantity: string;
        unitPrice: string;
        orderedSoFar: string;
        receivedSoFar: string;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        orgMaterial: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        };
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          unitPrice?: string | null;
          release: {
            __typename?: "Release";
            id: string;
            status: ReleaseStatus;
            sequenceNumber?: number | null;
            time?: number | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              name: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
          };
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
        }>;
      }>;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      taxCode?: {
        __typename?: "TaxCode";
        id: string;
        code: string;
        description?: string | null;
        rate: string;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      poLink?: {
        __typename?: "POLink";
        id: string;
        format: PoFormat;
        sourceSystem: SourceSystem;
        externalWarehouseId?: string | null;
        syncing: boolean;
        syncedVersion: number;
        syncedAt?: number | null;
        createdAt: number;
        autoSync: boolean;
        autoSyncError?: string | null;
        autoSyncFailureCount: number;
        retroactive: boolean;
        immutable: boolean;
        failedAt?: number | null;
        failedMessage?: string | null;
        validationErrors: Array<PoValidationError>;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      vendorContacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
  }>;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
  noteDocument?: {
    __typename?: "NoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  permissions: {
    __typename?: "RfqPermissions";
    archive: AuthorizationStatus;
    edit: AuthorizationStatus;
    submit: AuthorizationStatus;
  };
};

export type RfqItemFieldsFragment = {
  __typename?: "RfqItem";
  id: string;
  description: string;
  quantityDecimal: string;
  position?: number | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  projectItem: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      quantityDecimal: string;
      orderedQuantity: string;
      duplicateCount: number;
      unitPrice?: string | null;
      notes?: string | null;
      position?: number | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
  };
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
};

export type RfqQuotesAuxiliaryQuoteItemFieldsFragment = {
  __typename?: "AuxiliaryQuoteItem";
  id: string;
  unitPrice?: string | null;
  description?: string | null;
  notes?: string | null;
  expirationDate?: number | null;
  quantityDecimal?: string | null;
  leadTimeDays?: number | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
};

export type RfqQuotesFieldsFragment = {
  __typename?: "Rfq";
  id: string;
  includeServices: Array<ServiceType>;
  clientIdentifier: string;
  status: RfqStatus;
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    taxExempt: boolean;
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    permissions: {
      __typename?: "ProjectPermissions";
      report: AuthorizationStatus;
    };
  };
  nextRevision?: { __typename?: "Rfq"; id: string } | null;
  targets: Array<{
    __typename?: "RfqPreferredVendor";
    id: string;
    sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
  }>;
  items: Array<{
    __typename?: "RfqItem";
    id: string;
    description: string;
    quantityDecimal: string;
    position?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    };
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  }>;
  quotes: Array<{
    __typename?: "Quote";
    id: string;
    expirationDate?: number | null;
    notes?: string | null;
    status: QuoteStatus;
    paymentTermDays?: number | null;
    submittedDate?: number | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesQuoteNotifications: boolean;
      }>;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        name: string;
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    itemGroups: Array<{
      __typename?: "QuoteItemGroup";
      rfqItem: { __typename?: "RfqItem"; id: string };
      quoteItems: Array<{
        __typename?: "QuoteItem";
        id: string;
        unitPrice?: string | null;
        status: QuoteStatus;
        description?: string | null;
        notes?: string | null;
        expirationDate?: number | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    auxiliaryItems: Array<{
      __typename?: "AuxiliaryQuoteItem";
      id: string;
      unitPrice?: string | null;
      description?: string | null;
      notes?: string | null;
      expirationDate?: number | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
    servicePriceSheet?: {
      __typename?: "ServicePriceSheet";
      id: string;
      prices: Array<{
        __typename?: "ServicePrice";
        id: string;
        name: ServiceName;
        productCategory: ServiceProductCategoryName;
        type: ServiceType;
        regularHoursAmount?: string | null;
        offHoursAmount?: string | null;
        regularHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        offHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
    } | null;
  }>;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
  permissions: {
    __typename?: "RfqPermissions";
    archive: AuthorizationStatus;
    edit: AuthorizationStatus;
    exportQuotes: AuthorizationStatus;
  };
};

export type RfqQuotesPreferredVendorFieldsFragment = {
  __typename?: "OrgPreferredVendor";
  id: string;
  taxExempt: boolean;
  contacts: Array<{
    __typename?: "VendorContact";
    id: string;
    name: string;
    receivesQuoteNotifications: boolean;
  }>;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    name: string;
    id: string;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  };
};

export type RfqQuotesQuoteFieldsFragment = {
  __typename?: "Quote";
  id: string;
  expirationDate?: number | null;
  notes?: string | null;
  status: QuoteStatus;
  paymentTermDays?: number | null;
  submittedDate?: number | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  discount?: {
    __typename?: "Discount";
    amount?: string | null;
    percentage?: string | null;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    taxExempt: boolean;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      receivesQuoteNotifications: boolean;
    }>;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      name: string;
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
  } | null;
  itemGroups: Array<{
    __typename?: "QuoteItemGroup";
    rfqItem: { __typename?: "RfqItem"; id: string };
    quoteItems: Array<{
      __typename?: "QuoteItem";
      id: string;
      unitPrice?: string | null;
      status: QuoteStatus;
      description?: string | null;
      notes?: string | null;
      expirationDate?: number | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
    }>;
  }>;
  auxiliaryItems: Array<{
    __typename?: "AuxiliaryQuoteItem";
    id: string;
    unitPrice?: string | null;
    description?: string | null;
    notes?: string | null;
    expirationDate?: number | null;
    quantityDecimal?: string | null;
    leadTimeDays?: number | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  }>;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
  } | null;
  servicePriceSheet?: {
    __typename?: "ServicePriceSheet";
    id: string;
    prices: Array<{
      __typename?: "ServicePrice";
      id: string;
      name: ServiceName;
      productCategory: ServiceProductCategoryName;
      type: ServiceType;
      regularHoursAmount?: string | null;
      offHoursAmount?: string | null;
      regularHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      offHoursUOM: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
  } | null;
};

export type RfqQuotesQuoteItemFieldsFragment = {
  __typename?: "QuoteItem";
  id: string;
  unitPrice?: string | null;
  status: QuoteStatus;
  description?: string | null;
  notes?: string | null;
  expirationDate?: number | null;
  quantityDecimal?: string | null;
  leadTimeDays?: number | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  masterSku?: {
    __typename?: "Sku";
    id: string;
    name: string;
    manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  orgCatalogSku?: {
    __typename?: "OrgCatalogSku";
    id: string;
    name: string;
  } | null;
};

export type RfqQuotesQuoteItemGroupFieldsFragment = {
  __typename?: "QuoteItemGroup";
  rfqItem: { __typename?: "RfqItem"; id: string };
  quoteItems: Array<{
    __typename?: "QuoteItem";
    id: string;
    unitPrice?: string | null;
    status: QuoteStatus;
    description?: string | null;
    notes?: string | null;
    expirationDate?: number | null;
    quantityDecimal?: string | null;
    leadTimeDays?: number | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    masterSku?: {
      __typename?: "Sku";
      id: string;
      name: string;
      manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    orgCatalogSku?: {
      __typename?: "OrgCatalogSku";
      id: string;
      name: string;
    } | null;
  }>;
};

export type RfqVendorFieldsFragment = {
  __typename?: "Quote";
  id: string;
  clientIdentifier: string;
  status: QuoteStatus;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  };
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesQuoteNotifications: boolean;
    }>;
  } | null;
};

export type RfqsRfqFieldsFragment = {
  __typename?: "Rfq";
  id: string;
  clientIdentifier: string;
  deletedAt?: number | null;
  status: RfqStatus;
  createdAt: number;
  numBiddersRfqSentTo: number;
  numBiddersRfqViewedBy: number;
  description?: string | null;
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    jobNumber?: string | null;
  };
  items: Array<{
    __typename?: "RfqItem";
    id: string;
    rfqId: string;
    quantityDecimal: string;
  }>;
  quotes: Array<{
    __typename?: "Quote";
    id: string;
    status: QuoteStatus;
    submittedDate?: number | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      poNumber?: string | null;
    } | null;
    releases: Array<{
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      status: ReleaseStatus;
      deletedAt?: number | null;
    }>;
  }>;
  nextRevision?: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
  } | null;
  previousRevision?: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
  } | null;
  noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
  permissions: {
    __typename?: "RfqPermissions";
    archive: AuthorizationStatus;
    edit: AuthorizationStatus;
    submit: AuthorizationStatus;
  };
};

export type ServicePriceFieldsFragment = {
  __typename?: "ServicePrice";
  id: string;
  name: ServiceName;
  productCategory: ServiceProductCategoryName;
  type: ServiceType;
  regularHoursAmount?: string | null;
  offHoursAmount?: string | null;
  regularHoursUOM: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  offHoursUOM: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
};

export type SkuFieldsFragment = {
  __typename?: "Sku";
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  brand: string;
  manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
  product: {
    __typename?: "Product";
    id: string;
    categories: Array<{ __typename?: "ProductCategory"; id: string }>;
  };
};

export type SmsVerificationFieldsFragment = {
  __typename?: "SMSVerification";
  id: string;
  remainingDeliveries: number;
  remainingAttempts: number;
  completedAt?: number | null;
};

export type StartupDataFieldsFragment = {
  __typename?: "User";
  id: string;
  enterprise?: {
    __typename?: "Enterprise";
    id: string;
    name: string;
    orgs: Array<{
      __typename?: "Organization";
      id: string;
      name: string;
      notificationCount: number;
    }>;
  } | null;
  org: {
    __typename?: "Organization";
    id: string;
    settings?: {
      __typename?: "OrgSettings";
      releaseApproval: {
        __typename?: "ReleaseApprovalSettings";
        enabled: boolean;
        standaloneOnly: boolean;
        sourceWarehouse: boolean;
        reserved: boolean;
        exemptRoles: Array<OrgLocationRole>;
        approverRoles: Array<OrgLocationRole>;
        submitterRoles: Array<OrgLocationRole>;
        requiredApprovals: number;
        requiredApproverRoles: Array<OrgLocationRole>;
      };
      releases?: {
        __typename?: "ReleaseSettings";
        poNumbering: PoNumberingMode;
        notifications: ReleaseNotificationsMode;
        useSourceSystemPOs: boolean;
        poNumberSuffixPreview: string;
        defaultWarehouse?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
        } | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
      } | null;
      invoices: {
        __typename?: "InvoiceSettings";
        localPart?: string | null;
        domain: string;
        defaultPaymentTerm?: number | null;
        requireCostCodesDuringApproval: boolean;
        approval?: {
          __typename?: "InvoiceApprovalSettings";
          roles: Array<{
            __typename?: "InvoiceApprovalRole";
            role: OrgLocationRole;
            maxAmount?: string | null;
            notificationThreshold?: string | null;
          }>;
        } | null;
      };
      integrations: {
        __typename?: "IntegrationSettings";
        org: { __typename?: "Organization"; id: string };
        accounting: Array<{
          __typename?: "AccountingSettings";
          id: string;
          toJobLedgerAccount?: string | null;
          distributeAdditionalCharges?: boolean | null;
          materialCostType?: string | null;
          enabled: boolean;
          integration: IntegrationType;
          postInventoryReceipts?: boolean | null;
          inventoryReceiptLedgerAccount?: string | null;
          progress: {
            __typename?: "IntegrationMappingProgress";
            costCodes: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            orgMaterials: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            projects: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            vendors: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            releaseTypes: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            costTypes: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
          };
          additionalChargesCostCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
          taxAuthorities: Array<{
            __typename?: "TaxAuthority";
            code: string;
            rate: string;
          }>;
        }>;
        sourceSystems: Array<{
          __typename?: "SourceSystemSettings";
          defaultAutoSync?: boolean | null;
          postInventoryReceipts?: boolean | null;
          autoPostPOs?: boolean | null;
          autoPostInvoices?: boolean | null;
          autoExportApprovedInvoices?: boolean | null;
          autoCloseProjectsByJobStatus: boolean;
          system: SourceSystem;
          companyName: string;
          companyId: string;
          connected: boolean;
          wbsTags?: boolean | null;
          poNameTemplate?: string | null;
          poDescriptionTemplate?: string | null;
          invoiceDescriptionTemplate?: string | null;
          accountsPayableLedgerAccount?: {
            __typename?: "ExternalLedgerAccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
            subaccounts?: Array<{
              __typename?: "ExternalLedgerSubaccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
            }> | null;
          } | null;
          toJobLedgerAccount?: {
            __typename?: "ExternalLedgerAccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
            subaccounts?: Array<{
              __typename?: "ExternalLedgerSubaccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
            }> | null;
          } | null;
          toWarehouseLedgerAccount?: {
            __typename?: "ExternalLedgerAccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
            subaccounts?: Array<{
              __typename?: "ExternalLedgerSubaccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
            }> | null;
          } | null;
          materialCostType?: {
            __typename?: "ExternalCostType";
            externalId: string;
            sourceId: string;
            code: string;
            name: string;
            collidesWith?: {
              __typename?: "CostType";
              id: string;
              code: string;
              description: string;
            } | null;
          } | null;
          progress: {
            __typename?: "IntegrationMappingProgress";
            costCodes: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            orgMaterials: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            projects: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            vendors: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            releaseTypes: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
            costTypes: {
              __typename?: "MappingProgress";
              mapped: number;
              total: number;
            };
          };
          inventoryReceiptLedgerAccount?: {
            __typename?: "ExternalLedgerAccount";
            externalId: string;
            name: string;
          } | null;
          customPOTemplates: Array<{
            __typename?: "SourceSystemSettingCustomTemplate";
            name: string;
            template: string;
          }>;
          customInvoiceTemplates: Array<{
            __typename?: "SourceSystemSettingCustomTemplate";
            name: string;
            template: string;
          }>;
        }>;
      };
      display?: {
        __typename?: "DisplaySettings";
        enableManufacturers: boolean;
        itemCostTypes: boolean;
        enableAdditionalCharges: boolean;
        requireProjectAssignment: boolean;
        enableTaxVariance: boolean;
        defaultItemized?: boolean | null;
        receiptDefaultItemized?: boolean | null;
        usesProjectCostCodes: boolean;
        appendExternalCode?: boolean | null;
        currency: {
          __typename?: "Currency";
          id: string;
          code: string;
          name: string;
          symbol: string;
        };
      } | null;
      inventory: { __typename?: "InventorySettings"; enabled: boolean };
      projectSettings: {
        __typename?: "ProjectSettings";
        defaultTeam: Array<{
          __typename?: "OrgLocationDefaultProjectTeam";
          orgLocation: { __typename?: "OrgLocation"; id: string };
          users: Array<{ __typename?: "User"; id: string }>;
        }>;
      };
    } | null;
    costCodes: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      ledgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        sourceSystem: SourceSystem;
        name: string;
        number: string;
      } | null;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
    costTypes: Array<{
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
    uoms: Array<{
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    }>;
    warehouses: Array<{
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    }>;
    invoiceFolders: Array<{
      __typename?: "InvoiceFolder";
      id: string;
      name: string;
      createdAt: number;
    }>;
    releaseGroups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    locations: Array<{
      __typename?: "OrgLocation";
      id: string;
      name: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      permissions: {
        __typename?: "OrgLocationPermissions";
        createProject: AuthorizationStatus;
        createInvoice: AuthorizationStatus;
        createDeliverySlip: AuthorizationStatus;
        createQuoteDocument: AuthorizationStatus;
        submitReleaseDirectly: AuthorizationStatus;
        createRelease: AuthorizationStatus;
        createBuyout: AuthorizationStatus;
        createRfq: AuthorizationStatus;
        reserveRelease: AuthorizationStatus;
      };
    }>;
    complianceGroups: Array<{
      __typename?: "ComplianceGroup";
      id: string;
      code: string;
      description?: string | null;
      notes?: string | null;
    }>;
  };
};

export type TagFieldsFragment = {
  __typename?: "ProjectTag";
  id: string;
  name: string;
  color: string;
  deletedAt?: number | null;
  hasMapping: boolean;
};

export type TagExtendedFieldsFragment = {
  __typename?: "ProjectTag";
  id: string;
  name: string;
  color: string;
  deletedAt?: number | null;
  hasMapping: boolean;
  mapping?: {
    __typename?: "ProjectTagMapping";
    sourceSystem: SourceSystem;
    externalCostCode?: {
      __typename?: "ExternalCostCode";
      code: string;
      name: string;
    } | null;
    externalCostType?: { __typename?: "ExternalCostType"; code: string } | null;
  } | null;
};

export type TagSummaryFieldsFragment = {
  __typename?: "ProjectTag";
  id: string;
  name: string;
  color: string;
  deletedAt?: number | null;
  hasMapping: boolean;
};

export type UomFieldsFragment = {
  __typename?: "UOM";
  id: string;
  mnemonic?: string | null;
  singularDescription?: string | null;
  pluralDescription: string;
  alternativeRefs: Array<string>;
};

export type UpdateEstimatesWithFileOutputFieldsFragment = {
  __typename?: "UpdateEstimatesWithFileOutput";
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    zone?: { __typename?: "Zone"; id: string } | null;
  }>;
  errors: Array<{
    __typename?: "ImportError";
    lineNumber: number;
    errorMessage: string;
  }>;
};

export type UpdateVendorReleaseFieldsFragment = {
  __typename?: "Release";
  id: string;
  total?: string | null;
  netAmount: string;
  version: number;
  sequenceNumber?: number | null;
  amount?: string | null;
  poNumber?: string | null;
  includeServices: Array<ServiceType>;
  status: ReleaseStatus;
  time?: number | null;
  taxRate?: string | null;
  taxAmount?: string | null;
  taxUnadjustedAmount?: string | null;
  customTaxAmount?: string | null;
  notes?: string | null;
  paymentTerm: number;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      email: string;
      cellPhone: string;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
    }>;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  items: Array<{
    __typename?: "ReleaseItem";
    id: string;
    name: string;
    quantityDecimal: string;
    receivedQuantityDecimal: string;
    requestedQuantity: number;
    unitPrice?: string | null;
    isIncluded: boolean;
    position?: number | null;
    notes?: string | null;
    alternativeFulfillmentTime?: number | null;
    alternativeFulfillmentQuantityDecimal?: string | null;
    pricePrefilled: boolean;
    deliveryDate?: number | null;
    pricePrenegotiated: boolean;
    addedByVendor: boolean;
    deliveryDateTBD: boolean;
    taxable: boolean;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    buyoutItem?: {
      __typename?: "BuyoutItem";
      id: string;
      description: string;
      quantityDecimal: string;
      unitPrice: string;
      notes?: string | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
    } | null;
    projectItem?: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    } | null;
    backorderFromRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    redelivery?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      issue: {
        __typename?: "ReleaseIssue";
        id: string;
        releaseItem: {
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        };
      };
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    requestedUom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    alternativeFulfillmentRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      time?: number | null;
    } | null;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
  }>;
  issues: Array<{
    __typename?: "ReleaseIssue";
    id: string;
    issueType: ReleaseIssueType;
    description?: string | null;
    quantityDecimal: string;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    photos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolution?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      resolutionType: ReleaseIssueResolutionType;
      comments?: string | null;
      redeliveryReleaseItem?: {
        __typename?: "ReleaseItem";
        id: string;
        alternativeFulfillmentTime?: number | null;
        deliveryDate?: number | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        release: {
          __typename?: "Release";
          id: string;
          time?: number | null;
          sequenceNumber?: number | null;
        };
      } | null;
    } | null;
  }>;
};

export type UserViewerFieldsFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  sessionId?: string | null;
  lastName: string;
  email: string;
  cellPhone: string;
  photoUrl?: string | null;
  isPhoneValidated: boolean;
  getSmsNotifications: boolean;
  getEmailNotifications: boolean;
  systemRoles: Array<SystemRole>;
  orgRoles: Array<OrgRole>;
  isExternal: boolean;
  enterpriseRoles: Array<EnterpriseRole>;
  authId: string;
  locationRoles: Array<{
    __typename?: "LocationRole";
    orgLocID: string;
    role: OrgLocationRole;
  }>;
  org: {
    __typename?: "Organization";
    id: string;
    name: string;
    photoUrl?: string | null;
    hqAddress?: { __typename?: "Address"; id: string; country: string } | null;
  };
};

export type UsersUserFieldsFragment = {
  __typename?: "User";
  id: string;
  email: string;
  cellPhone: string;
  createdAt: number;
  firstName: string;
  lastName: string;
  photoUrl?: string | null;
  isEmailValidated: boolean;
  isOrgAdmin?: boolean | null;
  orgRoles: Array<OrgRole>;
  enterpriseRoles: Array<EnterpriseRole>;
  org: {
    __typename?: "Organization";
    id: string;
    locations: Array<{ __typename?: "OrgLocation"; id: string; name: string }>;
  };
  locationRoles: Array<{
    __typename?: "LocationRole";
    orgLocID: string;
    role: OrgLocationRole;
  }>;
};

export type VendorContactFieldsFragment = {
  __typename?: "VendorContact";
  id: string;
  name: string;
  cellPhone: string;
  cellPhoneExtension?: string | null;
  email: string;
  receivesQuoteNotifications: boolean;
  receivesBuyoutNotifications: boolean;
  receivesOrderNotifications: boolean;
  receivesInvoiceNotifications: boolean;
};

export type ViewerOrgFieldsFragment = {
  __typename?: "Organization";
  id: string;
  name: string;
  ein?: string | null;
  phone?: string | null;
  photoUrl?: string | null;
  hqAddress?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  settings?: {
    __typename?: "OrgSettings";
    invoices: {
      __typename?: "InvoiceSettings";
      localPart?: string | null;
      domain: string;
      defaultPaymentTerm?: number | null;
    };
    releases?: {
      __typename?: "ReleaseSettings";
      poNumbering: PoNumberingMode;
      notifications: ReleaseNotificationsMode;
    } | null;
    display?: {
      __typename?: "DisplaySettings";
      enableManufacturers: boolean;
      currency: {
        __typename?: "Currency";
        id: string;
        code: string;
        name: string;
        symbol: string;
      };
    } | null;
    projectSettings: {
      __typename?: "ProjectSettings";
      defaultTeam: Array<{
        __typename?: "OrgLocationDefaultProjectTeam";
        orgLocation: { __typename?: "OrgLocation"; id: string };
        users: Array<{ __typename?: "User"; id: string }>;
      }>;
    };
  } | null;
  enterprise?: { __typename?: "Enterprise"; id: string } | null;
};

export type ViewerOrgLocationFieldsFragment = {
  __typename?: "OrgLocation";
  id: string;
  name: string;
  address?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  permissions: {
    __typename?: "OrgLocationPermissions";
    createProject: AuthorizationStatus;
    createInvoice: AuthorizationStatus;
    createDeliverySlip: AuthorizationStatus;
    createQuoteDocument: AuthorizationStatus;
    submitReleaseDirectly: AuthorizationStatus;
    createRelease: AuthorizationStatus;
    createBuyout: AuthorizationStatus;
    createRfq: AuthorizationStatus;
    reserveRelease: AuthorizationStatus;
  };
};

export type WarehouseFieldsFragment = {
  __typename?: "Warehouse";
  id: string;
  name: string;
  externalCode?: string | null;
  archivedAt?: number | null;
  address: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
};

export type ZoneExtendedFieldsFragment = {
  __typename?: "Zone";
  id: string;
  name: string;
  inUse: boolean;
  externalSubJob?: {
    __typename?: "ExternalSubJob";
    id: string;
    sourceSystem: SourceSystem;
    externalId: string;
  } | null;
};

export type ZoneFieldsFragment = {
  __typename?: "Zone";
  id: string;
  name: string;
  inUse: boolean;
};

export type AcceptQuoteMutationVariables = Exact<{
  input: AcceptQuoteInput;
  quoteStatus?: InputMaybe<Array<QuoteStatus> | QuoteStatus>;
}>;

export type AcceptQuoteMutation = {
  __typename?: "Mutation";
  acceptQuote: {
    __typename?: "Rfq";
    id: string;
    includeServices: Array<ServiceType>;
    clientIdentifier: string;
    status: RfqStatus;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    nextRevision?: { __typename?: "Rfq"; id: string } | null;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    }>;
    items: Array<{
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      position?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      expirationDate?: number | null;
      notes?: string | null;
      status: QuoteStatus;
      paymentTermDays?: number | null;
      submittedDate?: number | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        taxExempt: boolean;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          receivesQuoteNotifications: boolean;
        }>;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          name: string;
          id: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        };
      } | null;
      itemGroups: Array<{
        __typename?: "QuoteItemGroup";
        rfqItem: { __typename?: "RfqItem"; id: string };
        quoteItems: Array<{
          __typename?: "QuoteItem";
          id: string;
          unitPrice?: string | null;
          status: QuoteStatus;
          description?: string | null;
          notes?: string | null;
          expirationDate?: number | null;
          quantityDecimal?: string | null;
          leadTimeDays?: number | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          masterSku?: {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
          } | null;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          orgCatalogSku?: {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
          } | null;
        }>;
      }>;
      auxiliaryItems: Array<{
        __typename?: "AuxiliaryQuoteItem";
        id: string;
        unitPrice?: string | null;
        description?: string | null;
        notes?: string | null;
        expirationDate?: number | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      }>;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      servicePriceSheet?: {
        __typename?: "ServicePriceSheet";
        id: string;
        prices: Array<{
          __typename?: "ServicePrice";
          id: string;
          name: ServiceName;
          productCategory: ServiceProductCategoryName;
          type: ServiceType;
          regularHoursAmount?: string | null;
          offHoursAmount?: string | null;
          regularHoursUOM: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          offHoursUOM: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }>;
        discount?: {
          __typename?: "Discount";
          amount?: string | null;
          percentage?: string | null;
        } | null;
      } | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
    permissions: {
      __typename?: "RfqPermissions";
      archive: AuthorizationStatus;
      edit: AuthorizationStatus;
      exportQuotes: AuthorizationStatus;
    };
  };
};

export type AddEstimatedItemMutationVariables = Exact<{
  input: AddEstimatedItemInput;
}>;

export type AddEstimatedItemMutation = {
  __typename?: "Mutation";
  addEstimatedItem: {
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    notes?: string | null;
    position?: number | null;
    item: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    };
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  };
};

export type AddToReleaseMutationVariables = Exact<{
  input: AddToReleaseInput;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type AddToReleaseMutation = {
  __typename?: "Mutation";
  addToRelease: Array<{
    __typename?: "ReleaseItem";
    id: string;
    name: string;
    quantityDecimal: string;
    receivedQuantityDecimal: string;
    notes?: string | null;
    unitPrice?: string | null;
    alternativeFulfillmentTime?: number | null;
    priceEstimated: boolean;
    pricePrenegotiated: boolean;
    isIncluded: boolean;
    deliveryDate?: number | null;
    position?: number | null;
    invoicedQuantity: string;
    invoicedRemainingAdjustment?: string | null;
    deliveryDateTBD: boolean;
    taxable: boolean;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
      mapping?: {
        __typename?: "ProjectTagMapping";
        sourceSystem: SourceSystem;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          code: string;
          name: string;
        } | null;
        externalCostType?: {
          __typename?: "ExternalCostType";
          code: string;
        } | null;
      } | null;
    }>;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    alternativeFulfillmentRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    backorderFromRelease?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        city: string;
        state: string;
      } | null;
      org: { __typename?: "Organization"; id: string; name: string };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
    } | null;
    redelivery?: {
      __typename?: "ReleaseIssueResolution";
      id: string;
      issue: {
        __typename?: "ReleaseIssue";
        id: string;
        releaseItem: {
          __typename?: "ReleaseItem";
          id: string;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        };
      };
    } | null;
    buyoutItem?: {
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      description: string;
      unitPrice: string;
      isIncluded: boolean;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        release: { __typename?: "Release"; id: string };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
      }>;
    } | null;
    projectItem?: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
    } | null;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    invoiceItems: Array<{
      __typename?: "InvoicedReleaseItem";
      id: string;
      quantity: string;
      unitPrice?: string | null;
      quantitySoFar?: string | null;
      unexportedQuantitySoFar?: string | null;
    }>;
    poItemLink?: {
      __typename?: "POItemLink";
      id: string;
      poItemExternalId: string;
    } | null;
  }>;
};

export type AddVendorLocationMutationVariables = Exact<{
  input: AddOrgLocationInput;
}>;

export type AddVendorLocationMutation = {
  __typename?: "Mutation";
  addVendorLocation?: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  } | null;
};

export type AddVendorOrgMutationVariables = Exact<{
  input: AddVendorOrgInput;
}>;

export type AddVendorOrgMutation = {
  __typename?: "Mutation";
  addVendorOrg: {
    __typename?: "Organization";
    id: string;
    name: string;
    locations: Array<{
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    }>;
  };
};

export type AddZoneMutationVariables = Exact<{
  input: AddZoneInput;
}>;

export type AddZoneMutation = {
  __typename?: "Mutation";
  addZone: Array<{
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  }>;
};

export type AddZonesMutationVariables = Exact<{
  input: AddZonesInput;
}>;

export type AddZonesMutation = {
  __typename?: "Mutation";
  addZones: Array<{
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  }>;
};

export type ArchiveReleaseMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveReleaseMutation = {
  __typename?: "Mutation";
  archiveRelease: {
    __typename?: "Release";
    id: string;
    deletedAt?: number | null;
  };
};

export type CancelRfqMutationVariables = Exact<{
  rfqID: Scalars["ID"]["input"];
}>;

export type CancelRfqMutation = {
  __typename?: "Mutation";
  cancelRfq?: string | null;
};

export type CancelRfqBuyoutsMutationVariables = Exact<{
  rfqID: Scalars["ID"]["input"];
}>;

export type CancelRfqBuyoutsMutation = {
  __typename?: "Mutation";
  cancelRFQBuyouts: Array<{ __typename?: "Buyout"; id: string }>;
};

export type ConnectOrgPreferredVendorMutationVariables = Exact<{
  input: ConnectNodeInput;
}>;

export type ConnectOrgPreferredVendorMutation = {
  __typename?: "Mutation";
  connectOrgPreferredVendor: {
    __typename?: "OrgPreferredVendor";
    id: string;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  };
};

export type ConnectSourceSystemMutationVariables = Exact<{
  input: ConnectSourceSystemInput;
}>;

export type ConnectSourceSystemMutation = {
  __typename?: "Mutation";
  connectSourceSystem: {
    __typename?: "SourceSystemSettings";
    system: SourceSystem;
    companyName: string;
    companyId: string;
    connected: boolean;
    postInventoryReceipts?: boolean | null;
    wbsTags?: boolean | null;
    autoExportApprovedInvoices?: boolean | null;
    autoPostPOs?: boolean | null;
    poNameTemplate?: string | null;
    poDescriptionTemplate?: string | null;
    invoiceDescriptionTemplate?: string | null;
    inventoryReceiptLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      name: string;
    } | null;
    customPOTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
    customInvoiceTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
  };
};

export type CreateAssetMutationVariables = Exact<{
  input: UploadAssetInput;
}>;

export type CreateAssetMutation = {
  __typename?: "Mutation";
  createAsset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
};

export type CreateBuyoutMutationVariables = Exact<{
  input: CreateBuyoutInput;
}>;

export type CreateBuyoutMutation = {
  __typename?: "Mutation";
  createBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    version: number;
  };
};

export type CreateCustomFileFormatMutationVariables = Exact<{
  input: CreateCustomFileFormatInput;
}>;

export type CreateCustomFileFormatMutation = {
  __typename?: "Mutation";
  createCustomFileFormat: { __typename?: "CustomFileFormat"; id: string };
};

export type CreateEditCopyRfqForChangesMutationVariables = Exact<{
  rfqID: Scalars["ID"]["input"];
}>;

export type CreateEditCopyRfqForChangesMutation = {
  __typename?: "Mutation";
  createEditCopyRfqForChanges: {
    __typename?: "Rfq";
    id: string;
    clientIdentifier: string;
  };
};

export type CreateManufacturerMutationVariables = Exact<{
  input: CreateManufacturerInput;
}>;

export type CreateManufacturerMutation = {
  __typename?: "Mutation";
  createManufacturer: { __typename?: "Manufacturer"; id: string; name: string };
};

export type CreateManufacturersMutationVariables = Exact<{
  input: Array<CreateManufacturerInput> | CreateManufacturerInput;
}>;

export type CreateManufacturersMutation = {
  __typename?: "Mutation";
  createManufacturers: Array<{
    __typename?: "Manufacturer";
    id: string;
    name: string;
  }>;
};

export type CreateOrgMutationVariables = Exact<{
  input: CreateOrgInput;
}>;

export type CreateOrgMutation = {
  __typename?: "Mutation";
  createOrg: { __typename?: "Organization"; id: string; name: string };
};

export type CreateOrgCatalogSkuMutationVariables = Exact<{
  input: CreateOrgCatalogSkuInput;
}>;

export type CreateOrgCatalogSkuMutation = {
  __typename?: "Mutation";
  createOrgCatalogSku: {
    __typename?: "OrgCatalogSku";
    id: string;
    name: string;
    customPartNumber?: string | null;
    sectionName?: string | null;
    materialCostCode?: string | null;
    type?: string | null;
    createdAt: number;
    deletedAt?: number | null;
    equivalentsCount: number;
    asSearchTerms: Array<string>;
    defaultUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    mappedCategories: Array<{
      __typename?: "ProductCategory";
      id: string;
      l1Name: string;
    }>;
  };
};

export type CreateOrgMaterialMutationVariables = Exact<{
  input: CreateOrgMaterialInput;
}>;

export type CreateOrgMaterialMutation = {
  __typename?: "Mutation";
  createOrgMaterial: {
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    defaultEstimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    referenceCounts: {
      __typename?: "MaterialReferenceCounts";
      estimatedItems: number;
      rfqItems: number;
      buyoutItems: number;
      releaseItems: number;
    };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    vendorPrices: Array<{
      __typename?: "VendorPrice";
      id: string;
      orgMaterialId: string;
      price: string;
      expirationDate?: number | null;
      orderIncrement?: number | null;
      minimumOrder?: number | null;
      leadTime?: number | null;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      uom: { __typename?: "UOM"; id: string };
      manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
    }>;
    externalItems: Array<{
      __typename?: "ExternalItem";
      id: string;
      externalId: string;
      name: string;
      purchasingUom?: string | null;
      defaultCostCode?: string | null;
      sourceSystem: SourceSystem;
      sourceId: string;
    }>;
  };
};

export type CreateQuoteDocumentMutationVariables = Exact<{
  input: CreateQuoteDocumentInput;
}>;

export type CreateQuoteDocumentMutation = {
  __typename?: "Mutation";
  createQuoteDocument: {
    __typename?: "QuoteDocument";
    id: string;
    taxAmount?: string | null;
    expirationDate?: number | null;
    paymentTerm?: number | null;
    createdAt: number;
    subtotal?: string | null;
    orgLocation: { __typename?: "OrgLocation"; id: string };
    predictedProject?: {
      __typename?: "Project";
      id: string;
      name: string;
      location: { __typename?: "OrgLocation"; id: string };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    items: Array<{
      __typename?: "QuoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
  };
};

export type CreateReservedReleaseMutationVariables = Exact<{
  input: CreateReservedReleaseInput;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type CreateReservedReleaseMutation = {
  __typename?: "Mutation";
  createReservedRelease: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  };
};

export type CreateStandaloneReleaseMutationVariables = Exact<{
  input: CreateStandaloneReleaseInput;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type CreateStandaloneReleaseMutation = {
  __typename?: "Mutation";
  createStandaloneRelease: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "User";
    id: string;
    email: string;
    cellPhone: string;
    createdAt: number;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
    isEmailValidated: boolean;
    isOrgAdmin?: boolean | null;
    orgRoles: Array<OrgRole>;
    enterpriseRoles: Array<EnterpriseRole>;
    org: {
      __typename?: "Organization";
      id: string;
      locations: Array<{
        __typename?: "OrgLocation";
        id: string;
        name: string;
      }>;
    };
    locationRoles: Array<{
      __typename?: "LocationRole";
      orgLocID: string;
      role: OrgLocationRole;
    }>;
  } | null;
};

export type CreateVendorMutationVariables = Exact<{
  input: AddOrgPreferredVendorInput;
}>;

export type CreateVendorMutation = {
  __typename?: "Mutation";
  addOrgPreferredVendor: Array<{
    __typename?: "OrgPreferredVendor";
    id: string;
    externalCode?: string | null;
    internal: boolean;
    taxExempt: boolean;
    defaultPaymentTerm?: number | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      phone?: string | null;
      type: OrgType;
      photoUrl?: string | null;
      hqAddress?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesInvoiceNotifications: boolean;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
    }>;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    externalVendors: Array<{
      __typename?: "ExternalVendor";
      id: string;
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  }>;
};

export type ArchiveBuyoutMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveBuyoutMutation = {
  __typename?: "Mutation";
  archiveBuyout: { __typename?: "Buyout"; id: string };
};

export type DeleteCustomFileFormatMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCustomFileFormatMutation = {
  __typename?: "Mutation";
  deleteCustomFileFormat?: {
    __typename?: "CustomFileFormat";
    id: string;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser?: { __typename?: "User"; id: string } | null;
};

export type DisconnectOrgPreferredVendorMutationVariables = Exact<{
  input: DisconnectNodeInput;
}>;

export type DisconnectOrgPreferredVendorMutation = {
  __typename?: "Mutation";
  disconnectOrgPreferredVendor: {
    __typename?: "OrgPreferredVendor";
    id: string;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  };
};

export type DisconnectSourceSystemMutationVariables = Exact<{
  input: DisconnectSourceSystemInput;
}>;

export type DisconnectSourceSystemMutation = {
  __typename?: "Mutation";
  disconnectSourceSystem: {
    __typename?: "SourceSystemSettings";
    system: SourceSystem;
    companyName: string;
    companyId: string;
    connected: boolean;
    postInventoryReceipts?: boolean | null;
    wbsTags?: boolean | null;
    autoExportApprovedInvoices?: boolean | null;
    autoPostPOs?: boolean | null;
    poNameTemplate?: string | null;
    poDescriptionTemplate?: string | null;
    invoiceDescriptionTemplate?: string | null;
    inventoryReceiptLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      name: string;
    } | null;
    customPOTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
    customInvoiceTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
  };
};

export type ExportQuoteSelectionMutationVariables = Exact<{
  input: ExportQuoteSelectionInput;
}>;

export type ExportQuoteSelectionMutation = {
  __typename?: "Mutation";
  exportQuoteSelection: {
    __typename?: "ExportedQuoteSelection";
    downloadURL?: string | null;
  };
};

export type ExportReleasesMutationVariables = Exact<{
  input: ExportReleasesInput;
}>;

export type ExportReleasesMutation = {
  __typename?: "Mutation";
  exportReleases: {
    __typename?: "ExportReleaseOutput";
    fileURLs?: Array<string> | null;
  };
};

export type ImportBuyoutMutationVariables = Exact<{
  input: ImportBuyoutInput;
}>;

export type ImportBuyoutMutation = {
  __typename?: "Mutation";
  importBuyout: { __typename?: "Buyout"; id: string; clientIdentifier: string };
};

export type ImportOrgCatalogMutationVariables = Exact<{
  input: ImportOrgCatalogInput;
}>;

export type ImportOrgCatalogMutation = {
  __typename?: "Mutation";
  importOrgCatalog?: { __typename?: "Organization"; id: string } | null;
};

export type InitializeSmsVerificationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type InitializeSmsVerificationMutation = {
  __typename?: "Mutation";
  initializeSMSVerification: {
    __typename?: "SMSVerification";
    id: string;
    remainingDeliveries: number;
    remainingAttempts: number;
    completedAt?: number | null;
  };
};

export type InitializeSourceSystemConnectionMutationVariables = Exact<{
  input: InitializeSourceSystemConnectionInput;
}>;

export type InitializeSourceSystemConnectionMutation = {
  __typename?: "Mutation";
  initializeSourceSystemConnection: string;
};

export type RefreshExternalPOsMutationVariables = Exact<{
  projectId: Scalars["ID"]["input"];
  sourceSystem: SourceSystem;
}>;

export type RefreshExternalPOsMutation = {
  __typename?: "Mutation";
  refreshExternalPOs: number;
};

export type RemoveEstimatedItemMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveEstimatedItemMutation = {
  __typename?: "Mutation";
  removeEstimatedItem: Array<{
    __typename?: "EstimatedItem";
    id: string;
    item: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    };
  }>;
};

export type RemoveEstimatedItemsMutationVariables = Exact<{
  input: RemoveEstimatedItemsInput;
}>;

export type RemoveEstimatedItemsMutation = {
  __typename?: "Mutation";
  removeEstimatedItems: Array<{
    __typename?: "AggregatedZone";
    zone?: { __typename?: "Zone"; id: string } | null;
  }>;
};

export type RemoveFromBuyoutMutationVariables = Exact<{
  input: RemoveFromBuyoutInput;
}>;

export type RemoveFromBuyoutMutation = {
  __typename?: "Mutation";
  removeFromBuyout: Array<string | null>;
};

export type RemoveOrgCatalogSkuMutationVariables = Exact<{
  input: RemoveOrgCatalogSkuInput;
}>;

export type RemoveOrgCatalogSkuMutation = {
  __typename?: "Mutation";
  removeOrgCatalogSku?: string | null;
};

export type RemoveRfqMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveRfqMutation = {
  __typename?: "Mutation";
  removeRfq?: string | null;
};

export type RemoveVendorMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveVendorMutation = {
  __typename?: "Mutation";
  removeOrgPreferredVendor: Array<{
    __typename?: "OrgPreferredVendor";
    id: string;
  }>;
};

export type RemoveVendorPriceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveVendorPriceMutation = {
  __typename?: "Mutation";
  deleteVendorPrice: { __typename?: "VendorPrice"; id: string };
};

export type RemoveZoneMutationVariables = Exact<{
  zoneId: Scalars["ID"]["input"];
}>;

export type RemoveZoneMutation = {
  __typename?: "Mutation";
  removeZone: Array<{
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  }>;
};

export type ResendQuoteMutationVariables = Exact<{
  input: ResendQuoteInput;
}>;

export type ResendQuoteMutation = {
  __typename?: "Mutation";
  resendQuote: { __typename?: "Quote"; id: string };
};

export type ResendSmsVerificationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ResendSmsVerificationMutation = {
  __typename?: "Mutation";
  resendSMSVerification: {
    __typename?: "SMSVerification";
    id: string;
    remainingDeliveries: number;
    remainingAttempts: number;
    completedAt?: number | null;
  };
};

export type ResolveReleaseIssuesMutationVariables = Exact<{
  input: ResolveReleaseIssuesInput;
}>;

export type ResolveReleaseIssuesMutation = {
  __typename?: "Mutation";
  resolveReleaseIssues: {
    __typename?: "Release";
    id: string;
    version: number;
    createdAt: number;
    sequenceNumber?: number | null;
    total?: string | null;
    poNumber?: string | null;
    includeServices: Array<ServiceType>;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    requestedTime?: number | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    paymentTerm: number;
    taxVariance?: string | null;
    updatedAt?: number | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      additionalChargesAllowance?: string | null;
      customTaxAmount?: string | null;
      taxRate?: string | null;
      taxAmount?: string | null;
      subtotal: string;
      createdAt: number;
      status: BuyoutStatus;
      version: number;
      includedServices: Array<ServiceType>;
      paymentTermDays?: number | null;
      notes?: string | null;
      poNumber?: string | null;
      netAmount: string;
      taxType?: TaxType | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      releases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
      }>;
      quote?: {
        __typename?: "Quote";
        id: string;
        notes?: string | null;
        expirationDate?: number | null;
        paymentTermDays?: number | null;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        discount?: {
          __typename?: "Discount";
          amount?: string | null;
          percentage?: string | null;
        } | null;
        rfq: {
          __typename?: "Rfq";
          id: string;
          clientIdentifier: string;
          includeServices: Array<ServiceType>;
          status: RfqStatus;
          createdBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          };
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        taxExempt: boolean;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          email: string;
          cellPhone: string;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        startDate?: number | null;
        status: ProjectStatus;
        taxExempt: boolean;
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            settings?: {
              __typename?: "OrgSettings";
              display?: {
                __typename?: "DisplaySettings";
                enableManufacturers: boolean;
                currency: {
                  __typename?: "Currency";
                  id: string;
                  code: string;
                  name: string;
                  symbol: string;
                };
              } | null;
            } | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
      items: Array<{
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        unitPrice: string;
        requestedUnitPrice?: string | null;
        expirationDate?: number | null;
        notes?: string | null;
        isIncluded: boolean;
        description: string;
        position?: number | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        rfqItem?: {
          __typename?: "RfqItem";
          id: string;
          description: string;
          quantityDecimal: string;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          projectItem: {
            __typename?: "ProjectItem";
            id: string;
            material: {
              __typename?: "OrgMaterial";
              id: string;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
            };
            estimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          manufacturer: {
            __typename?: "Manufacturer";
            url?: string | null;
            id: string;
            name: string;
          };
        } | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      quoteDocument?: {
        __typename?: "QuoteDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      } | null;
      taxCode?: {
        __typename?: "TaxCode";
        id: string;
        code: string;
        description?: string | null;
        rate: string;
      } | null;
      releaseType: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      releases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
        } | null;
      }>;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      archivedAt?: number | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      requestedQuantity: number;
      unitPrice?: string | null;
      isIncluded: boolean;
      position?: number | null;
      notes?: string | null;
      alternativeFulfillmentTime?: number | null;
      alternativeFulfillmentQuantityDecimal?: string | null;
      pricePrefilled: boolean;
      deliveryDate?: number | null;
      pricePrenegotiated: boolean;
      addedByVendor: boolean;
      deliveryDateTBD: boolean;
      taxable: boolean;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        description: string;
        quantityDecimal: string;
        unitPrice: string;
        notes?: string | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      requestedUom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        time?: number | null;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    invoice?: {
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        pageRange: { __typename?: "PageRange"; start: number; end: number };
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    } | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        settings?: {
          __typename?: "OrgSettings";
          display?: {
            __typename?: "DisplaySettings";
            enableManufacturers: boolean;
            currency: {
              __typename?: "Currency";
              id: string;
              code: string;
              name: string;
              symbol: string;
            };
          } | null;
        } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: { __typename?: "Rfq"; id: string; clientIdentifier: string };
    } | null;
  };
};

export type ScheduleReleaseMutationVariables = Exact<{
  input: UpdateContractorReleaseInput;
}>;

export type ScheduleReleaseMutation = {
  __typename?: "Mutation";
  updateContractorRelease: {
    __typename?: "Release";
    id: string;
    time?: number | null;
    version: number;
    status: ReleaseStatus;
  };
};

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type SendMessageMutation = {
  __typename?: "Mutation";
  sendMessage: Array<{ __typename?: "Message"; id: string }>;
};

export type SubmitQuoteMutationVariables = Exact<{
  input: SubmitQuoteInput;
}>;

export type SubmitQuoteMutation = {
  __typename?: "Mutation";
  submitQuote: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    status: QuoteStatus;
    notes?: string | null;
    expirationDate?: number | null;
    submittedDate?: number | null;
    paymentTermDays?: number | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    itemGroups: Array<{
      __typename?: "QuoteItemGroup";
      rfqItem: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  skus: Array<{
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    imageUrl: string;
                    description: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                  }>;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  product: {
                    __typename?: "Product";
                    id: string;
                    skus: Array<{
                      __typename?: "Sku";
                      id: string;
                      name: string;
                      imageUrl: string;
                      description: string;
                      manufacturer: {
                        __typename?: "Manufacturer";
                        id: string;
                        name: string;
                      };
                    }>;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      quoteItems: Array<{
        __typename?: "QuoteItem";
        id: string;
        unitPrice?: string | null;
        status: QuoteStatus;
        expirationDate?: number | null;
        description?: string | null;
        notes?: string | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            categories: Array<{ __typename?: "ProductCategory"; id: string }>;
          };
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          type?: string | null;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    auxiliaryItems: Array<{
      __typename?: "AuxiliaryQuoteItem";
      id: string;
      unitPrice?: string | null;
      description?: string | null;
      notes?: string | null;
      expirationDate?: number | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
    }>;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        startDate?: number | null;
        status: ProjectStatus;
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            settings?: {
              __typename?: "OrgSettings";
              display?: {
                __typename?: "DisplaySettings";
                enableManufacturers: boolean;
                currency: {
                  __typename?: "Currency";
                  id: string;
                  code: string;
                  name: string;
                  symbol: string;
                };
              } | null;
            } | null;
            hqAddress?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesQuoteNotifications: boolean;
      }>;
    } | null;
    previousRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
    nextRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
  };
};

export type SubmitRfqMutationVariables = Exact<{
  input: SubmitRfqInput;
}>;

export type SubmitRfqMutation = {
  __typename?: "Mutation";
  submitRfq: {
    __typename?: "Rfq";
    id: string;
    createdAt: number;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    deletedAt?: number | null;
    description?: string | null;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      budget?: string | null;
      deletedAt?: number | null;
      startDate?: number | null;
      percentageOfBudgetSpent?: string | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      poJobNumber?: string | null;
      restrictCostCodes: boolean;
      usesProjectCostCodes: boolean;
      preferredInventoryInflows: boolean;
      aggregatedZones: Array<{
        __typename?: "AggregatedZone";
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costCodes: Array<{
          __typename?: "AggregatedCostCode";
          costCode?: { __typename?: "CostCode"; id: string } | null;
          items: Array<{
            __typename?: "AggregatedItem";
            projectItem: { __typename?: "ProjectItem"; id: string };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              position?: number | null;
              notes?: string | null;
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
              tags: Array<{ __typename?: "ProjectTag"; id: string }>;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
              uom: { __typename?: "UOM"; id: string };
            }>;
            buyoutItems?: Array<{
              __typename?: "BuyoutItem";
              id: string;
            }> | null;
          }>;
        }>;
      }>;
      externalProjects: Array<{
        __typename?: "ExternalProject";
        id: string;
        sourceSystem: SourceSystem;
      }>;
      estimatedItemGroups: Array<{
        __typename?: "EstimatedItemGroup";
        estimatedItemCount: number;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      }>;
      team: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        photoUrl?: string | null;
      }>;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          costCodes: Array<{
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          }>;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      items: Array<{
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      }>;
      zones: Array<{
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      }>;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
      permissions: {
        __typename?: "ProjectPermissions";
        edit: AuthorizationStatus;
        createTag: AuthorizationStatus;
        report: AuthorizationStatus;
      };
    };
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    requisition?: { __typename?: "Requisition"; id: string } | null;
    items: Array<{
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      position?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      contacts: Array<{ __typename?: "VendorContact"; id: string }>;
    }>;
    nextRevision?: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      status: RfqStatus;
    } | null;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      status: QuoteStatus;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        subtotal: string;
        poExists?: boolean | null;
        description?: string | null;
        createdAt: number;
        status: BuyoutStatus;
        includedServices: Array<ServiceType>;
        poNumber?: string | null;
        version: number;
        paymentTermDays?: number | null;
        notes?: string | null;
        taxAmount?: string | null;
        taxRate?: string | null;
        netAmount: string;
        taxType?: TaxType | null;
        customTaxAmount?: string | null;
        additionalChargesAllowance?: string | null;
        exportedAt?: number | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        releases: Array<{
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
        }>;
        quote?: {
          __typename?: "Quote";
          id: string;
          notes?: string | null;
          expirationDate?: number | null;
          paymentTermDays?: number | null;
          additionalCharges: Array<{
            __typename?: "Charge";
            id: string;
            description: string;
            amount: string;
          }>;
          discount?: {
            __typename?: "Discount";
            amount?: string | null;
            percentage?: string | null;
          } | null;
          rfq: {
            __typename?: "Rfq";
            id: string;
            clientIdentifier: string;
            includeServices: Array<ServiceType>;
            status: RfqStatus;
            createdBy: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              photoUrl?: string | null;
            };
            quotes: Array<{
              __typename?: "Quote";
              id: string;
              buyout?: {
                __typename?: "Buyout";
                id: string;
                clientIdentifier: string;
                status: BuyoutStatus;
                sellerOrgLocation: {
                  __typename?: "OrgLocation";
                  id: string;
                  org: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                    photoUrl?: string | null;
                  };
                };
              } | null;
            }>;
          };
        } | null;
        quoteDocument?: {
          __typename?: "QuoteDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        } | null;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          taxExempt: boolean;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
            cellPhone: string;
            email: string;
            receivesBuyoutNotifications: boolean;
          }>;
        } | null;
        releaseType: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
          includeAdditionalCharges: boolean;
          poFormat?: PoFormat | null;
          default: boolean;
          taxMode?: TaxMode | null;
          defaultAutoSync: boolean;
        };
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          status: ProjectStatus;
          startDate?: number | null;
          jobNumber?: string | null;
          taxExempt: boolean;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
          location: { __typename?: "OrgLocation"; id: string };
          permissions: {
            __typename?: "ProjectPermissions";
            report: AuthorizationStatus;
          };
        };
        items: Array<{
          __typename?: "BuyoutItem";
          id: string;
          quantityDecimal: string;
          unitPrice: string;
          requestedUnitPrice?: string | null;
          releasedAmount?: string | null;
          completedAmount?: string | null;
          expirationDate?: number | null;
          notes?: string | null;
          isIncluded: boolean;
          position?: number | null;
          description: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          projectItem: {
            __typename?: "ProjectItem";
            id: string;
            material: {
              __typename?: "OrgMaterial";
              id: string;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
            };
            estimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              notes?: string | null;
              position?: number | null;
              zone?: {
                __typename?: "Zone";
                id: string;
                name: string;
                inUse: boolean;
              } | null;
              tags: Array<{
                __typename?: "ProjectTag";
                id: string;
                name: string;
                color: string;
                deletedAt?: number | null;
                hasMapping: boolean;
              }>;
              manufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
                inUse: boolean;
                mappings: Array<{
                  __typename?: "SourceSystemMapping";
                  externalId: string;
                  sourceSystem: SourceSystem;
                }>;
              } | null;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
            }>;
            manufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
            buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
          };
          rfqItem?: {
            __typename?: "RfqItem";
            id: string;
            description: string;
            quantityDecimal: string;
          } | null;
          orgCatalogSku?: {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
          } | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          masterSku?: {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: {
              __typename?: "Manufacturer";
              url?: string | null;
              id: string;
              name: string;
            };
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
        }>;
        standaloneReleaseItems: Array<{
          __typename?: "BuyoutStandaloneReleaseItem";
          totalQuantity: string;
          unitPrice: string;
          orderedSoFar: string;
          receivedSoFar: string;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          orgMaterial: {
            __typename?: "OrgMaterial";
            id: string;
            externalCode?: string | null;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            defaultEstimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            referenceCounts: {
              __typename?: "MaterialReferenceCounts";
              estimatedItems: number;
              rfqItems: number;
              buyoutItems: number;
              releaseItems: number;
            };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
              inUse: boolean;
              mappings: Array<{
                __typename?: "SourceSystemMapping";
                externalId: string;
                sourceSystem: SourceSystem;
              }>;
            } | null;
            vendorPrices: Array<{
              __typename?: "VendorPrice";
              id: string;
              orgMaterialId: string;
              price: string;
              expirationDate?: number | null;
              orderIncrement?: number | null;
              minimumOrder?: number | null;
              leadTime?: number | null;
              sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
              uom: { __typename?: "UOM"; id: string };
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            }>;
            externalItems: Array<{
              __typename?: "ExternalItem";
              id: string;
              externalId: string;
              name: string;
              purchasingUom?: string | null;
              defaultCostCode?: string | null;
              sourceSystem: SourceSystem;
              sourceId: string;
            }>;
          };
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
        }>;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        taxCode?: {
          __typename?: "TaxCode";
          id: string;
          code: string;
          description?: string | null;
          rate: string;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        poLink?: {
          __typename?: "POLink";
          id: string;
          format: PoFormat;
          sourceSystem: SourceSystem;
          externalWarehouseId?: string | null;
          syncing: boolean;
          syncedVersion: number;
          syncedAt?: number | null;
          createdAt: number;
          autoSync: boolean;
          autoSyncError?: string | null;
          autoSyncFailureCount: number;
          retroactive: boolean;
          immutable: boolean;
          failedAt?: number | null;
          failedMessage?: string | null;
          validationErrors: Array<PoValidationError>;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
        vendorContacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    permissions: {
      __typename?: "RfqPermissions";
      archive: AuthorizationStatus;
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
    };
  };
};

export type SubmitSmsVerificationMutationVariables = Exact<{
  input: SubmitSmsVerificationInput;
}>;

export type SubmitSmsVerificationMutation = {
  __typename?: "Mutation";
  submitSMSVerification: {
    __typename?: "SMSVerification";
    id: string;
    remainingDeliveries: number;
    remainingAttempts: number;
    completedAt?: number | null;
  };
};

export type UnarchiveReleasesMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type UnarchiveReleasesMutation = {
  __typename?: "Mutation";
  unarchiveReleases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    deletedAt?: number | null;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      archivedAt?: number | null;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      archivedAt?: number | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      archivedAt?: number | null;
    }>;
  }>;
};

export type UpdateAssetMutationVariables = Exact<{
  input: UpdateAssetInput;
}>;

export type UpdateAssetMutation = {
  __typename?: "Mutation";
  updateAsset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
};

export type UpdateContractorReleaseMutationVariables = Exact<{
  input: UpdateContractorReleaseInput;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type UpdateContractorReleaseMutation = {
  __typename?: "Mutation";
  updateContractorRelease: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  };
};

export type UpdateEstimatedItemsMutationVariables = Exact<{
  input: UpdateEstimatedItemsInput;
}>;

export type UpdateEstimatedItemsMutation = {
  __typename?: "Mutation";
  updateEstimatedItems: Array<{
    __typename?: "AggregatedZone";
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    costCodes: Array<{
      __typename?: "AggregatedCostCode";
      costCode?: { __typename?: "CostCode"; id: string } | null;
      items: Array<{
        __typename?: "AggregatedItem";
        projectItem: { __typename?: "ProjectItem"; id: string };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          position?: number | null;
          notes?: string | null;
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          tags: Array<{ __typename?: "ProjectTag"; id: string }>;
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
          uom: { __typename?: "UOM"; id: string };
        }>;
        buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
      }>;
    }>;
  }>;
};

export type UpdateEstimatedItemsTagsMutationVariables = Exact<{
  input: UpdateEstimatedItemInput;
}>;

export type UpdateEstimatedItemsTagsMutation = {
  __typename?: "Mutation";
  updateEstimatedItem: Array<{
    __typename?: "EstimatedItem";
    id: string;
    quantityDecimal: string;
    orderedQuantity: string;
    duplicateCount: number;
    unitPrice?: string | null;
    notes?: string | null;
    position?: number | null;
    item: { __typename?: "ProjectItem"; id: string };
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  }>;
};

export type UpdateEstimatesWithFileMutationVariables = Exact<{
  input: UpdateEstimatesWithFileInput;
}>;

export type UpdateEstimatesWithFileMutation = {
  __typename?: "Mutation";
  updateEstimatesWithFile: {
    __typename?: "UpdateEstimatesWithFileOutput";
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      zone?: { __typename?: "Zone"; id: string } | null;
    }>;
    errors: Array<{
      __typename?: "ImportError";
      lineNumber: number;
      errorMessage: string;
    }>;
  };
};

export type UpdateOrgMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgMutation = {
  __typename?: "Mutation";
  updateOrg: {
    __typename?: "Organization";
    id: string;
    name: string;
    ein?: string | null;
    phone?: string | null;
    logoImageUrl?: string | null;
    hqAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
};

export type UpdateOrgMaterialsMutationVariables = Exact<{
  input?: InputMaybe<UpdateOrgMaterialsInput>;
}>;

export type UpdateOrgMaterialsMutation = {
  __typename?: "Mutation";
  updateOrgMaterials: Array<{
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    defaultEstimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    referenceCounts: {
      __typename?: "MaterialReferenceCounts";
      estimatedItems: number;
      rfqItems: number;
      buyoutItems: number;
      releaseItems: number;
    };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    vendorPrices: Array<{
      __typename?: "VendorPrice";
      id: string;
      orgMaterialId: string;
      price: string;
      expirationDate?: number | null;
      orderIncrement?: number | null;
      minimumOrder?: number | null;
      leadTime?: number | null;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      uom: { __typename?: "UOM"; id: string };
      manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
    }>;
    externalItems: Array<{
      __typename?: "ExternalItem";
      id: string;
      externalId: string;
      name: string;
      purchasingUom?: string | null;
      defaultCostCode?: string | null;
      sourceSystem: SourceSystem;
      sourceId: string;
    }>;
  }>;
};

export type UpdateQuoteMutationVariables = Exact<{
  input: UpdateQuoteInput;
}>;

export type UpdateQuoteMutation = {
  __typename?: "Mutation";
  updateQuote: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    status: QuoteStatus;
    notes?: string | null;
    expirationDate?: number | null;
    submittedDate?: number | null;
    paymentTermDays?: number | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    itemGroups: Array<{
      __typename?: "QuoteItemGroup";
      rfqItem: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  skus: Array<{
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    imageUrl: string;
                    description: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                  }>;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  product: {
                    __typename?: "Product";
                    id: string;
                    skus: Array<{
                      __typename?: "Sku";
                      id: string;
                      name: string;
                      imageUrl: string;
                      description: string;
                      manufacturer: {
                        __typename?: "Manufacturer";
                        id: string;
                        name: string;
                      };
                    }>;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      quoteItems: Array<{
        __typename?: "QuoteItem";
        id: string;
        unitPrice?: string | null;
        status: QuoteStatus;
        expirationDate?: number | null;
        description?: string | null;
        notes?: string | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            categories: Array<{ __typename?: "ProductCategory"; id: string }>;
          };
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          type?: string | null;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    auxiliaryItems: Array<{
      __typename?: "AuxiliaryQuoteItem";
      id: string;
      unitPrice?: string | null;
      description?: string | null;
      notes?: string | null;
      expirationDate?: number | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
    }>;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        startDate?: number | null;
        status: ProjectStatus;
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            settings?: {
              __typename?: "OrgSettings";
              display?: {
                __typename?: "DisplaySettings";
                enableManufacturers: boolean;
                currency: {
                  __typename?: "Currency";
                  id: string;
                  code: string;
                  name: string;
                  symbol: string;
                };
              } | null;
            } | null;
            hqAddress?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesQuoteNotifications: boolean;
      }>;
    } | null;
    previousRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
    nextRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "User";
    id: string;
    email: string;
    cellPhone: string;
    createdAt: number;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
    isEmailValidated: boolean;
    isOrgAdmin?: boolean | null;
    orgRoles: Array<OrgRole>;
    enterpriseRoles: Array<EnterpriseRole>;
    org: {
      __typename?: "Organization";
      id: string;
      locations: Array<{
        __typename?: "OrgLocation";
        id: string;
        name: string;
      }>;
    };
    locationRoles: Array<{
      __typename?: "LocationRole";
      orgLocID: string;
      role: OrgLocationRole;
    }>;
  } | null;
};

export type UpdateVendorBuyoutMutationVariables = Exact<{
  input: UpdateVendorBuyoutInput;
}>;

export type UpdateVendorBuyoutMutation = {
  __typename?: "Mutation";
  updateVendorBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    additionalChargesAllowance?: string | null;
    customTaxAmount?: string | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    subtotal: string;
    createdAt: number;
    status: BuyoutStatus;
    version: number;
    includedServices: Array<ServiceType>;
    paymentTermDays?: number | null;
    notes?: string | null;
    poNumber?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          settings?: {
            __typename?: "OrgSettings";
            display?: {
              __typename?: "DisplaySettings";
              enableManufacturers: boolean;
              currency: {
                __typename?: "Currency";
                id: string;
                code: string;
                name: string;
                symbol: string;
              };
            } | null;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      description: string;
      position?: number | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  };
};

export type UpdateVendorOrgMutationVariables = Exact<{
  input: UpdateVendorOrgInput;
}>;

export type UpdateVendorOrgMutation = {
  __typename?: "Mutation";
  updateVendorOrg: {
    __typename?: "Organization";
    id: string;
    name: string;
    photoUrl?: string | null;
  };
};

export type UpdateVendorReleaseMutationVariables = Exact<{
  input: UpdateVendorReleaseInput;
}>;

export type UpdateVendorReleaseMutation = {
  __typename?: "Mutation";
  updateVendorRelease: Array<{
    __typename?: "Release";
    id: string;
    total?: string | null;
    netAmount: string;
    version: number;
    sequenceNumber?: number | null;
    amount?: string | null;
    poNumber?: string | null;
    includeServices: Array<ServiceType>;
    status: ReleaseStatus;
    time?: number | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    notes?: string | null;
    paymentTerm: number;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      requestedQuantity: number;
      unitPrice?: string | null;
      isIncluded: boolean;
      position?: number | null;
      notes?: string | null;
      alternativeFulfillmentTime?: number | null;
      alternativeFulfillmentQuantityDecimal?: string | null;
      pricePrefilled: boolean;
      deliveryDate?: number | null;
      pricePrenegotiated: boolean;
      addedByVendor: boolean;
      deliveryDateTBD: boolean;
      taxable: boolean;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        description: string;
        quantityDecimal: string;
        unitPrice: string;
        notes?: string | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      requestedUom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        time?: number | null;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
  }>;
};

export type UpdateViewerProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateViewerProfileMutation = {
  __typename?: "Mutation";
  updateViewerProfile?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    cellPhone: string;
    photoUrl?: string | null;
    getSmsNotifications: boolean;
    getEmailNotifications: boolean;
    isPhoneValidated: boolean;
    excludedReleaseEvents: Array<ReleaseEvent>;
    excludedInvoiceEvents: Array<InvoiceEvent>;
    excludedBuyoutEvents: Array<BuyoutEvent>;
    excludedQuoteEvents: Array<QuoteEvent>;
    excludedMessageContexts: Array<MessageContextKind>;
  } | null;
};

export type UpdateZoneMutationVariables = Exact<{
  input: UpdateZoneInput;
}>;

export type UpdateZoneMutation = {
  __typename?: "Mutation";
  updateZone: { __typename?: "Zone"; id: string; name: string; inUse: boolean };
};

export type WithdrawQuoteMutationVariables = Exact<{
  input: WithdrawQuoteInput;
}>;

export type WithdrawQuoteMutation = {
  __typename?: "Mutation";
  withdrawQuote: {
    __typename?: "Quote";
    id: string;
    status: QuoteStatus;
    items: Array<{
      __typename?: "QuoteItem";
      id: string;
      expirationDate?: number | null;
      notes?: string | null;
      unitPrice?: string | null;
      quantityDecimal?: string | null;
      status: QuoteStatus;
      masterSku?: { __typename?: "Sku"; id: string } | null;
      orgCatalogSku?: { __typename?: "OrgCatalogSku"; id: string } | null;
    }>;
  };
};

export type AssignableBuyoutsQueryVariables = Exact<{
  input: AssignableBuyoutsInput;
}>;

export type AssignableBuyoutsQuery = {
  __typename?: "Query";
  assignableBuyouts: Array<{
    __typename?: "AssignableBuyout";
    matchCount: number;
    itemCount: number;
    buyout: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      subtotal: string;
      description?: string | null;
      createdAt: number;
      deletedAt?: number | null;
      completedAmount: string;
      requestedAmount: string;
      scheduledAmount: string;
      exportedAt?: number | null;
      poExists?: boolean | null;
      status: BuyoutStatus;
      includedServices: Array<ServiceType>;
      items: Array<{
        __typename?: "BuyoutItem";
        id: string;
        description: string;
        notes?: string | null;
        unitPrice: string;
        isIncluded: boolean;
        auxiliaryQuoteItem?: {
          __typename?: "AuxiliaryQuoteItem";
          id: string;
          uom?: {
            __typename?: "UOM";
            id: string;
            singularDescription?: string | null;
          } | null;
        } | null;
        projectItem: { __typename?: "ProjectItem"; id: string };
      }>;
      releases: Array<{
        __typename?: "Release";
        id: string;
        deletedAt?: number | null;
      }>;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
      };
      quote?: {
        __typename?: "Quote";
        id: string;
        rfq: {
          __typename?: "Rfq";
          id: string;
          status: RfqStatus;
          clientIdentifier: string;
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        contacts: Array<{
          __typename?: "VendorContact";
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
        }>;
      } | null;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      quoteDocument?: { __typename?: "QuoteDocument"; id: string } | null;
      releaseType: { __typename?: "ReleaseType"; id: string; name: string };
      poLink?: {
        __typename?: "POLink";
        id: string;
        syncedAt?: number | null;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          email: string;
        } | null;
      } | null;
    };
  }>;
};

export type BuyoutMessagesContextQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type BuyoutMessagesContextQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    quote?: {
      __typename?: "Quote";
      id: string;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
        }>;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          org: { __typename?: "Organization"; id: string; name: string };
        };
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          org: { __typename?: "Organization"; id: string; name: string };
        };
      };
    } | null;
  } | null;
};

export type BuyoutDistributorMessagesContextQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type BuyoutDistributorMessagesContextQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    quote?: {
      __typename?: "Quote";
      id: string;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
        }>;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          org: { __typename?: "Organization"; id: string; name: string };
        };
      };
    } | null;
  } | null;
};

export type BuyoutReleaseDetailsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type BuyoutReleaseDetailsQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    status: ReleaseStatus;
    version: number;
    poNumber?: string | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      quantityDecimal: string;
      name: string;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      deliveryDate?: number | null;
      notes?: string | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      } | null;
      issue?: {
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
    }>;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  } | null;
};

export type BuyoutStockingsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type BuyoutStockingsQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    status: BuyoutStatus;
    version: number;
    clientIdentifier: string;
    subtotal: string;
    createdAt: number;
    poNumber?: string | null;
    includedServices: Array<ServiceType>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    servicePriceSheet: {
      __typename?: "ServicePriceSheet";
      id: string;
      prices: Array<{
        __typename?: "ServicePrice";
        id: string;
        name: ServiceName;
        productCategory: ServiceProductCategoryName;
        type: ServiceType;
        regularHoursAmount?: string | null;
        offHoursAmount?: string | null;
        regularHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        offHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
    };
  } | null;
};

export type BuyoutsQueryVariables = Exact<{
  filter?: InputMaybe<QueryBuyoutsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type BuyoutsQuery = {
  __typename?: "Query";
  buyouts: {
    __typename?: "BuyoutConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "BuyoutEdge";
      cursor: string;
      node: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        subtotal: string;
        description?: string | null;
        createdAt: number;
        deletedAt?: number | null;
        completedAmount: string;
        requestedAmount: string;
        scheduledAmount: string;
        exportedAt?: number | null;
        poExists?: boolean | null;
        status: BuyoutStatus;
        includedServices: Array<ServiceType>;
        releases: Array<{
          __typename?: "Release";
          id: string;
          deletedAt?: number | null;
        }>;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
        };
        quote?: {
          __typename?: "Quote";
          id: string;
          rfq: {
            __typename?: "Rfq";
            id: string;
            status: RfqStatus;
            clientIdentifier: string;
          };
        } | null;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
          contacts: Array<{
            __typename?: "VendorContact";
            receivesQuoteNotifications: boolean;
            receivesBuyoutNotifications: boolean;
            receivesOrderNotifications: boolean;
            receivesInvoiceNotifications: boolean;
            id: string;
            name: string;
            cellPhone: string;
            cellPhoneExtension?: string | null;
            email: string;
          }>;
        } | null;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        quoteDocument?: { __typename?: "QuoteDocument"; id: string } | null;
        releaseType: { __typename?: "ReleaseType"; id: string; name: string };
        poLink?: {
          __typename?: "POLink";
          id: string;
          syncedAt?: number | null;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            email: string;
          } | null;
        } | null;
      };
    }>;
  };
};

export type CustomFileFormatsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomFileFormatsQuery = {
  __typename?: "Query";
  customFileFormats: Array<{
    __typename?: "CustomFileFormat";
    id: string;
    name: string;
    fileType: FileType;
    mappings: Array<{
      __typename?: "FieldMapping";
      property: FieldMappingTargetProperty;
      index: number;
      name?: string | null;
    }>;
  }>;
};

export type DistributorBuyoutReleasesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorBuyoutReleasesQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    status: BuyoutStatus;
    requestedAmount: string;
    scheduledAmount: string;
    completedAmount: string;
    poNumber?: string | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      total?: string | null;
      time?: number | null;
      status: ReleaseStatus;
      version: number;
      includeServices: Array<ServiceType>;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      deliverySlips: Array<{
        __typename?: "DeliverySlip";
        id: string;
        status: DeliverySlipStatus;
        createdAt: number;
        reviewedAt?: number | null;
        archivedAt?: number | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        inventoryTransaction?: {
          __typename?: "InventoryTransaction";
          id: string;
        } | null;
        asset?: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        } | null;
      }>;
      deliveryPhotos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  } | null;
};

export type DistributorBuyoutStockingsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorBuyoutStockingsQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    status: BuyoutStatus;
    version: number;
    clientIdentifier: string;
    subtotal: string;
    createdAt: number;
    paymentTermDays?: number | null;
    poNumber?: string | null;
    includedServices: Array<ServiceType>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    servicePriceSheet: {
      __typename?: "ServicePriceSheet";
      id: string;
      prices: Array<{
        __typename?: "ServicePrice";
        id: string;
        name: ServiceName;
        productCategory: ServiceProductCategoryName;
        type: ServiceType;
        regularHoursAmount?: string | null;
        offHoursAmount?: string | null;
        regularHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        offHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
  } | null;
};

export type DistributorQuoteQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorQuoteQuery = {
  __typename?: "Query";
  quote?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    status: QuoteStatus;
    notes?: string | null;
    expirationDate?: number | null;
    submittedDate?: number | null;
    paymentTermDays?: number | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    itemGroups: Array<{
      __typename?: "QuoteItemGroup";
      rfqItem: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  skus: Array<{
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    imageUrl: string;
                    description: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                  }>;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  product: {
                    __typename?: "Product";
                    id: string;
                    skus: Array<{
                      __typename?: "Sku";
                      id: string;
                      name: string;
                      imageUrl: string;
                      description: string;
                      manufacturer: {
                        __typename?: "Manufacturer";
                        id: string;
                        name: string;
                      };
                    }>;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      quoteItems: Array<{
        __typename?: "QuoteItem";
        id: string;
        unitPrice?: string | null;
        status: QuoteStatus;
        expirationDate?: number | null;
        description?: string | null;
        notes?: string | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            categories: Array<{ __typename?: "ProductCategory"; id: string }>;
          };
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          type?: string | null;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    auxiliaryItems: Array<{
      __typename?: "AuxiliaryQuoteItem";
      id: string;
      unitPrice?: string | null;
      description?: string | null;
      notes?: string | null;
      expirationDate?: number | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
    }>;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        startDate?: number | null;
        status: ProjectStatus;
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            settings?: {
              __typename?: "OrgSettings";
              display?: {
                __typename?: "DisplaySettings";
                enableManufacturers: boolean;
                currency: {
                  __typename?: "Currency";
                  id: string;
                  code: string;
                  name: string;
                  symbol: string;
                };
              } | null;
            } | null;
            hqAddress?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
    servicePriceSheet?: {
      __typename?: "ServicePriceSheet";
      id: string;
      prices: Array<{
        __typename?: "ServicePrice";
        id: string;
        name: ServiceName;
        productCategory: ServiceProductCategoryName;
        type: ServiceType;
        regularHoursAmount?: string | null;
        offHoursAmount?: string | null;
        regularHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        offHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesQuoteNotifications: boolean;
      }>;
    } | null;
    previousRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
    nextRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
  } | null;
};

export type DistributorQuoteDeliveriesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorQuoteDeliveriesQuery = {
  __typename?: "Query";
  quote?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    status: QuoteStatus;
    notes?: string | null;
    expirationDate?: number | null;
    submittedDate?: number | null;
    paymentTermDays?: number | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    itemGroups: Array<{
      __typename?: "QuoteItemGroup";
      rfqItem: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  skus: Array<{
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    imageUrl: string;
                    description: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                  }>;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  product: {
                    __typename?: "Product";
                    id: string;
                    skus: Array<{
                      __typename?: "Sku";
                      id: string;
                      name: string;
                      imageUrl: string;
                      description: string;
                      manufacturer: {
                        __typename?: "Manufacturer";
                        id: string;
                        name: string;
                      };
                    }>;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      quoteItems: Array<{
        __typename?: "QuoteItem";
        id: string;
        unitPrice?: string | null;
        status: QuoteStatus;
        expirationDate?: number | null;
        description?: string | null;
        notes?: string | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            categories: Array<{ __typename?: "ProductCategory"; id: string }>;
          };
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          type?: string | null;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    auxiliaryItems: Array<{
      __typename?: "AuxiliaryQuoteItem";
      id: string;
      unitPrice?: string | null;
      description?: string | null;
      notes?: string | null;
      expirationDate?: number | null;
      quantityDecimal?: string | null;
      leadTimeDays?: number | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
    }>;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        startDate?: number | null;
        status: ProjectStatus;
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            settings?: {
              __typename?: "OrgSettings";
              display?: {
                __typename?: "DisplaySettings";
                enableManufacturers: boolean;
                currency: {
                  __typename?: "Currency";
                  id: string;
                  code: string;
                  name: string;
                  symbol: string;
                };
              } | null;
            } | null;
            hqAddress?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
    servicePriceSheet?: {
      __typename?: "ServicePriceSheet";
      id: string;
      prices: Array<{
        __typename?: "ServicePrice";
        id: string;
        name: ServiceName;
        productCategory: ServiceProductCategoryName;
        type: ServiceType;
        regularHoursAmount?: string | null;
        offHoursAmount?: string | null;
        regularHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        offHoursUOM: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesQuoteNotifications: boolean;
      }>;
    } | null;
    previousRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
    nextRevision?: {
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      url: string;
    } | null;
  } | null;
  serviceProductCategories: Array<{
    __typename?: "ServiceProductCategory";
    name: ServiceProductCategoryName;
    uomOptions: Array<{
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    }>;
  }>;
};

export type DistributorQuoteMessagesContextQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorQuoteMessagesContextQuery = {
  __typename?: "Query";
  quote?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        org: { __typename?: "Organization"; id: string; name: string };
      };
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
      }>;
    } | null;
  } | null;
};

export type DistributorReleaseQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorReleaseQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    version: number;
    createdAt: number;
    sequenceNumber?: number | null;
    total?: string | null;
    poNumber?: string | null;
    includeServices: Array<ServiceType>;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    requestedTime?: number | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    paymentTerm: number;
    taxVariance?: string | null;
    updatedAt?: number | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      additionalChargesAllowance?: string | null;
      customTaxAmount?: string | null;
      taxRate?: string | null;
      taxAmount?: string | null;
      subtotal: string;
      createdAt: number;
      status: BuyoutStatus;
      version: number;
      includedServices: Array<ServiceType>;
      paymentTermDays?: number | null;
      notes?: string | null;
      poNumber?: string | null;
      netAmount: string;
      taxType?: TaxType | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      releases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
      }>;
      quote?: {
        __typename?: "Quote";
        id: string;
        notes?: string | null;
        expirationDate?: number | null;
        paymentTermDays?: number | null;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        discount?: {
          __typename?: "Discount";
          amount?: string | null;
          percentage?: string | null;
        } | null;
        rfq: {
          __typename?: "Rfq";
          id: string;
          clientIdentifier: string;
          includeServices: Array<ServiceType>;
          status: RfqStatus;
          createdBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          };
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        taxExempt: boolean;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          email: string;
          cellPhone: string;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        startDate?: number | null;
        status: ProjectStatus;
        taxExempt: boolean;
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            settings?: {
              __typename?: "OrgSettings";
              display?: {
                __typename?: "DisplaySettings";
                enableManufacturers: boolean;
                currency: {
                  __typename?: "Currency";
                  id: string;
                  code: string;
                  name: string;
                  symbol: string;
                };
              } | null;
            } | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
      items: Array<{
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        unitPrice: string;
        requestedUnitPrice?: string | null;
        expirationDate?: number | null;
        notes?: string | null;
        isIncluded: boolean;
        description: string;
        position?: number | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        rfqItem?: {
          __typename?: "RfqItem";
          id: string;
          description: string;
          quantityDecimal: string;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          projectItem: {
            __typename?: "ProjectItem";
            id: string;
            material: {
              __typename?: "OrgMaterial";
              id: string;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
            };
            estimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        } | null;
        orgCatalogSku?: {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        masterSku?: {
          __typename?: "Sku";
          id: string;
          name: string;
          manufacturer: {
            __typename?: "Manufacturer";
            url?: string | null;
            id: string;
            name: string;
          };
        } | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      quoteDocument?: {
        __typename?: "QuoteDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      } | null;
      taxCode?: {
        __typename?: "TaxCode";
        id: string;
        code: string;
        description?: string | null;
        rate: string;
      } | null;
      releaseType: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      releases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
        } | null;
      }>;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      archivedAt?: number | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      requestedQuantity: number;
      unitPrice?: string | null;
      isIncluded: boolean;
      position?: number | null;
      notes?: string | null;
      alternativeFulfillmentTime?: number | null;
      alternativeFulfillmentQuantityDecimal?: string | null;
      pricePrefilled: boolean;
      deliveryDate?: number | null;
      pricePrenegotiated: boolean;
      addedByVendor: boolean;
      deliveryDateTBD: boolean;
      taxable: boolean;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        description: string;
        quantityDecimal: string;
        unitPrice: string;
        notes?: string | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      requestedUom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        time?: number | null;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    invoice?: {
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        pageRange: { __typename?: "PageRange"; start: number; end: number };
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    } | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        settings?: {
          __typename?: "OrgSettings";
          display?: {
            __typename?: "DisplaySettings";
            enableManufacturers: boolean;
            currency: {
              __typename?: "Currency";
              id: string;
              code: string;
              name: string;
              symbol: string;
            };
          } | null;
        } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: { __typename?: "Rfq"; id: string; clientIdentifier: string };
    } | null;
  } | null;
};

export type DownloadAssetQueryVariables = Exact<{
  url: Scalars["String"]["input"];
}>;

export type DownloadAssetQuery = {
  __typename?: "Query";
  downloadAsset?: { __typename?: "Blob"; data: string } | null;
};

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CategoriesQuery = {
  __typename?: "Query";
  categories: Array<{
    __typename?: "ProductCategory";
    id: string;
    l1Name: string;
    l2Name?: string | null;
    l3Name?: string | null;
    l4Name?: string | null;
  }>;
};

export type MasterSkusQueryVariables = Exact<{
  filter: QueryProductFilterInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MasterSkusQuery = {
  __typename?: "Query";
  masterSkus: {
    __typename?: "SkuConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    facets: Array<{
      __typename?: "Facet";
      id: string;
      name: string;
      values: Array<{
        __typename?: "FacetValue";
        id: string;
        name: string;
        value: string;
        count?: number | null;
      }>;
    }>;
    edges: Array<{
      __typename?: "SkuEdge";
      cursor: string;
      node: {
        __typename?: "Sku";
        id: string;
        name: string;
        imageUrl: string;
        specsUrl: string;
        manufacturerSkuUrl: string;
        brand: string;
        product: {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      };
    }>;
    searchContext?: {
      __typename?: "SearchContext";
      query: string;
      terms: Array<string>;
      inferredCategoryId: string;
    } | null;
  };
};

export type EstimatedPricesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type EstimatedPricesQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    id: string;
    items: Array<{
      __typename?: "ProjectItem";
      id: string;
      material: { __typename?: "OrgMaterial"; id: string };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        unitPrice?: string | null;
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        zone?: { __typename?: "Zone"; id: string } | null;
        uom: { __typename?: "UOM"; id: string };
      }>;
    }>;
  } | null;
};

export type ExternalPoQueryVariables = Exact<{
  input: ExternalPoQueryInput;
}>;

export type ExternalPoQuery = {
  __typename?: "Query";
  externalPO?: {
    __typename?: "ExternalPO";
    sourceSystem: SourceSystem;
    number: string;
    date: number;
    externalId: string;
    description: string;
    name: string;
    amount: string;
    releaseType?: { __typename?: "ReleaseType"; id: string } | null;
    project?: {
      __typename?: "ExternalProject";
      id: string;
      externalId: string;
      name: string;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        location: { __typename?: "OrgLocation"; id: string };
      } | null;
    } | null;
    vendor?: {
      __typename?: "ExternalVendor";
      id: string;
      name: string;
      orgPreferredVendors: Array<{
        __typename?: "OrgPreferredVendor";
        id: string;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
          };
        };
      }>;
    } | null;
    itemGroups: {
      __typename?: "ExternalPOItemGroups";
      taxCodeId?: string | null;
      importPossibility: ExternalPoImportPossibility;
      taxAmount: string;
      taxType?: TaxType | null;
      importable: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      missingCostCode: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      unsupportedCostType: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      additionalCharges: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      salesTax: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      missingTag: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      aggregatedItems: Array<{
        __typename?: "ExternalPOItem";
        sourceSystem: SourceSystem;
        externalId: string;
        description: string;
        quantity: string;
        unitCost: string;
        amount: string;
        invoicedAmount?: string | null;
        remainingAmount?: string | null;
        uom: string;
        notes?: string | null;
        externalCostTypeId?: string | null;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          collidesWith?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        costType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        wbsTag?: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        } | null;
      }>;
      taxCode?: {
        __typename?: "TaxCode";
        id: string;
        code: string;
        description?: string | null;
      } | null;
    };
    poLinks: Array<{
      __typename?: "POLink";
      id: string;
      release?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        deletedAt?: number | null;
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      } | null;
    }>;
  } | null;
};

export type ExternalPOsQueryVariables = Exact<{
  input: ExternalPOsQueryInput;
}>;

export type ExternalPOsQuery = {
  __typename?: "Query";
  externalPOs: {
    __typename?: "ExternalPOConnection";
    currentPage: number;
    hasNextPage: boolean;
    nodes: Array<{
      __typename?: "ExternalPO";
      sourceSystem: SourceSystem;
      number: string;
      externalId: string;
      amount: string;
      date: number;
      project?: {
        __typename?: "ExternalProject";
        id: string;
        externalId: string;
        name: string;
        project?: {
          __typename?: "Project";
          id: string;
          location: { __typename?: "OrgLocation"; id: string };
        } | null;
      } | null;
      vendor?: {
        __typename?: "ExternalVendor";
        id: string;
        name: string;
        orgPreferredVendors: Array<{
          __typename?: "OrgPreferredVendor";
          id: string;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
            };
          };
        }>;
      } | null;
      poLinks: Array<{
        __typename?: "POLink";
        id: string;
        release?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          deletedAt?: number | null;
        } | null;
      }>;
    }>;
  };
};

export type FacetsByCategoryQueryVariables = Exact<{
  filter: QueryProductFilterInput;
}>;

export type FacetsByCategoryQuery = {
  __typename?: "Query";
  masterSkus: {
    __typename?: "SkuConnection";
    facets: Array<{
      __typename?: "Facet";
      id: string;
      name: string;
      values: Array<{
        __typename?: "FacetValue";
        id: string;
        name: string;
        value: string;
        count?: number | null;
      }>;
    }>;
    edges: Array<{
      __typename?: "SkuEdge";
      node: { __typename?: "Sku"; id: string };
    }>;
  };
};

export type FieldsPreviewQueryVariables = Exact<{
  input: FieldsPreviewInput;
}>;

export type FieldsPreviewQuery = {
  __typename?: "Query";
  fieldsPreview: Array<{
    __typename?: "FieldPreview";
    name?: string | null;
    hint?: FieldMappingTargetProperty | null;
    records: Array<string>;
  }>;
};

export type ManufacturersQueryVariables = Exact<{
  searchPrefix: Scalars["String"]["input"];
}>;

export type ManufacturersQuery = {
  __typename?: "Query";
  manufacturers: Array<{
    __typename?: "Manufacturer";
    id: string;
    name: string;
  }>;
};

export type MasterProductsQueryVariables = Exact<{
  filter: QueryProductFilterInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MasterProductsQuery = {
  __typename?: "Query";
  masterProducts: {
    __typename?: "ProductConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    facets: Array<{
      __typename?: "Facet";
      id: string;
      name: string;
      values: Array<{
        __typename?: "FacetValue";
        id: string;
        name: string;
        value: string;
        count?: number | null;
      }>;
    }>;
    edges: Array<{
      __typename?: "ProductEdge";
      cursor: string;
      node: {
        __typename?: "Product";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        categories: Array<{
          __typename?: "ProductCategory";
          id: string;
          l1Name: string;
        }>;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
    }>;
    searchContext?: {
      __typename?: "SearchContext";
      query: string;
      terms: Array<string>;
      inferredCategoryId: string;
    } | null;
  };
};

export type MasterSkuQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MasterSkuQuery = {
  __typename?: "Query";
  masterSku?: {
    __typename?: "Sku";
    id: string;
    name: string;
    imageUrl: string;
    description: string;
  } | null;
};

export type MasterSkusByKeywordQueryVariables = Exact<{
  filter: QueryProductFilterInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MasterSkusByKeywordQuery = {
  __typename?: "Query";
  masterSkus: {
    __typename?: "SkuConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "SkuEdge";
      cursor: string;
      node: {
        __typename?: "Sku";
        id: string;
        name: string;
        imageUrl: string;
        description: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
      };
    }>;
    searchContext?: {
      __typename?: "SearchContext";
      terms: Array<string>;
    } | null;
  };
};

export type MessagesQueryVariables = Exact<{
  input: QueryMessagesInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MessagesQuery = {
  __typename?: "Query";
  messages: {
    __typename?: "MessageConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "MessageEdge";
      cursor: string;
      node: {
        __typename?: "Message";
        id: string;
        isPrivate: boolean;
        message: string;
        timeSent: number;
        author: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          photoUrl?: string | null;
          org: { __typename?: "Organization"; id: string; name: string };
        };
      };
    }>;
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean };
  };
};

export type PredictedReceiptNumberQueryVariables = Exact<{
  projectId: Scalars["ID"]["input"];
  sellerOrgLocationId: Scalars["ID"]["input"];
}>;

export type PredictedReceiptNumberQuery = {
  __typename?: "Query";
  predictedReceiptNumber?: string | null;
};

export type PredictedReleaseSequenceNumberQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PredictedReleaseSequenceNumberQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      predictedReleaseSequenceNumber: number;
    };
  } | null;
};

export type PredictedTaxQueryVariables = Exact<{
  input: PredictedTaxInput;
}>;

export type PredictedTaxQuery = {
  __typename?: "Query";
  predictedTax: { __typename?: "PredictedTaxOutput"; taxRate?: string | null };
};

export type ProjectExternalProjectsWithEstimatesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProjectExternalProjectsWithEstimatesQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    id: string;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
      estimates: Array<{
        __typename?: "ExternalEstimate";
        externalId: string;
        sourceId: string;
        sourceSystem: SourceSystem;
        description: string;
      }>;
    }>;
  } | null;
};

export type ProjectListOptionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryProjectsFilter>;
}>;

export type ProjectListOptionsQuery = {
  __typename?: "Query";
  projects: {
    __typename?: "ProjectConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ProjectEdge";
      cursor: string;
      node: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
        status: ProjectStatus;
        startDate?: number | null;
        taxExempt: boolean;
        preferredInventoryInflows: boolean;
        location: { __typename?: "OrgLocation"; id: string; name: string };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        team: Array<{ __typename?: "User"; id: string }>;
        externalProjects: Array<{
          __typename?: "ExternalProject";
          id: string;
          sourceSystem: SourceSystem;
        }>;
      };
    }>;
  };
};

export type ProjectPredictedPoNumberQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProjectPredictedPoNumberQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    id: string;
    predictedPoNumber?: string | null;
  } | null;
};

export type QuoteMessagesContextQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type QuoteMessagesContextQuery = {
  __typename?: "Query";
  quote?: {
    __typename?: "Quote";
    id: string;
    clientIdentifier: string;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      quotes: Array<{
        __typename?: "Quote";
        id: string;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
          }>;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            org: { __typename?: "Organization"; id: string; name: string };
          };
        } | null;
      }>;
    };
  } | null;
};

export type ReleaseQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type ReleaseQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  } | null;
};

export type ReleaseDistributorMessagesContextQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ReleaseDistributorMessagesContextQuery = {
  __typename?: "Query";
  release?: { __typename?: "Release"; id: string } | null;
};

export type ReleaseMessagesContextQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ReleaseMessagesContextQuery = {
  __typename?: "Query";
  release?: { __typename?: "Release"; id: string } | null;
};

export type ReleasePoLinkQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ReleasePoLinkQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    poNumber?: string | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
  } | null;
};

export type ReleaseProjectQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  excludePhantoms?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ReleaseProjectQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    usesProjectCostCodes: boolean;
    id: string;
    name: string;
    status: ProjectStatus;
    budget?: string | null;
    deletedAt?: number | null;
    startDate?: number | null;
    percentageOfBudgetSpent?: string | null;
    jobNumber?: string | null;
    taxExempt: boolean;
    poJobNumber?: string | null;
    restrictCostCodes: boolean;
    preferredInventoryInflows: boolean;
    items: Array<{
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      buyoutItems: Array<{
        __typename?: "BuyoutItem";
        id: string;
        expirationDate?: number | null;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        buyout: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
            };
          };
        };
      }>;
    }>;
    releaseInstructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    aggregatedZones: Array<{
      __typename?: "AggregatedZone";
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      costCodes: Array<{
        __typename?: "AggregatedCostCode";
        costCode?: { __typename?: "CostCode"; id: string } | null;
        items: Array<{
          __typename?: "AggregatedItem";
          projectItem: { __typename?: "ProjectItem"; id: string };
          estimatedItems: Array<{
            __typename?: "EstimatedItem";
            id: string;
            quantityDecimal: string;
            orderedQuantity: string;
            duplicateCount: number;
            unitPrice?: string | null;
            position?: number | null;
            notes?: string | null;
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            tags: Array<{ __typename?: "ProjectTag"; id: string }>;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
            } | null;
            uom: { __typename?: "UOM"; id: string };
          }>;
          buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
        }>;
      }>;
    }>;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
    }>;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    }> | null;
    estimatedItemGroups: Array<{
      __typename?: "EstimatedItemGroup";
      estimatedItemCount: number;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    team: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    }>;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        costCodes: Array<{
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }>;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    zones: Array<{
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    }>;
    permissions: {
      __typename?: "ProjectPermissions";
      edit: AuthorizationStatus;
      createTag: AuthorizationStatus;
      report: AuthorizationStatus;
    };
  } | null;
};

export type ReleasesQueryVariables = Exact<{
  filter?: InputMaybe<QueryReleasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<QueryReleasesSort>;
}>;

export type ReleasesQuery = {
  __typename?: "Query";
  releases: {
    __typename?: "ReleaseConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ReleaseEdge";
      cursor: string;
      node: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        poNumber?: string | null;
        description?: string | null;
        syncVersion: number;
        poExists?: boolean | null;
        isInvoiced: boolean;
        useSourceSystemPO: boolean;
        createdAt: number;
        rejectionReason?: string | null;
        status: ReleaseStatus;
        time?: number | null;
        timeTBD: boolean;
        version: number;
        includeServices: Array<ServiceType>;
        deletedAt?: number | null;
        taxRate?: string | null;
        total?: string | null;
        hasMissingCostCodes: boolean;
        exportedAt?: number | null;
        exportedVersion?: number | null;
        receiptPostedAt?: number | null;
        requiresInventoryReceipt?: boolean | null;
        isBackorder: boolean;
        itemCount: number;
        submittedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        } | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        rejectedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        } | null;
        permissions: {
          __typename?: "ReleasePermissions";
          edit: AuthorizationStatus;
          submit: AuthorizationStatus;
          submitDirectly: AuthorizationStatus;
          approve: AuthorizationStatus;
          archive: AuthorizationStatus;
        };
        siteContact?: {
          __typename?: "SiteContact";
          id: string;
          name?: string | null;
          phone?: string | null;
        } | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
          createdAt: number;
        } | null;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          externalCode?: string | null;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
            receivesOrderNotifications: boolean;
          }>;
        } | null;
        project?: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
          location: { __typename?: "OrgLocation"; id: string };
        } | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
            name: string;
          };
        } | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        issues: Array<{ __typename?: "ReleaseIssue"; id: string }>;
        poLink?: {
          __typename?: "POLink";
          id: string;
          format: PoFormat;
          sourceSystem: SourceSystem;
          externalWarehouseId?: string | null;
          syncing: boolean;
          syncedVersion: number;
          syncedAt?: number | null;
          createdAt: number;
          autoSync: boolean;
          autoSyncError?: string | null;
          autoSyncFailureCount: number;
          retroactive: boolean;
          immutable: boolean;
          failedAt?: number | null;
          failedMessage?: string | null;
          validationErrors: Array<PoValidationError>;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
        backorderFrom: Array<{
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        }>;
        quoteDocument?: {
          __typename?: "QuoteDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        } | null;
        approvalProgress: {
          __typename?: "ReleaseApprovalProgress";
          canApprove: boolean;
          canApproveImmediately: boolean;
          canReject: boolean;
          slots: Array<{
            __typename?: "ReleaseApprovalSlot";
            role?: OrgLocationRole | null;
            ineffectual: boolean;
            approvals: Array<{
              __typename?: "ReleaseApproval";
              id: string;
              roles: Array<OrgLocationRole>;
              createdAt: number;
              approver: {
                __typename?: "User";
                id: string;
                firstName: string;
                lastName: string;
              };
            }>;
          }>;
        };
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
        type: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
        };
        deliverySlips: Array<{
          __typename?: "DeliverySlip";
          id: string;
          archivedAt?: number | null;
        }>;
        noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
        invoices: Array<{
          __typename?: "Invoice";
          id: string;
          number?: string | null;
          issueDate?: number | null;
          archivedAt?: number | null;
        }>;
        receipts: Array<{
          __typename?: "Invoice";
          id: string;
          number?: string | null;
          issueDate?: number | null;
          archivedAt?: number | null;
        }>;
        groups: Array<{
          __typename?: "ReleaseGroup";
          id: string;
          name: string;
        }>;
        warehouse?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
        } | null;
        sourceWarehouse?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
        } | null;
      };
    }>;
  };
};

export type ReleasesSelectorQueryVariables = Exact<{
  filter?: InputMaybe<QueryReleasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ReleasesSelectorQuery = {
  __typename?: "Query";
  releases: {
    __typename?: "ReleaseConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ReleaseEdge";
      cursor: string;
      node: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        poNumber?: string | null;
        time?: number | null;
        netAmount: string;
        total?: string | null;
        status: ReleaseStatus;
        version: number;
        taxRate?: string | null;
        taxAmount?: string | null;
        customTaxAmount?: string | null;
        taxVariance?: string | null;
        taxType?: TaxType | null;
        project?: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
        } | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
        } | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
            name: string;
          };
        } | null;
        type: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
          includeAdditionalCharges: boolean;
          poFormat?: PoFormat | null;
          default: boolean;
          taxMode?: TaxMode | null;
          defaultAutoSync: boolean;
        };
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        taxCode?: {
          __typename?: "TaxCode";
          id: string;
          code: string;
          description?: string | null;
          rate: string;
        } | null;
      };
    }>;
  };
};

export type RfqQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RfqQuery = {
  __typename?: "Query";
  rfq?: {
    __typename?: "Rfq";
    id: string;
    createdAt: number;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    deletedAt?: number | null;
    description?: string | null;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      budget?: string | null;
      deletedAt?: number | null;
      startDate?: number | null;
      percentageOfBudgetSpent?: string | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      poJobNumber?: string | null;
      restrictCostCodes: boolean;
      usesProjectCostCodes: boolean;
      preferredInventoryInflows: boolean;
      aggregatedZones: Array<{
        __typename?: "AggregatedZone";
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costCodes: Array<{
          __typename?: "AggregatedCostCode";
          costCode?: { __typename?: "CostCode"; id: string } | null;
          items: Array<{
            __typename?: "AggregatedItem";
            projectItem: { __typename?: "ProjectItem"; id: string };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              position?: number | null;
              notes?: string | null;
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
              tags: Array<{ __typename?: "ProjectTag"; id: string }>;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
              uom: { __typename?: "UOM"; id: string };
            }>;
            buyoutItems?: Array<{
              __typename?: "BuyoutItem";
              id: string;
            }> | null;
          }>;
        }>;
      }>;
      externalProjects: Array<{
        __typename?: "ExternalProject";
        id: string;
        sourceSystem: SourceSystem;
      }>;
      estimatedItemGroups: Array<{
        __typename?: "EstimatedItemGroup";
        estimatedItemCount: number;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      }>;
      team: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        photoUrl?: string | null;
      }>;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          costCodes: Array<{
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          }>;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      items: Array<{
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      }>;
      zones: Array<{
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      }>;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
      permissions: {
        __typename?: "ProjectPermissions";
        edit: AuthorizationStatus;
        createTag: AuthorizationStatus;
        report: AuthorizationStatus;
      };
    };
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    requisition?: { __typename?: "Requisition"; id: string } | null;
    items: Array<{
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      position?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      contacts: Array<{ __typename?: "VendorContact"; id: string }>;
    }>;
    nextRevision?: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      status: RfqStatus;
    } | null;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      status: QuoteStatus;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        subtotal: string;
        poExists?: boolean | null;
        description?: string | null;
        createdAt: number;
        status: BuyoutStatus;
        includedServices: Array<ServiceType>;
        poNumber?: string | null;
        version: number;
        paymentTermDays?: number | null;
        notes?: string | null;
        taxAmount?: string | null;
        taxRate?: string | null;
        netAmount: string;
        taxType?: TaxType | null;
        customTaxAmount?: string | null;
        additionalChargesAllowance?: string | null;
        exportedAt?: number | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        releases: Array<{
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
        }>;
        quote?: {
          __typename?: "Quote";
          id: string;
          notes?: string | null;
          expirationDate?: number | null;
          paymentTermDays?: number | null;
          additionalCharges: Array<{
            __typename?: "Charge";
            id: string;
            description: string;
            amount: string;
          }>;
          discount?: {
            __typename?: "Discount";
            amount?: string | null;
            percentage?: string | null;
          } | null;
          rfq: {
            __typename?: "Rfq";
            id: string;
            clientIdentifier: string;
            includeServices: Array<ServiceType>;
            status: RfqStatus;
            createdBy: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              photoUrl?: string | null;
            };
            quotes: Array<{
              __typename?: "Quote";
              id: string;
              buyout?: {
                __typename?: "Buyout";
                id: string;
                clientIdentifier: string;
                status: BuyoutStatus;
                sellerOrgLocation: {
                  __typename?: "OrgLocation";
                  id: string;
                  org: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                    photoUrl?: string | null;
                  };
                };
              } | null;
            }>;
          };
        } | null;
        quoteDocument?: {
          __typename?: "QuoteDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        } | null;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          taxExempt: boolean;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
            cellPhone: string;
            email: string;
            receivesBuyoutNotifications: boolean;
          }>;
        } | null;
        releaseType: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
          includeAdditionalCharges: boolean;
          poFormat?: PoFormat | null;
          default: boolean;
          taxMode?: TaxMode | null;
          defaultAutoSync: boolean;
        };
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          status: ProjectStatus;
          startDate?: number | null;
          jobNumber?: string | null;
          taxExempt: boolean;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
          location: { __typename?: "OrgLocation"; id: string };
          permissions: {
            __typename?: "ProjectPermissions";
            report: AuthorizationStatus;
          };
        };
        items: Array<{
          __typename?: "BuyoutItem";
          id: string;
          quantityDecimal: string;
          unitPrice: string;
          requestedUnitPrice?: string | null;
          releasedAmount?: string | null;
          completedAmount?: string | null;
          expirationDate?: number | null;
          notes?: string | null;
          isIncluded: boolean;
          position?: number | null;
          description: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          projectItem: {
            __typename?: "ProjectItem";
            id: string;
            material: {
              __typename?: "OrgMaterial";
              id: string;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
            };
            estimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              notes?: string | null;
              position?: number | null;
              zone?: {
                __typename?: "Zone";
                id: string;
                name: string;
                inUse: boolean;
              } | null;
              tags: Array<{
                __typename?: "ProjectTag";
                id: string;
                name: string;
                color: string;
                deletedAt?: number | null;
                hasMapping: boolean;
              }>;
              manufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
                inUse: boolean;
                mappings: Array<{
                  __typename?: "SourceSystemMapping";
                  externalId: string;
                  sourceSystem: SourceSystem;
                }>;
              } | null;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
            }>;
            manufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
            buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
          };
          rfqItem?: {
            __typename?: "RfqItem";
            id: string;
            description: string;
            quantityDecimal: string;
          } | null;
          orgCatalogSku?: {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
          } | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          masterSku?: {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: {
              __typename?: "Manufacturer";
              url?: string | null;
              id: string;
              name: string;
            };
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
        }>;
        standaloneReleaseItems: Array<{
          __typename?: "BuyoutStandaloneReleaseItem";
          totalQuantity: string;
          unitPrice: string;
          orderedSoFar: string;
          receivedSoFar: string;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          orgMaterial: {
            __typename?: "OrgMaterial";
            id: string;
            externalCode?: string | null;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            defaultEstimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            referenceCounts: {
              __typename?: "MaterialReferenceCounts";
              estimatedItems: number;
              rfqItems: number;
              buyoutItems: number;
              releaseItems: number;
            };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
              inUse: boolean;
              mappings: Array<{
                __typename?: "SourceSystemMapping";
                externalId: string;
                sourceSystem: SourceSystem;
              }>;
            } | null;
            vendorPrices: Array<{
              __typename?: "VendorPrice";
              id: string;
              orgMaterialId: string;
              price: string;
              expirationDate?: number | null;
              orderIncrement?: number | null;
              minimumOrder?: number | null;
              leadTime?: number | null;
              sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
              uom: { __typename?: "UOM"; id: string };
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            }>;
            externalItems: Array<{
              __typename?: "ExternalItem";
              id: string;
              externalId: string;
              name: string;
              purchasingUom?: string | null;
              defaultCostCode?: string | null;
              sourceSystem: SourceSystem;
              sourceId: string;
            }>;
          };
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
        }>;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        taxCode?: {
          __typename?: "TaxCode";
          id: string;
          code: string;
          description?: string | null;
          rate: string;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        poLink?: {
          __typename?: "POLink";
          id: string;
          format: PoFormat;
          sourceSystem: SourceSystem;
          externalWarehouseId?: string | null;
          syncing: boolean;
          syncedVersion: number;
          syncedAt?: number | null;
          createdAt: number;
          autoSync: boolean;
          autoSyncError?: string | null;
          autoSyncFailureCount: number;
          retroactive: boolean;
          immutable: boolean;
          failedAt?: number | null;
          failedMessage?: string | null;
          validationErrors: Array<PoValidationError>;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
        vendorContacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    permissions: {
      __typename?: "RfqPermissions";
      archive: AuthorizationStatus;
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
    };
  } | null;
};

export type RfqQuotesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  quoteStatus?: InputMaybe<Array<QuoteStatus> | QuoteStatus>;
}>;

export type RfqQuotesQuery = {
  __typename?: "Query";
  rfq?: {
    __typename?: "Rfq";
    id: string;
    includeServices: Array<ServiceType>;
    clientIdentifier: string;
    status: RfqStatus;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    nextRevision?: { __typename?: "Rfq"; id: string } | null;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    }>;
    items: Array<{
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      position?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      expirationDate?: number | null;
      notes?: string | null;
      status: QuoteStatus;
      paymentTermDays?: number | null;
      submittedDate?: number | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        taxExempt: boolean;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          receivesQuoteNotifications: boolean;
        }>;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          name: string;
          id: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        };
      } | null;
      itemGroups: Array<{
        __typename?: "QuoteItemGroup";
        rfqItem: { __typename?: "RfqItem"; id: string };
        quoteItems: Array<{
          __typename?: "QuoteItem";
          id: string;
          unitPrice?: string | null;
          status: QuoteStatus;
          description?: string | null;
          notes?: string | null;
          expirationDate?: number | null;
          quantityDecimal?: string | null;
          leadTimeDays?: number | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          masterSku?: {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
          } | null;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          orgCatalogSku?: {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
          } | null;
        }>;
      }>;
      auxiliaryItems: Array<{
        __typename?: "AuxiliaryQuoteItem";
        id: string;
        unitPrice?: string | null;
        description?: string | null;
        notes?: string | null;
        expirationDate?: number | null;
        quantityDecimal?: string | null;
        leadTimeDays?: number | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      }>;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      servicePriceSheet?: {
        __typename?: "ServicePriceSheet";
        id: string;
        prices: Array<{
          __typename?: "ServicePrice";
          id: string;
          name: ServiceName;
          productCategory: ServiceProductCategoryName;
          type: ServiceType;
          regularHoursAmount?: string | null;
          offHoursAmount?: string | null;
          regularHoursUOM: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          offHoursUOM: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }>;
        discount?: {
          __typename?: "Discount";
          amount?: string | null;
          percentage?: string | null;
        } | null;
      } | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
    permissions: {
      __typename?: "RfqPermissions";
      archive: AuthorizationStatus;
      edit: AuthorizationStatus;
      exportQuotes: AuthorizationStatus;
    };
  } | null;
};

export type RfqVendorsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RfqVendorsQuery = {
  __typename?: "Query";
  rfq?: {
    __typename?: "Rfq";
    id: string;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      clientIdentifier: string;
      status: QuoteStatus;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          email: string;
          cellPhone: string;
          receivesQuoteNotifications: boolean;
        }>;
      } | null;
    }>;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesQuoteNotifications: boolean;
      }>;
    }>;
  } | null;
};

export type RfqsQueryVariables = Exact<{
  filter?: InputMaybe<QueryRfQsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  quoteStatus?: InputMaybe<Array<QuoteStatus> | QuoteStatus>;
}>;

export type RfqsQuery = {
  __typename?: "Query";
  rfqs: {
    __typename?: "RfqConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "RfqEdge";
      cursor: string;
      node: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        deletedAt?: number | null;
        status: RfqStatus;
        createdAt: number;
        numBiddersRfqSentTo: number;
        numBiddersRfqViewedBy: number;
        description?: string | null;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          status: ProjectStatus;
          jobNumber?: string | null;
        };
        items: Array<{
          __typename?: "RfqItem";
          id: string;
          rfqId: string;
          quantityDecimal: string;
        }>;
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          status: QuoteStatus;
          submittedDate?: number | null;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            poNumber?: string | null;
          } | null;
          releases: Array<{
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
            poNumber?: string | null;
            status: ReleaseStatus;
            deletedAt?: number | null;
          }>;
        }>;
        nextRevision?: {
          __typename?: "Rfq";
          id: string;
          clientIdentifier: string;
        } | null;
        previousRevision?: {
          __typename?: "Rfq";
          id: string;
          clientIdentifier: string;
        } | null;
        noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
        permissions: {
          __typename?: "RfqPermissions";
          archive: AuthorizationStatus;
          edit: AuthorizationStatus;
          submit: AuthorizationStatus;
        };
      };
    }>;
  };
};

export type StartupDataQueryVariables = Exact<{ [key: string]: never }>;

export type StartupDataQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    enterprise?: {
      __typename?: "Enterprise";
      id: string;
      name: string;
      orgs: Array<{
        __typename?: "Organization";
        id: string;
        name: string;
        notificationCount: number;
      }>;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      settings?: {
        __typename?: "OrgSettings";
        releaseApproval: {
          __typename?: "ReleaseApprovalSettings";
          enabled: boolean;
          standaloneOnly: boolean;
          sourceWarehouse: boolean;
          reserved: boolean;
          exemptRoles: Array<OrgLocationRole>;
          approverRoles: Array<OrgLocationRole>;
          submitterRoles: Array<OrgLocationRole>;
          requiredApprovals: number;
          requiredApproverRoles: Array<OrgLocationRole>;
        };
        releases?: {
          __typename?: "ReleaseSettings";
          poNumbering: PoNumberingMode;
          notifications: ReleaseNotificationsMode;
          useSourceSystemPOs: boolean;
          poNumberSuffixPreview: string;
          defaultWarehouse?: {
            __typename?: "Warehouse";
            id: string;
            name: string;
          } | null;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
        } | null;
        invoices: {
          __typename?: "InvoiceSettings";
          localPart?: string | null;
          domain: string;
          defaultPaymentTerm?: number | null;
          requireCostCodesDuringApproval: boolean;
          approval?: {
            __typename?: "InvoiceApprovalSettings";
            roles: Array<{
              __typename?: "InvoiceApprovalRole";
              role: OrgLocationRole;
              maxAmount?: string | null;
              notificationThreshold?: string | null;
            }>;
          } | null;
        };
        integrations: {
          __typename?: "IntegrationSettings";
          org: { __typename?: "Organization"; id: string };
          accounting: Array<{
            __typename?: "AccountingSettings";
            id: string;
            toJobLedgerAccount?: string | null;
            distributeAdditionalCharges?: boolean | null;
            materialCostType?: string | null;
            enabled: boolean;
            integration: IntegrationType;
            postInventoryReceipts?: boolean | null;
            inventoryReceiptLedgerAccount?: string | null;
            progress: {
              __typename?: "IntegrationMappingProgress";
              costCodes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              orgMaterials: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              projects: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              vendors: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              releaseTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              costTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
            };
            additionalChargesCostCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
            taxAuthorities: Array<{
              __typename?: "TaxAuthority";
              code: string;
              rate: string;
            }>;
          }>;
          sourceSystems: Array<{
            __typename?: "SourceSystemSettings";
            defaultAutoSync?: boolean | null;
            postInventoryReceipts?: boolean | null;
            autoPostPOs?: boolean | null;
            autoPostInvoices?: boolean | null;
            autoExportApprovedInvoices?: boolean | null;
            autoCloseProjectsByJobStatus: boolean;
            system: SourceSystem;
            companyName: string;
            companyId: string;
            connected: boolean;
            wbsTags?: boolean | null;
            poNameTemplate?: string | null;
            poDescriptionTemplate?: string | null;
            invoiceDescriptionTemplate?: string | null;
            accountsPayableLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
            toJobLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
            toWarehouseLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
            materialCostType?: {
              __typename?: "ExternalCostType";
              externalId: string;
              sourceId: string;
              code: string;
              name: string;
              collidesWith?: {
                __typename?: "CostType";
                id: string;
                code: string;
                description: string;
              } | null;
            } | null;
            progress: {
              __typename?: "IntegrationMappingProgress";
              costCodes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              orgMaterials: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              projects: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              vendors: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              releaseTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              costTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
            };
            inventoryReceiptLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              name: string;
            } | null;
            customPOTemplates: Array<{
              __typename?: "SourceSystemSettingCustomTemplate";
              name: string;
              template: string;
            }>;
            customInvoiceTemplates: Array<{
              __typename?: "SourceSystemSettingCustomTemplate";
              name: string;
              template: string;
            }>;
          }>;
        };
        display?: {
          __typename?: "DisplaySettings";
          enableManufacturers: boolean;
          itemCostTypes: boolean;
          enableAdditionalCharges: boolean;
          requireProjectAssignment: boolean;
          enableTaxVariance: boolean;
          defaultItemized?: boolean | null;
          receiptDefaultItemized?: boolean | null;
          usesProjectCostCodes: boolean;
          appendExternalCode?: boolean | null;
          currency: {
            __typename?: "Currency";
            id: string;
            code: string;
            name: string;
            symbol: string;
          };
        } | null;
        inventory: { __typename?: "InventorySettings"; enabled: boolean };
        projectSettings: {
          __typename?: "ProjectSettings";
          defaultTeam: Array<{
            __typename?: "OrgLocationDefaultProjectTeam";
            orgLocation: { __typename?: "OrgLocation"; id: string };
            users: Array<{ __typename?: "User"; id: string }>;
          }>;
        };
      } | null;
      costCodes: Array<{
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        ledgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          sourceId: string;
          sourceSystem: SourceSystem;
          name: string;
          number: string;
        } | null;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }>;
      costTypes: Array<{
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }>;
      uoms: Array<{
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      }>;
      warehouses: Array<{
        __typename?: "Warehouse";
        id: string;
        name: string;
        externalCode?: string | null;
        archivedAt?: number | null;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      }>;
      invoiceFolders: Array<{
        __typename?: "InvoiceFolder";
        id: string;
        name: string;
        createdAt: number;
      }>;
      releaseGroups: Array<{
        __typename?: "ReleaseGroup";
        id: string;
        name: string;
        createdAt: number;
      }>;
      locations: Array<{
        __typename?: "OrgLocation";
        id: string;
        name: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        permissions: {
          __typename?: "OrgLocationPermissions";
          createProject: AuthorizationStatus;
          createInvoice: AuthorizationStatus;
          createDeliverySlip: AuthorizationStatus;
          createQuoteDocument: AuthorizationStatus;
          submitReleaseDirectly: AuthorizationStatus;
          createRelease: AuthorizationStatus;
          createBuyout: AuthorizationStatus;
          createRfq: AuthorizationStatus;
          reserveRelease: AuthorizationStatus;
        };
      }>;
      complianceGroups: Array<{
        __typename?: "ComplianceGroup";
        id: string;
        code: string;
        description?: string | null;
        notes?: string | null;
      }>;
    };
  } | null;
};

export type UomQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UomQuery = {
  __typename?: "Query";
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
};

export type UomsQueryVariables = Exact<{ [key: string]: never }>;

export type UomsQuery = {
  __typename?: "Query";
  uoms: Array<{
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  }>;
};

export type UsersQueryVariables = Exact<{
  orgLocationID?: InputMaybe<Scalars["ID"]["input"]>;
  projectID?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User";
    id: string;
    email: string;
    cellPhone: string;
    createdAt: number;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
    isEmailValidated: boolean;
    isOrgAdmin?: boolean | null;
    orgRoles: Array<OrgRole>;
    enterpriseRoles: Array<EnterpriseRole>;
    org: {
      __typename?: "Organization";
      id: string;
      locations: Array<{
        __typename?: "OrgLocation";
        id: string;
        name: string;
      }>;
    };
    locationRoles: Array<{
      __typename?: "LocationRole";
      orgLocID: string;
      role: OrgLocationRole;
    }>;
  }>;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    firstName: string;
    sessionId?: string | null;
    lastName: string;
    email: string;
    cellPhone: string;
    photoUrl?: string | null;
    isPhoneValidated: boolean;
    getSmsNotifications: boolean;
    getEmailNotifications: boolean;
    systemRoles: Array<SystemRole>;
    orgRoles: Array<OrgRole>;
    isExternal: boolean;
    enterpriseRoles: Array<EnterpriseRole>;
    authId: string;
    locationRoles: Array<{
      __typename?: "LocationRole";
      orgLocID: string;
      role: OrgLocationRole;
    }>;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      hqAddress?: {
        __typename?: "Address";
        id: string;
        country: string;
      } | null;
    };
  } | null;
};

export type ViewerOrgQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerOrgQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      ein?: string | null;
      phone?: string | null;
      photoUrl?: string | null;
      hqAddress?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      settings?: {
        __typename?: "OrgSettings";
        invoices: {
          __typename?: "InvoiceSettings";
          localPart?: string | null;
          domain: string;
          defaultPaymentTerm?: number | null;
        };
        releases?: {
          __typename?: "ReleaseSettings";
          poNumbering: PoNumberingMode;
          notifications: ReleaseNotificationsMode;
        } | null;
        display?: {
          __typename?: "DisplaySettings";
          enableManufacturers: boolean;
          currency: {
            __typename?: "Currency";
            id: string;
            code: string;
            name: string;
            symbol: string;
          };
        } | null;
        projectSettings: {
          __typename?: "ProjectSettings";
          defaultTeam: Array<{
            __typename?: "OrgLocationDefaultProjectTeam";
            orgLocation: { __typename?: "OrgLocation"; id: string };
            users: Array<{ __typename?: "User"; id: string }>;
          }>;
        };
      } | null;
      enterprise?: { __typename?: "Enterprise"; id: string } | null;
    };
  } | null;
};

export type CostCodeFieldsFragment = {
  __typename?: "CostCode";
  id: string;
  code: string;
  description: string;
  inUse: boolean;
  mappings: Array<{
    __typename?: "SourceSystemMapping";
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
};

export type AddCostCodeMutationVariables = Exact<{
  input: AddCostCodeInput;
}>;

export type AddCostCodeMutation = {
  __typename?: "Mutation";
  addCostCode: Array<{
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  }>;
};

export type ConnectCostCodeLedgerAccountMutationVariables = Exact<{
  input: ConnectNodeInput;
}>;

export type ConnectCostCodeLedgerAccountMutation = {
  __typename?: "Mutation";
  connectCostCodeLedgerAccount: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      sourceSystem: SourceSystem;
      name: string;
      number: string;
    } | null;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  };
};

export type DisconnectCostCodeLedgerAccountMutationVariables = Exact<{
  input: DisconnectNodeInput;
}>;

export type DisconnectCostCodeLedgerAccountMutation = {
  __typename?: "Mutation";
  disconnectCostCodeLedgerAccount: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      sourceSystem: SourceSystem;
      name: string;
      number: string;
    } | null;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  };
};

export type ImportCostCodesMutationVariables = Exact<{
  input: ImportCostCodesInput;
}>;

export type ImportCostCodesMutation = {
  __typename?: "Mutation";
  importCostCodes: {
    __typename?: "ImportCostCodesOutput";
    created: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
    skipped: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
    mapped: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
  };
};

export type RemoveCostCodeMutationVariables = Exact<{
  costCodeID: Scalars["ID"]["input"];
}>;

export type RemoveCostCodeMutation = {
  __typename?: "Mutation";
  removeCostCode: Array<{
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  }>;
};

export type UpdateCostCodeMutationVariables = Exact<{
  input: UpdateCostCodeInput;
}>;

export type UpdateCostCodeMutation = {
  __typename?: "Mutation";
  updateCostCode: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  };
};

export type UpdateCostCodesMutationVariables = Exact<{
  input: UpdateCostCodesInput;
}>;

export type UpdateCostCodesMutation = {
  __typename?: "Mutation";
  updateCostCodes: Array<{
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  }>;
};

export type UpdateCostCodesWithFileMutationVariables = Exact<{
  input: UpdateCostCodesWithFileInput;
}>;

export type UpdateCostCodesWithFileMutation = {
  __typename?: "Mutation";
  updateCostCodesWithFile: Array<{
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  }>;
};

export type CostTypeFieldsFragment = {
  __typename?: "CostType";
  id: string;
  code: string;
  description: string;
  org: { __typename?: "Organization"; id: string };
  mappings: Array<{
    __typename?: "SourceSystemMapping";
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
};

export type ImportCostTypesMutationVariables = Exact<{
  input: ImportCostTypesInput;
}>;

export type ImportCostTypesMutation = {
  __typename?: "Mutation";
  importCostTypes: {
    __typename?: "ImportCostTypesOutput";
    created: Array<{
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
    mapped: Array<{
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
    skipped: Array<{
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }>;
  };
};

export type UpdateCostTypesMutationVariables = Exact<{
  input: UpdateCostTypesInput;
}>;

export type UpdateCostTypesMutation = {
  __typename?: "Mutation";
  updateCostTypes: Array<{
    __typename?: "CostType";
    id: string;
    code: string;
    description: string;
    org: { __typename?: "Organization"; id: string };
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  }>;
};

export type CostTypesQueryVariables = Exact<{ [key: string]: never }>;

export type CostTypesQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      costTypes: Array<{
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }>;
    };
  } | null;
};

export type EquipmentItemFieldsFragment = {
  __typename?: "EquipmentItem";
  id: string;
  code: string;
  description: string;
  externalEquipmentItem?: {
    __typename?: "ExternalEquipmentItem";
    id: string;
    externalId: string;
    code: string;
    name: string;
    sourceSystem: SourceSystem;
  } | null;
};

export type ExternalEquipmentItemFieldsFragment = {
  __typename?: "ExternalEquipmentItem";
  id: string;
  externalId: string;
  code: string;
  name: string;
  sourceSystem: SourceSystem;
};

export type ImportEquipmentItemsMutationVariables = Exact<{
  input: ImportEquipmentItemsInput;
}>;

export type ImportEquipmentItemsMutation = {
  __typename?: "Mutation";
  importEquipmentItems: {
    __typename?: "ImportEquipmentItemsOutput";
    created: Array<{
      __typename?: "EquipmentItem";
      id: string;
      code: string;
      description: string;
      externalEquipmentItem?: {
        __typename?: "ExternalEquipmentItem";
        id: string;
        externalId: string;
        code: string;
        name: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
    mapped: Array<{
      __typename?: "EquipmentItem";
      id: string;
      code: string;
      description: string;
      externalEquipmentItem?: {
        __typename?: "ExternalEquipmentItem";
        id: string;
        externalId: string;
        code: string;
        name: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
    skipped: Array<{
      __typename?: "EquipmentItem";
      id: string;
      code: string;
      description: string;
      externalEquipmentItem?: {
        __typename?: "ExternalEquipmentItem";
        id: string;
        externalId: string;
        code: string;
        name: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
  };
};

export type UpdateEquipmentItemsMutationVariables = Exact<{
  input: UpdateEquipmentItemsInput;
}>;

export type UpdateEquipmentItemsMutation = {
  __typename?: "Mutation";
  updateEquipmentItems: Array<{
    __typename?: "EquipmentItem";
    id: string;
    code: string;
    description: string;
    externalEquipmentItem?: {
      __typename?: "ExternalEquipmentItem";
      id: string;
      externalId: string;
      code: string;
      name: string;
      sourceSystem: SourceSystem;
    } | null;
  }>;
};

export type EquipmentItemsQueryVariables = Exact<{ [key: string]: never }>;

export type EquipmentItemsQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      equipmentItems: Array<{
        __typename?: "EquipmentItem";
        id: string;
        code: string;
        description: string;
        externalEquipmentItem?: {
          __typename?: "ExternalEquipmentItem";
          id: string;
          externalId: string;
          code: string;
          name: string;
          sourceSystem: SourceSystem;
        } | null;
      }>;
    };
  } | null;
};

export type ExternalEquipmentItemsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalEquipmentItemsQuery = {
  __typename?: "Query";
  externalEquipmentItems: Array<{
    __typename?: "ExternalEquipmentItem";
    id: string;
    externalId: string;
    code: string;
    name: string;
    sourceSystem: SourceSystem;
  }>;
};

export type ExternalPaymentMethodFieldsFragment = {
  __typename?: "ExternalBankAccount";
  id: string;
  externalId: string;
  sourceSystem: SourceSystem;
  name: string;
  number?: string | null;
  description?: string | null;
  type: string;
};

export type ImportPaymentMethodsMutationVariables = Exact<{
  input: ImportPaymentMethodsInput;
}>;

export type ImportPaymentMethodsMutation = {
  __typename?: "Mutation";
  importPaymentMethods: {
    __typename?: "ImportPaymentMethodsOutput";
    created: Array<{
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    }>;
    mapped: Array<{
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    }>;
    skipped: Array<{
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type UpdatePaymentMethodsMutationVariables = Exact<{
  input: UpdatePaymentMethodsInput;
}>;

export type UpdatePaymentMethodsMutation = {
  __typename?: "Mutation";
  updatePaymentMethods: Array<{
    __typename?: "PaymentMethod";
    id: string;
    number?: string | null;
    type: PaymentMethodType;
    description: string;
    default: boolean;
    externalBankAccount?: {
      __typename?: "ExternalBankAccount";
      id: string;
      name: string;
    } | null;
  }>;
};

export type ExternalPaymentMethodsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalPaymentMethodsQuery = {
  __typename?: "Query";
  externalBankAccounts: Array<{
    __typename?: "ExternalBankAccount";
    id: string;
    externalId: string;
    sourceSystem: SourceSystem;
    name: string;
    number?: string | null;
    description?: string | null;
    type: string;
  }>;
};

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      paymentMethods: Array<{
        __typename?: "PaymentMethod";
        id: string;
        number?: string | null;
        type: PaymentMethodType;
        description: string;
        default: boolean;
        externalBankAccount?: {
          __typename?: "ExternalBankAccount";
          id: string;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type ExternalServiceCodeFieldsFragment = {
  __typename?: "ExternalServiceCode";
  id: string;
  externalId: string;
  code: string;
  name: string;
  sourceSystem: SourceSystem;
};

export type ServiceCodeFieldsFragment = {
  __typename?: "ServiceCode";
  id: string;
  code: string;
  description: string;
  externalServiceCode?: {
    __typename?: "ExternalServiceCode";
    id: string;
    externalId: string;
    code: string;
    name: string;
    sourceSystem: SourceSystem;
  } | null;
};

export type ImportServiceCodesMutationVariables = Exact<{
  input: ImportServiceCodesInput;
}>;

export type ImportServiceCodesMutation = {
  __typename?: "Mutation";
  importServiceCodes: {
    __typename?: "ImportServiceCodesOutput";
    created: Array<{
      __typename?: "ServiceCode";
      id: string;
      code: string;
      description: string;
      externalServiceCode?: {
        __typename?: "ExternalServiceCode";
        id: string;
        externalId: string;
        code: string;
        name: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
    mapped: Array<{
      __typename?: "ServiceCode";
      id: string;
      code: string;
      description: string;
      externalServiceCode?: {
        __typename?: "ExternalServiceCode";
        id: string;
        externalId: string;
        code: string;
        name: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
    skipped: Array<{
      __typename?: "ServiceCode";
      id: string;
      code: string;
      description: string;
      externalServiceCode?: {
        __typename?: "ExternalServiceCode";
        id: string;
        externalId: string;
        code: string;
        name: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
  };
};

export type UpdateServiceCodesMutationVariables = Exact<{
  input: UpdateServiceCodesInput;
}>;

export type UpdateServiceCodesMutation = {
  __typename?: "Mutation";
  updateServiceCodes: Array<{
    __typename?: "ServiceCode";
    id: string;
    code: string;
    description: string;
    externalServiceCode?: {
      __typename?: "ExternalServiceCode";
      id: string;
      externalId: string;
      code: string;
      name: string;
      sourceSystem: SourceSystem;
    } | null;
  }>;
};

export type ExternalServiceCodesQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalServiceCodesQuery = {
  __typename?: "Query";
  externalServiceCodes: Array<{
    __typename?: "ExternalServiceCode";
    id: string;
    externalId: string;
    code: string;
    name: string;
    sourceSystem: SourceSystem;
  }>;
};

export type ServiceCodesQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceCodesQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      serviceCodes: Array<{
        __typename?: "ServiceCode";
        id: string;
        code: string;
        description: string;
        externalServiceCode?: {
          __typename?: "ExternalServiceCode";
          id: string;
          externalId: string;
          code: string;
          name: string;
          sourceSystem: SourceSystem;
        } | null;
      }>;
    };
  } | null;
};

export type ExternalTaxCodeFieldsFragment = {
  __typename?: "ExternalTaxCode";
  externalId: string;
  sourceId: string;
  sourceSystem: SourceSystem;
  code: string;
  description: string;
  totalRate: string;
};

export type TaxCodeFieldsFragment = {
  __typename?: "TaxCode";
  id: string;
  code: string;
  description?: string | null;
  rate: string;
  archivedAt?: number | null;
  externalTaxCode?: {
    __typename?: "ExternalTaxCode";
    externalId: string;
    sourceId: string;
    sourceSystem: SourceSystem;
  } | null;
};

export type ImportTaxCodesMutationVariables = Exact<{
  input: ImportTaxCodesInput;
}>;

export type ImportTaxCodesMutation = {
  __typename?: "Mutation";
  importTaxCodes: {
    __typename?: "ImportTaxCodesOutput";
    created: Array<{
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
      archivedAt?: number | null;
      externalTaxCode?: {
        __typename?: "ExternalTaxCode";
        externalId: string;
        sourceId: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
    skipped: Array<{
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
      archivedAt?: number | null;
      externalTaxCode?: {
        __typename?: "ExternalTaxCode";
        externalId: string;
        sourceId: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
    mapped: Array<{
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
      archivedAt?: number | null;
      externalTaxCode?: {
        __typename?: "ExternalTaxCode";
        externalId: string;
        sourceId: string;
        sourceSystem: SourceSystem;
      } | null;
    }>;
  };
};

export type UpdateTaxCodesMutationVariables = Exact<{
  input: UpdateTaxCodesInput;
}>;

export type UpdateTaxCodesMutation = {
  __typename?: "Mutation";
  updateTaxCodes: Array<{
    __typename?: "TaxCode";
    id: string;
    code: string;
    description?: string | null;
    rate: string;
    archivedAt?: number | null;
    externalTaxCode?: {
      __typename?: "ExternalTaxCode";
      externalId: string;
      sourceId: string;
      sourceSystem: SourceSystem;
    } | null;
  }>;
};

export type ExternalTaxCodesQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalTaxCodesQuery = {
  __typename?: "Query";
  externalTaxCodes: Array<{
    __typename?: "ExternalTaxCode";
    externalId: string;
    sourceId: string;
    sourceSystem: SourceSystem;
    code: string;
    description: string;
    totalRate: string;
  }>;
};

export type ViewerTaxCodesQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerTaxCodesQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      taxCodes: Array<{
        __typename?: "TaxCode";
        id: string;
        code: string;
        description?: string | null;
        rate: string;
        archivedAt?: number | null;
        externalTaxCode?: {
          __typename?: "ExternalTaxCode";
          externalId: string;
          sourceId: string;
          sourceSystem: SourceSystem;
          code: string;
          description: string;
          totalRate: string;
        } | null;
      }>;
    };
  } | null;
};

export type SystemReportQueryVariables = Exact<{
  excludedOrgIDs?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  from: Scalars["String"]["input"];
  to: Scalars["String"]["input"];
}>;

export type SystemReportQuery = { __typename?: "Query"; systemReport: string };

export type ExternalCostCodeFieldsFragment = {
  __typename?: "ExternalCostCode";
  externalId: string;
  sourceId: string;
  code: string;
  name: string;
  costCode?: { __typename?: "CostCode"; id: string } | null;
  collidesWith?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
};

export type ExternalCostTypeFieldsFragment = {
  __typename?: "ExternalCostType";
  externalId: string;
  sourceId: string;
  code: string;
  name: string;
  collidesWith?: {
    __typename?: "CostType";
    id: string;
    code: string;
    description: string;
  } | null;
};

export type ExternalLedgerAccountFieldsFragment = {
  __typename?: "ExternalLedgerAccount";
  externalId: string;
  sourceId: string;
  name: string;
  number: string;
  subaccounts?: Array<{
    __typename?: "ExternalLedgerSubaccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
  }> | null;
};

export type ExternalLedgerSubaccountFieldsFragment = {
  __typename?: "ExternalLedgerSubaccount";
  externalId: string;
  sourceId: string;
  name: string;
  number: string;
};

export type ExternalVendorFieldsFragment = {
  __typename?: "ExternalVendor";
  id: string;
  externalId: string;
  sourceId: string;
  name: string;
  email?: string | null;
  phone?: string | null;
  address?: {
    __typename?: "ExternalAddress";
    city?: string | null;
    country?: string | null;
    postalCode?: string | null;
    state?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
  } | null;
};

export type PreviewCustomInvoiceTemplatesMutationVariables = Exact<{
  input: PreviewCustomInvoiceTemplateInput;
}>;

export type PreviewCustomInvoiceTemplatesMutation = {
  __typename?: "Mutation";
  previewCustomInvoiceTemplates?: Array<string> | null;
};

export type PreviewCustomPoTemplatesMutationVariables = Exact<{
  input: PreviewCustomPoTemplateInput;
}>;

export type PreviewCustomPoTemplatesMutation = {
  __typename?: "Mutation";
  previewCustomPoTemplates?: Array<string> | null;
};

export type PreviewInvoiceTemplateMutationVariables = Exact<{
  input: PreviewInvoiceTemplateInput;
}>;

export type PreviewInvoiceTemplateMutation = {
  __typename?: "Mutation";
  previewInvoiceTemplate: string;
};

export type PreviewPoTemplateMutationVariables = Exact<{
  input: PreviewPoTemplateInput;
}>;

export type PreviewPoTemplateMutation = {
  __typename?: "Mutation";
  previewPoTemplate: string;
};

export type ExternalCostCodesQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalCostCodesQuery = {
  __typename?: "Query";
  externalCostCodes: Array<{
    __typename?: "ExternalCostCode";
    externalId: string;
    sourceId: string;
    code: string;
    name: string;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    collidesWith?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  }>;
};

export type ExternalCostTypesQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalCostTypesQuery = {
  __typename?: "Query";
  externalCostTypes: Array<{
    __typename?: "ExternalCostType";
    externalId: string;
    sourceId: string;
    code: string;
    name: string;
    collidesWith?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
    } | null;
  }>;
};

export type ExternalLedgerAccountsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalLedgerAccountsQuery = {
  __typename?: "Query";
  externalLedgerAccounts: Array<{
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
    subaccounts?: Array<{
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    }> | null;
  }>;
};

export type ExternalVendorsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  filter?: InputMaybe<QueryExternalVendorsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ExternalVendorsQuery = {
  __typename?: "Query";
  externalVendors: {
    __typename?: "ExternalVendorConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ExternalVendorEdge";
      cursor: string;
      node: {
        __typename?: "ExternalVendor";
        id: string;
        externalId: string;
        sourceId: string;
        name: string;
        email?: string | null;
        phone?: string | null;
        address?: {
          __typename?: "ExternalAddress";
          city?: string | null;
          country?: string | null;
          postalCode?: string | null;
          state?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
        } | null;
      };
    }>;
  };
};

export type InventoryItemFieldsFragment = {
  __typename?: "InventoryItem";
  id: string;
  archivedAt?: number | null;
  orgMaterial: {
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    defaultEstimateUom: { __typename?: "UOM"; id: string };
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
          } | null;
        }
      | { __typename?: "Product"; id: string; name: string }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          manufacturer: { __typename?: "Manufacturer"; id: string };
        };
  };
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  state: Array<{
    __typename?: "InventoryItemState";
    id: string;
    unitPrice: string;
    transactionNumber: number;
    itemPosition: number;
    originalQuantity: string;
    remainingQuantity: string;
    warehouse: { __typename?: "Warehouse"; id: string };
  }>;
};

export type InventoryItemStateFieldsFragment = {
  __typename?: "InventoryItemState";
  id: string;
  unitPrice: string;
  transactionNumber: number;
  itemPosition: number;
  originalQuantity: string;
  remainingQuantity: string;
  warehouse: { __typename?: "Warehouse"; id: string };
};

export type InventoryItemStrippedFieldsFragment = {
  __typename?: "InventoryItem";
  id: string;
  orgMaterial: { __typename?: "OrgMaterial"; id: string };
  uom: { __typename?: "UOM"; id: string };
  state: Array<{
    __typename?: "InventoryItemState";
    id: string;
    unitPrice: string;
    remainingQuantity: string;
    itemPosition: number;
    transactionNumber: number;
    warehouse: { __typename?: "Warehouse"; id: string };
  }>;
};

export type InventorySummaryQueryVariables = Exact<{ [key: string]: never }>;

export type InventorySummaryQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      inventoryItems: Array<{
        __typename?: "InventoryItem";
        id: string;
        orgMaterial: { __typename?: "OrgMaterial"; id: string };
        uom: { __typename?: "UOM"; id: string };
        state: Array<{
          __typename?: "InventoryItemState";
          id: string;
          unitPrice: string;
          remainingQuantity: string;
          itemPosition: number;
          transactionNumber: number;
          warehouse: { __typename?: "Warehouse"; id: string };
        }>;
      }>;
    };
  } | null;
};

export type OrgLocationExtendedFieldsFragment = {
  __typename?: "OrgLocation";
  assignedUserCount: number;
  id: string;
  name: string;
  phone?: string | null;
  address?: {
    __typename?: "Address";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  } | null;
  org: {
    __typename?: "Organization";
    id: string;
    name: string;
    photoUrl?: string | null;
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  contractorOrg?: { __typename?: "Organization"; id: string } | null;
};

export type CreateLocationMutationVariables = Exact<{
  input: AddOrgLocationInput;
}>;

export type CreateLocationMutation = {
  __typename?: "Mutation";
  addNewLocation?: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  } | null;
};

export type DeleteLocationMutationVariables = Exact<{
  input: RemoveOrgLocationInput;
}>;

export type DeleteLocationMutation = {
  __typename?: "Mutation";
  removeLocation?: string | null;
};

export type UpdateLocationMutationVariables = Exact<{
  input: UpdateOrgLocationInput;
}>;

export type UpdateLocationMutation = {
  __typename?: "Mutation";
  updateLocation?: { __typename?: "OrgLocation"; id: string } | null;
};

export type LocationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LocationsQuery = {
  __typename?: "Query";
  locations: {
    __typename?: "OrgLocationConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "OrgLocationEdge";
      cursor: string;
      node: {
        __typename?: "OrgLocation";
        assignedUserCount: number;
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
    }>;
  };
};

export type ComplianceGroupFieldsFragment = {
  __typename?: "ComplianceGroup";
  id: string;
  code: string;
  description?: string | null;
  notes?: string | null;
  externalComplianceGroup?: {
    __typename?: "ExternalComplianceGroup";
    id: string;
    externalId: string;
    sourceId: string;
    code: string;
    description: string;
    notes: string;
  } | null;
};

export type ExternalComplianceGroupFieldsFragment = {
  __typename?: "ExternalComplianceGroup";
  id: string;
  externalId: string;
  sourceId: string;
  code: string;
  description: string;
  notes: string;
};

export type ImportComplianceGroupsMutationVariables = Exact<{
  input: ImportComplianceGroupsInput;
}>;

export type ImportComplianceGroupsMutation = {
  __typename?: "Mutation";
  importComplianceGroups: {
    __typename?: "ImportComplianceGroupsOutput";
    created: Array<{
      __typename?: "ComplianceGroup";
      id: string;
      code: string;
      description?: string | null;
      notes?: string | null;
      externalComplianceGroup?: {
        __typename?: "ExternalComplianceGroup";
        id: string;
        externalId: string;
        sourceId: string;
        code: string;
        description: string;
        notes: string;
      } | null;
    }>;
    mapped: Array<{
      __typename?: "ComplianceGroup";
      id: string;
      code: string;
      description?: string | null;
      notes?: string | null;
      externalComplianceGroup?: {
        __typename?: "ExternalComplianceGroup";
        id: string;
        externalId: string;
        sourceId: string;
        code: string;
        description: string;
        notes: string;
      } | null;
    }>;
    skipped: Array<{
      __typename?: "ComplianceGroup";
      id: string;
      code: string;
      description?: string | null;
      notes?: string | null;
      externalComplianceGroup?: {
        __typename?: "ExternalComplianceGroup";
        id: string;
        externalId: string;
        sourceId: string;
        code: string;
        description: string;
        notes: string;
      } | null;
    }>;
  };
};

export type UpdateComplianceGroupsMutationVariables = Exact<{
  input: UpdateComplianceGroupsInput;
}>;

export type UpdateComplianceGroupsMutation = {
  __typename?: "Mutation";
  updateComplianceGroups: Array<{
    __typename?: "ComplianceGroup";
    id: string;
    code: string;
    description?: string | null;
    notes?: string | null;
    externalComplianceGroup?: {
      __typename?: "ExternalComplianceGroup";
      id: string;
      externalId: string;
      sourceId: string;
      code: string;
      description: string;
      notes: string;
    } | null;
  }>;
};

export type ComplianceGroupsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ComplianceGroupsQuery = {
  __typename?: "Query";
  externalComplianceGroups: Array<{
    __typename?: "ExternalComplianceGroup";
    id: string;
    externalId: string;
    sourceId: string;
    code: string;
    description: string;
    notes: string;
  }>;
};

export type ExternalPoTypeFieldsFragment = {
  __typename?: "ExternalPOType";
  externalId: string;
  sourceId: string;
  name: string;
  sourceSystem: SourceSystem;
};

export type MaterialSummaryFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  material:
    | { __typename?: "OrgCatalogSku"; id: string; name: string }
    | { __typename?: "Product"; id: string; name: string }
    | { __typename?: "Sku"; id: string; name: string };
};

export type OrderTypeFieldsFragment = {
  __typename?: "ReleaseType";
  id: string;
  name: string;
  default: boolean;
  requireDeliverySlip: boolean;
  billable: boolean;
  enableUpcomingNotifications: boolean;
  includeAdditionalCharges: boolean;
  transactionKind: TransactionKind;
  taxMode?: TaxMode | null;
  chargesMode?: ChargesMode | null;
  poFormat?: PoFormat | null;
  archivedAt?: number | null;
  defaultAutoSync: boolean;
  nonJobPO: boolean;
  trackInventory: boolean;
  includeTaxInInvoiceSubtotal: boolean;
  ledgerAccount?: {
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
    subaccounts?: Array<{
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    }> | null;
  } | null;
  ledgerSubaccount?: {
    __typename?: "ExternalLedgerSubaccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
  } | null;
  costType?: {
    __typename?: "CostType";
    id: string;
    code: string;
    description: string;
    org: { __typename?: "Organization"; id: string };
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  poType?: {
    __typename?: "ExternalPOType";
    sourceSystem: SourceSystem;
    externalId: string;
    sourceId: string;
    name: string;
  } | null;
  additionalChargesCostCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  additionalChargesCostType?: {
    __typename?: "CostType";
    id: string;
    code: string;
    description: string;
    org: { __typename?: "Organization"; id: string };
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  salesTaxCostCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  salesTaxCostType?: {
    __typename?: "CostType";
    id: string;
    code: string;
    description: string;
    org: { __typename?: "Organization"; id: string };
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  defaultOrgMaterial?: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | { __typename?: "OrgCatalogSku"; id: string; name: string }
      | { __typename?: "Product"; id: string; name: string }
      | { __typename?: "Sku"; id: string; name: string };
  } | null;
  salesTaxOrgMaterial?: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | { __typename?: "OrgCatalogSku"; id: string; name: string }
      | { __typename?: "Product"; id: string; name: string }
      | { __typename?: "Sku"; id: string; name: string };
  } | null;
};

export type ArchiveOrderTypeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveOrderTypeMutation = {
  __typename?: "Mutation";
  archiveReleaseType: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    default: boolean;
    requireDeliverySlip: boolean;
    billable: boolean;
    enableUpcomingNotifications: boolean;
    includeAdditionalCharges: boolean;
    transactionKind: TransactionKind;
    taxMode?: TaxMode | null;
    chargesMode?: ChargesMode | null;
    poFormat?: PoFormat | null;
    archivedAt?: number | null;
    defaultAutoSync: boolean;
    nonJobPO: boolean;
    trackInventory: boolean;
    includeTaxInInvoiceSubtotal: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    ledgerSubaccount?: {
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    poType?: {
      __typename?: "ExternalPOType";
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      name: string;
    } | null;
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    additionalChargesCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    defaultOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
    salesTaxOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
  };
};

export type CreateOrderTypeMutationVariables = Exact<{
  input: CreateReleaseTypeInput;
}>;

export type CreateOrderTypeMutation = {
  __typename?: "Mutation";
  createReleaseType: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    default: boolean;
    requireDeliverySlip: boolean;
    billable: boolean;
    enableUpcomingNotifications: boolean;
    includeAdditionalCharges: boolean;
    transactionKind: TransactionKind;
    taxMode?: TaxMode | null;
    chargesMode?: ChargesMode | null;
    poFormat?: PoFormat | null;
    archivedAt?: number | null;
    defaultAutoSync: boolean;
    nonJobPO: boolean;
    trackInventory: boolean;
    includeTaxInInvoiceSubtotal: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    ledgerSubaccount?: {
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    poType?: {
      __typename?: "ExternalPOType";
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      name: string;
    } | null;
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    additionalChargesCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    defaultOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
    salesTaxOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
  };
};

export type UnarchiveOrderTypeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UnarchiveOrderTypeMutation = {
  __typename?: "Mutation";
  unarchiveReleaseType: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    default: boolean;
    requireDeliverySlip: boolean;
    billable: boolean;
    enableUpcomingNotifications: boolean;
    includeAdditionalCharges: boolean;
    transactionKind: TransactionKind;
    taxMode?: TaxMode | null;
    chargesMode?: ChargesMode | null;
    poFormat?: PoFormat | null;
    archivedAt?: number | null;
    defaultAutoSync: boolean;
    nonJobPO: boolean;
    trackInventory: boolean;
    includeTaxInInvoiceSubtotal: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    ledgerSubaccount?: {
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    poType?: {
      __typename?: "ExternalPOType";
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      name: string;
    } | null;
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    additionalChargesCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    defaultOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
    salesTaxOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
  };
};

export type UpdateOrderTypeMutationVariables = Exact<{
  input: UpdateReleaseTypeInput;
}>;

export type UpdateOrderTypeMutation = {
  __typename?: "Mutation";
  updateReleaseType: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    default: boolean;
    requireDeliverySlip: boolean;
    billable: boolean;
    enableUpcomingNotifications: boolean;
    includeAdditionalCharges: boolean;
    transactionKind: TransactionKind;
    taxMode?: TaxMode | null;
    chargesMode?: ChargesMode | null;
    poFormat?: PoFormat | null;
    archivedAt?: number | null;
    defaultAutoSync: boolean;
    nonJobPO: boolean;
    trackInventory: boolean;
    includeTaxInInvoiceSubtotal: boolean;
    ledgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    ledgerSubaccount?: {
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    } | null;
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    poType?: {
      __typename?: "ExternalPOType";
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      name: string;
    } | null;
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    additionalChargesCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    salesTaxCostType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    defaultOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
    salesTaxOrgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | { __typename?: "OrgCatalogSku"; id: string; name: string }
        | { __typename?: "Product"; id: string; name: string }
        | { __typename?: "Sku"; id: string; name: string };
    } | null;
  };
};

export type ExternalPoTypesQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalPoTypesQuery = {
  __typename?: "Query";
  externalPOTypes?: Array<{
    __typename?: "ExternalPOType";
    externalId: string;
    sourceId: string;
    name: string;
    sourceSystem: SourceSystem;
  }> | null;
};

export type OrderTypesQueryVariables = Exact<{ [key: string]: never }>;

export type OrderTypesQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      releaseTypes: Array<{
        __typename?: "ReleaseType";
        id: string;
        name: string;
        default: boolean;
        requireDeliverySlip: boolean;
        billable: boolean;
        enableUpcomingNotifications: boolean;
        includeAdditionalCharges: boolean;
        transactionKind: TransactionKind;
        taxMode?: TaxMode | null;
        chargesMode?: ChargesMode | null;
        poFormat?: PoFormat | null;
        archivedAt?: number | null;
        defaultAutoSync: boolean;
        nonJobPO: boolean;
        trackInventory: boolean;
        includeTaxInInvoiceSubtotal: boolean;
        ledgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
          subaccounts?: Array<{
            __typename?: "ExternalLedgerSubaccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
          }> | null;
        } | null;
        ledgerSubaccount?: {
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        } | null;
        costType?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        poType?: {
          __typename?: "ExternalPOType";
          sourceSystem: SourceSystem;
          externalId: string;
          sourceId: string;
          name: string;
        } | null;
        additionalChargesCostCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        additionalChargesCostType?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        salesTaxCostCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        salesTaxCostType?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        defaultOrgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | { __typename?: "OrgCatalogSku"; id: string; name: string }
            | { __typename?: "Product"; id: string; name: string }
            | { __typename?: "Sku"; id: string; name: string };
        } | null;
        salesTaxOrgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | { __typename?: "OrgCatalogSku"; id: string; name: string }
            | { __typename?: "Product"; id: string; name: string }
            | { __typename?: "Sku"; id: string; name: string };
        } | null;
      }>;
    };
  } | null;
};

export type VendorPriceFieldsFragment = {
  __typename?: "VendorPrice";
  id: string;
  orgMaterialId: string;
  price: string;
  expirationDate?: number | null;
  orderIncrement?: number | null;
  minimumOrder?: number | null;
  leadTime?: number | null;
  sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
  uom: { __typename?: "UOM"; id: string };
  manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
};

export type AddVendorPriceMutationVariables = Exact<{
  input: AddVendorPriceInput;
}>;

export type AddVendorPriceMutation = {
  __typename?: "Mutation";
  addVendorPrice: {
    __typename?: "VendorPrice";
    id: string;
    orgMaterialId: string;
    price: string;
    expirationDate?: number | null;
    orderIncrement?: number | null;
    minimumOrder?: number | null;
    leadTime?: number | null;
    sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    uom: { __typename?: "UOM"; id: string };
    manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
  };
};

export type SetVendorPricesMutationVariables = Exact<{
  input: SetVendorPricesInput;
}>;

export type SetVendorPricesMutation = {
  __typename?: "Mutation";
  setVendorPrices: Array<{
    __typename?: "VendorPrice";
    id: string;
    orgMaterialId: string;
    price: string;
    expirationDate?: number | null;
    orderIncrement?: number | null;
    minimumOrder?: number | null;
    leadTime?: number | null;
    sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    uom: { __typename?: "UOM"; id: string };
    manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
  } | null>;
};

export type UpdateVendorPriceMutationVariables = Exact<{
  input: UpdateVendorPriceInput;
}>;

export type UpdateVendorPriceMutation = {
  __typename?: "Mutation";
  updateVendorPrice: {
    __typename?: "VendorPrice";
    id: string;
    orgMaterialId: string;
    price: string;
    expirationDate?: number | null;
    orderIncrement?: number | null;
    minimumOrder?: number | null;
    leadTime?: number | null;
    sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    uom: { __typename?: "UOM"; id: string };
    manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
  };
};

export type VendorPricesQueryVariables = Exact<{ [key: string]: never }>;

export type VendorPricesQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
    };
  } | null;
};

export type ExternalItemFieldsFragment = {
  __typename?: "ExternalItem";
  id: string;
  externalId: string;
  name: string;
  purchasingUom?: string | null;
  defaultCostCode?: string | null;
  sourceSystem: SourceSystem;
  sourceId: string;
};

export type OrgMaterialFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  externalCode?: string | null;
  material:
    | {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        customPartNumber?: string | null;
        sectionName?: string | null;
        defaultUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        defaultManufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: "Product";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }
    | {
        __typename?: "Sku";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        brand: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
        product: {
          __typename?: "Product";
          id: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      };
  defaultEstimateUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  referenceCounts: {
    __typename?: "MaterialReferenceCounts";
    estimatedItems: number;
    rfqItems: number;
    buyoutItems: number;
    releaseItems: number;
  };
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  vendorPrices: Array<{
    __typename?: "VendorPrice";
    id: string;
    orgMaterialId: string;
    price: string;
    expirationDate?: number | null;
    orderIncrement?: number | null;
    minimumOrder?: number | null;
    leadTime?: number | null;
    sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
    uom: { __typename?: "UOM"; id: string };
    manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
  }>;
  externalItems: Array<{
    __typename?: "ExternalItem";
    id: string;
    externalId: string;
    name: string;
    purchasingUom?: string | null;
    defaultCostCode?: string | null;
    sourceSystem: SourceSystem;
    sourceId: string;
  }>;
};

export type PartialOrgMaterialFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  externalCode?: string | null;
  costCode?: { __typename?: "CostCode"; id: string } | null;
  defaultEstimateUom: { __typename?: "UOM"; id: string };
  material:
    | {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        customPartNumber?: string | null;
        defaultManufacturer?: {
          __typename?: "Manufacturer";
          id: string;
        } | null;
      }
    | { __typename?: "Product"; id: string; name: string }
    | {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: { __typename?: "Manufacturer"; id: string };
      };
};

export type ProjectMaterialExtendedFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  material:
    | {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        customPartNumber?: string | null;
        sectionName?: string | null;
        defaultUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        defaultManufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: "Product";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }
    | {
        __typename?: "Sku";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        brand: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
        product: {
          __typename?: "Product";
          id: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      };
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
};

export type ProjectMaterialFieldsFragment = {
  __typename?: "OrgMaterial";
  id: string;
  material:
    | {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
        customPartNumber?: string | null;
        sectionName?: string | null;
        defaultUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        defaultManufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: "Product";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      }
    | {
        __typename?: "Sku";
        id: string;
        name: string;
        description: string;
        imageUrl: string;
        brand: string;
        manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
        product: {
          __typename?: "Product";
          id: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      };
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
};

export type ProjectMaterialOrgCatalogSkuFieldsFragment = {
  __typename?: "OrgCatalogSku";
  id: string;
  name: string;
  customPartNumber?: string | null;
  sectionName?: string | null;
  defaultUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  defaultManufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
};

export type ProjectMaterialProductFieldsFragment = {
  __typename?: "Product";
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
};

export type ProjectMaterialSkuFieldsFragment = {
  __typename?: "Sku";
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  brand: string;
  manufacturer: { __typename?: "Manufacturer"; id: string; name: string };
  product: {
    __typename?: "Product";
    id: string;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  };
};

export type ConnectOrgMaterialMutationVariables = Exact<{
  input: ConnectNodeInput;
}>;

export type ConnectOrgMaterialMutation = {
  __typename?: "Mutation";
  connectOrgMaterial: { __typename?: "OrgMaterial"; id: string };
};

export type DisconnectOrgMaterialMutationVariables = Exact<{
  input: DisconnectNodeInput;
}>;

export type DisconnectOrgMaterialMutation = {
  __typename?: "Mutation";
  disconnectOrgMaterial: { __typename?: "OrgMaterial"; id: string };
};

export type ImportOrgMaterialsMutationVariables = Exact<{
  input: ImportOrgMaterialsInput;
}>;

export type ImportOrgMaterialsMutation = {
  __typename?: "Mutation";
  importOrgMaterials: {
    __typename?: "ImportOrgMaterialsOutput";
    created: Array<{
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    }>;
    mapped: Array<{
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    }>;
    skipped: Array<{
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    }>;
  };
};

export type RemoveOrgMaterialMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveOrgMaterialMutation = {
  __typename?: "Mutation";
  archiveOrgMaterial: { __typename?: "OrgMaterial"; id: string };
};

export type ExternalItemsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryExternalItemsFilter>;
}>;

export type ExternalItemsQuery = {
  __typename?: "Query";
  externalItems: {
    __typename?: "ExternalItemConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ExternalItemEdge";
      cursor: string;
      node: {
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      };
    }>;
  };
};

export type OrgMaterialQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrgMaterialQuery = {
  __typename?: "Query";
  orgMaterial: {
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    defaultEstimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    referenceCounts: {
      __typename?: "MaterialReferenceCounts";
      estimatedItems: number;
      rfqItems: number;
      buyoutItems: number;
      releaseItems: number;
    };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    vendorPrices: Array<{
      __typename?: "VendorPrice";
      id: string;
      orgMaterialId: string;
      price: string;
      expirationDate?: number | null;
      orderIncrement?: number | null;
      minimumOrder?: number | null;
      leadTime?: number | null;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      uom: { __typename?: "UOM"; id: string };
      manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
    }>;
    externalItems: Array<{
      __typename?: "ExternalItem";
      id: string;
      externalId: string;
      name: string;
      purchasingUom?: string | null;
      defaultCostCode?: string | null;
      sourceSystem: SourceSystem;
      sourceId: string;
    }>;
  };
};

export type OrgMaterialsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryOrgMaterialsFilter>;
}>;

export type OrgMaterialsQuery = {
  __typename?: "Query";
  orgMaterials: {
    __typename?: "OrgMaterialConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "OrgMaterialEdge";
      cursor: string;
      node: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
    }>;
  };
};

export type OrgMaterialsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type OrgMaterialsSummaryQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      materials: Array<{
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        defaultEstimateUom: { __typename?: "UOM"; id: string };
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
              } | null;
            }
          | { __typename?: "Product"; id: string; name: string }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              manufacturer: { __typename?: "Manufacturer"; id: string };
            };
      }>;
    };
  } | null;
};

export type AccountingSettingsFieldsFragment = {
  __typename?: "AccountingSettings";
  id: string;
  toJobLedgerAccount?: string | null;
  distributeAdditionalCharges?: boolean | null;
  materialCostType?: string | null;
  enabled: boolean;
  integration: IntegrationType;
  postInventoryReceipts?: boolean | null;
  inventoryReceiptLedgerAccount?: string | null;
  additionalChargesCostCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
  } | null;
  taxAuthorities: Array<{
    __typename?: "TaxAuthority";
    code: string;
    rate: string;
  }>;
};

export type IntegrationSettingsExtendedFieldsFragment = {
  __typename?: "IntegrationSettings";
  org: { __typename?: "Organization"; id: string };
  accounting: Array<{
    __typename?: "AccountingSettings";
    id: string;
    toJobLedgerAccount?: string | null;
    distributeAdditionalCharges?: boolean | null;
    materialCostType?: string | null;
    enabled: boolean;
    integration: IntegrationType;
    postInventoryReceipts?: boolean | null;
    inventoryReceiptLedgerAccount?: string | null;
    progress: {
      __typename?: "IntegrationMappingProgress";
      costCodes: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      orgMaterials: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      projects: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      vendors: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      releaseTypes: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      costTypes: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
    };
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    taxAuthorities: Array<{
      __typename?: "TaxAuthority";
      code: string;
      rate: string;
    }>;
  }>;
  sourceSystems: Array<{
    __typename?: "SourceSystemSettings";
    defaultAutoSync?: boolean | null;
    postInventoryReceipts?: boolean | null;
    autoPostPOs?: boolean | null;
    autoPostInvoices?: boolean | null;
    autoExportApprovedInvoices?: boolean | null;
    autoCloseProjectsByJobStatus: boolean;
    system: SourceSystem;
    companyName: string;
    companyId: string;
    connected: boolean;
    wbsTags?: boolean | null;
    poNameTemplate?: string | null;
    poDescriptionTemplate?: string | null;
    invoiceDescriptionTemplate?: string | null;
    accountsPayableLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    toJobLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    toWarehouseLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
      subaccounts?: Array<{
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      }> | null;
    } | null;
    materialCostType?: {
      __typename?: "ExternalCostType";
      externalId: string;
      sourceId: string;
      code: string;
      name: string;
      collidesWith?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
    } | null;
    progress: {
      __typename?: "IntegrationMappingProgress";
      costCodes: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      orgMaterials: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      projects: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      vendors: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      releaseTypes: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
      costTypes: {
        __typename?: "MappingProgress";
        mapped: number;
        total: number;
      };
    };
    inventoryReceiptLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      name: string;
    } | null;
    customPOTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
    customInvoiceTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
  }>;
};

export type IntegrationSettingsFieldsFragment = {
  __typename?: "IntegrationSettings";
  accounting: Array<{
    __typename?: "AccountingSettings";
    id: string;
    toJobLedgerAccount?: string | null;
    distributeAdditionalCharges?: boolean | null;
    materialCostType?: string | null;
    enabled: boolean;
    integration: IntegrationType;
    postInventoryReceipts?: boolean | null;
    inventoryReceiptLedgerAccount?: string | null;
    additionalChargesCostCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    taxAuthorities: Array<{
      __typename?: "TaxAuthority";
      code: string;
      rate: string;
    }>;
  }>;
  sourceSystems: Array<{
    __typename?: "SourceSystemSettings";
    system: SourceSystem;
    companyName: string;
    companyId: string;
    connected: boolean;
    postInventoryReceipts?: boolean | null;
    wbsTags?: boolean | null;
    autoExportApprovedInvoices?: boolean | null;
    autoPostPOs?: boolean | null;
    poNameTemplate?: string | null;
    poDescriptionTemplate?: string | null;
    invoiceDescriptionTemplate?: string | null;
    inventoryReceiptLedgerAccount?: {
      __typename?: "ExternalLedgerAccount";
      externalId: string;
      name: string;
    } | null;
    customPOTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
    customInvoiceTemplates: Array<{
      __typename?: "SourceSystemSettingCustomTemplate";
      name: string;
      template: string;
    }>;
  }>;
};

export type LedgerAccountFieldsFragment = {
  __typename?: "ExternalLedgerAccount";
  externalId: string;
  sourceId: string;
  sourceSystem: SourceSystem;
  name: string;
  number: string;
};

export type OrgSettingsExtendedFieldsFragment = {
  __typename?: "OrgSettings";
  releaseApproval: {
    __typename?: "ReleaseApprovalSettings";
    enabled: boolean;
    standaloneOnly: boolean;
    sourceWarehouse: boolean;
    exemptRoles: Array<OrgLocationRole>;
    approverRoles: Array<OrgLocationRole>;
    submitterRoles: Array<OrgLocationRole>;
  };
  integrations: {
    __typename?: "IntegrationSettings";
    org: { __typename?: "Organization"; id: string };
    accounting: Array<{
      __typename?: "AccountingSettings";
      id: string;
      toJobLedgerAccount?: string | null;
      distributeAdditionalCharges?: boolean | null;
      materialCostType?: string | null;
      enabled: boolean;
      integration: IntegrationType;
      postInventoryReceipts?: boolean | null;
      inventoryReceiptLedgerAccount?: string | null;
      progress: {
        __typename?: "IntegrationMappingProgress";
        costCodes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        orgMaterials: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        projects: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        vendors: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        releaseTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        costTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
      };
      additionalChargesCostCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      taxAuthorities: Array<{
        __typename?: "TaxAuthority";
        code: string;
        rate: string;
      }>;
    }>;
    sourceSystems: Array<{
      __typename?: "SourceSystemSettings";
      defaultAutoSync?: boolean | null;
      postInventoryReceipts?: boolean | null;
      autoPostPOs?: boolean | null;
      autoPostInvoices?: boolean | null;
      autoExportApprovedInvoices?: boolean | null;
      autoCloseProjectsByJobStatus: boolean;
      system: SourceSystem;
      companyName: string;
      companyId: string;
      connected: boolean;
      wbsTags?: boolean | null;
      poNameTemplate?: string | null;
      poDescriptionTemplate?: string | null;
      invoiceDescriptionTemplate?: string | null;
      accountsPayableLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      toJobLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      toWarehouseLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      materialCostType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      progress: {
        __typename?: "IntegrationMappingProgress";
        costCodes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        orgMaterials: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        projects: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        vendors: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        releaseTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        costTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
      };
      inventoryReceiptLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        name: string;
      } | null;
      customPOTemplates: Array<{
        __typename?: "SourceSystemSettingCustomTemplate";
        name: string;
        template: string;
      }>;
      customInvoiceTemplates: Array<{
        __typename?: "SourceSystemSettingCustomTemplate";
        name: string;
        template: string;
      }>;
    }>;
  };
  display?: {
    __typename?: "DisplaySettings";
    enableManufacturers: boolean;
  } | null;
};

export type OrgSettingsFieldsFragment = {
  __typename?: "OrgSettings";
  releaseApproval: {
    __typename?: "ReleaseApprovalSettings";
    enabled: boolean;
    standaloneOnly: boolean;
    sourceWarehouse: boolean;
    reserved: boolean;
    exemptRoles: Array<OrgLocationRole>;
    approverRoles: Array<OrgLocationRole>;
    submitterRoles: Array<OrgLocationRole>;
    requiredApprovals: number;
    requiredApproverRoles: Array<OrgLocationRole>;
  };
  releases?: {
    __typename?: "ReleaseSettings";
    poNumbering: PoNumberingMode;
    notifications: ReleaseNotificationsMode;
    useSourceSystemPOs: boolean;
    poNumberSuffixPreview: string;
    defaultWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
  } | null;
  invoices: {
    __typename?: "InvoiceSettings";
    localPart?: string | null;
    domain: string;
    defaultPaymentTerm?: number | null;
    requireCostCodesDuringApproval: boolean;
    approval?: {
      __typename?: "InvoiceApprovalSettings";
      roles: Array<{
        __typename?: "InvoiceApprovalRole";
        role: OrgLocationRole;
        maxAmount?: string | null;
        notificationThreshold?: string | null;
      }>;
    } | null;
  };
  integrations: {
    __typename?: "IntegrationSettings";
    org: { __typename?: "Organization"; id: string };
    accounting: Array<{
      __typename?: "AccountingSettings";
      id: string;
      toJobLedgerAccount?: string | null;
      distributeAdditionalCharges?: boolean | null;
      materialCostType?: string | null;
      enabled: boolean;
      integration: IntegrationType;
      postInventoryReceipts?: boolean | null;
      inventoryReceiptLedgerAccount?: string | null;
      progress: {
        __typename?: "IntegrationMappingProgress";
        costCodes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        orgMaterials: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        projects: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        vendors: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        releaseTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        costTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
      };
      additionalChargesCostCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      taxAuthorities: Array<{
        __typename?: "TaxAuthority";
        code: string;
        rate: string;
      }>;
    }>;
    sourceSystems: Array<{
      __typename?: "SourceSystemSettings";
      defaultAutoSync?: boolean | null;
      postInventoryReceipts?: boolean | null;
      autoPostPOs?: boolean | null;
      autoPostInvoices?: boolean | null;
      autoExportApprovedInvoices?: boolean | null;
      autoCloseProjectsByJobStatus: boolean;
      system: SourceSystem;
      companyName: string;
      companyId: string;
      connected: boolean;
      wbsTags?: boolean | null;
      poNameTemplate?: string | null;
      poDescriptionTemplate?: string | null;
      invoiceDescriptionTemplate?: string | null;
      accountsPayableLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      toJobLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      toWarehouseLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      materialCostType?: {
        __typename?: "ExternalCostType";
        externalId: string;
        sourceId: string;
        code: string;
        name: string;
        collidesWith?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      progress: {
        __typename?: "IntegrationMappingProgress";
        costCodes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        orgMaterials: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        projects: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        vendors: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        releaseTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
        costTypes: {
          __typename?: "MappingProgress";
          mapped: number;
          total: number;
        };
      };
      inventoryReceiptLedgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        name: string;
      } | null;
      customPOTemplates: Array<{
        __typename?: "SourceSystemSettingCustomTemplate";
        name: string;
        template: string;
      }>;
      customInvoiceTemplates: Array<{
        __typename?: "SourceSystemSettingCustomTemplate";
        name: string;
        template: string;
      }>;
    }>;
  };
  display?: {
    __typename?: "DisplaySettings";
    enableManufacturers: boolean;
    itemCostTypes: boolean;
    enableAdditionalCharges: boolean;
    requireProjectAssignment: boolean;
    enableTaxVariance: boolean;
    defaultItemized?: boolean | null;
    receiptDefaultItemized?: boolean | null;
    usesProjectCostCodes: boolean;
    appendExternalCode?: boolean | null;
    currency: {
      __typename?: "Currency";
      id: string;
      code: string;
      name: string;
      symbol: string;
    };
  } | null;
  inventory: { __typename?: "InventorySettings"; enabled: boolean };
  projectSettings: {
    __typename?: "ProjectSettings";
    defaultTeam: Array<{
      __typename?: "OrgLocationDefaultProjectTeam";
      orgLocation: { __typename?: "OrgLocation"; id: string };
      users: Array<{ __typename?: "User"; id: string }>;
    }>;
  };
};

export type ProgressFieldsFragment = {
  __typename?: "IntegrationMappingProgress";
  costCodes: { __typename?: "MappingProgress"; mapped: number; total: number };
  orgMaterials: {
    __typename?: "MappingProgress";
    mapped: number;
    total: number;
  };
  projects: { __typename?: "MappingProgress"; mapped: number; total: number };
  vendors: { __typename?: "MappingProgress"; mapped: number; total: number };
  releaseTypes: {
    __typename?: "MappingProgress";
    mapped: number;
    total: number;
  };
  costTypes: { __typename?: "MappingProgress"; mapped: number; total: number };
};

export type SourceSystemMappingFieldsFragment = {
  __typename?: "SourceSystemMapping";
  externalId: string;
  sourceSystem: SourceSystem;
};

export type SourceSystemSettingExtendedFieldsFragment = {
  __typename?: "SourceSystemSettings";
  defaultAutoSync?: boolean | null;
  postInventoryReceipts?: boolean | null;
  autoPostPOs?: boolean | null;
  autoPostInvoices?: boolean | null;
  autoExportApprovedInvoices?: boolean | null;
  autoCloseProjectsByJobStatus: boolean;
  system: SourceSystem;
  companyName: string;
  companyId: string;
  connected: boolean;
  wbsTags?: boolean | null;
  poNameTemplate?: string | null;
  poDescriptionTemplate?: string | null;
  invoiceDescriptionTemplate?: string | null;
  accountsPayableLedgerAccount?: {
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
    subaccounts?: Array<{
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    }> | null;
  } | null;
  toJobLedgerAccount?: {
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
    subaccounts?: Array<{
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    }> | null;
  } | null;
  toWarehouseLedgerAccount?: {
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    sourceId: string;
    name: string;
    number: string;
    subaccounts?: Array<{
      __typename?: "ExternalLedgerSubaccount";
      externalId: string;
      sourceId: string;
      name: string;
      number: string;
    }> | null;
  } | null;
  materialCostType?: {
    __typename?: "ExternalCostType";
    externalId: string;
    sourceId: string;
    code: string;
    name: string;
    collidesWith?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
    } | null;
  } | null;
  progress: {
    __typename?: "IntegrationMappingProgress";
    costCodes: {
      __typename?: "MappingProgress";
      mapped: number;
      total: number;
    };
    orgMaterials: {
      __typename?: "MappingProgress";
      mapped: number;
      total: number;
    };
    projects: { __typename?: "MappingProgress"; mapped: number; total: number };
    vendors: { __typename?: "MappingProgress"; mapped: number; total: number };
    releaseTypes: {
      __typename?: "MappingProgress";
      mapped: number;
      total: number;
    };
    costTypes: {
      __typename?: "MappingProgress";
      mapped: number;
      total: number;
    };
  };
  inventoryReceiptLedgerAccount?: {
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    name: string;
  } | null;
  customPOTemplates: Array<{
    __typename?: "SourceSystemSettingCustomTemplate";
    name: string;
    template: string;
  }>;
  customInvoiceTemplates: Array<{
    __typename?: "SourceSystemSettingCustomTemplate";
    name: string;
    template: string;
  }>;
};

export type SourceSystemSettingFieldsFragment = {
  __typename?: "SourceSystemSettings";
  system: SourceSystem;
  companyName: string;
  companyId: string;
  connected: boolean;
  postInventoryReceipts?: boolean | null;
  wbsTags?: boolean | null;
  autoExportApprovedInvoices?: boolean | null;
  autoPostPOs?: boolean | null;
  poNameTemplate?: string | null;
  poDescriptionTemplate?: string | null;
  invoiceDescriptionTemplate?: string | null;
  inventoryReceiptLedgerAccount?: {
    __typename?: "ExternalLedgerAccount";
    externalId: string;
    name: string;
  } | null;
  customPOTemplates: Array<{
    __typename?: "SourceSystemSettingCustomTemplate";
    name: string;
    template: string;
  }>;
  customInvoiceTemplates: Array<{
    __typename?: "SourceSystemSettingCustomTemplate";
    name: string;
    template: string;
  }>;
};

export type UpdateOrgSettingsMutationVariables = Exact<{
  input: UpdateOrgSettingsInput;
}>;

export type UpdateOrgSettingsMutation = {
  __typename?: "Mutation";
  updateOrgSettings: {
    __typename?: "OrgSettings";
    releaseApproval: {
      __typename?: "ReleaseApprovalSettings";
      enabled: boolean;
      standaloneOnly: boolean;
      sourceWarehouse: boolean;
      reserved: boolean;
      exemptRoles: Array<OrgLocationRole>;
      approverRoles: Array<OrgLocationRole>;
      submitterRoles: Array<OrgLocationRole>;
      requiredApprovals: number;
      requiredApproverRoles: Array<OrgLocationRole>;
    };
    releases?: {
      __typename?: "ReleaseSettings";
      poNumbering: PoNumberingMode;
      notifications: ReleaseNotificationsMode;
      useSourceSystemPOs: boolean;
      poNumberSuffixPreview: string;
      defaultWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
    } | null;
    invoices: {
      __typename?: "InvoiceSettings";
      localPart?: string | null;
      domain: string;
      defaultPaymentTerm?: number | null;
      requireCostCodesDuringApproval: boolean;
      approval?: {
        __typename?: "InvoiceApprovalSettings";
        roles: Array<{
          __typename?: "InvoiceApprovalRole";
          role: OrgLocationRole;
          maxAmount?: string | null;
          notificationThreshold?: string | null;
        }>;
      } | null;
    };
    integrations: {
      __typename?: "IntegrationSettings";
      org: { __typename?: "Organization"; id: string };
      accounting: Array<{
        __typename?: "AccountingSettings";
        id: string;
        toJobLedgerAccount?: string | null;
        distributeAdditionalCharges?: boolean | null;
        materialCostType?: string | null;
        enabled: boolean;
        integration: IntegrationType;
        postInventoryReceipts?: boolean | null;
        inventoryReceiptLedgerAccount?: string | null;
        progress: {
          __typename?: "IntegrationMappingProgress";
          costCodes: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          orgMaterials: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          projects: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          vendors: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          releaseTypes: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          costTypes: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
        };
        additionalChargesCostCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
        taxAuthorities: Array<{
          __typename?: "TaxAuthority";
          code: string;
          rate: string;
        }>;
      }>;
      sourceSystems: Array<{
        __typename?: "SourceSystemSettings";
        defaultAutoSync?: boolean | null;
        postInventoryReceipts?: boolean | null;
        autoPostPOs?: boolean | null;
        autoPostInvoices?: boolean | null;
        autoExportApprovedInvoices?: boolean | null;
        autoCloseProjectsByJobStatus: boolean;
        system: SourceSystem;
        companyName: string;
        companyId: string;
        connected: boolean;
        wbsTags?: boolean | null;
        poNameTemplate?: string | null;
        poDescriptionTemplate?: string | null;
        invoiceDescriptionTemplate?: string | null;
        accountsPayableLedgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
          subaccounts?: Array<{
            __typename?: "ExternalLedgerSubaccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
          }> | null;
        } | null;
        toJobLedgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
          subaccounts?: Array<{
            __typename?: "ExternalLedgerSubaccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
          }> | null;
        } | null;
        toWarehouseLedgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
          subaccounts?: Array<{
            __typename?: "ExternalLedgerSubaccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
          }> | null;
        } | null;
        materialCostType?: {
          __typename?: "ExternalCostType";
          externalId: string;
          sourceId: string;
          code: string;
          name: string;
          collidesWith?: {
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        progress: {
          __typename?: "IntegrationMappingProgress";
          costCodes: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          orgMaterials: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          projects: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          vendors: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          releaseTypes: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
          costTypes: {
            __typename?: "MappingProgress";
            mapped: number;
            total: number;
          };
        };
        inventoryReceiptLedgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          name: string;
        } | null;
        customPOTemplates: Array<{
          __typename?: "SourceSystemSettingCustomTemplate";
          name: string;
          template: string;
        }>;
        customInvoiceTemplates: Array<{
          __typename?: "SourceSystemSettingCustomTemplate";
          name: string;
          template: string;
        }>;
      }>;
    };
    display?: {
      __typename?: "DisplaySettings";
      enableManufacturers: boolean;
      itemCostTypes: boolean;
      enableAdditionalCharges: boolean;
      requireProjectAssignment: boolean;
      enableTaxVariance: boolean;
      defaultItemized?: boolean | null;
      receiptDefaultItemized?: boolean | null;
      usesProjectCostCodes: boolean;
      appendExternalCode?: boolean | null;
      currency: {
        __typename?: "Currency";
        id: string;
        code: string;
        name: string;
        symbol: string;
      };
    } | null;
    inventory: { __typename?: "InventorySettings"; enabled: boolean };
    projectSettings: {
      __typename?: "ProjectSettings";
      defaultTeam: Array<{
        __typename?: "OrgLocationDefaultProjectTeam";
        orgLocation: { __typename?: "OrgLocation"; id: string };
        users: Array<{ __typename?: "User"; id: string }>;
      }>;
    };
  };
};

export type OrgSettingsExtendedQueryVariables = Exact<{ [key: string]: never }>;

export type OrgSettingsExtendedQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      settings?: {
        __typename?: "OrgSettings";
        releaseApproval: {
          __typename?: "ReleaseApprovalSettings";
          enabled: boolean;
          standaloneOnly: boolean;
          sourceWarehouse: boolean;
          exemptRoles: Array<OrgLocationRole>;
          approverRoles: Array<OrgLocationRole>;
          submitterRoles: Array<OrgLocationRole>;
        };
        integrations: {
          __typename?: "IntegrationSettings";
          org: { __typename?: "Organization"; id: string };
          accounting: Array<{
            __typename?: "AccountingSettings";
            id: string;
            toJobLedgerAccount?: string | null;
            distributeAdditionalCharges?: boolean | null;
            materialCostType?: string | null;
            enabled: boolean;
            integration: IntegrationType;
            postInventoryReceipts?: boolean | null;
            inventoryReceiptLedgerAccount?: string | null;
            progress: {
              __typename?: "IntegrationMappingProgress";
              costCodes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              orgMaterials: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              projects: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              vendors: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              releaseTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              costTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
            };
            additionalChargesCostCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
            taxAuthorities: Array<{
              __typename?: "TaxAuthority";
              code: string;
              rate: string;
            }>;
          }>;
          sourceSystems: Array<{
            __typename?: "SourceSystemSettings";
            defaultAutoSync?: boolean | null;
            postInventoryReceipts?: boolean | null;
            autoPostPOs?: boolean | null;
            autoPostInvoices?: boolean | null;
            autoExportApprovedInvoices?: boolean | null;
            autoCloseProjectsByJobStatus: boolean;
            system: SourceSystem;
            companyName: string;
            companyId: string;
            connected: boolean;
            wbsTags?: boolean | null;
            poNameTemplate?: string | null;
            poDescriptionTemplate?: string | null;
            invoiceDescriptionTemplate?: string | null;
            accountsPayableLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
            toJobLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
            toWarehouseLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
            materialCostType?: {
              __typename?: "ExternalCostType";
              externalId: string;
              sourceId: string;
              code: string;
              name: string;
              collidesWith?: {
                __typename?: "CostType";
                id: string;
                code: string;
                description: string;
              } | null;
            } | null;
            progress: {
              __typename?: "IntegrationMappingProgress";
              costCodes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              orgMaterials: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              projects: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              vendors: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              releaseTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
              costTypes: {
                __typename?: "MappingProgress";
                mapped: number;
                total: number;
              };
            };
            inventoryReceiptLedgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              name: string;
            } | null;
            customPOTemplates: Array<{
              __typename?: "SourceSystemSettingCustomTemplate";
              name: string;
              template: string;
            }>;
            customInvoiceTemplates: Array<{
              __typename?: "SourceSystemSettingCustomTemplate";
              name: string;
              template: string;
            }>;
          }>;
        };
        display?: {
          __typename?: "DisplaySettings";
          enableManufacturers: boolean;
        } | null;
      } | null;
    };
  } | null;
};

export type NotificationSettingsFieldsFragment = {
  __typename?: "NotificationSettings";
  excludedReleaseEvents: Array<ReleaseEvent>;
  excludedInvoiceEvents: Array<InvoiceEvent>;
  excludedBuyoutEvents: Array<BuyoutEvent>;
  excludedQuoteEvents: Array<QuoteEvent>;
  excludedMessageContexts: Array<MessageContextKind>;
};

export type ViewerOrgNotificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ViewerOrgNotificationsQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      settings?: {
        __typename?: "OrgSettings";
        notifications: {
          __typename?: "NotificationSettings";
          excludedReleaseEvents: Array<ReleaseEvent>;
          excludedInvoiceEvents: Array<InvoiceEvent>;
          excludedBuyoutEvents: Array<BuyoutEvent>;
          excludedQuoteEvents: Array<QuoteEvent>;
          excludedMessageContexts: Array<MessageContextKind>;
        };
      } | null;
    };
  } | null;
};

export type EnterpriseOrgFieldsFragment = {
  __typename?: "Organization";
  id: string;
  name: string;
  enterprise?: { __typename?: "Enterprise"; id: string; name: string } | null;
};

export type EnterpriseFieldsFragment = {
  __typename?: "Enterprise";
  id: string;
  name: string;
  orgs: Array<{
    __typename?: "Organization";
    id: string;
    name: string;
    enterprise?: { __typename?: "Enterprise"; id: string; name: string } | null;
  }>;
};

export type EnterprisesQueryVariables = Exact<{ [key: string]: never }>;

export type EnterprisesQuery = {
  __typename?: "Query";
  enterprises: Array<{
    __typename?: "Enterprise";
    id: string;
    name: string;
    orgs: Array<{
      __typename?: "Organization";
      id: string;
      name: string;
      enterprise?: {
        __typename?: "Enterprise";
        id: string;
        name: string;
      } | null;
    }>;
  }>;
};

export type AdminUsersUserFieldsFragment = {
  __typename?: "User";
  id: string;
  email: string;
  cellPhone: string;
  createdAt: number;
  firstName: string;
  lastName: string;
  photoUrl?: string | null;
  isEmailValidated: boolean;
  isOrgAdmin?: boolean | null;
  orgRoles: Array<OrgRole>;
  enterpriseRoles: Array<EnterpriseRole>;
  org: {
    __typename?: "Organization";
    id: string;
    locations: Array<{ __typename?: "OrgLocation"; id: string; name: string }>;
  };
  orgs: Array<{ __typename?: "Organization"; id: string; name: string }>;
  locationRoles: Array<{
    __typename?: "LocationRole";
    orgLocID: string;
    role: OrgLocationRole;
  }>;
};

export type AdminUsersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminUsersQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User";
    id: string;
    email: string;
    cellPhone: string;
    createdAt: number;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
    isEmailValidated: boolean;
    isOrgAdmin?: boolean | null;
    orgRoles: Array<OrgRole>;
    enterpriseRoles: Array<EnterpriseRole>;
    org: {
      __typename?: "Organization";
      id: string;
      locations: Array<{
        __typename?: "OrgLocation";
        id: string;
        name: string;
      }>;
    };
    orgs: Array<{ __typename?: "Organization"; id: string; name: string }>;
    locationRoles: Array<{
      __typename?: "LocationRole";
      orgLocID: string;
      role: OrgLocationRole;
    }>;
  }>;
};

export type EnterpriseUsersQueryVariables = Exact<{ [key: string]: never }>;

export type EnterpriseUsersQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    enterprise?: {
      __typename?: "Enterprise";
      id: string;
      users: Array<{
        __typename?: "User";
        id: string;
        email: string;
        cellPhone: string;
        createdAt: number;
        firstName: string;
        lastName: string;
        isEnterpriseAdmin: boolean;
      }>;
    } | null;
  } | null;
};

export type ImportVendorsMutationVariables = Exact<{
  input: ImportVendorsInput;
}>;

export type ImportVendorsMutation = {
  __typename?: "Mutation";
  importVendors: {
    __typename?: "ImportVendorsOutput";
    created: Array<{
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      defaultPaymentTerm?: number | null;
      defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        ledgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
        } | null;
      } | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
        receivesInvoiceNotifications: boolean;
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
      externalVendors: Array<{
        __typename?: "ExternalVendor";
        id: string;
        sourceSystem: SourceSystem;
        insurances: Array<{
          __typename?: "ExternalInsurance";
          id: string;
          externalId: string;
          sourceId: string;
          sourceSystem: SourceSystem;
          type?: string | null;
          startDate?: number | null;
          endDate?: number | null;
          required: boolean;
          company?: string | null;
          policyNumber?: string | null;
          limit?: string | null;
        }>;
      }>;
    }>;
    skipped: Array<{
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      defaultPaymentTerm?: number | null;
      defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        ledgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
        } | null;
      } | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
        receivesInvoiceNotifications: boolean;
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
      externalVendors: Array<{
        __typename?: "ExternalVendor";
        id: string;
        sourceSystem: SourceSystem;
        insurances: Array<{
          __typename?: "ExternalInsurance";
          id: string;
          externalId: string;
          sourceId: string;
          sourceSystem: SourceSystem;
          type?: string | null;
          startDate?: number | null;
          endDate?: number | null;
          required: boolean;
          company?: string | null;
          policyNumber?: string | null;
          limit?: string | null;
        }>;
      }>;
    }>;
    mapped: Array<{
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      defaultPaymentTerm?: number | null;
      defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        ledgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
        } | null;
      } | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        cellPhoneExtension?: string | null;
        email: string;
        receivesInvoiceNotifications: boolean;
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
      sellerOrgLocation: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
      externalVendors: Array<{
        __typename?: "ExternalVendor";
        id: string;
        sourceSystem: SourceSystem;
        insurances: Array<{
          __typename?: "ExternalInsurance";
          id: string;
          externalId: string;
          sourceId: string;
          sourceSystem: SourceSystem;
          type?: string | null;
          startDate?: number | null;
          endDate?: number | null;
          required: boolean;
          company?: string | null;
          policyNumber?: string | null;
          limit?: string | null;
        }>;
      }>;
    }>;
  };
};

export type AdminOrgPreferredVendorsFieldsFragment = {
  __typename?: "OrgPreferredVendor";
  id: string;
  externalCode?: string | null;
  internal: boolean;
  taxExempt: boolean;
  defaultPaymentTerm?: number | null;
  org: {
    __typename?: "Organization";
    id: string;
    name: string;
    phone?: string | null;
    type: OrgType;
    photoUrl?: string | null;
    hqAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
  costCode?: { __typename?: "CostCode"; id: string } | null;
  contacts: Array<{
    __typename?: "VendorContact";
    id: string;
    name: string;
    cellPhone: string;
    cellPhoneExtension?: string | null;
    email: string;
    receivesInvoiceNotifications: boolean;
    receivesQuoteNotifications: boolean;
    receivesBuyoutNotifications: boolean;
    receivesOrderNotifications: boolean;
  }>;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  };
  externalVendors: Array<{
    __typename?: "ExternalVendor";
    id: string;
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
};

export type UpdateVendorMutationVariables = Exact<{
  input: UpdateOrgPreferredVendorInput;
}>;

export type UpdateVendorMutation = {
  __typename?: "Mutation";
  updateOrgPreferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    externalCode?: string | null;
    internal: boolean;
    taxExempt: boolean;
    defaultPaymentTerm?: number | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      phone?: string | null;
      type: OrgType;
      photoUrl?: string | null;
      hqAddress?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesInvoiceNotifications: boolean;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
    }>;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    externalVendors: Array<{
      __typename?: "ExternalVendor";
      id: string;
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
};

export type UpdateVendorLocationMutationVariables = Exact<{
  input: UpdateOrgLocationInput;
}>;

export type UpdateVendorLocationMutation = {
  __typename?: "Mutation";
  updateVendorLocation?: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    phone?: string | null;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    contractorOrg?: { __typename?: "Organization"; id: string } | null;
  } | null;
};

export type RefreshExternalVendorsMutationVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type RefreshExternalVendorsMutation = {
  __typename?: "Mutation";
  refreshExternalVendors: number;
};

export type ExternalVendorQueryVariables = Exact<{
  input: ExternalIdQueryInput;
}>;

export type ExternalVendorQuery = {
  __typename?: "Query";
  externalVendor: {
    __typename?: "ExternalVendor";
    id: string;
    externalId: string;
    sourceId: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    address?: {
      __typename?: "ExternalAddress";
      city?: string | null;
      country?: string | null;
      postalCode?: string | null;
      state?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
    } | null;
  };
};

export type OrgsQueryVariables = Exact<{
  filter?: InputMaybe<QueryOrgsFilter>;
}>;

export type OrgsQuery = {
  __typename?: "Query";
  orgs: Array<{
    __typename?: "Organization";
    id: string;
    name: string;
    locations: Array<{
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    }>;
  }>;
};

export type AdminOrgPreferredVendorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryOrgPreferredVendorsFilter>;
}>;

export type AdminOrgPreferredVendorsQuery = {
  __typename?: "Query";
  orgPreferredVendorsConnection: {
    __typename?: "OrgPreferredVendorConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "OrgPreferredVendorEdge";
      cursor: string;
      node: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        internal: boolean;
        taxExempt: boolean;
        defaultPaymentTerm?: number | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          phone?: string | null;
          type: OrgType;
          photoUrl?: string | null;
          hqAddress?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        defaultReleaseType?: { __typename?: "ReleaseType"; id: string } | null;
        costCode?: { __typename?: "CostCode"; id: string } | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesInvoiceNotifications: boolean;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
        }>;
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          phone?: string | null;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        externalVendors: Array<{
          __typename?: "ExternalVendor";
          id: string;
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      };
    }>;
  };
};

export type EnterpriseVendorsQueryVariables = Exact<{
  excludedOrgIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type EnterpriseVendorsQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      enterprise?: {
        __typename?: "Enterprise";
        id: string;
        orgPreferredVendors: Array<{
          __typename?: "OrgPreferredVendor";
          id: string;
          externalCode?: string | null;
          internal: boolean;
          taxExempt: boolean;
          defaultPaymentTerm?: number | null;
          externalVendors: Array<{
            __typename?: "ExternalVendor";
            id: string;
            sourceId: string;
            sourceSystem: SourceSystem;
            externalId: string;
          }>;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            phone?: string | null;
            type: OrgType;
            photoUrl?: string | null;
            hqAddress?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
          };
          defaultReleaseType?: {
            __typename?: "ReleaseType";
            id: string;
          } | null;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
            cellPhone: string;
            cellPhoneExtension?: string | null;
            email: string;
            receivesInvoiceNotifications: boolean;
            receivesQuoteNotifications: boolean;
            receivesBuyoutNotifications: boolean;
            receivesOrderNotifications: boolean;
          }>;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            phone?: string | null;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
              contractorOrg?: {
                __typename?: "Organization";
                id: string;
              } | null;
            };
            contractorOrg?: { __typename?: "Organization"; id: string } | null;
          };
        }>;
      } | null;
    };
  } | null;
};

export type AddWarehouseMutationVariables = Exact<{
  input: AddWarehouseInput;
}>;

export type AddWarehouseMutation = {
  __typename?: "Mutation";
  addWarehouse: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
};

export type ArchiveWarehouseMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveWarehouseMutation = {
  __typename?: "Mutation";
  archiveWarehouse: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
};

export type UpdateWarehouseMutationVariables = Exact<{
  input: UpdateWarehouseInput;
}>;

export type UpdateWarehouseMutation = {
  __typename?: "Mutation";
  updateWarehouse: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
};

export type WarehousesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type WarehousesQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Organization";
    id: string;
    warehouses: Array<{
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    }>;
  } | null;
};

export type BuyoutReleasesFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  status: BuyoutStatus;
  subtotal: string;
  version: number;
  requestedAmount: string;
  scheduledAmount: string;
  completedAmount: string;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  releaseType: { __typename?: "ReleaseType"; id: string };
  quote?: {
    __typename?: "Quote";
    id: string;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      status: RfqStatus;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      };
    };
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      receivesOrderNotifications: boolean;
      cellPhone: string;
      email: string;
    }>;
  } | null;
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    };
  };
  releases: Array<{
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    includeServices: Array<ServiceType>;
    total?: string | null;
    deletedAt?: number | null;
    time?: number | null;
    timeTBD: boolean;
    status: ReleaseStatus;
    version: number;
    hasMissingCostCodes: boolean;
    poNumber?: string | null;
    project?: {
      __typename?: "Project";
      id: string;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    permissions: {
      __typename?: "ReleasePermissions";
      archive: AuthorizationStatus;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      default: boolean;
      requireDeliverySlip: boolean;
      billable: boolean;
      enableUpcomingNotifications: boolean;
      includeAdditionalCharges: boolean;
      transactionKind: TransactionKind;
      taxMode?: TaxMode | null;
      chargesMode?: ChargesMode | null;
      poFormat?: PoFormat | null;
      archivedAt?: number | null;
      defaultAutoSync: boolean;
      nonJobPO: boolean;
      trackInventory: boolean;
      includeTaxInInvoiceSubtotal: boolean;
      ledgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
      ledgerSubaccount?: {
        __typename?: "ExternalLedgerSubaccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      poType?: {
        __typename?: "ExternalPOType";
        sourceSystem: SourceSystem;
        externalId: string;
        sourceId: string;
        name: string;
      } | null;
      additionalChargesCostCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      additionalChargesCostType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      salesTaxCostCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      salesTaxCostType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      defaultOrgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | { __typename?: "OrgCatalogSku"; id: string; name: string }
          | { __typename?: "Product"; id: string; name: string }
          | { __typename?: "Sku"; id: string; name: string };
      } | null;
      salesTaxOrgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | { __typename?: "OrgCatalogSku"; id: string; name: string }
          | { __typename?: "Product"; id: string; name: string }
          | { __typename?: "Sku"; id: string; name: string };
      } | null;
    };
  }>;
};

export type BuyoutReleasesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type BuyoutReleasesQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    status: BuyoutStatus;
    subtotal: string;
    version: number;
    requestedAmount: string;
    scheduledAmount: string;
    completedAmount: string;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    releaseType: { __typename?: "ReleaseType"; id: string };
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          email: string;
        };
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesOrderNotifications: boolean;
        cellPhone: string;
        email: string;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      includeServices: Array<ServiceType>;
      total?: string | null;
      deletedAt?: number | null;
      time?: number | null;
      timeTBD: boolean;
      status: ReleaseStatus;
      version: number;
      hasMissingCostCodes: boolean;
      poNumber?: string | null;
      project?: {
        __typename?: "Project";
        id: string;
        location: { __typename?: "OrgLocation"; id: string };
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      poLink?: {
        __typename?: "POLink";
        id: string;
        format: PoFormat;
        sourceSystem: SourceSystem;
        externalWarehouseId?: string | null;
        syncing: boolean;
        syncedVersion: number;
        syncedAt?: number | null;
        createdAt: number;
        autoSync: boolean;
        autoSyncError?: string | null;
        autoSyncFailureCount: number;
        retroactive: boolean;
        immutable: boolean;
        failedAt?: number | null;
        failedMessage?: string | null;
        validationErrors: Array<PoValidationError>;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      deliverySlips: Array<{
        __typename?: "DeliverySlip";
        id: string;
        status: DeliverySlipStatus;
        createdAt: number;
        reviewedAt?: number | null;
        archivedAt?: number | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        inventoryTransaction?: {
          __typename?: "InventoryTransaction";
          id: string;
        } | null;
        asset?: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        } | null;
      }>;
      deliveryPhotos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      permissions: {
        __typename?: "ReleasePermissions";
        archive: AuthorizationStatus;
      };
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        default: boolean;
        requireDeliverySlip: boolean;
        billable: boolean;
        enableUpcomingNotifications: boolean;
        includeAdditionalCharges: boolean;
        transactionKind: TransactionKind;
        taxMode?: TaxMode | null;
        chargesMode?: ChargesMode | null;
        poFormat?: PoFormat | null;
        archivedAt?: number | null;
        defaultAutoSync: boolean;
        nonJobPO: boolean;
        trackInventory: boolean;
        includeTaxInInvoiceSubtotal: boolean;
        ledgerAccount?: {
          __typename?: "ExternalLedgerAccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
          subaccounts?: Array<{
            __typename?: "ExternalLedgerSubaccount";
            externalId: string;
            sourceId: string;
            name: string;
            number: string;
          }> | null;
        } | null;
        ledgerSubaccount?: {
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        } | null;
        costType?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        poType?: {
          __typename?: "ExternalPOType";
          sourceSystem: SourceSystem;
          externalId: string;
          sourceId: string;
          name: string;
        } | null;
        additionalChargesCostCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        additionalChargesCostType?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        salesTaxCostCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        salesTaxCostType?: {
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        defaultOrgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | { __typename?: "OrgCatalogSku"; id: string; name: string }
            | { __typename?: "Product"; id: string; name: string }
            | { __typename?: "Sku"; id: string; name: string };
        } | null;
        salesTaxOrgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | { __typename?: "OrgCatalogSku"; id: string; name: string }
            | { __typename?: "Product"; id: string; name: string }
            | { __typename?: "Sku"; id: string; name: string };
        } | null;
      };
    }>;
  } | null;
};

export type BuyoutFieldsFragment = {
  __typename?: "Buyout";
  id: string;
  clientIdentifier: string;
  subtotal: string;
  poExists?: boolean | null;
  description?: string | null;
  createdAt: number;
  status: BuyoutStatus;
  includedServices: Array<ServiceType>;
  poNumber?: string | null;
  version: number;
  paymentTermDays?: number | null;
  notes?: string | null;
  taxAmount?: string | null;
  taxRate?: string | null;
  netAmount: string;
  taxType?: TaxType | null;
  customTaxAmount?: string | null;
  additionalChargesAllowance?: string | null;
  exportedAt?: number | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  releases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
  }>;
  quote?: {
    __typename?: "Quote";
    id: string;
    notes?: string | null;
    expirationDate?: number | null;
    paymentTermDays?: number | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    discount?: {
      __typename?: "Discount";
      amount?: string | null;
      percentage?: string | null;
    } | null;
    rfq: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      includeServices: Array<ServiceType>;
      status: RfqStatus;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
          status: BuyoutStatus;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          };
        } | null;
      }>;
    };
  } | null;
  quoteDocument?: {
    __typename?: "QuoteDocument";
    id: string;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
  } | null;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    taxExempt: boolean;
    contacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      email: string;
      receivesBuyoutNotifications: boolean;
    }>;
  } | null;
  releaseType: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
  };
  sellerOrgLocation: {
    __typename?: "OrgLocation";
    id: string;
    name: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  };
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    startDate?: number | null;
    jobNumber?: string | null;
    taxExempt: boolean;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: { __typename?: "OrgLocation"; id: string };
    permissions: {
      __typename?: "ProjectPermissions";
      report: AuthorizationStatus;
    };
  };
  items: Array<{
    __typename?: "BuyoutItem";
    id: string;
    quantityDecimal: string;
    unitPrice: string;
    requestedUnitPrice?: string | null;
    releasedAmount?: string | null;
    completedAmount?: string | null;
    expirationDate?: number | null;
    notes?: string | null;
    isIncluded: boolean;
    position?: number | null;
    description: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    };
    rfqItem?: {
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
    } | null;
    orgCatalogSku?: {
      __typename?: "OrgCatalogSku";
      id: string;
      name: string;
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    masterSku?: {
      __typename?: "Sku";
      id: string;
      name: string;
      manufacturer: {
        __typename?: "Manufacturer";
        url?: string | null;
        id: string;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    releaseItems: Array<{
      __typename?: "ReleaseItem";
      id: string;
      quantityDecimal: string;
      unitPrice?: string | null;
      release: {
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        } | null;
      };
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
    }>;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
  }>;
  standaloneReleaseItems: Array<{
    __typename?: "BuyoutStandaloneReleaseItem";
    totalQuantity: string;
    unitPrice: string;
    orderedSoFar: string;
    receivedSoFar: string;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    orgMaterial: {
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    };
    releaseItems: Array<{
      __typename?: "ReleaseItem";
      id: string;
      quantityDecimal: string;
      unitPrice?: string | null;
      release: {
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        } | null;
      };
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
    }>;
  }>;
  additionalCharges: Array<{
    __typename?: "Charge";
    id: string;
    description: string;
    amount: string;
  }>;
  taxCode?: {
    __typename?: "TaxCode";
    id: string;
    code: string;
    description?: string | null;
    rate: string;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  poLink?: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  vendorContacts: Array<{
    __typename?: "VendorContact";
    id: string;
    name: string;
    cellPhone: string;
    cellPhoneExtension?: string | null;
    email: string;
    receivesQuoteNotifications: boolean;
    receivesBuyoutNotifications: boolean;
    receivesOrderNotifications: boolean;
    receivesInvoiceNotifications: boolean;
  }>;
};

export type BuyoutItemFieldsFragment = {
  __typename?: "BuyoutItem";
  id: string;
  quantityDecimal: string;
  unitPrice: string;
  requestedUnitPrice?: string | null;
  releasedAmount?: string | null;
  completedAmount?: string | null;
  expirationDate?: number | null;
  notes?: string | null;
  isIncluded: boolean;
  position?: number | null;
  description: string;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  projectItem: {
    __typename?: "ProjectItem";
    id: string;
    material: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    };
    estimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    estimatedItems: Array<{
      __typename?: "EstimatedItem";
      id: string;
      quantityDecimal: string;
      orderedQuantity: string;
      duplicateCount: number;
      unitPrice?: string | null;
      notes?: string | null;
      position?: number | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    }>;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
  };
  rfqItem?: {
    __typename?: "RfqItem";
    id: string;
    description: string;
    quantityDecimal: string;
  } | null;
  orgCatalogSku?: {
    __typename?: "OrgCatalogSku";
    id: string;
    name: string;
  } | null;
  manufacturer?: {
    __typename?: "Manufacturer";
    id: string;
    name: string;
  } | null;
  masterSku?: {
    __typename?: "Sku";
    id: string;
    name: string;
    manufacturer: {
      __typename?: "Manufacturer";
      url?: string | null;
      id: string;
      name: string;
    };
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  releaseItems: Array<{
    __typename?: "ReleaseItem";
    id: string;
    quantityDecimal: string;
    unitPrice?: string | null;
    release: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
    };
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
  }>;
  tags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
};

export type BuyoutItemReleaseItemFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  quantityDecimal: string;
  unitPrice?: string | null;
  release: {
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    sequenceNumber?: number | null;
    time?: number | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
  };
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  zone?: {
    __typename?: "Zone";
    id: string;
    name: string;
    inUse: boolean;
  } | null;
};

export type BuyoutStandaloneReleaseItemFieldsFragment = {
  __typename?: "BuyoutStandaloneReleaseItem";
  totalQuantity: string;
  unitPrice: string;
  orderedSoFar: string;
  receivedSoFar: string;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  orgMaterial: {
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    defaultEstimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    referenceCounts: {
      __typename?: "MaterialReferenceCounts";
      estimatedItems: number;
      rfqItems: number;
      buyoutItems: number;
      releaseItems: number;
    };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    vendorPrices: Array<{
      __typename?: "VendorPrice";
      id: string;
      orgMaterialId: string;
      price: string;
      expirationDate?: number | null;
      orderIncrement?: number | null;
      minimumOrder?: number | null;
      leadTime?: number | null;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      uom: { __typename?: "UOM"; id: string };
      manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
    }>;
    externalItems: Array<{
      __typename?: "ExternalItem";
      id: string;
      externalId: string;
      name: string;
      purchasingUom?: string | null;
      defaultCostCode?: string | null;
      sourceSystem: SourceSystem;
      sourceId: string;
    }>;
  };
  releaseItems: Array<{
    __typename?: "ReleaseItem";
    id: string;
    quantityDecimal: string;
    unitPrice?: string | null;
    release: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
    };
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
  }>;
};

export type ActivateBuyoutMutationVariables = Exact<{
  input: ActivateBuyoutInput;
}>;

export type ActivateBuyoutMutation = {
  __typename?: "Mutation";
  activateBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    subtotal: string;
    poExists?: boolean | null;
    description?: string | null;
    createdAt: number;
    status: BuyoutStatus;
    includedServices: Array<ServiceType>;
    poNumber?: string | null;
    version: number;
    paymentTermDays?: number | null;
    notes?: string | null;
    taxAmount?: string | null;
    taxRate?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    customTaxAmount?: string | null;
    additionalChargesAllowance?: string | null;
    exportedAt?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesBuyoutNotifications: boolean;
      }>;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      releasedAmount?: string | null;
      completedAmount?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      position?: number | null;
      description: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    standaloneReleaseItems: Array<{
      __typename?: "BuyoutStandaloneReleaseItem";
      totalQuantity: string;
      unitPrice: string;
      orderedSoFar: string;
      receivedSoFar: string;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
  };
};

export type AddToBuyoutMutationVariables = Exact<{
  input: AddToBuyoutInput;
}>;

export type AddToBuyoutMutation = {
  __typename?: "Mutation";
  addToBuyout: Array<{
    __typename?: "BuyoutItem";
    id: string;
    quantityDecimal: string;
    unitPrice: string;
    requestedUnitPrice?: string | null;
    releasedAmount?: string | null;
    completedAmount?: string | null;
    expirationDate?: number | null;
    notes?: string | null;
    isIncluded: boolean;
    position?: number | null;
    description: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    projectItem: {
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        quantityDecimal: string;
        orderedQuantity: string;
        duplicateCount: number;
        unitPrice?: string | null;
        notes?: string | null;
        position?: number | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    };
    rfqItem?: {
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
    } | null;
    orgCatalogSku?: {
      __typename?: "OrgCatalogSku";
      id: string;
      name: string;
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    masterSku?: {
      __typename?: "Sku";
      id: string;
      name: string;
      manufacturer: {
        __typename?: "Manufacturer";
        url?: string | null;
        id: string;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    releaseItems: Array<{
      __typename?: "ReleaseItem";
      id: string;
      quantityDecimal: string;
      unitPrice?: string | null;
      release: {
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        } | null;
      };
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
    }>;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
  }>;
};

export type CancelBuyoutMutationVariables = Exact<{
  input: CancelBuyoutInput;
}>;

export type CancelBuyoutMutation = {
  __typename?: "Mutation";
  cancelBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    subtotal: string;
    poExists?: boolean | null;
    description?: string | null;
    createdAt: number;
    status: BuyoutStatus;
    includedServices: Array<ServiceType>;
    poNumber?: string | null;
    version: number;
    paymentTermDays?: number | null;
    notes?: string | null;
    taxAmount?: string | null;
    taxRate?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    customTaxAmount?: string | null;
    additionalChargesAllowance?: string | null;
    exportedAt?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesBuyoutNotifications: boolean;
      }>;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      releasedAmount?: string | null;
      completedAmount?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      position?: number | null;
      description: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    standaloneReleaseItems: Array<{
      __typename?: "BuyoutStandaloneReleaseItem";
      totalQuantity: string;
      unitPrice: string;
      orderedSoFar: string;
      receivedSoFar: string;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
  };
};

export type SubmitBuyoutMutationVariables = Exact<{
  input: SubmitBuyoutInput;
}>;

export type SubmitBuyoutMutation = {
  __typename?: "Mutation";
  submitBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    subtotal: string;
    poExists?: boolean | null;
    description?: string | null;
    createdAt: number;
    status: BuyoutStatus;
    includedServices: Array<ServiceType>;
    poNumber?: string | null;
    version: number;
    paymentTermDays?: number | null;
    notes?: string | null;
    taxAmount?: string | null;
    taxRate?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    customTaxAmount?: string | null;
    additionalChargesAllowance?: string | null;
    exportedAt?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesBuyoutNotifications: boolean;
      }>;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      releasedAmount?: string | null;
      completedAmount?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      position?: number | null;
      description: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    standaloneReleaseItems: Array<{
      __typename?: "BuyoutStandaloneReleaseItem";
      totalQuantity: string;
      unitPrice: string;
      orderedSoFar: string;
      receivedSoFar: string;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
  };
};

export type UpdateContractorBuyoutMutationVariables = Exact<{
  input: UpdateContractorBuyoutInput;
}>;

export type UpdateContractorBuyoutMutation = {
  __typename?: "Mutation";
  updateContractorBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    subtotal: string;
    poExists?: boolean | null;
    description?: string | null;
    createdAt: number;
    status: BuyoutStatus;
    includedServices: Array<ServiceType>;
    poNumber?: string | null;
    version: number;
    paymentTermDays?: number | null;
    notes?: string | null;
    taxAmount?: string | null;
    taxRate?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    customTaxAmount?: string | null;
    additionalChargesAllowance?: string | null;
    exportedAt?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesBuyoutNotifications: boolean;
      }>;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      releasedAmount?: string | null;
      completedAmount?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      position?: number | null;
      description: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    standaloneReleaseItems: Array<{
      __typename?: "BuyoutStandaloneReleaseItem";
      totalQuantity: string;
      unitPrice: string;
      orderedSoFar: string;
      receivedSoFar: string;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
  };
};

export type BuyoutQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type BuyoutQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    subtotal: string;
    poExists?: boolean | null;
    description?: string | null;
    createdAt: number;
    status: BuyoutStatus;
    includedServices: Array<ServiceType>;
    poNumber?: string | null;
    version: number;
    paymentTermDays?: number | null;
    notes?: string | null;
    taxAmount?: string | null;
    taxRate?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    customTaxAmount?: string | null;
    additionalChargesAllowance?: string | null;
    exportedAt?: number | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
        quotes: Array<{
          __typename?: "Quote";
          id: string;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
            status: BuyoutStatus;
            sellerOrgLocation: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            };
          } | null;
        }>;
      };
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesBuyoutNotifications: boolean;
      }>;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      startDate?: number | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      releasedAmount?: string | null;
      completedAmount?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      position?: number | null;
      description: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    standaloneReleaseItems: Array<{
      __typename?: "BuyoutStandaloneReleaseItem";
      totalQuantity: string;
      unitPrice: string;
      orderedSoFar: string;
      receivedSoFar: string;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        release: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
          } | null;
        };
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
      }>;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
  } | null;
};

export type ArchiveBuyoutsMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type ArchiveBuyoutsMutation = {
  __typename?: "Mutation";
  archiveBuyouts: Array<{
    __typename?: "Buyout";
    id: string;
    deletedAt?: number | null;
    status: BuyoutStatus;
  }>;
};

export type NoteDocumentFieldsFragment = {
  __typename?: "NoteDocument";
  id: string;
  createdAt: number;
  orgLocation: { __typename?: "OrgLocation"; id: string };
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
  items: Array<{
    __typename?: "NoteDocumentItem";
    id: string;
    description?: string | null;
    quantity?: string | null;
    unitPrice?: string | null;
    uom?: string | null;
  }>;
};

export type NoteDocumentItemFieldsFragment = {
  __typename?: "NoteDocumentItem";
  id: string;
  description?: string | null;
  quantity?: string | null;
  unitPrice?: string | null;
  uom?: string | null;
};

export type NoteDocumentSummaryFieldsFragment = {
  __typename?: "NoteDocument";
  id: string;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
};

export type CreateNoteDocumentMutationVariables = Exact<{
  input: CreateNoteDocumentInput;
}>;

export type CreateNoteDocumentMutation = {
  __typename?: "Mutation";
  createNoteDocument: {
    __typename?: "NoteDocument";
    id: string;
    createdAt: number;
    orgLocation: { __typename?: "OrgLocation"; id: string };
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    items: Array<{
      __typename?: "NoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
    }>;
  };
};

export type NoteDocumentQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type NoteDocumentQuery = {
  __typename?: "Query";
  noteDocument: {
    __typename?: "NoteDocument";
    id: string;
    createdAt: number;
    orgLocation: { __typename?: "OrgLocation"; id: string };
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    items: Array<{
      __typename?: "NoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
    }>;
  };
};

export type QuoteDocumentFieldsFragment = {
  __typename?: "QuoteDocument";
  id: string;
  taxAmount?: string | null;
  expirationDate?: number | null;
  paymentTerm?: number | null;
  createdAt: number;
  subtotal?: string | null;
  orgLocation: { __typename?: "OrgLocation"; id: string };
  predictedProject?: {
    __typename?: "Project";
    id: string;
    name: string;
    location: { __typename?: "OrgLocation"; id: string };
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
  items: Array<{
    __typename?: "QuoteDocumentItem";
    id: string;
    description?: string | null;
    quantity?: string | null;
    unitPrice?: string | null;
    uom?: string | null;
    manufacturer?: string | null;
  }>;
  releaseItemHints: Array<{
    __typename?: "ReleaseItemHint";
    releaseItem: {
      __typename?: "ReleaseItem";
      id: string;
      unitPrice?: string | null;
      quantityDecimal: string;
      position?: number | null;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    };
    invoiceItem?: {
      __typename?: "InvoiceItem";
      id: string;
      UOM: string;
      unitPrice: string;
      quantityDecimal: string;
    } | null;
    deliverySlipItem?: {
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
    quoteDocumentItem?: {
      __typename?: "QuoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
  }>;
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
};

export type QuoteDocumentItemFieldsFragment = {
  __typename?: "QuoteDocumentItem";
  id: string;
  description?: string | null;
  quantity?: string | null;
  unitPrice?: string | null;
  uom?: string | null;
  manufacturer?: string | null;
};

export type QuoteDocumentSummaryFieldsFragment = {
  __typename?: "QuoteDocument";
  id: string;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
};

export type QuoteDocumentQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type QuoteDocumentQuery = {
  __typename?: "Query";
  quoteDocument: {
    __typename?: "QuoteDocument";
    id: string;
    taxAmount?: string | null;
    expirationDate?: number | null;
    paymentTerm?: number | null;
    createdAt: number;
    subtotal?: string | null;
    orgLocation: { __typename?: "OrgLocation"; id: string };
    predictedProject?: {
      __typename?: "Project";
      id: string;
      name: string;
      location: { __typename?: "OrgLocation"; id: string };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    items: Array<{
      __typename?: "QuoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
  };
};

export type InventoryTransactionFieldsFragment = {
  __typename?: "InventoryTransaction";
  id: string;
  type: InventoryTransactionType;
  number: number;
  date: number;
  createdAt: number;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  origin?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  destination?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  deliverySlip?: {
    __typename?: "DeliverySlip";
    id: string;
    deliveredReleaseItems: Array<{
      __typename?: "DeliveredReleaseItem";
      id: string;
      quantity: string;
      inventoryTransactionItems?: Array<{
        __typename?: "InventoryTransactionItem";
        id: string;
      }> | null;
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        deliveryDate?: number | null;
        deliveryDateTBD: boolean;
        unitPrice?: string | null;
      };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      includeServices: Array<ServiceType>;
      timeTBD: boolean;
      time?: number | null;
      taxRate?: string | null;
      customTaxAmount?: string | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      deliverySlips: Array<{
        __typename?: "DeliverySlip";
        id: string;
        status: DeliverySlipStatus;
        createdAt: number;
        reviewedAt?: number | null;
        archivedAt?: number | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        inventoryTransaction?: {
          __typename?: "InventoryTransaction";
          id: string;
        } | null;
        asset?: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        } | null;
      }>;
      taxCode?: { __typename?: "TaxCode"; id: string; rate: string } | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        amount: string;
      }>;
      items: Array<{
        __typename?: "ReleaseItem";
        id: string;
        quantityDecimal: string;
      }>;
      project?: {
        __typename?: "Project";
        id: string;
        location: { __typename?: "OrgLocation"; id: string };
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
      watchers: Array<{ __typename?: "User"; id: string }>;
      type: {
        __typename?: "ReleaseType";
        id: string;
        taxMode?: TaxMode | null;
        transactionKind: TransactionKind;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
    } | null;
  } | null;
  items: Array<{
    __typename?: "InventoryTransactionItem";
    id: string;
    unitPrice: string;
    quantity: string;
    notes?: string | null;
    item: {
      __typename?: "InventoryItem";
      id: string;
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
      };
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    };
  }>;
};

export type InventoryTransactionItemFieldsFragment = {
  __typename?: "InventoryTransactionItem";
  id: string;
  unitPrice: string;
  quantity: string;
  notes?: string | null;
  item: {
    __typename?: "InventoryItem";
    id: string;
    orgMaterial: {
      __typename?: "OrgMaterial";
      id: string;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
    };
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
  };
};

export type InventoryItemTransactionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: QueryInventoryItemTransactionsFilter;
}>;

export type InventoryItemTransactionsQuery = {
  __typename?: "Query";
  inventoryItemTransactions?: {
    __typename?: "InventoryTransactionConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InventoryTransactionEdge";
      cursor: string;
      node: {
        __typename?: "InventoryTransaction";
        id: string;
        type: InventoryTransactionType;
        number: number;
        date: number;
        createdAt: number;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        instructions?: { __typename?: "Instruction"; text: string } | null;
        origin?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
          externalCode?: string | null;
          archivedAt?: number | null;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
        } | null;
        destination?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
          externalCode?: string | null;
          archivedAt?: number | null;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
        } | null;
        deliverySlip?: {
          __typename?: "DeliverySlip";
          id: string;
          deliveredReleaseItems: Array<{
            __typename?: "DeliveredReleaseItem";
            id: string;
            quantity: string;
            inventoryTransactionItems?: Array<{
              __typename?: "InventoryTransactionItem";
              id: string;
            }> | null;
            releaseItem: {
              __typename?: "ReleaseItem";
              id: string;
              deliveryDate?: number | null;
              deliveryDateTBD: boolean;
              unitPrice?: string | null;
            };
          }>;
          release?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
            poNumber?: string | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
          } | null;
          project?: {
            __typename?: "Project";
            id: string;
            name: string;
            jobNumber?: string | null;
          } | null;
        } | null;
        items: Array<{
          __typename?: "InventoryTransactionItem";
          id: string;
          unitPrice: string;
          quantity: string;
          notes?: string | null;
          item: {
            __typename?: "InventoryItem";
            id: string;
            orgMaterial: {
              __typename?: "OrgMaterial";
              id: string;
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
            };
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        }>;
      };
    }>;
  } | null;
};

export type InventoryTransactionQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InventoryTransactionQuery = {
  __typename?: "Query";
  inventoryTransaction: {
    __typename?: "InventoryTransaction";
    id: string;
    type: InventoryTransactionType;
    number: number;
    date: number;
    createdAt: number;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    origin?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    destination?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliverySlip?: {
      __typename?: "DeliverySlip";
      id: string;
      deliveredReleaseItems: Array<{
        __typename?: "DeliveredReleaseItem";
        id: string;
        quantity: string;
        inventoryTransactionItems?: Array<{
          __typename?: "InventoryTransactionItem";
          id: string;
        }> | null;
        releaseItem: {
          __typename?: "ReleaseItem";
          id: string;
          deliveryDate?: number | null;
          deliveryDateTBD: boolean;
          unitPrice?: string | null;
        };
      }>;
      release?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        poNumber?: string | null;
        includeServices: Array<ServiceType>;
        timeTBD: boolean;
        time?: number | null;
        taxRate?: string | null;
        customTaxAmount?: string | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        deliverySlips: Array<{
          __typename?: "DeliverySlip";
          id: string;
          status: DeliverySlipStatus;
          createdAt: number;
          reviewedAt?: number | null;
          archivedAt?: number | null;
          createdBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          inventoryTransaction?: {
            __typename?: "InventoryTransaction";
            id: string;
          } | null;
          asset?: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          } | null;
        }>;
        taxCode?: { __typename?: "TaxCode"; id: string; rate: string } | null;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          amount: string;
        }>;
        items: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
        }>;
        project?: {
          __typename?: "Project";
          id: string;
          location: { __typename?: "OrgLocation"; id: string };
        } | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        } | null;
        watchers: Array<{ __typename?: "User"; id: string }>;
        type: {
          __typename?: "ReleaseType";
          id: string;
          taxMode?: TaxMode | null;
          transactionKind: TransactionKind;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
      } | null;
    } | null;
    items: Array<{
      __typename?: "InventoryTransactionItem";
      id: string;
      unitPrice: string;
      quantity: string;
      notes?: string | null;
      item: {
        __typename?: "InventoryItem";
        id: string;
        orgMaterial: {
          __typename?: "OrgMaterial";
          id: string;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
        };
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
    }>;
  };
};

export type InventoryTransactionSummaryFieldsFragment = {
  __typename?: "InventoryTransaction";
  id: string;
  type: InventoryTransactionType;
  number: number;
  date: number;
  createdAt: number;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  instructions?: { __typename?: "Instruction"; text: string } | null;
  origin?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  destination?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  deliverySlip?: {
    __typename?: "DeliverySlip";
    id: string;
    deliveredReleaseItems: Array<{
      __typename?: "DeliveredReleaseItem";
      id: string;
      quantity: string;
      inventoryTransactionItems?: Array<{
        __typename?: "InventoryTransactionItem";
        id: string;
      }> | null;
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        deliveryDate?: number | null;
        deliveryDateTBD: boolean;
        unitPrice?: string | null;
      };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
    } | null;
  } | null;
  items: Array<{
    __typename?: "InventoryTransactionItem";
    id: string;
    unitPrice: string;
    quantity: string;
    notes?: string | null;
    item: {
      __typename?: "InventoryItem";
      id: string;
      orgMaterial: {
        __typename?: "OrgMaterial";
        id: string;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
      };
      uom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
    };
  }>;
};

export type InventoryItemPendingFlowFieldsFragment = {
  __typename?: "ReleaseItem";
  id: string;
  quantityDecimal: string;
  receivedQuantityDecimal: string;
  unitPrice?: string | null;
  uom?: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  } | null;
  instructions?: {
    __typename?: "Instruction";
    text: string;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  } | null;
  release: {
    __typename?: "Release";
    id: string;
    time?: number | null;
    sequenceNumber?: number | null;
    poNumber?: string | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
    } | null;
    warehouse?: { __typename?: "Warehouse"; id: string; name: string } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
    } | null;
  };
};

export type InventoryItemStateSummaryFieldsFragment = {
  __typename?: "InventoryItemState";
  id: string;
  unitPrice: string;
  remainingQuantity: string;
  warehouse: { __typename?: "Warehouse"; id: string; name: string };
};

export type InventoryItemSummaryFieldsFragment = {
  __typename?: "InventoryItem";
  id: string;
  archivedAt?: number | null;
  orgMaterial: {
    __typename?: "OrgMaterial";
    id: string;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
  };
  state: Array<{
    __typename?: "InventoryItemState";
    id: string;
    unitPrice: string;
    remainingQuantity: string;
    warehouse: { __typename?: "Warehouse"; id: string; name: string };
  }>;
  uom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  pendingInflows: Array<{
    __typename?: "ReleaseItem";
    id: string;
    quantityDecimal: string;
    receivedQuantityDecimal: string;
    unitPrice?: string | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    release: {
      __typename?: "Release";
      id: string;
      time?: number | null;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
      } | null;
      warehouse?: { __typename?: "Warehouse"; id: string; name: string } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
    };
  }>;
  pendingOutflows: Array<{
    __typename?: "ReleaseItem";
    id: string;
    quantityDecimal: string;
    receivedQuantityDecimal: string;
    unitPrice?: string | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    release: {
      __typename?: "Release";
      id: string;
      time?: number | null;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
      } | null;
      warehouse?: { __typename?: "Warehouse"; id: string; name: string } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
    };
  }>;
};

export type InventoryTransactionItemSummaryFieldsFragment = {
  __typename?: "InventoryTransactionItem";
  id: string;
  unitPrice: string;
  quantity: string;
  notes?: string | null;
  item: {
    __typename?: "InventoryItem";
    id: string;
    archivedAt?: number | null;
    orgMaterial: {
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      costCode?: { __typename?: "CostCode"; id: string } | null;
      defaultEstimateUom: { __typename?: "UOM"; id: string };
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
            } | null;
          }
        | { __typename?: "Product"; id: string; name: string }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: { __typename?: "Manufacturer"; id: string };
          };
    };
    uom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    state: Array<{
      __typename?: "InventoryItemState";
      id: string;
      unitPrice: string;
      transactionNumber: number;
      itemPosition: number;
      originalQuantity: string;
      remainingQuantity: string;
      warehouse: { __typename?: "Warehouse"; id: string };
    }>;
  };
};

export type CreateInventoryAdjustmentMutationVariables = Exact<{
  input: CreateInventoryAdjustmentInput;
}>;

export type CreateInventoryAdjustmentMutation = {
  __typename?: "Mutation";
  createInventoryAdjustment: {
    __typename?: "InventoryTransaction";
    id: string;
    items: Array<{
      __typename?: "InventoryTransactionItem";
      id: string;
      unitPrice: string;
      quantity: string;
      notes?: string | null;
      item: {
        __typename?: "InventoryItem";
        id: string;
        archivedAt?: number | null;
        orgMaterial: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          defaultEstimateUom: { __typename?: "UOM"; id: string };
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                } | null;
              }
            | { __typename?: "Product"; id: string; name: string }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                manufacturer: { __typename?: "Manufacturer"; id: string };
              };
        };
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        state: Array<{
          __typename?: "InventoryItemState";
          id: string;
          unitPrice: string;
          transactionNumber: number;
          itemPosition: number;
          originalQuantity: string;
          remainingQuantity: string;
          warehouse: { __typename?: "Warehouse"; id: string };
        }>;
      };
    }>;
  };
};

export type CreateInventoryTransferMutationVariables = Exact<{
  input: CreateInventoryTransferInput;
}>;

export type CreateInventoryTransferMutation = {
  __typename?: "Mutation";
  createInventoryTransfer: {
    __typename?: "InventoryTransaction";
    id: string;
    items: Array<{
      __typename?: "InventoryTransactionItem";
      id: string;
      unitPrice: string;
      quantity: string;
      notes?: string | null;
      item: {
        __typename?: "InventoryItem";
        id: string;
        archivedAt?: number | null;
        orgMaterial: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          costCode?: { __typename?: "CostCode"; id: string } | null;
          defaultEstimateUom: { __typename?: "UOM"; id: string };
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                } | null;
              }
            | { __typename?: "Product"; id: string; name: string }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                manufacturer: { __typename?: "Manufacturer"; id: string };
              };
        };
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        state: Array<{
          __typename?: "InventoryItemState";
          id: string;
          unitPrice: string;
          transactionNumber: number;
          itemPosition: number;
          originalQuantity: string;
          remainingQuantity: string;
          warehouse: { __typename?: "Warehouse"; id: string };
        }>;
      };
    }>;
  };
};

export type ArchiveInventoryItemsMutationVariables = Exact<{
  input?: InputMaybe<ArchiveInventoryItemsInput>;
}>;

export type ArchiveInventoryItemsMutation = {
  __typename?: "Mutation";
  archiveInventoryItems: Array<{
    __typename?: "InventoryItem";
    id: string;
    archivedAt?: number | null;
  }>;
};

export type InventoryItemsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInventoryItemsFilter>;
}>;

export type InventoryItemsQuery = {
  __typename?: "Query";
  inventoryItems: {
    __typename?: "InventoryItemConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InventoryItemEdge";
      cursor: string;
      node: {
        __typename?: "InventoryItem";
        id: string;
        archivedAt?: number | null;
        orgMaterial: {
          __typename?: "OrgMaterial";
          id: string;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
        };
        state: Array<{
          __typename?: "InventoryItemState";
          id: string;
          unitPrice: string;
          remainingQuantity: string;
          warehouse: { __typename?: "Warehouse"; id: string; name: string };
        }>;
        uom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        pendingInflows: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          receivedQuantityDecimal: string;
          unitPrice?: string | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
            poNumber?: string | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
            project?: {
              __typename?: "Project";
              id: string;
              name: string;
              jobNumber?: string | null;
            } | null;
            warehouse?: {
              __typename?: "Warehouse";
              id: string;
              name: string;
            } | null;
            sourceWarehouse?: {
              __typename?: "Warehouse";
              id: string;
              name: string;
            } | null;
          };
        }>;
        pendingOutflows: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          receivedQuantityDecimal: string;
          unitPrice?: string | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
            poNumber?: string | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
            project?: {
              __typename?: "Project";
              id: string;
              name: string;
              jobNumber?: string | null;
            } | null;
            warehouse?: {
              __typename?: "Warehouse";
              id: string;
              name: string;
            } | null;
            sourceWarehouse?: {
              __typename?: "Warehouse";
              id: string;
              name: string;
            } | null;
          };
        }>;
      };
    }>;
  };
};

export type InventoryTransactionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: QueryInventoryTransactionsFilter;
}>;

export type InventoryTransactionsQuery = {
  __typename?: "Query";
  inventoryTransactions?: {
    __typename?: "InventoryTransactionConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InventoryTransactionEdge";
      cursor: string;
      node: {
        __typename?: "InventoryTransaction";
        id: string;
        type: InventoryTransactionType;
        number: number;
        date: number;
        createdAt: number;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        instructions?: { __typename?: "Instruction"; text: string } | null;
        origin?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
          externalCode?: string | null;
          archivedAt?: number | null;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
        } | null;
        destination?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
          externalCode?: string | null;
          archivedAt?: number | null;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
        } | null;
        deliverySlip?: {
          __typename?: "DeliverySlip";
          id: string;
          deliveredReleaseItems: Array<{
            __typename?: "DeliveredReleaseItem";
            id: string;
            quantity: string;
            inventoryTransactionItems?: Array<{
              __typename?: "InventoryTransactionItem";
              id: string;
            }> | null;
            releaseItem: {
              __typename?: "ReleaseItem";
              id: string;
              deliveryDate?: number | null;
              deliveryDateTBD: boolean;
              unitPrice?: string | null;
            };
          }>;
          release?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
            poNumber?: string | null;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
              org: {
                __typename?: "Organization";
                id: string;
                name: string;
                photoUrl?: string | null;
              };
            } | null;
          } | null;
          project?: {
            __typename?: "Project";
            id: string;
            name: string;
            jobNumber?: string | null;
          } | null;
        } | null;
        items: Array<{
          __typename?: "InventoryTransactionItem";
          id: string;
          unitPrice: string;
          quantity: string;
          notes?: string | null;
          item: {
            __typename?: "InventoryItem";
            id: string;
            orgMaterial: {
              __typename?: "OrgMaterial";
              id: string;
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
            };
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        }>;
      };
    }>;
  } | null;
};

export type InvoiceDocumentFieldsFragment = {
  __typename?: "InvoiceDocument";
  id: string;
  version: number;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
  pageRange: { __typename?: "PageRange"; start: number; end: number };
};

export type InvoiceEmailAttachmentFieldsFragment = {
  __typename?: "InvoiceEmailAttachment";
  id: string;
  rejectedAt?: number | null;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
  invoiceDocuments: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    version: number;
    invoice: {
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      status: InvoiceStatus;
      archivedAt?: number | null;
      release?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
    };
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    pageRange: { __typename?: "PageRange"; start: number; end: number };
  }>;
};

export type InvoiceEmailFieldsFragment = {
  __typename?: "InvoiceEmail";
  id: string;
  from: string;
  to: string;
  subject: string;
  content: string;
  archivedAt?: number | null;
  status: InvoiceEmailStatus;
  receivedAt: number;
  attachments: Array<{
    __typename?: "InvoiceEmailAttachment";
    id: string;
    rejectedAt?: number | null;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    invoiceDocuments: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      invoice: {
        __typename?: "Invoice";
        id: string;
        number?: string | null;
        status: InvoiceStatus;
        archivedAt?: number | null;
        release?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
      };
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
  }>;
  orgPreferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    };
    contacts: Array<{ __typename?: "VendorContact"; id: string; name: string }>;
  } | null;
  invoices: Array<{
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    status: InvoiceStatus;
    archivedAt?: number | null;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      status: ReleaseStatus;
    } | null;
  }>;
};

export type ArchiveInvoiceEmailsMutationVariables = Exact<{
  input: ArchiveInvoiceEmailsInput;
}>;

export type ArchiveInvoiceEmailsMutation = {
  __typename?: "Mutation";
  archiveInvoiceEmails: Array<{
    __typename?: "InvoiceEmail";
    id: string;
    archivedAt?: number | null;
  }>;
};

export type ArchiveInvoiceEmailMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveInvoiceEmailMutation = {
  __typename?: "Mutation";
  archiveInvoiceEmail: {
    __typename?: "InvoiceEmail";
    id: string;
    archivedAt?: number | null;
  };
};

export type InvoiceEmailsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInvoiceEmailsFilter>;
}>;

export type InvoiceEmailsQuery = {
  __typename?: "Query";
  invoiceEmails: {
    __typename?: "InvoiceEmailConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InvoiceEmailEdge";
      cursor: string;
      node: {
        __typename?: "InvoiceEmail";
        id: string;
        from: string;
        to: string;
        subject: string;
        content: string;
        archivedAt?: number | null;
        status: InvoiceEmailStatus;
        receivedAt: number;
        attachments: Array<{
          __typename?: "InvoiceEmailAttachment";
          id: string;
          rejectedAt?: number | null;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
          invoiceDocuments: Array<{
            __typename?: "InvoiceDocument";
            id: string;
            version: number;
            invoice: {
              __typename?: "Invoice";
              id: string;
              number?: string | null;
              status: InvoiceStatus;
              archivedAt?: number | null;
              release?: {
                __typename?: "Release";
                id: string;
                sequenceNumber?: number | null;
              } | null;
            };
            asset: {
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            };
            pageRange: { __typename?: "PageRange"; start: number; end: number };
          }>;
        }>;
        orgPreferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          sellerOrgLocation: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
              name: string;
            };
          };
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
          }>;
        } | null;
        invoices: Array<{
          __typename?: "Invoice";
          id: string;
          number?: string | null;
          status: InvoiceStatus;
          archivedAt?: number | null;
          release?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
            status: ReleaseStatus;
          } | null;
        }>;
      };
    }>;
  };
};

export type InvoiceFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  hasRelease: boolean;
  createdAt: number;
  poExists?: boolean | null;
  orgDuplicate: boolean;
  vendorDuplicate: boolean;
  number?: string | null;
  poNumber?: string | null;
  dueDate?: number | null;
  accountingDate?: number | null;
  description?: string | null;
  subtotal?: string | null;
  chargesAmount?: string | null;
  taxAmount?: string | null;
  total?: string | null;
  status: InvoiceStatus;
  issueDate?: number | null;
  prePaid: boolean;
  computedSubtotal: string;
  isDeliverySlip: boolean;
  exportedAt?: number | null;
  archivedAt?: number | null;
  approvedAt?: number | null;
  retentionPercentage?: string | null;
  retentionAmount?: string | null;
  discountDate?: number | null;
  discountPercentage?: string | null;
  discountAmount?: string | null;
  discountedTotal?: string | null;
  autoMatched: boolean;
  headerOnly: boolean;
  documents: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    version: number;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    pageRange: { __typename?: "PageRange"; start: number; end: number };
  }>;
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    syncVersion: number;
    poNumber?: string | null;
    version: number;
    status: ReleaseStatus;
    total?: string | null;
    netAmount: string;
    taxableNetAmount: string;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    taxVariance?: string | null;
    taxRate?: string | null;
    time?: number | null;
    customTaxAmount?: string | null;
    useSourceSystemPO: boolean;
    description?: string | null;
    itemCount: number;
    paymentTerm: number;
    taxType?: TaxType | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    createdBy: { __typename?: "User"; id: string };
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      };
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
    };
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      total?: string | null;
      number?: string | null;
      status: InvoiceStatus;
      issueDate?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
      kickbacks: Array<{
        __typename?: "InvoiceKickback";
        id: string;
        resolvedAt?: number | null;
        createdAt: number;
        deletedAt?: number | null;
      }>;
    }>;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    warehouse?: { __typename?: "Warehouse"; id: string } | null;
  } | null;
  paymentMethod?: {
    __typename?: "PaymentMethod";
    id: string;
    number?: string | null;
    type: PaymentMethodType;
    description: string;
    default: boolean;
    externalBankAccount?: {
      __typename?: "ExternalBankAccount";
      id: string;
      name: string;
    } | null;
  } | null;
  items: Array<{
    __typename?: "InvoiceItem";
    id: string;
    description: string;
    partNumber?: string | null;
    unitPrice: string;
    UOM: string;
    manufacturer?: string | null;
    quantityDecimal: string;
  }>;
  releaseItemHints: Array<{
    __typename?: "ReleaseItemHint";
    releaseItem: {
      __typename?: "ReleaseItem";
      id: string;
      unitPrice?: string | null;
      quantityDecimal: string;
      position?: number | null;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    };
    invoiceItem?: {
      __typename?: "InvoiceItem";
      id: string;
      UOM: string;
      unitPrice: string;
      quantityDecimal: string;
    } | null;
    deliverySlipItem?: {
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
    quoteDocumentItem?: {
      __typename?: "QuoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
  }>;
  charges: Array<{
    __typename?: "InvoiceCharge";
    id: string;
    description: string;
    amount: string;
  }>;
  approvedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  createdBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  kickbacks: Array<{
    __typename?: "InvoiceKickback";
    id: string;
    description?: string | null;
    createdAt: number;
    resolutionDescription?: string | null;
    resolvedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolutionAssets?: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }> | null;
    comments: Array<{
      __typename?: "InvoiceKickbackComment";
      id: string;
      text?: string | null;
      createdAt: number;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  }>;
  issues: Array<{
    __typename?: "InvoiceIssue";
    id: string;
    description: string;
    createdAt: number;
    resolutionDescription?: string | null;
    respondedAt?: number | null;
    rejectionReason?: string | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    contractorAssets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    vendorAssets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  }>;
  link?: {
    __typename?: "InvoiceLink";
    id: string;
    sourceSystem: SourceSystem;
    createdAt: number;
    exportedAt?: number | null;
    failedAt?: number | null;
    failedMessage?: string | null;
    batchId?: string | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    batch?: {
      __typename?: "ExternalBatch";
      id: string;
      number: string;
      month: string;
    } | null;
  } | null;
  permissions: {
    __typename?: "InvoicePermissions";
    edit: AuthorizationStatus;
    respond: AuthorizationStatus;
    export: AuthorizationStatus;
    archive: AuthorizationStatus;
  };
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  predictedProject?: {
    __typename?: "Project";
    id: string;
    jobNumber?: string | null;
    location: { __typename?: "OrgLocation"; id: string };
  } | null;
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: { __typename?: "Organization"; id: string; name: string };
  } | null;
  exportedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
  orgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      photoUrl?: string | null;
      name: string;
    };
  } | null;
  equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
  serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
  duplicates: Array<{
    __typename?: "Invoice";
    id: string;
    total?: string | null;
    number?: string | null;
    status: InvoiceStatus;
    archivedAt?: number | null;
    issueDate?: number | null;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    }>;
  }>;
};

export type InvoiceIssueFieldsFragment = {
  __typename?: "InvoiceIssue";
  id: string;
  description: string;
  createdAt: number;
  resolutionDescription?: string | null;
  respondedAt?: number | null;
  rejectionReason?: string | null;
  reportedBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
  contractorAssets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  vendorAssets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
};

export type InvoiceItemFieldsFragment = {
  __typename?: "InvoiceItem";
  id: string;
  description: string;
  partNumber?: string | null;
  unitPrice: string;
  UOM: string;
  manufacturer?: string | null;
  quantityDecimal: string;
};

export type InvoiceKickbackCommentFieldsFragment = {
  __typename?: "InvoiceKickbackComment";
  id: string;
  text?: string | null;
  createdAt: number;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type InvoiceKickbackFieldsFragment = {
  __typename?: "InvoiceKickback";
  id: string;
  description?: string | null;
  createdAt: number;
  resolutionDescription?: string | null;
  resolvedAt?: number | null;
  reportedBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
  assignees: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  }>;
  resolvedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  assets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  resolutionAssets?: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }> | null;
  comments: Array<{
    __typename?: "InvoiceKickbackComment";
    id: string;
    text?: string | null;
    createdAt: number;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
};

export type ReleaseItemHintFragmentFragment = {
  __typename?: "ReleaseItemHint";
  releaseItem: {
    __typename?: "ReleaseItem";
    id: string;
    unitPrice?: string | null;
    quantityDecimal: string;
    position?: number | null;
    orgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    } | null;
    uom?: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    } | null;
    manufacturer?: {
      __typename?: "Manufacturer";
      id: string;
      name: string;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
  };
  invoiceItem?: {
    __typename?: "InvoiceItem";
    id: string;
    UOM: string;
    unitPrice: string;
    quantityDecimal: string;
  } | null;
  deliverySlipItem?: {
    __typename?: "DeliverySlipItem";
    id: string;
    description?: string | null;
    orderedQuantity?: string | null;
    receivedQuantity?: string | null;
    uom?: string | null;
    manufacturer?: string | null;
  } | null;
  quoteDocumentItem?: {
    __typename?: "QuoteDocumentItem";
    id: string;
    description?: string | null;
    quantity?: string | null;
    unitPrice?: string | null;
    uom?: string | null;
    manufacturer?: string | null;
  } | null;
};

export type ApproveInvoiceMutationVariables = Exact<{
  input: ApproveInvoiceInput;
}>;

export type ApproveInvoiceMutation = {
  __typename?: "Mutation";
  approveInvoice: {
    __typename?: "Invoice";
    id: string;
    hasRelease: boolean;
    createdAt: number;
    poExists?: boolean | null;
    orgDuplicate: boolean;
    vendorDuplicate: boolean;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    accountingDate?: number | null;
    description?: string | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    issueDate?: number | null;
    prePaid: boolean;
    computedSubtotal: string;
    isDeliverySlip: boolean;
    exportedAt?: number | null;
    archivedAt?: number | null;
    approvedAt?: number | null;
    retentionPercentage?: string | null;
    retentionAmount?: string | null;
    discountDate?: number | null;
    discountPercentage?: string | null;
    discountAmount?: string | null;
    discountedTotal?: string | null;
    autoMatched: boolean;
    headerOnly: boolean;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      syncVersion: number;
      poNumber?: string | null;
      version: number;
      status: ReleaseStatus;
      total?: string | null;
      netAmount: string;
      taxableNetAmount: string;
      taxAmount?: string | null;
      taxUnadjustedAmount?: string | null;
      taxVariance?: string | null;
      taxRate?: string | null;
      time?: number | null;
      customTaxAmount?: string | null;
      useSourceSystemPO: boolean;
      description?: string | null;
      itemCount: number;
      paymentTerm: number;
      taxType?: TaxType | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        format: PoFormat;
        sourceSystem: SourceSystem;
        externalWarehouseId?: string | null;
        syncing: boolean;
        syncedVersion: number;
        syncedAt?: number | null;
        createdAt: number;
        autoSync: boolean;
        autoSyncError?: string | null;
        autoSyncFailureCount: number;
        retroactive: boolean;
        immutable: boolean;
        failedAt?: number | null;
        failedMessage?: string | null;
        validationErrors: Array<PoValidationError>;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      createdBy: { __typename?: "User"; id: string };
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      } | null;
      permissions: {
        __typename?: "ReleasePermissions";
        edit: AuthorizationStatus;
      };
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        total?: string | null;
        number?: string | null;
        status: InvoiceStatus;
        issueDate?: number | null;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        }>;
        kickbacks: Array<{
          __typename?: "InvoiceKickback";
          id: string;
          resolvedAt?: number | null;
          createdAt: number;
          deletedAt?: number | null;
        }>;
      }>;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
        costType?: {
          __typename?: "CostType";
          id: string;
          description: string;
        } | null;
      };
      warehouse?: { __typename?: "Warehouse"; id: string } | null;
    } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    } | null;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      partNumber?: string | null;
      unitPrice: string;
      UOM: string;
      manufacturer?: string | null;
      quantityDecimal: string;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    charges: Array<{
      __typename?: "InvoiceCharge";
      id: string;
      description: string;
      amount: string;
    }>;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    predictedProject?: {
      __typename?: "Project";
      id: string;
      jobNumber?: string | null;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: { __typename?: "Organization"; id: string; name: string };
    } | null;
    exportedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
    orgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
    serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
    duplicates: Array<{
      __typename?: "Invoice";
      id: string;
      total?: string | null;
      number?: string | null;
      status: InvoiceStatus;
      archivedAt?: number | null;
      issueDate?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
  };
};

export type ConnectMissingPoItemsMutationVariables = Exact<{
  poLinkId: Scalars["ID"]["input"];
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type ConnectMissingPoItemsMutation = {
  __typename?: "Mutation";
  connectMissingPOItems: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  };
};

export type CreateInvoiceIssueMutationVariables = Exact<{
  input: CreateInvoiceIssueInput;
}>;

export type CreateInvoiceIssueMutation = {
  __typename?: "Mutation";
  createInvoiceIssue: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  };
};

export type CreateInvoiceKickbackMutationVariables = Exact<{
  input: CreateInvoiceKickbackInput;
}>;

export type CreateInvoiceKickbackMutation = {
  __typename?: "Mutation";
  createInvoiceKickback: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type CreateInvoiceKickbackCommentMutationVariables = Exact<{
  input: CreateInvoiceKickbackCommentInput;
}>;

export type CreateInvoiceKickbackCommentMutation = {
  __typename?: "Mutation";
  createInvoiceKickbackComment: {
    __typename?: "InvoiceKickback";
    id: string;
    description?: string | null;
    createdAt: number;
    resolutionDescription?: string | null;
    resolvedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolutionAssets?: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }> | null;
    comments: Array<{
      __typename?: "InvoiceKickbackComment";
      id: string;
      text?: string | null;
      createdAt: number;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  };
};

export type DeleteInvoiceIssueMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteInvoiceIssueMutation = {
  __typename?: "Mutation";
  deleteInvoiceIssue: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  };
};

export type DeleteInvoiceKickbackMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteInvoiceKickbackMutation = {
  __typename?: "Mutation";
  deleteInvoiceKickback: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type DeleteInvoiceKickbackCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteInvoiceKickbackCommentMutation = {
  __typename?: "Mutation";
  deleteInvoiceKickbackComment: {
    __typename?: "InvoiceKickback";
    id: string;
    description?: string | null;
    createdAt: number;
    resolutionDescription?: string | null;
    resolvedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolutionAssets?: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }> | null;
    comments: Array<{
      __typename?: "InvoiceKickbackComment";
      id: string;
      text?: string | null;
      createdAt: number;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  };
};

export type DownloadInvoiceDocumentMutationVariables = Exact<{
  input: DownloadInvoiceDocumentInput;
}>;

export type DownloadInvoiceDocumentMutation = {
  __typename?: "Mutation";
  downloadInvoiceDocument: string;
};

export type DownloadInvoiceDocumentsMutationVariables = Exact<{
  input: DownloadInvoiceDocumentsInput;
}>;

export type DownloadInvoiceDocumentsMutation = {
  __typename?: "Mutation";
  downloadInvoiceDocuments: string;
};

export type RescanInvoiceMutationVariables = Exact<{
  invoiceId: Scalars["ID"]["input"];
}>;

export type RescanInvoiceMutation = {
  __typename?: "Mutation";
  rescanInvoice: boolean;
};

export type ResolveInvoiceKickbackMutationVariables = Exact<{
  input: ResolveInvoiceKickbackInput;
}>;

export type ResolveInvoiceKickbackMutation = {
  __typename?: "Mutation";
  resolveInvoiceKickback: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type UnarchiveInvoicesMutationVariables = Exact<{
  invoiceIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type UnarchiveInvoicesMutation = {
  __typename?: "Mutation";
  unarchiveInvoices: Array<{ __typename?: "Invoice"; id: string }>;
};

export type UpdateInvoiceMutationVariables = Exact<{
  input: UpdateInvoiceInput;
  includeDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateInvoiceMutation = {
  __typename?: "Mutation";
  updateInvoice: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    subtotal?: string | null;
    hasRelease: boolean;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    orgDuplicate: boolean;
    vendorDuplicate: boolean;
    issueDate?: number | null;
    poExists?: boolean | null;
    retentionPercentage?: string | null;
    retentionAmount?: string | null;
    discountDate?: number | null;
    description?: string | null;
    notes?: string | null;
    discountPercentage?: string | null;
    discountAmount?: string | null;
    discountedTotal?: string | null;
    headerOnly: boolean;
    accountingDate?: number | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    } | null;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      version: number;
      status: ReleaseStatus;
      paymentTerm: number;
      itemCount: number;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesOrderNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      } | null;
      permissions: {
        __typename?: "ReleasePermissions";
        edit: AuthorizationStatus;
      };
    } | null;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      approve: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
    };
    documents?: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    duplicates: Array<{
      __typename?: "Invoice";
      id: string;
      total?: string | null;
      number?: string | null;
      status: InvoiceStatus;
      issueDate?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
  };
};

export type UpdateInvoiceIssueMutationVariables = Exact<{
  input: UpdateInvoiceIssueInput;
}>;

export type UpdateInvoiceIssueMutation = {
  __typename?: "Mutation";
  updateInvoiceIssue: {
    __typename?: "Invoice";
    id: string;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  };
};

export type UpdateInvoiceKickbackMutationVariables = Exact<{
  input: UpdateInvoiceKickbackInput;
}>;

export type UpdateInvoiceKickbackMutation = {
  __typename?: "Mutation";
  updateInvoiceKickback: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type UpdateInvoiceKickbackCommentMutationVariables = Exact<{
  input: UpdateInvoiceKickbackCommentInput;
}>;

export type UpdateInvoiceKickbackCommentMutation = {
  __typename?: "Mutation";
  updateInvoiceKickbackComment: {
    __typename?: "InvoiceKickback";
    id: string;
    description?: string | null;
    createdAt: number;
    resolutionDescription?: string | null;
    resolvedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolutionAssets?: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }> | null;
    comments: Array<{
      __typename?: "InvoiceKickbackComment";
      id: string;
      text?: string | null;
      createdAt: number;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  };
};

export type InvoiceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InvoiceQuery = {
  __typename?: "Query";
  invoice: {
    __typename?: "Invoice";
    id: string;
    hasRelease: boolean;
    createdAt: number;
    poExists?: boolean | null;
    orgDuplicate: boolean;
    vendorDuplicate: boolean;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    accountingDate?: number | null;
    description?: string | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    issueDate?: number | null;
    prePaid: boolean;
    computedSubtotal: string;
    isDeliverySlip: boolean;
    exportedAt?: number | null;
    archivedAt?: number | null;
    approvedAt?: number | null;
    retentionPercentage?: string | null;
    retentionAmount?: string | null;
    discountDate?: number | null;
    discountPercentage?: string | null;
    discountAmount?: string | null;
    discountedTotal?: string | null;
    autoMatched: boolean;
    headerOnly: boolean;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      syncVersion: number;
      poNumber?: string | null;
      version: number;
      status: ReleaseStatus;
      total?: string | null;
      netAmount: string;
      taxableNetAmount: string;
      taxAmount?: string | null;
      taxUnadjustedAmount?: string | null;
      taxVariance?: string | null;
      taxRate?: string | null;
      time?: number | null;
      customTaxAmount?: string | null;
      useSourceSystemPO: boolean;
      description?: string | null;
      itemCount: number;
      paymentTerm: number;
      taxType?: TaxType | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        format: PoFormat;
        sourceSystem: SourceSystem;
        externalWarehouseId?: string | null;
        syncing: boolean;
        syncedVersion: number;
        syncedAt?: number | null;
        createdAt: number;
        autoSync: boolean;
        autoSyncError?: string | null;
        autoSyncFailureCount: number;
        retroactive: boolean;
        immutable: boolean;
        failedAt?: number | null;
        failedMessage?: string | null;
        validationErrors: Array<PoValidationError>;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      createdBy: { __typename?: "User"; id: string };
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      } | null;
      permissions: {
        __typename?: "ReleasePermissions";
        edit: AuthorizationStatus;
      };
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        total?: string | null;
        number?: string | null;
        status: InvoiceStatus;
        issueDate?: number | null;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        }>;
        kickbacks: Array<{
          __typename?: "InvoiceKickback";
          id: string;
          resolvedAt?: number | null;
          createdAt: number;
          deletedAt?: number | null;
        }>;
      }>;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
        costType?: {
          __typename?: "CostType";
          id: string;
          description: string;
        } | null;
      };
      warehouse?: { __typename?: "Warehouse"; id: string } | null;
    } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    } | null;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      partNumber?: string | null;
      unitPrice: string;
      UOM: string;
      manufacturer?: string | null;
      quantityDecimal: string;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    charges: Array<{
      __typename?: "InvoiceCharge";
      id: string;
      description: string;
      amount: string;
    }>;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    predictedProject?: {
      __typename?: "Project";
      id: string;
      jobNumber?: string | null;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: { __typename?: "Organization"; id: string; name: string };
    } | null;
    exportedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
    orgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
    serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
    duplicates: Array<{
      __typename?: "Invoice";
      id: string;
      total?: string | null;
      number?: string | null;
      status: InvoiceStatus;
      archivedAt?: number | null;
      issueDate?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
  };
};

export type InvoiceKickbackEligibleUsersQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InvoiceKickbackEligibleUsersQuery = {
  __typename?: "Query";
  invoice: {
    __typename?: "Invoice";
    id: string;
    kickbackEligibleUsers: Array<{
      __typename?: "User";
      id: string;
      email: string;
      cellPhone: string;
      createdAt: number;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
      isEmailValidated: boolean;
      isOrgAdmin?: boolean | null;
      orgRoles: Array<OrgRole>;
      enterpriseRoles: Array<EnterpriseRole>;
      org: {
        __typename?: "Organization";
        id: string;
        locations: Array<{
          __typename?: "OrgLocation";
          id: string;
          name: string;
        }>;
      };
      locationRoles: Array<{
        __typename?: "LocationRole";
        orgLocID: string;
        role: OrgLocationRole;
      }>;
    }>;
  };
};

export type InvoicePermissionsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
  includeApprove: Scalars["Boolean"]["input"];
}>;

export type InvoicePermissionsQuery = {
  __typename?: "Query";
  invoice: {
    __typename?: "Invoice";
    id: string;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      approve?: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
  };
};

export type InvoiceFolderFieldsFragment = {
  __typename?: "InvoiceFolder";
  id: string;
  name: string;
  createdAt: number;
};

export type CreateInvoiceFolderMutationVariables = Exact<{
  input: CreateInvoiceFolderInput;
}>;

export type CreateInvoiceFolderMutation = {
  __typename?: "Mutation";
  createInvoiceFolder: {
    __typename?: "InvoiceFolder";
    id: string;
    name: string;
    createdAt: number;
  };
};

export type DeleteInvoiceFolderMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteInvoiceFolderMutation = {
  __typename?: "Mutation";
  deleteInvoiceFolder: { __typename?: "InvoiceFolder"; id: string };
};

export type UpdateInvoiceFolderMutationVariables = Exact<{
  input: UpdateInvoiceFolderInput;
}>;

export type UpdateInvoiceFolderMutation = {
  __typename?: "Mutation";
  updateInvoiceFolder: {
    __typename?: "InvoiceFolder";
    id: string;
    name: string;
    createdAt: number;
  };
};

export type OrgFoldersQueryVariables = Exact<{ [key: string]: never }>;

export type OrgFoldersQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      invoiceFolders: Array<{
        __typename?: "InvoiceFolder";
        id: string;
        name: string;
        createdAt: number;
      }>;
    };
  } | null;
};

export type InvoiceLinkFieldsFragment = {
  __typename?: "InvoiceLink";
  id: string;
  sourceSystem: SourceSystem;
  createdAt: number;
  exportedAt?: number | null;
  failedAt?: number | null;
  failedMessage?: string | null;
  batchId?: string | null;
  createdBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  batch?: {
    __typename?: "ExternalBatch";
    id: string;
    number: string;
    month: string;
  } | null;
};

export type InvoiceSummaryFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  poNumber?: string | null;
  dueDate?: number | null;
  description?: string | null;
  total?: string | null;
  issueDate?: number | null;
  createdAt: number;
  archivedAt?: number | null;
  status: InvoiceStatus;
  exportedAt?: number | null;
  downloadedAt?: number | null;
  approvedAt?: number | null;
  orgDuplicate: boolean;
  vendorDuplicate: boolean;
  headerOnly: boolean;
  exportedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  downloadedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  approvedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
  } | null;
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    poNumber?: string | null;
    exportedAt?: number | null;
    status: ReleaseStatus;
    receiptPostedAt?: number | null;
    requiresInventoryReceipt?: boolean | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
    };
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      syncedAt?: number | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
    }>;
  } | null;
  link?: {
    __typename?: "InvoiceLink";
    id: string;
    sourceSystem: SourceSystem;
    createdAt: number;
    exportedAt?: number | null;
    failedAt?: number | null;
    failedMessage?: string | null;
    batchId?: string | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    batch?: {
      __typename?: "ExternalBatch";
      id: string;
      number: string;
      month: string;
    } | null;
  } | null;
  permissions: {
    __typename?: "InvoicePermissions";
    archive: AuthorizationStatus;
    export: AuthorizationStatus;
  };
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      photoUrl?: string | null;
      name: string;
    };
  } | null;
  folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
  kickbacks: Array<{
    __typename?: "InvoiceKickback";
    id: string;
    resolvedAt?: number | null;
    createdAt: number;
    deletedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type InvoiceToExportFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  status: InvoiceStatus;
  poNumber?: string | null;
  total?: string | null;
  exportedAt?: number | null;
  createdAt: number;
  taxAmount?: string | null;
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    hasMissingCostCodes: boolean;
    exportedAt?: number | null;
    status: ReleaseStatus;
    poNumber?: string | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      syncedAt?: number | null;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      deletedAt?: number | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    project?: { __typename?: "Project"; id: string; name: string } | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
      ledgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
    };
  } | null;
  documents: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    version: number;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    pageRange: { __typename?: "PageRange"; start: number; end: number };
  }>;
  permissions: {
    __typename?: "InvoicePermissions";
    archive: AuthorizationStatus;
    export: AuthorizationStatus;
  };
  link?: {
    __typename?: "InvoiceLink";
    id: string;
    exportedAt?: number | null;
    batch?: {
      __typename?: "ExternalBatch";
      id: string;
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      number: string;
      month: string;
      type: BatchType;
    } | null;
  } | null;
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: { __typename?: "Organization"; id: string; name: string };
  } | null;
};

export type InvoiceValidationFieldsFragment = {
  __typename?: "InvoiceValidation";
  errors: Array<InvoiceValidationError>;
  warnings: Array<InvoiceValidationWarning>;
  salesTaxRemainingAmount?: string | null;
  additionalChargesRemainingAmount?: string | null;
  itemsMissingCostCodes: Array<{ __typename?: "ReleaseItem"; id: string }>;
  costCodesNotConnected: Array<{ __typename?: "CostCode"; id: string }>;
  costCodesMissingInPO: Array<{
    __typename?: "CostCode";
    id: string;
    description: string;
  }>;
  costCodesExceedingPOAmounts: Array<{ __typename?: "CostCode"; id: string }>;
  wbsExceedingPOAmounts: Array<{ __typename?: "ProjectTag"; id: string }>;
  wbsMissingInPO: Array<{ __typename?: "ProjectTag"; id: string }>;
  costCodeMismatches: Array<{ __typename?: "POItemLink"; id: string }>;
  descriptionMismatches: Array<{ __typename?: "POItemLink"; id: string }>;
  uomMismatches: Array<{ __typename?: "POItemLink"; id: string }>;
  itemsNotConnected: Array<{ __typename?: "ReleaseItem"; id: string }>;
  itemsMissingTags: Array<{
    __typename?: "ReleaseItem";
    id: string;
    orgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
    } | null;
  }>;
  missingBudgetCombinations: Array<{
    __typename?: "BudgetCombination";
    costCode: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    };
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
  }>;
  itemsExceedingRemainingAmount: Array<{
    __typename?: "ReleaseItem";
    id: string;
  }>;
  itemsExceedingRemainingUnexportedAmount: Array<{
    __typename?: "ReleaseItem";
    id: string;
  }>;
  po?: {
    __typename?: "ExternalPO";
    amount: string;
    items: Array<{
      __typename?: "ExternalPOItem";
      externalId: string;
      remainingAmount?: string | null;
      description: string;
    }>;
  } | null;
};

export type KickbackSummaryFieldsFragment = {
  __typename?: "InvoiceKickback";
  id: string;
  resolvedAt?: number | null;
  createdAt: number;
  deletedAt?: number | null;
  reportedBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  assignees: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  }>;
  resolvedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type ArchiveInvoiceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveInvoiceMutation = {
  __typename?: "Mutation";
  archiveInvoice: {
    __typename?: "Invoice";
    id: string;
    archivedAt?: number | null;
    status: InvoiceStatus;
  };
};

export type ArchiveInvoicesMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type ArchiveInvoicesMutation = {
  __typename?: "Mutation";
  archiveInvoices: Array<{
    __typename?: "Invoice";
    id: string;
    archivedAt?: number | null;
    status: InvoiceStatus;
  }>;
};

export type ClearPoNumberMutationVariables = Exact<{
  invoiceId: Scalars["ID"]["input"];
}>;

export type ClearPoNumberMutation = {
  __typename?: "Mutation";
  clearPoNumber: {
    __typename?: "Invoice";
    id: string;
    poNumber?: string | null;
    release?: {
      __typename?: "Release";
      id: string;
      poNumber?: string | null;
    } | null;
  };
};

export type CreateInvoicesMutationVariables = Exact<{
  input: CreateInvoicesInput;
}>;

export type CreateInvoicesMutation = {
  __typename?: "Mutation";
  createInvoices: Array<{
    __typename?: "Invoice";
    id: string;
    hasRelease: boolean;
    createdAt: number;
    poExists?: boolean | null;
    orgDuplicate: boolean;
    vendorDuplicate: boolean;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    accountingDate?: number | null;
    description?: string | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    issueDate?: number | null;
    prePaid: boolean;
    computedSubtotal: string;
    isDeliverySlip: boolean;
    exportedAt?: number | null;
    archivedAt?: number | null;
    approvedAt?: number | null;
    retentionPercentage?: string | null;
    retentionAmount?: string | null;
    discountDate?: number | null;
    discountPercentage?: string | null;
    discountAmount?: string | null;
    discountedTotal?: string | null;
    autoMatched: boolean;
    headerOnly: boolean;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      syncVersion: number;
      poNumber?: string | null;
      version: number;
      status: ReleaseStatus;
      total?: string | null;
      netAmount: string;
      taxableNetAmount: string;
      taxAmount?: string | null;
      taxUnadjustedAmount?: string | null;
      taxVariance?: string | null;
      taxRate?: string | null;
      time?: number | null;
      customTaxAmount?: string | null;
      useSourceSystemPO: boolean;
      description?: string | null;
      itemCount: number;
      paymentTerm: number;
      taxType?: TaxType | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        format: PoFormat;
        sourceSystem: SourceSystem;
        externalWarehouseId?: string | null;
        syncing: boolean;
        syncedVersion: number;
        syncedAt?: number | null;
        createdAt: number;
        autoSync: boolean;
        autoSyncError?: string | null;
        autoSyncFailureCount: number;
        retroactive: boolean;
        immutable: boolean;
        failedAt?: number | null;
        failedMessage?: string | null;
        validationErrors: Array<PoValidationError>;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      createdBy: { __typename?: "User"; id: string };
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      } | null;
      permissions: {
        __typename?: "ReleasePermissions";
        edit: AuthorizationStatus;
      };
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        total?: string | null;
        number?: string | null;
        status: InvoiceStatus;
        issueDate?: number | null;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        }>;
        kickbacks: Array<{
          __typename?: "InvoiceKickback";
          id: string;
          resolvedAt?: number | null;
          createdAt: number;
          deletedAt?: number | null;
        }>;
      }>;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
        costType?: {
          __typename?: "CostType";
          id: string;
          description: string;
        } | null;
      };
      warehouse?: { __typename?: "Warehouse"; id: string } | null;
    } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    } | null;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      partNumber?: string | null;
      unitPrice: string;
      UOM: string;
      manufacturer?: string | null;
      quantityDecimal: string;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    charges: Array<{
      __typename?: "InvoiceCharge";
      id: string;
      description: string;
      amount: string;
    }>;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    predictedProject?: {
      __typename?: "Project";
      id: string;
      jobNumber?: string | null;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: { __typename?: "Organization"; id: string; name: string };
    } | null;
    exportedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
    orgLocation?: { __typename?: "OrgLocation"; id: string } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
    serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
    duplicates: Array<{
      __typename?: "Invoice";
      id: string;
      total?: string | null;
      number?: string | null;
      status: InvoiceStatus;
      archivedAt?: number | null;
      issueDate?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
  }>;
};

export type ExportInvoicesMutationVariables = Exact<{
  input: ExportInvoicesInput;
}>;

export type ExportInvoicesMutation = {
  __typename?: "Mutation";
  exportInvoices: {
    __typename?: "ExportInvoicesOutput";
    fileURLs?: Array<string> | null;
  };
};

export type LinkInvoiceMutationVariables = Exact<{
  input: LinkInvoiceInput;
}>;

export type LinkInvoiceMutation = {
  __typename?: "Mutation";
  linkInvoice: {
    __typename?: "Invoice";
    id: string;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
  };
};

export type SplitInvoiceDocumentMutationVariables = Exact<{
  input: SplitInvoiceDocumentInput;
}>;

export type SplitInvoiceDocumentMutation = {
  __typename?: "Mutation";
  splitInvoiceDocument: Array<{
    __typename?: "PageRange";
    start: number;
    end: number;
  }>;
};

export type UnlinkInvoiceMutationVariables = Exact<{
  invoiceLinkId: Scalars["ID"]["input"];
}>;

export type UnlinkInvoiceMutation = {
  __typename?: "Mutation";
  unlinkInvoice: {
    __typename?: "Invoice";
    id: string;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
  };
};

export type UpdateInvoiceEmailAttachmentMutationVariables = Exact<{
  input: UpdateInvoiceEmailAttachmentInput;
}>;

export type UpdateInvoiceEmailAttachmentMutation = {
  __typename?: "Mutation";
  updateInvoiceEmailAttachment: {
    __typename?: "InvoiceEmailAttachment";
    id: string;
  };
};

export type UpdateInvoicesMutationVariables = Exact<{
  input: UpdateInvoicesInput;
}>;

export type UpdateInvoicesMutation = {
  __typename?: "Mutation";
  updateInvoices: Array<{
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    description?: string | null;
    total?: string | null;
    issueDate?: number | null;
    createdAt: number;
    archivedAt?: number | null;
    status: InvoiceStatus;
    exportedAt?: number | null;
    downloadedAt?: number | null;
    approvedAt?: number | null;
    orgDuplicate: boolean;
    vendorDuplicate: boolean;
    headerOnly: boolean;
    exportedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    downloadedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
    } | null;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      exportedAt?: number | null;
      status: ReleaseStatus;
      receiptPostedAt?: number | null;
      requiresInventoryReceipt?: boolean | null;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
      };
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        syncedAt?: number | null;
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
      } | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      deliverySlips: Array<{
        __typename?: "DeliverySlip";
        id: string;
        status: DeliverySlipStatus;
      }>;
    } | null;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
      batchId?: string | null;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      batch?: {
        __typename?: "ExternalBatch";
        id: string;
        number: string;
        month: string;
      } | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      archive: AuthorizationStatus;
      export: AuthorizationStatus;
    };
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      resolvedAt?: number | null;
      createdAt: number;
      deletedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    }>;
  }>;
};

export type AssetQueryVariables = Exact<{
  url: Scalars["String"]["input"];
}>;

export type AssetQuery = {
  __typename?: "Query";
  asset: {
    __typename?: "Asset";
    id: string;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
};

export type InvoiceStatsQueryVariables = Exact<{ [key: string]: never }>;

export type InvoiceStatsQuery = {
  __typename?: "Query";
  invoiceStats: {
    __typename?: "InvoiceStats";
    pendingReceipts: number;
    emails: { __typename?: "InvoiceEmailStats"; requireAttention: number };
  };
};

export type InvoiceValidationQueryVariables = Exact<{
  input: LinkInvoiceInput;
}>;

export type InvoiceValidationQuery = {
  __typename?: "Query";
  invoiceValidation: {
    __typename?: "InvoiceValidation";
    errors: Array<InvoiceValidationError>;
    warnings: Array<InvoiceValidationWarning>;
    salesTaxRemainingAmount?: string | null;
    additionalChargesRemainingAmount?: string | null;
    itemsMissingCostCodes: Array<{ __typename?: "ReleaseItem"; id: string }>;
    costCodesNotConnected: Array<{ __typename?: "CostCode"; id: string }>;
    costCodesMissingInPO: Array<{
      __typename?: "CostCode";
      id: string;
      description: string;
    }>;
    costCodesExceedingPOAmounts: Array<{ __typename?: "CostCode"; id: string }>;
    wbsExceedingPOAmounts: Array<{ __typename?: "ProjectTag"; id: string }>;
    wbsMissingInPO: Array<{ __typename?: "ProjectTag"; id: string }>;
    costCodeMismatches: Array<{ __typename?: "POItemLink"; id: string }>;
    descriptionMismatches: Array<{ __typename?: "POItemLink"; id: string }>;
    uomMismatches: Array<{ __typename?: "POItemLink"; id: string }>;
    itemsNotConnected: Array<{ __typename?: "ReleaseItem"; id: string }>;
    itemsMissingTags: Array<{
      __typename?: "ReleaseItem";
      id: string;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
      } | null;
    }>;
    missingBudgetCombinations: Array<{
      __typename?: "BudgetCombination";
      costCode: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      };
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
    }>;
    itemsExceedingRemainingAmount: Array<{
      __typename?: "ReleaseItem";
      id: string;
    }>;
    itemsExceedingRemainingUnexportedAmount: Array<{
      __typename?: "ReleaseItem";
      id: string;
    }>;
    po?: {
      __typename?: "ExternalPO";
      amount: string;
      items: Array<{
        __typename?: "ExternalPOItem";
        externalId: string;
        remainingAmount?: string | null;
        description: string;
      }>;
    } | null;
  };
};

export type InvoicesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInvoicesFilter>;
  sort?: InputMaybe<QueryInvoicesSort>;
}>;

export type InvoicesQuery = {
  __typename?: "Query";
  invoices: {
    __typename?: "InvoiceConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InvoiceEdge";
      cursor: string;
      node: {
        __typename?: "Invoice";
        id: string;
        number?: string | null;
        poNumber?: string | null;
        dueDate?: number | null;
        description?: string | null;
        total?: string | null;
        issueDate?: number | null;
        createdAt: number;
        archivedAt?: number | null;
        status: InvoiceStatus;
        exportedAt?: number | null;
        downloadedAt?: number | null;
        approvedAt?: number | null;
        orgDuplicate: boolean;
        vendorDuplicate: boolean;
        headerOnly: boolean;
        exportedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        downloadedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        approvedBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        project?: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
        } | null;
        release?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          poNumber?: string | null;
          exportedAt?: number | null;
          status: ReleaseStatus;
          receiptPostedAt?: number | null;
          requiresInventoryReceipt?: boolean | null;
          type: {
            __typename?: "ReleaseType";
            id: string;
            name: string;
            transactionKind: TransactionKind;
          };
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
              name: string;
            };
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            externalCode?: string | null;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
              receivesInvoiceNotifications: boolean;
            }>;
          } | null;
          poLink?: {
            __typename?: "POLink";
            id: string;
            syncedAt?: number | null;
            createdBy?: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
            } | null;
          } | null;
          project?: {
            __typename?: "Project";
            id: string;
            name: string;
            jobNumber?: string | null;
          } | null;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
          } | null;
          deliverySlips: Array<{
            __typename?: "DeliverySlip";
            id: string;
            status: DeliverySlipStatus;
          }>;
        } | null;
        link?: {
          __typename?: "InvoiceLink";
          id: string;
          sourceSystem: SourceSystem;
          createdAt: number;
          exportedAt?: number | null;
          failedAt?: number | null;
          failedMessage?: string | null;
          batchId?: string | null;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            photoUrl?: string | null;
          } | null;
          batch?: {
            __typename?: "ExternalBatch";
            id: string;
            number: string;
            month: string;
          } | null;
        } | null;
        permissions: {
          __typename?: "InvoicePermissions";
          archive: AuthorizationStatus;
          export: AuthorizationStatus;
        };
        predictedSellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
            name: string;
          };
        } | null;
        folders: Array<{
          __typename?: "InvoiceFolder";
          id: string;
          name: string;
        }>;
        kickbacks: Array<{
          __typename?: "InvoiceKickback";
          id: string;
          resolvedAt?: number | null;
          createdAt: number;
          deletedAt?: number | null;
          reportedBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          assignees: Array<{
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          }>;
          resolvedBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        }>;
      };
    }>;
  };
};

export type InvoicesByIdsQueryVariables = Exact<{
  filter?: InputMaybe<QueryInvoicesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvoicesByIdsQuery = {
  __typename?: "Query";
  invoices: {
    __typename?: "InvoiceConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InvoiceEdge";
      cursor: string;
      node: {
        __typename?: "Invoice";
        id: string;
        number?: string | null;
        status: InvoiceStatus;
        poNumber?: string | null;
        total?: string | null;
        exportedAt?: number | null;
        createdAt: number;
        taxAmount?: string | null;
        release?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          hasMissingCostCodes: boolean;
          exportedAt?: number | null;
          status: ReleaseStatus;
          poNumber?: string | null;
          poLink?: {
            __typename?: "POLink";
            id: string;
            syncedAt?: number | null;
          } | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
              name: string;
            };
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            externalCode?: string | null;
            deletedAt?: number | null;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
              receivesInvoiceNotifications: boolean;
            }>;
          } | null;
          project?: { __typename?: "Project"; id: string; name: string } | null;
          type: {
            __typename?: "ReleaseType";
            id: string;
            name: string;
            transactionKind: TransactionKind;
            requireDeliverySlip: boolean;
            trackInventory: boolean;
            includeAdditionalCharges: boolean;
            poFormat?: PoFormat | null;
            default: boolean;
            taxMode?: TaxMode | null;
            defaultAutoSync: boolean;
            costType?: {
              __typename?: "CostType";
              id: string;
              description: string;
            } | null;
            ledgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
          };
        } | null;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          version: number;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
          pageRange: { __typename?: "PageRange"; start: number; end: number };
        }>;
        permissions: {
          __typename?: "InvoicePermissions";
          archive: AuthorizationStatus;
          export: AuthorizationStatus;
        };
        link?: {
          __typename?: "InvoiceLink";
          id: string;
          exportedAt?: number | null;
          batch?: {
            __typename?: "ExternalBatch";
            id: string;
            sourceSystem: SourceSystem;
            externalId: string;
            sourceId: string;
            number: string;
            month: string;
            type: BatchType;
          } | null;
        } | null;
        predictedSellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          org: { __typename?: "Organization"; id: string; name: string };
        } | null;
      };
    }>;
  };
};

export type ExternalAddressFieldsFragment = {
  __typename?: "ExternalAddress";
  city?: string | null;
  country?: string | null;
  postalCode?: string | null;
  state?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
};

export type ExternalEstimateFieldsFragment = {
  __typename?: "ExternalEstimate";
  externalId: string;
  sourceId: string;
  sourceSystem: SourceSystem;
  description: string;
};

export type ExternalProjectEssentialFieldsFragment = {
  __typename?: "ExternalProject";
  id: string;
  sourceSystem: SourceSystem;
};

export type ExternalProjectFieldsFragment = {
  __typename?: "ExternalProject";
  id: string;
  externalId: string;
  sourceId: string;
  name: string;
  number?: string | null;
  startDate?: number | null;
  amount?: string | null;
  sourceSystem: SourceSystem;
  status?: string | null;
  address?: {
    __typename?: "ExternalAddress";
    city?: string | null;
    country?: string | null;
    postalCode?: string | null;
    state?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
  } | null;
  project?: { __typename?: "Project"; id: string; name: string } | null;
};

export type ExternalProjectWithEstimatesFieldsFragment = {
  __typename?: "ExternalProject";
  id: string;
  sourceSystem: SourceSystem;
  estimates: Array<{
    __typename?: "ExternalEstimate";
    externalId: string;
    sourceId: string;
    sourceSystem: SourceSystem;
    description: string;
  }>;
};

export type ProjectCostCodeFieldsFragment = {
  __typename?: "CostCode";
  id: string;
  code: string;
  description: string;
};

export type ConnectProjectMutationVariables = Exact<{
  input: ConnectNodeInput;
}>;

export type ConnectProjectMutation = {
  __typename?: "Mutation";
  connectProject: {
    __typename?: "Project";
    id: string;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
    budgetLink?: {
      __typename?: "BudgetLink";
      id: string;
      autoSync: boolean;
      createdAt: number;
      syncedAt?: number | null;
      autoSyncError?: string | null;
    } | null;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
  };
};

export type CopyEstimatedItemsMutationVariables = Exact<{
  input: CopyEstimatedItemsInput;
}>;

export type CopyEstimatedItemsMutation = {
  __typename?: "Mutation";
  copyEstimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
};

export type DisconnectProjectMutationVariables = Exact<{
  input: DisconnectNodeInput;
}>;

export type DisconnectProjectMutation = {
  __typename?: "Mutation";
  disconnectProject: {
    __typename?: "Project";
    id: string;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
    }>;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
  };
};

export type ImportEstimateMutationVariables = Exact<{
  input: ImportEstimateInput;
  excludePhantoms?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ImportEstimateMutation = {
  __typename?: "Mutation";
  importEstimate: {
    __typename?: "Project";
    usesProjectCostCodes: boolean;
    id: string;
    name: string;
    status: ProjectStatus;
    budget?: string | null;
    deletedAt?: number | null;
    startDate?: number | null;
    percentageOfBudgetSpent?: string | null;
    jobNumber?: string | null;
    taxExempt: boolean;
    poJobNumber?: string | null;
    restrictCostCodes: boolean;
    preferredInventoryInflows: boolean;
    releaseInstructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    aggregatedZones: Array<{
      __typename?: "AggregatedZone";
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      costCodes: Array<{
        __typename?: "AggregatedCostCode";
        costCode?: { __typename?: "CostCode"; id: string } | null;
        items: Array<{
          __typename?: "AggregatedItem";
          projectItem: { __typename?: "ProjectItem"; id: string };
          estimatedItems: Array<{
            __typename?: "EstimatedItem";
            id: string;
            quantityDecimal: string;
            orderedQuantity: string;
            duplicateCount: number;
            unitPrice?: string | null;
            position?: number | null;
            notes?: string | null;
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            tags: Array<{ __typename?: "ProjectTag"; id: string }>;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
            } | null;
            uom: { __typename?: "UOM"; id: string };
          }>;
          buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
        }>;
      }>;
    }>;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
    }>;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    }> | null;
    estimatedItemGroups: Array<{
      __typename?: "EstimatedItemGroup";
      estimatedItemCount: number;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    team: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    }>;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        costCodes: Array<{
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }>;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    items: Array<{
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    }>;
    zones: Array<{
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    }>;
    permissions: {
      __typename?: "ProjectPermissions";
      edit: AuthorizationStatus;
      createTag: AuthorizationStatus;
      report: AuthorizationStatus;
    };
  };
};

export type ImportProjectTagsMutationVariables = Exact<{
  input: ImportProjectTagsInput;
}>;

export type ImportProjectTagsMutation = {
  __typename?: "Mutation";
  importProjectTags: {
    __typename?: "Project";
    id: string;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    }>;
  };
};

export type ImportZonesMutationVariables = Exact<{
  input: ImportZonesInput;
}>;

export type ImportZonesMutation = {
  __typename?: "Mutation";
  importZones: {
    __typename?: "Project";
    id: string;
    zones: Array<{
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    }>;
  };
};

export type RefreshExternalProjectsMutationVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type RefreshExternalProjectsMutation = {
  __typename?: "Mutation";
  refreshExternalProjects: number;
};

export type UpdateTagsMutationVariables = Exact<{
  input: UpdateTagsInput;
}>;

export type UpdateTagsMutation = {
  __typename?: "Mutation";
  updateTags: Array<{
    __typename?: "ProjectTag";
    id: string;
    name: string;
    color: string;
    deletedAt?: number | null;
    hasMapping: boolean;
  }>;
};

export type EstimatedItemGroupFieldsFragment = {
  __typename?: "EstimatedItemGroup";
  estimatedItems: Array<{
    __typename?: "EstimatedItem";
    id: string;
    unitPrice?: string | null;
    quantityDecimal: string;
    orderedQuantity: string;
    tags: Array<{ __typename?: "ProjectTag"; id: string }>;
    zone?: { __typename?: "Zone"; id: string } | null;
    material: { __typename?: "OrgMaterial"; id: string };
    costCode?: { __typename?: "CostCode"; id: string } | null;
    uom: { __typename?: "UOM"; id: string };
    sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
  }>;
};

export type ProjectAllowanceFieldsFragment = {
  __typename?: "ProjectAllowance";
  amount?: string | null;
  costCodes: Array<{
    __typename?: "CostCodeAllowance";
    id: string;
    position?: number | null;
    amount: string;
    costCode: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
    };
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    costTypes?: Array<{
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
  }>;
  tags: Array<{
    __typename?: "TagAllowance";
    id: string;
    amount: string;
    tag: {
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
    };
  }>;
};

export type ProjectBudgetLinkFieldsFragment = {
  __typename?: "BudgetLink";
  id: string;
  autoSync: boolean;
  createdAt: number;
  syncedAt?: number | null;
  autoSyncError?: string | null;
};

export type LinkBudgetMutationVariables = Exact<{
  input: LinkBudgetInput;
}>;

export type LinkBudgetMutation = {
  __typename?: "Mutation";
  linkBudget: {
    __typename?: "BudgetLink";
    id: string;
    autoSync: boolean;
    createdAt: number;
    syncedAt?: number | null;
    autoSyncError?: string | null;
    project: {
      __typename?: "Project";
      id: string;
      usesProjectCostCodes: boolean;
      allowance: {
        __typename?: "ProjectAllowance";
        amount?: string | null;
        costCodes: Array<{
          __typename?: "CostCodeAllowance";
          id: string;
          position?: number | null;
          amount: string;
          costCode: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
          };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          costTypes?: Array<{
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
            org: { __typename?: "Organization"; id: string };
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          }> | null;
        }>;
        tags: Array<{
          __typename?: "TagAllowance";
          id: string;
          amount: string;
          tag: {
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          };
        }>;
      };
      costCodes?: Array<{
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }> | null;
      estimatedItemGroups: Array<{
        __typename?: "EstimatedItemGroup";
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          unitPrice?: string | null;
          quantityDecimal: string;
          orderedQuantity: string;
          tags: Array<{ __typename?: "ProjectTag"; id: string }>;
          zone?: { __typename?: "Zone"; id: string } | null;
          material: { __typename?: "OrgMaterial"; id: string };
          costCode?: { __typename?: "CostCode"; id: string } | null;
          uom: { __typename?: "UOM"; id: string };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
      }>;
    };
  };
};

export type SyncBudgetMutationVariables = Exact<{
  budgetLinkId: Scalars["ID"]["input"];
}>;

export type SyncBudgetMutation = {
  __typename?: "Mutation";
  syncBudget: {
    __typename?: "Project";
    id: string;
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
    budgetLink?: {
      __typename?: "BudgetLink";
      id: string;
      autoSync: boolean;
      createdAt: number;
      syncedAt?: number | null;
      autoSyncError?: string | null;
    } | null;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
  };
};

export type UnlinkBudgetMutationVariables = Exact<{
  budgetLinkId: Scalars["ID"]["input"];
}>;

export type UnlinkBudgetMutation = {
  __typename?: "Mutation";
  unlinkBudget: {
    __typename?: "Project";
    id: string;
    usesProjectCostCodes: boolean;
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
    estimatedItemGroups: Array<{
      __typename?: "EstimatedItemGroup";
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        orderedQuantity: string;
        tags: Array<{ __typename?: "ProjectTag"; id: string }>;
        zone?: { __typename?: "Zone"; id: string } | null;
        material: { __typename?: "OrgMaterial"; id: string };
        costCode?: { __typename?: "CostCode"; id: string } | null;
        uom: { __typename?: "UOM"; id: string };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
    }>;
  };
};

export type UpdateBudgetLinkMutationVariables = Exact<{
  input: UpdateBudgetLinkInput;
}>;

export type UpdateBudgetLinkMutation = {
  __typename?: "Mutation";
  updateBudgetLink: {
    __typename?: "BudgetLink";
    id: string;
    autoSync: boolean;
    createdAt: number;
    syncedAt?: number | null;
    autoSyncError?: string | null;
    project: {
      __typename?: "Project";
      id: string;
      usesProjectCostCodes: boolean;
      allowance: {
        __typename?: "ProjectAllowance";
        amount?: string | null;
        costCodes: Array<{
          __typename?: "CostCodeAllowance";
          id: string;
          position?: number | null;
          amount: string;
          costCode: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
          };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          costTypes?: Array<{
            __typename?: "CostType";
            id: string;
            code: string;
            description: string;
            org: { __typename?: "Organization"; id: string };
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          }> | null;
        }>;
        tags: Array<{
          __typename?: "TagAllowance";
          id: string;
          amount: string;
          tag: {
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          };
        }>;
      };
      costCodes?: Array<{
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      }> | null;
    };
  };
};

export type UpdateProjectAllowanceMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectAllowanceMutation = {
  __typename?: "Mutation";
  updateProject: {
    __typename?: "Project";
    id: string;
    restrictCostCodes: boolean;
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
  };
};

export type ProjectAllowanceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProjectAllowanceQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    id: string;
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
    budgetLink?: {
      __typename?: "BudgetLink";
      id: string;
      autoSync: boolean;
      createdAt: number;
      syncedAt?: number | null;
      autoSyncError?: string | null;
    } | null;
  } | null;
};

export type ProjectReportCostCodeFieldsFragment = {
  __typename?: "ProjectReportCostCode";
  allowance?: string | null;
  estimated?: string | null;
  reserved?: string | null;
  ordered?: string | null;
  orderedTotal?: string | null;
  invoiced?: string | null;
  overUnder?: string | null;
  unallotted?: string | null;
  invoicedTotal?: string | null;
  received?: string | null;
  receivedTotal?: string | null;
  paid?: string | null;
  paidTotal?: string | null;
  costCode?: { __typename?: "CostCode"; id: string; code: string } | null;
  materials: Array<{
    __typename?: "ProjectReportMaterial";
    estimated?: string | null;
    quoted?: boolean | null;
    reserved?: string | null;
    ordered?: string | null;
    invoiced?: string | null;
    received?: string | null;
    paid?: string | null;
    material: { __typename?: "OrgMaterial"; id: string };
  }>;
};

export type ProjectReportCostCodeVendorFieldsFragment = {
  __typename?: "ProjectReportCostCodeVendor";
  allowance?: string | null;
  estimated?: string | null;
  reserved?: string | null;
  ordered?: string | null;
  orderedTotal?: string | null;
  invoiced?: string | null;
  overUnder?: string | null;
  unallotted?: string | null;
  invoicedTotal?: string | null;
  received?: string | null;
  receivedTotal?: string | null;
  paid?: string | null;
  paidTotal?: string | null;
  costCode?: { __typename?: "CostCode"; id: string } | null;
  vendors: Array<{
    __typename?: "ProjectReportVendor";
    reserved?: string | null;
    ordered?: string | null;
    invoiced?: string | null;
    received?: string | null;
    paid?: string | null;
    vendor: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  }>;
};

export type ProjectReportMaterialFieldsFragment = {
  __typename?: "ProjectReportMaterial";
  estimated?: string | null;
  quoted?: boolean | null;
  reserved?: string | null;
  ordered?: string | null;
  invoiced?: string | null;
  received?: string | null;
  paid?: string | null;
  material: { __typename?: "OrgMaterial"; id: string };
};

export type ProjectReportPerMaterialFieldsFragment = {
  __typename?: "ProjectReportPerMaterial";
  allowance?: string | null;
  estimated?: string | null;
  unallotted?: string | null;
  reserved?: string | null;
  ordered?: string | null;
  orderedNet?: string | null;
  invoiced?: string | null;
  overUnder?: string | null;
  invoicedTotal?: string | null;
  received?: string | null;
  receivedTotal?: string | null;
  paid?: string | null;
  paidTotal?: string | null;
  costCodes: Array<{
    __typename?: "ProjectReportCostCode";
    allowance?: string | null;
    estimated?: string | null;
    reserved?: string | null;
    ordered?: string | null;
    orderedTotal?: string | null;
    invoiced?: string | null;
    overUnder?: string | null;
    unallotted?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    costCode?: { __typename?: "CostCode"; id: string; code: string } | null;
    materials: Array<{
      __typename?: "ProjectReportMaterial";
      estimated?: string | null;
      quoted?: boolean | null;
      reserved?: string | null;
      ordered?: string | null;
      invoiced?: string | null;
      received?: string | null;
      paid?: string | null;
      material: { __typename?: "OrgMaterial"; id: string };
    }>;
  }>;
};

export type ProjectReportPerMaterialZoneFieldsFragment = {
  __typename?: "ProjectReportPerMaterialZone";
  allowance?: string | null;
  estimated?: string | null;
  unallotted?: string | null;
  ordered?: string | null;
  orderedNet?: string | null;
  invoiced?: string | null;
  overUnder?: string | null;
  invoicedTotal?: string | null;
  received?: string | null;
  receivedTotal?: string | null;
  paid?: string | null;
  paidTotal?: string | null;
  zones: Array<{
    __typename?: "ProjectReportZone";
    allowance?: string | null;
    estimated?: string | null;
    ordered?: string | null;
    orderedTotal?: string | null;
    invoiced?: string | null;
    overUnder?: string | null;
    unallotted?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    zone?: { __typename?: "Zone"; id: string } | null;
    costCodes: Array<{
      __typename?: "ProjectReportCostCode";
      allowance?: string | null;
      estimated?: string | null;
      reserved?: string | null;
      ordered?: string | null;
      orderedTotal?: string | null;
      invoiced?: string | null;
      overUnder?: string | null;
      unallotted?: string | null;
      invoicedTotal?: string | null;
      received?: string | null;
      receivedTotal?: string | null;
      paid?: string | null;
      paidTotal?: string | null;
      costCode?: { __typename?: "CostCode"; id: string; code: string } | null;
      materials: Array<{
        __typename?: "ProjectReportMaterial";
        estimated?: string | null;
        quoted?: boolean | null;
        reserved?: string | null;
        ordered?: string | null;
        invoiced?: string | null;
        received?: string | null;
        paid?: string | null;
        material: { __typename?: "OrgMaterial"; id: string };
      }>;
    }>;
  }>;
};

export type ProjectReportPerVendorFieldsFragment = {
  __typename?: "ProjectReportPerVendor";
  allowance?: string | null;
  estimated?: string | null;
  unallotted?: string | null;
  reserved?: string | null;
  ordered?: string | null;
  invoiced?: string | null;
  orderedNet?: string | null;
  overUnder?: string | null;
  invoicedTotal?: string | null;
  received?: string | null;
  receivedTotal?: string | null;
  paid?: string | null;
  paidTotal?: string | null;
  costCodes: Array<{
    __typename?: "ProjectReportCostCodeVendor";
    allowance?: string | null;
    estimated?: string | null;
    reserved?: string | null;
    ordered?: string | null;
    orderedTotal?: string | null;
    invoiced?: string | null;
    overUnder?: string | null;
    unallotted?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    vendors: Array<{
      __typename?: "ProjectReportVendor";
      reserved?: string | null;
      ordered?: string | null;
      invoiced?: string | null;
      received?: string | null;
      paid?: string | null;
      vendor: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    }>;
  }>;
};

export type ProjectReportReleasesFieldsFragment = {
  __typename?: "ProjectReportReleases";
  project: { __typename?: "Project"; id: string; name: string };
  orgMaterial?: {
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
  } | null;
  elements: Array<{
    __typename?: "ProjectReportReleasesElement";
    release: {
      __typename?: "Release";
      id: string;
      total?: string | null;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      invoices: Array<{ __typename?: "Invoice"; id: string }>;
    };
    releaseItems: Array<{
      __typename?: "ReleaseItem";
      id: string;
      isIncluded: boolean;
      invoicedQuantity: string;
      quantityDecimal: string;
      unitPrice?: string | null;
      receivedQuantityDecimal: string;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          costCode?: { __typename?: "CostCode"; id: string } | null;
        };
      } | null;
      invoicedAmount: {
        __typename?: "Amount";
        net?: string | null;
        tax?: string | null;
        charges?: string | null;
      };
      orderedAmount: {
        __typename?: "Amount";
        net?: string | null;
        tax?: string | null;
        charges?: string | null;
      };
      uom?: { __typename?: "UOM"; id: string } | null;
      zone?: { __typename?: "Zone"; id: string } | null;
      costCode?: { __typename?: "CostCode"; id: string } | null;
    }>;
  }>;
};

export type ProjectReportVendorFieldsFragment = {
  __typename?: "ProjectReportVendor";
  reserved?: string | null;
  ordered?: string | null;
  invoiced?: string | null;
  received?: string | null;
  paid?: string | null;
  vendor: {
    __typename?: "Organization";
    id: string;
    name: string;
    photoUrl?: string | null;
  };
};

export type ProjectReportZoneFieldsFragment = {
  __typename?: "ProjectReportZone";
  allowance?: string | null;
  estimated?: string | null;
  ordered?: string | null;
  orderedTotal?: string | null;
  invoiced?: string | null;
  overUnder?: string | null;
  unallotted?: string | null;
  invoicedTotal?: string | null;
  received?: string | null;
  receivedTotal?: string | null;
  paid?: string | null;
  paidTotal?: string | null;
  zone?: { __typename?: "Zone"; id: string } | null;
  costCodes: Array<{
    __typename?: "ProjectReportCostCode";
    allowance?: string | null;
    estimated?: string | null;
    reserved?: string | null;
    ordered?: string | null;
    orderedTotal?: string | null;
    invoiced?: string | null;
    overUnder?: string | null;
    unallotted?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    costCode?: { __typename?: "CostCode"; id: string; code: string } | null;
    materials: Array<{
      __typename?: "ProjectReportMaterial";
      estimated?: string | null;
      quoted?: boolean | null;
      reserved?: string | null;
      ordered?: string | null;
      invoiced?: string | null;
      received?: string | null;
      paid?: string | null;
      material: { __typename?: "OrgMaterial"; id: string };
    }>;
  }>;
};

export type ProjectReportPerMaterialQueryVariables = Exact<{
  input?: InputMaybe<ProjectReportPerMaterialInput>;
}>;

export type ProjectReportPerMaterialQuery = {
  __typename?: "Query";
  projectReportPerMaterial: {
    __typename?: "ProjectReportPerMaterial";
    allowance?: string | null;
    estimated?: string | null;
    unallotted?: string | null;
    reserved?: string | null;
    ordered?: string | null;
    orderedNet?: string | null;
    invoiced?: string | null;
    overUnder?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    costCodes: Array<{
      __typename?: "ProjectReportCostCode";
      allowance?: string | null;
      estimated?: string | null;
      reserved?: string | null;
      ordered?: string | null;
      orderedTotal?: string | null;
      invoiced?: string | null;
      overUnder?: string | null;
      unallotted?: string | null;
      invoicedTotal?: string | null;
      received?: string | null;
      receivedTotal?: string | null;
      paid?: string | null;
      paidTotal?: string | null;
      costCode?: { __typename?: "CostCode"; id: string; code: string } | null;
      materials: Array<{
        __typename?: "ProjectReportMaterial";
        estimated?: string | null;
        quoted?: boolean | null;
        reserved?: string | null;
        ordered?: string | null;
        invoiced?: string | null;
        received?: string | null;
        paid?: string | null;
        material: { __typename?: "OrgMaterial"; id: string };
      }>;
    }>;
  };
};

export type ProjectReportPerMaterialZoneQueryVariables = Exact<{
  input?: InputMaybe<ProjectReportPerMaterialZoneInput>;
}>;

export type ProjectReportPerMaterialZoneQuery = {
  __typename?: "Query";
  projectReportPerMaterialZone: {
    __typename?: "ProjectReportPerMaterialZone";
    allowance?: string | null;
    estimated?: string | null;
    unallotted?: string | null;
    ordered?: string | null;
    orderedNet?: string | null;
    invoiced?: string | null;
    overUnder?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    zones: Array<{
      __typename?: "ProjectReportZone";
      allowance?: string | null;
      estimated?: string | null;
      ordered?: string | null;
      orderedTotal?: string | null;
      invoiced?: string | null;
      overUnder?: string | null;
      unallotted?: string | null;
      invoicedTotal?: string | null;
      received?: string | null;
      receivedTotal?: string | null;
      paid?: string | null;
      paidTotal?: string | null;
      zone?: { __typename?: "Zone"; id: string } | null;
      costCodes: Array<{
        __typename?: "ProjectReportCostCode";
        allowance?: string | null;
        estimated?: string | null;
        reserved?: string | null;
        ordered?: string | null;
        orderedTotal?: string | null;
        invoiced?: string | null;
        overUnder?: string | null;
        unallotted?: string | null;
        invoicedTotal?: string | null;
        received?: string | null;
        receivedTotal?: string | null;
        paid?: string | null;
        paidTotal?: string | null;
        costCode?: { __typename?: "CostCode"; id: string; code: string } | null;
        materials: Array<{
          __typename?: "ProjectReportMaterial";
          estimated?: string | null;
          quoted?: boolean | null;
          reserved?: string | null;
          ordered?: string | null;
          invoiced?: string | null;
          received?: string | null;
          paid?: string | null;
          material: { __typename?: "OrgMaterial"; id: string };
        }>;
      }>;
    }>;
  };
};

export type ProjectReportPerVendorQueryVariables = Exact<{
  input?: InputMaybe<ProjectReportPerVendorInput>;
}>;

export type ProjectReportPerVendorQuery = {
  __typename?: "Query";
  projectReportPerVendor: {
    __typename?: "ProjectReportPerVendor";
    allowance?: string | null;
    estimated?: string | null;
    unallotted?: string | null;
    reserved?: string | null;
    ordered?: string | null;
    invoiced?: string | null;
    orderedNet?: string | null;
    overUnder?: string | null;
    invoicedTotal?: string | null;
    received?: string | null;
    receivedTotal?: string | null;
    paid?: string | null;
    paidTotal?: string | null;
    costCodes: Array<{
      __typename?: "ProjectReportCostCodeVendor";
      allowance?: string | null;
      estimated?: string | null;
      reserved?: string | null;
      ordered?: string | null;
      orderedTotal?: string | null;
      invoiced?: string | null;
      overUnder?: string | null;
      unallotted?: string | null;
      invoicedTotal?: string | null;
      received?: string | null;
      receivedTotal?: string | null;
      paid?: string | null;
      paidTotal?: string | null;
      costCode?: { __typename?: "CostCode"; id: string } | null;
      vendors: Array<{
        __typename?: "ProjectReportVendor";
        reserved?: string | null;
        ordered?: string | null;
        invoiced?: string | null;
        received?: string | null;
        paid?: string | null;
        vendor: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      }>;
    }>;
  };
};

export type ProjectReportReleasesQueryVariables = Exact<{
  input?: InputMaybe<ProjectReportReleasesInput>;
}>;

export type ProjectReportReleasesQuery = {
  __typename?: "Query";
  projectReportReleases: {
    __typename?: "ProjectReportReleases";
    project: { __typename?: "Project"; id: string; name: string };
    orgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
    } | null;
    elements: Array<{
      __typename?: "ProjectReportReleasesElement";
      release: {
        __typename?: "Release";
        id: string;
        total?: string | null;
        status: ReleaseStatus;
        sequenceNumber?: number | null;
        time?: number | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
        } | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
            name: string;
          };
        } | null;
        invoices: Array<{ __typename?: "Invoice"; id: string }>;
      };
      releaseItems: Array<{
        __typename?: "ReleaseItem";
        id: string;
        isIncluded: boolean;
        invoicedQuantity: string;
        quantityDecimal: string;
        unitPrice?: string | null;
        receivedQuantityDecimal: string;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        } | null;
        projectItem?: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            costCode?: { __typename?: "CostCode"; id: string } | null;
          };
        } | null;
        invoicedAmount: {
          __typename?: "Amount";
          net?: string | null;
          tax?: string | null;
          charges?: string | null;
        };
        orderedAmount: {
          __typename?: "Amount";
          net?: string | null;
          tax?: string | null;
          charges?: string | null;
        };
        uom?: { __typename?: "UOM"; id: string } | null;
        zone?: { __typename?: "Zone"; id: string } | null;
        costCode?: { __typename?: "CostCode"; id: string } | null;
      }>;
    }>;
  };
};

export type ExternalProjectQueryVariables = Exact<{
  input: ExternalIdQueryInput;
}>;

export type ExternalProjectQuery = {
  __typename?: "Query";
  externalProject: {
    __typename?: "ExternalProject";
    id: string;
    externalId: string;
    sourceId: string;
    name: string;
    number?: string | null;
    startDate?: number | null;
    amount?: string | null;
    sourceSystem: SourceSystem;
    status?: string | null;
    address?: {
      __typename?: "ExternalAddress";
      city?: string | null;
      country?: string | null;
      postalCode?: string | null;
      state?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
    } | null;
    project?: { __typename?: "Project"; id: string; name: string } | null;
  };
};

export type ExternalProjectsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  filter?: InputMaybe<QueryExternalProjectsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ExternalProjectsQuery = {
  __typename?: "Query";
  externalProjects: {
    __typename?: "ExternalProjectConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ExternalProjectEdge";
      cursor: string;
      node: {
        __typename?: "ExternalProject";
        id: string;
        externalId: string;
        sourceId: string;
        name: string;
        number?: string | null;
        startDate?: number | null;
        amount?: string | null;
        sourceSystem: SourceSystem;
        status?: string | null;
        address?: {
          __typename?: "ExternalAddress";
          city?: string | null;
          country?: string | null;
          postalCode?: string | null;
          state?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
        } | null;
        project?: { __typename?: "Project"; id: string; name: string } | null;
      };
    }>;
  };
};

export type ProjectQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  excludePhantoms?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ProjectQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    usesProjectCostCodes: boolean;
    id: string;
    name: string;
    status: ProjectStatus;
    budget?: string | null;
    deletedAt?: number | null;
    startDate?: number | null;
    percentageOfBudgetSpent?: string | null;
    jobNumber?: string | null;
    taxExempt: boolean;
    poJobNumber?: string | null;
    restrictCostCodes: boolean;
    preferredInventoryInflows: boolean;
    releaseInstructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    aggregatedZones: Array<{
      __typename?: "AggregatedZone";
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      costCodes: Array<{
        __typename?: "AggregatedCostCode";
        costCode?: { __typename?: "CostCode"; id: string } | null;
        items: Array<{
          __typename?: "AggregatedItem";
          projectItem: { __typename?: "ProjectItem"; id: string };
          estimatedItems: Array<{
            __typename?: "EstimatedItem";
            id: string;
            quantityDecimal: string;
            orderedQuantity: string;
            duplicateCount: number;
            unitPrice?: string | null;
            position?: number | null;
            notes?: string | null;
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            tags: Array<{ __typename?: "ProjectTag"; id: string }>;
            sellerOrgLocation?: {
              __typename?: "OrgLocation";
              id: string;
            } | null;
            uom: { __typename?: "UOM"; id: string };
          }>;
          buyoutItems?: Array<{ __typename?: "BuyoutItem"; id: string }> | null;
        }>;
      }>;
    }>;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
    }>;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    }> | null;
    estimatedItemGroups: Array<{
      __typename?: "EstimatedItemGroup";
      estimatedItemCount: number;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    team: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    }>;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        costCodes: Array<{
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }>;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    items: Array<{
      __typename?: "ProjectItem";
      id: string;
      material: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      estimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
      buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
    }>;
    zones: Array<{
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    }>;
    permissions: {
      __typename?: "ProjectPermissions";
      edit: AuthorizationStatus;
      createTag: AuthorizationStatus;
      report: AuthorizationStatus;
    };
  } | null;
};

export type ProjectStoreQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProjectStoreQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    id: string;
    restrictCostCodes: boolean;
    usesProjectCostCodes: boolean;
    zones: Array<{
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
      externalSubJob?: {
        __typename?: "ExternalSubJob";
        id: string;
        sourceSystem: SourceSystem;
        externalId: string;
      } | null;
    }>;
    tags: Array<{
      __typename?: "ProjectTag";
      id: string;
      name: string;
      color: string;
      deletedAt?: number | null;
      hasMapping: boolean;
      mapping?: {
        __typename?: "ProjectTagMapping";
        sourceSystem: SourceSystem;
        externalCostCode?: {
          __typename?: "ExternalCostCode";
          code: string;
          name: string;
        } | null;
        externalCostType?: {
          __typename?: "ExternalCostType";
          code: string;
        } | null;
      } | null;
    }>;
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
    budgetLink?: {
      __typename?: "BudgetLink";
      id: string;
      autoSync: boolean;
      createdAt: number;
      syncedAt?: number | null;
      autoSyncError?: string | null;
    } | null;
    estimatedItemGroups: Array<{
      __typename?: "EstimatedItemGroup";
      estimatedItems: Array<{
        __typename?: "EstimatedItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        orderedQuantity: string;
        tags: Array<{ __typename?: "ProjectTag"; id: string }>;
        zone?: { __typename?: "Zone"; id: string } | null;
        material: { __typename?: "OrgMaterial"; id: string };
        costCode?: { __typename?: "CostCode"; id: string } | null;
        uom: { __typename?: "UOM"; id: string };
        sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
      }>;
    }>;
    releaseInstructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
  } | null;
};

export type ProjectProjectsFieldsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  budget?: string | null;
  startDate?: number | null;
  jobNumber?: string | null;
  deletedAt?: number | null;
  percentageOfBudgetSpent?: string | null;
  orderedAmount: string;
  totalAmount: string;
  materialCount: number;
  releaseCount: number;
  team: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  }>;
  mappings: Array<{
    __typename?: "SourceSystemMapping";
    externalId: string;
    sourceSystem: SourceSystem;
  }>;
  permissions: {
    __typename?: "ProjectPermissions";
    report: AuthorizationStatus;
  };
};

export type ArchiveProjectsMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type ArchiveProjectsMutation = {
  __typename?: "Mutation";
  archiveProjects: Array<{
    __typename?: "Project";
    id: string;
    deletedAt?: number | null;
    status: ProjectStatus;
  }>;
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createNewProject: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    status: ProjectStatus;
    startDate?: number | null;
    taxExempt: boolean;
    preferredInventoryInflows: boolean;
    location: { __typename?: "OrgLocation"; id: string; name: string };
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    team: Array<{ __typename?: "User"; id: string }>;
    externalProjects: Array<{
      __typename?: "ExternalProject";
      id: string;
      sourceSystem: SourceSystem;
    }>;
  };
};

export type CreateProjectsMutationVariables = Exact<{
  input: CreateProjectsInput;
}>;

export type CreateProjectsMutation = {
  __typename?: "Mutation";
  createProjects: Array<{ __typename?: "Project"; id: string; name: string }>;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteProjectMutation = {
  __typename?: "Mutation";
  removeProject?: string | null;
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: "Mutation";
  updateProject: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    budget?: string | null;
    startDate?: number | null;
    percentageOfBudgetSpent?: string | null;
    jobNumber?: string | null;
    taxExempt: boolean;
    poJobNumber?: string | null;
    usesProjectCostCodes: boolean;
    team: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    }>;
    releaseInstructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
    location: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      contractorOrg?: { __typename?: "Organization"; id: string } | null;
    };
    costCodes?: Array<{
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    }> | null;
    allowance: {
      __typename?: "ProjectAllowance";
      amount?: string | null;
      costCodes: Array<{
        __typename?: "CostCodeAllowance";
        id: string;
        position?: number | null;
        amount: string;
        costCode: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
        };
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costTypes?: Array<{
          __typename?: "CostType";
          id: string;
          code: string;
          description: string;
          org: { __typename?: "Organization"; id: string };
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "TagAllowance";
        id: string;
        amount: string;
        tag: {
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        };
      }>;
    };
  };
};

export type ProjectEstimatedItemsCountQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProjectEstimatedItemsCountQuery = {
  __typename?: "Query";
  project?: {
    __typename?: "Project";
    id: string;
    estimatedItemsCount: number;
  } | null;
};

export type ProjectsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryProjectsFilter>;
}>;

export type ProjectsQuery = {
  __typename?: "Query";
  projects: {
    __typename?: "ProjectConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ProjectEdge";
      cursor: string;
      node: {
        __typename?: "Project";
        id: string;
        name: string;
        status: ProjectStatus;
        budget?: string | null;
        startDate?: number | null;
        jobNumber?: string | null;
        deletedAt?: number | null;
        percentageOfBudgetSpent?: string | null;
        orderedAmount: string;
        totalAmount: string;
        materialCount: number;
        releaseCount: number;
        team: Array<{
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        }>;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
        permissions: {
          __typename?: "ProjectPermissions";
          report: AuthorizationStatus;
        };
      };
    }>;
  };
};

export type ReceiptDocumentFieldsFragment = {
  __typename?: "InvoiceDocument";
  id: string;
  version: number;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
  pageRange: { __typename?: "PageRange"; start: number; end: number };
};

export type ReceiptFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  poNumber?: string | null;
  dueDate?: number | null;
  subtotal?: string | null;
  chargesAmount?: string | null;
  taxAmount?: string | null;
  total?: string | null;
  status: InvoiceStatus;
  description?: string | null;
  issueDescription?: string | null;
  issueDate?: number | null;
  resolutionDescription?: string | null;
  rejectionReason?: string | null;
  issueResponseDate?: number | null;
  prePaid: boolean;
  computedSubtotal: string;
  approvedAt?: number | null;
  isDeliverySlip: boolean;
  exportedAt?: number | null;
  archivedAt?: number | null;
  createdAt: number;
  notes?: string | null;
  documents: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    version: number;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    pageRange: { __typename?: "PageRange"; start: number; end: number };
  }>;
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    poNumber?: string | null;
    version: number;
    status: ReleaseStatus;
    total?: string | null;
    netAmount: string;
    taxAmount?: string | null;
    time?: number | null;
    customTaxAmount?: string | null;
    paymentTerm: number;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      };
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
    };
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      unitPrice?: string | null;
      name: string;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        pluralDescription: string;
      } | null;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      total?: string | null;
      number?: string | null;
      type: InvoiceType;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
  } | null;
  costCode?: {
    __typename?: "CostCode";
    id: string;
    code: string;
    description: string;
    inUse: boolean;
    mappings: Array<{
      __typename?: "SourceSystemMapping";
      externalId: string;
      sourceSystem: SourceSystem;
    }>;
  } | null;
  items: Array<{
    __typename?: "InvoiceItem";
    id: string;
    description: string;
    partNumber?: string | null;
    unitPrice: string;
    UOM: string;
    manufacturer?: string | null;
    quantityDecimal: string;
  }>;
  releaseItemHints: Array<{
    __typename?: "ReleaseItemHint";
    releaseItem: {
      __typename?: "ReleaseItem";
      id: string;
      unitPrice?: string | null;
      quantityDecimal: string;
      position?: number | null;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    };
    invoiceItem?: {
      __typename?: "InvoiceItem";
      id: string;
      UOM: string;
      unitPrice: string;
      quantityDecimal: string;
    } | null;
    deliverySlipItem?: {
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
    quoteDocumentItem?: {
      __typename?: "QuoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
  }>;
  charges: Array<{
    __typename?: "InvoiceCharge";
    id: string;
    description: string;
    amount: string;
  }>;
  approvedBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  } | null;
  kickbacks: Array<{
    __typename?: "InvoiceKickback";
    id: string;
    description?: string | null;
    createdAt: number;
    resolutionDescription?: string | null;
    resolvedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    resolutionAssets?: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }> | null;
    comments: Array<{
      __typename?: "InvoiceKickbackComment";
      id: string;
      text?: string | null;
      createdAt: number;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  }>;
  issues: Array<{
    __typename?: "InvoiceIssue";
    id: string;
    description: string;
    createdAt: number;
    resolutionDescription?: string | null;
    respondedAt?: number | null;
    rejectionReason?: string | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    contractorAssets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    vendorAssets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  }>;
  link?: {
    __typename?: "InvoiceLink";
    id: string;
    sourceSystem: SourceSystem;
    createdAt: number;
    exportedAt?: number | null;
    failedAt?: number | null;
    failedMessage?: string | null;
  } | null;
  permissions: {
    __typename?: "InvoicePermissions";
    edit: AuthorizationStatus;
    approve: AuthorizationStatus;
    respond: AuthorizationStatus;
    export: AuthorizationStatus;
    archive: AuthorizationStatus;
  };
  predictedProject?: {
    __typename?: "Project";
    id: string;
    location: { __typename?: "OrgLocation"; id: string };
  } | null;
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
  } | null;
  createdBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    deletedAt?: number | null;
    location: { __typename?: "OrgLocation"; id: string };
  } | null;
  equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
  serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
  paymentMethod?: {
    __typename?: "PaymentMethod";
    id: string;
    number?: string | null;
    type: PaymentMethodType;
    description: string;
    default: boolean;
    externalBankAccount?: {
      __typename?: "ExternalBankAccount";
      id: string;
      name: string;
    } | null;
  } | null;
  folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
};

export type ReceiptItemFieldsFragment = {
  __typename?: "InvoiceItem";
  id: string;
  description: string;
  partNumber?: string | null;
  unitPrice: string;
  UOM: string;
  manufacturer?: string | null;
  quantityDecimal: string;
};

export type ReceiptLinkFieldsFragment = {
  __typename?: "InvoiceLink";
  id: string;
  sourceSystem: SourceSystem;
  createdAt: number;
  exportedAt?: number | null;
  failedAt?: number | null;
  failedMessage?: string | null;
};

export type ReceiptSummaryFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  poNumber?: string | null;
  issueDate?: number | null;
  status: InvoiceStatus;
  exportedAt?: number | null;
  archivedAt?: number | null;
  prePaid: boolean;
  total?: string | null;
  createdAt: number;
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    exportedAt?: number | null;
    poNumber?: string | null;
    status: ReleaseStatus;
    receiptPostedAt?: number | null;
    requiresInventoryReceipt?: boolean | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
    };
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      syncedAt?: number | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      team: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
  } | null;
  link?: {
    __typename?: "InvoiceLink";
    id: string;
    sourceSystem: SourceSystem;
    createdAt: number;
    exportedAt?: number | null;
    failedAt?: number | null;
    failedMessage?: string | null;
  } | null;
  permissions: {
    __typename?: "InvoicePermissions";
    archive: AuthorizationStatus;
    export: AuthorizationStatus;
  };
  createdBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    team: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
  } | null;
  kickbacks: Array<{
    __typename?: "InvoiceKickback";
    id: string;
    resolvedAt?: number | null;
    createdAt: number;
    deletedAt?: number | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    assignees: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    resolvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
  folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
};

export type ReceiptToExportFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  status: InvoiceStatus;
  poNumber?: string | null;
  createdAt: number;
  total?: string | null;
  release?: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    hasMissingCostCodes: boolean;
    exportedAt?: number | null;
    status: ReleaseStatus;
    poNumber?: string | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      syncedAt?: number | null;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      deletedAt?: number | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      zones: Array<{ __typename?: "Zone"; id: string }>;
    } | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      ledgerAccount?: {
        __typename?: "ExternalLedgerAccount";
        externalId: string;
        sourceId: string;
        name: string;
        number: string;
        subaccounts?: Array<{
          __typename?: "ExternalLedgerSubaccount";
          externalId: string;
          sourceId: string;
          name: string;
          number: string;
        }> | null;
      } | null;
    };
  } | null;
  documents: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    version: number;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    pageRange: { __typename?: "PageRange"; start: number; end: number };
  }>;
  permissions: {
    __typename?: "InvoicePermissions";
    archive: AuthorizationStatus;
    export: AuthorizationStatus;
  };
  link?: {
    __typename?: "InvoiceLink";
    id: string;
    batch?: {
      __typename?: "ExternalBatch";
      id: string;
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      number: string;
      month: string;
      type: BatchType;
    } | null;
  } | null;
  paymentMethod?: {
    __typename?: "PaymentMethod";
    id: string;
    description: string;
  } | null;
};

export type ApproveReceiptMutationVariables = Exact<{
  input: ApproveInvoiceInput;
}>;

export type ApproveReceiptMutation = {
  __typename?: "Mutation";
  approveReceipt: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    description?: string | null;
    issueDescription?: string | null;
    issueDate?: number | null;
    resolutionDescription?: string | null;
    rejectionReason?: string | null;
    issueResponseDate?: number | null;
    prePaid: boolean;
    computedSubtotal: string;
    approvedAt?: number | null;
    isDeliverySlip: boolean;
    exportedAt?: number | null;
    archivedAt?: number | null;
    createdAt: number;
    notes?: string | null;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      version: number;
      status: ReleaseStatus;
      total?: string | null;
      netAmount: string;
      taxAmount?: string | null;
      time?: number | null;
      customTaxAmount?: string | null;
      paymentTerm: number;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      } | null;
      permissions: {
        __typename?: "ReleasePermissions";
        edit: AuthorizationStatus;
      };
      items: Array<{
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        name: string;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          pluralDescription: string;
        } | null;
      }>;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        total?: string | null;
        number?: string | null;
        type: InvoiceType;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        }>;
      }>;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      partNumber?: string | null;
      unitPrice: string;
      UOM: string;
      manufacturer?: string | null;
      quantityDecimal: string;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    charges: Array<{
      __typename?: "InvoiceCharge";
      id: string;
      description: string;
      amount: string;
    }>;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      approve: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
    predictedProject?: {
      __typename?: "Project";
      id: string;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
    } | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      deletedAt?: number | null;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
    serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    } | null;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
  };
};

export type ArchiveReceiptMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveReceiptMutation = {
  __typename?: "Mutation";
  archiveReceipt: {
    __typename?: "Invoice";
    id: string;
    archivedAt?: number | null;
    status: InvoiceStatus;
  };
};

export type CreateReceiptMutationVariables = Exact<{
  input: CreateReceiptInput;
}>;

export type CreateReceiptMutation = {
  __typename?: "Mutation";
  createReceipt: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    issueDate?: number | null;
    status: InvoiceStatus;
    exportedAt?: number | null;
    archivedAt?: number | null;
    prePaid: boolean;
    total?: string | null;
    createdAt: number;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      exportedAt?: number | null;
      poNumber?: string | null;
      status: ReleaseStatus;
      receiptPostedAt?: number | null;
      requiresInventoryReceipt?: boolean | null;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
      };
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        syncedAt?: number | null;
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        jobNumber?: string | null;
        team: Array<{
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        }>;
      } | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
    } | null;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      archive: AuthorizationStatus;
      export: AuthorizationStatus;
    };
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      team: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      }>;
    } | null;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      resolvedAt?: number | null;
      createdAt: number;
      deletedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    }>;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
  };
};

export type CreateReceiptKickbackMutationVariables = Exact<{
  input: CreateReceiptKickbackInput;
}>;

export type CreateReceiptKickbackMutation = {
  __typename?: "Mutation";
  createReceiptKickback: {
    __typename?: "Invoice";
    id: string;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type DeleteReceiptKickbackMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteReceiptKickbackMutation = {
  __typename?: "Mutation";
  deleteReceiptKickback: {
    __typename?: "Invoice";
    id: string;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type ExportReceiptsMutationVariables = Exact<{
  input: ExportInvoicesInput;
}>;

export type ExportReceiptsMutation = {
  __typename?: "Mutation";
  exportReceipts: {
    __typename?: "ExportInvoicesOutput";
    fileURLs?: Array<string> | null;
  };
};

export type LinkReceiptMutationVariables = Exact<{
  input: LinkInvoiceInput;
}>;

export type LinkReceiptMutation = {
  __typename?: "Mutation";
  linkReceipt: {
    __typename?: "Invoice";
    id: string;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
    } | null;
  };
};

export type ResolveReceiptKickbackMutationVariables = Exact<{
  input: ResolveReceiptKickbackInput;
}>;

export type ResolveReceiptKickbackMutation = {
  __typename?: "Mutation";
  resolveReceiptKickback: {
    __typename?: "Invoice";
    id: string;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type UpdateReceiptMutationVariables = Exact<{
  input: UpdateInvoiceInput;
  includeDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateReceiptMutation = {
  __typename?: "Mutation";
  updateReceipt: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    issueDescription?: string | null;
    issueDate?: number | null;
    resolutionDescription?: string | null;
    rejectionReason?: string | null;
    issueResponseDate?: number | null;
    prePaid: boolean;
    notes?: string | null;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      approve: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
    };
    documents?: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
    serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
    release?: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    } | null;
  };
};

export type UpdateReceiptKickbackMutationVariables = Exact<{
  input: UpdateReceiptKickbackInput;
}>;

export type UpdateReceiptKickbackMutation = {
  __typename?: "Mutation";
  updateReceiptKickback: {
    __typename?: "Invoice";
    id: string;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
  };
};

export type ReceiptQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ReceiptQuery = {
  __typename?: "Query";
  receipt: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    subtotal?: string | null;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    status: InvoiceStatus;
    description?: string | null;
    issueDescription?: string | null;
    issueDate?: number | null;
    resolutionDescription?: string | null;
    rejectionReason?: string | null;
    issueResponseDate?: number | null;
    prePaid: boolean;
    computedSubtotal: string;
    approvedAt?: number | null;
    isDeliverySlip: boolean;
    exportedAt?: number | null;
    archivedAt?: number | null;
    createdAt: number;
    notes?: string | null;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
      poNumber?: string | null;
      version: number;
      status: ReleaseStatus;
      total?: string | null;
      netAmount: string;
      taxAmount?: string | null;
      time?: number | null;
      customTaxAmount?: string | null;
      paymentTerm: number;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        externalCode?: string | null;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          photoUrl?: string | null;
          name: string;
        };
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      } | null;
      permissions: {
        __typename?: "ReleasePermissions";
        edit: AuthorizationStatus;
      };
      items: Array<{
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        name: string;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          pluralDescription: string;
        } | null;
      }>;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        total?: string | null;
        number?: string | null;
        type: InvoiceType;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        }>;
      }>;
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      partNumber?: string | null;
      unitPrice: string;
      UOM: string;
      manufacturer?: string | null;
      quantityDecimal: string;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    charges: Array<{
      __typename?: "InvoiceCharge";
      id: string;
      description: string;
      amount: string;
    }>;
    approvedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    kickbacks: Array<{
      __typename?: "InvoiceKickback";
      id: string;
      description?: string | null;
      createdAt: number;
      resolutionDescription?: string | null;
      resolvedAt?: number | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      assignees: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      }>;
      resolvedBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolutionAssets?: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }> | null;
      comments: Array<{
        __typename?: "InvoiceKickbackComment";
        id: string;
        text?: string | null;
        createdAt: number;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
      }>;
    }>;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
    link?: {
      __typename?: "InvoiceLink";
      id: string;
      sourceSystem: SourceSystem;
      createdAt: number;
      exportedAt?: number | null;
      failedAt?: number | null;
      failedMessage?: string | null;
    } | null;
    permissions: {
      __typename?: "InvoicePermissions";
      edit: AuthorizationStatus;
      approve: AuthorizationStatus;
      respond: AuthorizationStatus;
      export: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
    predictedProject?: {
      __typename?: "Project";
      id: string;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
    } | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      deletedAt?: number | null;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    equipmentItem?: { __typename?: "EquipmentItem"; id: string } | null;
    serviceCode?: { __typename?: "ServiceCode"; id: string } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      id: string;
      number?: string | null;
      type: PaymentMethodType;
      description: string;
      default: boolean;
      externalBankAccount?: {
        __typename?: "ExternalBankAccount";
        id: string;
        name: string;
      } | null;
    } | null;
    folders: Array<{ __typename?: "InvoiceFolder"; id: string; name: string }>;
  };
};

export type ReceiptsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryInvoicesFilter>;
  sort?: InputMaybe<QueryInvoicesSort>;
}>;

export type ReceiptsQuery = {
  __typename?: "Query";
  receipts: {
    __typename?: "InvoiceConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InvoiceEdge";
      cursor: string;
      node: {
        __typename?: "Invoice";
        id: string;
        number?: string | null;
        poNumber?: string | null;
        issueDate?: number | null;
        status: InvoiceStatus;
        exportedAt?: number | null;
        archivedAt?: number | null;
        prePaid: boolean;
        total?: string | null;
        createdAt: number;
        release?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          exportedAt?: number | null;
          poNumber?: string | null;
          status: ReleaseStatus;
          receiptPostedAt?: number | null;
          requiresInventoryReceipt?: boolean | null;
          type: {
            __typename?: "ReleaseType";
            id: string;
            name: string;
            transactionKind: TransactionKind;
          };
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
              name: string;
            };
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            externalCode?: string | null;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
              receivesInvoiceNotifications: boolean;
            }>;
          } | null;
          poLink?: {
            __typename?: "POLink";
            id: string;
            syncedAt?: number | null;
          } | null;
          project?: {
            __typename?: "Project";
            id: string;
            name: string;
            jobNumber?: string | null;
            team: Array<{
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
            }>;
          } | null;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
          } | null;
        } | null;
        link?: {
          __typename?: "InvoiceLink";
          id: string;
          sourceSystem: SourceSystem;
          createdAt: number;
          exportedAt?: number | null;
          failedAt?: number | null;
          failedMessage?: string | null;
        } | null;
        permissions: {
          __typename?: "InvoicePermissions";
          archive: AuthorizationStatus;
          export: AuthorizationStatus;
        };
        createdBy?: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        project?: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
          team: Array<{
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          }>;
        } | null;
        kickbacks: Array<{
          __typename?: "InvoiceKickback";
          id: string;
          resolvedAt?: number | null;
          createdAt: number;
          deletedAt?: number | null;
          reportedBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          assignees: Array<{
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          }>;
          resolvedBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        }>;
        folders: Array<{
          __typename?: "InvoiceFolder";
          id: string;
          name: string;
        }>;
      };
    }>;
  };
};

export type ReceiptsByIdsQueryVariables = Exact<{
  filter?: InputMaybe<QueryInvoicesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ReceiptsByIdsQuery = {
  __typename?: "Query";
  receipts: {
    __typename?: "InvoiceConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "InvoiceEdge";
      cursor: string;
      node: {
        __typename?: "Invoice";
        id: string;
        number?: string | null;
        status: InvoiceStatus;
        poNumber?: string | null;
        createdAt: number;
        total?: string | null;
        release?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          hasMissingCostCodes: boolean;
          exportedAt?: number | null;
          status: ReleaseStatus;
          poNumber?: string | null;
          poLink?: {
            __typename?: "POLink";
            id: string;
            syncedAt?: number | null;
          } | null;
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
            org: {
              __typename?: "Organization";
              id: string;
              photoUrl?: string | null;
              name: string;
            };
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            externalCode?: string | null;
            deletedAt?: number | null;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
              receivesInvoiceNotifications: boolean;
            }>;
          } | null;
          project?: {
            __typename?: "Project";
            id: string;
            name: string;
            zones: Array<{ __typename?: "Zone"; id: string }>;
          } | null;
          type: {
            __typename?: "ReleaseType";
            id: string;
            name: string;
            transactionKind: TransactionKind;
            requireDeliverySlip: boolean;
            trackInventory: boolean;
            includeAdditionalCharges: boolean;
            poFormat?: PoFormat | null;
            default: boolean;
            taxMode?: TaxMode | null;
            defaultAutoSync: boolean;
            ledgerAccount?: {
              __typename?: "ExternalLedgerAccount";
              externalId: string;
              sourceId: string;
              name: string;
              number: string;
              subaccounts?: Array<{
                __typename?: "ExternalLedgerSubaccount";
                externalId: string;
                sourceId: string;
                name: string;
                number: string;
              }> | null;
            } | null;
          };
        } | null;
        documents: Array<{
          __typename?: "InvoiceDocument";
          id: string;
          version: number;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
          pageRange: { __typename?: "PageRange"; start: number; end: number };
        }>;
        permissions: {
          __typename?: "InvoicePermissions";
          archive: AuthorizationStatus;
          export: AuthorizationStatus;
        };
        link?: {
          __typename?: "InvoiceLink";
          id: string;
          batch?: {
            __typename?: "ExternalBatch";
            id: string;
            sourceSystem: SourceSystem;
            externalId: string;
            sourceId: string;
            number: string;
            month: string;
            type: BatchType;
          } | null;
        } | null;
        paymentMethod?: {
          __typename?: "PaymentMethod";
          id: string;
          description: string;
        } | null;
      };
    }>;
  };
};

export type ExternalBatchFieldsFragment = {
  __typename?: "ExternalBatch";
  id: string;
  sourceSystem: SourceSystem;
  externalId: string;
  sourceId: string;
  number: string;
  month: string;
  type: BatchType;
};

export type ExternalWarehouseFieldsFragment = {
  __typename?: "ExternalWarehouse";
  externalId: string;
  sourceId: string;
  code: string;
  name: string;
};

export type MaterialConversionFieldsFragment = {
  __typename?: "MaterialConversion";
  id: string;
  quantityMultiplier: string;
  from: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  to: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  material: {
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    costCode?: { __typename?: "CostCode"; id: string } | null;
    defaultEstimateUom: { __typename?: "UOM"; id: string };
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
          } | null;
        }
      | { __typename?: "Product"; id: string; name: string }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          manufacturer: { __typename?: "Manufacturer"; id: string };
        };
  };
};

export type PoLinkFieldsFragment = {
  __typename?: "POLink";
  id: string;
  format: PoFormat;
  sourceSystem: SourceSystem;
  externalWarehouseId?: string | null;
  syncing: boolean;
  syncedVersion: number;
  syncedAt?: number | null;
  createdAt: number;
  autoSync: boolean;
  autoSyncError?: string | null;
  autoSyncFailureCount: number;
  retroactive: boolean;
  immutable: boolean;
  failedAt?: number | null;
  failedMessage?: string | null;
  validationErrors: Array<PoValidationError>;
  createdBy?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type PoMaterialConversionFieldsFragment = {
  __typename?: "POMaterialConversion";
  purchasingUom: string;
  quantityMultiplier?: string | null;
  fulfillmentUom: {
    __typename?: "UOM";
    id: string;
    mnemonic?: string | null;
    singularDescription?: string | null;
    pluralDescription: string;
    alternativeRefs: Array<string>;
  };
  material: {
    __typename?: "OrgMaterial";
    id: string;
    externalCode?: string | null;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
    defaultEstimateUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    referenceCounts: {
      __typename?: "MaterialReferenceCounts";
      estimatedItems: number;
      rfqItems: number;
      buyoutItems: number;
      releaseItems: number;
    };
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    vendorPrices: Array<{
      __typename?: "VendorPrice";
      id: string;
      orgMaterialId: string;
      price: string;
      expirationDate?: number | null;
      orderIncrement?: number | null;
      minimumOrder?: number | null;
      leadTime?: number | null;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      uom: { __typename?: "UOM"; id: string };
      manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
    }>;
    externalItems: Array<{
      __typename?: "ExternalItem";
      id: string;
      externalId: string;
      name: string;
      purchasingUom?: string | null;
      defaultCostCode?: string | null;
      sourceSystem: SourceSystem;
      sourceId: string;
    }>;
  };
};

export type PoValidationFieldsFragment = {
  __typename?: "POValidation";
  errors: Array<PoValidationError>;
  warnings: Array<PoValidationWarning>;
  itemsMissingPrices: Array<{ __typename?: "ReleaseItem"; id: string }>;
  itemsMissingCostCodes: Array<{ __typename?: "ReleaseItem"; id: string }>;
  costCodesNotConnected: Array<{ __typename?: "CostCode"; id: string }>;
  materialsNotConnected: Array<{
    __typename?: "OrgMaterial";
    id: string;
    material:
      | {
          __typename?: "OrgCatalogSku";
          id: string;
          name: string;
          customPartNumber?: string | null;
          sectionName?: string | null;
          defaultUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          defaultManufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "Product";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        }
      | {
          __typename?: "Sku";
          id: string;
          name: string;
          description: string;
          imageUrl: string;
          brand: string;
          manufacturer: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          };
          product: {
            __typename?: "Product";
            id: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          };
        };
  }>;
  materialConversions: Array<{
    __typename?: "POMaterialConversion";
    purchasingUom: string;
    quantityMultiplier?: string | null;
    fulfillmentUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    material: {
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    };
  }>;
  itemsMissingTags: Array<{
    __typename?: "ReleaseItem";
    id: string;
    orgMaterial?: {
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
    } | null;
  }>;
  missingBudgetCombinations: Array<{
    __typename?: "BudgetCombination";
    costCode: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
      inUse: boolean;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    };
    costType?: {
      __typename?: "CostType";
      id: string;
      code: string;
      description: string;
      org: { __typename?: "Organization"; id: string };
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
  }>;
};

export type ReleaseSummaryFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  poNumber?: string | null;
  predictedPoNumber?: string | null;
  status: ReleaseStatus;
  time?: number | null;
  version: number;
  taxRate?: string | null;
  total?: string | null;
  hasMissingCostCodes: boolean;
  hasMissingExternalCodes: boolean;
  customTaxAmount?: string | null;
  useSourceSystemPO: boolean;
  preferredVendor?: {
    __typename?: "OrgPreferredVendor";
    id: string;
    deletedAt?: number | null;
    externalCode?: string | null;
  } | null;
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    org: {
      __typename?: "Organization";
      id: string;
      photoUrl?: string | null;
      name: string;
    };
  } | null;
  permissions: {
    __typename?: "ReleasePermissions";
    edit: AuthorizationStatus;
    submit: AuthorizationStatus;
    submitDirectly: AuthorizationStatus;
    approve: AuthorizationStatus;
    archive: AuthorizationStatus;
  };
  warehouse?: {
    __typename?: "Warehouse";
    id: string;
    name: string;
    externalCode?: string | null;
    archivedAt?: number | null;
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  poLink?: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    batch?: {
      __typename?: "ExternalBatch";
      id: string;
      sourceSystem: SourceSystem;
      externalId: string;
      sourceId: string;
      number: string;
      month: string;
      type: BatchType;
    } | null;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  materialConversions: Array<{
    __typename?: "POMaterialConversion";
    purchasingUom: string;
    quantityMultiplier?: string | null;
    fulfillmentUom: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    material: {
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
      defaultEstimateUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      referenceCounts: {
        __typename?: "MaterialReferenceCounts";
        estimatedItems: number;
        rfqItems: number;
        buyoutItems: number;
        releaseItems: number;
      };
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      vendorPrices: Array<{
        __typename?: "VendorPrice";
        id: string;
        orgMaterialId: string;
        price: string;
        expirationDate?: number | null;
        orderIncrement?: number | null;
        minimumOrder?: number | null;
        leadTime?: number | null;
        sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
        uom: { __typename?: "UOM"; id: string };
        manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
      }>;
      externalItems: Array<{
        __typename?: "ExternalItem";
        id: string;
        externalId: string;
        name: string;
        purchasingUom?: string | null;
        defaultCostCode?: string | null;
        sourceSystem: SourceSystem;
        sourceId: string;
      }>;
    };
  }>;
  type: {
    __typename?: "ReleaseType";
    id: string;
    name: string;
    transactionKind: TransactionKind;
    requireDeliverySlip: boolean;
    trackInventory: boolean;
    includeAdditionalCharges: boolean;
    poFormat?: PoFormat | null;
    default: boolean;
    taxMode?: TaxMode | null;
    defaultAutoSync: boolean;
  };
};

export type CreateExternalBatchMutationVariables = Exact<{
  input: CreateExternalBatchInput;
}>;

export type CreateExternalBatchMutation = {
  __typename?: "Mutation";
  createExternalBatch: {
    __typename?: "ExternalBatch";
    id: string;
    sourceSystem: SourceSystem;
    externalId: string;
    sourceId: string;
    number: string;
    month: string;
    type: BatchType;
  };
};

export type CreateMaterialConversionsMutationVariables = Exact<{
  input: Array<CreateMaterialConversionInput> | CreateMaterialConversionInput;
}>;

export type CreateMaterialConversionsMutation = {
  __typename?: "Mutation";
  createMaterialConversions: Array<{
    __typename?: "MaterialConversion";
    id: string;
    quantityMultiplier: string;
    from: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    to: {
      __typename?: "UOM";
      id: string;
      mnemonic?: string | null;
      singularDescription?: string | null;
      pluralDescription: string;
      alternativeRefs: Array<string>;
    };
    material: {
      __typename?: "OrgMaterial";
      id: string;
      externalCode?: string | null;
      costCode?: { __typename?: "CostCode"; id: string } | null;
      defaultEstimateUom: { __typename?: "UOM"; id: string };
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
            } | null;
          }
        | { __typename?: "Product"; id: string; name: string }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: { __typename?: "Manufacturer"; id: string };
          };
    };
  }>;
};

export type LinkPoMutationVariables = Exact<{
  input: LinkPoInput;
}>;

export type LinkPoMutation = {
  __typename?: "Mutation";
  linkPO: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
};

export type ReverseSyncPoMutationVariables = Exact<{
  poLinkId: Scalars["ID"]["input"];
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type ReverseSyncPoMutation = {
  __typename?: "Mutation";
  reverseSyncPO: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  };
};

export type SyncPoMutationVariables = Exact<{
  poLinkId: Scalars["ID"]["input"];
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
}>;

export type SyncPoMutation = {
  __typename?: "Mutation";
  syncPO: {
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
    createdAt: number;
    syncVersion: number;
    total?: string | null;
    receiptPostedAt?: number | null;
    poExists?: boolean | null;
    useSourceSystemPO: boolean;
    poNumber?: string | null;
    submittedAt?: number | null;
    rejectedAt?: number | null;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    includeServices: Array<ServiceType>;
    taxRate?: string | null;
    taxAmount?: string | null;
    taxUnadjustedAmount?: string | null;
    customTaxAmount?: string | null;
    taxVariance?: string | null;
    taxType?: TaxType | null;
    netAmount: string;
    taxableNetAmount: string;
    notes?: string | null;
    description?: string | null;
    paymentTerm: number;
    hasMissingCostCodes: boolean;
    hasMissingExternalCodes: boolean;
    version: number;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    requiresInventoryReceipt?: boolean | null;
    updatedAt?: number | null;
    receiptPostedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    originalReassignedRelease?: {
      __typename?: "Release";
      id: string;
      reassignedReleases: Array<{
        __typename?: "Release";
        id: string;
        status: ReleaseStatus;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
      poNumber?: string | null;
      poLink?: { __typename?: "POLink"; id: string } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      taxExempt: boolean;
      preferredInventoryInflows: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: { __typename?: "OrgLocation"; id: string };
      team: Array<{ __typename?: "User"; id: string }>;
      permissions: {
        __typename?: "ProjectPermissions";
        report: AuthorizationStatus;
      };
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
      cancel: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
      role?: string | null;
      archivedAt?: number | null;
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      notes?: string | null;
      unitPrice?: string | null;
      alternativeFulfillmentTime?: number | null;
      priceEstimated: boolean;
      pricePrenegotiated: boolean;
      isIncluded: boolean;
      deliveryDate?: number | null;
      position?: number | null;
      invoicedQuantity: string;
      invoicedRemainingAdjustment?: string | null;
      deliveryDateTBD: boolean;
      taxable: boolean;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
        mapping?: {
          __typename?: "ProjectTagMapping";
          sourceSystem: SourceSystem;
          externalCostCode?: {
            __typename?: "ExternalCostCode";
            code: string;
            name: string;
          } | null;
          externalCostType?: {
            __typename?: "ExternalCostType";
            code: string;
          } | null;
        } | null;
      }>;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        address?: {
          __typename?: "Address";
          id: string;
          city: string;
          state: string;
        } | null;
        org: { __typename?: "Organization"; id: string; name: string };
      } | null;
      sourceWarehouse?: {
        __typename?: "Warehouse";
        id: string;
        name: string;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        quantityDecimal: string;
        description: string;
        unitPrice: string;
        isIncluded: boolean;
        releaseItems: Array<{
          __typename?: "ReleaseItem";
          id: string;
          quantityDecimal: string;
          release: { __typename?: "Release"; id: string };
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          uom?: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          } | null;
        }>;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        unitPrice?: string | null;
        quantitySoFar?: string | null;
        unexportedQuantitySoFar?: string | null;
      }>;
      poItemLink?: {
        __typename?: "POItemLink";
        id: string;
        poItemExternalId: string;
      } | null;
    }>;
    issues: Array<{
      __typename?: "ReleaseIssue";
      id: string;
      issueType: ReleaseIssueType;
      description?: string | null;
      quantityDecimal: string;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      photos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      resolution?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        resolutionType: ReleaseIssueResolutionType;
        comments?: string | null;
        redeliveryReleaseItem?: {
          __typename?: "ReleaseItem";
          id: string;
          alternativeFulfillmentTime?: number | null;
          deliveryDate?: number | null;
          alternativeFulfillmentRelease?: {
            __typename?: "Release";
            id: string;
            sequenceNumber?: number | null;
          } | null;
          release: {
            __typename?: "Release";
            id: string;
            time?: number | null;
            sequenceNumber?: number | null;
          };
        } | null;
      } | null;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      status: DeliverySlipStatus;
      createdAt: number;
      reviewedAt?: number | null;
      archivedAt?: number | null;
      createdBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      inventoryTransaction?: {
        __typename?: "InventoryTransaction";
        id: string;
      } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
      externalCode?: string | null;
      archivedAt?: number | null;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    deliveryAddress?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    quote?: {
      __typename?: "Quote";
      id: string;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        status: RfqStatus;
      };
    } | null;
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    reassignedReleases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
    }>;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      status: InvoiceStatus;
      number?: string | null;
      vendorDuplicate: boolean;
      orgDuplicate: boolean;
      total?: string | null;
      subtotal?: string | null;
      chargesAmount?: string | null;
      taxAmount?: string | null;
      archivedAt?: number | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      createdAt: number;
      prePaid: boolean;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      documents: Array<{
        __typename?: "InvoiceDocument";
        id: string;
        asset: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        };
      }>;
    }>;
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
      costType?: {
        __typename?: "CostType";
        id: string;
        description: string;
      } | null;
    };
    vendorContacts: Array<{
      __typename?: "VendorContact";
      id: string;
      name: string;
      cellPhone: string;
      cellPhoneExtension?: string | null;
      email: string;
      receivesQuoteNotifications: boolean;
      receivesBuyoutNotifications: boolean;
      receivesOrderNotifications: boolean;
      receivesInvoiceNotifications: boolean;
    }>;
    orgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      phone?: string | null;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    costCode?: {
      __typename?: "CostCode";
      id: string;
      code: string;
      description: string;
    } | null;
    zone?: {
      __typename?: "Zone";
      id: string;
      name: string;
      inUse: boolean;
    } | null;
    groups: Array<{
      __typename?: "ReleaseGroup";
      id: string;
      name: string;
      createdAt: number;
    }>;
    watchers: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    }>;
    complianceGroup?: { __typename?: "ComplianceGroup"; id: string } | null;
  };
};

export type UnlinkPoMutationVariables = Exact<{
  poLinkId: Scalars["ID"]["input"];
}>;

export type UnlinkPoMutation = {
  __typename?: "Mutation";
  unlinkPO: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
};

export type UpdateContractorReleasesMutationVariables = Exact<{
  input: UpdateContractorReleasesInput;
}>;

export type UpdateContractorReleasesMutation = {
  __typename?: "Mutation";
  updateContractorReleases: Array<{
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    useSourceSystemPO: boolean;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
  }>;
};

export type UpdatePoLinkMutationVariables = Exact<{
  input: UpdatePoLinkInput;
}>;

export type UpdatePoLinkMutation = {
  __typename?: "Mutation";
  updatePOLink: {
    __typename?: "POLink";
    id: string;
    format: PoFormat;
    sourceSystem: SourceSystem;
    externalWarehouseId?: string | null;
    syncing: boolean;
    syncedVersion: number;
    syncedAt?: number | null;
    createdAt: number;
    autoSync: boolean;
    autoSyncError?: string | null;
    autoSyncFailureCount: number;
    retroactive: boolean;
    immutable: boolean;
    failedAt?: number | null;
    failedMessage?: string | null;
    validationErrors: Array<PoValidationError>;
    createdBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
};

export type ExternalBatchQueryVariables = Exact<{
  input: ExternalBatchQueryInput;
}>;

export type ExternalBatchQuery = {
  __typename?: "Query";
  externalBatch?: {
    __typename?: "ExternalBatch";
    id: string;
    sourceSystem: SourceSystem;
    externalId: string;
    sourceId: string;
    number: string;
    month: string;
    type: BatchType;
  } | null;
};

export type ExternalWarehousesQueryVariables = Exact<{
  sourceSystem: SourceSystem;
}>;

export type ExternalWarehousesQuery = {
  __typename?: "Query";
  externalWarehouses: Array<{
    __typename?: "ExternalWarehouse";
    externalId: string;
    sourceId: string;
    code: string;
    name: string;
  }>;
};

export type PoValidationQueryVariables = Exact<{
  input: LinkPoInput;
}>;

export type PoValidationQuery = {
  __typename?: "Query";
  poValidation: {
    __typename?: "POValidation";
    errors: Array<PoValidationError>;
    warnings: Array<PoValidationWarning>;
    itemsMissingPrices: Array<{ __typename?: "ReleaseItem"; id: string }>;
    itemsMissingCostCodes: Array<{ __typename?: "ReleaseItem"; id: string }>;
    costCodesNotConnected: Array<{ __typename?: "CostCode"; id: string }>;
    materialsNotConnected: Array<{
      __typename?: "OrgMaterial";
      id: string;
      material:
        | {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
            customPartNumber?: string | null;
            sectionName?: string | null;
            defaultUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            defaultManufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
          }
        | {
            __typename?: "Product";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            uom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
          }
        | {
            __typename?: "Sku";
            id: string;
            name: string;
            description: string;
            imageUrl: string;
            brand: string;
            manufacturer: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            };
            product: {
              __typename?: "Product";
              id: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            };
          };
    }>;
    materialConversions: Array<{
      __typename?: "POMaterialConversion";
      purchasingUom: string;
      quantityMultiplier?: string | null;
      fulfillmentUom: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      };
      material: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      };
    }>;
    itemsMissingTags: Array<{
      __typename?: "ReleaseItem";
      id: string;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
      } | null;
    }>;
    missingBudgetCombinations: Array<{
      __typename?: "BudgetCombination";
      costCode: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      };
      costType?: {
        __typename?: "CostType";
        id: string;
        code: string;
        description: string;
        org: { __typename?: "Organization"; id: string };
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
    }>;
  };
};

export type ReleasesByIdsQueryVariables = Exact<{
  filter?: InputMaybe<QueryReleasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ReleasesByIdsQuery = {
  __typename?: "Query";
  releases: {
    __typename?: "ReleaseConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ReleaseEdge";
      cursor: string;
      node: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        poNumber?: string | null;
        predictedPoNumber?: string | null;
        status: ReleaseStatus;
        time?: number | null;
        version: number;
        taxRate?: string | null;
        total?: string | null;
        hasMissingCostCodes: boolean;
        hasMissingExternalCodes: boolean;
        customTaxAmount?: string | null;
        useSourceSystemPO: boolean;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          deletedAt?: number | null;
          externalCode?: string | null;
        } | null;
        project?: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
        } | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            photoUrl?: string | null;
            name: string;
          };
        } | null;
        permissions: {
          __typename?: "ReleasePermissions";
          edit: AuthorizationStatus;
          submit: AuthorizationStatus;
          submitDirectly: AuthorizationStatus;
          approve: AuthorizationStatus;
          archive: AuthorizationStatus;
        };
        warehouse?: {
          __typename?: "Warehouse";
          id: string;
          name: string;
          externalCode?: string | null;
          archivedAt?: number | null;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
        } | null;
        poLink?: {
          __typename?: "POLink";
          id: string;
          format: PoFormat;
          sourceSystem: SourceSystem;
          externalWarehouseId?: string | null;
          syncing: boolean;
          syncedVersion: number;
          syncedAt?: number | null;
          createdAt: number;
          autoSync: boolean;
          autoSyncError?: string | null;
          autoSyncFailureCount: number;
          retroactive: boolean;
          immutable: boolean;
          failedAt?: number | null;
          failedMessage?: string | null;
          validationErrors: Array<PoValidationError>;
          batch?: {
            __typename?: "ExternalBatch";
            id: string;
            sourceSystem: SourceSystem;
            externalId: string;
            sourceId: string;
            number: string;
            month: string;
            type: BatchType;
          } | null;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
        materialConversions: Array<{
          __typename?: "POMaterialConversion";
          purchasingUom: string;
          quantityMultiplier?: string | null;
          fulfillmentUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          material: {
            __typename?: "OrgMaterial";
            id: string;
            externalCode?: string | null;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            defaultEstimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            referenceCounts: {
              __typename?: "MaterialReferenceCounts";
              estimatedItems: number;
              rfqItems: number;
              buyoutItems: number;
              releaseItems: number;
            };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
              inUse: boolean;
              mappings: Array<{
                __typename?: "SourceSystemMapping";
                externalId: string;
                sourceSystem: SourceSystem;
              }>;
            } | null;
            vendorPrices: Array<{
              __typename?: "VendorPrice";
              id: string;
              orgMaterialId: string;
              price: string;
              expirationDate?: number | null;
              orderIncrement?: number | null;
              minimumOrder?: number | null;
              leadTime?: number | null;
              sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
              uom: { __typename?: "UOM"; id: string };
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            }>;
            externalItems: Array<{
              __typename?: "ExternalItem";
              id: string;
              externalId: string;
              name: string;
              purchasingUom?: string | null;
              defaultCostCode?: string | null;
              sourceSystem: SourceSystem;
              sourceId: string;
            }>;
          };
        }>;
        type: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
          includeAdditionalCharges: boolean;
          poFormat?: PoFormat | null;
          default: boolean;
          taxMode?: TaxMode | null;
          defaultAutoSync: boolean;
        };
      };
    }>;
  };
};

export type SiteContactFieldsFragment = {
  __typename?: "SiteContact";
  id: string;
  name?: string | null;
  phone?: string | null;
  role?: string | null;
  archivedAt?: number | null;
};

export type ArchiveSiteContactMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveSiteContactMutation = {
  __typename?: "Mutation";
  archiveSiteContact: {
    __typename?: "SiteContact";
    id: string;
    archivedAt?: number | null;
  };
};

export type CreateSiteContactMutationVariables = Exact<{
  input: CreateSiteContactInput;
}>;

export type CreateSiteContactMutation = {
  __typename?: "Mutation";
  createSiteContact: {
    __typename?: "SiteContact";
    id: string;
    name?: string | null;
    phone?: string | null;
    role?: string | null;
    archivedAt?: number | null;
  };
};

export type SiteContactsQueryVariables = Exact<{
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SiteContactsQuery = {
  __typename?: "Query";
  siteContacts: Array<{
    __typename?: "SiteContact";
    id: string;
    name?: string | null;
    phone?: string | null;
    role?: string | null;
    archivedAt?: number | null;
  }>;
};

export type ReleasePredictedPoNumberQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ReleasePredictedPoNumberQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    predictedPoNumber?: string | null;
  } | null;
};

export type ReleaseItemDeliveredItemsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  itemId: Scalars["String"]["input"];
}>;

export type ReleaseItemDeliveredItemsQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    item: {
      __typename?: "ReleaseItem";
      id: string;
      deliveredItems: Array<{
        __typename?: "DeliveredReleaseItem";
        id: string;
        quantity: string;
        deliverySlip: {
          __typename?: "DeliverySlip";
          id: string;
          status: DeliverySlipStatus;
          createdAt: number;
          createdBy: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          asset?: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          } | null;
        };
      }>;
    };
  } | null;
};

export type ReleaseItemInvoiceItemsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  itemId: Scalars["String"]["input"];
}>;

export type ReleaseItemInvoiceItemsQuery = {
  __typename?: "Query";
  release?: {
    __typename?: "Release";
    id: string;
    item: {
      __typename?: "ReleaseItem";
      id: string;
      invoiceItems: Array<{
        __typename?: "InvoicedReleaseItem";
        id: string;
        quantity: string;
        invoice: {
          __typename?: "Invoice";
          id: string;
          number?: string | null;
          createdAt: number;
          documents: Array<{
            __typename?: "InvoiceDocument";
            id: string;
            asset: {
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            };
          }>;
        };
      }>;
    };
  } | null;
};

export type ReleaseGroupFieldsFragment = {
  __typename?: "ReleaseGroup";
  id: string;
  name: string;
  createdAt: number;
};

export type ReleaseToCsvExportFieldsFragment = {
  __typename?: "Release";
  id: string;
  sequenceNumber?: number | null;
  status: ReleaseStatus;
  poNumber?: string | null;
  description?: string | null;
  createdAt: number;
  time?: number | null;
  timeTBD: boolean;
  includeServices: Array<ServiceType>;
  total?: string | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  project?: {
    __typename?: "Project";
    id: string;
    jobNumber?: string | null;
  } | null;
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
  } | null;
  sellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: { __typename?: "Organization"; id: string; name: string };
  } | null;
  type: { __typename?: "ReleaseType"; id: string; name: string };
  issues: Array<{ __typename?: "ReleaseIssue"; id: string }>;
  siteContact?: {
    __typename?: "SiteContact";
    id: string;
    name?: string | null;
    phone?: string | null;
  } | null;
};

export type ArchiveReleasesMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type ArchiveReleasesMutation = {
  __typename?: "Mutation";
  archiveReleases: Array<{
    __typename?: "Release";
    id: string;
    deletedAt?: number | null;
    status: ReleaseStatus;
  }>;
};

export type CreateOrderGroupMutationVariables = Exact<{
  input: CreateReleaseGroupInput;
}>;

export type CreateOrderGroupMutation = {
  __typename?: "Mutation";
  createReleaseGroup: {
    __typename?: "ReleaseGroup";
    id: string;
    name: string;
    createdAt: number;
  };
};

export type DeleteOrderGroupMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteOrderGroupMutation = {
  __typename?: "Mutation";
  deleteReleaseGroup: { __typename?: "ReleaseGroup"; id: string };
};

export type UpdateOrderGroupMutationVariables = Exact<{
  input: UpdateReleaseGroupInput;
}>;

export type UpdateOrderGroupMutation = {
  __typename?: "Mutation";
  updateReleaseGroup: {
    __typename?: "ReleaseGroup";
    id: string;
    name: string;
    createdAt: number;
  };
};

export type UpdateReleasesMutationVariables = Exact<{
  input: UpdateContractorReleasesInput;
}>;

export type UpdateReleasesMutation = {
  __typename?: "Mutation";
  updateContractorReleases: Array<{
    __typename?: "Release";
    id: string;
    sequenceNumber?: number | null;
    poNumber?: string | null;
    description?: string | null;
    syncVersion: number;
    poExists?: boolean | null;
    isInvoiced: boolean;
    useSourceSystemPO: boolean;
    createdAt: number;
    rejectionReason?: string | null;
    status: ReleaseStatus;
    time?: number | null;
    timeTBD: boolean;
    version: number;
    includeServices: Array<ServiceType>;
    deletedAt?: number | null;
    taxRate?: string | null;
    total?: string | null;
    hasMissingCostCodes: boolean;
    exportedAt?: number | null;
    exportedVersion?: number | null;
    receiptPostedAt?: number | null;
    requiresInventoryReceipt?: boolean | null;
    isBackorder: boolean;
    itemCount: number;
    submittedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    rejectedBy?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    } | null;
    permissions: {
      __typename?: "ReleasePermissions";
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
      submitDirectly: AuthorizationStatus;
      approve: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
    siteContact?: {
      __typename?: "SiteContact";
      id: string;
      name?: string | null;
      phone?: string | null;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
      createdAt: number;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      externalCode?: string | null;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      location: { __typename?: "OrgLocation"; id: string };
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
      org: {
        __typename?: "Organization";
        id: string;
        photoUrl?: string | null;
        name: string;
      };
    } | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    issues: Array<{ __typename?: "ReleaseIssue"; id: string }>;
    poLink?: {
      __typename?: "POLink";
      id: string;
      format: PoFormat;
      sourceSystem: SourceSystem;
      externalWarehouseId?: string | null;
      syncing: boolean;
      syncedVersion: number;
      syncedAt?: number | null;
      createdAt: number;
      autoSync: boolean;
      autoSyncError?: string | null;
      autoSyncFailureCount: number;
      retroactive: boolean;
      immutable: boolean;
      failedAt?: number | null;
      failedMessage?: string | null;
      validationErrors: Array<PoValidationError>;
      createdBy?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    backorderFrom: Array<{
      __typename?: "Release";
      id: string;
      sequenceNumber?: number | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    approvalProgress: {
      __typename?: "ReleaseApprovalProgress";
      canApprove: boolean;
      canApproveImmediately: boolean;
      canReject: boolean;
      slots: Array<{
        __typename?: "ReleaseApprovalSlot";
        role?: OrgLocationRole | null;
        ineffectual: boolean;
        approvals: Array<{
          __typename?: "ReleaseApproval";
          id: string;
          roles: Array<OrgLocationRole>;
          createdAt: number;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
        }>;
      }>;
    };
    approvals: Array<{
      __typename?: "ReleaseApproval";
      id: string;
      roles: Array<OrgLocationRole>;
      createdAt: number;
      approver: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
    };
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      archivedAt?: number | null;
    }>;
    noteDocument?: { __typename?: "NoteDocument"; id: string } | null;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      issueDate?: number | null;
      archivedAt?: number | null;
    }>;
    receipts: Array<{
      __typename?: "Invoice";
      id: string;
      number?: string | null;
      issueDate?: number | null;
      archivedAt?: number | null;
    }>;
    groups: Array<{ __typename?: "ReleaseGroup"; id: string; name: string }>;
    warehouse?: { __typename?: "Warehouse"; id: string; name: string } | null;
    sourceWarehouse?: {
      __typename?: "Warehouse";
      id: string;
      name: string;
    } | null;
  }>;
};

export type ReleasesToCsvExportQueryVariables = Exact<{
  filter?: InputMaybe<QueryReleasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<QueryReleasesSort>;
}>;

export type ReleasesToCsvExportQuery = {
  __typename?: "Query";
  releases: {
    __typename?: "ReleaseConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "ReleaseEdge";
      cursor: string;
      node: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        status: ReleaseStatus;
        poNumber?: string | null;
        description?: string | null;
        createdAt: number;
        time?: number | null;
        timeTBD: boolean;
        includeServices: Array<ServiceType>;
        total?: string | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          email: string;
        };
        project?: {
          __typename?: "Project";
          id: string;
          jobNumber?: string | null;
        } | null;
        buyout?: {
          __typename?: "Buyout";
          id: string;
          clientIdentifier: string;
        } | null;
        sellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          org: { __typename?: "Organization"; id: string; name: string };
        } | null;
        type: { __typename?: "ReleaseType"; id: string; name: string };
        issues: Array<{ __typename?: "ReleaseIssue"; id: string }>;
        siteContact?: {
          __typename?: "SiteContact";
          id: string;
          name?: string | null;
          phone?: string | null;
        } | null;
      };
    }>;
  };
};

export type DeliveredReleaseItemsFieldsFragment = {
  __typename?: "DeliveredReleaseItem";
  id: string;
  quantity: string;
  quantitySoFar?: string | null;
  releaseItem: { __typename?: "ReleaseItem"; id: string };
  deliverySlip: { __typename?: "DeliverySlip"; id: string };
};

export type DeliverySlipFieldsFragment = {
  __typename?: "DeliverySlip";
  id: string;
  status: DeliverySlipStatus;
  notes?: string | null;
  fulfillmentDate?: number | null;
  poNumber?: string | null;
  hasPredictedRelease: boolean;
  createdAt: number;
  reviewedAt?: number | null;
  archivedAt?: number | null;
  orgLocation: { __typename?: "OrgLocation"; id: string };
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
    location: { __typename?: "OrgLocation"; id: string };
    address: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  } | null;
  asset?: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  } | null;
  items: Array<{
    __typename?: "DeliverySlipItem";
    id: string;
    description?: string | null;
    orderedQuantity?: string | null;
    receivedQuantity?: string | null;
    uom?: string | null;
    manufacturer?: string | null;
  }>;
  deliveredReleaseItems: Array<{
    __typename?: "DeliveredReleaseItem";
    id: string;
    quantity: string;
    quantitySoFar?: string | null;
    releaseItem: { __typename?: "ReleaseItem"; id: string };
    deliverySlip: { __typename?: "DeliverySlip"; id: string };
  }>;
  releaseItemHints: Array<{
    __typename?: "ReleaseItemHint";
    releaseItem: {
      __typename?: "ReleaseItem";
      id: string;
      unitPrice?: string | null;
      quantityDecimal: string;
      position?: number | null;
      orgMaterial?: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              sectionName?: string | null;
              defaultUom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
            }
          | {
              __typename?: "Product";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
            }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              description: string;
              imageUrl: string;
              brand: string;
              manufacturer: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              };
              product: {
                __typename?: "Product";
                id: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              };
            };
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        referenceCounts: {
          __typename?: "MaterialReferenceCounts";
          estimatedItems: number;
          rfqItems: number;
          buyoutItems: number;
          releaseItems: number;
        };
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
        vendorPrices: Array<{
          __typename?: "VendorPrice";
          id: string;
          orgMaterialId: string;
          price: string;
          expirationDate?: number | null;
          orderIncrement?: number | null;
          minimumOrder?: number | null;
          leadTime?: number | null;
          sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
          uom: { __typename?: "UOM"; id: string };
          manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
        }>;
        externalItems: Array<{
          __typename?: "ExternalItem";
          id: string;
          externalId: string;
          name: string;
          purchasingUom?: string | null;
          defaultCostCode?: string | null;
          sourceSystem: SourceSystem;
          sourceId: string;
        }>;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    };
    invoiceItem?: {
      __typename?: "InvoiceItem";
      id: string;
      UOM: string;
      unitPrice: string;
      quantityDecimal: string;
    } | null;
    deliverySlipItem?: {
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
    quoteDocumentItem?: {
      __typename?: "QuoteDocumentItem";
      id: string;
      description?: string | null;
      quantity?: string | null;
      unitPrice?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    } | null;
  }>;
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
    address?: {
      __typename?: "Address";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
  predictedRelease?: {
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    poNumber?: string | null;
    total?: string | null;
    netAmount: string;
    time?: number | null;
    version: number;
    sequenceNumber?: number | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
    project?: { __typename?: "Project"; id: string; name: string } | null;
  } | null;
  release?: {
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    sequenceNumber?: number | null;
    time?: number | null;
    total?: string | null;
    netAmount: string;
    poNumber?: string | null;
    itemCount: number;
    version: number;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      location: { __typename?: "OrgLocation"; id: string };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    poLink?: {
      __typename?: "POLink";
      id: string;
      immutable: boolean;
      sourceSystem: SourceSystem;
      syncedAt?: number | null;
    } | null;
    warehouse?: { __typename?: "Warehouse"; id: string } | null;
    sourceWarehouse?: { __typename?: "Warehouse"; id: string } | null;
  } | null;
  deliveryPhotos: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl?: string | null;
  };
  permissions: {
    __typename?: "DeliverySlipPermissions";
    edit: AuthorizationStatus;
    archive: AuthorizationStatus;
  };
};

export type DeliverySlipItemFieldsFragment = {
  __typename?: "DeliverySlipItem";
  id: string;
  description?: string | null;
  orderedQuantity?: string | null;
  receivedQuantity?: string | null;
  uom?: string | null;
  manufacturer?: string | null;
};

export type DeliverySlipPartialFieldsFragment = {
  __typename?: "DeliverySlip";
  id: string;
  status: DeliverySlipStatus;
  notes?: string | null;
  fulfillmentDate?: number | null;
  poNumber?: string | null;
  createdAt: number;
  reviewedAt?: number | null;
  archivedAt?: number | null;
  orgLocation: { __typename?: "OrgLocation"; id: string };
  project?: {
    __typename?: "Project";
    id: string;
    name: string;
    jobNumber?: string | null;
  } | null;
  predictedSellerOrgLocation?: {
    __typename?: "OrgLocation";
    id: string;
    org: {
      __typename?: "Organization";
      id: string;
      name: string;
      photoUrl?: string | null;
    };
  } | null;
  release?: {
    __typename?: "Release";
    id: string;
    status: ReleaseStatus;
    sequenceNumber?: number | null;
    time?: number | null;
    total?: string | null;
    poNumber?: string | null;
    itemCount: number;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
    };
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        cellPhone: string;
        email: string;
        receivesQuoteNotifications: boolean;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
        receivesInvoiceNotifications: boolean;
      }>;
    } | null;
    buyout?: {
      __typename?: "Buyout";
      id: string;
      clientIdentifier: string;
    } | null;
    project?: { __typename?: "Project"; id: string; name: string } | null;
  } | null;
  createdBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl?: string | null;
  };
  permissions: {
    __typename?: "DeliverySlipPermissions";
    edit: AuthorizationStatus;
    archive: AuthorizationStatus;
  };
};

export type ArchiveDeliverySlipMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ArchiveDeliverySlipMutation = {
  __typename?: "Mutation";
  archiveDeliverySlip: {
    __typename?: "DeliverySlip";
    id: string;
    archivedAt?: number | null;
    status: DeliverySlipStatus;
  };
};

export type CreateDeliverySlipMutationVariables = Exact<{
  input: CreateDeliverySlipInput;
}>;

export type CreateDeliverySlipMutation = {
  __typename?: "Mutation";
  createDeliverySlip: {
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    notes?: string | null;
    fulfillmentDate?: number | null;
    poNumber?: string | null;
    hasPredictedRelease: boolean;
    createdAt: number;
    reviewedAt?: number | null;
    archivedAt?: number | null;
    orgLocation: { __typename?: "OrgLocation"; id: string };
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      location: { __typename?: "OrgLocation"; id: string };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
    items: Array<{
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    }>;
    deliveredReleaseItems: Array<{
      __typename?: "DeliveredReleaseItem";
      id: string;
      quantity: string;
      quantitySoFar?: string | null;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      deliverySlip: { __typename?: "DeliverySlip"; id: string };
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    predictedRelease?: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      poNumber?: string | null;
      total?: string | null;
      netAmount: string;
      time?: number | null;
      version: number;
      sequenceNumber?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      project?: { __typename?: "Project"; id: string; name: string } | null;
    } | null;
    release?: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
      total?: string | null;
      netAmount: string;
      poNumber?: string | null;
      itemCount: number;
      version: number;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      } | null;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        location: { __typename?: "OrgLocation"; id: string };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      } | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        immutable: boolean;
        sourceSystem: SourceSystem;
        syncedAt?: number | null;
      } | null;
      warehouse?: { __typename?: "Warehouse"; id: string } | null;
      sourceWarehouse?: { __typename?: "Warehouse"; id: string } | null;
    } | null;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    };
    permissions: {
      __typename?: "DeliverySlipPermissions";
      edit: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
  };
};

export type ReplaceDeliverySlipMutationVariables = Exact<{
  input: UpdateDeliverySlipInput;
}>;

export type ReplaceDeliverySlipMutation = {
  __typename?: "Mutation";
  updateDeliverySlip: {
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    items: Array<{
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    }>;
    deliveredReleaseItems: Array<{
      __typename?: "DeliveredReleaseItem";
      id: string;
      quantity: string;
      quantitySoFar?: string | null;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      deliverySlip: { __typename?: "DeliverySlip"; id: string };
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
  };
};

export type RescanDeliverySlipMutationVariables = Exact<{
  deliverySlipId: Scalars["ID"]["input"];
}>;

export type RescanDeliverySlipMutation = {
  __typename?: "Mutation";
  rescanDeliverySlip: boolean;
};

export type UpdateDeliverySlipMutationVariables = Exact<{
  input: UpdateDeliverySlipInput;
}>;

export type UpdateDeliverySlipMutation = {
  __typename?: "Mutation";
  updateDeliverySlip: {
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    fulfillmentDate?: number | null;
    items: Array<{
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    deliveredReleaseItems: Array<{
      __typename?: "DeliveredReleaseItem";
      id: string;
      quantity: string;
      quantitySoFar?: string | null;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      deliverySlip: { __typename?: "DeliverySlip"; id: string };
    }>;
    release?: { __typename?: "Release"; id: string; itemCount: number } | null;
  };
};

export type DeliverySlipQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeliverySlipQuery = {
  __typename?: "Query";
  deliverySlip: {
    __typename?: "DeliverySlip";
    id: string;
    status: DeliverySlipStatus;
    notes?: string | null;
    fulfillmentDate?: number | null;
    poNumber?: string | null;
    hasPredictedRelease: boolean;
    createdAt: number;
    reviewedAt?: number | null;
    archivedAt?: number | null;
    orgLocation: { __typename?: "OrgLocation"; id: string };
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      jobNumber?: string | null;
      location: { __typename?: "OrgLocation"; id: string };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    } | null;
    asset?: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    } | null;
    items: Array<{
      __typename?: "DeliverySlipItem";
      id: string;
      description?: string | null;
      orderedQuantity?: string | null;
      receivedQuantity?: string | null;
      uom?: string | null;
      manufacturer?: string | null;
    }>;
    deliveredReleaseItems: Array<{
      __typename?: "DeliveredReleaseItem";
      id: string;
      quantity: string;
      quantitySoFar?: string | null;
      releaseItem: { __typename?: "ReleaseItem"; id: string };
      deliverySlip: { __typename?: "DeliverySlip"; id: string };
    }>;
    releaseItemHints: Array<{
      __typename?: "ReleaseItemHint";
      releaseItem: {
        __typename?: "ReleaseItem";
        id: string;
        unitPrice?: string | null;
        quantityDecimal: string;
        position?: number | null;
        orgMaterial?: {
          __typename?: "OrgMaterial";
          id: string;
          externalCode?: string | null;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          defaultEstimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          referenceCounts: {
            __typename?: "MaterialReferenceCounts";
            estimatedItems: number;
            rfqItems: number;
            buyoutItems: number;
            releaseItems: number;
          };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          vendorPrices: Array<{
            __typename?: "VendorPrice";
            id: string;
            orgMaterialId: string;
            price: string;
            expirationDate?: number | null;
            orderIncrement?: number | null;
            minimumOrder?: number | null;
            leadTime?: number | null;
            sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
            uom: { __typename?: "UOM"; id: string };
            manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
          }>;
          externalItems: Array<{
            __typename?: "ExternalItem";
            id: string;
            externalId: string;
            name: string;
            purchasingUom?: string | null;
            defaultCostCode?: string | null;
            sourceSystem: SourceSystem;
            sourceId: string;
          }>;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      };
      invoiceItem?: {
        __typename?: "InvoiceItem";
        id: string;
        UOM: string;
        unitPrice: string;
        quantityDecimal: string;
      } | null;
      deliverySlipItem?: {
        __typename?: "DeliverySlipItem";
        id: string;
        description?: string | null;
        orderedQuantity?: string | null;
        receivedQuantity?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
      quoteDocumentItem?: {
        __typename?: "QuoteDocumentItem";
        id: string;
        description?: string | null;
        quantity?: string | null;
        unitPrice?: string | null;
        uom?: string | null;
        manufacturer?: string | null;
      } | null;
    }>;
    predictedSellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    predictedRelease?: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      poNumber?: string | null;
      total?: string | null;
      netAmount: string;
      time?: number | null;
      version: number;
      sequenceNumber?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      } | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      project?: { __typename?: "Project"; id: string; name: string } | null;
    } | null;
    release?: {
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
      total?: string | null;
      netAmount: string;
      poNumber?: string | null;
      itemCount: number;
      version: number;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      } | null;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        contacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        location: { __typename?: "OrgLocation"; id: string };
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      } | null;
      poLink?: {
        __typename?: "POLink";
        id: string;
        immutable: boolean;
        sourceSystem: SourceSystem;
        syncedAt?: number | null;
      } | null;
      warehouse?: { __typename?: "Warehouse"; id: string } | null;
      sourceWarehouse?: { __typename?: "Warehouse"; id: string } | null;
    } | null;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl?: string | null;
    };
    permissions: {
      __typename?: "DeliverySlipPermissions";
      edit: AuthorizationStatus;
      archive: AuthorizationStatus;
    };
  };
};

export type DeliverySlipStatsQueryVariables = Exact<{ [key: string]: never }>;

export type DeliverySlipStatsQuery = {
  __typename?: "Query";
  deliverySlipStats: { __typename?: "DeliverySlipStats"; pendingCount: number };
};

export type DeliverySlipsQueryVariables = Exact<{
  filter?: InputMaybe<QueryDeliverySlipsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<QueryDeliverySlipsSort>;
}>;

export type DeliverySlipsQuery = {
  __typename?: "Query";
  deliverySlips: {
    __typename?: "DeliverySlipConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "DeliverySlipEdge";
      cursor: string;
      node: {
        __typename?: "DeliverySlip";
        id: string;
        status: DeliverySlipStatus;
        notes?: string | null;
        fulfillmentDate?: number | null;
        poNumber?: string | null;
        createdAt: number;
        reviewedAt?: number | null;
        archivedAt?: number | null;
        orgLocation: { __typename?: "OrgLocation"; id: string };
        project?: {
          __typename?: "Project";
          id: string;
          name: string;
          jobNumber?: string | null;
        } | null;
        predictedSellerOrgLocation?: {
          __typename?: "OrgLocation";
          id: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        } | null;
        release?: {
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
          sequenceNumber?: number | null;
          time?: number | null;
          total?: string | null;
          poNumber?: string | null;
          itemCount: number;
          type: {
            __typename?: "ReleaseType";
            id: string;
            name: string;
            transactionKind: TransactionKind;
          };
          sellerOrgLocation?: {
            __typename?: "OrgLocation";
            id: string;
            name: string;
            org: {
              __typename?: "Organization";
              id: string;
              name: string;
              photoUrl?: string | null;
            };
            address?: {
              __typename?: "Address";
              id: string;
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: string;
            } | null;
          } | null;
          preferredVendor?: {
            __typename?: "OrgPreferredVendor";
            id: string;
            contacts: Array<{
              __typename?: "VendorContact";
              id: string;
              name: string;
              cellPhone: string;
              email: string;
              receivesQuoteNotifications: boolean;
              receivesBuyoutNotifications: boolean;
              receivesOrderNotifications: boolean;
              receivesInvoiceNotifications: boolean;
            }>;
          } | null;
          buyout?: {
            __typename?: "Buyout";
            id: string;
            clientIdentifier: string;
          } | null;
          project?: { __typename?: "Project"; id: string; name: string } | null;
        } | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          photoUrl?: string | null;
        };
        permissions: {
          __typename?: "DeliverySlipPermissions";
          edit: AuthorizationStatus;
          archive: AuthorizationStatus;
        };
      };
    }>;
  };
};

export type OrgMaterialsFilteredQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<QueryOrgMaterialsFilter>;
}>;

export type OrgMaterialsFilteredQuery = {
  __typename?: "Query";
  orgMaterials: {
    __typename?: "OrgMaterialConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: "OrgMaterialEdge";
      cursor: string;
      node: {
        __typename?: "OrgMaterial";
        id: string;
        externalCode?: string | null;
        defaultEstimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        costCode?: { __typename?: "CostCode"; id: string } | null;
        material:
          | {
              __typename?: "OrgCatalogSku";
              id: string;
              name: string;
              customPartNumber?: string | null;
              defaultManufacturer?: {
                __typename?: "Manufacturer";
                id: string;
              } | null;
            }
          | { __typename?: "Product"; id: string; name: string }
          | {
              __typename?: "Sku";
              id: string;
              name: string;
              manufacturer: { __typename?: "Manufacturer"; id: string };
            };
      };
    }>;
  };
};

export type ArchiveRfqsMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type ArchiveRfqsMutation = {
  __typename?: "Mutation";
  archiveRfqs: Array<{
    __typename?: "Rfq";
    id: string;
    deletedAt?: number | null;
    status: RfqStatus;
  }>;
};

export type CreateRfqMutationVariables = Exact<{
  input: CreateRfqInput;
}>;

export type CreateRfqMutation = {
  __typename?: "Mutation";
  createRfq: {
    __typename?: "Rfq";
    id: string;
    createdAt: number;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    deletedAt?: number | null;
    description?: string | null;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      budget?: string | null;
      deletedAt?: number | null;
      startDate?: number | null;
      percentageOfBudgetSpent?: string | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      poJobNumber?: string | null;
      restrictCostCodes: boolean;
      usesProjectCostCodes: boolean;
      preferredInventoryInflows: boolean;
      aggregatedZones: Array<{
        __typename?: "AggregatedZone";
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costCodes: Array<{
          __typename?: "AggregatedCostCode";
          costCode?: { __typename?: "CostCode"; id: string } | null;
          items: Array<{
            __typename?: "AggregatedItem";
            projectItem: { __typename?: "ProjectItem"; id: string };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              position?: number | null;
              notes?: string | null;
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
              tags: Array<{ __typename?: "ProjectTag"; id: string }>;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
              uom: { __typename?: "UOM"; id: string };
            }>;
            buyoutItems?: Array<{
              __typename?: "BuyoutItem";
              id: string;
            }> | null;
          }>;
        }>;
      }>;
      externalProjects: Array<{
        __typename?: "ExternalProject";
        id: string;
        sourceSystem: SourceSystem;
      }>;
      estimatedItemGroups: Array<{
        __typename?: "EstimatedItemGroup";
        estimatedItemCount: number;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      }>;
      team: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        photoUrl?: string | null;
      }>;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          costCodes: Array<{
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          }>;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      items: Array<{
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      }>;
      zones: Array<{
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      }>;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
      permissions: {
        __typename?: "ProjectPermissions";
        edit: AuthorizationStatus;
        createTag: AuthorizationStatus;
        report: AuthorizationStatus;
      };
    };
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    requisition?: { __typename?: "Requisition"; id: string } | null;
    items: Array<{
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      position?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      contacts: Array<{ __typename?: "VendorContact"; id: string }>;
    }>;
    nextRevision?: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      status: RfqStatus;
    } | null;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      status: QuoteStatus;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        subtotal: string;
        poExists?: boolean | null;
        description?: string | null;
        createdAt: number;
        status: BuyoutStatus;
        includedServices: Array<ServiceType>;
        poNumber?: string | null;
        version: number;
        paymentTermDays?: number | null;
        notes?: string | null;
        taxAmount?: string | null;
        taxRate?: string | null;
        netAmount: string;
        taxType?: TaxType | null;
        customTaxAmount?: string | null;
        additionalChargesAllowance?: string | null;
        exportedAt?: number | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        releases: Array<{
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
        }>;
        quote?: {
          __typename?: "Quote";
          id: string;
          notes?: string | null;
          expirationDate?: number | null;
          paymentTermDays?: number | null;
          additionalCharges: Array<{
            __typename?: "Charge";
            id: string;
            description: string;
            amount: string;
          }>;
          discount?: {
            __typename?: "Discount";
            amount?: string | null;
            percentage?: string | null;
          } | null;
          rfq: {
            __typename?: "Rfq";
            id: string;
            clientIdentifier: string;
            includeServices: Array<ServiceType>;
            status: RfqStatus;
            createdBy: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              photoUrl?: string | null;
            };
            quotes: Array<{
              __typename?: "Quote";
              id: string;
              buyout?: {
                __typename?: "Buyout";
                id: string;
                clientIdentifier: string;
                status: BuyoutStatus;
                sellerOrgLocation: {
                  __typename?: "OrgLocation";
                  id: string;
                  org: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                    photoUrl?: string | null;
                  };
                };
              } | null;
            }>;
          };
        } | null;
        quoteDocument?: {
          __typename?: "QuoteDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        } | null;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          taxExempt: boolean;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
            cellPhone: string;
            email: string;
            receivesBuyoutNotifications: boolean;
          }>;
        } | null;
        releaseType: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
          includeAdditionalCharges: boolean;
          poFormat?: PoFormat | null;
          default: boolean;
          taxMode?: TaxMode | null;
          defaultAutoSync: boolean;
        };
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          status: ProjectStatus;
          startDate?: number | null;
          jobNumber?: string | null;
          taxExempt: boolean;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
          location: { __typename?: "OrgLocation"; id: string };
          permissions: {
            __typename?: "ProjectPermissions";
            report: AuthorizationStatus;
          };
        };
        items: Array<{
          __typename?: "BuyoutItem";
          id: string;
          quantityDecimal: string;
          unitPrice: string;
          requestedUnitPrice?: string | null;
          releasedAmount?: string | null;
          completedAmount?: string | null;
          expirationDate?: number | null;
          notes?: string | null;
          isIncluded: boolean;
          position?: number | null;
          description: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          projectItem: {
            __typename?: "ProjectItem";
            id: string;
            material: {
              __typename?: "OrgMaterial";
              id: string;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
            };
            estimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              notes?: string | null;
              position?: number | null;
              zone?: {
                __typename?: "Zone";
                id: string;
                name: string;
                inUse: boolean;
              } | null;
              tags: Array<{
                __typename?: "ProjectTag";
                id: string;
                name: string;
                color: string;
                deletedAt?: number | null;
                hasMapping: boolean;
              }>;
              manufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
                inUse: boolean;
                mappings: Array<{
                  __typename?: "SourceSystemMapping";
                  externalId: string;
                  sourceSystem: SourceSystem;
                }>;
              } | null;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
            }>;
            manufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
            buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
          };
          rfqItem?: {
            __typename?: "RfqItem";
            id: string;
            description: string;
            quantityDecimal: string;
          } | null;
          orgCatalogSku?: {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
          } | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          masterSku?: {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: {
              __typename?: "Manufacturer";
              url?: string | null;
              id: string;
              name: string;
            };
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
        }>;
        standaloneReleaseItems: Array<{
          __typename?: "BuyoutStandaloneReleaseItem";
          totalQuantity: string;
          unitPrice: string;
          orderedSoFar: string;
          receivedSoFar: string;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          orgMaterial: {
            __typename?: "OrgMaterial";
            id: string;
            externalCode?: string | null;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            defaultEstimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            referenceCounts: {
              __typename?: "MaterialReferenceCounts";
              estimatedItems: number;
              rfqItems: number;
              buyoutItems: number;
              releaseItems: number;
            };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
              inUse: boolean;
              mappings: Array<{
                __typename?: "SourceSystemMapping";
                externalId: string;
                sourceSystem: SourceSystem;
              }>;
            } | null;
            vendorPrices: Array<{
              __typename?: "VendorPrice";
              id: string;
              orgMaterialId: string;
              price: string;
              expirationDate?: number | null;
              orderIncrement?: number | null;
              minimumOrder?: number | null;
              leadTime?: number | null;
              sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
              uom: { __typename?: "UOM"; id: string };
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            }>;
            externalItems: Array<{
              __typename?: "ExternalItem";
              id: string;
              externalId: string;
              name: string;
              purchasingUom?: string | null;
              defaultCostCode?: string | null;
              sourceSystem: SourceSystem;
              sourceId: string;
            }>;
          };
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
        }>;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        taxCode?: {
          __typename?: "TaxCode";
          id: string;
          code: string;
          description?: string | null;
          rate: string;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        poLink?: {
          __typename?: "POLink";
          id: string;
          format: PoFormat;
          sourceSystem: SourceSystem;
          externalWarehouseId?: string | null;
          syncing: boolean;
          syncedVersion: number;
          syncedAt?: number | null;
          createdAt: number;
          autoSync: boolean;
          autoSyncError?: string | null;
          autoSyncFailureCount: number;
          retroactive: boolean;
          immutable: boolean;
          failedAt?: number | null;
          failedMessage?: string | null;
          validationErrors: Array<PoValidationError>;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
        vendorContacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    permissions: {
      __typename?: "RfqPermissions";
      archive: AuthorizationStatus;
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
    };
  };
};

export type UpdateRfqMutationVariables = Exact<{
  input: UpdateRfqInput;
}>;

export type UpdateRfqMutation = {
  __typename?: "Mutation";
  updateRfq: {
    __typename?: "Rfq";
    id: string;
    createdAt: number;
    clientIdentifier: string;
    includeServices: Array<ServiceType>;
    status: RfqStatus;
    deletedAt?: number | null;
    description?: string | null;
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      status: ProjectStatus;
      budget?: string | null;
      deletedAt?: number | null;
      startDate?: number | null;
      percentageOfBudgetSpent?: string | null;
      jobNumber?: string | null;
      taxExempt: boolean;
      poJobNumber?: string | null;
      restrictCostCodes: boolean;
      usesProjectCostCodes: boolean;
      preferredInventoryInflows: boolean;
      aggregatedZones: Array<{
        __typename?: "AggregatedZone";
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        costCodes: Array<{
          __typename?: "AggregatedCostCode";
          costCode?: { __typename?: "CostCode"; id: string } | null;
          items: Array<{
            __typename?: "AggregatedItem";
            projectItem: { __typename?: "ProjectItem"; id: string };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              position?: number | null;
              notes?: string | null;
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
              tags: Array<{ __typename?: "ProjectTag"; id: string }>;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
              uom: { __typename?: "UOM"; id: string };
            }>;
            buyoutItems?: Array<{
              __typename?: "BuyoutItem";
              id: string;
            }> | null;
          }>;
        }>;
      }>;
      externalProjects: Array<{
        __typename?: "ExternalProject";
        id: string;
        sourceSystem: SourceSystem;
      }>;
      estimatedItemGroups: Array<{
        __typename?: "EstimatedItemGroup";
        estimatedItemCount: number;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
          inUse: boolean;
          mappings: Array<{
            __typename?: "SourceSystemMapping";
            externalId: string;
            sourceSystem: SourceSystem;
          }>;
        } | null;
      }>;
      team: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        photoUrl?: string | null;
      }>;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          costCodes: Array<{
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          }>;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      items: Array<{
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{ __typename?: "EstimatedItem"; id: string }>;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      }>;
      zones: Array<{
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      }>;
      mappings: Array<{
        __typename?: "SourceSystemMapping";
        externalId: string;
        sourceSystem: SourceSystem;
      }>;
      permissions: {
        __typename?: "ProjectPermissions";
        edit: AuthorizationStatus;
        createTag: AuthorizationStatus;
        report: AuthorizationStatus;
      };
    };
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    requisition?: { __typename?: "Requisition"; id: string } | null;
    items: Array<{
      __typename?: "RfqItem";
      id: string;
      description: string;
      quantityDecimal: string;
      position?: number | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
        estimatedItems: Array<{
          __typename?: "EstimatedItem";
          id: string;
          quantityDecimal: string;
          orderedQuantity: string;
          duplicateCount: number;
          unitPrice?: string | null;
          notes?: string | null;
          position?: number | null;
          zone?: {
            __typename?: "Zone";
            id: string;
            name: string;
            inUse: boolean;
          } | null;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          sellerOrgLocation?: { __typename?: "OrgLocation"; id: string } | null;
        }>;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
      };
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
    }>;
    targets: Array<{
      __typename?: "RfqPreferredVendor";
      id: string;
      sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
      contacts: Array<{ __typename?: "VendorContact"; id: string }>;
    }>;
    nextRevision?: {
      __typename?: "Rfq";
      id: string;
      clientIdentifier: string;
      status: RfqStatus;
    } | null;
    quotes: Array<{
      __typename?: "Quote";
      id: string;
      status: QuoteStatus;
      buyout?: {
        __typename?: "Buyout";
        id: string;
        clientIdentifier: string;
        subtotal: string;
        poExists?: boolean | null;
        description?: string | null;
        createdAt: number;
        status: BuyoutStatus;
        includedServices: Array<ServiceType>;
        poNumber?: string | null;
        version: number;
        paymentTermDays?: number | null;
        notes?: string | null;
        taxAmount?: string | null;
        taxRate?: string | null;
        netAmount: string;
        taxType?: TaxType | null;
        customTaxAmount?: string | null;
        additionalChargesAllowance?: string | null;
        exportedAt?: number | null;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        releases: Array<{
          __typename?: "Release";
          id: string;
          status: ReleaseStatus;
        }>;
        quote?: {
          __typename?: "Quote";
          id: string;
          notes?: string | null;
          expirationDate?: number | null;
          paymentTermDays?: number | null;
          additionalCharges: Array<{
            __typename?: "Charge";
            id: string;
            description: string;
            amount: string;
          }>;
          discount?: {
            __typename?: "Discount";
            amount?: string | null;
            percentage?: string | null;
          } | null;
          rfq: {
            __typename?: "Rfq";
            id: string;
            clientIdentifier: string;
            includeServices: Array<ServiceType>;
            status: RfqStatus;
            createdBy: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              photoUrl?: string | null;
            };
            quotes: Array<{
              __typename?: "Quote";
              id: string;
              buyout?: {
                __typename?: "Buyout";
                id: string;
                clientIdentifier: string;
                status: BuyoutStatus;
                sellerOrgLocation: {
                  __typename?: "OrgLocation";
                  id: string;
                  org: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                    photoUrl?: string | null;
                  };
                };
              } | null;
            }>;
          };
        } | null;
        quoteDocument?: {
          __typename?: "QuoteDocument";
          id: string;
          asset: {
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          };
        } | null;
        preferredVendor?: {
          __typename?: "OrgPreferredVendor";
          id: string;
          taxExempt: boolean;
          contacts: Array<{
            __typename?: "VendorContact";
            id: string;
            name: string;
            cellPhone: string;
            email: string;
            receivesBuyoutNotifications: boolean;
          }>;
        } | null;
        releaseType: {
          __typename?: "ReleaseType";
          id: string;
          name: string;
          transactionKind: TransactionKind;
          requireDeliverySlip: boolean;
          trackInventory: boolean;
          includeAdditionalCharges: boolean;
          poFormat?: PoFormat | null;
          default: boolean;
          taxMode?: TaxMode | null;
          defaultAutoSync: boolean;
        };
        sellerOrgLocation: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          status: ProjectStatus;
          startDate?: number | null;
          jobNumber?: string | null;
          taxExempt: boolean;
          address: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          };
          location: { __typename?: "OrgLocation"; id: string };
          permissions: {
            __typename?: "ProjectPermissions";
            report: AuthorizationStatus;
          };
        };
        items: Array<{
          __typename?: "BuyoutItem";
          id: string;
          quantityDecimal: string;
          unitPrice: string;
          requestedUnitPrice?: string | null;
          releasedAmount?: string | null;
          completedAmount?: string | null;
          expirationDate?: number | null;
          notes?: string | null;
          isIncluded: boolean;
          position?: number | null;
          description: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          instructions?: {
            __typename?: "Instruction";
            text: string;
            assets: Array<{
              __typename?: "Asset";
              id: string;
              url: string;
              type: AssetType;
              context: AssetContext;
              thumbnailUrl?: string | null;
              createdAt: number;
              isPrivate: boolean;
              pages?: Array<{
                __typename?: "AssetPage";
                url: string;
                thumbnailUrl: string;
              }> | null;
            }>;
          } | null;
          projectItem: {
            __typename?: "ProjectItem";
            id: string;
            material: {
              __typename?: "OrgMaterial";
              id: string;
              material:
                | {
                    __typename?: "OrgCatalogSku";
                    id: string;
                    name: string;
                    customPartNumber?: string | null;
                    sectionName?: string | null;
                    defaultUom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                    defaultManufacturer?: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    } | null;
                  }
                | {
                    __typename?: "Product";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  }
                | {
                    __typename?: "Sku";
                    id: string;
                    name: string;
                    description: string;
                    imageUrl: string;
                    brand: string;
                    manufacturer: {
                      __typename?: "Manufacturer";
                      id: string;
                      name: string;
                    };
                    product: {
                      __typename?: "Product";
                      id: string;
                      uom: {
                        __typename?: "UOM";
                        id: string;
                        mnemonic?: string | null;
                        singularDescription?: string | null;
                        pluralDescription: string;
                        alternativeRefs: Array<string>;
                      };
                    };
                  };
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
              } | null;
            };
            estimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            estimatedItems: Array<{
              __typename?: "EstimatedItem";
              id: string;
              quantityDecimal: string;
              orderedQuantity: string;
              duplicateCount: number;
              unitPrice?: string | null;
              notes?: string | null;
              position?: number | null;
              zone?: {
                __typename?: "Zone";
                id: string;
                name: string;
                inUse: boolean;
              } | null;
              tags: Array<{
                __typename?: "ProjectTag";
                id: string;
                name: string;
                color: string;
                deletedAt?: number | null;
                hasMapping: boolean;
              }>;
              manufacturer?: {
                __typename?: "Manufacturer";
                id: string;
                name: string;
              } | null;
              costCode?: {
                __typename?: "CostCode";
                id: string;
                code: string;
                description: string;
                inUse: boolean;
                mappings: Array<{
                  __typename?: "SourceSystemMapping";
                  externalId: string;
                  sourceSystem: SourceSystem;
                }>;
              } | null;
              uom: {
                __typename?: "UOM";
                id: string;
                mnemonic?: string | null;
                singularDescription?: string | null;
                pluralDescription: string;
                alternativeRefs: Array<string>;
              };
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
              } | null;
            }>;
            manufacturer?: {
              __typename?: "Manufacturer";
              id: string;
              name: string;
            } | null;
            buyoutItems: Array<{ __typename?: "BuyoutItem"; id: string }>;
          };
          rfqItem?: {
            __typename?: "RfqItem";
            id: string;
            description: string;
            quantityDecimal: string;
          } | null;
          orgCatalogSku?: {
            __typename?: "OrgCatalogSku";
            id: string;
            name: string;
          } | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
          masterSku?: {
            __typename?: "Sku";
            id: string;
            name: string;
            manufacturer: {
              __typename?: "Manufacturer";
              url?: string | null;
              id: string;
              name: string;
            };
          } | null;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
          tags: Array<{
            __typename?: "ProjectTag";
            id: string;
            name: string;
            color: string;
            deletedAt?: number | null;
            hasMapping: boolean;
          }>;
        }>;
        standaloneReleaseItems: Array<{
          __typename?: "BuyoutStandaloneReleaseItem";
          totalQuantity: string;
          unitPrice: string;
          orderedSoFar: string;
          receivedSoFar: string;
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
            inUse: boolean;
            mappings: Array<{
              __typename?: "SourceSystemMapping";
              externalId: string;
              sourceSystem: SourceSystem;
            }>;
          } | null;
          uom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
          orgMaterial: {
            __typename?: "OrgMaterial";
            id: string;
            externalCode?: string | null;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            defaultEstimateUom: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            };
            referenceCounts: {
              __typename?: "MaterialReferenceCounts";
              estimatedItems: number;
              rfqItems: number;
              buyoutItems: number;
              releaseItems: number;
            };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
              inUse: boolean;
              mappings: Array<{
                __typename?: "SourceSystemMapping";
                externalId: string;
                sourceSystem: SourceSystem;
              }>;
            } | null;
            vendorPrices: Array<{
              __typename?: "VendorPrice";
              id: string;
              orgMaterialId: string;
              price: string;
              expirationDate?: number | null;
              orderIncrement?: number | null;
              minimumOrder?: number | null;
              leadTime?: number | null;
              sellerOrgLocation: { __typename?: "OrgLocation"; id: string };
              uom: { __typename?: "UOM"; id: string };
              manufacturer?: { __typename?: "Manufacturer"; id: string } | null;
            }>;
            externalItems: Array<{
              __typename?: "ExternalItem";
              id: string;
              externalId: string;
              name: string;
              purchasingUom?: string | null;
              defaultCostCode?: string | null;
              sourceSystem: SourceSystem;
              sourceId: string;
            }>;
          };
          releaseItems: Array<{
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            unitPrice?: string | null;
            release: {
              __typename?: "Release";
              id: string;
              status: ReleaseStatus;
              sequenceNumber?: number | null;
              time?: number | null;
              sellerOrgLocation?: {
                __typename?: "OrgLocation";
                id: string;
                name: string;
                org: {
                  __typename?: "Organization";
                  id: string;
                  name: string;
                  photoUrl?: string | null;
                };
              } | null;
            };
            uom?: {
              __typename?: "UOM";
              id: string;
              mnemonic?: string | null;
              singularDescription?: string | null;
              pluralDescription: string;
              alternativeRefs: Array<string>;
            } | null;
            zone?: {
              __typename?: "Zone";
              id: string;
              name: string;
              inUse: boolean;
            } | null;
          }>;
        }>;
        additionalCharges: Array<{
          __typename?: "Charge";
          id: string;
          description: string;
          amount: string;
        }>;
        taxCode?: {
          __typename?: "TaxCode";
          id: string;
          code: string;
          description?: string | null;
          rate: string;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        poLink?: {
          __typename?: "POLink";
          id: string;
          format: PoFormat;
          sourceSystem: SourceSystem;
          externalWarehouseId?: string | null;
          syncing: boolean;
          syncedVersion: number;
          syncedAt?: number | null;
          createdAt: number;
          autoSync: boolean;
          autoSyncError?: string | null;
          autoSyncFailureCount: number;
          retroactive: boolean;
          immutable: boolean;
          failedAt?: number | null;
          failedMessage?: string | null;
          validationErrors: Array<PoValidationError>;
          createdBy?: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
        vendorContacts: Array<{
          __typename?: "VendorContact";
          id: string;
          name: string;
          cellPhone: string;
          cellPhoneExtension?: string | null;
          email: string;
          receivesQuoteNotifications: boolean;
          receivesBuyoutNotifications: boolean;
          receivesOrderNotifications: boolean;
          receivesInvoiceNotifications: boolean;
        }>;
      } | null;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    noteDocument?: {
      __typename?: "NoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    permissions: {
      __typename?: "RfqPermissions";
      archive: AuthorizationStatus;
      edit: AuthorizationStatus;
      submit: AuthorizationStatus;
    };
  };
};

export type ViewerNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerNotificationsQuery = {
  __typename?: "Query";
  viewer?: {
    __typename?: "User";
    id: string;
    getEmailNotifications: boolean;
    getSmsNotifications: boolean;
    excludedReleaseEvents: Array<ReleaseEvent>;
    excludedInvoiceEvents: Array<InvoiceEvent>;
    excludedBuyoutEvents: Array<BuyoutEvent>;
    excludedQuoteEvents: Array<QuoteEvent>;
    excludedMessageContexts: Array<MessageContextKind>;
  } | null;
};

export type AcceptBuyoutMutationVariables = Exact<{
  input: AcceptBuyoutInput;
}>;

export type AcceptBuyoutMutation = {
  __typename?: "Mutation";
  acceptBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    additionalChargesAllowance?: string | null;
    customTaxAmount?: string | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    subtotal: string;
    createdAt: number;
    status: BuyoutStatus;
    version: number;
    includedServices: Array<ServiceType>;
    paymentTermDays?: number | null;
    notes?: string | null;
    poNumber?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          settings?: {
            __typename?: "OrgSettings";
            display?: {
              __typename?: "DisplaySettings";
              enableManufacturers: boolean;
              currency: {
                __typename?: "Currency";
                id: string;
                code: string;
                name: string;
                symbol: string;
              };
            } | null;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      description: string;
      position?: number | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  };
};

export type DeclineBuyoutMutationVariables = Exact<{
  input: DeclineBuyoutInput;
}>;

export type DeclineBuyoutMutation = {
  __typename?: "Mutation";
  declineBuyout: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    additionalChargesAllowance?: string | null;
    customTaxAmount?: string | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    subtotal: string;
    createdAt: number;
    status: BuyoutStatus;
    version: number;
    includedServices: Array<ServiceType>;
    paymentTermDays?: number | null;
    notes?: string | null;
    poNumber?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          settings?: {
            __typename?: "OrgSettings";
            display?: {
              __typename?: "DisplaySettings";
              enableManufacturers: boolean;
              currency: {
                __typename?: "Currency";
                id: string;
                code: string;
                name: string;
                symbol: string;
              };
            } | null;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      description: string;
      position?: number | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  };
};

export type DistributorBuyoutQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorBuyoutQuery = {
  __typename?: "Query";
  buyout?: {
    __typename?: "Buyout";
    id: string;
    clientIdentifier: string;
    additionalChargesAllowance?: string | null;
    customTaxAmount?: string | null;
    taxRate?: string | null;
    taxAmount?: string | null;
    subtotal: string;
    createdAt: number;
    status: BuyoutStatus;
    version: number;
    includedServices: Array<ServiceType>;
    paymentTermDays?: number | null;
    notes?: string | null;
    poNumber?: string | null;
    netAmount: string;
    taxType?: TaxType | null;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    releases: Array<{
      __typename?: "Release";
      id: string;
      status: ReleaseStatus;
      sequenceNumber?: number | null;
      time?: number | null;
    }>;
    quote?: {
      __typename?: "Quote";
      id: string;
      notes?: string | null;
      expirationDate?: number | null;
      paymentTermDays?: number | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      discount?: {
        __typename?: "Discount";
        amount?: string | null;
        percentage?: string | null;
      } | null;
      rfq: {
        __typename?: "Rfq";
        id: string;
        clientIdentifier: string;
        includeServices: Array<ServiceType>;
        status: RfqStatus;
        createdBy: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
      contacts: Array<{
        __typename?: "VendorContact";
        id: string;
        name: string;
        email: string;
        cellPhone: string;
        receivesBuyoutNotifications: boolean;
        receivesOrderNotifications: boolean;
      }>;
    } | null;
    sellerOrgLocation: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      startDate?: number | null;
      status: ProjectStatus;
      taxExempt: boolean;
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        phone?: string | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
          settings?: {
            __typename?: "OrgSettings";
            display?: {
              __typename?: "DisplaySettings";
              enableManufacturers: boolean;
              currency: {
                __typename?: "Currency";
                id: string;
                code: string;
                name: string;
                symbol: string;
              };
            } | null;
          } | null;
          contractorOrg?: { __typename?: "Organization"; id: string } | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        contractorOrg?: { __typename?: "Organization"; id: string } | null;
      };
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    items: Array<{
      __typename?: "BuyoutItem";
      id: string;
      quantityDecimal: string;
      unitPrice: string;
      requestedUnitPrice?: string | null;
      expirationDate?: number | null;
      notes?: string | null;
      isIncluded: boolean;
      description: string;
      position?: number | null;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
        inUse: boolean;
        mappings: Array<{
          __typename?: "SourceSystemMapping";
          externalId: string;
          sourceSystem: SourceSystem;
        }>;
      } | null;
      projectItem: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      };
      rfqItem?: {
        __typename?: "RfqItem";
        id: string;
        description: string;
        quantityDecimal: string;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        projectItem: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        };
      } | null;
      orgCatalogSku?: {
        __typename?: "OrgCatalogSku";
        id: string;
        name: string;
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      masterSku?: {
        __typename?: "Sku";
        id: string;
        name: string;
        manufacturer: {
          __typename?: "Manufacturer";
          url?: string | null;
          id: string;
          name: string;
        };
      } | null;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    createdBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    quoteDocument?: {
      __typename?: "QuoteDocument";
      id: string;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
    } | null;
    taxCode?: {
      __typename?: "TaxCode";
      id: string;
      code: string;
      description?: string | null;
      rate: string;
    } | null;
    releaseType: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  } | null;
};

export type DistributorDeliverySlipFieldsFragment = {
  __typename?: "DeliverySlip";
  id: string;
  archivedAt?: number | null;
  asset?: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  } | null;
};

export type DistributorInvoiceDocumentFieldsFragment = {
  __typename?: "InvoiceDocument";
  id: string;
  version: number;
  asset: {
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  };
  pageRange: { __typename?: "PageRange"; start: number; end: number };
};

export type DistributorInvoiceFieldsFragment = {
  __typename?: "Invoice";
  id: string;
  number?: string | null;
  poNumber?: string | null;
  dueDate?: number | null;
  description?: string | null;
  subtotal?: string | null;
  computedSubtotal: string;
  chargesAmount?: string | null;
  taxAmount?: string | null;
  total?: string | null;
  issueDate?: number | null;
  status: InvoiceStatus;
  prePaid: boolean;
  retentionPercentage?: string | null;
  retentionAmount?: string | null;
  discountPercentage?: string | null;
  discountAmount?: string | null;
  discountDate?: number | null;
  discountedTotal?: string | null;
  items: Array<{
    __typename?: "InvoiceItem";
    id: string;
    description: string;
    partNumber?: string | null;
    unitPrice: string;
    UOM: string;
    manufacturer?: string | null;
    quantityDecimal: string;
  }>;
  documents: Array<{
    __typename?: "InvoiceDocument";
    id: string;
    version: number;
    asset: {
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    };
    pageRange: { __typename?: "PageRange"; start: number; end: number };
  }>;
  charges: Array<{
    __typename?: "InvoiceCharge";
    id: string;
    description: string;
    amount: string;
  }>;
  release?: {
    __typename?: "Release";
    id: string;
    version: number;
    sequenceNumber?: number | null;
    status: ReleaseStatus;
    total?: string | null;
    netAmount: string;
    taxAmount?: string | null;
    customTaxAmount?: string | null;
    taxableNetAmount: string;
    taxRate?: string | null;
    paymentTerm: number;
    notes?: string | null;
    taxType?: TaxType | null;
    additionalCharges: Array<{
      __typename?: "Charge";
      id: string;
      description: string;
      amount: string;
    }>;
    assets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    preferredVendor?: {
      __typename?: "OrgPreferredVendor";
      id: string;
      taxExempt: boolean;
    } | null;
    sellerOrgLocation?: {
      __typename?: "OrgLocation";
      id: string;
      name: string;
      org: {
        __typename?: "Organization";
        id: string;
        name: string;
        photoUrl?: string | null;
      };
      address?: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    project?: {
      __typename?: "Project";
      id: string;
      name: string;
      taxExempt: boolean;
      address: {
        __typename?: "Address";
        id: string;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      location: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
      };
    } | null;
    items: Array<{
      __typename?: "ReleaseItem";
      id: string;
      name: string;
      quantityDecimal: string;
      receivedQuantityDecimal: string;
      requestedQuantity: number;
      unitPrice?: string | null;
      isIncluded: boolean;
      position?: number | null;
      notes?: string | null;
      alternativeFulfillmentTime?: number | null;
      alternativeFulfillmentQuantityDecimal?: string | null;
      pricePrefilled: boolean;
      deliveryDate?: number | null;
      pricePrenegotiated: boolean;
      addedByVendor: boolean;
      deliveryDateTBD: boolean;
      taxable: boolean;
      zone?: {
        __typename?: "Zone";
        id: string;
        name: string;
        inUse: boolean;
      } | null;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      buyoutItem?: {
        __typename?: "BuyoutItem";
        id: string;
        description: string;
        quantityDecimal: string;
        unitPrice: string;
        notes?: string | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
      } | null;
      projectItem?: {
        __typename?: "ProjectItem";
        id: string;
        material: {
          __typename?: "OrgMaterial";
          id: string;
          material:
            | {
                __typename?: "OrgCatalogSku";
                id: string;
                name: string;
                customPartNumber?: string | null;
                sectionName?: string | null;
                defaultUom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
                defaultManufacturer?: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                } | null;
              }
            | {
                __typename?: "Product";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                uom: {
                  __typename?: "UOM";
                  id: string;
                  mnemonic?: string | null;
                  singularDescription?: string | null;
                  pluralDescription: string;
                  alternativeRefs: Array<string>;
                };
              }
            | {
                __typename?: "Sku";
                id: string;
                name: string;
                description: string;
                imageUrl: string;
                brand: string;
                manufacturer: {
                  __typename?: "Manufacturer";
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: "Product";
                  id: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                };
              };
          costCode?: {
            __typename?: "CostCode";
            id: string;
            code: string;
            description: string;
          } | null;
        };
        estimateUom: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        };
      } | null;
      backorderFromRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
      } | null;
      redelivery?: {
        __typename?: "ReleaseIssueResolution";
        id: string;
        issue: {
          __typename?: "ReleaseIssue";
          id: string;
          releaseItem: {
            __typename?: "ReleaseItem";
            id: string;
            quantityDecimal: string;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          };
        };
      } | null;
      manufacturer?: {
        __typename?: "Manufacturer";
        id: string;
        name: string;
      } | null;
      uom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      requestedUom?: {
        __typename?: "UOM";
        id: string;
        mnemonic?: string | null;
        singularDescription?: string | null;
        pluralDescription: string;
        alternativeRefs: Array<string>;
      } | null;
      alternativeFulfillmentRelease?: {
        __typename?: "Release";
        id: string;
        sequenceNumber?: number | null;
        time?: number | null;
      } | null;
      issues: Array<{
        __typename?: "ReleaseIssue";
        id: string;
        issueType: ReleaseIssueType;
        description?: string | null;
        quantityDecimal: string;
        releaseItem: { __typename?: "ReleaseItem"; id: string };
        photos: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        resolution?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          resolutionType: ReleaseIssueResolutionType;
          comments?: string | null;
          redeliveryReleaseItem?: {
            __typename?: "ReleaseItem";
            id: string;
            alternativeFulfillmentTime?: number | null;
            deliveryDate?: number | null;
            alternativeFulfillmentRelease?: {
              __typename?: "Release";
              id: string;
              sequenceNumber?: number | null;
            } | null;
            release: {
              __typename?: "Release";
              id: string;
              time?: number | null;
              sequenceNumber?: number | null;
            };
          } | null;
        } | null;
      }>;
      costCode?: {
        __typename?: "CostCode";
        id: string;
        code: string;
        description: string;
      } | null;
      tags: Array<{
        __typename?: "ProjectTag";
        id: string;
        name: string;
        color: string;
        deletedAt?: number | null;
        hasMapping: boolean;
      }>;
    }>;
    deliverySlips: Array<{
      __typename?: "DeliverySlip";
      id: string;
      archivedAt?: number | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      } | null;
    }>;
    deliveryPhotos: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    instructions?: {
      __typename?: "Instruction";
      text: string;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    } | null;
    type: {
      __typename?: "ReleaseType";
      id: string;
      name: string;
      transactionKind: TransactionKind;
      requireDeliverySlip: boolean;
      trackInventory: boolean;
      includeAdditionalCharges: boolean;
      poFormat?: PoFormat | null;
      default: boolean;
      taxMode?: TaxMode | null;
      defaultAutoSync: boolean;
    };
  } | null;
  issues: Array<{
    __typename?: "InvoiceIssue";
    id: string;
    description: string;
    createdAt: number;
    resolutionDescription?: string | null;
    respondedAt?: number | null;
    rejectionReason?: string | null;
    reportedBy: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      photoUrl?: string | null;
    };
    contractorAssets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
    vendorAssets: Array<{
      __typename?: "Asset";
      id: string;
      url: string;
      type: AssetType;
      context: AssetContext;
      thumbnailUrl?: string | null;
      createdAt: number;
      isPrivate: boolean;
      pages?: Array<{
        __typename?: "AssetPage";
        url: string;
        thumbnailUrl: string;
      }> | null;
    }>;
  }>;
};

export type DistributorInvoiceIssueFieldsFragment = {
  __typename?: "InvoiceIssue";
  id: string;
  description: string;
  createdAt: number;
  resolutionDescription?: string | null;
  respondedAt?: number | null;
  rejectionReason?: string | null;
  reportedBy: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    photoUrl?: string | null;
  };
  contractorAssets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
  vendorAssets: Array<{
    __typename?: "Asset";
    id: string;
    url: string;
    type: AssetType;
    context: AssetContext;
    thumbnailUrl?: string | null;
    createdAt: number;
    isPrivate: boolean;
    pages?: Array<{
      __typename?: "AssetPage";
      url: string;
      thumbnailUrl: string;
    }> | null;
  }>;
};

export type DistributorInvoiceItemFieldsFragment = {
  __typename?: "InvoiceItem";
  id: string;
  description: string;
  partNumber?: string | null;
  unitPrice: string;
  UOM: string;
  manufacturer?: string | null;
  quantityDecimal: string;
};

export type CorrectInvoiceDocumentMutationVariables = Exact<{
  input: CorrectInvoiceDocumentInput;
}>;

export type CorrectInvoiceDocumentMutation = {
  __typename?: "Mutation";
  correctInvoiceDocument: {
    __typename?: "Invoice";
    id: string;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  };
};

export type ResolveInvoiceIssueMutationVariables = Exact<{
  input: ResolveInvoiceIssueInput;
}>;

export type ResolveInvoiceIssueMutation = {
  __typename?: "Mutation";
  resolveInvoiceIssue: {
    __typename?: "Invoice";
    id: string;
    status: InvoiceStatus;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  };
};

export type DistributorInvoiceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DistributorInvoiceQuery = {
  __typename?: "Query";
  invoice: {
    __typename?: "Invoice";
    id: string;
    number?: string | null;
    poNumber?: string | null;
    dueDate?: number | null;
    description?: string | null;
    subtotal?: string | null;
    computedSubtotal: string;
    chargesAmount?: string | null;
    taxAmount?: string | null;
    total?: string | null;
    issueDate?: number | null;
    status: InvoiceStatus;
    prePaid: boolean;
    retentionPercentage?: string | null;
    retentionAmount?: string | null;
    discountPercentage?: string | null;
    discountAmount?: string | null;
    discountDate?: number | null;
    discountedTotal?: string | null;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      partNumber?: string | null;
      unitPrice: string;
      UOM: string;
      manufacturer?: string | null;
      quantityDecimal: string;
    }>;
    documents: Array<{
      __typename?: "InvoiceDocument";
      id: string;
      version: number;
      asset: {
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      };
      pageRange: { __typename?: "PageRange"; start: number; end: number };
    }>;
    charges: Array<{
      __typename?: "InvoiceCharge";
      id: string;
      description: string;
      amount: string;
    }>;
    release?: {
      __typename?: "Release";
      id: string;
      version: number;
      sequenceNumber?: number | null;
      status: ReleaseStatus;
      total?: string | null;
      netAmount: string;
      taxAmount?: string | null;
      customTaxAmount?: string | null;
      taxableNetAmount: string;
      taxRate?: string | null;
      paymentTerm: number;
      notes?: string | null;
      taxType?: TaxType | null;
      additionalCharges: Array<{
        __typename?: "Charge";
        id: string;
        description: string;
        amount: string;
      }>;
      assets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      preferredVendor?: {
        __typename?: "OrgPreferredVendor";
        id: string;
        taxExempt: boolean;
      } | null;
      sellerOrgLocation?: {
        __typename?: "OrgLocation";
        id: string;
        name: string;
        org: {
          __typename?: "Organization";
          id: string;
          name: string;
          photoUrl?: string | null;
        };
        address?: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        } | null;
      } | null;
      project?: {
        __typename?: "Project";
        id: string;
        name: string;
        taxExempt: boolean;
        address: {
          __typename?: "Address";
          id: string;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
        location: {
          __typename?: "OrgLocation";
          id: string;
          name: string;
          address?: {
            __typename?: "Address";
            id: string;
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            state: string;
            postalCode: string;
            country: string;
          } | null;
          org: {
            __typename?: "Organization";
            id: string;
            name: string;
            photoUrl?: string | null;
          };
        };
      } | null;
      items: Array<{
        __typename?: "ReleaseItem";
        id: string;
        name: string;
        quantityDecimal: string;
        receivedQuantityDecimal: string;
        requestedQuantity: number;
        unitPrice?: string | null;
        isIncluded: boolean;
        position?: number | null;
        notes?: string | null;
        alternativeFulfillmentTime?: number | null;
        alternativeFulfillmentQuantityDecimal?: string | null;
        pricePrefilled: boolean;
        deliveryDate?: number | null;
        pricePrenegotiated: boolean;
        addedByVendor: boolean;
        deliveryDateTBD: boolean;
        taxable: boolean;
        zone?: {
          __typename?: "Zone";
          id: string;
          name: string;
          inUse: boolean;
        } | null;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
        instructions?: {
          __typename?: "Instruction";
          text: string;
          assets: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
        } | null;
        buyoutItem?: {
          __typename?: "BuyoutItem";
          id: string;
          description: string;
          quantityDecimal: string;
          unitPrice: string;
          notes?: string | null;
          manufacturer?: {
            __typename?: "Manufacturer";
            id: string;
            name: string;
          } | null;
        } | null;
        projectItem?: {
          __typename?: "ProjectItem";
          id: string;
          material: {
            __typename?: "OrgMaterial";
            id: string;
            material:
              | {
                  __typename?: "OrgCatalogSku";
                  id: string;
                  name: string;
                  customPartNumber?: string | null;
                  sectionName?: string | null;
                  defaultUom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                  defaultManufacturer?: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  } | null;
                }
              | {
                  __typename?: "Product";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  uom: {
                    __typename?: "UOM";
                    id: string;
                    mnemonic?: string | null;
                    singularDescription?: string | null;
                    pluralDescription: string;
                    alternativeRefs: Array<string>;
                  };
                }
              | {
                  __typename?: "Sku";
                  id: string;
                  name: string;
                  description: string;
                  imageUrl: string;
                  brand: string;
                  manufacturer: {
                    __typename?: "Manufacturer";
                    id: string;
                    name: string;
                  };
                  product: {
                    __typename?: "Product";
                    id: string;
                    uom: {
                      __typename?: "UOM";
                      id: string;
                      mnemonic?: string | null;
                      singularDescription?: string | null;
                      pluralDescription: string;
                      alternativeRefs: Array<string>;
                    };
                  };
                };
            costCode?: {
              __typename?: "CostCode";
              id: string;
              code: string;
              description: string;
            } | null;
          };
          estimateUom: {
            __typename?: "UOM";
            id: string;
            mnemonic?: string | null;
            singularDescription?: string | null;
            pluralDescription: string;
            alternativeRefs: Array<string>;
          };
        } | null;
        backorderFromRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
        } | null;
        redelivery?: {
          __typename?: "ReleaseIssueResolution";
          id: string;
          issue: {
            __typename?: "ReleaseIssue";
            id: string;
            releaseItem: {
              __typename?: "ReleaseItem";
              id: string;
              quantityDecimal: string;
              release: {
                __typename?: "Release";
                id: string;
                time?: number | null;
                sequenceNumber?: number | null;
              };
            };
          };
        } | null;
        manufacturer?: {
          __typename?: "Manufacturer";
          id: string;
          name: string;
        } | null;
        uom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        requestedUom?: {
          __typename?: "UOM";
          id: string;
          mnemonic?: string | null;
          singularDescription?: string | null;
          pluralDescription: string;
          alternativeRefs: Array<string>;
        } | null;
        alternativeFulfillmentRelease?: {
          __typename?: "Release";
          id: string;
          sequenceNumber?: number | null;
          time?: number | null;
        } | null;
        issues: Array<{
          __typename?: "ReleaseIssue";
          id: string;
          issueType: ReleaseIssueType;
          description?: string | null;
          quantityDecimal: string;
          releaseItem: { __typename?: "ReleaseItem"; id: string };
          photos: Array<{
            __typename?: "Asset";
            id: string;
            url: string;
            type: AssetType;
            context: AssetContext;
            thumbnailUrl?: string | null;
            createdAt: number;
            isPrivate: boolean;
            pages?: Array<{
              __typename?: "AssetPage";
              url: string;
              thumbnailUrl: string;
            }> | null;
          }>;
          resolution?: {
            __typename?: "ReleaseIssueResolution";
            id: string;
            resolutionType: ReleaseIssueResolutionType;
            comments?: string | null;
            redeliveryReleaseItem?: {
              __typename?: "ReleaseItem";
              id: string;
              alternativeFulfillmentTime?: number | null;
              deliveryDate?: number | null;
              alternativeFulfillmentRelease?: {
                __typename?: "Release";
                id: string;
                sequenceNumber?: number | null;
              } | null;
              release: {
                __typename?: "Release";
                id: string;
                time?: number | null;
                sequenceNumber?: number | null;
              };
            } | null;
          } | null;
        }>;
        costCode?: {
          __typename?: "CostCode";
          id: string;
          code: string;
          description: string;
        } | null;
        tags: Array<{
          __typename?: "ProjectTag";
          id: string;
          name: string;
          color: string;
          deletedAt?: number | null;
          hasMapping: boolean;
        }>;
      }>;
      deliverySlips: Array<{
        __typename?: "DeliverySlip";
        id: string;
        archivedAt?: number | null;
        asset?: {
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        } | null;
      }>;
      deliveryPhotos: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      instructions?: {
        __typename?: "Instruction";
        text: string;
        assets: Array<{
          __typename?: "Asset";
          id: string;
          url: string;
          type: AssetType;
          context: AssetContext;
          thumbnailUrl?: string | null;
          createdAt: number;
          isPrivate: boolean;
          pages?: Array<{
            __typename?: "AssetPage";
            url: string;
            thumbnailUrl: string;
          }> | null;
        }>;
      } | null;
      type: {
        __typename?: "ReleaseType";
        id: string;
        name: string;
        transactionKind: TransactionKind;
        requireDeliverySlip: boolean;
        trackInventory: boolean;
        includeAdditionalCharges: boolean;
        poFormat?: PoFormat | null;
        default: boolean;
        taxMode?: TaxMode | null;
        defaultAutoSync: boolean;
      };
    } | null;
    issues: Array<{
      __typename?: "InvoiceIssue";
      id: string;
      description: string;
      createdAt: number;
      resolutionDescription?: string | null;
      respondedAt?: number | null;
      rejectionReason?: string | null;
      reportedBy: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      };
      contractorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
      vendorAssets: Array<{
        __typename?: "Asset";
        id: string;
        url: string;
        type: AssetType;
        context: AssetContext;
        thumbnailUrl?: string | null;
        createdAt: number;
        isPrivate: boolean;
        pages?: Array<{
          __typename?: "AssetPage";
          url: string;
          thumbnailUrl: string;
        }> | null;
      }>;
    }>;
  };
};

export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    addressLine1
    addressLine2
    city
    state
    postalCode
    country
  }
`;
export const VendorContactFieldsFragmentDoc = gql`
  fragment VendorContactFields on VendorContact {
    id
    name
    cellPhone
    cellPhoneExtension
    email
    receivesQuoteNotifications
    receivesBuyoutNotifications
    receivesOrderNotifications
    receivesInvoiceNotifications
  }
`;
export const ExternalNotificationFieldsFragmentDoc = gql`
  fragment ExternalNotificationFields on ExternalNotification {
    id
    channel
    subject
    recipient
    release {
      id
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          name
          photoUrl
        }
      }
      preferredVendor {
        id
        contacts {
          ...VendorContactFields
          receivesQuoteNotifications
          receivesBuyoutNotifications
          receivesOrderNotifications
          receivesInvoiceNotifications
        }
      }
    }
    buyout {
      id
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          name
          photoUrl
        }
      }
      preferredVendor {
        id
        contacts {
          ...VendorContactFields
          receivesQuoteNotifications
          receivesBuyoutNotifications
          receivesOrderNotifications
          receivesInvoiceNotifications
        }
      }
    }
    quote {
      id
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          name
          photoUrl
        }
      }
      preferredVendor {
        id
        contacts {
          ...VendorContactFields
          receivesQuoteNotifications
          receivesBuyoutNotifications
          receivesOrderNotifications
          receivesInvoiceNotifications
        }
      }
    }
    invoice {
      id
      release {
        id
        sellerOrgLocation {
          id
          address {
            ...AddressFields
          }
          org {
            id
            name
            photoUrl
          }
        }
        preferredVendor {
          id
          contacts {
            ...VendorContactFields
          }
        }
      }
    }
    createdAt
  }
  ${AddressFieldsFragmentDoc}
  ${VendorContactFieldsFragmentDoc}
`;
export const NotificationQuoteChangeContextFieldsFragmentDoc = gql`
  fragment NotificationQuoteChangeContextFields on NotificationContextQuoteStatusChange {
    id
    type
    quoteStatus: status
    quote {
      id
      status
      rfq {
        id
        clientIdentifier
        project {
          id
          name
        }
      }
      sellerOrgLocation {
        id
        org {
          id
          name
        }
      }
      preferredVendor {
        id
        contacts {
          id
          name
        }
      }
    }
    rfqOwner {
      id
      name
      org {
        id
        name
      }
    }
    initiatedBy {
      id
      firstName
      lastName
    }
  }
`;
export const NotificationBuyoutChangeContextFieldsFragmentDoc = gql`
  fragment NotificationBuyoutChangeContextFields on NotificationContextBuyoutStatusChange {
    id
    type
    buyout {
      id
      clientIdentifier
      project {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
      }
      preferredVendor {
        id
        contacts {
          id
          name
        }
        sellerOrgLocation {
          id
          name
          org {
            id
            name
          }
        }
      }
    }
    initiatedBy {
      id
      firstName
      lastName
    }
    newBuyoutStatus: newStatus
    oldBuyoutStatus: oldStatus
  }
`;
export const ReleaseApprovalFieldsFragmentDoc = gql`
  fragment ReleaseApprovalFields on ReleaseApproval {
    id
    approver {
      id
      firstName
      lastName
    }
    roles
    createdAt
  }
`;
export const NotificationReleaseChangeContextFieldsFragmentDoc = gql`
  fragment NotificationReleaseChangeContextFields on NotificationContextReleaseStatusChange {
    id
    type
    newStatus
    oldStatus
    event
    release {
      id
      sequenceNumber
      itemCount
      noteDocument {
        id
      }
      quoteDocument {
        id
      }
      status
      time
      project {
        id
        name
      }
      buyout {
        id
        clientIdentifier
        createdBy {
          id
          firstName
          lastName
        }
      }
      sellerOrgLocation {
        id
        org {
          id
          name
        }
      }
      preferredVendor {
        id
        contacts {
          id
          name
        }
      }
      submittedBy {
        id
        firstName
        lastName
        photoUrl
      }
      approvals {
        ...ReleaseApprovalFields
      }
      rejectedBy {
        id
        firstName
        lastName
        photoUrl
      }
      warehouse {
        id
        name
      }
    }
    initiatedBy {
      id
      firstName
      lastName
    }
  }
  ${ReleaseApprovalFieldsFragmentDoc}
`;
export const NotificationInvoiceChangeContextFieldsFragmentDoc = gql`
  fragment NotificationInvoiceChangeContextFields on NotificationContextInvoiceStatusChange {
    id
    type
    newInvoiceStatus: newStatus
    oldInvoiceStatus: oldStatus
    invoiceNotificationEvent: event
    invoiceNotificationInitiatedBy: initiatedBy {
      id
      firstName
      lastName
    }
    invoice {
      id
      number
      type
      approvedBy {
        id
        firstName
        lastName
        photoUrl
      }
      release {
        id
        sequenceNumber
        preferredVendor {
          id
          sellerOrgLocation {
            id
            org {
              id
              name
              photoUrl
            }
          }
        }
      }
      project {
        id
        name
        location {
          id
          org {
            id
            name
          }
        }
      }
    }
  }
`;
export const NotificationMessageContextFieldsFragmentDoc = gql`
  fragment NotificationMessageContextFields on NotificationContextMessage {
    id
    type
    message {
      id
      quote {
        id
        rfq {
          id
          clientIdentifier
          project {
            id
            name
          }
        }
        sellerOrgLocation {
          id
          org {
            id
            name
          }
        }
      }
      buyout {
        id
        clientIdentifier
        sellerOrgLocation {
          id
          org {
            id
            name
          }
        }
        quote {
          id
        }
        project {
          id
          name
        }
      }
      release {
        id
        sequenceNumber
        buyout {
          id
          clientIdentifier
          quote {
            id
          }
        }
        sellerOrgLocation {
          id
          org {
            id
            name
          }
        }
        project {
          id
          name
        }
      }
      author {
        id
        firstName
        lastName
      }
      message
      timeSent
    }
  }
`;
export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on Notification {
    id
    context {
      type
      ... on NotificationContextQuoteStatusChange {
        ...NotificationQuoteChangeContextFields
      }
      ... on NotificationContextBuyoutStatusChange {
        ...NotificationBuyoutChangeContextFields
      }
      ... on NotificationContextReleaseStatusChange {
        ...NotificationReleaseChangeContextFields
      }
      ... on NotificationContextInvoiceStatusChange {
        ...NotificationInvoiceChangeContextFields
      }
      ... on NotificationContextMessage {
        ...NotificationMessageContextFields
      }
    }
    createdAt
    readAt
    reminder
  }
  ${NotificationQuoteChangeContextFieldsFragmentDoc}
  ${NotificationBuyoutChangeContextFieldsFragmentDoc}
  ${NotificationReleaseChangeContextFieldsFragmentDoc}
  ${NotificationInvoiceChangeContextFieldsFragmentDoc}
  ${NotificationMessageContextFieldsFragmentDoc}
`;
export const TaxCodeSummaryFieldsFragmentDoc = gql`
  fragment TaxCodeSummaryFields on TaxCode {
    id
    code
    description
    rate
  }
`;
export const ExternalInsuranceFieldsFragmentDoc = gql`
  fragment ExternalInsuranceFields on ExternalInsurance {
    id
    externalId
    sourceId
    sourceSystem
    type
    startDate
    endDate
    required
    company
    policyNumber
    limit
  }
`;
export const PreferredVendorExternalVendorFieldsFragmentDoc = gql`
  fragment PreferredVendorExternalVendorFields on ExternalVendor {
    id
    sourceSystem
    insurances {
      ...ExternalInsuranceFields
    }
  }
  ${ExternalInsuranceFieldsFragmentDoc}
`;
export const OrgPreferredVendorsFieldsFragmentDoc = gql`
  fragment OrgPreferredVendorsFields on OrgPreferredVendor {
    id
    externalCode
    taxExempt
    defaultPaymentTerm
    defaultReleaseType {
      id
    }
    costCode {
      id
      ledgerAccount {
        externalId
      }
    }
    contacts {
      id
      name
      cellPhone
      cellPhoneExtension
      email
      receivesInvoiceNotifications
      receivesQuoteNotifications
      receivesBuyoutNotifications
      receivesOrderNotifications
    }
    sellerOrgLocation {
      id
      name
      phone
      address {
        ...AddressFields
      }
      org {
        id
        name
        photoUrl
      }
    }
    externalVendors {
      ...PreferredVendorExternalVendorFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${PreferredVendorExternalVendorFieldsFragmentDoc}
`;
export const BuyoutDistributorMessagesContextFieldsFragmentDoc = gql`
  fragment BuyoutDistributorMessagesContextFields on Buyout {
    id
    clientIdentifier
    quote {
      id
      preferredVendor {
        id
        contacts {
          id
          name
        }
      }
      rfq {
        id
        createdBy {
          id
          firstName
          lastName
          org {
            id
            name
          }
        }
      }
    }
  }
`;
export const BuyoutMessagesContextFieldsFragmentDoc = gql`
  fragment BuyoutMessagesContextFields on Buyout {
    id
    clientIdentifier
    quote {
      id
      preferredVendor {
        id
        contacts {
          id
          name
        }
        sellerOrgLocation {
          id
          org {
            id
            name
          }
        }
      }
      rfq {
        id
        createdBy {
          id
          firstName
          lastName
          org {
            id
            name
          }
        }
      }
    }
  }
`;
export const TagFieldsFragmentDoc = gql`
  fragment TagFields on ProjectTag {
    id
    name
    color
    deletedAt
    hasMapping
  }
`;
export const ZoneFieldsFragmentDoc = gql`
  fragment ZoneFields on Zone {
    id
    name
    inUse
  }
`;
export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on Asset {
    id
    url
    type
    context
    pages {
      url
      thumbnailUrl
    }
    thumbnailUrl
    createdAt
    isPrivate
  }
`;
export const InstructionFieldsFragmentDoc = gql`
  fragment InstructionFields on Instruction {
    text
    assets {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const SourceSystemMappingFieldsFragmentDoc = gql`
  fragment SourceSystemMappingFields on SourceSystemMapping {
    externalId
    sourceSystem
  }
`;
export const CostCodeFieldsFragmentDoc = gql`
  fragment CostCodeFields on CostCode {
    id
    code
    description
    inUse
    mappings {
      ...SourceSystemMappingFields
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
`;
export const UomFieldsFragmentDoc = gql`
  fragment UOMFields on UOM {
    id
    mnemonic
    singularDescription
    pluralDescription
    alternativeRefs
  }
`;
export const ProjectMaterialProductFieldsFragmentDoc = gql`
  fragment ProjectMaterialProductFields on Product {
    id
    name
    description
    imageUrl
    uom {
      ...UOMFields
    }
  }
  ${UomFieldsFragmentDoc}
`;
export const ManufacturerFieldsFragmentDoc = gql`
  fragment ManufacturerFields on Manufacturer {
    id
    name
  }
`;
export const ProjectMaterialSkuFieldsFragmentDoc = gql`
  fragment ProjectMaterialSkuFields on Sku {
    id
    name
    description
    imageUrl
    brand
    manufacturer {
      ...ManufacturerFields
    }
    product {
      id
      uom {
        ...UOMFields
      }
    }
  }
  ${ManufacturerFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const ProjectMaterialOrgCatalogSkuFieldsFragmentDoc = gql`
  fragment ProjectMaterialOrgCatalogSkuFields on OrgCatalogSku {
    id
    name
    customPartNumber
    sectionName
    defaultUom {
      ...UOMFields
    }
    defaultManufacturer {
      ...ManufacturerFields
    }
  }
  ${UomFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
`;
export const CostCodeSummaryFieldsFragmentDoc = gql`
  fragment CostCodeSummaryFields on CostCode {
    id
    code
    description
  }
`;
export const ProjectMaterialFieldsFragmentDoc = gql`
  fragment ProjectMaterialFields on OrgMaterial {
    id
    material {
      ... on Product {
        ...ProjectMaterialProductFields
      }
      ... on Sku {
        ...ProjectMaterialSkuFields
      }
      ... on OrgCatalogSku {
        ...ProjectMaterialOrgCatalogSkuFields
      }
    }
    costCode {
      ...CostCodeSummaryFields
    }
  }
  ${ProjectMaterialProductFieldsFragmentDoc}
  ${ProjectMaterialSkuFieldsFragmentDoc}
  ${ProjectMaterialOrgCatalogSkuFieldsFragmentDoc}
  ${CostCodeSummaryFieldsFragmentDoc}
`;
export const EstimatedItemFieldsFragmentDoc = gql`
  fragment EstimatedItemFields on EstimatedItem {
    id
    quantityDecimal
    orderedQuantity
    duplicateCount
    unitPrice
    notes
    zone {
      ...ZoneFields
    }
    tags {
      ...TagFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    position
    costCode {
      ...CostCodeFields
    }
    uom {
      ...UOMFields
    }
    sellerOrgLocation {
      id
    }
  }
  ${ZoneFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const ProjectItemFieldsFragmentDoc = gql`
  fragment ProjectItemFields on ProjectItem {
    id
    material {
      ...ProjectMaterialFields
    }
    estimateUom {
      ...UOMFields
    }
    estimatedItems {
      ...EstimatedItemFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    buyoutItems {
      id
    }
  }
  ${ProjectMaterialFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${EstimatedItemFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
`;
export const ReleaseIssueResolutionFieldsFragmentDoc = gql`
  fragment ReleaseIssueResolutionFields on ReleaseIssueResolution {
    id
    resolutionType
    comments
    redeliveryReleaseItem {
      id
      alternativeFulfillmentTime
      alternativeFulfillmentRelease {
        id
        sequenceNumber
      }
      deliveryDate
      release {
        id
        time
        sequenceNumber
      }
    }
  }
`;
export const ReleaseIssuesFieldsFragmentDoc = gql`
  fragment ReleaseIssuesFields on ReleaseIssue {
    id
    releaseItem {
      id
    }
    issueType
    description
    photos {
      ...AssetFields
    }
    quantityDecimal
    resolution {
      ...ReleaseIssueResolutionFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${ReleaseIssueResolutionFieldsFragmentDoc}
`;
export const BuyoutReleaseDetailsItemFieldsFragmentDoc = gql`
  fragment BuyoutReleaseDetailsItemFields on ReleaseItem {
    id
    tags {
      ...TagFields
    }
    zone {
      ...ZoneFields
    }
    quantityDecimal
    name
    unitPrice
    alternativeFulfillmentTime
    instructions {
      ...InstructionFields
    }
    alternativeFulfillmentRelease {
      id
      sequenceNumber
    }
    deliveryDate
    backorderFromRelease {
      id
      sequenceNumber
    }
    notes
    assets {
      ...AssetFields
    }
    redelivery {
      id
      issue {
        id
        releaseItem {
          id
          release {
            id
            time
            sequenceNumber
          }
        }
      }
    }
    costCode {
      ...CostCodeFields
    }
    buyoutItem {
      id
      quantityDecimal
      description
      unitPrice
    }
    projectItem {
      ...ProjectItemFields
    }
    issue {
      ...ReleaseIssuesFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    uom {
      ...UOMFields
    }
  }
  ${TagFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${ProjectItemFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const OrderTypeSummaryFieldsFragmentDoc = gql`
  fragment OrderTypeSummaryFields on ReleaseType {
    id
    name
    transactionKind
    requireDeliverySlip
    trackInventory
    includeAdditionalCharges
    poFormat
    default
    taxMode
    defaultAutoSync
  }
`;
export const BuyoutReleaseDetailsFieldsFragmentDoc = gql`
  fragment BuyoutReleaseDetailsFields on Release {
    id
    sequenceNumber
    status
    version
    items {
      ...BuyoutReleaseDetailsItemFields
    }
    poNumber
    type {
      ...OrderTypeSummaryFields
    }
  }
  ${BuyoutReleaseDetailsItemFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
`;
export const BuyoutReleaseItemFieldsFragmentDoc = gql`
  fragment BuyoutReleaseItemFields on ReleaseItem {
    id
    quantityDecimal
    zone {
      id
      name
    }
    tags {
      id
      name
      color
    }
    manufacturer {
      id
      name
      url
    }
  }
`;
export const AdditionalChargesFieldsFragmentDoc = gql`
  fragment AdditionalChargesFields on Charge {
    id
    description
    amount
  }
`;
export const BuyoutQuoteFieldsFragmentDoc = gql`
  fragment BuyoutQuoteFields on Quote {
    id
    additionalCharges {
      ...AdditionalChargesFields
    }
    notes
    expirationDate
    paymentTermDays
    discount {
      amount
      percentage
    }
    rfq {
      id
      clientIdentifier
      includeServices
      status
      createdBy {
        id
        firstName
        lastName
        photoUrl
      }
      quotes {
        id
        buyout {
          id
          clientIdentifier
          status
          sellerOrgLocation {
            id
            org {
              id
              name
              photoUrl
            }
          }
        }
      }
    }
  }
  ${AdditionalChargesFieldsFragmentDoc}
`;
export const ServicePriceFieldsFragmentDoc = gql`
  fragment ServicePriceFields on ServicePrice {
    id
    name
    productCategory
    type
    regularHoursUOM {
      ...UOMFields
    }
    regularHoursAmount
    offHoursUOM {
      ...UOMFields
    }
    offHoursAmount
  }
  ${UomFieldsFragmentDoc}
`;
export const BuyoutStockingPriceFieldsFragmentDoc = gql`
  fragment BuyoutStockingPriceFields on Buyout {
    id
    status
    version
    clientIdentifier
    subtotal
    createdAt
    additionalCharges {
      ...AdditionalChargesFields
    }
    createdBy {
      id
      firstName
      lastName
    }
    quote {
      ...BuyoutQuoteFields
    }
    project {
      id
      name
      status
      startDate
      address {
        ...AddressFields
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    poNumber
    releases {
      id
      status
      sequenceNumber
      time
    }
    includedServices
    servicePriceSheet {
      id
      prices {
        ...ServicePriceFields
      }
      discount {
        amount
        percentage
      }
    }
  }
  ${AdditionalChargesFieldsFragmentDoc}
  ${BuyoutQuoteFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${ServicePriceFieldsFragmentDoc}
`;
export const OrgLocationFieldsFragmentDoc = gql`
  fragment OrgLocationFields on OrgLocation {
    id
    name
    phone
    address {
      ...AddressFields
    }
    org {
      id
      name
      photoUrl
      contractorOrg {
        id
      }
    }
    contractorOrg {
      id
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const BuyoutsBuyoutFieldsFragmentDoc = gql`
  fragment BuyoutsBuyoutFields on Buyout {
    id
    clientIdentifier
    subtotal
    description
    createdAt
    deletedAt
    completedAmount
    requestedAmount
    scheduledAmount
    exportedAt
    poExists
    releases(
      status: [DRAFT, RECEIVED, PARTIALLY_RECEIVED, SCHEDULED, REQUESTED]
    ) {
      id
      deletedAt
    }
    status
    project {
      id
      name
      jobNumber
    }
    quote {
      id
      rfq {
        id
        status
        clientIdentifier
      }
    }
    preferredVendor {
      id
      org {
        id
        name
        photoUrl
      }
      contacts {
        ...VendorContactFields
        receivesQuoteNotifications
        receivesBuyoutNotifications
        receivesOrderNotifications
        receivesInvoiceNotifications
      }
    }
    sellerOrgLocation {
      ...OrgLocationFields
    }
    includedServices
    quoteDocument {
      id
    }
    releaseType {
      id
      name
    }
    poLink {
      id
      syncedAt
      createdBy {
        id
        firstName
        lastName
        email
      }
    }
  }
  ${VendorContactFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
`;
export const ReleaseDeliverySlipFieldsFragmentDoc = gql`
  fragment ReleaseDeliverySlipFields on DeliverySlip {
    id
    status
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
    reviewedAt
    archivedAt
    inventoryTransaction {
      id
    }
    asset {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const DistributorBuyoutReleaseFieldsFragmentDoc = gql`
  fragment DistributorBuyoutReleaseFields on Release {
    id
    sequenceNumber
    total
    time
    status
    version
    issues {
      ...ReleaseIssuesFields
    }
    includeServices
    deliverySlips {
      ...ReleaseDeliverySlipFields
    }
    deliveryPhotos {
      ...AssetFields
    }
  }
  ${ReleaseIssuesFieldsFragmentDoc}
  ${ReleaseDeliverySlipFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`;
export const DistributorBuyoutReleasesFieldsFragmentDoc = gql`
  fragment DistributorBuyoutReleasesFields on Buyout {
    id
    clientIdentifier
    status
    requestedAmount
    scheduledAmount
    completedAmount
    createdBy {
      id
      firstName
      lastName
      org {
        id
        name
        photoUrl
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
        email
        cellPhone
        receivesOrderNotifications
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    project {
      id
      name
      address {
        ...AddressFields
      }
      location {
        id
        name
        org {
          id
          name
          photoUrl
        }
      }
    }
    poNumber
    releases {
      ...DistributorBuyoutReleaseFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${DistributorBuyoutReleaseFieldsFragmentDoc}
`;
export const DistributorBuyoutStockingPriceFieldsFragmentDoc = gql`
  fragment DistributorBuyoutStockingPriceFields on Buyout {
    id
    status
    version
    clientIdentifier
    subtotal
    createdAt
    additionalCharges {
      id
      description
      amount
    }
    quote {
      ...BuyoutQuoteFields
    }
    releases {
      id
      status
      sequenceNumber
      time
    }
    paymentTermDays
    project {
      id
      name
      status
      startDate
      location {
        ...OrgLocationFields
      }
      address {
        ...AddressFields
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    poNumber
    includedServices
    servicePriceSheet {
      id
      prices {
        ...ServicePriceFields
      }
      discount {
        amount
        percentage
      }
    }
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
  }
  ${BuyoutQuoteFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${ServicePriceFieldsFragmentDoc}
`;
export const DistributorQuoteCommonFieldsFragmentDoc = gql`
  fragment DistributorQuoteCommonFields on Quote {
    id
    clientIdentifier
    status
    notes
    assets {
      ...AssetFields
    }
    expirationDate
    submittedDate
    paymentTermDays
    sellerOrgLocation {
      id
      org {
        id
        name
        photoUrl
      }
      name
      address {
        ...AddressFields
      }
    }
    rfq {
      id
      clientIdentifier
      includeServices
      status
      createdBy {
        id
        firstName
        lastName
        photoUrl
        org {
          id
          name
        }
      }
      project {
        id
        name
        startDate
        status
        location {
          ...OrgLocationFields
          org {
            id
            hqAddress {
              ...AddressFields
            }
          }
        }
        address {
          ...AddressFields
        }
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
        email
        cellPhone
        receivesQuoteNotifications
      }
    }
    previousRevision {
      id
      clientIdentifier
      url
    }
    nextRevision {
      id
      clientIdentifier
      url
    }
  }
  ${AssetFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
`;
export const DistributorCostCodeFieldsFragmentDoc = gql`
  fragment DistributorCostCodeFields on CostCode {
    id
    code
    description
  }
`;
export const DistributorProjectMaterialFieldsFragmentDoc = gql`
  fragment DistributorProjectMaterialFields on OrgMaterial {
    id
    material {
      ... on Product {
        ...ProjectMaterialProductFields
      }
      ... on Sku {
        ...ProjectMaterialSkuFields
      }
      ... on OrgCatalogSku {
        ...ProjectMaterialOrgCatalogSkuFields
      }
    }
    costCode {
      ...DistributorCostCodeFields
    }
  }
  ${ProjectMaterialProductFieldsFragmentDoc}
  ${ProjectMaterialSkuFieldsFragmentDoc}
  ${ProjectMaterialOrgCatalogSkuFieldsFragmentDoc}
  ${DistributorCostCodeFieldsFragmentDoc}
`;
export const DistributorProjectItemFieldsFragmentDoc = gql`
  fragment DistributorProjectItemFields on ProjectItem {
    id
    material {
      ...DistributorProjectMaterialFields
    }
    estimateUom {
      ...UOMFields
    }
  }
  ${DistributorProjectMaterialFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const DistributorQuoteItemGroupRfqItemFieldsFragmentDoc = gql`
  fragment DistributorQuoteItemGroupRfqItemFields on RfqItem {
    id
    description
    instructions {
      ...InstructionFields
    }
    projectItem {
      ...DistributorProjectItemFields
      material {
        ...DistributorProjectMaterialFields
        id
        material {
          ... on Product {
            id
            skus {
              id
              name
              imageUrl
              description
              manufacturer {
                ...ManufacturerFields
              }
            }
          }
          ... on Sku {
            id
            product {
              id
              skus {
                id
                name
                imageUrl
                description
                manufacturer {
                  ...ManufacturerFields
                }
              }
            }
          }
        }
      }
    }
    quantityDecimal
    manufacturer {
      ...ManufacturerFields
    }
    costCode {
      ...CostCodeFields
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${DistributorProjectItemFieldsFragmentDoc}
  ${DistributorProjectMaterialFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
`;
export const SkuFieldsFragmentDoc = gql`
  fragment SkuFields on Sku {
    id
    name
    description
    imageUrl
    brand
    manufacturer {
      ...ManufacturerFields
    }
    product {
      id
      categories {
        id
      }
    }
  }
  ${ManufacturerFieldsFragmentDoc}
`;
export const DistributorQuoteItemFieldsFragmentDoc = gql`
  fragment DistributorQuoteItemFields on QuoteItem {
    id
    unitPrice
    status
    expirationDate
    description
    notes
    assets {
      ...AssetFields
    }
    quantityDecimal
    leadTimeDays
    masterSku {
      ...SkuFields
    }
    orgCatalogSku {
      id
      name
      type
    }
    manufacturer {
      ...ManufacturerFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${SkuFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
`;
export const DistributorQuoteItemGroupFieldsFragmentDoc = gql`
  fragment DistributorQuoteItemGroupFields on QuoteItemGroup {
    rfqItem {
      ...DistributorQuoteItemGroupRfqItemFields
    }
    quoteItems {
      ...DistributorQuoteItemFields
    }
  }
  ${DistributorQuoteItemGroupRfqItemFieldsFragmentDoc}
  ${DistributorQuoteItemFieldsFragmentDoc}
`;
export const DistributorQuotesAuxiliaryQuoteItemFieldsFragmentDoc = gql`
  fragment DistributorQuotesAuxiliaryQuoteItemFields on AuxiliaryQuoteItem {
    id
    unitPrice
    manufacturer {
      ...ManufacturerFields
    }
    description
    notes
    expirationDate
    quantityDecimal
    assets {
      ...AssetFields
    }
    uom {
      ...UOMFields
    }
    leadTimeDays
  }
  ${ManufacturerFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const CurrencyFieldsFragmentDoc = gql`
  fragment CurrencyFields on Currency {
    id
    code
    name
    symbol
  }
`;
export const DistributorQuoteFieldsFragmentDoc = gql`
  fragment DistributorQuoteFields on Quote {
    ...DistributorQuoteCommonFields
    sellerOrgLocation {
      ...OrgLocationFields
    }
    discount {
      amount
      percentage
    }
    additionalCharges {
      ...AdditionalChargesFields
    }
    itemGroups {
      ...DistributorQuoteItemGroupFields
    }
    auxiliaryItems {
      ...DistributorQuotesAuxiliaryQuoteItemFields
    }
    rfq {
      id
      instructions {
        ...InstructionFields
      }
      project {
        id
        location {
          id
          org {
            id
            settings {
              display {
                enableManufacturers
                currency {
                  ...CurrencyFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${DistributorQuoteCommonFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${DistributorQuoteItemGroupFieldsFragmentDoc}
  ${DistributorQuotesAuxiliaryQuoteItemFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export const DistributorQuoteMessageContextFieldsFragmentDoc = gql`
  fragment DistributorQuoteMessageContextFields on Quote {
    id
    clientIdentifier
    rfq {
      id
      clientIdentifier
      createdBy {
        id
        firstName
        lastName
        org {
          id
          name
        }
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
      }
    }
  }
`;
export const DistributorBuyoutQuoteFieldsFragmentDoc = gql`
  fragment DistributorBuyoutQuoteFields on Quote {
    id
    additionalCharges {
      id
      description
      amount
    }
    notes
    expirationDate
    paymentTermDays
    discount {
      amount
      percentage
    }
    rfq {
      id
      clientIdentifier
      includeServices
      status
      createdBy {
        id
        firstName
        lastName
        photoUrl
      }
    }
  }
`;
export const DistributorBuyoutItemFieldsFragmentDoc = gql`
  fragment DistributorBuyoutItemFields on BuyoutItem {
    id
    quantityDecimal
    unitPrice
    requestedUnitPrice
    expirationDate
    notes
    isIncluded
    description
    position
    costCode {
      ...CostCodeFields
    }
    projectItem {
      ...DistributorProjectItemFields
    }
    rfqItem {
      id
      description
      instructions {
        ...InstructionFields
      }
      quantityDecimal
      projectItem {
        ...DistributorProjectItemFields
      }
    }
    orgCatalogSku {
      id
      name
    }
    manufacturer {
      ...ManufacturerFields
    }
    masterSku {
      id
      name
      manufacturer {
        ...ManufacturerFields
        url
      }
    }
    instructions {
      ...InstructionFields
    }
    assets {
      ...AssetFields
    }
    tags {
      ...TagFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
  ${DistributorProjectItemFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export const QuoteDocumentSummaryFieldsFragmentDoc = gql`
  fragment QuoteDocumentSummaryFields on QuoteDocument {
    id
    asset {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const DistributorBuyoutFieldsFragmentDoc = gql`
  fragment DistributorBuyoutFields on Buyout {
    id
    clientIdentifier
    additionalChargesAllowance
    customTaxAmount
    taxRate
    taxAmount
    subtotal
    createdAt
    status
    version
    includedServices
    instructions {
      ...InstructionFields
    }
    additionalCharges {
      ...AdditionalChargesFields
    }
    paymentTermDays
    notes
    poNumber
    releases {
      id
      status
      sequenceNumber
      time
    }
    quote {
      ...DistributorBuyoutQuoteFields
    }
    preferredVendor {
      id
      taxExempt
      contacts {
        id
        name
        email
        cellPhone
        receivesBuyoutNotifications
        receivesOrderNotifications
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    project {
      id
      name
      startDate
      status
      taxExempt
      location {
        ...OrgLocationFields
        org {
          id
          settings {
            display {
              enableManufacturers
              currency {
                ...CurrencyFields
              }
            }
          }
        }
      }
      address {
        ...AddressFields
      }
    }
    items {
      ...DistributorBuyoutItemFields
    }
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
    assets {
      ...AssetFields
    }
    quoteDocument {
      ...QuoteDocumentSummaryFields
    }
    netAmount
    taxCode {
      id
      code
      description
      rate
    }
    taxType
    releaseType {
      ...OrderTypeSummaryFields
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${DistributorBuyoutQuoteFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${DistributorBuyoutItemFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${QuoteDocumentSummaryFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
`;
export const DistributorDeliverySlipFieldsFragmentDoc = gql`
  fragment DistributorDeliverySlipFields on DeliverySlip {
    id
    archivedAt
    asset {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const DistributorReleaseItemFieldsFragmentDoc = gql`
  fragment DistributorReleaseItemFields on ReleaseItem {
    id
    zone {
      ...ZoneFields
    }
    name
    quantityDecimal
    receivedQuantityDecimal
    requestedQuantity @client
    unitPrice
    isIncluded
    position
    notes
    assets {
      ...AssetFields
    }
    instructions {
      ...InstructionFields
    }
    buyoutItem {
      id
      description
      quantityDecimal
      unitPrice
      notes
      manufacturer {
        id
        name
      }
    }
    projectItem {
      id
      material {
        ...DistributorProjectMaterialFields
      }
      estimateUom {
        ...UOMFields
      }
    }
    backorderFromRelease {
      id
      sequenceNumber
    }
    redelivery {
      id
      issue {
        id
        releaseItem {
          id
          quantityDecimal
          release {
            id
            time
            sequenceNumber
          }
        }
      }
    }
    manufacturer {
      ...ManufacturerFields
    }
    uom {
      ...UOMFields
    }
    requestedUom @client {
      ...UOMFields
    }
    alternativeFulfillmentTime
    alternativeFulfillmentQuantityDecimal
    alternativeFulfillmentRelease {
      id
      sequenceNumber
      time
    }
    pricePrefilled
    deliveryDate
    pricePrenegotiated
    issues {
      ...ReleaseIssuesFields
    }
    costCode {
      ...DistributorCostCodeFields
    }
    tags {
      ...TagFields
    }
    addedByVendor
    deliveryDateTBD
    taxable
  }
  ${ZoneFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${DistributorProjectMaterialFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
  ${DistributorCostCodeFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export const WarehouseFieldsFragmentDoc = gql`
  fragment WarehouseFields on Warehouse {
    id
    name
    address {
      ...AddressFields
    }
    externalCode
    archivedAt
  }
  ${AddressFieldsFragmentDoc}
`;
export const DistributorReleaseInvoiceFieldsFragmentDoc = gql`
  fragment DistributorReleaseInvoiceFields on Invoice {
    id
    number
    documents {
      id
      pageRange {
        start
        end
      }
      asset {
        ...AssetFields
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const DistributorReleaseFieldsFragmentDoc = gql`
  fragment DistributorReleaseFields on Release {
    id
    version
    createdAt
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
    submittedBy {
      id
      firstName
      lastName
    }
    buyout {
      ...DistributorBuyoutFields
    }
    preferredVendor {
      id
      taxExempt
      contacts {
        id
        name
        email
        cellPhone
        receivesBuyoutNotifications
        receivesOrderNotifications
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
      phone
    }
    project {
      id
      name
      jobNumber
      startDate
      status
      taxExempt
      location {
        ...OrgLocationFields
      }
      address {
        ...AddressFields
      }
      releases {
        id
        status
        sequenceNumber
        time
        buyout {
          id
          clientIdentifier
        }
      }
    }
    sequenceNumber
    total
    instructions {
      ...InstructionFields
    }
    poNumber
    includeServices
    status
    time
    timeTBD
    requestedTime @client
    deliverySlips {
      ...DistributorDeliverySlipFields
    }
    deliveryPhotos {
      ...AssetFields
    }
    taxRate
    taxAmount
    taxUnadjustedAmount
    customTaxAmount
    netAmount
    taxableNetAmount
    notes
    assets {
      ...AssetFields
    }
    quoteDocument {
      ...QuoteDocumentSummaryFields
    }
    paymentTerm
    additionalCharges {
      ...AdditionalChargesFields
    }
    items {
      ...DistributorReleaseItemFields
    }
    issues {
      ...ReleaseIssuesFields
    }
    warehouse {
      ...WarehouseFields
    }
    siteContact {
      id
      name
      phone
    }
    deliveryAddress {
      ...AddressFields
    }
    invoice {
      ...DistributorReleaseInvoiceFields
    }
    type {
      ...OrderTypeSummaryFields
    }
    orgLocation {
      id
      phone
      org {
        id
        name
        settings {
          display {
            enableManufacturers
            currency {
              ...CurrencyFields
            }
          }
        }
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    vendorContacts {
      ...VendorContactFields
    }
    taxCode {
      id
      code
      description
      rate
    }
    taxVariance
    quote {
      id
      rfq {
        id
        clientIdentifier
      }
    }
    updatedAt
  }
  ${DistributorBuyoutFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${DistributorDeliverySlipFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${QuoteDocumentSummaryFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${DistributorReleaseItemFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
  ${WarehouseFieldsFragmentDoc}
  ${DistributorReleaseInvoiceFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${VendorContactFieldsFragmentDoc}
`;
export const ExternalPoBaseFieldsFragmentDoc = gql`
  fragment ExternalPOBaseFields on ExternalPO {
    sourceSystem
    number
    externalId
    amount
    date
    project {
      id
      externalId
      name
      project {
        id
        location {
          id
        }
      }
    }
    vendor {
      id
      name
      orgPreferredVendors {
        id
        sellerOrgLocation {
          id
          name
          org {
            id
            photoUrl
          }
        }
      }
    }
    poLinks {
      id
      release {
        id
        sequenceNumber
        deletedAt
      }
    }
  }
`;
export const ExternalCostCodeFieldsFragmentDoc = gql`
  fragment ExternalCostCodeFields on ExternalCostCode {
    externalId
    sourceId
    code
    name
    costCode {
      id
    }
    collidesWith {
      id
      code
      description
    }
  }
`;
export const ExternalCostTypeFieldsFragmentDoc = gql`
  fragment ExternalCostTypeFields on ExternalCostType {
    externalId
    sourceId
    code
    name
    collidesWith {
      id
      code
      description
    }
  }
`;
export const ExternalPoItemFieldsFragmentDoc = gql`
  fragment ExternalPOItemFields on ExternalPOItem {
    sourceSystem
    externalId
    description
    quantity
    unitCost
    amount
    invoicedAmount
    remainingAmount
    uom
    notes
    externalCostCode {
      ...ExternalCostCodeFields
    }
    costCode {
      ...CostCodeFields
    }
    externalCostTypeId
    costType {
      ...ExternalCostTypeFields
    }
    wbsTag {
      ...TagFields
    }
  }
  ${ExternalCostCodeFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${ExternalCostTypeFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export const ExternalPoFieldsFragmentDoc = gql`
  fragment ExternalPOFields on ExternalPO {
    sourceSystem
    number
    date
    externalId
    description
    name
    releaseType {
      id
    }
    amount
    project {
      id
      externalId
      name
      project {
        id
        name
        location {
          id
        }
      }
    }
    vendor {
      id
      name
      orgPreferredVendors {
        id
        sellerOrgLocation {
          id
          name
          org {
            id
            photoUrl
          }
        }
      }
    }
    itemGroups {
      importable {
        ...ExternalPOItemFields
      }
      missingCostCode {
        ...ExternalPOItemFields
      }
      unsupportedCostType {
        ...ExternalPOItemFields
      }
      additionalCharges {
        ...ExternalPOItemFields
      }
      salesTax {
        ...ExternalPOItemFields
      }
      missingTag {
        ...ExternalPOItemFields
      }
      aggregatedItems {
        ...ExternalPOItemFields
      }
      taxCodeId
      importPossibility
      taxCode {
        id
        code
        description
      }
      taxAmount
      taxType
    }
    poLinks {
      id
      release {
        id
        sequenceNumber
        deletedAt
        sellerOrgLocation {
          id
        }
      }
    }
  }
  ${ExternalPoItemFieldsFragmentDoc}
`;
export const MasterProductsProductFieldsFragmentDoc = gql`
  fragment MasterProductsProductFields on Product {
    id
    name
    description
    imageUrl
    categories {
      id
      l1Name
    }
    uom {
      ...UOMFields
    }
  }
  ${UomFieldsFragmentDoc}
`;
export const MasterSkuSkuFieldsFragmentDoc = gql`
  fragment MasterSkuSkuFields on Sku {
    id
    name
    imageUrl
    description
  }
`;
export const MasterSkusByKeywordSkuFieldsFragmentDoc = gql`
  fragment MasterSkusByKeywordSkuFields on Sku {
    id
    name
    imageUrl
    description
    manufacturer {
      ...ManufacturerFields
    }
  }
  ${ManufacturerFieldsFragmentDoc}
`;
export const MasterSkusFacetFieldsFragmentDoc = gql`
  fragment MasterSkusFacetFields on Facet {
    id
    name
    values {
      id
      name
      value
      count
    }
  }
`;
export const MasterSkusProductFieldsFragmentDoc = gql`
  fragment MasterSkusProductFields on Product {
    id
    name
  }
`;
export const MasterSkusSkuFieldsFragmentDoc = gql`
  fragment MasterSkusSkuFields on Sku {
    id
    product {
      id
      name
      description
      imageUrl
      uom {
        ...UOMFields
      }
    }
    manufacturer {
      ...ManufacturerFields
      url
    }
    name
    imageUrl
    specsUrl
    manufacturerSkuUrl
    brand
  }
  ${UomFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
`;
export const MessageFieldsFragmentDoc = gql`
  fragment MessageFields on Message {
    id
    author {
      id
      firstName
      lastName
      email
      photoUrl
      org {
        id
        name
      }
    }
    isPrivate
    message
    timeSent
  }
`;
export const OrgsOrgLocationFieldsFragmentDoc = gql`
  fragment OrgsOrgLocationFields on OrgLocation {
    id
    name
    phone
    address {
      ...AddressFields
    }
    org {
      id
      name
      photoUrl
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const OrgFieldsFragmentDoc = gql`
  fragment OrgFields on Organization {
    id
    name
    locations {
      ...OrgsOrgLocationFields
    }
  }
  ${OrgsOrgLocationFieldsFragmentDoc}
`;
export const OrgSkuFieldsFragmentDoc = gql`
  fragment OrgSkuFields on OrgCatalogSku {
    id
    name
    customPartNumber
    sectionName
    materialCostCode
    defaultUom {
      ...UOMFields
    }
    type
    createdAt
    deletedAt
    equivalentsCount
    asSearchTerms
    mappedCategories {
      id
      l1Name
    }
  }
  ${UomFieldsFragmentDoc}
`;
export const PageInfoFieldsFragmentDoc = gql`
  fragment PageInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const PartialProjectItemReleaseItemFieldsFragmentDoc = gql`
  fragment PartialProjectItemReleaseItemFields on ReleaseItem {
    id
    tags {
      ...TagFields
    }
    zone {
      ...ZoneFields
    }
    name
    quantityDecimal
    buyoutItem {
      id
      buyout {
        id
        clientIdentifier
      }
    }
    uom {
      ...UOMFields
    }
    release {
      id
      status
      time
      sequenceNumber
      sellerOrgLocation {
        id
        org {
          id
          name
          photoUrl
        }
      }
    }
  }
  ${TagFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const PartialProjectItemFieldsFragmentDoc = gql`
  fragment PartialProjectItemFields on ProjectItem {
    id
    estimateUom {
      ...UOMFields
    }
    material {
      ...ProjectMaterialFields
    }
    estimatedItems {
      ...EstimatedItemFields
    }
    releaseItems(status: [REQUESTED, SCHEDULED, RECEIVED, PARTIALLY_RECEIVED]) {
      ...PartialProjectItemReleaseItemFields
    }
  }
  ${UomFieldsFragmentDoc}
  ${ProjectMaterialFieldsFragmentDoc}
  ${EstimatedItemFieldsFragmentDoc}
  ${PartialProjectItemReleaseItemFieldsFragmentDoc}
`;
export const ExternalEstimateFieldsFragmentDoc = gql`
  fragment ExternalEstimateFields on ExternalEstimate {
    externalId
    sourceId
    sourceSystem
    description
  }
`;
export const ExternalProjectWithEstimatesFieldsFragmentDoc = gql`
  fragment ExternalProjectWithEstimatesFields on ExternalProject {
    id
    sourceSystem
    estimates {
      ...ExternalEstimateFields
    }
  }
  ${ExternalEstimateFieldsFragmentDoc}
`;
export const ProjectExternalProjectsWithEstimatesFieldsFragmentDoc = gql`
  fragment ProjectExternalProjectsWithEstimatesFields on Project {
    id
    externalProjects {
      ...ExternalProjectWithEstimatesFields
    }
  }
  ${ExternalProjectWithEstimatesFieldsFragmentDoc}
`;
export const ProjectListOptionFieldsFragmentDoc = gql`
  fragment ProjectListOptionFields on Project {
    id
    name
    jobNumber
    status
    startDate
    location {
      id
      name
    }
    address {
      ...AddressFields
    }
    team {
      id
    }
    externalProjects {
      id
      sourceSystem
    }
    taxExempt
    preferredInventoryInflows
  }
  ${AddressFieldsFragmentDoc}
`;
export const ProjectPredictedPoNumberFieldsFragmentDoc = gql`
  fragment ProjectPredictedPoNumberFields on Project {
    id
    predictedPoNumber
  }
`;
export const QuoteMessageContextFieldsFragmentDoc = gql`
  fragment QuoteMessageContextFields on Quote {
    id
    clientIdentifier
    rfq {
      id
      clientIdentifier
      quotes {
        id
        preferredVendor {
          id
          contacts {
            id
            name
          }
          sellerOrgLocation {
            id
            org {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const ReleaseDistributorMessagesContextFieldsFragmentDoc = gql`
  fragment ReleaseDistributorMessagesContextFields on Release {
    id
  }
`;
export const ReleaseReassignmentFieldsFragmentDoc = gql`
  fragment ReleaseReassignmentFields on Release {
    id
    status
  }
`;
export const SiteContactFieldsFragmentDoc = gql`
  fragment SiteContactFields on SiteContact {
    id
    name
    phone
    role
    archivedAt
  }
`;
export const TagExtendedFieldsFragmentDoc = gql`
  fragment TagExtendedFields on ProjectTag {
    id
    name
    color
    deletedAt
    hasMapping
    mapping {
      externalCostCode {
        code
        name
      }
      externalCostType {
        code
      }
      sourceSystem
    }
  }
`;
export const InvoicedReleaseItemFieldsFragmentDoc = gql`
  fragment InvoicedReleaseItemFields on InvoicedReleaseItem {
    id
    quantity
    unitPrice
    quantitySoFar
    unexportedQuantitySoFar
  }
`;
export const ReleaseItemFieldsFragmentDoc = gql`
  fragment ReleaseItemFields on ReleaseItem {
    id
    tags {
      ...TagExtendedFields
    }
    zone {
      ...ZoneFields
    }
    name
    quantityDecimal
    receivedQuantityDecimal
    notes
    assets {
      ...AssetFields
    }
    unitPrice
    alternativeFulfillmentTime
    priceEstimated
    pricePrenegotiated
    isIncluded
    instructions {
      ...InstructionFields
    }
    alternativeFulfillmentRelease {
      id
      sequenceNumber
    }
    deliveryDate
    backorderFromRelease {
      id
      sequenceNumber
    }
    sellerOrgLocation {
      id
      address {
        id
        city
        state
      }
      org {
        id
        name
      }
    }
    sourceWarehouse {
      id
      name
    }
    redelivery {
      id
      issue {
        id
        releaseItem {
          id
          release {
            id
            time
            sequenceNumber
          }
        }
      }
    }
    buyoutItem {
      id
      quantityDecimal
      description
      unitPrice
      isIncluded
      releaseItems(
        status: [REQUESTED, SCHEDULED, RECEIVED, PARTIALLY_RECEIVED]
      ) {
        id
        release {
          id
        }
        quantityDecimal
        zone {
          ...ZoneFields
        }
        uom {
          ...UOMFields
        }
      }
    }
    projectItem {
      id
      material {
        ...ProjectMaterialFields
      }
      estimatedItems {
        ...EstimatedItemFields
      }
      estimateUom {
        ...UOMFields
      }
    }
    costCode {
      ...CostCodeFields
    }
    costType {
      id
      code
      description
    }
    issues {
      ...ReleaseIssuesFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    uom {
      ...UOMFields
    }
    position
    invoicedQuantity
    invoiceItems(invoiceId: $invoiceId, invoiceStatuses: $invoiceStatuses) {
      ...InvoicedReleaseItemFields
    }
    poItemLink {
      id
      poItemExternalId
    }
    invoicedRemainingAdjustment(excludedInvoiceId: $invoiceId)
    deliveryDateTBD
    taxable
  }
  ${TagExtendedFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${ProjectMaterialFieldsFragmentDoc}
  ${EstimatedItemFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${InvoicedReleaseItemFieldsFragmentDoc}
`;
export const NoteDocumentSummaryFieldsFragmentDoc = gql`
  fragment NoteDocumentSummaryFields on NoteDocument {
    id
    asset {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const PoLinkFieldsFragmentDoc = gql`
  fragment POLinkFields on POLink {
    id
    format
    sourceSystem
    externalWarehouseId
    syncing
    syncedVersion
    syncedAt
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    autoSync
    autoSyncError
    autoSyncFailureCount
    retroactive
    immutable
    failedAt
    failedMessage
    validationErrors
  }
`;
export const ReleaseApprovalSlotFieldsFragmentDoc = gql`
  fragment ReleaseApprovalSlotFields on ReleaseApprovalSlot {
    role
    approvals {
      ...ReleaseApprovalFields
    }
    ineffectual
  }
  ${ReleaseApprovalFieldsFragmentDoc}
`;
export const ReleaseApprovalProgressFieldsFragmentDoc = gql`
  fragment ReleaseApprovalProgressFields on ReleaseApprovalProgress {
    slots {
      ...ReleaseApprovalSlotFields
    }
    canApprove
    canApproveImmediately
    canReject
  }
  ${ReleaseApprovalSlotFieldsFragmentDoc}
`;
export const ProjectCostCodeFieldsFragmentDoc = gql`
  fragment ProjectCostCodeFields on CostCode {
    id
    code
    description
  }
`;
export const ReleaseGroupFieldsFragmentDoc = gql`
  fragment ReleaseGroupFields on ReleaseGroup {
    id
    name
    createdAt
  }
`;
export const ReleaseFieldsFragmentDoc = gql`
  fragment ReleaseFields on Release {
    id
    sequenceNumber
    deletedAt
    createdAt
    syncVersion
    total(allowIncomplete: true)
    receiptPostedAt
    poExists
    useSourceSystemPO
    receiptPostedBy {
      id
      firstName
      lastName
    }
    originalReassignedRelease {
      id
      reassignedReleases {
        ...ReleaseReassignmentFields
      }
    }
    buyout {
      id
      clientIdentifier
      createdAt
      poNumber
      poLink {
        id
      }
    }
    preferredVendor {
      id
      externalCode
      contacts {
        id
        name
        cellPhone
        email
        receivesOrderNotifications
      }
      taxExempt
    }
    sellerOrgLocation {
      id
      address {
        ...AddressFields
      }
      org {
        id
        name
        photoUrl
      }
      phone
    }
    project {
      id
      name
      jobNumber
      taxExempt
      address {
        ...AddressFields
      }
      location {
        id
      }
      team {
        id
      }
      permissions {
        report
      }
      preferredInventoryInflows
    }
    poNumber
    createdBy {
      id
      firstName
      lastName
    }
    submittedAt
    submittedBy {
      id
      firstName
      lastName
      photoUrl
    }
    rejectedAt
    rejectedBy {
      id
      firstName
      lastName
      photoUrl
    }
    rejectionReason
    permissions {
      edit
      submit
      submitDirectly
      approve
      archive
      cancel
    }
    siteContact {
      ...SiteContactFields
    }
    status
    time
    timeTBD
    items {
      ...ReleaseItemFields
    }
    issues {
      ...ReleaseIssuesFields
    }
    includeServices
    deliverySlips {
      ...ReleaseDeliverySlipFields
    }
    deliveryPhotos {
      ...AssetFields
    }
    instructions {
      ...InstructionFields
    }
    taxRate
    taxAmount
    taxUnadjustedAmount
    customTaxAmount
    taxVariance
    taxCode {
      id
      code
      description
      rate
    }
    taxType
    netAmount
    taxableNetAmount
    notes
    description
    assets {
      ...AssetFields
    }
    paymentTerm
    additionalCharges {
      ...AdditionalChargesFields
    }
    hasMissingCostCodes
    hasMissingExternalCodes
    warehouse {
      ...WarehouseFields
    }
    sourceWarehouse {
      ...WarehouseFields
    }
    deliveryAddress {
      ...AddressFields
    }
    quoteDocument {
      ...QuoteDocumentSummaryFields
    }
    quote {
      id
      rfq {
        id
        clientIdentifier
        status
      }
    }
    noteDocument {
      ...NoteDocumentSummaryFields
    }
    version
    exportedAt
    exportedVersion
    poLink {
      ...POLinkFields
    }
    reassignedReleases {
      ...ReleaseReassignmentFields
    }
    invoices {
      id
      status
      number
      vendorDuplicate
      orgDuplicate
      total
      documents {
        id
        asset {
          ...AssetFields
        }
      }
      subtotal
      chargesAmount
      taxAmount
      archivedAt
    }
    receipts {
      id
      number
      createdAt
      prePaid
      createdBy {
        id
        firstName
        lastName
      }
      documents {
        id
        asset {
          ...AssetFields
        }
      }
    }
    requiresInventoryReceipt
    approvals {
      ...ReleaseApprovalFields
    }
    approvalProgress {
      ...ReleaseApprovalProgressFields
    }
    type {
      ...OrderTypeSummaryFields
      costType {
        id
        description
      }
    }
    vendorContacts {
      ...VendorContactFields
    }
    orgLocation {
      id
      phone
      address {
        ...AddressFields
      }
      org {
        id
        photoUrl
        name
      }
    }
    costCode {
      ...ProjectCostCodeFields
    }
    zone {
      ...ZoneFields
    }
    groups {
      ...ReleaseGroupFields
    }
    watchers {
      id
      firstName
      lastName
    }
    complianceGroup {
      id
    }
    updatedAt
  }
  ${ReleaseReassignmentFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${SiteContactFieldsFragmentDoc}
  ${ReleaseItemFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
  ${ReleaseDeliverySlipFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${WarehouseFieldsFragmentDoc}
  ${QuoteDocumentSummaryFieldsFragmentDoc}
  ${NoteDocumentSummaryFieldsFragmentDoc}
  ${PoLinkFieldsFragmentDoc}
  ${ReleaseApprovalFieldsFragmentDoc}
  ${ReleaseApprovalProgressFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${VendorContactFieldsFragmentDoc}
  ${ProjectCostCodeFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
  ${ReleaseGroupFieldsFragmentDoc}
`;
export const ReleaseMessagesContextFieldsFragmentDoc = gql`
  fragment ReleaseMessagesContextFields on Release {
    id
  }
`;
export const ReleasePartialFieldsFragmentDoc = gql`
  fragment ReleasePartialFields on Release {
    id
    sequenceNumber
    poNumber
    description
    syncVersion
    poExists
    isInvoiced
    useSourceSystemPO
    submittedBy {
      id
      firstName
      lastName
      photoUrl
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    rejectedBy {
      id
      firstName
      lastName
      photoUrl
    }
    rejectionReason
    permissions {
      edit
      submit
      submitDirectly
      approve
      archive
    }
    siteContact {
      id
      name
      phone
    }
    buyout {
      id
      clientIdentifier
      createdAt
    }
    preferredVendor {
      id
      externalCode
      contacts {
        id
        name
        receivesOrderNotifications
      }
    }
    project {
      id
      name
      jobNumber
      location {
        id
      }
    }
    sellerOrgLocation {
      id
      address {
        ...AddressFields
      }
      org {
        id
        photoUrl
        name
      }
    }
    status
    time
    timeTBD
    version
    includeServices
    deletedAt
    taxRate
    instructions {
      ...InstructionFields
    }
    issues {
      id
    }
    total
    hasMissingCostCodes
    exportedAt
    exportedVersion
    poLink {
      ...POLinkFields
    }
    receiptPostedAt
    requiresInventoryReceipt
    isBackorder
    backorderFrom {
      id
      sequenceNumber
    }
    quoteDocument {
      ...QuoteDocumentSummaryFields
    }
    approvalProgress {
      ...ReleaseApprovalProgressFields
    }
    approvals {
      ...ReleaseApprovalFields
    }
    type {
      id
      name
      transactionKind
      requireDeliverySlip
      trackInventory
    }
    deliverySlips {
      id
      archivedAt
    }
    noteDocument {
      id
    }
    invoices {
      id
      number
      issueDate
      archivedAt
    }
    receipts {
      id
      number
      issueDate
      archivedAt
    }
    itemCount
    groups {
      id
      name
    }
    warehouse {
      id
      name
    }
    sourceWarehouse {
      id
      name
    }
  }
  ${AddressFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${PoLinkFieldsFragmentDoc}
  ${QuoteDocumentSummaryFieldsFragmentDoc}
  ${ReleaseApprovalProgressFieldsFragmentDoc}
  ${ReleaseApprovalFieldsFragmentDoc}
`;
export const ProjectMaterialExtendedFieldsFragmentDoc = gql`
  fragment ProjectMaterialExtendedFields on OrgMaterial {
    ...ProjectMaterialFields
  }
  ${ProjectMaterialFieldsFragmentDoc}
`;
export const ProjectFieldsFragmentDoc = gql`
  fragment ProjectFields on Project {
    id
    name
    status
    budget
    deletedAt
    startDate
    percentageOfBudgetSpent
    jobNumber
    taxExempt
    estimatedItemGroups {
      costCode {
        ...CostCodeFields
      }
      estimatedItemCount
    }
    team {
      id
      firstName
      lastName
      email
      photoUrl
    }
    address {
      ...AddressFields
    }
    location {
      ...OrgLocationFields
      org {
        id
        costCodes {
          ...CostCodeFields
        }
      }
    }
    items {
      id
      material {
        ...ProjectMaterialExtendedFields
      }
      manufacturer {
        ...ManufacturerFields
      }
      estimateUom {
        ...UOMFields
      }
      estimatedItems {
        id
      }
      buyoutItems {
        id
      }
    }
    zones {
      ...ZoneFields
    }
    mappings {
      ...SourceSystemMappingFields
    }
    permissions {
      edit
      createTag
      report
    }
    poJobNumber
    restrictCostCodes
    usesProjectCostCodes
    preferredInventoryInflows
  }
  ${CostCodeFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${ProjectMaterialExtendedFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
  ${SourceSystemMappingFieldsFragmentDoc}
`;
export const AggregatedItemFieldsFragmentDoc = gql`
  fragment AggregatedItemFields on AggregatedItem {
    projectItem {
      id
    }
    estimatedItems {
      id
      quantityDecimal
      orderedQuantity
      duplicateCount
      unitPrice
      manufacturer {
        id
      }
      tags {
        id
      }
      position
      sellerOrgLocation {
        id
      }
      notes
      uom {
        id
      }
    }
    buyoutItems {
      id
    }
  }
`;
export const AggregatedCostCodeFieldsFragmentDoc = gql`
  fragment AggregatedCostCodeFields on AggregatedCostCode {
    costCode {
      id
    }
    items {
      ...AggregatedItemFields
    }
  }
  ${AggregatedItemFieldsFragmentDoc}
`;
export const AggregatedZoneFieldsFragmentDoc = gql`
  fragment AggregatedZoneFields on AggregatedZone {
    zone {
      ...ZoneFields
    }
    costCodes {
      ...AggregatedCostCodeFields
    }
  }
  ${ZoneFieldsFragmentDoc}
  ${AggregatedCostCodeFieldsFragmentDoc}
`;
export const ExternalProjectEssentialFieldsFragmentDoc = gql`
  fragment ExternalProjectEssentialFields on ExternalProject {
    id
    sourceSystem
  }
`;
export const ProjectExtendedFieldsFragmentDoc = gql`
  fragment ProjectExtendedFields on Project {
    ...ProjectFields
    releaseInstructions {
      ...InstructionFields
    }
    aggregatedZones(excludePhantoms: $excludePhantoms) {
      ...AggregatedZoneFields
    }
    mappings {
      ...SourceSystemMappingFields
    }
    externalProjects {
      ...ExternalProjectEssentialFields
    }
    costCodes {
      ...ProjectCostCodeFields
    }
    usesProjectCostCodes
  }
  ${ProjectFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${AggregatedZoneFieldsFragmentDoc}
  ${SourceSystemMappingFieldsFragmentDoc}
  ${ExternalProjectEssentialFieldsFragmentDoc}
  ${ProjectCostCodeFieldsFragmentDoc}
`;
export const EstimatedItemExtendedFieldsFragmentDoc = gql`
  fragment EstimatedItemExtendedFields on EstimatedItem {
    ...EstimatedItemFields
  }
  ${EstimatedItemFieldsFragmentDoc}
`;
export const ProjectItemExtendedFieldsFragmentDoc = gql`
  fragment ProjectItemExtendedFields on ProjectItem {
    ...ProjectItemFields
    material {
      ...ProjectMaterialExtendedFields
    }
    estimatedItems {
      ...EstimatedItemExtendedFields
    }
  }
  ${ProjectItemFieldsFragmentDoc}
  ${ProjectMaterialExtendedFieldsFragmentDoc}
  ${EstimatedItemExtendedFieldsFragmentDoc}
`;
export const ReleaseProjectItemFieldsFragmentDoc = gql`
  fragment ReleaseProjectItemFields on ProjectItem {
    ...ProjectItemExtendedFields
    buyoutItems {
      id
      expirationDate
      instructions {
        ...InstructionFields
      }
      description
      quantityDecimal
      buyout {
        id
        clientIdentifier
        sellerOrgLocation {
          id
          org {
            id
            photoUrl
          }
        }
      }
    }
  }
  ${ProjectItemExtendedFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
`;
export const ReleaseProjectFieldsFragmentDoc = gql`
  fragment ReleaseProjectFields on Project {
    ...ProjectExtendedFields
    items {
      ...ReleaseProjectItemFields
    }
  }
  ${ProjectExtendedFieldsFragmentDoc}
  ${ReleaseProjectItemFieldsFragmentDoc}
`;
export const ReleaseSelectorFieldsFragmentDoc = gql`
  fragment ReleaseSelectorFields on Release {
    id
    sequenceNumber
    poNumber
    time
    netAmount
    total
    status
    version
    project {
      id
      name
      jobNumber
    }
    buyout {
      id
      clientIdentifier
    }
    sellerOrgLocation {
      id
      org {
        id
        photoUrl
        name
      }
    }
    type {
      ...OrderTypeSummaryFields
    }
    additionalCharges {
      ...AdditionalChargesFields
    }
    taxRate
    taxAmount
    customTaxAmount
    taxVariance
    taxCode {
      id
      code
      description
      rate
    }
    taxType
  }
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
`;
export const RfqItemFieldsFragmentDoc = gql`
  fragment RfqItemFields on RfqItem {
    id
    description
    instructions {
      ...InstructionFields
    }
    quantityDecimal
    projectItem {
      ...ProjectItemFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    costCode {
      ...CostCodeFields
    }
    position
  }
  ${InstructionFieldsFragmentDoc}
  ${ProjectItemFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
`;
export const BuyoutItemReleaseItemFieldsFragmentDoc = gql`
  fragment BuyoutItemReleaseItemFields on ReleaseItem {
    id
    release {
      id
      status
      sequenceNumber
      time
      sellerOrgLocation {
        id
        name
        org {
          id
          name
          photoUrl
        }
      }
    }
    quantityDecimal
    unitPrice
    uom {
      ...UOMFields
    }
    zone {
      ...ZoneFields
    }
  }
  ${UomFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
`;
export const BuyoutItemFieldsFragmentDoc = gql`
  fragment BuyoutItemFields on BuyoutItem {
    id
    quantityDecimal
    unitPrice
    requestedUnitPrice
    releasedAmount
    completedAmount
    expirationDate
    notes
    assets {
      ...AssetFields
    }
    isIncluded
    instructions {
      ...InstructionFields
    }
    position
    description
    projectItem {
      ...ProjectItemFields
    }
    rfqItem {
      id
      description
      quantityDecimal
    }
    orgCatalogSku {
      id
      name
    }
    manufacturer {
      ...ManufacturerFields
    }
    masterSku {
      id
      name
      manufacturer {
        ...ManufacturerFields
        url
      }
    }
    costCode {
      ...CostCodeFields
    }
    releaseItems(status: [REQUESTED, SCHEDULED, RECEIVED, PARTIALLY_RECEIVED]) {
      ...BuyoutItemReleaseItemFields
    }
    tags {
      ...TagFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${ProjectItemFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${BuyoutItemReleaseItemFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export const VendorPriceFieldsFragmentDoc = gql`
  fragment VendorPriceFields on VendorPrice {
    id
    orgMaterialId
    sellerOrgLocation {
      id
    }
    uom {
      id
    }
    manufacturer {
      id
    }
    price
    expirationDate
    orderIncrement
    minimumOrder
    leadTime
  }
`;
export const ExternalItemFieldsFragmentDoc = gql`
  fragment ExternalItemFields on ExternalItem {
    id
    externalId
    name
    purchasingUom
    defaultCostCode
    sourceSystem
    sourceId
  }
`;
export const OrgMaterialFieldsFragmentDoc = gql`
  fragment OrgMaterialFields on OrgMaterial {
    id
    material {
      ... on Product {
        ...ProjectMaterialProductFields
      }
      ... on Sku {
        ...ProjectMaterialSkuFields
      }
      ... on OrgCatalogSku {
        ...ProjectMaterialOrgCatalogSkuFields
      }
    }
    defaultEstimateUom {
      ...UOMFields
    }
    externalCode
    referenceCounts {
      estimatedItems
      rfqItems
      buyoutItems
      releaseItems
    }
    costCode {
      ...CostCodeFields
    }
    vendorPrices {
      ...VendorPriceFields
    }
    externalItems {
      ...ExternalItemFields
    }
  }
  ${ProjectMaterialProductFieldsFragmentDoc}
  ${ProjectMaterialSkuFieldsFragmentDoc}
  ${ProjectMaterialOrgCatalogSkuFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${VendorPriceFieldsFragmentDoc}
  ${ExternalItemFieldsFragmentDoc}
`;
export const BuyoutStandaloneReleaseItemFieldsFragmentDoc = gql`
  fragment BuyoutStandaloneReleaseItemFields on BuyoutStandaloneReleaseItem {
    totalQuantity
    unitPrice
    orderedSoFar
    receivedSoFar
    costCode {
      ...CostCodeFields
    }
    uom {
      ...UOMFields
    }
    orgMaterial {
      ...OrgMaterialFields
    }
    releaseItems {
      ...BuyoutItemReleaseItemFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${OrgMaterialFieldsFragmentDoc}
  ${BuyoutItemReleaseItemFieldsFragmentDoc}
`;
export const BuyoutFieldsFragmentDoc = gql`
  fragment BuyoutFields on Buyout {
    id
    clientIdentifier
    instructions {
      ...InstructionFields
    }
    subtotal
    poExists
    description
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    status
    releases {
      id
      status
    }
    quote {
      ...BuyoutQuoteFields
    }
    quoteDocument {
      ...QuoteDocumentSummaryFields
    }
    preferredVendor {
      id
      contacts {
        id
        name
        cellPhone
        email
        receivesBuyoutNotifications
      }
      taxExempt
    }
    releaseType {
      ...OrderTypeSummaryFields
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    project {
      id
      name
      status
      startDate
      jobNumber
      taxExempt
      address {
        ...AddressFields
      }
      location {
        id
      }
      permissions {
        report
      }
    }
    items {
      ...BuyoutItemFields
    }
    standaloneReleaseItems {
      ...BuyoutStandaloneReleaseItemFields
    }
    includedServices
    poNumber
    version
    additionalCharges {
      ...AdditionalChargesFields
    }
    paymentTermDays
    notes
    taxAmount
    taxRate
    netAmount
    taxCode {
      id
      code
      description
      rate
    }
    taxType
    customTaxAmount
    additionalChargesAllowance
    assets {
      ...AssetFields
    }
    poLink {
      ...POLinkFields
    }
    exportedAt
    vendorContacts {
      ...VendorContactFields
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${BuyoutQuoteFieldsFragmentDoc}
  ${QuoteDocumentSummaryFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${BuyoutItemFieldsFragmentDoc}
  ${BuyoutStandaloneReleaseItemFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${PoLinkFieldsFragmentDoc}
  ${VendorContactFieldsFragmentDoc}
`;
export const RfqFieldsFragmentDoc = gql`
  fragment RfqFields on Rfq {
    id
    createdAt
    clientIdentifier
    includeServices
    status
    deletedAt
    project {
      ...ProjectFields
      aggregatedZones {
        ...AggregatedZoneFields
      }
      externalProjects {
        ...ExternalProjectEssentialFields
      }
    }
    instructions {
      ...InstructionFields
    }
    requisition {
      id
    }
    items {
      ...RfqItemFields
    }
    targets {
      id
      sellerOrgLocation {
        id
      }
      contacts {
        id
      }
    }
    nextRevision {
      id
      clientIdentifier
      status
    }
    quotes {
      id
      status
      buyout {
        ...BuyoutFields
      }
    }
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
    noteDocument {
      ...NoteDocumentSummaryFields
    }
    description
    permissions {
      archive
      edit
      submit
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${AggregatedZoneFieldsFragmentDoc}
  ${ExternalProjectEssentialFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${RfqItemFieldsFragmentDoc}
  ${BuyoutFieldsFragmentDoc}
  ${NoteDocumentSummaryFieldsFragmentDoc}
`;
export const QuoteStockingPriceFieldsFragmentDoc = gql`
  fragment QuoteStockingPriceFields on Quote {
    id
    servicePriceSheet {
      id
      prices {
        ...ServicePriceFields
      }
      discount {
        amount
        percentage
      }
    }
  }
  ${ServicePriceFieldsFragmentDoc}
`;
export const RfqQuotesPreferredVendorFieldsFragmentDoc = gql`
  fragment RfqQuotesPreferredVendorFields on OrgPreferredVendor {
    id
    contacts {
      id
      name
      receivesQuoteNotifications
    }
    sellerOrgLocation {
      name
      id
      address {
        ...AddressFields
      }
      org {
        id
        name
        photoUrl
      }
    }
    taxExempt
  }
  ${AddressFieldsFragmentDoc}
`;
export const RfqQuotesQuoteItemFieldsFragmentDoc = gql`
  fragment RfqQuotesQuoteItemFields on QuoteItem {
    id
    unitPrice
    status
    manufacturer {
      ...ManufacturerFields
    }
    masterSku {
      id
      name
      manufacturer {
        ...ManufacturerFields
      }
    }
    assets {
      ...AssetFields
    }
    description
    notes
    expirationDate
    quantityDecimal
    orgCatalogSku {
      id
      name
    }
    leadTimeDays
  }
  ${ManufacturerFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`;
export const RfqQuotesQuoteItemGroupFieldsFragmentDoc = gql`
  fragment RfqQuotesQuoteItemGroupFields on QuoteItemGroup {
    rfqItem {
      id
    }
    quoteItems {
      ...RfqQuotesQuoteItemFields
    }
  }
  ${RfqQuotesQuoteItemFieldsFragmentDoc}
`;
export const RfqQuotesAuxiliaryQuoteItemFieldsFragmentDoc = gql`
  fragment RfqQuotesAuxiliaryQuoteItemFields on AuxiliaryQuoteItem {
    id
    unitPrice
    manufacturer {
      ...ManufacturerFields
    }
    description
    notes
    expirationDate
    quantityDecimal
    uom {
      ...UOMFields
    }
    assets {
      ...AssetFields
    }
    leadTimeDays
  }
  ${ManufacturerFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`;
export const RfqQuotesQuoteFieldsFragmentDoc = gql`
  fragment RfqQuotesQuoteFields on Quote {
    ...QuoteStockingPriceFields
    id
    expirationDate
    notes
    assets {
      ...AssetFields
    }
    status
    paymentTermDays
    discount {
      amount
      percentage
    }
    sellerOrgLocation {
      id
      org {
        id
        name
        photoUrl
      }
    }
    preferredVendor {
      ...RfqQuotesPreferredVendorFields
    }
    itemGroups {
      ...RfqQuotesQuoteItemGroupFields
    }
    auxiliaryItems {
      ...RfqQuotesAuxiliaryQuoteItemFields
    }
    additionalCharges {
      ...AdditionalChargesFields
    }
    buyout {
      id
      clientIdentifier
    }
    submittedDate
  }
  ${QuoteStockingPriceFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${RfqQuotesPreferredVendorFieldsFragmentDoc}
  ${RfqQuotesQuoteItemGroupFieldsFragmentDoc}
  ${RfqQuotesAuxiliaryQuoteItemFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
`;
export const RfqQuotesFieldsFragmentDoc = gql`
  fragment RfqQuotesFields on Rfq {
    id
    includeServices
    clientIdentifier
    status
    project {
      id
      name
      jobNumber
      location {
        ...OrgLocationFields
      }
      permissions {
        report
      }
      taxExempt
    }
    nextRevision {
      id
    }
    targets {
      id
      sellerOrgLocation {
        id
      }
    }
    items {
      ...RfqItemFields
    }
    quotes(status: $quoteStatus) {
      ...RfqQuotesQuoteFields
    }
    instructions {
      ...InstructionFields
    }
    noteDocument {
      id
    }
    permissions {
      archive
      edit
      exportQuotes
    }
  }
  ${OrgLocationFieldsFragmentDoc}
  ${RfqItemFieldsFragmentDoc}
  ${RfqQuotesQuoteFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
`;
export const RfqVendorFieldsFragmentDoc = gql`
  fragment RfqVendorFields on Quote {
    id
    clientIdentifier
    status
    sellerOrgLocation {
      id
      name
      address {
        ...AddressFields
      }
      org {
        id
        name
        photoUrl
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
        email
        cellPhone
        receivesQuoteNotifications
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const RfqsRfqFieldsFragmentDoc = gql`
  fragment RfqsRfqFields on Rfq {
    id
    clientIdentifier
    deletedAt
    project {
      id
      name
      status
      jobNumber
    }
    items {
      id
      rfqId
      quantityDecimal
    }
    status
    quotes(status: $quoteStatus) {
      id
      status
      buyout {
        id
        clientIdentifier
        poNumber
      }
      releases {
        id
        sequenceNumber
        poNumber
        status
        deletedAt
      }
      submittedDate
    }
    createdAt
    numBiddersRfqSentTo
    numBiddersRfqViewedBy
    nextRevision {
      id
      clientIdentifier
    }
    previousRevision {
      id
      clientIdentifier
    }
    noteDocument {
      id
    }
    description
    permissions {
      archive
      edit
      submit
    }
  }
`;
export const SmsVerificationFieldsFragmentDoc = gql`
  fragment SMSVerificationFields on SMSVerification {
    id
    remainingDeliveries
    remainingAttempts
    completedAt
  }
`;
export const AccountingSettingsFieldsFragmentDoc = gql`
  fragment AccountingSettingsFields on AccountingSettings {
    id
    toJobLedgerAccount
    additionalChargesCostCode {
      id
      code
      description
    }
    taxAuthorities {
      code
      rate
    }
    distributeAdditionalCharges
    materialCostType
    enabled
    integration
    postInventoryReceipts
    inventoryReceiptLedgerAccount
  }
`;
export const ProgressFieldsFragmentDoc = gql`
  fragment ProgressFields on IntegrationMappingProgress {
    costCodes {
      mapped
      total
    }
    orgMaterials {
      mapped
      total
    }
    projects {
      mapped
      total
    }
    vendors {
      mapped
      total
    }
    releaseTypes {
      mapped
      total
    }
    costTypes {
      mapped
      total
    }
  }
`;
export const SourceSystemSettingFieldsFragmentDoc = gql`
  fragment SourceSystemSettingFields on SourceSystemSettings {
    system
    companyName
    companyId
    connected
    postInventoryReceipts
    wbsTags
    autoExportApprovedInvoices
    autoPostPOs
    inventoryReceiptLedgerAccount {
      externalId
      name
    }
    poNameTemplate
    poDescriptionTemplate
    invoiceDescriptionTemplate
    customPOTemplates {
      name
      template
    }
    customInvoiceTemplates {
      name
      template
    }
  }
`;
export const ExternalLedgerSubaccountFieldsFragmentDoc = gql`
  fragment ExternalLedgerSubaccountFields on ExternalLedgerSubaccount {
    externalId
    sourceId
    name
    number
  }
`;
export const ExternalLedgerAccountFieldsFragmentDoc = gql`
  fragment ExternalLedgerAccountFields on ExternalLedgerAccount {
    externalId
    sourceId
    name
    number
    subaccounts {
      ...ExternalLedgerSubaccountFields
    }
  }
  ${ExternalLedgerSubaccountFieldsFragmentDoc}
`;
export const SourceSystemSettingExtendedFieldsFragmentDoc = gql`
  fragment SourceSystemSettingExtendedFields on SourceSystemSettings {
    ...SourceSystemSettingFields
    accountsPayableLedgerAccount {
      ...ExternalLedgerAccountFields
    }
    toJobLedgerAccount {
      ...ExternalLedgerAccountFields
    }
    toWarehouseLedgerAccount {
      ...ExternalLedgerAccountFields
    }
    materialCostType {
      ...ExternalCostTypeFields
    }
    defaultAutoSync
    progress {
      ...ProgressFields
    }
    postInventoryReceipts
    autoPostPOs
    autoPostInvoices
    autoExportApprovedInvoices
    autoCloseProjectsByJobStatus
  }
  ${SourceSystemSettingFieldsFragmentDoc}
  ${ExternalLedgerAccountFieldsFragmentDoc}
  ${ExternalCostTypeFieldsFragmentDoc}
  ${ProgressFieldsFragmentDoc}
`;
export const IntegrationSettingsExtendedFieldsFragmentDoc = gql`
  fragment IntegrationSettingsExtendedFields on IntegrationSettings {
    org {
      id
    }
    accounting {
      ...AccountingSettingsFields
      progress {
        ...ProgressFields
      }
    }
    sourceSystems {
      ...SourceSystemSettingExtendedFields
    }
  }
  ${AccountingSettingsFieldsFragmentDoc}
  ${ProgressFieldsFragmentDoc}
  ${SourceSystemSettingExtendedFieldsFragmentDoc}
`;
export const OrgSettingsFieldsFragmentDoc = gql`
  fragment OrgSettingsFields on OrgSettings {
    releaseApproval {
      enabled
      standaloneOnly
      sourceWarehouse
      reserved
      exemptRoles
      approverRoles
      submitterRoles
      requiredApprovals
      requiredApproverRoles
    }
    releases {
      poNumbering
      notifications
      useSourceSystemPOs
      poNumberSuffixPreview
      defaultWarehouse {
        id
        name
      }
      instructions {
        ...InstructionFields
      }
    }
    invoices {
      localPart
      domain
      approval {
        roles {
          role
          maxAmount
          notificationThreshold
        }
      }
      defaultPaymentTerm
      requireCostCodesDuringApproval
    }
    integrations {
      ...IntegrationSettingsExtendedFields
    }
    display {
      enableManufacturers
      itemCostTypes
      enableAdditionalCharges
      requireProjectAssignment
      enableTaxVariance
      defaultItemized
      receiptDefaultItemized
      usesProjectCostCodes
      currency {
        ...CurrencyFields
      }
      appendExternalCode
    }
    inventory {
      enabled
    }
    projectSettings {
      defaultTeam {
        orgLocation {
          id
        }
        users {
          id
        }
      }
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${IntegrationSettingsExtendedFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export const LedgerAccountFieldsFragmentDoc = gql`
  fragment LedgerAccountFields on ExternalLedgerAccount {
    externalId
    sourceId
    sourceSystem
    name
    number
  }
`;
export const CostTypeFieldsFragmentDoc = gql`
  fragment CostTypeFields on CostType {
    id
    org {
      id
    }
    code
    description
    mappings {
      ...SourceSystemMappingFields
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
`;
export const ViewerOrgLocationFieldsFragmentDoc = gql`
  fragment ViewerOrgLocationFields on OrgLocation {
    id
    name
    address {
      ...AddressFields
    }
    permissions {
      createProject
      createInvoice
      createDeliverySlip
      createQuoteDocument
      submitReleaseDirectly
      createRelease
      createBuyout
      createRfq
      reserveRelease
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export const StartupDataFieldsFragmentDoc = gql`
  fragment StartupDataFields on User {
    id
    enterprise {
      id
      name
      orgs {
        id
        name
        notificationCount(limit: 10)
      }
    }
    org {
      id
      settings {
        ...OrgSettingsFields
      }
      costCodes {
        ...CostCodeFields
        ledgerAccount {
          ...LedgerAccountFields
        }
      }
      costTypes {
        ...CostTypeFields
      }
      uoms {
        ...UOMFields
      }
      warehouses {
        ...WarehouseFields
      }
      invoiceFolders {
        id
        name
        createdAt
      }
      releaseGroups {
        id
        name
        createdAt
      }
      locations {
        ...ViewerOrgLocationFields
      }
      complianceGroups {
        id
        code
        description
        notes
      }
    }
  }
  ${OrgSettingsFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${LedgerAccountFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${WarehouseFieldsFragmentDoc}
  ${ViewerOrgLocationFieldsFragmentDoc}
`;
export const TagSummaryFieldsFragmentDoc = gql`
  fragment TagSummaryFields on ProjectTag {
    id
    name
    color
    deletedAt
    hasMapping
  }
`;
export const UpdateEstimatesWithFileOutputFieldsFragmentDoc = gql`
  fragment UpdateEstimatesWithFileOutputFields on UpdateEstimatesWithFileOutput {
    estimatedItems {
      id
      zone {
        id
      }
    }
    errors {
      lineNumber
      errorMessage
    }
  }
`;
export const UpdateVendorReleaseFieldsFragmentDoc = gql`
  fragment UpdateVendorReleaseFields on Release {
    id
    total
    netAmount
    version
    preferredVendor {
      id
      contacts {
        id
        name
        email
        cellPhone
        receivesBuyoutNotifications
        receivesOrderNotifications
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    sequenceNumber
    amount
    instructions {
      ...InstructionFields
    }
    poNumber
    includeServices
    status
    time
    taxRate
    taxAmount
    taxUnadjustedAmount
    customTaxAmount
    notes
    assets {
      ...AssetFields
    }
    paymentTerm
    additionalCharges {
      ...AdditionalChargesFields
    }
    items {
      ...DistributorReleaseItemFields
    }
    issues {
      ...ReleaseIssuesFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${DistributorReleaseItemFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
`;
export const UserViewerFieldsFragmentDoc = gql`
  fragment UserViewerFields on User {
    id
    firstName
    sessionId
    lastName
    email
    cellPhone
    photoUrl
    isPhoneValidated
    getSmsNotifications
    getEmailNotifications
    systemRoles
    orgRoles
    isExternal
    enterpriseRoles
    locationRoles {
      orgLocID
      role
    }
    authId
    org {
      id
      name
      photoUrl
      hqAddress {
        id
        country
      }
    }
  }
`;
export const UsersUserFieldsFragmentDoc = gql`
  fragment UsersUserFields on User {
    id
    email
    cellPhone
    createdAt
    firstName
    lastName
    photoUrl
    isEmailValidated
    org {
      id
      locations {
        id
        name
      }
    }
    isOrgAdmin
    orgRoles
    enterpriseRoles
    locationRoles {
      orgLocID
      role
    }
  }
`;
export const ViewerOrgFieldsFragmentDoc = gql`
  fragment ViewerOrgFields on Organization {
    id
    name
    ein
    phone
    photoUrl
    hqAddress {
      ...AddressFields
    }
    settings {
      invoices {
        localPart
        domain
        defaultPaymentTerm
      }
      releases {
        poNumbering
        notifications
      }
      display {
        currency {
          ...CurrencyFields
        }
        enableManufacturers
      }
      projectSettings {
        defaultTeam {
          orgLocation {
            id
          }
          users {
            id
          }
        }
      }
    }
    enterprise {
      id
    }
  }
  ${AddressFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
`;
export const ZoneExtendedFieldsFragmentDoc = gql`
  fragment ZoneExtendedFields on Zone {
    id
    name
    inUse
    externalSubJob {
      id
      sourceSystem
      externalId
    }
  }
`;
export const ExternalEquipmentItemFieldsFragmentDoc = gql`
  fragment ExternalEquipmentItemFields on ExternalEquipmentItem {
    id
    externalId
    code
    name
    sourceSystem
  }
`;
export const EquipmentItemFieldsFragmentDoc = gql`
  fragment EquipmentItemFields on EquipmentItem {
    id
    code
    description
    externalEquipmentItem {
      ...ExternalEquipmentItemFields
    }
  }
  ${ExternalEquipmentItemFieldsFragmentDoc}
`;
export const ExternalPaymentMethodFieldsFragmentDoc = gql`
  fragment ExternalPaymentMethodFields on ExternalBankAccount {
    id
    externalId
    sourceSystem
    name
    number
    description
    type
  }
`;
export const ExternalServiceCodeFieldsFragmentDoc = gql`
  fragment ExternalServiceCodeFields on ExternalServiceCode {
    id
    externalId
    code
    name
    sourceSystem
  }
`;
export const ServiceCodeFieldsFragmentDoc = gql`
  fragment ServiceCodeFields on ServiceCode {
    id
    code
    description
    externalServiceCode {
      ...ExternalServiceCodeFields
    }
  }
  ${ExternalServiceCodeFieldsFragmentDoc}
`;
export const ExternalTaxCodeFieldsFragmentDoc = gql`
  fragment ExternalTaxCodeFields on ExternalTaxCode {
    externalId
    sourceId
    sourceSystem
    code
    description
    totalRate
  }
`;
export const TaxCodeFieldsFragmentDoc = gql`
  fragment TaxCodeFields on TaxCode {
    id
    code
    description
    rate
    archivedAt
    externalTaxCode {
      externalId
      sourceId
      sourceSystem
    }
  }
`;
export const ExternalAddressFieldsFragmentDoc = gql`
  fragment ExternalAddressFields on ExternalAddress {
    city
    country
    postalCode
    state
    addressLine1
    addressLine2
  }
`;
export const ExternalVendorFieldsFragmentDoc = gql`
  fragment ExternalVendorFields on ExternalVendor {
    id
    externalId
    sourceId
    name
    email
    phone
    address {
      ...ExternalAddressFields
    }
  }
  ${ExternalAddressFieldsFragmentDoc}
`;
export const InventoryItemStrippedFieldsFragmentDoc = gql`
  fragment InventoryItemStrippedFields on InventoryItem {
    id
    orgMaterial {
      id
    }
    uom {
      id
    }
    state {
      id
      warehouse {
        id
      }
      unitPrice
      remainingQuantity
      itemPosition
      transactionNumber
    }
  }
`;
export const OrgLocationExtendedFieldsFragmentDoc = gql`
  fragment OrgLocationExtendedFields on OrgLocation {
    ...OrgLocationFields
    assignedUserCount
  }
  ${OrgLocationFieldsFragmentDoc}
`;
export const ExternalComplianceGroupFieldsFragmentDoc = gql`
  fragment ExternalComplianceGroupFields on ExternalComplianceGroup {
    id
    externalId
    sourceId
    code
    description
    notes
  }
`;
export const ComplianceGroupFieldsFragmentDoc = gql`
  fragment ComplianceGroupFields on ComplianceGroup {
    id
    code
    description
    notes
    externalComplianceGroup {
      ...ExternalComplianceGroupFields
    }
  }
  ${ExternalComplianceGroupFieldsFragmentDoc}
`;
export const ExternalPoTypeFieldsFragmentDoc = gql`
  fragment ExternalPOTypeFields on ExternalPOType {
    externalId
    sourceId
    name
    sourceSystem
  }
`;
export const IntegrationSettingsFieldsFragmentDoc = gql`
  fragment IntegrationSettingsFields on IntegrationSettings {
    accounting {
      ...AccountingSettingsFields
    }
    sourceSystems {
      ...SourceSystemSettingFields
    }
  }
  ${AccountingSettingsFieldsFragmentDoc}
  ${SourceSystemSettingFieldsFragmentDoc}
`;
export const OrgSettingsExtendedFieldsFragmentDoc = gql`
  fragment OrgSettingsExtendedFields on OrgSettings {
    releaseApproval {
      enabled
      standaloneOnly
      sourceWarehouse
      exemptRoles
      approverRoles
      submitterRoles
    }
    integrations {
      ...IntegrationSettingsExtendedFields
    }
    display {
      enableManufacturers
    }
  }
  ${IntegrationSettingsExtendedFieldsFragmentDoc}
`;
export const NotificationSettingsFieldsFragmentDoc = gql`
  fragment NotificationSettingsFields on NotificationSettings {
    excludedReleaseEvents
    excludedInvoiceEvents
    excludedBuyoutEvents
    excludedQuoteEvents
    excludedMessageContexts
  }
`;
export const EnterpriseOrgFieldsFragmentDoc = gql`
  fragment EnterpriseOrgFields on Organization {
    id
    name
    enterprise {
      id
      name
    }
  }
`;
export const EnterpriseFieldsFragmentDoc = gql`
  fragment EnterpriseFields on Enterprise {
    id
    name
    orgs {
      ...EnterpriseOrgFields
    }
  }
  ${EnterpriseOrgFieldsFragmentDoc}
`;
export const AdminUsersUserFieldsFragmentDoc = gql`
  fragment AdminUsersUserFields on User {
    id
    email
    cellPhone
    createdAt
    firstName
    lastName
    photoUrl
    isEmailValidated
    org {
      id
      locations {
        id
        name
      }
    }
    orgs {
      id
      name
    }
    isOrgAdmin
    orgRoles
    enterpriseRoles
    locationRoles {
      orgLocID
      role
    }
  }
`;
export const OrganizationFieldsFragmentDoc = gql`
  fragment OrganizationFields on Organization {
    id
    name
    hqAddress {
      ...AddressFields
    }
    phone
    type
    photoUrl
  }
  ${AddressFieldsFragmentDoc}
`;
export const AdminOrgPreferredVendorsFieldsFragmentDoc = gql`
  fragment AdminOrgPreferredVendorsFields on OrgPreferredVendor {
    id
    externalCode
    internal
    taxExempt
    org {
      ...OrganizationFields
    }
    defaultPaymentTerm
    defaultReleaseType {
      id
    }
    costCode {
      id
    }
    contacts {
      id
      name
      cellPhone
      cellPhoneExtension
      email
      receivesInvoiceNotifications
      receivesQuoteNotifications
      receivesBuyoutNotifications
      receivesOrderNotifications
    }
    sellerOrgLocation {
      ...OrgLocationFields
    }
    externalVendors {
      id
      externalId
      sourceSystem
    }
  }
  ${OrganizationFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
`;
export const MaterialSummaryFieldsFragmentDoc = gql`
  fragment MaterialSummaryFields on OrgMaterial {
    id
    material {
      ... on Product {
        id
        name
      }
      ... on Sku {
        id
        name
      }
      ... on OrgCatalogSku {
        id
        name
      }
    }
  }
`;
export const OrderTypeFieldsFragmentDoc = gql`
  fragment OrderTypeFields on ReleaseType {
    id
    name
    default
    requireDeliverySlip
    billable
    enableUpcomingNotifications
    includeAdditionalCharges
    transactionKind
    taxMode
    chargesMode
    ledgerAccount {
      ...ExternalLedgerAccountFields
    }
    ledgerSubaccount {
      ...ExternalLedgerSubaccountFields
    }
    costType {
      ...CostTypeFields
    }
    poFormat
    poType {
      sourceSystem
      externalId
      sourceId
      name
    }
    archivedAt
    additionalChargesCostCode {
      ...CostCodeFields
    }
    additionalChargesCostType {
      ...CostTypeFields
    }
    salesTaxCostCode {
      ...CostCodeFields
    }
    salesTaxCostType {
      ...CostTypeFields
    }
    defaultOrgMaterial {
      ...MaterialSummaryFields
    }
    salesTaxOrgMaterial {
      ...MaterialSummaryFields
    }
    defaultAutoSync
    nonJobPO
    trackInventory
    includeTaxInInvoiceSubtotal
  }
  ${ExternalLedgerAccountFieldsFragmentDoc}
  ${ExternalLedgerSubaccountFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${MaterialSummaryFieldsFragmentDoc}
`;
export const BuyoutReleaseFieldsFragmentDoc = gql`
  fragment BuyoutReleaseFields on Release {
    id
    sequenceNumber
    includeServices
    total
    deletedAt
    time
    timeTBD
    status
    version
    hasMissingCostCodes
    poNumber
    project {
      id
      location {
        id
      }
    }
    instructions {
      ...InstructionFields
    }
    issues {
      ...ReleaseIssuesFields
    }
    poLink {
      ...POLinkFields
    }
    deliverySlips {
      ...ReleaseDeliverySlipFields
    }
    deliveryPhotos {
      ...AssetFields
    }
    permissions {
      archive
    }
    type {
      ...OrderTypeFields
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${ReleaseIssuesFieldsFragmentDoc}
  ${PoLinkFieldsFragmentDoc}
  ${ReleaseDeliverySlipFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${OrderTypeFieldsFragmentDoc}
`;
export const BuyoutReleasesFieldsFragmentDoc = gql`
  fragment BuyoutReleasesFields on Buyout {
    id
    clientIdentifier
    status
    subtotal
    version
    requestedAmount
    scheduledAmount
    completedAmount
    createdBy {
      id
      firstName
      lastName
    }
    releaseType {
      id
    }
    quote {
      id
      rfq {
        id
        clientIdentifier
        status
        createdBy {
          id
          firstName
          lastName
          email
        }
      }
    }
    preferredVendor {
      id
      contacts {
        id
        name
        receivesOrderNotifications
        cellPhone
        email
      }
    }
    sellerOrgLocation {
      id
      name
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    project {
      id
      name
      address {
        ...AddressFields
      }
      location {
        id
        name
        org {
          id
          name
          photoUrl
        }
      }
    }
    releases {
      ...BuyoutReleaseFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${BuyoutReleaseFieldsFragmentDoc}
`;
export const NoteDocumentItemFieldsFragmentDoc = gql`
  fragment NoteDocumentItemFields on NoteDocumentItem {
    id
    description
    quantity
    unitPrice
    uom
  }
`;
export const NoteDocumentFieldsFragmentDoc = gql`
  fragment NoteDocumentFields on NoteDocument {
    id
    orgLocation {
      id
    }
    asset {
      ...AssetFields
    }
    items {
      ...NoteDocumentItemFields
    }
    createdAt
  }
  ${AssetFieldsFragmentDoc}
  ${NoteDocumentItemFieldsFragmentDoc}
`;
export const QuoteDocumentItemFieldsFragmentDoc = gql`
  fragment QuoteDocumentItemFields on QuoteDocumentItem {
    id
    description
    quantity
    unitPrice
    uom
    manufacturer
  }
`;
export const ReleaseItemHintFragmentFragmentDoc = gql`
  fragment ReleaseItemHintFragment on ReleaseItemHint {
    releaseItem {
      id
      orgMaterial {
        ...OrgMaterialFields
      }
      uom {
        ...UOMFields
      }
      unitPrice
      quantityDecimal
      manufacturer {
        ...ManufacturerFields
      }
      costCode {
        ...CostCodeFields
      }
      position
    }
    invoiceItem {
      id
      UOM
      unitPrice
      quantityDecimal
    }
    deliverySlipItem {
      id
      description
      orderedQuantity
      receivedQuantity
      uom
      manufacturer
    }
    quoteDocumentItem {
      id
      description
      quantity
      unitPrice
      uom
      manufacturer
    }
  }
  ${OrgMaterialFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${ManufacturerFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
`;
export const QuoteDocumentFieldsFragmentDoc = gql`
  fragment QuoteDocumentFields on QuoteDocument {
    id
    orgLocation {
      id
    }
    predictedProject {
      id
      name
      location {
        id
      }
      address {
        ...AddressFields
      }
    }
    asset {
      ...AssetFields
    }
    items {
      ...QuoteDocumentItemFields
    }
    releaseItemHints {
      ...ReleaseItemHintFragment
    }
    predictedSellerOrgLocation {
      id
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    taxAmount
    expirationDate
    paymentTerm
    createdAt
    subtotal
  }
  ${AddressFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${QuoteDocumentItemFieldsFragmentDoc}
  ${ReleaseItemHintFragmentFragmentDoc}
`;
export const OrgMaterialSummaryFieldsFragmentDoc = gql`
  fragment OrgMaterialSummaryFields on OrgMaterial {
    id
    material {
      ... on Product {
        ...ProjectMaterialProductFields
      }
      ... on Sku {
        ...ProjectMaterialSkuFields
      }
      ... on OrgCatalogSku {
        ...ProjectMaterialOrgCatalogSkuFields
      }
    }
  }
  ${ProjectMaterialProductFieldsFragmentDoc}
  ${ProjectMaterialSkuFieldsFragmentDoc}
  ${ProjectMaterialOrgCatalogSkuFieldsFragmentDoc}
`;
export const InventoryTransactionItemFieldsFragmentDoc = gql`
  fragment InventoryTransactionItemFields on InventoryTransactionItem {
    id
    unitPrice
    item {
      id
      orgMaterial {
        ...OrgMaterialSummaryFields
        costCode {
          id
          code
          description
        }
      }
      uom {
        ...UOMFields
      }
    }
    quantity
    notes
  }
  ${OrgMaterialSummaryFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;
export const InventoryTransactionFieldsFragmentDoc = gql`
  fragment InventoryTransactionFields on InventoryTransaction {
    id
    type
    number
    date
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    instructions {
      ...InstructionFields
    }
    origin {
      ...WarehouseFields
    }
    destination {
      ...WarehouseFields
    }
    deliverySlip {
      id
      deliveredReleaseItems {
        id
        quantity
        inventoryTransactionItems {
          id
        }
        releaseItem {
          id
          deliveryDate
          deliveryDateTBD
          unitPrice
        }
      }
      release {
        id
        sequenceNumber
        poNumber
        includeServices
        timeTBD
        time
        taxRate
        customTaxAmount
        instructions {
          ...InstructionFields
        }
        deliverySlips {
          ...ReleaseDeliverySlipFields
        }
        taxCode {
          id
          rate
        }
        additionalCharges {
          id
          amount
        }
        items {
          id
          quantityDecimal
        }
        project {
          id
          location {
            id
          }
        }
        sellerOrgLocation {
          id
          org {
            id
            name
            photoUrl
          }
        }
        watchers {
          id
        }
        type {
          id
          taxMode
          transactionKind
        }
      }
      project {
        id
        name
        jobNumber
      }
    }
    items {
      ...InventoryTransactionItemFields
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${WarehouseFieldsFragmentDoc}
  ${ReleaseDeliverySlipFieldsFragmentDoc}
  ${InventoryTransactionItemFieldsFragmentDoc}
`;
export const InventoryTransactionSummaryFieldsFragmentDoc = gql`
  fragment InventoryTransactionSummaryFields on InventoryTransaction {
    id
    type
    number
    date
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    instructions {
      text
    }
    origin {
      ...WarehouseFields
    }
    destination {
      ...WarehouseFields
    }
    deliverySlip {
      id
      deliveredReleaseItems {
        id
        quantity
        inventoryTransactionItems {
          id
        }
        releaseItem {
          id
          deliveryDate
          deliveryDateTBD
          unitPrice
        }
      }
      release {
        id
        sequenceNumber
        poNumber
        sellerOrgLocation {
          id
          org {
            id
            name
            photoUrl
          }
        }
      }
      project {
        id
        name
        jobNumber
      }
    }
    items {
      ...InventoryTransactionItemFields
    }
  }
  ${WarehouseFieldsFragmentDoc}
  ${InventoryTransactionItemFieldsFragmentDoc}
`;
export const InventoryItemStateSummaryFieldsFragmentDoc = gql`
  fragment InventoryItemStateSummaryFields on InventoryItemState {
    id
    warehouse {
      id
      name
    }
    unitPrice
    remainingQuantity
  }
`;
export const InventoryItemPendingFlowFieldsFragmentDoc = gql`
  fragment InventoryItemPendingFlowFields on ReleaseItem {
    id
    quantityDecimal
    receivedQuantityDecimal
    unitPrice
    uom {
      ...UOMFields
    }
    instructions {
      ...InstructionFields
    }
    release {
      id
      time
      sequenceNumber
      poNumber
      sellerOrgLocation {
        id
        org {
          id
          name
          photoUrl
        }
      }
      project {
        id
        name
        jobNumber
      }
      warehouse {
        id
        name
      }
      sourceWarehouse {
        id
        name
      }
    }
  }
  ${UomFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
`;
export const InventoryItemSummaryFieldsFragmentDoc = gql`
  fragment InventoryItemSummaryFields on InventoryItem {
    id
    archivedAt
    orgMaterial {
      ...OrgMaterialSummaryFields
      costCode {
        id
        code
        description
      }
    }
    state {
      ...InventoryItemStateSummaryFields
    }
    uom {
      ...UOMFields
    }
    pendingInflows {
      ...InventoryItemPendingFlowFields
    }
    pendingOutflows {
      ...InventoryItemPendingFlowFields
    }
  }
  ${OrgMaterialSummaryFieldsFragmentDoc}
  ${InventoryItemStateSummaryFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${InventoryItemPendingFlowFieldsFragmentDoc}
`;
export const PartialOrgMaterialFieldsFragmentDoc = gql`
  fragment PartialOrgMaterialFields on OrgMaterial {
    id
    costCode {
      id
    }
    defaultEstimateUom {
      id
    }
    externalCode
    material {
      ... on Product {
        id
        name
      }
      ... on Sku {
        id
        name
        manufacturer {
          id
        }
      }
      ... on OrgCatalogSku {
        id
        name
        customPartNumber
        defaultManufacturer {
          id
        }
      }
    }
  }
`;
export const InventoryItemStateFieldsFragmentDoc = gql`
  fragment InventoryItemStateFields on InventoryItemState {
    id
    warehouse {
      id
    }
    unitPrice
    transactionNumber
    itemPosition
    originalQuantity
    remainingQuantity
  }
`;
export const InventoryItemFieldsFragmentDoc = gql`
  fragment InventoryItemFields on InventoryItem {
    id
    orgMaterial {
      ...PartialOrgMaterialFields
    }
    uom {
      ...UOMFields
    }
    state {
      ...InventoryItemStateFields
    }
    archivedAt
  }
  ${PartialOrgMaterialFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
  ${InventoryItemStateFieldsFragmentDoc}
`;
export const InventoryTransactionItemSummaryFieldsFragmentDoc = gql`
  fragment InventoryTransactionItemSummaryFields on InventoryTransactionItem {
    id
    unitPrice
    item {
      ...InventoryItemFields
    }
    quantity
    notes
  }
  ${InventoryItemFieldsFragmentDoc}
`;
export const PageRangeFieldsFragmentDoc = gql`
  fragment PageRangeFields on PageRange {
    start
    end
  }
`;
export const InvoiceDocumentFieldsFragmentDoc = gql`
  fragment InvoiceDocumentFields on InvoiceDocument {
    id
    version
    asset {
      ...AssetFields
    }
    pageRange {
      ...PageRangeFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${PageRangeFieldsFragmentDoc}
`;
export const InvoiceEmailAttachmentFieldsFragmentDoc = gql`
  fragment InvoiceEmailAttachmentFields on InvoiceEmailAttachment {
    id
    asset {
      ...AssetFields
    }
    invoiceDocuments {
      ...InvoiceDocumentFields
      invoice {
        id
        number
        status
        archivedAt
        release {
          id
          sequenceNumber
        }
      }
    }
    rejectedAt
  }
  ${AssetFieldsFragmentDoc}
  ${InvoiceDocumentFieldsFragmentDoc}
`;
export const InvoiceEmailFieldsFragmentDoc = gql`
  fragment InvoiceEmailFields on InvoiceEmail {
    id
    from
    to
    subject
    content
    archivedAt
    attachments {
      ...InvoiceEmailAttachmentFields
    }
    orgPreferredVendor {
      id
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      contacts {
        id
        name
      }
    }
    status
    receivedAt
    invoices {
      id
      number
      status
      archivedAt
      release {
        id
        sequenceNumber
        status
      }
    }
  }
  ${InvoiceEmailAttachmentFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const PaymentMethodFieldsFragmentDoc = gql`
  fragment PaymentMethodFields on PaymentMethod {
    id
    number
    type
    description
    default
    externalBankAccount {
      id
      name
    }
  }
`;
export const InvoiceItemFieldsFragmentDoc = gql`
  fragment InvoiceItemFields on InvoiceItem {
    id
    description
    partNumber
    unitPrice
    UOM
    manufacturer
    quantityDecimal
  }
`;
export const InvoiceKickbackCommentFieldsFragmentDoc = gql`
  fragment InvoiceKickbackCommentFields on InvoiceKickbackComment {
    id
    text
    assets {
      ...AssetFields
    }
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
  }
  ${AssetFieldsFragmentDoc}
`;
export const InvoiceKickbackFieldsFragmentDoc = gql`
  fragment InvoiceKickbackFields on InvoiceKickback {
    id
    reportedBy {
      id
      firstName
      lastName
      photoUrl
    }
    assignees {
      id
      firstName
      lastName
      photoUrl
    }
    resolvedBy {
      id
      firstName
      lastName
      photoUrl
    }
    description
    createdAt
    resolutionDescription
    resolvedAt
    assets {
      ...AssetFields
    }
    resolutionAssets {
      ...AssetFields
    }
    comments {
      ...InvoiceKickbackCommentFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${InvoiceKickbackCommentFieldsFragmentDoc}
`;
export const InvoiceIssueFieldsFragmentDoc = gql`
  fragment InvoiceIssueFields on InvoiceIssue {
    id
    reportedBy {
      id
      firstName
      lastName
      photoUrl
    }
    description
    createdAt
    resolutionDescription
    respondedAt
    rejectionReason
    contractorAssets {
      ...AssetFields
    }
    vendorAssets {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const InvoiceLinkFieldsFragmentDoc = gql`
  fragment InvoiceLinkFields on InvoiceLink {
    id
    sourceSystem
    createdAt
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
    exportedAt
    failedAt
    failedMessage
    batchId
    batch {
      id
      number
      month
    }
  }
`;
export const InvoiceFieldsFragmentDoc = gql`
  fragment InvoiceFields on Invoice {
    id
    documents {
      ...InvoiceDocumentFields
    }
    hasRelease
    createdAt
    poExists
    release {
      id
      sequenceNumber
      syncVersion
      poNumber
      version
      status
      total
      netAmount
      taxableNetAmount
      taxAmount
      taxUnadjustedAmount
      taxVariance
      taxRate
      time
      customTaxAmount
      useSourceSystemPO
      description
      poLink {
        ...POLinkFields
      }
      instructions {
        ...InstructionFields
      }
      additionalCharges {
        ...AdditionalChargesFields
      }
      createdBy {
        id
      }
      buyout {
        id
        clientIdentifier
      }
      itemCount
      preferredVendor {
        id
        externalCode
        contacts {
          id
          name
          cellPhone
          email
          receivesInvoiceNotifications
        }
      }
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      paymentTerm
      project {
        id
        name
        jobNumber
        address {
          ...AddressFields
        }
        location {
          id
          name
          address {
            ...AddressFields
          }
        }
        tags {
          ...TagFields
        }
      }
      permissions {
        edit
      }
      invoices {
        id
        total
        number
        status
        documents {
          id
          asset {
            ...AssetFields
          }
        }
        issueDate
        kickbacks {
          id
          resolvedAt
          createdAt
          deletedAt
        }
      }
      type {
        ...OrderTypeSummaryFields
        costType {
          id
          description
        }
      }
      warehouse {
        id
      }
      taxType
    }
    orgDuplicate
    vendorDuplicate
    number
    poNumber
    dueDate
    accountingDate
    description
    subtotal
    chargesAmount
    taxAmount
    total
    status
    issueDate
    prePaid
    paymentMethod {
      ...PaymentMethodFields
    }
    items {
      ...InvoiceItemFields
    }
    releaseItemHints {
      ...ReleaseItemHintFragment
    }
    charges {
      id
      description
      amount
    }
    computedSubtotal
    approvedBy {
      id
      firstName
      lastName
      photoUrl
    }
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
    kickbacks {
      ...InvoiceKickbackFields
    }
    issues {
      ...InvoiceIssueFields
    }
    link {
      ...InvoiceLinkFields
    }
    permissions {
      edit
      respond
      export
      archive
    }
    project {
      id
      name
      address {
        ...AddressFields
      }
    }
    predictedProject {
      id
      jobNumber
      location {
        id
      }
    }
    isDeliverySlip
    predictedSellerOrgLocation {
      id
      org {
        id
        name
      }
    }
    exportedAt
    exportedBy {
      id
      firstName
      lastName
      photoUrl
    }
    archivedAt
    approvedAt
    retentionPercentage
    retentionAmount
    discountDate
    discountPercentage
    discountAmount
    discountedTotal
    autoMatched
    folders {
      id
      name
    }
    orgLocation {
      id
    }
    sellerOrgLocation {
      id
      address {
        ...AddressFields
      }
      org {
        id
        photoUrl
        name
      }
    }
    headerOnly
    equipmentItem {
      id
    }
    serviceCode {
      id
    }
    duplicates {
      id
      total
      number
      status
      archivedAt
      documents {
        id
        asset {
          ...AssetFields
        }
      }
      issueDate
    }
  }
  ${InvoiceDocumentFieldsFragmentDoc}
  ${PoLinkFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${PaymentMethodFieldsFragmentDoc}
  ${InvoiceItemFieldsFragmentDoc}
  ${ReleaseItemHintFragmentFragmentDoc}
  ${InvoiceKickbackFieldsFragmentDoc}
  ${InvoiceIssueFieldsFragmentDoc}
  ${InvoiceLinkFieldsFragmentDoc}
`;
export const InvoiceFolderFieldsFragmentDoc = gql`
  fragment InvoiceFolderFields on InvoiceFolder {
    id
    name
    createdAt
  }
`;
export const KickbackSummaryFieldsFragmentDoc = gql`
  fragment KickbackSummaryFields on InvoiceKickback {
    id
    resolvedAt
    createdAt
    deletedAt
    reportedBy {
      id
      firstName
      lastName
    }
    assignees {
      id
      firstName
      lastName
    }
    resolvedBy {
      id
      firstName
      lastName
    }
  }
`;
export const InvoiceSummaryFieldsFragmentDoc = gql`
  fragment InvoiceSummaryFields on Invoice {
    id
    number
    poNumber
    dueDate
    description
    total
    issueDate
    createdAt
    archivedAt
    status
    exportedAt
    exportedBy {
      id
      firstName
      lastName
    }
    downloadedAt
    downloadedBy {
      id
      firstName
      lastName
    }
    approvedAt
    approvedBy {
      id
      firstName
      lastName
    }
    orgDuplicate
    vendorDuplicate
    project {
      id
      name
      jobNumber
    }
    release {
      id
      sequenceNumber
      poNumber
      exportedAt
      status
      receiptPostedAt
      requiresInventoryReceipt
      type {
        id
        name
        transactionKind
      }
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      preferredVendor {
        id
        externalCode
        contacts {
          id
          name
          receivesInvoiceNotifications
        }
      }
      poLink {
        id
        syncedAt
        createdBy {
          id
          firstName
          lastName
        }
      }
      project {
        id
        name
        jobNumber
      }
      buyout {
        id
        clientIdentifier
      }
      deliverySlips {
        id
        status
      }
    }
    link {
      ...InvoiceLinkFields
    }
    permissions {
      archive
      export
    }
    predictedSellerOrgLocation {
      id
      address {
        ...AddressFields
      }
      org {
        id
        photoUrl
        name
      }
    }
    folders {
      id
      name
    }
    kickbacks {
      ...KickbackSummaryFields
    }
    headerOnly
  }
  ${AddressFieldsFragmentDoc}
  ${InvoiceLinkFieldsFragmentDoc}
  ${KickbackSummaryFieldsFragmentDoc}
`;
export const ExternalBatchFieldsFragmentDoc = gql`
  fragment ExternalBatchFields on ExternalBatch {
    id
    sourceSystem
    externalId
    sourceId
    number
    month
    type
  }
`;
export const InvoiceToExportFieldsFragmentDoc = gql`
  fragment InvoiceToExportFields on Invoice {
    id
    number
    status
    poNumber
    total
    exportedAt
    createdAt
    taxAmount
    release {
      id
      sequenceNumber
      hasMissingCostCodes
      exportedAt
      poLink {
        id
        syncedAt
      }
      status
      poNumber
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      preferredVendor {
        id
        externalCode
        contacts {
          id
          name
          receivesInvoiceNotifications
        }
        deletedAt
      }
      project {
        id
        name
      }
      type {
        ...OrderTypeSummaryFields
        costType {
          id
          description
        }
        ledgerAccount {
          ...ExternalLedgerAccountFields
        }
      }
    }
    documents {
      ...InvoiceDocumentFields
    }
    permissions {
      archive
      export
    }
    link {
      id
      exportedAt
      batch {
        ...ExternalBatchFields
      }
    }
    predictedSellerOrgLocation {
      id
      org {
        id
        name
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${ExternalLedgerAccountFieldsFragmentDoc}
  ${InvoiceDocumentFieldsFragmentDoc}
  ${ExternalBatchFieldsFragmentDoc}
`;
export const InvoiceValidationFieldsFragmentDoc = gql`
  fragment InvoiceValidationFields on InvoiceValidation {
    errors
    warnings
    salesTaxRemainingAmount
    additionalChargesRemainingAmount
    itemsMissingCostCodes {
      id
    }
    costCodesNotConnected {
      id
    }
    costCodesMissingInPO {
      id
      description
    }
    costCodesExceedingPOAmounts {
      id
    }
    wbsExceedingPOAmounts {
      id
    }
    wbsMissingInPO {
      id
    }
    costCodeMismatches {
      id
    }
    descriptionMismatches {
      id
    }
    uomMismatches {
      id
    }
    itemsNotConnected {
      id
    }
    itemsMissingTags {
      id
      orgMaterial {
        id
        material {
          ... on Product {
            ...ProjectMaterialProductFields
          }
          ... on Sku {
            ...ProjectMaterialSkuFields
          }
          ... on OrgCatalogSku {
            ...ProjectMaterialOrgCatalogSkuFields
          }
        }
      }
    }
    missingBudgetCombinations {
      costCode {
        ...CostCodeFields
      }
      costType {
        ...CostTypeFields
      }
      zone {
        ...ZoneFields
      }
    }
    itemsExceedingRemainingAmount {
      id
    }
    itemsExceedingRemainingUnexportedAmount {
      id
    }
    po {
      amount
      items {
        externalId
        remainingAmount
        description
      }
    }
  }
  ${ProjectMaterialProductFieldsFragmentDoc}
  ${ProjectMaterialSkuFieldsFragmentDoc}
  ${ProjectMaterialOrgCatalogSkuFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
`;
export const ExternalProjectFieldsFragmentDoc = gql`
  fragment ExternalProjectFields on ExternalProject {
    id
    externalId
    sourceId
    address {
      ...ExternalAddressFields
    }
    name
    number
    startDate
    amount
    sourceSystem
    status
    project {
      id
      name
    }
  }
  ${ExternalAddressFieldsFragmentDoc}
`;
export const EstimatedItemGroupFieldsFragmentDoc = gql`
  fragment EstimatedItemGroupFields on EstimatedItemGroup {
    estimatedItems {
      id
      tags {
        id
      }
      zone {
        id
      }
      material {
        id
      }
      costCode {
        id
      }
      unitPrice
      quantityDecimal
      orderedQuantity
      uom {
        id
      }
      sellerOrgLocation {
        id
      }
    }
  }
`;
export const ProjectAllowanceFieldsFragmentDoc = gql`
  fragment ProjectAllowanceFields on ProjectAllowance {
    amount
    costCodes {
      id
      costCode {
        id
        code
        description
        inUse
      }
      position
      amount
      zone {
        id
        name
        inUse
      }
      costTypes {
        ...CostTypeFields
      }
    }
    tags {
      id
      amount
      tag {
        ...TagFields
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export const ProjectBudgetLinkFieldsFragmentDoc = gql`
  fragment ProjectBudgetLinkFields on BudgetLink {
    id
    autoSync
    createdAt
    syncedAt
    autoSyncError
  }
`;
export const ProjectReportMaterialFieldsFragmentDoc = gql`
  fragment ProjectReportMaterialFields on ProjectReportMaterial {
    material {
      id
    }
    estimated
    quoted
    reserved
    ordered
    invoiced
    received
    paid
  }
`;
export const ProjectReportCostCodeFieldsFragmentDoc = gql`
  fragment ProjectReportCostCodeFields on ProjectReportCostCode {
    costCode {
      id
      code
    }
    materials {
      ...ProjectReportMaterialFields
    }
    allowance
    estimated
    reserved
    ordered
    orderedTotal
    invoiced
    overUnder
    unallotted
    invoicedTotal
    received
    receivedTotal
    paid
    paidTotal
  }
  ${ProjectReportMaterialFieldsFragmentDoc}
`;
export const ProjectReportPerMaterialFieldsFragmentDoc = gql`
  fragment ProjectReportPerMaterialFields on ProjectReportPerMaterial {
    costCodes {
      ...ProjectReportCostCodeFields
    }
    allowance
    estimated
    unallotted
    reserved
    ordered
    orderedNet
    invoiced
    overUnder
    invoicedTotal
    received
    receivedTotal
    paid
    paidTotal
  }
  ${ProjectReportCostCodeFieldsFragmentDoc}
`;
export const ProjectReportZoneFieldsFragmentDoc = gql`
  fragment ProjectReportZoneFields on ProjectReportZone {
    zone {
      id
    }
    costCodes {
      ...ProjectReportCostCodeFields
    }
    allowance
    estimated
    ordered
    orderedTotal
    invoiced
    overUnder
    unallotted
    invoicedTotal
    received
    receivedTotal
    paid
    paidTotal
  }
  ${ProjectReportCostCodeFieldsFragmentDoc}
`;
export const ProjectReportPerMaterialZoneFieldsFragmentDoc = gql`
  fragment ProjectReportPerMaterialZoneFields on ProjectReportPerMaterialZone {
    zones {
      ...ProjectReportZoneFields
    }
    allowance
    estimated
    unallotted
    ordered
    orderedNet
    invoiced
    overUnder
    invoicedTotal
    received
    receivedTotal
    paid
    paidTotal
  }
  ${ProjectReportZoneFieldsFragmentDoc}
`;
export const ProjectReportVendorFieldsFragmentDoc = gql`
  fragment ProjectReportVendorFields on ProjectReportVendor {
    vendor {
      id
      name
      photoUrl
    }
    reserved
    ordered
    invoiced
    received
    paid
  }
`;
export const ProjectReportCostCodeVendorFieldsFragmentDoc = gql`
  fragment ProjectReportCostCodeVendorFields on ProjectReportCostCodeVendor {
    costCode {
      id
    }
    vendors {
      ...ProjectReportVendorFields
    }
    allowance
    estimated
    reserved
    ordered
    orderedTotal
    invoiced
    overUnder
    unallotted
    invoicedTotal
    received
    receivedTotal
    paid
    paidTotal
  }
  ${ProjectReportVendorFieldsFragmentDoc}
`;
export const ProjectReportPerVendorFieldsFragmentDoc = gql`
  fragment ProjectReportPerVendorFields on ProjectReportPerVendor {
    costCodes {
      ...ProjectReportCostCodeVendorFields
    }
    allowance
    estimated
    unallotted
    reserved
    ordered
    invoiced
    orderedNet
    overUnder
    invoicedTotal
    received
    receivedTotal
    paid
    paidTotal
  }
  ${ProjectReportCostCodeVendorFieldsFragmentDoc}
`;
export const ProjectReportReleasesFieldsFragmentDoc = gql`
  fragment ProjectReportReleasesFields on ProjectReportReleases {
    project {
      id
      name
    }
    orgMaterial {
      ...ProjectMaterialFields
    }
    elements {
      release {
        id
        total
        status
        sequenceNumber
        time
        buyout {
          id
          clientIdentifier
        }
        sellerOrgLocation {
          id
          address {
            ...AddressFields
          }
          org {
            id
            photoUrl
            name
          }
        }
        invoices {
          id
        }
      }
      releaseItems {
        id
        isIncluded
        orgMaterial {
          ...ProjectMaterialFields
        }
        projectItem {
          id
          material {
            id
            costCode {
              id
            }
          }
        }
        invoicedAmount {
          net
          tax
          charges
        }
        orderedAmount {
          net
          tax
          charges
        }
        invoicedQuantity
        quantityDecimal
        unitPrice
        receivedQuantityDecimal
        uom {
          id
        }
        zone {
          id
        }
        costCode {
          id
        }
      }
    }
  }
  ${ProjectMaterialFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const ProjectProjectsFieldsFragmentDoc = gql`
  fragment ProjectProjectsFields on Project {
    id
    name
    status
    budget
    startDate
    jobNumber
    deletedAt
    percentageOfBudgetSpent
    orderedAmount
    totalAmount
    materialCount
    releaseCount
    team {
      id
      firstName
      lastName
      photoUrl
    }
    mappings {
      ...SourceSystemMappingFields
    }
    permissions {
      report
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
`;
export const ReceiptDocumentFieldsFragmentDoc = gql`
  fragment ReceiptDocumentFields on InvoiceDocument {
    id
    version
    asset {
      ...AssetFields
    }
    pageRange {
      ...PageRangeFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${PageRangeFieldsFragmentDoc}
`;
export const ReceiptItemFieldsFragmentDoc = gql`
  fragment ReceiptItemFields on InvoiceItem {
    id
    description
    partNumber
    unitPrice
    UOM
    manufacturer
    quantityDecimal
  }
`;
export const ReceiptLinkFieldsFragmentDoc = gql`
  fragment ReceiptLinkFields on InvoiceLink {
    id
    sourceSystem
    createdAt
    exportedAt
    failedAt
    failedMessage
  }
`;
export const ReceiptFieldsFragmentDoc = gql`
  fragment ReceiptFields on Invoice {
    id
    documents {
      ...ReceiptDocumentFields
    }
    release {
      id
      type {
        ...OrderTypeSummaryFields
      }
      sequenceNumber
      poNumber
      version
      status
      total
      netAmount
      taxAmount
      time
      customTaxAmount
      createdBy {
        id
        firstName
        lastName
      }
      additionalCharges {
        ...AdditionalChargesFields
      }
      preferredVendor {
        id
        externalCode
        contacts {
          id
          name
          cellPhone
          email
          receivesInvoiceNotifications
        }
      }
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      paymentTerm
      project {
        id
        name
        address {
          ...AddressFields
        }
        location {
          id
          name
          address {
            ...AddressFields
          }
        }
        tags {
          ...TagFields
        }
      }
      permissions {
        edit
      }
      items {
        id
        unitPrice
        uom {
          id
          mnemonic
          pluralDescription
        }
        name
      }
      invoices {
        id
        total
        number
        type
        documents {
          id
          asset {
            ...AssetFields
          }
        }
      }
    }
    number
    poNumber
    dueDate
    subtotal
    chargesAmount
    taxAmount
    total
    status
    description
    issueDescription
    issueDate
    resolutionDescription
    rejectionReason
    issueResponseDate
    prePaid
    costCode {
      ...CostCodeFields
    }
    items {
      ...ReceiptItemFields
    }
    releaseItemHints {
      ...ReleaseItemHintFragment
    }
    charges {
      id
      description
      amount
    }
    computedSubtotal
    approvedBy {
      id
      firstName
      lastName
      photoUrl
    }
    approvedAt
    kickbacks {
      ...InvoiceKickbackFields
    }
    issues {
      ...InvoiceIssueFields
    }
    link {
      ...ReceiptLinkFields
    }
    permissions {
      edit
      approve
      respond
      export
      archive
    }
    predictedProject {
      id
      location {
        id
      }
    }
    isDeliverySlip
    predictedSellerOrgLocation {
      id
    }
    exportedAt
    archivedAt
    createdAt
    notes
    createdBy {
      id
      firstName
      lastName
    }
    project {
      id
      deletedAt
      location {
        id
      }
    }
    equipmentItem {
      id
    }
    serviceCode {
      id
    }
    paymentMethod {
      ...PaymentMethodFields
    }
    folders {
      id
      name
    }
  }
  ${ReceiptDocumentFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${ReceiptItemFieldsFragmentDoc}
  ${ReleaseItemHintFragmentFragmentDoc}
  ${InvoiceKickbackFieldsFragmentDoc}
  ${InvoiceIssueFieldsFragmentDoc}
  ${ReceiptLinkFieldsFragmentDoc}
  ${PaymentMethodFieldsFragmentDoc}
`;
export const ReceiptSummaryFieldsFragmentDoc = gql`
  fragment ReceiptSummaryFields on Invoice {
    id
    number
    poNumber
    issueDate
    status
    exportedAt
    archivedAt
    prePaid
    release {
      id
      sequenceNumber
      exportedAt
      poNumber
      status
      receiptPostedAt
      requiresInventoryReceipt
      type {
        id
        name
        transactionKind
      }
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      preferredVendor {
        id
        externalCode
        contacts {
          id
          name
          receivesInvoiceNotifications
        }
      }
      poLink {
        id
        syncedAt
      }
      project {
        id
        name
        jobNumber
        team {
          id
          firstName
          lastName
        }
      }
      buyout {
        id
        clientIdentifier
      }
    }
    link {
      ...ReceiptLinkFields
    }
    permissions {
      archive
      export
    }
    total
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    project {
      id
      name
      jobNumber
      team {
        id
        firstName
        lastName
      }
    }
    kickbacks {
      ...KickbackSummaryFields
    }
    folders {
      id
      name
    }
  }
  ${AddressFieldsFragmentDoc}
  ${ReceiptLinkFieldsFragmentDoc}
  ${KickbackSummaryFieldsFragmentDoc}
`;
export const ReceiptToExportFieldsFragmentDoc = gql`
  fragment ReceiptToExportFields on Invoice {
    id
    number
    status
    poNumber
    createdAt
    total
    release {
      id
      sequenceNumber
      hasMissingCostCodes
      exportedAt
      poLink {
        id
        syncedAt
      }
      status
      poNumber
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      preferredVendor {
        id
        externalCode
        contacts {
          id
          name
          receivesInvoiceNotifications
        }
        deletedAt
      }
      project {
        id
        name
        zones {
          id
        }
      }
      type {
        ...OrderTypeSummaryFields
        ledgerAccount {
          ...ExternalLedgerAccountFields
        }
      }
    }
    documents {
      ...ReceiptDocumentFields
    }
    permissions {
      archive
      export
    }
    link {
      id
      batch {
        ...ExternalBatchFields
      }
    }
    paymentMethod {
      id
      description
    }
  }
  ${AddressFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${ExternalLedgerAccountFieldsFragmentDoc}
  ${ReceiptDocumentFieldsFragmentDoc}
  ${ExternalBatchFieldsFragmentDoc}
`;
export const ExternalWarehouseFieldsFragmentDoc = gql`
  fragment ExternalWarehouseFields on ExternalWarehouse {
    externalId
    sourceId
    code
    name
  }
`;
export const MaterialConversionFieldsFragmentDoc = gql`
  fragment MaterialConversionFields on MaterialConversion {
    id
    from {
      ...UOMFields
    }
    to {
      ...UOMFields
    }
    material {
      ...PartialOrgMaterialFields
    }
    quantityMultiplier
  }
  ${UomFieldsFragmentDoc}
  ${PartialOrgMaterialFieldsFragmentDoc}
`;
export const PoMaterialConversionFieldsFragmentDoc = gql`
  fragment POMaterialConversionFields on POMaterialConversion {
    fulfillmentUom {
      ...UOMFields
    }
    purchasingUom
    material {
      ...OrgMaterialFields
    }
    quantityMultiplier
  }
  ${UomFieldsFragmentDoc}
  ${OrgMaterialFieldsFragmentDoc}
`;
export const PoValidationFieldsFragmentDoc = gql`
  fragment POValidationFields on POValidation {
    errors
    warnings
    itemsMissingPrices {
      id
    }
    itemsMissingCostCodes {
      id
    }
    costCodesNotConnected {
      id
    }
    materialsNotConnected {
      id
      material {
        ... on Product {
          ...ProjectMaterialProductFields
        }
        ... on Sku {
          ...ProjectMaterialSkuFields
        }
        ... on OrgCatalogSku {
          ...ProjectMaterialOrgCatalogSkuFields
        }
      }
    }
    materialConversions {
      ...POMaterialConversionFields
    }
    itemsMissingTags {
      id
      orgMaterial {
        id
        material {
          ... on Product {
            ...ProjectMaterialProductFields
          }
          ... on Sku {
            ...ProjectMaterialSkuFields
          }
          ... on OrgCatalogSku {
            ...ProjectMaterialOrgCatalogSkuFields
          }
        }
      }
    }
    missingBudgetCombinations {
      costCode {
        ...CostCodeFields
      }
      costType {
        ...CostTypeFields
      }
      zone {
        ...ZoneFields
      }
    }
  }
  ${ProjectMaterialProductFieldsFragmentDoc}
  ${ProjectMaterialSkuFieldsFragmentDoc}
  ${ProjectMaterialOrgCatalogSkuFieldsFragmentDoc}
  ${PoMaterialConversionFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
`;
export const ReleaseSummaryFieldsFragmentDoc = gql`
  fragment ReleaseSummaryFields on Release {
    id
    sequenceNumber
    poNumber
    preferredVendor {
      id
      deletedAt
      externalCode
    }
    predictedPoNumber
    project {
      id
      name
      jobNumber
    }
    sellerOrgLocation {
      id
      address {
        ...AddressFields
      }
      org {
        id
        photoUrl
        name
      }
    }
    status
    time
    version
    taxRate
    total
    hasMissingCostCodes
    hasMissingExternalCodes
    customTaxAmount
    useSourceSystemPO
    permissions {
      edit
      submit
      submitDirectly
      approve
      archive
    }
    warehouse {
      ...WarehouseFields
    }
    poLink {
      ...POLinkFields
      batch {
        ...ExternalBatchFields
      }
    }
    materialConversions {
      ...POMaterialConversionFields
    }
    type {
      ...OrderTypeSummaryFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${WarehouseFieldsFragmentDoc}
  ${PoLinkFieldsFragmentDoc}
  ${ExternalBatchFieldsFragmentDoc}
  ${PoMaterialConversionFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
`;
export const ReleaseToCsvExportFieldsFragmentDoc = gql`
  fragment ReleaseToCsvExportFields on Release {
    id
    sequenceNumber
    status
    poNumber
    description
    createdAt
    createdBy {
      id
      firstName
      lastName
      email
    }
    time
    timeTBD
    includeServices
    total
    project {
      id
      jobNumber
    }
    buyout {
      id
      clientIdentifier
    }
    sellerOrgLocation {
      id
      org {
        id
        name
      }
    }
    type {
      id
      name
    }
    issues {
      id
    }
    siteContact {
      id
      name
      phone
    }
  }
`;
export const DeliverySlipItemFieldsFragmentDoc = gql`
  fragment DeliverySlipItemFields on DeliverySlipItem {
    id
    description
    orderedQuantity
    receivedQuantity
    uom
    manufacturer
  }
`;
export const DeliveredReleaseItemsFieldsFragmentDoc = gql`
  fragment DeliveredReleaseItemsFields on DeliveredReleaseItem {
    id
    releaseItem {
      id
    }
    deliverySlip {
      id
    }
    quantity
    quantitySoFar
  }
`;
export const DeliverySlipFieldsFragmentDoc = gql`
  fragment DeliverySlipFields on DeliverySlip {
    id
    status
    orgLocation {
      id
    }
    project {
      id
      name
      jobNumber
      location {
        id
      }
      address {
        ...AddressFields
      }
    }
    asset {
      ...AssetFields
    }
    items {
      ...DeliverySlipItemFields
    }
    deliveredReleaseItems {
      ...DeliveredReleaseItemsFields
    }
    releaseItemHints {
      ...ReleaseItemHintFragment
    }
    notes
    fulfillmentDate
    predictedSellerOrgLocation {
      id
      org {
        id
        name
        photoUrl
      }
      address {
        ...AddressFields
      }
    }
    predictedRelease {
      id
      status
      poNumber
      total
      netAmount
      additionalCharges {
        ...AdditionalChargesFields
      }
      time
      version
      type {
        ...OrderTypeSummaryFields
      }
      sellerOrgLocation {
        id
        org {
          id
          name
          photoUrl
        }
      }
      sequenceNumber
      buyout {
        id
        clientIdentifier
      }
      project {
        id
        name
      }
    }
    release {
      id
      status
      sequenceNumber
      time
      total
      netAmount
      additionalCharges {
        ...AdditionalChargesFields
      }
      poNumber
      itemCount
      version
      sellerOrgLocation {
        id
        name
        org {
          id
          name
          photoUrl
        }
        address {
          ...AddressFields
        }
      }
      type {
        ...OrderTypeSummaryFields
      }
      preferredVendor {
        id
        contacts {
          id
          name
          cellPhone
          email
          receivesQuoteNotifications
          receivesBuyoutNotifications
          receivesOrderNotifications
          receivesInvoiceNotifications
        }
      }
      buyout {
        id
        clientIdentifier
      }
      project {
        id
        name
        location {
          id
        }
        address {
          ...AddressFields
        }
      }
      poLink {
        id
        immutable
        sourceSystem
        syncedAt
      }
      warehouse {
        id
      }
      sourceWarehouse {
        id
      }
    }
    deliveryPhotos {
      ...AssetFields
    }
    poNumber
    hasPredictedRelease
    createdAt
    reviewedAt
    createdBy {
      id
      firstName
      lastName
      email
      photoUrl
    }
    permissions {
      edit
      archive
    }
    archivedAt
  }
  ${AddressFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${DeliverySlipItemFieldsFragmentDoc}
  ${DeliveredReleaseItemsFieldsFragmentDoc}
  ${ReleaseItemHintFragmentFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
`;
export const DeliverySlipPartialFieldsFragmentDoc = gql`
  fragment DeliverySlipPartialFields on DeliverySlip {
    id
    status
    orgLocation {
      id
    }
    project {
      id
      name
      jobNumber
    }
    notes
    fulfillmentDate
    predictedSellerOrgLocation {
      id
      org {
        id
        name
        photoUrl
      }
    }
    release {
      id
      status
      sequenceNumber
      time
      total
      poNumber
      itemCount
      type {
        id
        name
        transactionKind
      }
      sellerOrgLocation {
        id
        name
        org {
          id
          name
          photoUrl
        }
        address {
          ...AddressFields
        }
      }
      preferredVendor {
        id
        contacts {
          id
          name
          cellPhone
          email
          receivesQuoteNotifications
          receivesBuyoutNotifications
          receivesOrderNotifications
          receivesInvoiceNotifications
        }
      }
      buyout {
        id
        clientIdentifier
      }
      project {
        id
        name
      }
    }
    poNumber
    createdAt
    reviewedAt
    createdBy {
      id
      firstName
      lastName
      email
      photoUrl
    }
    permissions {
      edit
      archive
    }
    archivedAt
  }
  ${AddressFieldsFragmentDoc}
`;
export const DistributorInvoiceItemFieldsFragmentDoc = gql`
  fragment DistributorInvoiceItemFields on InvoiceItem {
    id
    description
    partNumber
    unitPrice
    UOM
    manufacturer
    quantityDecimal
  }
`;
export const DistributorInvoiceDocumentFieldsFragmentDoc = gql`
  fragment DistributorInvoiceDocumentFields on InvoiceDocument {
    id
    version
    asset {
      ...AssetFields
    }
    pageRange {
      ...PageRangeFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${PageRangeFieldsFragmentDoc}
`;
export const DistributorInvoiceIssueFieldsFragmentDoc = gql`
  fragment DistributorInvoiceIssueFields on InvoiceIssue {
    id
    reportedBy {
      id
      firstName
      lastName
      photoUrl
    }
    description
    createdAt
    resolutionDescription
    respondedAt
    rejectionReason
    contractorAssets {
      ...AssetFields
    }
    vendorAssets {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export const DistributorInvoiceFieldsFragmentDoc = gql`
  fragment DistributorInvoiceFields on Invoice {
    id
    number
    poNumber
    dueDate
    description
    subtotal
    computedSubtotal
    chargesAmount
    taxAmount
    total
    issueDate
    status
    prePaid
    retentionPercentage
    retentionAmount
    discountPercentage
    discountAmount
    discountDate
    discountedTotal
    items {
      ...DistributorInvoiceItemFields
    }
    documents {
      ...DistributorInvoiceDocumentFields
    }
    charges {
      id
      description
      amount
    }
    release {
      id
      version
      sequenceNumber
      status
      total
      netAmount
      taxAmount
      customTaxAmount
      taxableNetAmount
      additionalCharges {
        ...AdditionalChargesFields
      }
      taxRate
      paymentTerm
      notes
      assets {
        ...AssetFields
      }
      preferredVendor {
        id
        taxExempt
      }
      sellerOrgLocation {
        id
        name
        org {
          id
          name
          photoUrl
        }
        address {
          ...AddressFields
        }
      }
      project {
        id
        name
        taxExempt
        address {
          ...AddressFields
        }
        location {
          id
          name
          address {
            ...AddressFields
          }
          org {
            id
            name
            photoUrl
          }
        }
      }
      items {
        ...DistributorReleaseItemFields
      }
      deliverySlips {
        ...DistributorDeliverySlipFields
      }
      deliveryPhotos {
        ...AssetFields
      }
      instructions {
        ...InstructionFields
      }
      type {
        ...OrderTypeSummaryFields
      }
      taxType
    }
    issues {
      ...DistributorInvoiceIssueFields
    }
  }
  ${DistributorInvoiceItemFieldsFragmentDoc}
  ${DistributorInvoiceDocumentFieldsFragmentDoc}
  ${AdditionalChargesFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${DistributorReleaseItemFieldsFragmentDoc}
  ${DistributorDeliverySlipFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
  ${OrderTypeSummaryFieldsFragmentDoc}
  ${DistributorInvoiceIssueFieldsFragmentDoc}
`;
export const CurrenciesDocument = gql`
  query Currencies {
    currencies {
      ...CurrencyFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    options,
  );
}
export function useCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    options,
  );
}
export function useCurrenciesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CurrenciesQuery,
        CurrenciesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    options,
  );
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<
  typeof useCurrenciesLazyQuery
>;
export type CurrenciesSuspenseQueryHookResult = ReturnType<
  typeof useCurrenciesSuspenseQuery
>;
export type CurrenciesQueryResult = Apollo.QueryResult<
  CurrenciesQuery,
  CurrenciesQueryVariables
>;
export const OrgNotificationCountDocument = gql`
  query OrgNotificationCount {
    viewer {
      id
      org {
        id
        enterprise {
          id
          orgs {
            id
            notificationCount(limit: 10)
          }
        }
      }
    }
  }
`;

/**
 * __useOrgNotificationCountQuery__
 *
 * To run a query within a React component, call `useOrgNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgNotificationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgNotificationCountQuery,
    OrgNotificationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgNotificationCountQuery,
    OrgNotificationCountQueryVariables
  >(OrgNotificationCountDocument, options);
}
export function useOrgNotificationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgNotificationCountQuery,
    OrgNotificationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgNotificationCountQuery,
    OrgNotificationCountQueryVariables
  >(OrgNotificationCountDocument, options);
}
export function useOrgNotificationCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgNotificationCountQuery,
        OrgNotificationCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrgNotificationCountQuery,
    OrgNotificationCountQueryVariables
  >(OrgNotificationCountDocument, options);
}
export type OrgNotificationCountQueryHookResult = ReturnType<
  typeof useOrgNotificationCountQuery
>;
export type OrgNotificationCountLazyQueryHookResult = ReturnType<
  typeof useOrgNotificationCountLazyQuery
>;
export type OrgNotificationCountSuspenseQueryHookResult = ReturnType<
  typeof useOrgNotificationCountSuspenseQuery
>;
export type OrgNotificationCountQueryResult = Apollo.QueryResult<
  OrgNotificationCountQuery,
  OrgNotificationCountQueryVariables
>;
export const UpdateInvoiceOptionsDocument = gql`
  mutation UpdateInvoiceOptions($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      id
      retentionPercentage
      retentionAmount
      accountingDate
      discountPercentage
      discountAmount
      discountDate
      dueDate
      paymentMethod {
        id
      }
    }
  }
`;
export type UpdateInvoiceOptionsMutationFn = Apollo.MutationFunction<
  UpdateInvoiceOptionsMutation,
  UpdateInvoiceOptionsMutationVariables
>;

/**
 * __useUpdateInvoiceOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceOptionsMutation, { data, loading, error }] = useUpdateInvoiceOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceOptionsMutation,
    UpdateInvoiceOptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceOptionsMutation,
    UpdateInvoiceOptionsMutationVariables
  >(UpdateInvoiceOptionsDocument, options);
}
export type UpdateInvoiceOptionsMutationHookResult = ReturnType<
  typeof useUpdateInvoiceOptionsMutation
>;
export type UpdateInvoiceOptionsMutationResult =
  Apollo.MutationResult<UpdateInvoiceOptionsMutation>;
export type UpdateInvoiceOptionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceOptionsMutation,
  UpdateInvoiceOptionsMutationVariables
>;
export const MarkMessageThreadAsReadDocument = gql`
  mutation MarkMessageThreadAsRead($input: MarkMessageThreadAsReadInput!) {
    markMessageThreadAsRead(input: $input) {
      id
    }
  }
`;
export type MarkMessageThreadAsReadMutationFn = Apollo.MutationFunction<
  MarkMessageThreadAsReadMutation,
  MarkMessageThreadAsReadMutationVariables
>;

/**
 * __useMarkMessageThreadAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageThreadAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageThreadAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageThreadAsReadMutation, { data, loading, error }] = useMarkMessageThreadAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkMessageThreadAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkMessageThreadAsReadMutation,
    MarkMessageThreadAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkMessageThreadAsReadMutation,
    MarkMessageThreadAsReadMutationVariables
  >(MarkMessageThreadAsReadDocument, options);
}
export type MarkMessageThreadAsReadMutationHookResult = ReturnType<
  typeof useMarkMessageThreadAsReadMutation
>;
export type MarkMessageThreadAsReadMutationResult =
  Apollo.MutationResult<MarkMessageThreadAsReadMutation>;
export type MarkMessageThreadAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkMessageThreadAsReadMutation,
  MarkMessageThreadAsReadMutationVariables
>;
export const MessageStatsDocument = gql`
  query MessageStats($input: MessageStatsInput!) {
    messageStats(input: $input) {
      unreadPrivate
      unreadPublic
    }
  }
`;

/**
 * __useMessageStatsQuery__
 *
 * To run a query within a React component, call `useMessageStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessageStatsQuery,
    MessageStatsQueryVariables
  > &
    (
      | { variables: MessageStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageStatsQuery, MessageStatsQueryVariables>(
    MessageStatsDocument,
    options,
  );
}
export function useMessageStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageStatsQuery,
    MessageStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessageStatsQuery, MessageStatsQueryVariables>(
    MessageStatsDocument,
    options,
  );
}
export function useMessageStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MessageStatsQuery,
        MessageStatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MessageStatsQuery, MessageStatsQueryVariables>(
    MessageStatsDocument,
    options,
  );
}
export type MessageStatsQueryHookResult = ReturnType<
  typeof useMessageStatsQuery
>;
export type MessageStatsLazyQueryHookResult = ReturnType<
  typeof useMessageStatsLazyQuery
>;
export type MessageStatsSuspenseQueryHookResult = ReturnType<
  typeof useMessageStatsSuspenseQuery
>;
export type MessageStatsQueryResult = Apollo.QueryResult<
  MessageStatsQuery,
  MessageStatsQueryVariables
>;
export const DeleteNotificationDocument = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >(DeleteNotificationDocument, options);
}
export type DeleteNotificationMutationHookResult = ReturnType<
  typeof useDeleteNotificationMutation
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
export const ReadNotificationDocument = gql`
  mutation ReadNotification($input: ReadNotificationInput!) {
    readNotification(input: $input) {
      id
      readAt
    }
  }
`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadNotificationMutation,
    ReadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadNotificationMutation,
    ReadNotificationMutationVariables
  >(ReadNotificationDocument, options);
}
export type ReadNotificationMutationHookResult = ReturnType<
  typeof useReadNotificationMutation
>;
export type ReadNotificationMutationResult =
  Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
>;
export const ResendExternalNotificationDocument = gql`
  mutation ResendExternalNotification(
    $input: ResendExternalNotificationInput!
  ) {
    resendExternalNotification(input: $input) {
      id
    }
  }
`;
export type ResendExternalNotificationMutationFn = Apollo.MutationFunction<
  ResendExternalNotificationMutation,
  ResendExternalNotificationMutationVariables
>;

/**
 * __useResendExternalNotificationMutation__
 *
 * To run a mutation, you first call `useResendExternalNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendExternalNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendExternalNotificationMutation, { data, loading, error }] = useResendExternalNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendExternalNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendExternalNotificationMutation,
    ResendExternalNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendExternalNotificationMutation,
    ResendExternalNotificationMutationVariables
  >(ResendExternalNotificationDocument, options);
}
export type ResendExternalNotificationMutationHookResult = ReturnType<
  typeof useResendExternalNotificationMutation
>;
export type ResendExternalNotificationMutationResult =
  Apollo.MutationResult<ResendExternalNotificationMutation>;
export type ResendExternalNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    ResendExternalNotificationMutation,
    ResendExternalNotificationMutationVariables
  >;
export const ExternalNotificationsDocument = gql`
  query ExternalNotifications(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryNotificationsFilter
  ) {
    externalNotifications(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        node {
          ...ExternalNotificationFields
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${ExternalNotificationFieldsFragmentDoc}
`;

/**
 * __useExternalNotificationsQuery__
 *
 * To run a query within a React component, call `useExternalNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExternalNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExternalNotificationsQuery,
    ExternalNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalNotificationsQuery,
    ExternalNotificationsQueryVariables
  >(ExternalNotificationsDocument, options);
}
export function useExternalNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalNotificationsQuery,
    ExternalNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalNotificationsQuery,
    ExternalNotificationsQueryVariables
  >(ExternalNotificationsDocument, options);
}
export function useExternalNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalNotificationsQuery,
        ExternalNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalNotificationsQuery,
    ExternalNotificationsQueryVariables
  >(ExternalNotificationsDocument, options);
}
export type ExternalNotificationsQueryHookResult = ReturnType<
  typeof useExternalNotificationsQuery
>;
export type ExternalNotificationsLazyQueryHookResult = ReturnType<
  typeof useExternalNotificationsLazyQuery
>;
export type ExternalNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useExternalNotificationsSuspenseQuery
>;
export type ExternalNotificationsQueryResult = Apollo.QueryResult<
  ExternalNotificationsQuery,
  ExternalNotificationsQueryVariables
>;
export const NotificationsDocument = gql`
  query Notifications($first: Int, $filter: QueryNotificationsFilter) {
    notifications(first: $first, filter: $filter) {
      edges {
        node {
          ...NotificationFields
        }
      }
    }
  }
  ${NotificationFieldsFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export function useNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NotificationsQuery,
        NotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, options);
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsSuspenseQueryHookResult = ReturnType<
  typeof useNotificationsSuspenseQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const OrderTypeSummariesDocument = gql`
  query OrderTypeSummaries($id: ID!, $archived: Boolean = false) {
    org(id: $id) {
      id
      releaseTypes(archived: $archived) {
        ...OrderTypeSummaryFields
      }
    }
  }
  ${OrderTypeSummaryFieldsFragmentDoc}
`;

/**
 * __useOrderTypeSummariesQuery__
 *
 * To run a query within a React component, call `useOrderTypeSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTypeSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTypeSummariesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useOrderTypeSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderTypeSummariesQuery,
    OrderTypeSummariesQueryVariables
  > &
    (
      | { variables: OrderTypeSummariesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderTypeSummariesQuery,
    OrderTypeSummariesQueryVariables
  >(OrderTypeSummariesDocument, options);
}
export function useOrderTypeSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderTypeSummariesQuery,
    OrderTypeSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderTypeSummariesQuery,
    OrderTypeSummariesQueryVariables
  >(OrderTypeSummariesDocument, options);
}
export function useOrderTypeSummariesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderTypeSummariesQuery,
        OrderTypeSummariesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderTypeSummariesQuery,
    OrderTypeSummariesQueryVariables
  >(OrderTypeSummariesDocument, options);
}
export type OrderTypeSummariesQueryHookResult = ReturnType<
  typeof useOrderTypeSummariesQuery
>;
export type OrderTypeSummariesLazyQueryHookResult = ReturnType<
  typeof useOrderTypeSummariesLazyQuery
>;
export type OrderTypeSummariesSuspenseQueryHookResult = ReturnType<
  typeof useOrderTypeSummariesSuspenseQuery
>;
export type OrderTypeSummariesQueryResult = Apollo.QueryResult<
  OrderTypeSummariesQuery,
  OrderTypeSummariesQueryVariables
>;
export const TaxCodesSummaryDocument = gql`
  query TaxCodesSummary {
    viewer {
      id
      org {
        id
        taxCodes(archived: false) {
          ...TaxCodeSummaryFields
        }
      }
    }
  }
  ${TaxCodeSummaryFieldsFragmentDoc}
`;

/**
 * __useTaxCodesSummaryQuery__
 *
 * To run a query within a React component, call `useTaxCodesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxCodesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxCodesSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaxCodesSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaxCodesSummaryQuery,
    TaxCodesSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxCodesSummaryQuery, TaxCodesSummaryQueryVariables>(
    TaxCodesSummaryDocument,
    options,
  );
}
export function useTaxCodesSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxCodesSummaryQuery,
    TaxCodesSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaxCodesSummaryQuery,
    TaxCodesSummaryQueryVariables
  >(TaxCodesSummaryDocument, options);
}
export function useTaxCodesSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TaxCodesSummaryQuery,
        TaxCodesSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TaxCodesSummaryQuery,
    TaxCodesSummaryQueryVariables
  >(TaxCodesSummaryDocument, options);
}
export type TaxCodesSummaryQueryHookResult = ReturnType<
  typeof useTaxCodesSummaryQuery
>;
export type TaxCodesSummaryLazyQueryHookResult = ReturnType<
  typeof useTaxCodesSummaryLazyQuery
>;
export type TaxCodesSummarySuspenseQueryHookResult = ReturnType<
  typeof useTaxCodesSummarySuspenseQuery
>;
export type TaxCodesSummaryQueryResult = Apollo.QueryResult<
  TaxCodesSummaryQuery,
  TaxCodesSummaryQueryVariables
>;
export const OrgPreferredVendorsDocument = gql`
  query orgPreferredVendors(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryOrgPreferredVendorsFilter
  ) {
    orgPreferredVendorsConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...OrgPreferredVendorsFields
        }
      }
    }
  }
  ${OrgPreferredVendorsFieldsFragmentDoc}
`;

/**
 * __useOrgPreferredVendorsQuery__
 *
 * To run a query within a React component, call `useOrgPreferredVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPreferredVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPreferredVendorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrgPreferredVendorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgPreferredVendorsQuery,
    OrgPreferredVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgPreferredVendorsQuery,
    OrgPreferredVendorsQueryVariables
  >(OrgPreferredVendorsDocument, options);
}
export function useOrgPreferredVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgPreferredVendorsQuery,
    OrgPreferredVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgPreferredVendorsQuery,
    OrgPreferredVendorsQueryVariables
  >(OrgPreferredVendorsDocument, options);
}
export function useOrgPreferredVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgPreferredVendorsQuery,
        OrgPreferredVendorsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrgPreferredVendorsQuery,
    OrgPreferredVendorsQueryVariables
  >(OrgPreferredVendorsDocument, options);
}
export type OrgPreferredVendorsQueryHookResult = ReturnType<
  typeof useOrgPreferredVendorsQuery
>;
export type OrgPreferredVendorsLazyQueryHookResult = ReturnType<
  typeof useOrgPreferredVendorsLazyQuery
>;
export type OrgPreferredVendorsSuspenseQueryHookResult = ReturnType<
  typeof useOrgPreferredVendorsSuspenseQuery
>;
export type OrgPreferredVendorsQueryResult = Apollo.QueryResult<
  OrgPreferredVendorsQuery,
  OrgPreferredVendorsQueryVariables
>;
export const AcceptQuoteDocument = gql`
  mutation AcceptQuote(
    $input: AcceptQuoteInput!
    $quoteStatus: [QuoteStatus!]
  ) {
    acceptQuote(input: $input) {
      ...RfqQuotesFields
    }
  }
  ${RfqQuotesFieldsFragmentDoc}
`;
export type AcceptQuoteMutationFn = Apollo.MutationFunction<
  AcceptQuoteMutation,
  AcceptQuoteMutationVariables
>;

/**
 * __useAcceptQuoteMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteMutation, { data, loading, error }] = useAcceptQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      quoteStatus: // value for 'quoteStatus'
 *   },
 * });
 */
export function useAcceptQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptQuoteMutation,
    AcceptQuoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptQuoteMutation, AcceptQuoteMutationVariables>(
    AcceptQuoteDocument,
    options,
  );
}
export type AcceptQuoteMutationHookResult = ReturnType<
  typeof useAcceptQuoteMutation
>;
export type AcceptQuoteMutationResult =
  Apollo.MutationResult<AcceptQuoteMutation>;
export type AcceptQuoteMutationOptions = Apollo.BaseMutationOptions<
  AcceptQuoteMutation,
  AcceptQuoteMutationVariables
>;
export const AddEstimatedItemDocument = gql`
  mutation AddEstimatedItem($input: AddEstimatedItemInput!) {
    addEstimatedItem(input: $input) {
      ...EstimatedItemExtendedFields
      item {
        ...ProjectItemExtendedFields
      }
    }
  }
  ${EstimatedItemExtendedFieldsFragmentDoc}
  ${ProjectItemExtendedFieldsFragmentDoc}
`;
export type AddEstimatedItemMutationFn = Apollo.MutationFunction<
  AddEstimatedItemMutation,
  AddEstimatedItemMutationVariables
>;

/**
 * __useAddEstimatedItemMutation__
 *
 * To run a mutation, you first call `useAddEstimatedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEstimatedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEstimatedItemMutation, { data, loading, error }] = useAddEstimatedItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEstimatedItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEstimatedItemMutation,
    AddEstimatedItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEstimatedItemMutation,
    AddEstimatedItemMutationVariables
  >(AddEstimatedItemDocument, options);
}
export type AddEstimatedItemMutationHookResult = ReturnType<
  typeof useAddEstimatedItemMutation
>;
export type AddEstimatedItemMutationResult =
  Apollo.MutationResult<AddEstimatedItemMutation>;
export type AddEstimatedItemMutationOptions = Apollo.BaseMutationOptions<
  AddEstimatedItemMutation,
  AddEstimatedItemMutationVariables
>;
export const AddToReleaseDocument = gql`
  mutation AddToRelease(
    $input: AddToReleaseInput!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    addToRelease(input: $input) {
      ...ReleaseItemFields
    }
  }
  ${ReleaseItemFieldsFragmentDoc}
`;
export type AddToReleaseMutationFn = Apollo.MutationFunction<
  AddToReleaseMutation,
  AddToReleaseMutationVariables
>;

/**
 * __useAddToReleaseMutation__
 *
 * To run a mutation, you first call `useAddToReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToReleaseMutation, { data, loading, error }] = useAddToReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useAddToReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToReleaseMutation,
    AddToReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddToReleaseMutation,
    AddToReleaseMutationVariables
  >(AddToReleaseDocument, options);
}
export type AddToReleaseMutationHookResult = ReturnType<
  typeof useAddToReleaseMutation
>;
export type AddToReleaseMutationResult =
  Apollo.MutationResult<AddToReleaseMutation>;
export type AddToReleaseMutationOptions = Apollo.BaseMutationOptions<
  AddToReleaseMutation,
  AddToReleaseMutationVariables
>;
export const AddVendorLocationDocument = gql`
  mutation AddVendorLocation($input: AddOrgLocationInput!) {
    addVendorLocation(input: $input) {
      ...OrgLocationFields
    }
  }
  ${OrgLocationFieldsFragmentDoc}
`;
export type AddVendorLocationMutationFn = Apollo.MutationFunction<
  AddVendorLocationMutation,
  AddVendorLocationMutationVariables
>;

/**
 * __useAddVendorLocationMutation__
 *
 * To run a mutation, you first call `useAddVendorLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorLocationMutation, { data, loading, error }] = useAddVendorLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVendorLocationMutation,
    AddVendorLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddVendorLocationMutation,
    AddVendorLocationMutationVariables
  >(AddVendorLocationDocument, options);
}
export type AddVendorLocationMutationHookResult = ReturnType<
  typeof useAddVendorLocationMutation
>;
export type AddVendorLocationMutationResult =
  Apollo.MutationResult<AddVendorLocationMutation>;
export type AddVendorLocationMutationOptions = Apollo.BaseMutationOptions<
  AddVendorLocationMutation,
  AddVendorLocationMutationVariables
>;
export const AddVendorOrgDocument = gql`
  mutation AddVendorOrg($input: AddVendorOrgInput!) {
    addVendorOrg(input: $input) {
      ...OrgFields
    }
  }
  ${OrgFieldsFragmentDoc}
`;
export type AddVendorOrgMutationFn = Apollo.MutationFunction<
  AddVendorOrgMutation,
  AddVendorOrgMutationVariables
>;

/**
 * __useAddVendorOrgMutation__
 *
 * To run a mutation, you first call `useAddVendorOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorOrgMutation, { data, loading, error }] = useAddVendorOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVendorOrgMutation,
    AddVendorOrgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddVendorOrgMutation,
    AddVendorOrgMutationVariables
  >(AddVendorOrgDocument, options);
}
export type AddVendorOrgMutationHookResult = ReturnType<
  typeof useAddVendorOrgMutation
>;
export type AddVendorOrgMutationResult =
  Apollo.MutationResult<AddVendorOrgMutation>;
export type AddVendorOrgMutationOptions = Apollo.BaseMutationOptions<
  AddVendorOrgMutation,
  AddVendorOrgMutationVariables
>;
export const AddZoneDocument = gql`
  mutation AddZone($input: AddZoneInput!) {
    addZone(input: $input) {
      ...ZoneFields
    }
  }
  ${ZoneFieldsFragmentDoc}
`;
export type AddZoneMutationFn = Apollo.MutationFunction<
  AddZoneMutation,
  AddZoneMutationVariables
>;

/**
 * __useAddZoneMutation__
 *
 * To run a mutation, you first call `useAddZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addZoneMutation, { data, loading, error }] = useAddZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddZoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddZoneMutation,
    AddZoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddZoneMutation, AddZoneMutationVariables>(
    AddZoneDocument,
    options,
  );
}
export type AddZoneMutationHookResult = ReturnType<typeof useAddZoneMutation>;
export type AddZoneMutationResult = Apollo.MutationResult<AddZoneMutation>;
export type AddZoneMutationOptions = Apollo.BaseMutationOptions<
  AddZoneMutation,
  AddZoneMutationVariables
>;
export const AddZonesDocument = gql`
  mutation AddZones($input: AddZonesInput!) {
    addZones(input: $input) {
      ...ZoneFields
    }
  }
  ${ZoneFieldsFragmentDoc}
`;
export type AddZonesMutationFn = Apollo.MutationFunction<
  AddZonesMutation,
  AddZonesMutationVariables
>;

/**
 * __useAddZonesMutation__
 *
 * To run a mutation, you first call `useAddZonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddZonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addZonesMutation, { data, loading, error }] = useAddZonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddZonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddZonesMutation,
    AddZonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddZonesMutation, AddZonesMutationVariables>(
    AddZonesDocument,
    options,
  );
}
export type AddZonesMutationHookResult = ReturnType<typeof useAddZonesMutation>;
export type AddZonesMutationResult = Apollo.MutationResult<AddZonesMutation>;
export type AddZonesMutationOptions = Apollo.BaseMutationOptions<
  AddZonesMutation,
  AddZonesMutationVariables
>;
export const ArchiveReleaseDocument = gql`
  mutation ArchiveRelease($id: ID!) {
    archiveRelease(id: $id) {
      id
      deletedAt
    }
  }
`;
export type ArchiveReleaseMutationFn = Apollo.MutationFunction<
  ArchiveReleaseMutation,
  ArchiveReleaseMutationVariables
>;

/**
 * __useArchiveReleaseMutation__
 *
 * To run a mutation, you first call `useArchiveReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveReleaseMutation, { data, loading, error }] = useArchiveReleaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveReleaseMutation,
    ArchiveReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveReleaseMutation,
    ArchiveReleaseMutationVariables
  >(ArchiveReleaseDocument, options);
}
export type ArchiveReleaseMutationHookResult = ReturnType<
  typeof useArchiveReleaseMutation
>;
export type ArchiveReleaseMutationResult =
  Apollo.MutationResult<ArchiveReleaseMutation>;
export type ArchiveReleaseMutationOptions = Apollo.BaseMutationOptions<
  ArchiveReleaseMutation,
  ArchiveReleaseMutationVariables
>;
export const CancelRfqDocument = gql`
  mutation CancelRfq($rfqID: ID!) {
    cancelRfq(rfqID: $rfqID)
  }
`;
export type CancelRfqMutationFn = Apollo.MutationFunction<
  CancelRfqMutation,
  CancelRfqMutationVariables
>;

/**
 * __useCancelRfqMutation__
 *
 * To run a mutation, you first call `useCancelRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRfqMutation, { data, loading, error }] = useCancelRfqMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useCancelRfqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRfqMutation,
    CancelRfqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelRfqMutation, CancelRfqMutationVariables>(
    CancelRfqDocument,
    options,
  );
}
export type CancelRfqMutationHookResult = ReturnType<
  typeof useCancelRfqMutation
>;
export type CancelRfqMutationResult = Apollo.MutationResult<CancelRfqMutation>;
export type CancelRfqMutationOptions = Apollo.BaseMutationOptions<
  CancelRfqMutation,
  CancelRfqMutationVariables
>;
export const CancelRfqBuyoutsDocument = gql`
  mutation cancelRFQBuyouts($rfqID: ID!) {
    cancelRFQBuyouts(rfqID: $rfqID) {
      id
    }
  }
`;
export type CancelRfqBuyoutsMutationFn = Apollo.MutationFunction<
  CancelRfqBuyoutsMutation,
  CancelRfqBuyoutsMutationVariables
>;

/**
 * __useCancelRfqBuyoutsMutation__
 *
 * To run a mutation, you first call `useCancelRfqBuyoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRfqBuyoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRfqBuyoutsMutation, { data, loading, error }] = useCancelRfqBuyoutsMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useCancelRfqBuyoutsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRfqBuyoutsMutation,
    CancelRfqBuyoutsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRfqBuyoutsMutation,
    CancelRfqBuyoutsMutationVariables
  >(CancelRfqBuyoutsDocument, options);
}
export type CancelRfqBuyoutsMutationHookResult = ReturnType<
  typeof useCancelRfqBuyoutsMutation
>;
export type CancelRfqBuyoutsMutationResult =
  Apollo.MutationResult<CancelRfqBuyoutsMutation>;
export type CancelRfqBuyoutsMutationOptions = Apollo.BaseMutationOptions<
  CancelRfqBuyoutsMutation,
  CancelRfqBuyoutsMutationVariables
>;
export const ConnectOrgPreferredVendorDocument = gql`
  mutation ConnectOrgPreferredVendor($input: ConnectNodeInput!) {
    connectOrgPreferredVendor(input: $input) {
      id
      mappings {
        ...SourceSystemMappingFields
      }
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
`;
export type ConnectOrgPreferredVendorMutationFn = Apollo.MutationFunction<
  ConnectOrgPreferredVendorMutation,
  ConnectOrgPreferredVendorMutationVariables
>;

/**
 * __useConnectOrgPreferredVendorMutation__
 *
 * To run a mutation, you first call `useConnectOrgPreferredVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectOrgPreferredVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectOrgPreferredVendorMutation, { data, loading, error }] = useConnectOrgPreferredVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectOrgPreferredVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectOrgPreferredVendorMutation,
    ConnectOrgPreferredVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectOrgPreferredVendorMutation,
    ConnectOrgPreferredVendorMutationVariables
  >(ConnectOrgPreferredVendorDocument, options);
}
export type ConnectOrgPreferredVendorMutationHookResult = ReturnType<
  typeof useConnectOrgPreferredVendorMutation
>;
export type ConnectOrgPreferredVendorMutationResult =
  Apollo.MutationResult<ConnectOrgPreferredVendorMutation>;
export type ConnectOrgPreferredVendorMutationOptions =
  Apollo.BaseMutationOptions<
    ConnectOrgPreferredVendorMutation,
    ConnectOrgPreferredVendorMutationVariables
  >;
export const ConnectSourceSystemDocument = gql`
  mutation ConnectSourceSystem($input: ConnectSourceSystemInput!) {
    connectSourceSystem(input: $input) {
      ...SourceSystemSettingFields
    }
  }
  ${SourceSystemSettingFieldsFragmentDoc}
`;
export type ConnectSourceSystemMutationFn = Apollo.MutationFunction<
  ConnectSourceSystemMutation,
  ConnectSourceSystemMutationVariables
>;

/**
 * __useConnectSourceSystemMutation__
 *
 * To run a mutation, you first call `useConnectSourceSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectSourceSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectSourceSystemMutation, { data, loading, error }] = useConnectSourceSystemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectSourceSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectSourceSystemMutation,
    ConnectSourceSystemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectSourceSystemMutation,
    ConnectSourceSystemMutationVariables
  >(ConnectSourceSystemDocument, options);
}
export type ConnectSourceSystemMutationHookResult = ReturnType<
  typeof useConnectSourceSystemMutation
>;
export type ConnectSourceSystemMutationResult =
  Apollo.MutationResult<ConnectSourceSystemMutation>;
export type ConnectSourceSystemMutationOptions = Apollo.BaseMutationOptions<
  ConnectSourceSystemMutation,
  ConnectSourceSystemMutationVariables
>;
export const CreateAssetDocument = gql`
  mutation CreateAsset($input: UploadAssetInput!) {
    createAsset(input: $input) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetMutation,
    CreateAssetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options,
  );
}
export type CreateAssetMutationHookResult = ReturnType<
  typeof useCreateAssetMutation
>;
export type CreateAssetMutationResult =
  Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;
export const CreateBuyoutDocument = gql`
  mutation CreateBuyout($input: CreateBuyoutInput!) {
    createBuyout(input: $input) {
      id
      clientIdentifier
      version
    }
  }
`;
export type CreateBuyoutMutationFn = Apollo.MutationFunction<
  CreateBuyoutMutation,
  CreateBuyoutMutationVariables
>;

/**
 * __useCreateBuyoutMutation__
 *
 * To run a mutation, you first call `useCreateBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuyoutMutation, { data, loading, error }] = useCreateBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBuyoutMutation,
    CreateBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBuyoutMutation,
    CreateBuyoutMutationVariables
  >(CreateBuyoutDocument, options);
}
export type CreateBuyoutMutationHookResult = ReturnType<
  typeof useCreateBuyoutMutation
>;
export type CreateBuyoutMutationResult =
  Apollo.MutationResult<CreateBuyoutMutation>;
export type CreateBuyoutMutationOptions = Apollo.BaseMutationOptions<
  CreateBuyoutMutation,
  CreateBuyoutMutationVariables
>;
export const CreateCustomFileFormatDocument = gql`
  mutation CreateCustomFileFormat($input: CreateCustomFileFormatInput!) {
    createCustomFileFormat(input: $input) {
      id
    }
  }
`;
export type CreateCustomFileFormatMutationFn = Apollo.MutationFunction<
  CreateCustomFileFormatMutation,
  CreateCustomFileFormatMutationVariables
>;

/**
 * __useCreateCustomFileFormatMutation__
 *
 * To run a mutation, you first call `useCreateCustomFileFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomFileFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomFileFormatMutation, { data, loading, error }] = useCreateCustomFileFormatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomFileFormatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomFileFormatMutation,
    CreateCustomFileFormatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomFileFormatMutation,
    CreateCustomFileFormatMutationVariables
  >(CreateCustomFileFormatDocument, options);
}
export type CreateCustomFileFormatMutationHookResult = ReturnType<
  typeof useCreateCustomFileFormatMutation
>;
export type CreateCustomFileFormatMutationResult =
  Apollo.MutationResult<CreateCustomFileFormatMutation>;
export type CreateCustomFileFormatMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomFileFormatMutation,
  CreateCustomFileFormatMutationVariables
>;
export const CreateEditCopyRfqForChangesDocument = gql`
  mutation CreateEditCopyRfqForChanges($rfqID: ID!) {
    createEditCopyRfqForChanges(rfqID: $rfqID) {
      id
      clientIdentifier
    }
  }
`;
export type CreateEditCopyRfqForChangesMutationFn = Apollo.MutationFunction<
  CreateEditCopyRfqForChangesMutation,
  CreateEditCopyRfqForChangesMutationVariables
>;

/**
 * __useCreateEditCopyRfqForChangesMutation__
 *
 * To run a mutation, you first call `useCreateEditCopyRfqForChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditCopyRfqForChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditCopyRfqForChangesMutation, { data, loading, error }] = useCreateEditCopyRfqForChangesMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useCreateEditCopyRfqForChangesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEditCopyRfqForChangesMutation,
    CreateEditCopyRfqForChangesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEditCopyRfqForChangesMutation,
    CreateEditCopyRfqForChangesMutationVariables
  >(CreateEditCopyRfqForChangesDocument, options);
}
export type CreateEditCopyRfqForChangesMutationHookResult = ReturnType<
  typeof useCreateEditCopyRfqForChangesMutation
>;
export type CreateEditCopyRfqForChangesMutationResult =
  Apollo.MutationResult<CreateEditCopyRfqForChangesMutation>;
export type CreateEditCopyRfqForChangesMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEditCopyRfqForChangesMutation,
    CreateEditCopyRfqForChangesMutationVariables
  >;
export const CreateManufacturerDocument = gql`
  mutation CreateManufacturer($input: CreateManufacturerInput!) {
    createManufacturer(input: $input) {
      id
      name
    }
  }
`;
export type CreateManufacturerMutationFn = Apollo.MutationFunction<
  CreateManufacturerMutation,
  CreateManufacturerMutationVariables
>;

/**
 * __useCreateManufacturerMutation__
 *
 * To run a mutation, you first call `useCreateManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManufacturerMutation, { data, loading, error }] = useCreateManufacturerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManufacturerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManufacturerMutation,
    CreateManufacturerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManufacturerMutation,
    CreateManufacturerMutationVariables
  >(CreateManufacturerDocument, options);
}
export type CreateManufacturerMutationHookResult = ReturnType<
  typeof useCreateManufacturerMutation
>;
export type CreateManufacturerMutationResult =
  Apollo.MutationResult<CreateManufacturerMutation>;
export type CreateManufacturerMutationOptions = Apollo.BaseMutationOptions<
  CreateManufacturerMutation,
  CreateManufacturerMutationVariables
>;
export const CreateManufacturersDocument = gql`
  mutation CreateManufacturers($input: [CreateManufacturerInput!]!) {
    createManufacturers(input: $input) {
      id
      name
    }
  }
`;
export type CreateManufacturersMutationFn = Apollo.MutationFunction<
  CreateManufacturersMutation,
  CreateManufacturersMutationVariables
>;

/**
 * __useCreateManufacturersMutation__
 *
 * To run a mutation, you first call `useCreateManufacturersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManufacturersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManufacturersMutation, { data, loading, error }] = useCreateManufacturersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManufacturersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManufacturersMutation,
    CreateManufacturersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManufacturersMutation,
    CreateManufacturersMutationVariables
  >(CreateManufacturersDocument, options);
}
export type CreateManufacturersMutationHookResult = ReturnType<
  typeof useCreateManufacturersMutation
>;
export type CreateManufacturersMutationResult =
  Apollo.MutationResult<CreateManufacturersMutation>;
export type CreateManufacturersMutationOptions = Apollo.BaseMutationOptions<
  CreateManufacturersMutation,
  CreateManufacturersMutationVariables
>;
export const CreateOrgDocument = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      id
      name
    }
  }
`;
export type CreateOrgMutationFn = Apollo.MutationFunction<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrgMutation,
    CreateOrgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(
    CreateOrgDocument,
    options,
  );
}
export type CreateOrgMutationHookResult = ReturnType<
  typeof useCreateOrgMutation
>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;
export const CreateOrgCatalogSkuDocument = gql`
  mutation CreateOrgCatalogSku($input: CreateOrgCatalogSkuInput!) {
    createOrgCatalogSku(input: $input) {
      ...OrgSkuFields
    }
  }
  ${OrgSkuFieldsFragmentDoc}
`;
export type CreateOrgCatalogSkuMutationFn = Apollo.MutationFunction<
  CreateOrgCatalogSkuMutation,
  CreateOrgCatalogSkuMutationVariables
>;

/**
 * __useCreateOrgCatalogSkuMutation__
 *
 * To run a mutation, you first call `useCreateOrgCatalogSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgCatalogSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgCatalogSkuMutation, { data, loading, error }] = useCreateOrgCatalogSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgCatalogSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrgCatalogSkuMutation,
    CreateOrgCatalogSkuMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrgCatalogSkuMutation,
    CreateOrgCatalogSkuMutationVariables
  >(CreateOrgCatalogSkuDocument, options);
}
export type CreateOrgCatalogSkuMutationHookResult = ReturnType<
  typeof useCreateOrgCatalogSkuMutation
>;
export type CreateOrgCatalogSkuMutationResult =
  Apollo.MutationResult<CreateOrgCatalogSkuMutation>;
export type CreateOrgCatalogSkuMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgCatalogSkuMutation,
  CreateOrgCatalogSkuMutationVariables
>;
export const CreateOrgMaterialDocument = gql`
  mutation CreateOrgMaterial($input: CreateOrgMaterialInput!) {
    createOrgMaterial(input: $input) {
      ...OrgMaterialFields
    }
  }
  ${OrgMaterialFieldsFragmentDoc}
`;
export type CreateOrgMaterialMutationFn = Apollo.MutationFunction<
  CreateOrgMaterialMutation,
  CreateOrgMaterialMutationVariables
>;

/**
 * __useCreateOrgMaterialMutation__
 *
 * To run a mutation, you first call `useCreateOrgMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMaterialMutation, { data, loading, error }] = useCreateOrgMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMaterialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrgMaterialMutation,
    CreateOrgMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrgMaterialMutation,
    CreateOrgMaterialMutationVariables
  >(CreateOrgMaterialDocument, options);
}
export type CreateOrgMaterialMutationHookResult = ReturnType<
  typeof useCreateOrgMaterialMutation
>;
export type CreateOrgMaterialMutationResult =
  Apollo.MutationResult<CreateOrgMaterialMutation>;
export type CreateOrgMaterialMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgMaterialMutation,
  CreateOrgMaterialMutationVariables
>;
export const CreateQuoteDocumentDocument = gql`
  mutation CreateQuoteDocument($input: CreateQuoteDocumentInput!) {
    createQuoteDocument(input: $input) {
      ...QuoteDocumentFields
    }
  }
  ${QuoteDocumentFieldsFragmentDoc}
`;
export type CreateQuoteDocumentMutationFn = Apollo.MutationFunction<
  CreateQuoteDocumentMutation,
  CreateQuoteDocumentMutationVariables
>;

/**
 * __useCreateQuoteDocumentMutation__
 *
 * To run a mutation, you first call `useCreateQuoteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteDocumentMutation, { data, loading, error }] = useCreateQuoteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuoteDocumentMutation,
    CreateQuoteDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuoteDocumentMutation,
    CreateQuoteDocumentMutationVariables
  >(CreateQuoteDocumentDocument, options);
}
export type CreateQuoteDocumentMutationHookResult = ReturnType<
  typeof useCreateQuoteDocumentMutation
>;
export type CreateQuoteDocumentMutationResult =
  Apollo.MutationResult<CreateQuoteDocumentMutation>;
export type CreateQuoteDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateQuoteDocumentMutation,
  CreateQuoteDocumentMutationVariables
>;
export const CreateReservedReleaseDocument = gql`
  mutation CreateReservedRelease(
    $input: CreateReservedReleaseInput!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    createReservedRelease(input: $input) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;
export type CreateReservedReleaseMutationFn = Apollo.MutationFunction<
  CreateReservedReleaseMutation,
  CreateReservedReleaseMutationVariables
>;

/**
 * __useCreateReservedReleaseMutation__
 *
 * To run a mutation, you first call `useCreateReservedReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservedReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservedReleaseMutation, { data, loading, error }] = useCreateReservedReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useCreateReservedReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReservedReleaseMutation,
    CreateReservedReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReservedReleaseMutation,
    CreateReservedReleaseMutationVariables
  >(CreateReservedReleaseDocument, options);
}
export type CreateReservedReleaseMutationHookResult = ReturnType<
  typeof useCreateReservedReleaseMutation
>;
export type CreateReservedReleaseMutationResult =
  Apollo.MutationResult<CreateReservedReleaseMutation>;
export type CreateReservedReleaseMutationOptions = Apollo.BaseMutationOptions<
  CreateReservedReleaseMutation,
  CreateReservedReleaseMutationVariables
>;
export const CreateStandaloneReleaseDocument = gql`
  mutation CreateStandaloneRelease(
    $input: CreateStandaloneReleaseInput!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    createStandaloneRelease(input: $input) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;
export type CreateStandaloneReleaseMutationFn = Apollo.MutationFunction<
  CreateStandaloneReleaseMutation,
  CreateStandaloneReleaseMutationVariables
>;

/**
 * __useCreateStandaloneReleaseMutation__
 *
 * To run a mutation, you first call `useCreateStandaloneReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandaloneReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandaloneReleaseMutation, { data, loading, error }] = useCreateStandaloneReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useCreateStandaloneReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStandaloneReleaseMutation,
    CreateStandaloneReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStandaloneReleaseMutation,
    CreateStandaloneReleaseMutationVariables
  >(CreateStandaloneReleaseDocument, options);
}
export type CreateStandaloneReleaseMutationHookResult = ReturnType<
  typeof useCreateStandaloneReleaseMutation
>;
export type CreateStandaloneReleaseMutationResult =
  Apollo.MutationResult<CreateStandaloneReleaseMutation>;
export type CreateStandaloneReleaseMutationOptions = Apollo.BaseMutationOptions<
  CreateStandaloneReleaseMutation,
  CreateStandaloneReleaseMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UsersUserFields
    }
  }
  ${UsersUserFieldsFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const CreateVendorDocument = gql`
  mutation CreateVendor($input: AddOrgPreferredVendorInput!) {
    addOrgPreferredVendor(input: $input) {
      ...AdminOrgPreferredVendorsFields
    }
  }
  ${AdminOrgPreferredVendorsFieldsFragmentDoc}
`;
export type CreateVendorMutationFn = Apollo.MutationFunction<
  CreateVendorMutation,
  CreateVendorMutationVariables
>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVendorMutation,
    CreateVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVendorMutation,
    CreateVendorMutationVariables
  >(CreateVendorDocument, options);
}
export type CreateVendorMutationHookResult = ReturnType<
  typeof useCreateVendorMutation
>;
export type CreateVendorMutationResult =
  Apollo.MutationResult<CreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<
  CreateVendorMutation,
  CreateVendorMutationVariables
>;
export const ArchiveBuyoutDocument = gql`
  mutation ArchiveBuyout($id: ID!) {
    archiveBuyout(id: $id) {
      id
    }
  }
`;
export type ArchiveBuyoutMutationFn = Apollo.MutationFunction<
  ArchiveBuyoutMutation,
  ArchiveBuyoutMutationVariables
>;

/**
 * __useArchiveBuyoutMutation__
 *
 * To run a mutation, you first call `useArchiveBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBuyoutMutation, { data, loading, error }] = useArchiveBuyoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveBuyoutMutation,
    ArchiveBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveBuyoutMutation,
    ArchiveBuyoutMutationVariables
  >(ArchiveBuyoutDocument, options);
}
export type ArchiveBuyoutMutationHookResult = ReturnType<
  typeof useArchiveBuyoutMutation
>;
export type ArchiveBuyoutMutationResult =
  Apollo.MutationResult<ArchiveBuyoutMutation>;
export type ArchiveBuyoutMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBuyoutMutation,
  ArchiveBuyoutMutationVariables
>;
export const DeleteCustomFileFormatDocument = gql`
  mutation DeleteCustomFileFormat($id: ID!) {
    deleteCustomFileFormat(id: $id) {
      id
    }
  }
`;
export type DeleteCustomFileFormatMutationFn = Apollo.MutationFunction<
  DeleteCustomFileFormatMutation,
  DeleteCustomFileFormatMutationVariables
>;

/**
 * __useDeleteCustomFileFormatMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFileFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFileFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFileFormatMutation, { data, loading, error }] = useDeleteCustomFileFormatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomFileFormatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomFileFormatMutation,
    DeleteCustomFileFormatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomFileFormatMutation,
    DeleteCustomFileFormatMutationVariables
  >(DeleteCustomFileFormatDocument, options);
}
export type DeleteCustomFileFormatMutationHookResult = ReturnType<
  typeof useDeleteCustomFileFormatMutation
>;
export type DeleteCustomFileFormatMutationResult =
  Apollo.MutationResult<DeleteCustomFileFormatMutation>;
export type DeleteCustomFileFormatMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomFileFormatMutation,
  DeleteCustomFileFormatMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const DisconnectOrgPreferredVendorDocument = gql`
  mutation DisconnectOrgPreferredVendor($input: DisconnectNodeInput!) {
    disconnectOrgPreferredVendor(input: $input) {
      id
      mappings {
        ...SourceSystemMappingFields
      }
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
`;
export type DisconnectOrgPreferredVendorMutationFn = Apollo.MutationFunction<
  DisconnectOrgPreferredVendorMutation,
  DisconnectOrgPreferredVendorMutationVariables
>;

/**
 * __useDisconnectOrgPreferredVendorMutation__
 *
 * To run a mutation, you first call `useDisconnectOrgPreferredVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectOrgPreferredVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectOrgPreferredVendorMutation, { data, loading, error }] = useDisconnectOrgPreferredVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectOrgPreferredVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectOrgPreferredVendorMutation,
    DisconnectOrgPreferredVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectOrgPreferredVendorMutation,
    DisconnectOrgPreferredVendorMutationVariables
  >(DisconnectOrgPreferredVendorDocument, options);
}
export type DisconnectOrgPreferredVendorMutationHookResult = ReturnType<
  typeof useDisconnectOrgPreferredVendorMutation
>;
export type DisconnectOrgPreferredVendorMutationResult =
  Apollo.MutationResult<DisconnectOrgPreferredVendorMutation>;
export type DisconnectOrgPreferredVendorMutationOptions =
  Apollo.BaseMutationOptions<
    DisconnectOrgPreferredVendorMutation,
    DisconnectOrgPreferredVendorMutationVariables
  >;
export const DisconnectSourceSystemDocument = gql`
  mutation DisconnectSourceSystem($input: DisconnectSourceSystemInput!) {
    disconnectSourceSystem(input: $input) {
      ...SourceSystemSettingFields
    }
  }
  ${SourceSystemSettingFieldsFragmentDoc}
`;
export type DisconnectSourceSystemMutationFn = Apollo.MutationFunction<
  DisconnectSourceSystemMutation,
  DisconnectSourceSystemMutationVariables
>;

/**
 * __useDisconnectSourceSystemMutation__
 *
 * To run a mutation, you first call `useDisconnectSourceSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectSourceSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectSourceSystemMutation, { data, loading, error }] = useDisconnectSourceSystemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectSourceSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectSourceSystemMutation,
    DisconnectSourceSystemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectSourceSystemMutation,
    DisconnectSourceSystemMutationVariables
  >(DisconnectSourceSystemDocument, options);
}
export type DisconnectSourceSystemMutationHookResult = ReturnType<
  typeof useDisconnectSourceSystemMutation
>;
export type DisconnectSourceSystemMutationResult =
  Apollo.MutationResult<DisconnectSourceSystemMutation>;
export type DisconnectSourceSystemMutationOptions = Apollo.BaseMutationOptions<
  DisconnectSourceSystemMutation,
  DisconnectSourceSystemMutationVariables
>;
export const ExportQuoteSelectionDocument = gql`
  mutation ExportQuoteSelection($input: ExportQuoteSelectionInput!) {
    exportQuoteSelection(input: $input) {
      downloadURL
    }
  }
`;
export type ExportQuoteSelectionMutationFn = Apollo.MutationFunction<
  ExportQuoteSelectionMutation,
  ExportQuoteSelectionMutationVariables
>;

/**
 * __useExportQuoteSelectionMutation__
 *
 * To run a mutation, you first call `useExportQuoteSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportQuoteSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportQuoteSelectionMutation, { data, loading, error }] = useExportQuoteSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportQuoteSelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportQuoteSelectionMutation,
    ExportQuoteSelectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportQuoteSelectionMutation,
    ExportQuoteSelectionMutationVariables
  >(ExportQuoteSelectionDocument, options);
}
export type ExportQuoteSelectionMutationHookResult = ReturnType<
  typeof useExportQuoteSelectionMutation
>;
export type ExportQuoteSelectionMutationResult =
  Apollo.MutationResult<ExportQuoteSelectionMutation>;
export type ExportQuoteSelectionMutationOptions = Apollo.BaseMutationOptions<
  ExportQuoteSelectionMutation,
  ExportQuoteSelectionMutationVariables
>;
export const ExportReleasesDocument = gql`
  mutation ExportReleases($input: ExportReleasesInput!) {
    exportReleases(input: $input) {
      fileURLs
    }
  }
`;
export type ExportReleasesMutationFn = Apollo.MutationFunction<
  ExportReleasesMutation,
  ExportReleasesMutationVariables
>;

/**
 * __useExportReleasesMutation__
 *
 * To run a mutation, you first call `useExportReleasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportReleasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportReleasesMutation, { data, loading, error }] = useExportReleasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportReleasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportReleasesMutation,
    ExportReleasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportReleasesMutation,
    ExportReleasesMutationVariables
  >(ExportReleasesDocument, options);
}
export type ExportReleasesMutationHookResult = ReturnType<
  typeof useExportReleasesMutation
>;
export type ExportReleasesMutationResult =
  Apollo.MutationResult<ExportReleasesMutation>;
export type ExportReleasesMutationOptions = Apollo.BaseMutationOptions<
  ExportReleasesMutation,
  ExportReleasesMutationVariables
>;
export const ImportBuyoutDocument = gql`
  mutation ImportBuyout($input: ImportBuyoutInput!) {
    importBuyout(input: $input) {
      id
      clientIdentifier
    }
  }
`;
export type ImportBuyoutMutationFn = Apollo.MutationFunction<
  ImportBuyoutMutation,
  ImportBuyoutMutationVariables
>;

/**
 * __useImportBuyoutMutation__
 *
 * To run a mutation, you first call `useImportBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBuyoutMutation, { data, loading, error }] = useImportBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportBuyoutMutation,
    ImportBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportBuyoutMutation,
    ImportBuyoutMutationVariables
  >(ImportBuyoutDocument, options);
}
export type ImportBuyoutMutationHookResult = ReturnType<
  typeof useImportBuyoutMutation
>;
export type ImportBuyoutMutationResult =
  Apollo.MutationResult<ImportBuyoutMutation>;
export type ImportBuyoutMutationOptions = Apollo.BaseMutationOptions<
  ImportBuyoutMutation,
  ImportBuyoutMutationVariables
>;
export const ImportOrgCatalogDocument = gql`
  mutation ImportOrgCatalog($input: ImportOrgCatalogInput!) {
    importOrgCatalog(input: $input) {
      id
    }
  }
`;
export type ImportOrgCatalogMutationFn = Apollo.MutationFunction<
  ImportOrgCatalogMutation,
  ImportOrgCatalogMutationVariables
>;

/**
 * __useImportOrgCatalogMutation__
 *
 * To run a mutation, you first call `useImportOrgCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportOrgCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importOrgCatalogMutation, { data, loading, error }] = useImportOrgCatalogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportOrgCatalogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportOrgCatalogMutation,
    ImportOrgCatalogMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportOrgCatalogMutation,
    ImportOrgCatalogMutationVariables
  >(ImportOrgCatalogDocument, options);
}
export type ImportOrgCatalogMutationHookResult = ReturnType<
  typeof useImportOrgCatalogMutation
>;
export type ImportOrgCatalogMutationResult =
  Apollo.MutationResult<ImportOrgCatalogMutation>;
export type ImportOrgCatalogMutationOptions = Apollo.BaseMutationOptions<
  ImportOrgCatalogMutation,
  ImportOrgCatalogMutationVariables
>;
export const InitializeSmsVerificationDocument = gql`
  mutation InitializeSMSVerification {
    initializeSMSVerification {
      ...SMSVerificationFields
    }
  }
  ${SmsVerificationFieldsFragmentDoc}
`;
export type InitializeSmsVerificationMutationFn = Apollo.MutationFunction<
  InitializeSmsVerificationMutation,
  InitializeSmsVerificationMutationVariables
>;

/**
 * __useInitializeSmsVerificationMutation__
 *
 * To run a mutation, you first call `useInitializeSmsVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeSmsVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeSmsVerificationMutation, { data, loading, error }] = useInitializeSmsVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitializeSmsVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitializeSmsVerificationMutation,
    InitializeSmsVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitializeSmsVerificationMutation,
    InitializeSmsVerificationMutationVariables
  >(InitializeSmsVerificationDocument, options);
}
export type InitializeSmsVerificationMutationHookResult = ReturnType<
  typeof useInitializeSmsVerificationMutation
>;
export type InitializeSmsVerificationMutationResult =
  Apollo.MutationResult<InitializeSmsVerificationMutation>;
export type InitializeSmsVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    InitializeSmsVerificationMutation,
    InitializeSmsVerificationMutationVariables
  >;
export const InitializeSourceSystemConnectionDocument = gql`
  mutation InitializeSourceSystemConnection(
    $input: InitializeSourceSystemConnectionInput!
  ) {
    initializeSourceSystemConnection(input: $input)
  }
`;
export type InitializeSourceSystemConnectionMutationFn =
  Apollo.MutationFunction<
    InitializeSourceSystemConnectionMutation,
    InitializeSourceSystemConnectionMutationVariables
  >;

/**
 * __useInitializeSourceSystemConnectionMutation__
 *
 * To run a mutation, you first call `useInitializeSourceSystemConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeSourceSystemConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeSourceSystemConnectionMutation, { data, loading, error }] = useInitializeSourceSystemConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeSourceSystemConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitializeSourceSystemConnectionMutation,
    InitializeSourceSystemConnectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitializeSourceSystemConnectionMutation,
    InitializeSourceSystemConnectionMutationVariables
  >(InitializeSourceSystemConnectionDocument, options);
}
export type InitializeSourceSystemConnectionMutationHookResult = ReturnType<
  typeof useInitializeSourceSystemConnectionMutation
>;
export type InitializeSourceSystemConnectionMutationResult =
  Apollo.MutationResult<InitializeSourceSystemConnectionMutation>;
export type InitializeSourceSystemConnectionMutationOptions =
  Apollo.BaseMutationOptions<
    InitializeSourceSystemConnectionMutation,
    InitializeSourceSystemConnectionMutationVariables
  >;
export const RefreshExternalPOsDocument = gql`
  mutation RefreshExternalPOs($projectId: ID!, $sourceSystem: SourceSystem!) {
    refreshExternalPOs(projectId: $projectId, sourceSystem: $sourceSystem)
  }
`;
export type RefreshExternalPOsMutationFn = Apollo.MutationFunction<
  RefreshExternalPOsMutation,
  RefreshExternalPOsMutationVariables
>;

/**
 * __useRefreshExternalPOsMutation__
 *
 * To run a mutation, you first call `useRefreshExternalPOsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExternalPOsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExternalPOsMutation, { data, loading, error }] = useRefreshExternalPOsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useRefreshExternalPOsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshExternalPOsMutation,
    RefreshExternalPOsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshExternalPOsMutation,
    RefreshExternalPOsMutationVariables
  >(RefreshExternalPOsDocument, options);
}
export type RefreshExternalPOsMutationHookResult = ReturnType<
  typeof useRefreshExternalPOsMutation
>;
export type RefreshExternalPOsMutationResult =
  Apollo.MutationResult<RefreshExternalPOsMutation>;
export type RefreshExternalPOsMutationOptions = Apollo.BaseMutationOptions<
  RefreshExternalPOsMutation,
  RefreshExternalPOsMutationVariables
>;
export const RemoveEstimatedItemDocument = gql`
  mutation RemoveEstimatedItem($id: ID!) {
    removeEstimatedItem(itemId: $id) {
      id
      item {
        ...ProjectItemFields
      }
    }
  }
  ${ProjectItemFieldsFragmentDoc}
`;
export type RemoveEstimatedItemMutationFn = Apollo.MutationFunction<
  RemoveEstimatedItemMutation,
  RemoveEstimatedItemMutationVariables
>;

/**
 * __useRemoveEstimatedItemMutation__
 *
 * To run a mutation, you first call `useRemoveEstimatedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEstimatedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEstimatedItemMutation, { data, loading, error }] = useRemoveEstimatedItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEstimatedItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEstimatedItemMutation,
    RemoveEstimatedItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEstimatedItemMutation,
    RemoveEstimatedItemMutationVariables
  >(RemoveEstimatedItemDocument, options);
}
export type RemoveEstimatedItemMutationHookResult = ReturnType<
  typeof useRemoveEstimatedItemMutation
>;
export type RemoveEstimatedItemMutationResult =
  Apollo.MutationResult<RemoveEstimatedItemMutation>;
export type RemoveEstimatedItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveEstimatedItemMutation,
  RemoveEstimatedItemMutationVariables
>;
export const RemoveEstimatedItemsDocument = gql`
  mutation RemoveEstimatedItems($input: RemoveEstimatedItemsInput!) {
    removeEstimatedItems(input: $input) {
      zone {
        id
      }
    }
  }
`;
export type RemoveEstimatedItemsMutationFn = Apollo.MutationFunction<
  RemoveEstimatedItemsMutation,
  RemoveEstimatedItemsMutationVariables
>;

/**
 * __useRemoveEstimatedItemsMutation__
 *
 * To run a mutation, you first call `useRemoveEstimatedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEstimatedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEstimatedItemsMutation, { data, loading, error }] = useRemoveEstimatedItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEstimatedItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEstimatedItemsMutation,
    RemoveEstimatedItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEstimatedItemsMutation,
    RemoveEstimatedItemsMutationVariables
  >(RemoveEstimatedItemsDocument, options);
}
export type RemoveEstimatedItemsMutationHookResult = ReturnType<
  typeof useRemoveEstimatedItemsMutation
>;
export type RemoveEstimatedItemsMutationResult =
  Apollo.MutationResult<RemoveEstimatedItemsMutation>;
export type RemoveEstimatedItemsMutationOptions = Apollo.BaseMutationOptions<
  RemoveEstimatedItemsMutation,
  RemoveEstimatedItemsMutationVariables
>;
export const RemoveFromBuyoutDocument = gql`
  mutation removeFromBuyout($input: RemoveFromBuyoutInput!) {
    removeFromBuyout(input: $input)
  }
`;
export type RemoveFromBuyoutMutationFn = Apollo.MutationFunction<
  RemoveFromBuyoutMutation,
  RemoveFromBuyoutMutationVariables
>;

/**
 * __useRemoveFromBuyoutMutation__
 *
 * To run a mutation, you first call `useRemoveFromBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromBuyoutMutation, { data, loading, error }] = useRemoveFromBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromBuyoutMutation,
    RemoveFromBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFromBuyoutMutation,
    RemoveFromBuyoutMutationVariables
  >(RemoveFromBuyoutDocument, options);
}
export type RemoveFromBuyoutMutationHookResult = ReturnType<
  typeof useRemoveFromBuyoutMutation
>;
export type RemoveFromBuyoutMutationResult =
  Apollo.MutationResult<RemoveFromBuyoutMutation>;
export type RemoveFromBuyoutMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromBuyoutMutation,
  RemoveFromBuyoutMutationVariables
>;
export const RemoveOrgCatalogSkuDocument = gql`
  mutation RemoveOrgCatalogSku($input: RemoveOrgCatalogSkuInput!) {
    removeOrgCatalogSku(input: $input)
  }
`;
export type RemoveOrgCatalogSkuMutationFn = Apollo.MutationFunction<
  RemoveOrgCatalogSkuMutation,
  RemoveOrgCatalogSkuMutationVariables
>;

/**
 * __useRemoveOrgCatalogSkuMutation__
 *
 * To run a mutation, you first call `useRemoveOrgCatalogSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgCatalogSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgCatalogSkuMutation, { data, loading, error }] = useRemoveOrgCatalogSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrgCatalogSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrgCatalogSkuMutation,
    RemoveOrgCatalogSkuMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOrgCatalogSkuMutation,
    RemoveOrgCatalogSkuMutationVariables
  >(RemoveOrgCatalogSkuDocument, options);
}
export type RemoveOrgCatalogSkuMutationHookResult = ReturnType<
  typeof useRemoveOrgCatalogSkuMutation
>;
export type RemoveOrgCatalogSkuMutationResult =
  Apollo.MutationResult<RemoveOrgCatalogSkuMutation>;
export type RemoveOrgCatalogSkuMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrgCatalogSkuMutation,
  RemoveOrgCatalogSkuMutationVariables
>;
export const RemoveRfqDocument = gql`
  mutation RemoveRfq($id: ID!) {
    removeRfq(id: $id)
  }
`;
export type RemoveRfqMutationFn = Apollo.MutationFunction<
  RemoveRfqMutation,
  RemoveRfqMutationVariables
>;

/**
 * __useRemoveRfqMutation__
 *
 * To run a mutation, you first call `useRemoveRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRfqMutation, { data, loading, error }] = useRemoveRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRfqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRfqMutation,
    RemoveRfqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveRfqMutation, RemoveRfqMutationVariables>(
    RemoveRfqDocument,
    options,
  );
}
export type RemoveRfqMutationHookResult = ReturnType<
  typeof useRemoveRfqMutation
>;
export type RemoveRfqMutationResult = Apollo.MutationResult<RemoveRfqMutation>;
export type RemoveRfqMutationOptions = Apollo.BaseMutationOptions<
  RemoveRfqMutation,
  RemoveRfqMutationVariables
>;
export const RemoveVendorDocument = gql`
  mutation RemoveVendor($id: ID!) {
    removeOrgPreferredVendor(vendorID: $id) {
      id
    }
  }
`;
export type RemoveVendorMutationFn = Apollo.MutationFunction<
  RemoveVendorMutation,
  RemoveVendorMutationVariables
>;

/**
 * __useRemoveVendorMutation__
 *
 * To run a mutation, you first call `useRemoveVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVendorMutation, { data, loading, error }] = useRemoveVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVendorMutation,
    RemoveVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveVendorMutation,
    RemoveVendorMutationVariables
  >(RemoveVendorDocument, options);
}
export type RemoveVendorMutationHookResult = ReturnType<
  typeof useRemoveVendorMutation
>;
export type RemoveVendorMutationResult =
  Apollo.MutationResult<RemoveVendorMutation>;
export type RemoveVendorMutationOptions = Apollo.BaseMutationOptions<
  RemoveVendorMutation,
  RemoveVendorMutationVariables
>;
export const RemoveVendorPriceDocument = gql`
  mutation RemoveVendorPrice($id: ID!) {
    deleteVendorPrice(id: $id) {
      id
    }
  }
`;
export type RemoveVendorPriceMutationFn = Apollo.MutationFunction<
  RemoveVendorPriceMutation,
  RemoveVendorPriceMutationVariables
>;

/**
 * __useRemoveVendorPriceMutation__
 *
 * To run a mutation, you first call `useRemoveVendorPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVendorPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVendorPriceMutation, { data, loading, error }] = useRemoveVendorPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveVendorPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVendorPriceMutation,
    RemoveVendorPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveVendorPriceMutation,
    RemoveVendorPriceMutationVariables
  >(RemoveVendorPriceDocument, options);
}
export type RemoveVendorPriceMutationHookResult = ReturnType<
  typeof useRemoveVendorPriceMutation
>;
export type RemoveVendorPriceMutationResult =
  Apollo.MutationResult<RemoveVendorPriceMutation>;
export type RemoveVendorPriceMutationOptions = Apollo.BaseMutationOptions<
  RemoveVendorPriceMutation,
  RemoveVendorPriceMutationVariables
>;
export const RemoveZoneDocument = gql`
  mutation RemoveZone($zoneId: ID!) {
    removeZone(zoneId: $zoneId) {
      ...ZoneFields
    }
  }
  ${ZoneFieldsFragmentDoc}
`;
export type RemoveZoneMutationFn = Apollo.MutationFunction<
  RemoveZoneMutation,
  RemoveZoneMutationVariables
>;

/**
 * __useRemoveZoneMutation__
 *
 * To run a mutation, you first call `useRemoveZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoneMutation, { data, loading, error }] = useRemoveZoneMutation({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useRemoveZoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoneMutation,
    RemoveZoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoneMutation, RemoveZoneMutationVariables>(
    RemoveZoneDocument,
    options,
  );
}
export type RemoveZoneMutationHookResult = ReturnType<
  typeof useRemoveZoneMutation
>;
export type RemoveZoneMutationResult =
  Apollo.MutationResult<RemoveZoneMutation>;
export type RemoveZoneMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoneMutation,
  RemoveZoneMutationVariables
>;
export const ResendQuoteDocument = gql`
  mutation ResendQuote($input: ResendQuoteInput!) {
    resendQuote(input: $input) {
      id
    }
  }
`;
export type ResendQuoteMutationFn = Apollo.MutationFunction<
  ResendQuoteMutation,
  ResendQuoteMutationVariables
>;

/**
 * __useResendQuoteMutation__
 *
 * To run a mutation, you first call `useResendQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendQuoteMutation, { data, loading, error }] = useResendQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendQuoteMutation,
    ResendQuoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendQuoteMutation, ResendQuoteMutationVariables>(
    ResendQuoteDocument,
    options,
  );
}
export type ResendQuoteMutationHookResult = ReturnType<
  typeof useResendQuoteMutation
>;
export type ResendQuoteMutationResult =
  Apollo.MutationResult<ResendQuoteMutation>;
export type ResendQuoteMutationOptions = Apollo.BaseMutationOptions<
  ResendQuoteMutation,
  ResendQuoteMutationVariables
>;
export const ResendSmsVerificationDocument = gql`
  mutation ResendSMSVerification($id: ID!) {
    resendSMSVerification(smsVerificationId: $id) {
      ...SMSVerificationFields
    }
  }
  ${SmsVerificationFieldsFragmentDoc}
`;
export type ResendSmsVerificationMutationFn = Apollo.MutationFunction<
  ResendSmsVerificationMutation,
  ResendSmsVerificationMutationVariables
>;

/**
 * __useResendSmsVerificationMutation__
 *
 * To run a mutation, you first call `useResendSmsVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSmsVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSmsVerificationMutation, { data, loading, error }] = useResendSmsVerificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendSmsVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendSmsVerificationMutation,
    ResendSmsVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendSmsVerificationMutation,
    ResendSmsVerificationMutationVariables
  >(ResendSmsVerificationDocument, options);
}
export type ResendSmsVerificationMutationHookResult = ReturnType<
  typeof useResendSmsVerificationMutation
>;
export type ResendSmsVerificationMutationResult =
  Apollo.MutationResult<ResendSmsVerificationMutation>;
export type ResendSmsVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendSmsVerificationMutation,
  ResendSmsVerificationMutationVariables
>;
export const ResolveReleaseIssuesDocument = gql`
  mutation ResolveReleaseIssues($input: ResolveReleaseIssuesInput!) {
    resolveReleaseIssues(input: $input) {
      ...DistributorReleaseFields
    }
  }
  ${DistributorReleaseFieldsFragmentDoc}
`;
export type ResolveReleaseIssuesMutationFn = Apollo.MutationFunction<
  ResolveReleaseIssuesMutation,
  ResolveReleaseIssuesMutationVariables
>;

/**
 * __useResolveReleaseIssuesMutation__
 *
 * To run a mutation, you first call `useResolveReleaseIssuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveReleaseIssuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveReleaseIssuesMutation, { data, loading, error }] = useResolveReleaseIssuesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveReleaseIssuesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveReleaseIssuesMutation,
    ResolveReleaseIssuesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResolveReleaseIssuesMutation,
    ResolveReleaseIssuesMutationVariables
  >(ResolveReleaseIssuesDocument, options);
}
export type ResolveReleaseIssuesMutationHookResult = ReturnType<
  typeof useResolveReleaseIssuesMutation
>;
export type ResolveReleaseIssuesMutationResult =
  Apollo.MutationResult<ResolveReleaseIssuesMutation>;
export type ResolveReleaseIssuesMutationOptions = Apollo.BaseMutationOptions<
  ResolveReleaseIssuesMutation,
  ResolveReleaseIssuesMutationVariables
>;
export const ScheduleReleaseDocument = gql`
  mutation scheduleRelease($input: UpdateContractorReleaseInput!) {
    updateContractorRelease(input: $input) {
      id
      time
      version
      status
    }
  }
`;
export type ScheduleReleaseMutationFn = Apollo.MutationFunction<
  ScheduleReleaseMutation,
  ScheduleReleaseMutationVariables
>;

/**
 * __useScheduleReleaseMutation__
 *
 * To run a mutation, you first call `useScheduleReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleReleaseMutation, { data, loading, error }] = useScheduleReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleReleaseMutation,
    ScheduleReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScheduleReleaseMutation,
    ScheduleReleaseMutationVariables
  >(ScheduleReleaseDocument, options);
}
export type ScheduleReleaseMutationHookResult = ReturnType<
  typeof useScheduleReleaseMutation
>;
export type ScheduleReleaseMutationResult =
  Apollo.MutationResult<ScheduleReleaseMutation>;
export type ScheduleReleaseMutationOptions = Apollo.BaseMutationOptions<
  ScheduleReleaseMutation,
  ScheduleReleaseMutationVariables
>;
export const SendMessageDocument = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
    }
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options,
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const SubmitQuoteDocument = gql`
  mutation SubmitQuote($input: SubmitQuoteInput!) {
    submitQuote(input: $input) {
      ...DistributorQuoteFields
    }
  }
  ${DistributorQuoteFieldsFragmentDoc}
`;
export type SubmitQuoteMutationFn = Apollo.MutationFunction<
  SubmitQuoteMutation,
  SubmitQuoteMutationVariables
>;

/**
 * __useSubmitQuoteMutation__
 *
 * To run a mutation, you first call `useSubmitQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuoteMutation, { data, loading, error }] = useSubmitQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitQuoteMutation,
    SubmitQuoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitQuoteMutation, SubmitQuoteMutationVariables>(
    SubmitQuoteDocument,
    options,
  );
}
export type SubmitQuoteMutationHookResult = ReturnType<
  typeof useSubmitQuoteMutation
>;
export type SubmitQuoteMutationResult =
  Apollo.MutationResult<SubmitQuoteMutation>;
export type SubmitQuoteMutationOptions = Apollo.BaseMutationOptions<
  SubmitQuoteMutation,
  SubmitQuoteMutationVariables
>;
export const SubmitRfqDocument = gql`
  mutation SubmitRfq($input: SubmitRfqInput!) {
    submitRfq(input: $input) {
      ...RfqFields
    }
  }
  ${RfqFieldsFragmentDoc}
`;
export type SubmitRfqMutationFn = Apollo.MutationFunction<
  SubmitRfqMutation,
  SubmitRfqMutationVariables
>;

/**
 * __useSubmitRfqMutation__
 *
 * To run a mutation, you first call `useSubmitRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRfqMutation, { data, loading, error }] = useSubmitRfqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRfqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitRfqMutation,
    SubmitRfqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitRfqMutation, SubmitRfqMutationVariables>(
    SubmitRfqDocument,
    options,
  );
}
export type SubmitRfqMutationHookResult = ReturnType<
  typeof useSubmitRfqMutation
>;
export type SubmitRfqMutationResult = Apollo.MutationResult<SubmitRfqMutation>;
export type SubmitRfqMutationOptions = Apollo.BaseMutationOptions<
  SubmitRfqMutation,
  SubmitRfqMutationVariables
>;
export const SubmitSmsVerificationDocument = gql`
  mutation SubmitSMSVerification($input: SubmitSMSVerificationInput!) {
    submitSMSVerification(input: $input) {
      ...SMSVerificationFields
    }
  }
  ${SmsVerificationFieldsFragmentDoc}
`;
export type SubmitSmsVerificationMutationFn = Apollo.MutationFunction<
  SubmitSmsVerificationMutation,
  SubmitSmsVerificationMutationVariables
>;

/**
 * __useSubmitSmsVerificationMutation__
 *
 * To run a mutation, you first call `useSubmitSmsVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSmsVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSmsVerificationMutation, { data, loading, error }] = useSubmitSmsVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSmsVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSmsVerificationMutation,
    SubmitSmsVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitSmsVerificationMutation,
    SubmitSmsVerificationMutationVariables
  >(SubmitSmsVerificationDocument, options);
}
export type SubmitSmsVerificationMutationHookResult = ReturnType<
  typeof useSubmitSmsVerificationMutation
>;
export type SubmitSmsVerificationMutationResult =
  Apollo.MutationResult<SubmitSmsVerificationMutation>;
export type SubmitSmsVerificationMutationOptions = Apollo.BaseMutationOptions<
  SubmitSmsVerificationMutation,
  SubmitSmsVerificationMutationVariables
>;
export const UnarchiveReleasesDocument = gql`
  mutation UnarchiveReleases($ids: [ID!]!) {
    unarchiveReleases(ids: $ids) {
      id
      status
      deletedAt
      invoices {
        id
        status
        archivedAt
      }
      receipts {
        id
        status
        archivedAt
      }
      deliverySlips {
        id
        status
        archivedAt
      }
    }
  }
`;
export type UnarchiveReleasesMutationFn = Apollo.MutationFunction<
  UnarchiveReleasesMutation,
  UnarchiveReleasesMutationVariables
>;

/**
 * __useUnarchiveReleasesMutation__
 *
 * To run a mutation, you first call `useUnarchiveReleasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveReleasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveReleasesMutation, { data, loading, error }] = useUnarchiveReleasesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUnarchiveReleasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveReleasesMutation,
    UnarchiveReleasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveReleasesMutation,
    UnarchiveReleasesMutationVariables
  >(UnarchiveReleasesDocument, options);
}
export type UnarchiveReleasesMutationHookResult = ReturnType<
  typeof useUnarchiveReleasesMutation
>;
export type UnarchiveReleasesMutationResult =
  Apollo.MutationResult<UnarchiveReleasesMutation>;
export type UnarchiveReleasesMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveReleasesMutation,
  UnarchiveReleasesMutationVariables
>;
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export type UpdateAssetMutationFn = Apollo.MutationFunction<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetMutation,
    UpdateAssetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(
    UpdateAssetDocument,
    options,
  );
}
export type UpdateAssetMutationHookResult = ReturnType<
  typeof useUpdateAssetMutation
>;
export type UpdateAssetMutationResult =
  Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;
export const UpdateContractorReleaseDocument = gql`
  mutation UpdateContractorRelease(
    $input: UpdateContractorReleaseInput!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    updateContractorRelease(input: $input) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;
export type UpdateContractorReleaseMutationFn = Apollo.MutationFunction<
  UpdateContractorReleaseMutation,
  UpdateContractorReleaseMutationVariables
>;

/**
 * __useUpdateContractorReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateContractorReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractorReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractorReleaseMutation, { data, loading, error }] = useUpdateContractorReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useUpdateContractorReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractorReleaseMutation,
    UpdateContractorReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractorReleaseMutation,
    UpdateContractorReleaseMutationVariables
  >(UpdateContractorReleaseDocument, options);
}
export type UpdateContractorReleaseMutationHookResult = ReturnType<
  typeof useUpdateContractorReleaseMutation
>;
export type UpdateContractorReleaseMutationResult =
  Apollo.MutationResult<UpdateContractorReleaseMutation>;
export type UpdateContractorReleaseMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractorReleaseMutation,
  UpdateContractorReleaseMutationVariables
>;
export const UpdateEstimatedItemsDocument = gql`
  mutation UpdateEstimatedItems($input: UpdateEstimatedItemsInput!) {
    updateEstimatedItems(input: $input) {
      ...AggregatedZoneFields
    }
  }
  ${AggregatedZoneFieldsFragmentDoc}
`;
export type UpdateEstimatedItemsMutationFn = Apollo.MutationFunction<
  UpdateEstimatedItemsMutation,
  UpdateEstimatedItemsMutationVariables
>;

/**
 * __useUpdateEstimatedItemsMutation__
 *
 * To run a mutation, you first call `useUpdateEstimatedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimatedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimatedItemsMutation, { data, loading, error }] = useUpdateEstimatedItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstimatedItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEstimatedItemsMutation,
    UpdateEstimatedItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEstimatedItemsMutation,
    UpdateEstimatedItemsMutationVariables
  >(UpdateEstimatedItemsDocument, options);
}
export type UpdateEstimatedItemsMutationHookResult = ReturnType<
  typeof useUpdateEstimatedItemsMutation
>;
export type UpdateEstimatedItemsMutationResult =
  Apollo.MutationResult<UpdateEstimatedItemsMutation>;
export type UpdateEstimatedItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateEstimatedItemsMutation,
  UpdateEstimatedItemsMutationVariables
>;
export const UpdateEstimatedItemsTagsDocument = gql`
  mutation UpdateEstimatedItemsTags($input: UpdateEstimatedItemInput!) {
    updateEstimatedItem(input: $input) {
      ...EstimatedItemFields
      item {
        id
      }
    }
  }
  ${EstimatedItemFieldsFragmentDoc}
`;
export type UpdateEstimatedItemsTagsMutationFn = Apollo.MutationFunction<
  UpdateEstimatedItemsTagsMutation,
  UpdateEstimatedItemsTagsMutationVariables
>;

/**
 * __useUpdateEstimatedItemsTagsMutation__
 *
 * To run a mutation, you first call `useUpdateEstimatedItemsTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimatedItemsTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimatedItemsTagsMutation, { data, loading, error }] = useUpdateEstimatedItemsTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstimatedItemsTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEstimatedItemsTagsMutation,
    UpdateEstimatedItemsTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEstimatedItemsTagsMutation,
    UpdateEstimatedItemsTagsMutationVariables
  >(UpdateEstimatedItemsTagsDocument, options);
}
export type UpdateEstimatedItemsTagsMutationHookResult = ReturnType<
  typeof useUpdateEstimatedItemsTagsMutation
>;
export type UpdateEstimatedItemsTagsMutationResult =
  Apollo.MutationResult<UpdateEstimatedItemsTagsMutation>;
export type UpdateEstimatedItemsTagsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateEstimatedItemsTagsMutation,
    UpdateEstimatedItemsTagsMutationVariables
  >;
export const UpdateEstimatesWithFileDocument = gql`
  mutation UpdateEstimatesWithFile($input: UpdateEstimatesWithFileInput!) {
    updateEstimatesWithFile(input: $input) {
      ...UpdateEstimatesWithFileOutputFields
    }
  }
  ${UpdateEstimatesWithFileOutputFieldsFragmentDoc}
`;
export type UpdateEstimatesWithFileMutationFn = Apollo.MutationFunction<
  UpdateEstimatesWithFileMutation,
  UpdateEstimatesWithFileMutationVariables
>;

/**
 * __useUpdateEstimatesWithFileMutation__
 *
 * To run a mutation, you first call `useUpdateEstimatesWithFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimatesWithFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimatesWithFileMutation, { data, loading, error }] = useUpdateEstimatesWithFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstimatesWithFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEstimatesWithFileMutation,
    UpdateEstimatesWithFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEstimatesWithFileMutation,
    UpdateEstimatesWithFileMutationVariables
  >(UpdateEstimatesWithFileDocument, options);
}
export type UpdateEstimatesWithFileMutationHookResult = ReturnType<
  typeof useUpdateEstimatesWithFileMutation
>;
export type UpdateEstimatesWithFileMutationResult =
  Apollo.MutationResult<UpdateEstimatesWithFileMutation>;
export type UpdateEstimatesWithFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateEstimatesWithFileMutation,
  UpdateEstimatesWithFileMutationVariables
>;
export const UpdateOrgDocument = gql`
  mutation UpdateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      name
      ein
      phone
      logoImageUrl
      hqAddress {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type UpdateOrgMutationFn = Apollo.MutationFunction<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMutation,
    UpdateOrgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(
    UpdateOrgDocument,
    options,
  );
}
export type UpdateOrgMutationHookResult = ReturnType<
  typeof useUpdateOrgMutation
>;
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>;
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;
export const UpdateOrgMaterialsDocument = gql`
  mutation updateOrgMaterials($input: UpdateOrgMaterialsInput) {
    updateOrgMaterials(input: $input) {
      ...OrgMaterialFields
    }
  }
  ${OrgMaterialFieldsFragmentDoc}
`;
export type UpdateOrgMaterialsMutationFn = Apollo.MutationFunction<
  UpdateOrgMaterialsMutation,
  UpdateOrgMaterialsMutationVariables
>;

/**
 * __useUpdateOrgMaterialsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMaterialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMaterialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMaterialsMutation, { data, loading, error }] = useUpdateOrgMaterialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMaterialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMaterialsMutation,
    UpdateOrgMaterialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgMaterialsMutation,
    UpdateOrgMaterialsMutationVariables
  >(UpdateOrgMaterialsDocument, options);
}
export type UpdateOrgMaterialsMutationHookResult = ReturnType<
  typeof useUpdateOrgMaterialsMutation
>;
export type UpdateOrgMaterialsMutationResult =
  Apollo.MutationResult<UpdateOrgMaterialsMutation>;
export type UpdateOrgMaterialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgMaterialsMutation,
  UpdateOrgMaterialsMutationVariables
>;
export const UpdateQuoteDocument = gql`
  mutation UpdateQuote($input: UpdateQuoteInput!) {
    updateQuote(input: $input) {
      ...DistributorQuoteFields
    }
  }
  ${DistributorQuoteFieldsFragmentDoc}
`;
export type UpdateQuoteMutationFn = Apollo.MutationFunction<
  UpdateQuoteMutation,
  UpdateQuoteMutationVariables
>;

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuoteMutation,
    UpdateQuoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(
    UpdateQuoteDocument,
    options,
  );
}
export type UpdateQuoteMutationHookResult = ReturnType<
  typeof useUpdateQuoteMutation
>;
export type UpdateQuoteMutationResult =
  Apollo.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuoteMutation,
  UpdateQuoteMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UsersUserFields
    }
  }
  ${UsersUserFieldsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateVendorBuyoutDocument = gql`
  mutation UpdateVendorBuyout($input: UpdateVendorBuyoutInput!) {
    updateVendorBuyout(input: $input) {
      ...DistributorBuyoutFields
    }
  }
  ${DistributorBuyoutFieldsFragmentDoc}
`;
export type UpdateVendorBuyoutMutationFn = Apollo.MutationFunction<
  UpdateVendorBuyoutMutation,
  UpdateVendorBuyoutMutationVariables
>;

/**
 * __useUpdateVendorBuyoutMutation__
 *
 * To run a mutation, you first call `useUpdateVendorBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorBuyoutMutation, { data, loading, error }] = useUpdateVendorBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorBuyoutMutation,
    UpdateVendorBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorBuyoutMutation,
    UpdateVendorBuyoutMutationVariables
  >(UpdateVendorBuyoutDocument, options);
}
export type UpdateVendorBuyoutMutationHookResult = ReturnType<
  typeof useUpdateVendorBuyoutMutation
>;
export type UpdateVendorBuyoutMutationResult =
  Apollo.MutationResult<UpdateVendorBuyoutMutation>;
export type UpdateVendorBuyoutMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorBuyoutMutation,
  UpdateVendorBuyoutMutationVariables
>;
export const UpdateVendorOrgDocument = gql`
  mutation UpdateVendorOrg($input: UpdateVendorOrgInput!) {
    updateVendorOrg(input: $input) {
      id
      name
      photoUrl
    }
  }
`;
export type UpdateVendorOrgMutationFn = Apollo.MutationFunction<
  UpdateVendorOrgMutation,
  UpdateVendorOrgMutationVariables
>;

/**
 * __useUpdateVendorOrgMutation__
 *
 * To run a mutation, you first call `useUpdateVendorOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorOrgMutation, { data, loading, error }] = useUpdateVendorOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorOrgMutation,
    UpdateVendorOrgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorOrgMutation,
    UpdateVendorOrgMutationVariables
  >(UpdateVendorOrgDocument, options);
}
export type UpdateVendorOrgMutationHookResult = ReturnType<
  typeof useUpdateVendorOrgMutation
>;
export type UpdateVendorOrgMutationResult =
  Apollo.MutationResult<UpdateVendorOrgMutation>;
export type UpdateVendorOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorOrgMutation,
  UpdateVendorOrgMutationVariables
>;
export const UpdateVendorReleaseDocument = gql`
  mutation UpdateVendorRelease($input: UpdateVendorReleaseInput!) {
    updateVendorRelease(input: $input) {
      ...UpdateVendorReleaseFields
    }
  }
  ${UpdateVendorReleaseFieldsFragmentDoc}
`;
export type UpdateVendorReleaseMutationFn = Apollo.MutationFunction<
  UpdateVendorReleaseMutation,
  UpdateVendorReleaseMutationVariables
>;

/**
 * __useUpdateVendorReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateVendorReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorReleaseMutation, { data, loading, error }] = useUpdateVendorReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorReleaseMutation,
    UpdateVendorReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorReleaseMutation,
    UpdateVendorReleaseMutationVariables
  >(UpdateVendorReleaseDocument, options);
}
export type UpdateVendorReleaseMutationHookResult = ReturnType<
  typeof useUpdateVendorReleaseMutation
>;
export type UpdateVendorReleaseMutationResult =
  Apollo.MutationResult<UpdateVendorReleaseMutation>;
export type UpdateVendorReleaseMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorReleaseMutation,
  UpdateVendorReleaseMutationVariables
>;
export const UpdateViewerProfileDocument = gql`
  mutation UpdateViewerProfile($input: UpdateProfileInput!) {
    updateViewerProfile(input: $input) {
      id
      firstName
      lastName
      email
      cellPhone
      photoUrl
      getSmsNotifications
      getEmailNotifications
      isPhoneValidated
      excludedReleaseEvents
      excludedInvoiceEvents
      excludedBuyoutEvents
      excludedQuoteEvents
      excludedMessageContexts
    }
  }
`;
export type UpdateViewerProfileMutationFn = Apollo.MutationFunction<
  UpdateViewerProfileMutation,
  UpdateViewerProfileMutationVariables
>;

/**
 * __useUpdateViewerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateViewerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewerProfileMutation, { data, loading, error }] = useUpdateViewerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewerProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateViewerProfileMutation,
    UpdateViewerProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateViewerProfileMutation,
    UpdateViewerProfileMutationVariables
  >(UpdateViewerProfileDocument, options);
}
export type UpdateViewerProfileMutationHookResult = ReturnType<
  typeof useUpdateViewerProfileMutation
>;
export type UpdateViewerProfileMutationResult =
  Apollo.MutationResult<UpdateViewerProfileMutation>;
export type UpdateViewerProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateViewerProfileMutation,
  UpdateViewerProfileMutationVariables
>;
export const UpdateZoneDocument = gql`
  mutation UpdateZone($input: UpdateZoneInput!) {
    updateZone(input: $input) {
      ...ZoneFields
    }
  }
  ${ZoneFieldsFragmentDoc}
`;
export type UpdateZoneMutationFn = Apollo.MutationFunction<
  UpdateZoneMutation,
  UpdateZoneMutationVariables
>;

/**
 * __useUpdateZoneMutation__
 *
 * To run a mutation, you first call `useUpdateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoneMutation, { data, loading, error }] = useUpdateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoneMutation,
    UpdateZoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(
    UpdateZoneDocument,
    options,
  );
}
export type UpdateZoneMutationHookResult = ReturnType<
  typeof useUpdateZoneMutation
>;
export type UpdateZoneMutationResult =
  Apollo.MutationResult<UpdateZoneMutation>;
export type UpdateZoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoneMutation,
  UpdateZoneMutationVariables
>;
export const WithdrawQuoteDocument = gql`
  mutation WithdrawQuote($input: WithdrawQuoteInput!) {
    withdrawQuote(input: $input) {
      id
      items {
        id
        expirationDate
        notes
        unitPrice
        quantityDecimal
        masterSku {
          id
        }
        orgCatalogSku {
          id
        }
        status
      }
      status
    }
  }
`;
export type WithdrawQuoteMutationFn = Apollo.MutationFunction<
  WithdrawQuoteMutation,
  WithdrawQuoteMutationVariables
>;

/**
 * __useWithdrawQuoteMutation__
 *
 * To run a mutation, you first call `useWithdrawQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawQuoteMutation, { data, loading, error }] = useWithdrawQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawQuoteMutation,
    WithdrawQuoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WithdrawQuoteMutation,
    WithdrawQuoteMutationVariables
  >(WithdrawQuoteDocument, options);
}
export type WithdrawQuoteMutationHookResult = ReturnType<
  typeof useWithdrawQuoteMutation
>;
export type WithdrawQuoteMutationResult =
  Apollo.MutationResult<WithdrawQuoteMutation>;
export type WithdrawQuoteMutationOptions = Apollo.BaseMutationOptions<
  WithdrawQuoteMutation,
  WithdrawQuoteMutationVariables
>;
export const AssignableBuyoutsDocument = gql`
  query AssignableBuyouts($input: AssignableBuyoutsInput!) {
    assignableBuyouts(input: $input) {
      buyout {
        ...BuyoutsBuyoutFields
        items {
          id
          description
          notes
          unitPrice
          isIncluded
          auxiliaryQuoteItem {
            id
            uom {
              id
              singularDescription
            }
          }
          projectItem {
            id
          }
        }
      }
      matchCount
      itemCount
    }
  }
  ${BuyoutsBuyoutFieldsFragmentDoc}
`;

/**
 * __useAssignableBuyoutsQuery__
 *
 * To run a query within a React component, call `useAssignableBuyoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignableBuyoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignableBuyoutsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignableBuyoutsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssignableBuyoutsQuery,
    AssignableBuyoutsQueryVariables
  > &
    (
      | { variables: AssignableBuyoutsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssignableBuyoutsQuery,
    AssignableBuyoutsQueryVariables
  >(AssignableBuyoutsDocument, options);
}
export function useAssignableBuyoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignableBuyoutsQuery,
    AssignableBuyoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssignableBuyoutsQuery,
    AssignableBuyoutsQueryVariables
  >(AssignableBuyoutsDocument, options);
}
export function useAssignableBuyoutsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssignableBuyoutsQuery,
        AssignableBuyoutsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssignableBuyoutsQuery,
    AssignableBuyoutsQueryVariables
  >(AssignableBuyoutsDocument, options);
}
export type AssignableBuyoutsQueryHookResult = ReturnType<
  typeof useAssignableBuyoutsQuery
>;
export type AssignableBuyoutsLazyQueryHookResult = ReturnType<
  typeof useAssignableBuyoutsLazyQuery
>;
export type AssignableBuyoutsSuspenseQueryHookResult = ReturnType<
  typeof useAssignableBuyoutsSuspenseQuery
>;
export type AssignableBuyoutsQueryResult = Apollo.QueryResult<
  AssignableBuyoutsQuery,
  AssignableBuyoutsQueryVariables
>;
export const BuyoutMessagesContextDocument = gql`
  query BuyoutMessagesContext($id: ID!) {
    buyout(id: $id) {
      ...BuyoutMessagesContextFields
    }
  }
  ${BuyoutMessagesContextFieldsFragmentDoc}
`;

/**
 * __useBuyoutMessagesContextQuery__
 *
 * To run a query within a React component, call `useBuyoutMessagesContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutMessagesContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutMessagesContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyoutMessagesContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuyoutMessagesContextQuery,
    BuyoutMessagesContextQueryVariables
  > &
    (
      | { variables: BuyoutMessagesContextQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BuyoutMessagesContextQuery,
    BuyoutMessagesContextQueryVariables
  >(BuyoutMessagesContextDocument, options);
}
export function useBuyoutMessagesContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyoutMessagesContextQuery,
    BuyoutMessagesContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuyoutMessagesContextQuery,
    BuyoutMessagesContextQueryVariables
  >(BuyoutMessagesContextDocument, options);
}
export function useBuyoutMessagesContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuyoutMessagesContextQuery,
        BuyoutMessagesContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuyoutMessagesContextQuery,
    BuyoutMessagesContextQueryVariables
  >(BuyoutMessagesContextDocument, options);
}
export type BuyoutMessagesContextQueryHookResult = ReturnType<
  typeof useBuyoutMessagesContextQuery
>;
export type BuyoutMessagesContextLazyQueryHookResult = ReturnType<
  typeof useBuyoutMessagesContextLazyQuery
>;
export type BuyoutMessagesContextSuspenseQueryHookResult = ReturnType<
  typeof useBuyoutMessagesContextSuspenseQuery
>;
export type BuyoutMessagesContextQueryResult = Apollo.QueryResult<
  BuyoutMessagesContextQuery,
  BuyoutMessagesContextQueryVariables
>;
export const BuyoutDistributorMessagesContextDocument = gql`
  query BuyoutDistributorMessagesContext($id: ID!) {
    buyout(id: $id) {
      ...BuyoutDistributorMessagesContextFields
    }
  }
  ${BuyoutDistributorMessagesContextFieldsFragmentDoc}
`;

/**
 * __useBuyoutDistributorMessagesContextQuery__
 *
 * To run a query within a React component, call `useBuyoutDistributorMessagesContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutDistributorMessagesContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutDistributorMessagesContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyoutDistributorMessagesContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuyoutDistributorMessagesContextQuery,
    BuyoutDistributorMessagesContextQueryVariables
  > &
    (
      | {
          variables: BuyoutDistributorMessagesContextQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BuyoutDistributorMessagesContextQuery,
    BuyoutDistributorMessagesContextQueryVariables
  >(BuyoutDistributorMessagesContextDocument, options);
}
export function useBuyoutDistributorMessagesContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyoutDistributorMessagesContextQuery,
    BuyoutDistributorMessagesContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuyoutDistributorMessagesContextQuery,
    BuyoutDistributorMessagesContextQueryVariables
  >(BuyoutDistributorMessagesContextDocument, options);
}
export function useBuyoutDistributorMessagesContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuyoutDistributorMessagesContextQuery,
        BuyoutDistributorMessagesContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuyoutDistributorMessagesContextQuery,
    BuyoutDistributorMessagesContextQueryVariables
  >(BuyoutDistributorMessagesContextDocument, options);
}
export type BuyoutDistributorMessagesContextQueryHookResult = ReturnType<
  typeof useBuyoutDistributorMessagesContextQuery
>;
export type BuyoutDistributorMessagesContextLazyQueryHookResult = ReturnType<
  typeof useBuyoutDistributorMessagesContextLazyQuery
>;
export type BuyoutDistributorMessagesContextSuspenseQueryHookResult =
  ReturnType<typeof useBuyoutDistributorMessagesContextSuspenseQuery>;
export type BuyoutDistributorMessagesContextQueryResult = Apollo.QueryResult<
  BuyoutDistributorMessagesContextQuery,
  BuyoutDistributorMessagesContextQueryVariables
>;
export const BuyoutReleaseDetailsDocument = gql`
  query BuyoutReleaseDetails($id: ID!) {
    release(id: $id) {
      ...BuyoutReleaseDetailsFields
    }
  }
  ${BuyoutReleaseDetailsFieldsFragmentDoc}
`;

/**
 * __useBuyoutReleaseDetailsQuery__
 *
 * To run a query within a React component, call `useBuyoutReleaseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutReleaseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutReleaseDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyoutReleaseDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuyoutReleaseDetailsQuery,
    BuyoutReleaseDetailsQueryVariables
  > &
    (
      | { variables: BuyoutReleaseDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BuyoutReleaseDetailsQuery,
    BuyoutReleaseDetailsQueryVariables
  >(BuyoutReleaseDetailsDocument, options);
}
export function useBuyoutReleaseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyoutReleaseDetailsQuery,
    BuyoutReleaseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuyoutReleaseDetailsQuery,
    BuyoutReleaseDetailsQueryVariables
  >(BuyoutReleaseDetailsDocument, options);
}
export function useBuyoutReleaseDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuyoutReleaseDetailsQuery,
        BuyoutReleaseDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuyoutReleaseDetailsQuery,
    BuyoutReleaseDetailsQueryVariables
  >(BuyoutReleaseDetailsDocument, options);
}
export type BuyoutReleaseDetailsQueryHookResult = ReturnType<
  typeof useBuyoutReleaseDetailsQuery
>;
export type BuyoutReleaseDetailsLazyQueryHookResult = ReturnType<
  typeof useBuyoutReleaseDetailsLazyQuery
>;
export type BuyoutReleaseDetailsSuspenseQueryHookResult = ReturnType<
  typeof useBuyoutReleaseDetailsSuspenseQuery
>;
export type BuyoutReleaseDetailsQueryResult = Apollo.QueryResult<
  BuyoutReleaseDetailsQuery,
  BuyoutReleaseDetailsQueryVariables
>;
export const BuyoutStockingsDocument = gql`
  query buyoutStockings($id: ID!) {
    buyout(id: $id) {
      ...BuyoutStockingPriceFields
    }
  }
  ${BuyoutStockingPriceFieldsFragmentDoc}
`;

/**
 * __useBuyoutStockingsQuery__
 *
 * To run a query within a React component, call `useBuyoutStockingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutStockingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutStockingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyoutStockingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuyoutStockingsQuery,
    BuyoutStockingsQueryVariables
  > &
    (
      | { variables: BuyoutStockingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuyoutStockingsQuery, BuyoutStockingsQueryVariables>(
    BuyoutStockingsDocument,
    options,
  );
}
export function useBuyoutStockingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyoutStockingsQuery,
    BuyoutStockingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuyoutStockingsQuery,
    BuyoutStockingsQueryVariables
  >(BuyoutStockingsDocument, options);
}
export function useBuyoutStockingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuyoutStockingsQuery,
        BuyoutStockingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuyoutStockingsQuery,
    BuyoutStockingsQueryVariables
  >(BuyoutStockingsDocument, options);
}
export type BuyoutStockingsQueryHookResult = ReturnType<
  typeof useBuyoutStockingsQuery
>;
export type BuyoutStockingsLazyQueryHookResult = ReturnType<
  typeof useBuyoutStockingsLazyQuery
>;
export type BuyoutStockingsSuspenseQueryHookResult = ReturnType<
  typeof useBuyoutStockingsSuspenseQuery
>;
export type BuyoutStockingsQueryResult = Apollo.QueryResult<
  BuyoutStockingsQuery,
  BuyoutStockingsQueryVariables
>;
export const BuyoutsDocument = gql`
  query Buyouts(
    $filter: QueryBuyoutsFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    buyouts(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...BuyoutsBuyoutFields
        }
      }
    }
  }
  ${BuyoutsBuyoutFieldsFragmentDoc}
`;

/**
 * __useBuyoutsQuery__
 *
 * To run a query within a React component, call `useBuyoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useBuyoutsQuery(
  baseOptions?: Apollo.QueryHookOptions<BuyoutsQuery, BuyoutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuyoutsQuery, BuyoutsQueryVariables>(
    BuyoutsDocument,
    options,
  );
}
export function useBuyoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyoutsQuery,
    BuyoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuyoutsQuery, BuyoutsQueryVariables>(
    BuyoutsDocument,
    options,
  );
}
export function useBuyoutsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BuyoutsQuery, BuyoutsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuyoutsQuery, BuyoutsQueryVariables>(
    BuyoutsDocument,
    options,
  );
}
export type BuyoutsQueryHookResult = ReturnType<typeof useBuyoutsQuery>;
export type BuyoutsLazyQueryHookResult = ReturnType<typeof useBuyoutsLazyQuery>;
export type BuyoutsSuspenseQueryHookResult = ReturnType<
  typeof useBuyoutsSuspenseQuery
>;
export type BuyoutsQueryResult = Apollo.QueryResult<
  BuyoutsQuery,
  BuyoutsQueryVariables
>;
export const CustomFileFormatsDocument = gql`
  query CustomFileFormats {
    customFileFormats {
      id
      name
      fileType
      mappings {
        property
        index
        name
      }
    }
  }
`;

/**
 * __useCustomFileFormatsQuery__
 *
 * To run a query within a React component, call `useCustomFileFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFileFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFileFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomFileFormatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomFileFormatsQuery,
    CustomFileFormatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomFileFormatsQuery,
    CustomFileFormatsQueryVariables
  >(CustomFileFormatsDocument, options);
}
export function useCustomFileFormatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomFileFormatsQuery,
    CustomFileFormatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomFileFormatsQuery,
    CustomFileFormatsQueryVariables
  >(CustomFileFormatsDocument, options);
}
export function useCustomFileFormatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CustomFileFormatsQuery,
        CustomFileFormatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CustomFileFormatsQuery,
    CustomFileFormatsQueryVariables
  >(CustomFileFormatsDocument, options);
}
export type CustomFileFormatsQueryHookResult = ReturnType<
  typeof useCustomFileFormatsQuery
>;
export type CustomFileFormatsLazyQueryHookResult = ReturnType<
  typeof useCustomFileFormatsLazyQuery
>;
export type CustomFileFormatsSuspenseQueryHookResult = ReturnType<
  typeof useCustomFileFormatsSuspenseQuery
>;
export type CustomFileFormatsQueryResult = Apollo.QueryResult<
  CustomFileFormatsQuery,
  CustomFileFormatsQueryVariables
>;
export const DistributorBuyoutReleasesDocument = gql`
  query DistributorBuyoutReleases($id: ID!) {
    buyout(id: $id) {
      ...DistributorBuyoutReleasesFields
    }
  }
  ${DistributorBuyoutReleasesFieldsFragmentDoc}
`;

/**
 * __useDistributorBuyoutReleasesQuery__
 *
 * To run a query within a React component, call `useDistributorBuyoutReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorBuyoutReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorBuyoutReleasesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorBuyoutReleasesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorBuyoutReleasesQuery,
    DistributorBuyoutReleasesQueryVariables
  > &
    (
      | { variables: DistributorBuyoutReleasesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorBuyoutReleasesQuery,
    DistributorBuyoutReleasesQueryVariables
  >(DistributorBuyoutReleasesDocument, options);
}
export function useDistributorBuyoutReleasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorBuyoutReleasesQuery,
    DistributorBuyoutReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorBuyoutReleasesQuery,
    DistributorBuyoutReleasesQueryVariables
  >(DistributorBuyoutReleasesDocument, options);
}
export function useDistributorBuyoutReleasesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorBuyoutReleasesQuery,
        DistributorBuyoutReleasesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorBuyoutReleasesQuery,
    DistributorBuyoutReleasesQueryVariables
  >(DistributorBuyoutReleasesDocument, options);
}
export type DistributorBuyoutReleasesQueryHookResult = ReturnType<
  typeof useDistributorBuyoutReleasesQuery
>;
export type DistributorBuyoutReleasesLazyQueryHookResult = ReturnType<
  typeof useDistributorBuyoutReleasesLazyQuery
>;
export type DistributorBuyoutReleasesSuspenseQueryHookResult = ReturnType<
  typeof useDistributorBuyoutReleasesSuspenseQuery
>;
export type DistributorBuyoutReleasesQueryResult = Apollo.QueryResult<
  DistributorBuyoutReleasesQuery,
  DistributorBuyoutReleasesQueryVariables
>;
export const DistributorBuyoutStockingsDocument = gql`
  query DistributorBuyoutStockings($id: ID!) {
    buyout(id: $id) {
      ...DistributorBuyoutStockingPriceFields
    }
  }
  ${DistributorBuyoutStockingPriceFieldsFragmentDoc}
`;

/**
 * __useDistributorBuyoutStockingsQuery__
 *
 * To run a query within a React component, call `useDistributorBuyoutStockingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorBuyoutStockingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorBuyoutStockingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorBuyoutStockingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorBuyoutStockingsQuery,
    DistributorBuyoutStockingsQueryVariables
  > &
    (
      | { variables: DistributorBuyoutStockingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorBuyoutStockingsQuery,
    DistributorBuyoutStockingsQueryVariables
  >(DistributorBuyoutStockingsDocument, options);
}
export function useDistributorBuyoutStockingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorBuyoutStockingsQuery,
    DistributorBuyoutStockingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorBuyoutStockingsQuery,
    DistributorBuyoutStockingsQueryVariables
  >(DistributorBuyoutStockingsDocument, options);
}
export function useDistributorBuyoutStockingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorBuyoutStockingsQuery,
        DistributorBuyoutStockingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorBuyoutStockingsQuery,
    DistributorBuyoutStockingsQueryVariables
  >(DistributorBuyoutStockingsDocument, options);
}
export type DistributorBuyoutStockingsQueryHookResult = ReturnType<
  typeof useDistributorBuyoutStockingsQuery
>;
export type DistributorBuyoutStockingsLazyQueryHookResult = ReturnType<
  typeof useDistributorBuyoutStockingsLazyQuery
>;
export type DistributorBuyoutStockingsSuspenseQueryHookResult = ReturnType<
  typeof useDistributorBuyoutStockingsSuspenseQuery
>;
export type DistributorBuyoutStockingsQueryResult = Apollo.QueryResult<
  DistributorBuyoutStockingsQuery,
  DistributorBuyoutStockingsQueryVariables
>;
export const DistributorQuoteDocument = gql`
  query DistributorQuote($id: ID!) {
    quote(id: $id) {
      ...DistributorQuoteFields
      ...QuoteStockingPriceFields
    }
  }
  ${DistributorQuoteFieldsFragmentDoc}
  ${QuoteStockingPriceFieldsFragmentDoc}
`;

/**
 * __useDistributorQuoteQuery__
 *
 * To run a query within a React component, call `useDistributorQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorQuoteQuery,
    DistributorQuoteQueryVariables
  > &
    (
      | { variables: DistributorQuoteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DistributorQuoteQuery, DistributorQuoteQueryVariables>(
    DistributorQuoteDocument,
    options,
  );
}
export function useDistributorQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorQuoteQuery,
    DistributorQuoteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorQuoteQuery,
    DistributorQuoteQueryVariables
  >(DistributorQuoteDocument, options);
}
export function useDistributorQuoteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorQuoteQuery,
        DistributorQuoteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorQuoteQuery,
    DistributorQuoteQueryVariables
  >(DistributorQuoteDocument, options);
}
export type DistributorQuoteQueryHookResult = ReturnType<
  typeof useDistributorQuoteQuery
>;
export type DistributorQuoteLazyQueryHookResult = ReturnType<
  typeof useDistributorQuoteLazyQuery
>;
export type DistributorQuoteSuspenseQueryHookResult = ReturnType<
  typeof useDistributorQuoteSuspenseQuery
>;
export type DistributorQuoteQueryResult = Apollo.QueryResult<
  DistributorQuoteQuery,
  DistributorQuoteQueryVariables
>;
export const DistributorQuoteDeliveriesDocument = gql`
  query DistributorQuoteDeliveries($id: ID!) {
    quote(id: $id) {
      ...DistributorQuoteFields
      ...QuoteStockingPriceFields
    }
    serviceProductCategories {
      name
      uomOptions {
        ...UOMFields
      }
    }
  }
  ${DistributorQuoteFieldsFragmentDoc}
  ${QuoteStockingPriceFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;

/**
 * __useDistributorQuoteDeliveriesQuery__
 *
 * To run a query within a React component, call `useDistributorQuoteDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorQuoteDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorQuoteDeliveriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorQuoteDeliveriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorQuoteDeliveriesQuery,
    DistributorQuoteDeliveriesQueryVariables
  > &
    (
      | { variables: DistributorQuoteDeliveriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorQuoteDeliveriesQuery,
    DistributorQuoteDeliveriesQueryVariables
  >(DistributorQuoteDeliveriesDocument, options);
}
export function useDistributorQuoteDeliveriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorQuoteDeliveriesQuery,
    DistributorQuoteDeliveriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorQuoteDeliveriesQuery,
    DistributorQuoteDeliveriesQueryVariables
  >(DistributorQuoteDeliveriesDocument, options);
}
export function useDistributorQuoteDeliveriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorQuoteDeliveriesQuery,
        DistributorQuoteDeliveriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorQuoteDeliveriesQuery,
    DistributorQuoteDeliveriesQueryVariables
  >(DistributorQuoteDeliveriesDocument, options);
}
export type DistributorQuoteDeliveriesQueryHookResult = ReturnType<
  typeof useDistributorQuoteDeliveriesQuery
>;
export type DistributorQuoteDeliveriesLazyQueryHookResult = ReturnType<
  typeof useDistributorQuoteDeliveriesLazyQuery
>;
export type DistributorQuoteDeliveriesSuspenseQueryHookResult = ReturnType<
  typeof useDistributorQuoteDeliveriesSuspenseQuery
>;
export type DistributorQuoteDeliveriesQueryResult = Apollo.QueryResult<
  DistributorQuoteDeliveriesQuery,
  DistributorQuoteDeliveriesQueryVariables
>;
export const DistributorQuoteMessagesContextDocument = gql`
  query DistributorQuoteMessagesContext($id: ID!) {
    quote(id: $id) {
      ...DistributorQuoteMessageContextFields
    }
  }
  ${DistributorQuoteMessageContextFieldsFragmentDoc}
`;

/**
 * __useDistributorQuoteMessagesContextQuery__
 *
 * To run a query within a React component, call `useDistributorQuoteMessagesContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorQuoteMessagesContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorQuoteMessagesContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorQuoteMessagesContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorQuoteMessagesContextQuery,
    DistributorQuoteMessagesContextQueryVariables
  > &
    (
      | {
          variables: DistributorQuoteMessagesContextQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorQuoteMessagesContextQuery,
    DistributorQuoteMessagesContextQueryVariables
  >(DistributorQuoteMessagesContextDocument, options);
}
export function useDistributorQuoteMessagesContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorQuoteMessagesContextQuery,
    DistributorQuoteMessagesContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorQuoteMessagesContextQuery,
    DistributorQuoteMessagesContextQueryVariables
  >(DistributorQuoteMessagesContextDocument, options);
}
export function useDistributorQuoteMessagesContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorQuoteMessagesContextQuery,
        DistributorQuoteMessagesContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorQuoteMessagesContextQuery,
    DistributorQuoteMessagesContextQueryVariables
  >(DistributorQuoteMessagesContextDocument, options);
}
export type DistributorQuoteMessagesContextQueryHookResult = ReturnType<
  typeof useDistributorQuoteMessagesContextQuery
>;
export type DistributorQuoteMessagesContextLazyQueryHookResult = ReturnType<
  typeof useDistributorQuoteMessagesContextLazyQuery
>;
export type DistributorQuoteMessagesContextSuspenseQueryHookResult = ReturnType<
  typeof useDistributorQuoteMessagesContextSuspenseQuery
>;
export type DistributorQuoteMessagesContextQueryResult = Apollo.QueryResult<
  DistributorQuoteMessagesContextQuery,
  DistributorQuoteMessagesContextQueryVariables
>;
export const DistributorReleaseDocument = gql`
  query DistributorRelease($id: ID!) {
    release(id: $id) {
      ...DistributorReleaseFields
    }
  }
  ${DistributorReleaseFieldsFragmentDoc}
`;

/**
 * __useDistributorReleaseQuery__
 *
 * To run a query within a React component, call `useDistributorReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorReleaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorReleaseQuery,
    DistributorReleaseQueryVariables
  > &
    (
      | { variables: DistributorReleaseQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorReleaseQuery,
    DistributorReleaseQueryVariables
  >(DistributorReleaseDocument, options);
}
export function useDistributorReleaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorReleaseQuery,
    DistributorReleaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorReleaseQuery,
    DistributorReleaseQueryVariables
  >(DistributorReleaseDocument, options);
}
export function useDistributorReleaseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorReleaseQuery,
        DistributorReleaseQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorReleaseQuery,
    DistributorReleaseQueryVariables
  >(DistributorReleaseDocument, options);
}
export type DistributorReleaseQueryHookResult = ReturnType<
  typeof useDistributorReleaseQuery
>;
export type DistributorReleaseLazyQueryHookResult = ReturnType<
  typeof useDistributorReleaseLazyQuery
>;
export type DistributorReleaseSuspenseQueryHookResult = ReturnType<
  typeof useDistributorReleaseSuspenseQuery
>;
export type DistributorReleaseQueryResult = Apollo.QueryResult<
  DistributorReleaseQuery,
  DistributorReleaseQueryVariables
>;
export const DownloadAssetDocument = gql`
  query DownloadAsset($url: String!) {
    downloadAsset(url: $url) {
      data
    }
  }
`;

/**
 * __useDownloadAssetQuery__
 *
 * To run a query within a React component, call `useDownloadAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAssetQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useDownloadAssetQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadAssetQuery,
    DownloadAssetQueryVariables
  > &
    (
      | { variables: DownloadAssetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadAssetQuery, DownloadAssetQueryVariables>(
    DownloadAssetDocument,
    options,
  );
}
export function useDownloadAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadAssetQuery,
    DownloadAssetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadAssetQuery, DownloadAssetQueryVariables>(
    DownloadAssetDocument,
    options,
  );
}
export function useDownloadAssetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DownloadAssetQuery,
        DownloadAssetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DownloadAssetQuery,
    DownloadAssetQueryVariables
  >(DownloadAssetDocument, options);
}
export type DownloadAssetQueryHookResult = ReturnType<
  typeof useDownloadAssetQuery
>;
export type DownloadAssetLazyQueryHookResult = ReturnType<
  typeof useDownloadAssetLazyQuery
>;
export type DownloadAssetSuspenseQueryHookResult = ReturnType<
  typeof useDownloadAssetSuspenseQuery
>;
export type DownloadAssetQueryResult = Apollo.QueryResult<
  DownloadAssetQuery,
  DownloadAssetQueryVariables
>;
export const CategoriesDocument = gql`
  query Categories {
    categories {
      id
      l1Name
      l2Name
      l3Name
      l4Name
    }
  }
`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  );
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  );
}
export function useCategoriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CategoriesQuery,
        CategoriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  );
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<
  typeof useCategoriesLazyQuery
>;
export type CategoriesSuspenseQueryHookResult = ReturnType<
  typeof useCategoriesSuspenseQuery
>;
export type CategoriesQueryResult = Apollo.QueryResult<
  CategoriesQuery,
  CategoriesQueryVariables
>;
export const MasterSkusDocument = gql`
  query MasterSkus(
    $filter: QueryProductFilterInput!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    masterSkus(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      facets {
        ...MasterSkusFacetFields
      }
      edges {
        cursor
        node {
          ...MasterSkusSkuFields
        }
      }
      searchContext {
        query
        terms
        inferredCategoryId
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${MasterSkusFacetFieldsFragmentDoc}
  ${MasterSkusSkuFieldsFragmentDoc}
`;

/**
 * __useMasterSkusQuery__
 *
 * To run a query within a React component, call `useMasterSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterSkusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMasterSkusQuery(
  baseOptions: Apollo.QueryHookOptions<
    MasterSkusQuery,
    MasterSkusQueryVariables
  > &
    (
      | { variables: MasterSkusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MasterSkusQuery, MasterSkusQueryVariables>(
    MasterSkusDocument,
    options,
  );
}
export function useMasterSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MasterSkusQuery,
    MasterSkusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MasterSkusQuery, MasterSkusQueryVariables>(
    MasterSkusDocument,
    options,
  );
}
export function useMasterSkusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MasterSkusQuery,
        MasterSkusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MasterSkusQuery, MasterSkusQueryVariables>(
    MasterSkusDocument,
    options,
  );
}
export type MasterSkusQueryHookResult = ReturnType<typeof useMasterSkusQuery>;
export type MasterSkusLazyQueryHookResult = ReturnType<
  typeof useMasterSkusLazyQuery
>;
export type MasterSkusSuspenseQueryHookResult = ReturnType<
  typeof useMasterSkusSuspenseQuery
>;
export type MasterSkusQueryResult = Apollo.QueryResult<
  MasterSkusQuery,
  MasterSkusQueryVariables
>;
export const EstimatedPricesDocument = gql`
  query EstimatedPrices($id: ID!) {
    project(id: $id) {
      id
      items {
        id
        material {
          id
        }
        estimatedItems {
          id
          manufacturer {
            id
          }
          unitPrice
          zone {
            id
          }
          uom {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useEstimatedPricesQuery__
 *
 * To run a query within a React component, call `useEstimatedPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatedPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatedPricesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEstimatedPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimatedPricesQuery,
    EstimatedPricesQueryVariables
  > &
    (
      | { variables: EstimatedPricesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimatedPricesQuery, EstimatedPricesQueryVariables>(
    EstimatedPricesDocument,
    options,
  );
}
export function useEstimatedPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatedPricesQuery,
    EstimatedPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimatedPricesQuery,
    EstimatedPricesQueryVariables
  >(EstimatedPricesDocument, options);
}
export function useEstimatedPricesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EstimatedPricesQuery,
        EstimatedPricesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EstimatedPricesQuery,
    EstimatedPricesQueryVariables
  >(EstimatedPricesDocument, options);
}
export type EstimatedPricesQueryHookResult = ReturnType<
  typeof useEstimatedPricesQuery
>;
export type EstimatedPricesLazyQueryHookResult = ReturnType<
  typeof useEstimatedPricesLazyQuery
>;
export type EstimatedPricesSuspenseQueryHookResult = ReturnType<
  typeof useEstimatedPricesSuspenseQuery
>;
export type EstimatedPricesQueryResult = Apollo.QueryResult<
  EstimatedPricesQuery,
  EstimatedPricesQueryVariables
>;
export const ExternalPoDocument = gql`
  query ExternalPO($input: ExternalPOQueryInput!) {
    externalPO(input: $input) {
      ...ExternalPOFields
    }
  }
  ${ExternalPoFieldsFragmentDoc}
`;

/**
 * __useExternalPoQuery__
 *
 * To run a query within a React component, call `useExternalPoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalPoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalPoQuery,
    ExternalPoQueryVariables
  > &
    (
      | { variables: ExternalPoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalPoQuery, ExternalPoQueryVariables>(
    ExternalPoDocument,
    options,
  );
}
export function useExternalPoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalPoQuery,
    ExternalPoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalPoQuery, ExternalPoQueryVariables>(
    ExternalPoDocument,
    options,
  );
}
export function useExternalPoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalPoQuery,
        ExternalPoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExternalPoQuery, ExternalPoQueryVariables>(
    ExternalPoDocument,
    options,
  );
}
export type ExternalPoQueryHookResult = ReturnType<typeof useExternalPoQuery>;
export type ExternalPoLazyQueryHookResult = ReturnType<
  typeof useExternalPoLazyQuery
>;
export type ExternalPoSuspenseQueryHookResult = ReturnType<
  typeof useExternalPoSuspenseQuery
>;
export type ExternalPoQueryResult = Apollo.QueryResult<
  ExternalPoQuery,
  ExternalPoQueryVariables
>;
export const ExternalPOsDocument = gql`
  query ExternalPOs($input: ExternalPOsQueryInput!) {
    externalPOs(input: $input) {
      currentPage
      hasNextPage
      nodes {
        ...ExternalPOBaseFields
      }
    }
  }
  ${ExternalPoBaseFieldsFragmentDoc}
`;

/**
 * __useExternalPOsQuery__
 *
 * To run a query within a React component, call `useExternalPOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPOsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalPOsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalPOsQuery,
    ExternalPOsQueryVariables
  > &
    (
      | { variables: ExternalPOsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalPOsQuery, ExternalPOsQueryVariables>(
    ExternalPOsDocument,
    options,
  );
}
export function useExternalPOsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalPOsQuery,
    ExternalPOsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalPOsQuery, ExternalPOsQueryVariables>(
    ExternalPOsDocument,
    options,
  );
}
export function useExternalPOsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalPOsQuery,
        ExternalPOsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExternalPOsQuery, ExternalPOsQueryVariables>(
    ExternalPOsDocument,
    options,
  );
}
export type ExternalPOsQueryHookResult = ReturnType<typeof useExternalPOsQuery>;
export type ExternalPOsLazyQueryHookResult = ReturnType<
  typeof useExternalPOsLazyQuery
>;
export type ExternalPOsSuspenseQueryHookResult = ReturnType<
  typeof useExternalPOsSuspenseQuery
>;
export type ExternalPOsQueryResult = Apollo.QueryResult<
  ExternalPOsQuery,
  ExternalPOsQueryVariables
>;
export const FacetsByCategoryDocument = gql`
  query FacetsByCategory($filter: QueryProductFilterInput!) {
    masterSkus(filter: $filter) {
      facets {
        id
        name
        values {
          id
          name
          value
          count
        }
      }
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * __useFacetsByCategoryQuery__
 *
 * To run a query within a React component, call `useFacetsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacetsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacetsByCategoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFacetsByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FacetsByCategoryQuery,
    FacetsByCategoryQueryVariables
  > &
    (
      | { variables: FacetsByCategoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FacetsByCategoryQuery, FacetsByCategoryQueryVariables>(
    FacetsByCategoryDocument,
    options,
  );
}
export function useFacetsByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FacetsByCategoryQuery,
    FacetsByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FacetsByCategoryQuery,
    FacetsByCategoryQueryVariables
  >(FacetsByCategoryDocument, options);
}
export function useFacetsByCategorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FacetsByCategoryQuery,
        FacetsByCategoryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FacetsByCategoryQuery,
    FacetsByCategoryQueryVariables
  >(FacetsByCategoryDocument, options);
}
export type FacetsByCategoryQueryHookResult = ReturnType<
  typeof useFacetsByCategoryQuery
>;
export type FacetsByCategoryLazyQueryHookResult = ReturnType<
  typeof useFacetsByCategoryLazyQuery
>;
export type FacetsByCategorySuspenseQueryHookResult = ReturnType<
  typeof useFacetsByCategorySuspenseQuery
>;
export type FacetsByCategoryQueryResult = Apollo.QueryResult<
  FacetsByCategoryQuery,
  FacetsByCategoryQueryVariables
>;
export const FieldsPreviewDocument = gql`
  query FieldsPreview($input: FieldsPreviewInput!) {
    fieldsPreview(input: $input) {
      name
      hint
      records
    }
  }
`;

/**
 * __useFieldsPreviewQuery__
 *
 * To run a query within a React component, call `useFieldsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFieldsPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FieldsPreviewQuery,
    FieldsPreviewQueryVariables
  > &
    (
      | { variables: FieldsPreviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldsPreviewQuery, FieldsPreviewQueryVariables>(
    FieldsPreviewDocument,
    options,
  );
}
export function useFieldsPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldsPreviewQuery,
    FieldsPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldsPreviewQuery, FieldsPreviewQueryVariables>(
    FieldsPreviewDocument,
    options,
  );
}
export function useFieldsPreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FieldsPreviewQuery,
        FieldsPreviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FieldsPreviewQuery,
    FieldsPreviewQueryVariables
  >(FieldsPreviewDocument, options);
}
export type FieldsPreviewQueryHookResult = ReturnType<
  typeof useFieldsPreviewQuery
>;
export type FieldsPreviewLazyQueryHookResult = ReturnType<
  typeof useFieldsPreviewLazyQuery
>;
export type FieldsPreviewSuspenseQueryHookResult = ReturnType<
  typeof useFieldsPreviewSuspenseQuery
>;
export type FieldsPreviewQueryResult = Apollo.QueryResult<
  FieldsPreviewQuery,
  FieldsPreviewQueryVariables
>;
export const ManufacturersDocument = gql`
  query Manufacturers($searchPrefix: String!) {
    manufacturers(searchPrefix: $searchPrefix) {
      ...ManufacturerFields
    }
  }
  ${ManufacturerFieldsFragmentDoc}
`;

/**
 * __useManufacturersQuery__
 *
 * To run a query within a React component, call `useManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturersQuery({
 *   variables: {
 *      searchPrefix: // value for 'searchPrefix'
 *   },
 * });
 */
export function useManufacturersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ManufacturersQuery,
    ManufacturersQueryVariables
  > &
    (
      | { variables: ManufacturersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManufacturersQuery, ManufacturersQueryVariables>(
    ManufacturersDocument,
    options,
  );
}
export function useManufacturersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturersQuery,
    ManufacturersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManufacturersQuery, ManufacturersQueryVariables>(
    ManufacturersDocument,
    options,
  );
}
export function useManufacturersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturersQuery,
        ManufacturersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturersQuery,
    ManufacturersQueryVariables
  >(ManufacturersDocument, options);
}
export type ManufacturersQueryHookResult = ReturnType<
  typeof useManufacturersQuery
>;
export type ManufacturersLazyQueryHookResult = ReturnType<
  typeof useManufacturersLazyQuery
>;
export type ManufacturersSuspenseQueryHookResult = ReturnType<
  typeof useManufacturersSuspenseQuery
>;
export type ManufacturersQueryResult = Apollo.QueryResult<
  ManufacturersQuery,
  ManufacturersQueryVariables
>;
export const MasterProductsDocument = gql`
  query MasterProducts(
    $filter: QueryProductFilterInput!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    masterProducts(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      facets {
        ...MasterSkusFacetFields
      }
      edges {
        cursor
        node {
          ...MasterProductsProductFields
        }
      }
      searchContext {
        query
        terms
        inferredCategoryId
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${MasterSkusFacetFieldsFragmentDoc}
  ${MasterProductsProductFieldsFragmentDoc}
`;

/**
 * __useMasterProductsQuery__
 *
 * To run a query within a React component, call `useMasterProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMasterProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MasterProductsQuery,
    MasterProductsQueryVariables
  > &
    (
      | { variables: MasterProductsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MasterProductsQuery, MasterProductsQueryVariables>(
    MasterProductsDocument,
    options,
  );
}
export function useMasterProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MasterProductsQuery,
    MasterProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MasterProductsQuery, MasterProductsQueryVariables>(
    MasterProductsDocument,
    options,
  );
}
export function useMasterProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MasterProductsQuery,
        MasterProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MasterProductsQuery,
    MasterProductsQueryVariables
  >(MasterProductsDocument, options);
}
export type MasterProductsQueryHookResult = ReturnType<
  typeof useMasterProductsQuery
>;
export type MasterProductsLazyQueryHookResult = ReturnType<
  typeof useMasterProductsLazyQuery
>;
export type MasterProductsSuspenseQueryHookResult = ReturnType<
  typeof useMasterProductsSuspenseQuery
>;
export type MasterProductsQueryResult = Apollo.QueryResult<
  MasterProductsQuery,
  MasterProductsQueryVariables
>;
export const MasterSkuDocument = gql`
  query MasterSku($id: ID!) {
    masterSku(id: $id) {
      ...MasterSkuSkuFields
    }
  }
  ${MasterSkuSkuFieldsFragmentDoc}
`;

/**
 * __useMasterSkuQuery__
 *
 * To run a query within a React component, call `useMasterSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterSkuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMasterSkuQuery(
  baseOptions: Apollo.QueryHookOptions<
    MasterSkuQuery,
    MasterSkuQueryVariables
  > &
    (
      | { variables: MasterSkuQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MasterSkuQuery, MasterSkuQueryVariables>(
    MasterSkuDocument,
    options,
  );
}
export function useMasterSkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MasterSkuQuery,
    MasterSkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MasterSkuQuery, MasterSkuQueryVariables>(
    MasterSkuDocument,
    options,
  );
}
export function useMasterSkuSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MasterSkuQuery, MasterSkuQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MasterSkuQuery, MasterSkuQueryVariables>(
    MasterSkuDocument,
    options,
  );
}
export type MasterSkuQueryHookResult = ReturnType<typeof useMasterSkuQuery>;
export type MasterSkuLazyQueryHookResult = ReturnType<
  typeof useMasterSkuLazyQuery
>;
export type MasterSkuSuspenseQueryHookResult = ReturnType<
  typeof useMasterSkuSuspenseQuery
>;
export type MasterSkuQueryResult = Apollo.QueryResult<
  MasterSkuQuery,
  MasterSkuQueryVariables
>;
export const MasterSkusByKeywordDocument = gql`
  query MasterSkusByKeyword(
    $filter: QueryProductFilterInput!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    masterSkus(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...MasterSkusByKeywordSkuFields
        }
      }
      searchContext {
        terms
      }
    }
  }
  ${MasterSkusByKeywordSkuFieldsFragmentDoc}
`;

/**
 * __useMasterSkusByKeywordQuery__
 *
 * To run a query within a React component, call `useMasterSkusByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterSkusByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterSkusByKeywordQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMasterSkusByKeywordQuery(
  baseOptions: Apollo.QueryHookOptions<
    MasterSkusByKeywordQuery,
    MasterSkusByKeywordQueryVariables
  > &
    (
      | { variables: MasterSkusByKeywordQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MasterSkusByKeywordQuery,
    MasterSkusByKeywordQueryVariables
  >(MasterSkusByKeywordDocument, options);
}
export function useMasterSkusByKeywordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MasterSkusByKeywordQuery,
    MasterSkusByKeywordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MasterSkusByKeywordQuery,
    MasterSkusByKeywordQueryVariables
  >(MasterSkusByKeywordDocument, options);
}
export function useMasterSkusByKeywordSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MasterSkusByKeywordQuery,
        MasterSkusByKeywordQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MasterSkusByKeywordQuery,
    MasterSkusByKeywordQueryVariables
  >(MasterSkusByKeywordDocument, options);
}
export type MasterSkusByKeywordQueryHookResult = ReturnType<
  typeof useMasterSkusByKeywordQuery
>;
export type MasterSkusByKeywordLazyQueryHookResult = ReturnType<
  typeof useMasterSkusByKeywordLazyQuery
>;
export type MasterSkusByKeywordSuspenseQueryHookResult = ReturnType<
  typeof useMasterSkusByKeywordSuspenseQuery
>;
export type MasterSkusByKeywordQueryResult = Apollo.QueryResult<
  MasterSkusByKeywordQuery,
  MasterSkusByKeywordQueryVariables
>;
export const MessagesDocument = gql`
  query Messages(
    $input: QueryMessagesInput!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    messages(
      input: $input
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        cursor
        node {
          ...MessageFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${MessageFieldsFragmentDoc}
`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables> &
    ({ variables: MessagesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(
    MessagesDocument,
    options,
  );
}
export function useMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagesQuery,
    MessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(
    MessagesDocument,
    options,
  );
}
export function useMessagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MessagesQuery, MessagesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MessagesQuery, MessagesQueryVariables>(
    MessagesDocument,
    options,
  );
}
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<
  typeof useMessagesLazyQuery
>;
export type MessagesSuspenseQueryHookResult = ReturnType<
  typeof useMessagesSuspenseQuery
>;
export type MessagesQueryResult = Apollo.QueryResult<
  MessagesQuery,
  MessagesQueryVariables
>;
export const PredictedReceiptNumberDocument = gql`
  query PredictedReceiptNumber($projectId: ID!, $sellerOrgLocationId: ID!) {
    predictedReceiptNumber(
      projectId: $projectId
      sellerOrgLocationId: $sellerOrgLocationId
    )
  }
`;

/**
 * __usePredictedReceiptNumberQuery__
 *
 * To run a query within a React component, call `usePredictedReceiptNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredictedReceiptNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredictedReceiptNumberQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      sellerOrgLocationId: // value for 'sellerOrgLocationId'
 *   },
 * });
 */
export function usePredictedReceiptNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    PredictedReceiptNumberQuery,
    PredictedReceiptNumberQueryVariables
  > &
    (
      | { variables: PredictedReceiptNumberQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PredictedReceiptNumberQuery,
    PredictedReceiptNumberQueryVariables
  >(PredictedReceiptNumberDocument, options);
}
export function usePredictedReceiptNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PredictedReceiptNumberQuery,
    PredictedReceiptNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PredictedReceiptNumberQuery,
    PredictedReceiptNumberQueryVariables
  >(PredictedReceiptNumberDocument, options);
}
export function usePredictedReceiptNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PredictedReceiptNumberQuery,
        PredictedReceiptNumberQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PredictedReceiptNumberQuery,
    PredictedReceiptNumberQueryVariables
  >(PredictedReceiptNumberDocument, options);
}
export type PredictedReceiptNumberQueryHookResult = ReturnType<
  typeof usePredictedReceiptNumberQuery
>;
export type PredictedReceiptNumberLazyQueryHookResult = ReturnType<
  typeof usePredictedReceiptNumberLazyQuery
>;
export type PredictedReceiptNumberSuspenseQueryHookResult = ReturnType<
  typeof usePredictedReceiptNumberSuspenseQuery
>;
export type PredictedReceiptNumberQueryResult = Apollo.QueryResult<
  PredictedReceiptNumberQuery,
  PredictedReceiptNumberQueryVariables
>;
export const PredictedReleaseSequenceNumberDocument = gql`
  query PredictedReleaseSequenceNumber {
    viewer {
      id
      org {
        id
        predictedReleaseSequenceNumber
      }
    }
  }
`;

/**
 * __usePredictedReleaseSequenceNumberQuery__
 *
 * To run a query within a React component, call `usePredictedReleaseSequenceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredictedReleaseSequenceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredictedReleaseSequenceNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function usePredictedReleaseSequenceNumberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PredictedReleaseSequenceNumberQuery,
    PredictedReleaseSequenceNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PredictedReleaseSequenceNumberQuery,
    PredictedReleaseSequenceNumberQueryVariables
  >(PredictedReleaseSequenceNumberDocument, options);
}
export function usePredictedReleaseSequenceNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PredictedReleaseSequenceNumberQuery,
    PredictedReleaseSequenceNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PredictedReleaseSequenceNumberQuery,
    PredictedReleaseSequenceNumberQueryVariables
  >(PredictedReleaseSequenceNumberDocument, options);
}
export function usePredictedReleaseSequenceNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PredictedReleaseSequenceNumberQuery,
        PredictedReleaseSequenceNumberQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PredictedReleaseSequenceNumberQuery,
    PredictedReleaseSequenceNumberQueryVariables
  >(PredictedReleaseSequenceNumberDocument, options);
}
export type PredictedReleaseSequenceNumberQueryHookResult = ReturnType<
  typeof usePredictedReleaseSequenceNumberQuery
>;
export type PredictedReleaseSequenceNumberLazyQueryHookResult = ReturnType<
  typeof usePredictedReleaseSequenceNumberLazyQuery
>;
export type PredictedReleaseSequenceNumberSuspenseQueryHookResult = ReturnType<
  typeof usePredictedReleaseSequenceNumberSuspenseQuery
>;
export type PredictedReleaseSequenceNumberQueryResult = Apollo.QueryResult<
  PredictedReleaseSequenceNumberQuery,
  PredictedReleaseSequenceNumberQueryVariables
>;
export const PredictedTaxDocument = gql`
  query PredictedTax($input: PredictedTaxInput!) {
    predictedTax(input: $input) {
      taxRate
    }
  }
`;

/**
 * __usePredictedTaxQuery__
 *
 * To run a query within a React component, call `usePredictedTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredictedTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredictedTaxQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePredictedTaxQuery(
  baseOptions: Apollo.QueryHookOptions<
    PredictedTaxQuery,
    PredictedTaxQueryVariables
  > &
    (
      | { variables: PredictedTaxQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PredictedTaxQuery, PredictedTaxQueryVariables>(
    PredictedTaxDocument,
    options,
  );
}
export function usePredictedTaxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PredictedTaxQuery,
    PredictedTaxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PredictedTaxQuery, PredictedTaxQueryVariables>(
    PredictedTaxDocument,
    options,
  );
}
export function usePredictedTaxSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PredictedTaxQuery,
        PredictedTaxQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PredictedTaxQuery, PredictedTaxQueryVariables>(
    PredictedTaxDocument,
    options,
  );
}
export type PredictedTaxQueryHookResult = ReturnType<
  typeof usePredictedTaxQuery
>;
export type PredictedTaxLazyQueryHookResult = ReturnType<
  typeof usePredictedTaxLazyQuery
>;
export type PredictedTaxSuspenseQueryHookResult = ReturnType<
  typeof usePredictedTaxSuspenseQuery
>;
export type PredictedTaxQueryResult = Apollo.QueryResult<
  PredictedTaxQuery,
  PredictedTaxQueryVariables
>;
export const ProjectExternalProjectsWithEstimatesDocument = gql`
  query ProjectExternalProjectsWithEstimates($id: ID!) {
    project(id: $id) {
      ...ProjectExternalProjectsWithEstimatesFields
    }
  }
  ${ProjectExternalProjectsWithEstimatesFieldsFragmentDoc}
`;

/**
 * __useProjectExternalProjectsWithEstimatesQuery__
 *
 * To run a query within a React component, call `useProjectExternalProjectsWithEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectExternalProjectsWithEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectExternalProjectsWithEstimatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectExternalProjectsWithEstimatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectExternalProjectsWithEstimatesQuery,
    ProjectExternalProjectsWithEstimatesQueryVariables
  > &
    (
      | {
          variables: ProjectExternalProjectsWithEstimatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectExternalProjectsWithEstimatesQuery,
    ProjectExternalProjectsWithEstimatesQueryVariables
  >(ProjectExternalProjectsWithEstimatesDocument, options);
}
export function useProjectExternalProjectsWithEstimatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectExternalProjectsWithEstimatesQuery,
    ProjectExternalProjectsWithEstimatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectExternalProjectsWithEstimatesQuery,
    ProjectExternalProjectsWithEstimatesQueryVariables
  >(ProjectExternalProjectsWithEstimatesDocument, options);
}
export function useProjectExternalProjectsWithEstimatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectExternalProjectsWithEstimatesQuery,
        ProjectExternalProjectsWithEstimatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectExternalProjectsWithEstimatesQuery,
    ProjectExternalProjectsWithEstimatesQueryVariables
  >(ProjectExternalProjectsWithEstimatesDocument, options);
}
export type ProjectExternalProjectsWithEstimatesQueryHookResult = ReturnType<
  typeof useProjectExternalProjectsWithEstimatesQuery
>;
export type ProjectExternalProjectsWithEstimatesLazyQueryHookResult =
  ReturnType<typeof useProjectExternalProjectsWithEstimatesLazyQuery>;
export type ProjectExternalProjectsWithEstimatesSuspenseQueryHookResult =
  ReturnType<typeof useProjectExternalProjectsWithEstimatesSuspenseQuery>;
export type ProjectExternalProjectsWithEstimatesQueryResult =
  Apollo.QueryResult<
    ProjectExternalProjectsWithEstimatesQuery,
    ProjectExternalProjectsWithEstimatesQueryVariables
  >;
export const ProjectListOptionsDocument = gql`
  query ProjectListOptions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryProjectsFilter
  ) {
    projects(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ProjectListOptionFields
        }
      }
    }
  }
  ${ProjectListOptionFieldsFragmentDoc}
`;

/**
 * __useProjectListOptionsQuery__
 *
 * To run a query within a React component, call `useProjectListOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectListOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectListOptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectListOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectListOptionsQuery,
    ProjectListOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectListOptionsQuery,
    ProjectListOptionsQueryVariables
  >(ProjectListOptionsDocument, options);
}
export function useProjectListOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectListOptionsQuery,
    ProjectListOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectListOptionsQuery,
    ProjectListOptionsQueryVariables
  >(ProjectListOptionsDocument, options);
}
export function useProjectListOptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectListOptionsQuery,
        ProjectListOptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectListOptionsQuery,
    ProjectListOptionsQueryVariables
  >(ProjectListOptionsDocument, options);
}
export type ProjectListOptionsQueryHookResult = ReturnType<
  typeof useProjectListOptionsQuery
>;
export type ProjectListOptionsLazyQueryHookResult = ReturnType<
  typeof useProjectListOptionsLazyQuery
>;
export type ProjectListOptionsSuspenseQueryHookResult = ReturnType<
  typeof useProjectListOptionsSuspenseQuery
>;
export type ProjectListOptionsQueryResult = Apollo.QueryResult<
  ProjectListOptionsQuery,
  ProjectListOptionsQueryVariables
>;
export const ProjectPredictedPoNumberDocument = gql`
  query ProjectPredictedPoNumber($id: ID!) {
    project(id: $id) {
      ...ProjectPredictedPoNumberFields
    }
  }
  ${ProjectPredictedPoNumberFieldsFragmentDoc}
`;

/**
 * __useProjectPredictedPoNumberQuery__
 *
 * To run a query within a React component, call `useProjectPredictedPoNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPredictedPoNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPredictedPoNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectPredictedPoNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectPredictedPoNumberQuery,
    ProjectPredictedPoNumberQueryVariables
  > &
    (
      | { variables: ProjectPredictedPoNumberQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectPredictedPoNumberQuery,
    ProjectPredictedPoNumberQueryVariables
  >(ProjectPredictedPoNumberDocument, options);
}
export function useProjectPredictedPoNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectPredictedPoNumberQuery,
    ProjectPredictedPoNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectPredictedPoNumberQuery,
    ProjectPredictedPoNumberQueryVariables
  >(ProjectPredictedPoNumberDocument, options);
}
export function useProjectPredictedPoNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectPredictedPoNumberQuery,
        ProjectPredictedPoNumberQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectPredictedPoNumberQuery,
    ProjectPredictedPoNumberQueryVariables
  >(ProjectPredictedPoNumberDocument, options);
}
export type ProjectPredictedPoNumberQueryHookResult = ReturnType<
  typeof useProjectPredictedPoNumberQuery
>;
export type ProjectPredictedPoNumberLazyQueryHookResult = ReturnType<
  typeof useProjectPredictedPoNumberLazyQuery
>;
export type ProjectPredictedPoNumberSuspenseQueryHookResult = ReturnType<
  typeof useProjectPredictedPoNumberSuspenseQuery
>;
export type ProjectPredictedPoNumberQueryResult = Apollo.QueryResult<
  ProjectPredictedPoNumberQuery,
  ProjectPredictedPoNumberQueryVariables
>;
export const QuoteMessagesContextDocument = gql`
  query QuoteMessagesContext($id: ID!) {
    quote(id: $id) {
      ...QuoteMessageContextFields
    }
  }
  ${QuoteMessageContextFieldsFragmentDoc}
`;

/**
 * __useQuoteMessagesContextQuery__
 *
 * To run a query within a React component, call `useQuoteMessagesContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteMessagesContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteMessagesContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteMessagesContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuoteMessagesContextQuery,
    QuoteMessagesContextQueryVariables
  > &
    (
      | { variables: QuoteMessagesContextQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QuoteMessagesContextQuery,
    QuoteMessagesContextQueryVariables
  >(QuoteMessagesContextDocument, options);
}
export function useQuoteMessagesContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuoteMessagesContextQuery,
    QuoteMessagesContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QuoteMessagesContextQuery,
    QuoteMessagesContextQueryVariables
  >(QuoteMessagesContextDocument, options);
}
export function useQuoteMessagesContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QuoteMessagesContextQuery,
        QuoteMessagesContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QuoteMessagesContextQuery,
    QuoteMessagesContextQueryVariables
  >(QuoteMessagesContextDocument, options);
}
export type QuoteMessagesContextQueryHookResult = ReturnType<
  typeof useQuoteMessagesContextQuery
>;
export type QuoteMessagesContextLazyQueryHookResult = ReturnType<
  typeof useQuoteMessagesContextLazyQuery
>;
export type QuoteMessagesContextSuspenseQueryHookResult = ReturnType<
  typeof useQuoteMessagesContextSuspenseQuery
>;
export type QuoteMessagesContextQueryResult = Apollo.QueryResult<
  QuoteMessagesContextQuery,
  QuoteMessagesContextQueryVariables
>;
export const ReleaseDocument = gql`
  query Release($id: ID!, $invoiceId: ID, $invoiceStatuses: [InvoiceStatus!]) {
    release(id: $id) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;

/**
 * __useReleaseQuery__
 *
 * To run a query within a React component, call `useReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useReleaseQuery(
  baseOptions: Apollo.QueryHookOptions<ReleaseQuery, ReleaseQueryVariables> &
    ({ variables: ReleaseQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleaseQuery, ReleaseQueryVariables>(
    ReleaseDocument,
    options,
  );
}
export function useReleaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleaseQuery,
    ReleaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReleaseQuery, ReleaseQueryVariables>(
    ReleaseDocument,
    options,
  );
}
export function useReleaseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReleaseQuery, ReleaseQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReleaseQuery, ReleaseQueryVariables>(
    ReleaseDocument,
    options,
  );
}
export type ReleaseQueryHookResult = ReturnType<typeof useReleaseQuery>;
export type ReleaseLazyQueryHookResult = ReturnType<typeof useReleaseLazyQuery>;
export type ReleaseSuspenseQueryHookResult = ReturnType<
  typeof useReleaseSuspenseQuery
>;
export type ReleaseQueryResult = Apollo.QueryResult<
  ReleaseQuery,
  ReleaseQueryVariables
>;
export const ReleaseDistributorMessagesContextDocument = gql`
  query ReleaseDistributorMessagesContext($id: ID!) {
    release(id: $id) {
      ...ReleaseDistributorMessagesContextFields
    }
  }
  ${ReleaseDistributorMessagesContextFieldsFragmentDoc}
`;

/**
 * __useReleaseDistributorMessagesContextQuery__
 *
 * To run a query within a React component, call `useReleaseDistributorMessagesContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseDistributorMessagesContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseDistributorMessagesContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseDistributorMessagesContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleaseDistributorMessagesContextQuery,
    ReleaseDistributorMessagesContextQueryVariables
  > &
    (
      | {
          variables: ReleaseDistributorMessagesContextQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReleaseDistributorMessagesContextQuery,
    ReleaseDistributorMessagesContextQueryVariables
  >(ReleaseDistributorMessagesContextDocument, options);
}
export function useReleaseDistributorMessagesContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleaseDistributorMessagesContextQuery,
    ReleaseDistributorMessagesContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleaseDistributorMessagesContextQuery,
    ReleaseDistributorMessagesContextQueryVariables
  >(ReleaseDistributorMessagesContextDocument, options);
}
export function useReleaseDistributorMessagesContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleaseDistributorMessagesContextQuery,
        ReleaseDistributorMessagesContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleaseDistributorMessagesContextQuery,
    ReleaseDistributorMessagesContextQueryVariables
  >(ReleaseDistributorMessagesContextDocument, options);
}
export type ReleaseDistributorMessagesContextQueryHookResult = ReturnType<
  typeof useReleaseDistributorMessagesContextQuery
>;
export type ReleaseDistributorMessagesContextLazyQueryHookResult = ReturnType<
  typeof useReleaseDistributorMessagesContextLazyQuery
>;
export type ReleaseDistributorMessagesContextSuspenseQueryHookResult =
  ReturnType<typeof useReleaseDistributorMessagesContextSuspenseQuery>;
export type ReleaseDistributorMessagesContextQueryResult = Apollo.QueryResult<
  ReleaseDistributorMessagesContextQuery,
  ReleaseDistributorMessagesContextQueryVariables
>;
export const ReleaseMessagesContextDocument = gql`
  query ReleaseMessagesContext($id: ID!) {
    release(id: $id) {
      ...ReleaseMessagesContextFields
    }
  }
  ${ReleaseMessagesContextFieldsFragmentDoc}
`;

/**
 * __useReleaseMessagesContextQuery__
 *
 * To run a query within a React component, call `useReleaseMessagesContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseMessagesContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseMessagesContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseMessagesContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleaseMessagesContextQuery,
    ReleaseMessagesContextQueryVariables
  > &
    (
      | { variables: ReleaseMessagesContextQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReleaseMessagesContextQuery,
    ReleaseMessagesContextQueryVariables
  >(ReleaseMessagesContextDocument, options);
}
export function useReleaseMessagesContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleaseMessagesContextQuery,
    ReleaseMessagesContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleaseMessagesContextQuery,
    ReleaseMessagesContextQueryVariables
  >(ReleaseMessagesContextDocument, options);
}
export function useReleaseMessagesContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleaseMessagesContextQuery,
        ReleaseMessagesContextQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleaseMessagesContextQuery,
    ReleaseMessagesContextQueryVariables
  >(ReleaseMessagesContextDocument, options);
}
export type ReleaseMessagesContextQueryHookResult = ReturnType<
  typeof useReleaseMessagesContextQuery
>;
export type ReleaseMessagesContextLazyQueryHookResult = ReturnType<
  typeof useReleaseMessagesContextLazyQuery
>;
export type ReleaseMessagesContextSuspenseQueryHookResult = ReturnType<
  typeof useReleaseMessagesContextSuspenseQuery
>;
export type ReleaseMessagesContextQueryResult = Apollo.QueryResult<
  ReleaseMessagesContextQuery,
  ReleaseMessagesContextQueryVariables
>;
export const ReleasePoLinkDocument = gql`
  query ReleasePOLink($id: ID!) {
    release(id: $id) {
      id
      poLink {
        ...POLinkFields
      }
      poNumber
    }
  }
  ${PoLinkFieldsFragmentDoc}
`;

/**
 * __useReleasePoLinkQuery__
 *
 * To run a query within a React component, call `useReleasePoLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasePoLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasePoLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleasePoLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleasePoLinkQuery,
    ReleasePoLinkQueryVariables
  > &
    (
      | { variables: ReleasePoLinkQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleasePoLinkQuery, ReleasePoLinkQueryVariables>(
    ReleasePoLinkDocument,
    options,
  );
}
export function useReleasePoLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasePoLinkQuery,
    ReleasePoLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReleasePoLinkQuery, ReleasePoLinkQueryVariables>(
    ReleasePoLinkDocument,
    options,
  );
}
export function useReleasePoLinkSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleasePoLinkQuery,
        ReleasePoLinkQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleasePoLinkQuery,
    ReleasePoLinkQueryVariables
  >(ReleasePoLinkDocument, options);
}
export type ReleasePoLinkQueryHookResult = ReturnType<
  typeof useReleasePoLinkQuery
>;
export type ReleasePoLinkLazyQueryHookResult = ReturnType<
  typeof useReleasePoLinkLazyQuery
>;
export type ReleasePoLinkSuspenseQueryHookResult = ReturnType<
  typeof useReleasePoLinkSuspenseQuery
>;
export type ReleasePoLinkQueryResult = Apollo.QueryResult<
  ReleasePoLinkQuery,
  ReleasePoLinkQueryVariables
>;
export const ReleaseProjectDocument = gql`
  query ReleaseProject($id: ID!, $excludePhantoms: Boolean = false) {
    project(id: $id) {
      ...ReleaseProjectFields
    }
  }
  ${ReleaseProjectFieldsFragmentDoc}
`;

/**
 * __useReleaseProjectQuery__
 *
 * To run a query within a React component, call `useReleaseProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      excludePhantoms: // value for 'excludePhantoms'
 *   },
 * });
 */
export function useReleaseProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleaseProjectQuery,
    ReleaseProjectQueryVariables
  > &
    (
      | { variables: ReleaseProjectQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleaseProjectQuery, ReleaseProjectQueryVariables>(
    ReleaseProjectDocument,
    options,
  );
}
export function useReleaseProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleaseProjectQuery,
    ReleaseProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReleaseProjectQuery, ReleaseProjectQueryVariables>(
    ReleaseProjectDocument,
    options,
  );
}
export function useReleaseProjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleaseProjectQuery,
        ReleaseProjectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleaseProjectQuery,
    ReleaseProjectQueryVariables
  >(ReleaseProjectDocument, options);
}
export type ReleaseProjectQueryHookResult = ReturnType<
  typeof useReleaseProjectQuery
>;
export type ReleaseProjectLazyQueryHookResult = ReturnType<
  typeof useReleaseProjectLazyQuery
>;
export type ReleaseProjectSuspenseQueryHookResult = ReturnType<
  typeof useReleaseProjectSuspenseQuery
>;
export type ReleaseProjectQueryResult = Apollo.QueryResult<
  ReleaseProjectQuery,
  ReleaseProjectQueryVariables
>;
export const ReleasesDocument = gql`
  query Releases(
    $filter: QueryReleasesFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sort: QueryReleasesSort
  ) {
    releases(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ReleasePartialFields
        }
      }
    }
  }
  ${ReleasePartialFieldsFragmentDoc}
`;

/**
 * __useReleasesQuery__
 *
 * To run a query within a React component, call `useReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReleasesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReleasesQuery, ReleasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleasesQuery, ReleasesQueryVariables>(
    ReleasesDocument,
    options,
  );
}
export function useReleasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasesQuery,
    ReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReleasesQuery, ReleasesQueryVariables>(
    ReleasesDocument,
    options,
  );
}
export function useReleasesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReleasesQuery, ReleasesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReleasesQuery, ReleasesQueryVariables>(
    ReleasesDocument,
    options,
  );
}
export type ReleasesQueryHookResult = ReturnType<typeof useReleasesQuery>;
export type ReleasesLazyQueryHookResult = ReturnType<
  typeof useReleasesLazyQuery
>;
export type ReleasesSuspenseQueryHookResult = ReturnType<
  typeof useReleasesSuspenseQuery
>;
export type ReleasesQueryResult = Apollo.QueryResult<
  ReleasesQuery,
  ReleasesQueryVariables
>;
export const ReleasesSelectorDocument = gql`
  query ReleasesSelector(
    $filter: QueryReleasesFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    releases(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ReleaseSelectorFields
        }
      }
    }
  }
  ${ReleaseSelectorFieldsFragmentDoc}
`;

/**
 * __useReleasesSelectorQuery__
 *
 * To run a query within a React component, call `useReleasesSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesSelectorQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useReleasesSelectorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReleasesSelectorQuery,
    ReleasesSelectorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleasesSelectorQuery, ReleasesSelectorQueryVariables>(
    ReleasesSelectorDocument,
    options,
  );
}
export function useReleasesSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasesSelectorQuery,
    ReleasesSelectorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleasesSelectorQuery,
    ReleasesSelectorQueryVariables
  >(ReleasesSelectorDocument, options);
}
export function useReleasesSelectorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleasesSelectorQuery,
        ReleasesSelectorQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleasesSelectorQuery,
    ReleasesSelectorQueryVariables
  >(ReleasesSelectorDocument, options);
}
export type ReleasesSelectorQueryHookResult = ReturnType<
  typeof useReleasesSelectorQuery
>;
export type ReleasesSelectorLazyQueryHookResult = ReturnType<
  typeof useReleasesSelectorLazyQuery
>;
export type ReleasesSelectorSuspenseQueryHookResult = ReturnType<
  typeof useReleasesSelectorSuspenseQuery
>;
export type ReleasesSelectorQueryResult = Apollo.QueryResult<
  ReleasesSelectorQuery,
  ReleasesSelectorQueryVariables
>;
export const RfqDocument = gql`
  query Rfq($id: ID!) {
    rfq(id: $id) {
      ...RfqFields
    }
  }
  ${RfqFieldsFragmentDoc}
`;

/**
 * __useRfqQuery__
 *
 * To run a query within a React component, call `useRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRfqQuery(
  baseOptions: Apollo.QueryHookOptions<RfqQuery, RfqQueryVariables> &
    ({ variables: RfqQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RfqQuery, RfqQueryVariables>(RfqDocument, options);
}
export function useRfqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RfqQuery, RfqQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RfqQuery, RfqQueryVariables>(RfqDocument, options);
}
export function useRfqSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RfqQuery, RfqQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RfqQuery, RfqQueryVariables>(
    RfqDocument,
    options,
  );
}
export type RfqQueryHookResult = ReturnType<typeof useRfqQuery>;
export type RfqLazyQueryHookResult = ReturnType<typeof useRfqLazyQuery>;
export type RfqSuspenseQueryHookResult = ReturnType<typeof useRfqSuspenseQuery>;
export type RfqQueryResult = Apollo.QueryResult<RfqQuery, RfqQueryVariables>;
export const RfqQuotesDocument = gql`
  query RfqQuotes($id: ID!, $quoteStatus: [QuoteStatus!]) {
    rfq(id: $id) {
      ...RfqQuotesFields
    }
  }
  ${RfqQuotesFieldsFragmentDoc}
`;

/**
 * __useRfqQuotesQuery__
 *
 * To run a query within a React component, call `useRfqQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqQuotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      quoteStatus: // value for 'quoteStatus'
 *   },
 * });
 */
export function useRfqQuotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RfqQuotesQuery,
    RfqQuotesQueryVariables
  > &
    (
      | { variables: RfqQuotesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RfqQuotesQuery, RfqQuotesQueryVariables>(
    RfqQuotesDocument,
    options,
  );
}
export function useRfqQuotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RfqQuotesQuery,
    RfqQuotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RfqQuotesQuery, RfqQuotesQueryVariables>(
    RfqQuotesDocument,
    options,
  );
}
export function useRfqQuotesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RfqQuotesQuery, RfqQuotesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RfqQuotesQuery, RfqQuotesQueryVariables>(
    RfqQuotesDocument,
    options,
  );
}
export type RfqQuotesQueryHookResult = ReturnType<typeof useRfqQuotesQuery>;
export type RfqQuotesLazyQueryHookResult = ReturnType<
  typeof useRfqQuotesLazyQuery
>;
export type RfqQuotesSuspenseQueryHookResult = ReturnType<
  typeof useRfqQuotesSuspenseQuery
>;
export type RfqQuotesQueryResult = Apollo.QueryResult<
  RfqQuotesQuery,
  RfqQuotesQueryVariables
>;
export const RfqVendorsDocument = gql`
  query RfqVendors($id: ID!) {
    rfq(id: $id) {
      id
      quotes {
        ...RfqVendorFields
      }
      targets {
        id
        sellerOrgLocation {
          id
        }
        contacts {
          id
          name
          email
          cellPhone
          receivesQuoteNotifications
        }
      }
    }
  }
  ${RfqVendorFieldsFragmentDoc}
`;

/**
 * __useRfqVendorsQuery__
 *
 * To run a query within a React component, call `useRfqVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqVendorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRfqVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RfqVendorsQuery,
    RfqVendorsQueryVariables
  > &
    (
      | { variables: RfqVendorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RfqVendorsQuery, RfqVendorsQueryVariables>(
    RfqVendorsDocument,
    options,
  );
}
export function useRfqVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RfqVendorsQuery,
    RfqVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RfqVendorsQuery, RfqVendorsQueryVariables>(
    RfqVendorsDocument,
    options,
  );
}
export function useRfqVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RfqVendorsQuery,
        RfqVendorsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RfqVendorsQuery, RfqVendorsQueryVariables>(
    RfqVendorsDocument,
    options,
  );
}
export type RfqVendorsQueryHookResult = ReturnType<typeof useRfqVendorsQuery>;
export type RfqVendorsLazyQueryHookResult = ReturnType<
  typeof useRfqVendorsLazyQuery
>;
export type RfqVendorsSuspenseQueryHookResult = ReturnType<
  typeof useRfqVendorsSuspenseQuery
>;
export type RfqVendorsQueryResult = Apollo.QueryResult<
  RfqVendorsQuery,
  RfqVendorsQueryVariables
>;
export const RfqsDocument = gql`
  query Rfqs(
    $filter: QueryRFQsFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $quoteStatus: [QuoteStatus!]
  ) {
    rfqs(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...RfqsRfqFields
        }
      }
    }
  }
  ${RfqsRfqFieldsFragmentDoc}
`;

/**
 * __useRfqsQuery__
 *
 * To run a query within a React component, call `useRfqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      quoteStatus: // value for 'quoteStatus'
 *   },
 * });
 */
export function useRfqsQuery(
  baseOptions?: Apollo.QueryHookOptions<RfqsQuery, RfqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RfqsQuery, RfqsQueryVariables>(RfqsDocument, options);
}
export function useRfqsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RfqsQuery, RfqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RfqsQuery, RfqsQueryVariables>(
    RfqsDocument,
    options,
  );
}
export function useRfqsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RfqsQuery, RfqsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RfqsQuery, RfqsQueryVariables>(
    RfqsDocument,
    options,
  );
}
export type RfqsQueryHookResult = ReturnType<typeof useRfqsQuery>;
export type RfqsLazyQueryHookResult = ReturnType<typeof useRfqsLazyQuery>;
export type RfqsSuspenseQueryHookResult = ReturnType<
  typeof useRfqsSuspenseQuery
>;
export type RfqsQueryResult = Apollo.QueryResult<RfqsQuery, RfqsQueryVariables>;
export const StartupDataDocument = gql`
  query StartupData {
    viewer {
      ...StartupDataFields
    }
  }
  ${StartupDataFieldsFragmentDoc}
`;

/**
 * __useStartupDataQuery__
 *
 * To run a query within a React component, call `useStartupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartupDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useStartupDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StartupDataQuery,
    StartupDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StartupDataQuery, StartupDataQueryVariables>(
    StartupDataDocument,
    options,
  );
}
export function useStartupDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartupDataQuery,
    StartupDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StartupDataQuery, StartupDataQueryVariables>(
    StartupDataDocument,
    options,
  );
}
export function useStartupDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        StartupDataQuery,
        StartupDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StartupDataQuery, StartupDataQueryVariables>(
    StartupDataDocument,
    options,
  );
}
export type StartupDataQueryHookResult = ReturnType<typeof useStartupDataQuery>;
export type StartupDataLazyQueryHookResult = ReturnType<
  typeof useStartupDataLazyQuery
>;
export type StartupDataSuspenseQueryHookResult = ReturnType<
  typeof useStartupDataSuspenseQuery
>;
export type StartupDataQueryResult = Apollo.QueryResult<
  StartupDataQuery,
  StartupDataQueryVariables
>;
export const UomDocument = gql`
  query Uom($id: ID!) {
    uom(id: $id) {
      ...UOMFields
    }
  }
  ${UomFieldsFragmentDoc}
`;

/**
 * __useUomQuery__
 *
 * To run a query within a React component, call `useUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUomQuery(
  baseOptions: Apollo.QueryHookOptions<UomQuery, UomQueryVariables> &
    ({ variables: UomQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UomQuery, UomQueryVariables>(UomDocument, options);
}
export function useUomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UomQuery, UomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UomQuery, UomQueryVariables>(UomDocument, options);
}
export function useUomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UomQuery, UomQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UomQuery, UomQueryVariables>(
    UomDocument,
    options,
  );
}
export type UomQueryHookResult = ReturnType<typeof useUomQuery>;
export type UomLazyQueryHookResult = ReturnType<typeof useUomLazyQuery>;
export type UomSuspenseQueryHookResult = ReturnType<typeof useUomSuspenseQuery>;
export type UomQueryResult = Apollo.QueryResult<UomQuery, UomQueryVariables>;
export const UomsDocument = gql`
  query Uoms {
    uoms {
      ...UOMFields
    }
  }
  ${UomFieldsFragmentDoc}
`;

/**
 * __useUomsQuery__
 *
 * To run a query within a React component, call `useUomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUomsQuery(
  baseOptions?: Apollo.QueryHookOptions<UomsQuery, UomsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UomsQuery, UomsQueryVariables>(UomsDocument, options);
}
export function useUomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UomsQuery, UomsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UomsQuery, UomsQueryVariables>(
    UomsDocument,
    options,
  );
}
export function useUomsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UomsQuery, UomsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UomsQuery, UomsQueryVariables>(
    UomsDocument,
    options,
  );
}
export type UomsQueryHookResult = ReturnType<typeof useUomsQuery>;
export type UomsLazyQueryHookResult = ReturnType<typeof useUomsLazyQuery>;
export type UomsSuspenseQueryHookResult = ReturnType<
  typeof useUomsSuspenseQuery
>;
export type UomsQueryResult = Apollo.QueryResult<UomsQuery, UomsQueryVariables>;
export const UsersDocument = gql`
  query Users($orgLocationID: ID, $projectID: ID) {
    users(orgLocationID: $orgLocationID, projectID: $projectID) {
      ...UsersUserFields
    }
  }
  ${UsersUserFieldsFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      orgLocationID: // value for 'orgLocationID'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<
  typeof useUsersSuspenseQuery
>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      ...UserViewerFields
    }
  }
  ${UserViewerFieldsFragmentDoc}
`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options,
  );
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options,
  );
}
export function useViewerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ViewerQuery, ViewerQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options,
  );
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerSuspenseQueryHookResult = ReturnType<
  typeof useViewerSuspenseQuery
>;
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>;
export const ViewerOrgDocument = gql`
  query ViewerOrg {
    viewer {
      id
      org {
        ...ViewerOrgFields
      }
    }
  }
  ${ViewerOrgFieldsFragmentDoc}
`;

/**
 * __useViewerOrgQuery__
 *
 * To run a query within a React component, call `useViewerOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewerOrgQuery,
    ViewerOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewerOrgQuery, ViewerOrgQueryVariables>(
    ViewerOrgDocument,
    options,
  );
}
export function useViewerOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewerOrgQuery,
    ViewerOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewerOrgQuery, ViewerOrgQueryVariables>(
    ViewerOrgDocument,
    options,
  );
}
export function useViewerOrgSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ViewerOrgQuery, ViewerOrgQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ViewerOrgQuery, ViewerOrgQueryVariables>(
    ViewerOrgDocument,
    options,
  );
}
export type ViewerOrgQueryHookResult = ReturnType<typeof useViewerOrgQuery>;
export type ViewerOrgLazyQueryHookResult = ReturnType<
  typeof useViewerOrgLazyQuery
>;
export type ViewerOrgSuspenseQueryHookResult = ReturnType<
  typeof useViewerOrgSuspenseQuery
>;
export type ViewerOrgQueryResult = Apollo.QueryResult<
  ViewerOrgQuery,
  ViewerOrgQueryVariables
>;
export const AddCostCodeDocument = gql`
  mutation AddCostCode($input: AddCostCodeInput!) {
    addCostCode(input: $input) {
      ...CostCodeFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
`;
export type AddCostCodeMutationFn = Apollo.MutationFunction<
  AddCostCodeMutation,
  AddCostCodeMutationVariables
>;

/**
 * __useAddCostCodeMutation__
 *
 * To run a mutation, you first call `useAddCostCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCostCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCostCodeMutation, { data, loading, error }] = useAddCostCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCostCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCostCodeMutation,
    AddCostCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCostCodeMutation, AddCostCodeMutationVariables>(
    AddCostCodeDocument,
    options,
  );
}
export type AddCostCodeMutationHookResult = ReturnType<
  typeof useAddCostCodeMutation
>;
export type AddCostCodeMutationResult =
  Apollo.MutationResult<AddCostCodeMutation>;
export type AddCostCodeMutationOptions = Apollo.BaseMutationOptions<
  AddCostCodeMutation,
  AddCostCodeMutationVariables
>;
export const ConnectCostCodeLedgerAccountDocument = gql`
  mutation ConnectCostCodeLedgerAccount($input: ConnectNodeInput!) {
    connectCostCodeLedgerAccount(input: $input) {
      ...CostCodeFields
      ledgerAccount {
        ...LedgerAccountFields
      }
    }
  }
  ${CostCodeFieldsFragmentDoc}
  ${LedgerAccountFieldsFragmentDoc}
`;
export type ConnectCostCodeLedgerAccountMutationFn = Apollo.MutationFunction<
  ConnectCostCodeLedgerAccountMutation,
  ConnectCostCodeLedgerAccountMutationVariables
>;

/**
 * __useConnectCostCodeLedgerAccountMutation__
 *
 * To run a mutation, you first call `useConnectCostCodeLedgerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectCostCodeLedgerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectCostCodeLedgerAccountMutation, { data, loading, error }] = useConnectCostCodeLedgerAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectCostCodeLedgerAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectCostCodeLedgerAccountMutation,
    ConnectCostCodeLedgerAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectCostCodeLedgerAccountMutation,
    ConnectCostCodeLedgerAccountMutationVariables
  >(ConnectCostCodeLedgerAccountDocument, options);
}
export type ConnectCostCodeLedgerAccountMutationHookResult = ReturnType<
  typeof useConnectCostCodeLedgerAccountMutation
>;
export type ConnectCostCodeLedgerAccountMutationResult =
  Apollo.MutationResult<ConnectCostCodeLedgerAccountMutation>;
export type ConnectCostCodeLedgerAccountMutationOptions =
  Apollo.BaseMutationOptions<
    ConnectCostCodeLedgerAccountMutation,
    ConnectCostCodeLedgerAccountMutationVariables
  >;
export const DisconnectCostCodeLedgerAccountDocument = gql`
  mutation DisconnectCostCodeLedgerAccount($input: DisconnectNodeInput!) {
    disconnectCostCodeLedgerAccount(input: $input) {
      ...CostCodeFields
      ledgerAccount {
        ...LedgerAccountFields
      }
    }
  }
  ${CostCodeFieldsFragmentDoc}
  ${LedgerAccountFieldsFragmentDoc}
`;
export type DisconnectCostCodeLedgerAccountMutationFn = Apollo.MutationFunction<
  DisconnectCostCodeLedgerAccountMutation,
  DisconnectCostCodeLedgerAccountMutationVariables
>;

/**
 * __useDisconnectCostCodeLedgerAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectCostCodeLedgerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCostCodeLedgerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCostCodeLedgerAccountMutation, { data, loading, error }] = useDisconnectCostCodeLedgerAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectCostCodeLedgerAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectCostCodeLedgerAccountMutation,
    DisconnectCostCodeLedgerAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectCostCodeLedgerAccountMutation,
    DisconnectCostCodeLedgerAccountMutationVariables
  >(DisconnectCostCodeLedgerAccountDocument, options);
}
export type DisconnectCostCodeLedgerAccountMutationHookResult = ReturnType<
  typeof useDisconnectCostCodeLedgerAccountMutation
>;
export type DisconnectCostCodeLedgerAccountMutationResult =
  Apollo.MutationResult<DisconnectCostCodeLedgerAccountMutation>;
export type DisconnectCostCodeLedgerAccountMutationOptions =
  Apollo.BaseMutationOptions<
    DisconnectCostCodeLedgerAccountMutation,
    DisconnectCostCodeLedgerAccountMutationVariables
  >;
export const ImportCostCodesDocument = gql`
  mutation ImportCostCodes($input: ImportCostCodesInput!) {
    importCostCodes(input: $input) {
      created {
        ...CostCodeFields
      }
      skipped {
        ...CostCodeFields
      }
      mapped {
        ...CostCodeFields
      }
    }
  }
  ${CostCodeFieldsFragmentDoc}
`;
export type ImportCostCodesMutationFn = Apollo.MutationFunction<
  ImportCostCodesMutation,
  ImportCostCodesMutationVariables
>;

/**
 * __useImportCostCodesMutation__
 *
 * To run a mutation, you first call `useImportCostCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCostCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCostCodesMutation, { data, loading, error }] = useImportCostCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCostCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportCostCodesMutation,
    ImportCostCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportCostCodesMutation,
    ImportCostCodesMutationVariables
  >(ImportCostCodesDocument, options);
}
export type ImportCostCodesMutationHookResult = ReturnType<
  typeof useImportCostCodesMutation
>;
export type ImportCostCodesMutationResult =
  Apollo.MutationResult<ImportCostCodesMutation>;
export type ImportCostCodesMutationOptions = Apollo.BaseMutationOptions<
  ImportCostCodesMutation,
  ImportCostCodesMutationVariables
>;
export const RemoveCostCodeDocument = gql`
  mutation RemoveCostCode($costCodeID: ID!) {
    removeCostCode(costCodeID: $costCodeID) {
      ...CostCodeFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
`;
export type RemoveCostCodeMutationFn = Apollo.MutationFunction<
  RemoveCostCodeMutation,
  RemoveCostCodeMutationVariables
>;

/**
 * __useRemoveCostCodeMutation__
 *
 * To run a mutation, you first call `useRemoveCostCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCostCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCostCodeMutation, { data, loading, error }] = useRemoveCostCodeMutation({
 *   variables: {
 *      costCodeID: // value for 'costCodeID'
 *   },
 * });
 */
export function useRemoveCostCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCostCodeMutation,
    RemoveCostCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCostCodeMutation,
    RemoveCostCodeMutationVariables
  >(RemoveCostCodeDocument, options);
}
export type RemoveCostCodeMutationHookResult = ReturnType<
  typeof useRemoveCostCodeMutation
>;
export type RemoveCostCodeMutationResult =
  Apollo.MutationResult<RemoveCostCodeMutation>;
export type RemoveCostCodeMutationOptions = Apollo.BaseMutationOptions<
  RemoveCostCodeMutation,
  RemoveCostCodeMutationVariables
>;
export const UpdateCostCodeDocument = gql`
  mutation UpdateCostCode($input: UpdateCostCodeInput!) {
    updateCostCode(input: $input) {
      ...CostCodeFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
`;
export type UpdateCostCodeMutationFn = Apollo.MutationFunction<
  UpdateCostCodeMutation,
  UpdateCostCodeMutationVariables
>;

/**
 * __useUpdateCostCodeMutation__
 *
 * To run a mutation, you first call `useUpdateCostCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCodeMutation, { data, loading, error }] = useUpdateCostCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostCodeMutation,
    UpdateCostCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCostCodeMutation,
    UpdateCostCodeMutationVariables
  >(UpdateCostCodeDocument, options);
}
export type UpdateCostCodeMutationHookResult = ReturnType<
  typeof useUpdateCostCodeMutation
>;
export type UpdateCostCodeMutationResult =
  Apollo.MutationResult<UpdateCostCodeMutation>;
export type UpdateCostCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostCodeMutation,
  UpdateCostCodeMutationVariables
>;
export const UpdateCostCodesDocument = gql`
  mutation UpdateCostCodes($input: UpdateCostCodesInput!) {
    updateCostCodes(input: $input) {
      ...CostCodeFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
`;
export type UpdateCostCodesMutationFn = Apollo.MutationFunction<
  UpdateCostCodesMutation,
  UpdateCostCodesMutationVariables
>;

/**
 * __useUpdateCostCodesMutation__
 *
 * To run a mutation, you first call `useUpdateCostCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCodesMutation, { data, loading, error }] = useUpdateCostCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostCodesMutation,
    UpdateCostCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCostCodesMutation,
    UpdateCostCodesMutationVariables
  >(UpdateCostCodesDocument, options);
}
export type UpdateCostCodesMutationHookResult = ReturnType<
  typeof useUpdateCostCodesMutation
>;
export type UpdateCostCodesMutationResult =
  Apollo.MutationResult<UpdateCostCodesMutation>;
export type UpdateCostCodesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostCodesMutation,
  UpdateCostCodesMutationVariables
>;
export const UpdateCostCodesWithFileDocument = gql`
  mutation UpdateCostCodesWithFile($input: UpdateCostCodesWithFileInput!) {
    updateCostCodesWithFile(input: $input) {
      ...CostCodeFields
    }
  }
  ${CostCodeFieldsFragmentDoc}
`;
export type UpdateCostCodesWithFileMutationFn = Apollo.MutationFunction<
  UpdateCostCodesWithFileMutation,
  UpdateCostCodesWithFileMutationVariables
>;

/**
 * __useUpdateCostCodesWithFileMutation__
 *
 * To run a mutation, you first call `useUpdateCostCodesWithFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCodesWithFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCodesWithFileMutation, { data, loading, error }] = useUpdateCostCodesWithFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCodesWithFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostCodesWithFileMutation,
    UpdateCostCodesWithFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCostCodesWithFileMutation,
    UpdateCostCodesWithFileMutationVariables
  >(UpdateCostCodesWithFileDocument, options);
}
export type UpdateCostCodesWithFileMutationHookResult = ReturnType<
  typeof useUpdateCostCodesWithFileMutation
>;
export type UpdateCostCodesWithFileMutationResult =
  Apollo.MutationResult<UpdateCostCodesWithFileMutation>;
export type UpdateCostCodesWithFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostCodesWithFileMutation,
  UpdateCostCodesWithFileMutationVariables
>;
export const ImportCostTypesDocument = gql`
  mutation ImportCostTypes($input: ImportCostTypesInput!) {
    importCostTypes(input: $input) {
      created {
        ...CostTypeFields
      }
      mapped {
        ...CostTypeFields
      }
      skipped {
        ...CostTypeFields
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export type ImportCostTypesMutationFn = Apollo.MutationFunction<
  ImportCostTypesMutation,
  ImportCostTypesMutationVariables
>;

/**
 * __useImportCostTypesMutation__
 *
 * To run a mutation, you first call `useImportCostTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCostTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCostTypesMutation, { data, loading, error }] = useImportCostTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCostTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportCostTypesMutation,
    ImportCostTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportCostTypesMutation,
    ImportCostTypesMutationVariables
  >(ImportCostTypesDocument, options);
}
export type ImportCostTypesMutationHookResult = ReturnType<
  typeof useImportCostTypesMutation
>;
export type ImportCostTypesMutationResult =
  Apollo.MutationResult<ImportCostTypesMutation>;
export type ImportCostTypesMutationOptions = Apollo.BaseMutationOptions<
  ImportCostTypesMutation,
  ImportCostTypesMutationVariables
>;
export const UpdateCostTypesDocument = gql`
  mutation UpdateCostTypes($input: UpdateCostTypesInput!) {
    updateCostTypes(input: $input) {
      ...CostTypeFields
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export type UpdateCostTypesMutationFn = Apollo.MutationFunction<
  UpdateCostTypesMutation,
  UpdateCostTypesMutationVariables
>;

/**
 * __useUpdateCostTypesMutation__
 *
 * To run a mutation, you first call `useUpdateCostTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostTypesMutation, { data, loading, error }] = useUpdateCostTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostTypesMutation,
    UpdateCostTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCostTypesMutation,
    UpdateCostTypesMutationVariables
  >(UpdateCostTypesDocument, options);
}
export type UpdateCostTypesMutationHookResult = ReturnType<
  typeof useUpdateCostTypesMutation
>;
export type UpdateCostTypesMutationResult =
  Apollo.MutationResult<UpdateCostTypesMutation>;
export type UpdateCostTypesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostTypesMutation,
  UpdateCostTypesMutationVariables
>;
export const CostTypesDocument = gql`
  query CostTypes {
    viewer {
      id
      org {
        id
        costTypes {
          ...CostTypeFields
        }
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;

/**
 * __useCostTypesQuery__
 *
 * To run a query within a React component, call `useCostTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCostTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CostTypesQuery,
    CostTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostTypesQuery, CostTypesQueryVariables>(
    CostTypesDocument,
    options,
  );
}
export function useCostTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostTypesQuery,
    CostTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostTypesQuery, CostTypesQueryVariables>(
    CostTypesDocument,
    options,
  );
}
export function useCostTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CostTypesQuery, CostTypesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostTypesQuery, CostTypesQueryVariables>(
    CostTypesDocument,
    options,
  );
}
export type CostTypesQueryHookResult = ReturnType<typeof useCostTypesQuery>;
export type CostTypesLazyQueryHookResult = ReturnType<
  typeof useCostTypesLazyQuery
>;
export type CostTypesSuspenseQueryHookResult = ReturnType<
  typeof useCostTypesSuspenseQuery
>;
export type CostTypesQueryResult = Apollo.QueryResult<
  CostTypesQuery,
  CostTypesQueryVariables
>;
export const ImportEquipmentItemsDocument = gql`
  mutation ImportEquipmentItems($input: ImportEquipmentItemsInput!) {
    importEquipmentItems(input: $input) {
      created {
        ...EquipmentItemFields
      }
      mapped {
        ...EquipmentItemFields
      }
      skipped {
        ...EquipmentItemFields
      }
    }
  }
  ${EquipmentItemFieldsFragmentDoc}
`;
export type ImportEquipmentItemsMutationFn = Apollo.MutationFunction<
  ImportEquipmentItemsMutation,
  ImportEquipmentItemsMutationVariables
>;

/**
 * __useImportEquipmentItemsMutation__
 *
 * To run a mutation, you first call `useImportEquipmentItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEquipmentItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEquipmentItemsMutation, { data, loading, error }] = useImportEquipmentItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportEquipmentItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportEquipmentItemsMutation,
    ImportEquipmentItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportEquipmentItemsMutation,
    ImportEquipmentItemsMutationVariables
  >(ImportEquipmentItemsDocument, options);
}
export type ImportEquipmentItemsMutationHookResult = ReturnType<
  typeof useImportEquipmentItemsMutation
>;
export type ImportEquipmentItemsMutationResult =
  Apollo.MutationResult<ImportEquipmentItemsMutation>;
export type ImportEquipmentItemsMutationOptions = Apollo.BaseMutationOptions<
  ImportEquipmentItemsMutation,
  ImportEquipmentItemsMutationVariables
>;
export const UpdateEquipmentItemsDocument = gql`
  mutation UpdateEquipmentItems($input: UpdateEquipmentItemsInput!) {
    updateEquipmentItems(input: $input) {
      ...EquipmentItemFields
    }
  }
  ${EquipmentItemFieldsFragmentDoc}
`;
export type UpdateEquipmentItemsMutationFn = Apollo.MutationFunction<
  UpdateEquipmentItemsMutation,
  UpdateEquipmentItemsMutationVariables
>;

/**
 * __useUpdateEquipmentItemsMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentItemsMutation, { data, loading, error }] = useUpdateEquipmentItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEquipmentItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEquipmentItemsMutation,
    UpdateEquipmentItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEquipmentItemsMutation,
    UpdateEquipmentItemsMutationVariables
  >(UpdateEquipmentItemsDocument, options);
}
export type UpdateEquipmentItemsMutationHookResult = ReturnType<
  typeof useUpdateEquipmentItemsMutation
>;
export type UpdateEquipmentItemsMutationResult =
  Apollo.MutationResult<UpdateEquipmentItemsMutation>;
export type UpdateEquipmentItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateEquipmentItemsMutation,
  UpdateEquipmentItemsMutationVariables
>;
export const EquipmentItemsDocument = gql`
  query EquipmentItems {
    viewer {
      id
      org {
        id
        equipmentItems {
          ...EquipmentItemFields
        }
      }
    }
  }
  ${EquipmentItemFieldsFragmentDoc}
`;

/**
 * __useEquipmentItemsQuery__
 *
 * To run a query within a React component, call `useEquipmentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEquipmentItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EquipmentItemsQuery,
    EquipmentItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentItemsQuery, EquipmentItemsQueryVariables>(
    EquipmentItemsDocument,
    options,
  );
}
export function useEquipmentItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EquipmentItemsQuery,
    EquipmentItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentItemsQuery, EquipmentItemsQueryVariables>(
    EquipmentItemsDocument,
    options,
  );
}
export function useEquipmentItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EquipmentItemsQuery,
        EquipmentItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EquipmentItemsQuery,
    EquipmentItemsQueryVariables
  >(EquipmentItemsDocument, options);
}
export type EquipmentItemsQueryHookResult = ReturnType<
  typeof useEquipmentItemsQuery
>;
export type EquipmentItemsLazyQueryHookResult = ReturnType<
  typeof useEquipmentItemsLazyQuery
>;
export type EquipmentItemsSuspenseQueryHookResult = ReturnType<
  typeof useEquipmentItemsSuspenseQuery
>;
export type EquipmentItemsQueryResult = Apollo.QueryResult<
  EquipmentItemsQuery,
  EquipmentItemsQueryVariables
>;
export const ExternalEquipmentItemsDocument = gql`
  query ExternalEquipmentItems($sourceSystem: SourceSystem!) {
    externalEquipmentItems(sourceSystem: $sourceSystem) {
      ...ExternalEquipmentItemFields
    }
  }
  ${ExternalEquipmentItemFieldsFragmentDoc}
`;

/**
 * __useExternalEquipmentItemsQuery__
 *
 * To run a query within a React component, call `useExternalEquipmentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalEquipmentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalEquipmentItemsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalEquipmentItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalEquipmentItemsQuery,
    ExternalEquipmentItemsQueryVariables
  > &
    (
      | { variables: ExternalEquipmentItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalEquipmentItemsQuery,
    ExternalEquipmentItemsQueryVariables
  >(ExternalEquipmentItemsDocument, options);
}
export function useExternalEquipmentItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalEquipmentItemsQuery,
    ExternalEquipmentItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalEquipmentItemsQuery,
    ExternalEquipmentItemsQueryVariables
  >(ExternalEquipmentItemsDocument, options);
}
export function useExternalEquipmentItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalEquipmentItemsQuery,
        ExternalEquipmentItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalEquipmentItemsQuery,
    ExternalEquipmentItemsQueryVariables
  >(ExternalEquipmentItemsDocument, options);
}
export type ExternalEquipmentItemsQueryHookResult = ReturnType<
  typeof useExternalEquipmentItemsQuery
>;
export type ExternalEquipmentItemsLazyQueryHookResult = ReturnType<
  typeof useExternalEquipmentItemsLazyQuery
>;
export type ExternalEquipmentItemsSuspenseQueryHookResult = ReturnType<
  typeof useExternalEquipmentItemsSuspenseQuery
>;
export type ExternalEquipmentItemsQueryResult = Apollo.QueryResult<
  ExternalEquipmentItemsQuery,
  ExternalEquipmentItemsQueryVariables
>;
export const ImportPaymentMethodsDocument = gql`
  mutation ImportPaymentMethods($input: ImportPaymentMethodsInput!) {
    importPaymentMethods(input: $input) {
      created {
        ...PaymentMethodFields
      }
      mapped {
        ...PaymentMethodFields
      }
      skipped {
        ...PaymentMethodFields
      }
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;
export type ImportPaymentMethodsMutationFn = Apollo.MutationFunction<
  ImportPaymentMethodsMutation,
  ImportPaymentMethodsMutationVariables
>;

/**
 * __useImportPaymentMethodsMutation__
 *
 * To run a mutation, you first call `useImportPaymentMethodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPaymentMethodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPaymentMethodsMutation, { data, loading, error }] = useImportPaymentMethodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPaymentMethodsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportPaymentMethodsMutation,
    ImportPaymentMethodsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportPaymentMethodsMutation,
    ImportPaymentMethodsMutationVariables
  >(ImportPaymentMethodsDocument, options);
}
export type ImportPaymentMethodsMutationHookResult = ReturnType<
  typeof useImportPaymentMethodsMutation
>;
export type ImportPaymentMethodsMutationResult =
  Apollo.MutationResult<ImportPaymentMethodsMutation>;
export type ImportPaymentMethodsMutationOptions = Apollo.BaseMutationOptions<
  ImportPaymentMethodsMutation,
  ImportPaymentMethodsMutationVariables
>;
export const UpdatePaymentMethodsDocument = gql`
  mutation UpdatePaymentMethods($input: UpdatePaymentMethodsInput!) {
    updatePaymentMethods(input: $input) {
      ...PaymentMethodFields
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;
export type UpdatePaymentMethodsMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodsMutation,
  UpdatePaymentMethodsMutationVariables
>;

/**
 * __useUpdatePaymentMethodsMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodsMutation, { data, loading, error }] = useUpdatePaymentMethodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodsMutation,
    UpdatePaymentMethodsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentMethodsMutation,
    UpdatePaymentMethodsMutationVariables
  >(UpdatePaymentMethodsDocument, options);
}
export type UpdatePaymentMethodsMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodsMutation
>;
export type UpdatePaymentMethodsMutationResult =
  Apollo.MutationResult<UpdatePaymentMethodsMutation>;
export type UpdatePaymentMethodsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodsMutation,
  UpdatePaymentMethodsMutationVariables
>;
export const ExternalPaymentMethodsDocument = gql`
  query ExternalPaymentMethods($sourceSystem: SourceSystem!) {
    externalBankAccounts(sourceSystem: $sourceSystem) {
      ...ExternalPaymentMethodFields
    }
  }
  ${ExternalPaymentMethodFieldsFragmentDoc}
`;

/**
 * __useExternalPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useExternalPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPaymentMethodsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalPaymentMethodsQuery,
    ExternalPaymentMethodsQueryVariables
  > &
    (
      | { variables: ExternalPaymentMethodsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalPaymentMethodsQuery,
    ExternalPaymentMethodsQueryVariables
  >(ExternalPaymentMethodsDocument, options);
}
export function useExternalPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalPaymentMethodsQuery,
    ExternalPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalPaymentMethodsQuery,
    ExternalPaymentMethodsQueryVariables
  >(ExternalPaymentMethodsDocument, options);
}
export function useExternalPaymentMethodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalPaymentMethodsQuery,
        ExternalPaymentMethodsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalPaymentMethodsQuery,
    ExternalPaymentMethodsQueryVariables
  >(ExternalPaymentMethodsDocument, options);
}
export type ExternalPaymentMethodsQueryHookResult = ReturnType<
  typeof useExternalPaymentMethodsQuery
>;
export type ExternalPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useExternalPaymentMethodsLazyQuery
>;
export type ExternalPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useExternalPaymentMethodsSuspenseQuery
>;
export type ExternalPaymentMethodsQueryResult = Apollo.QueryResult<
  ExternalPaymentMethodsQuery,
  ExternalPaymentMethodsQueryVariables
>;
export const PaymentMethodsDocument = gql`
  query PaymentMethods {
    viewer {
      id
      org {
        id
        paymentMethods {
          ...PaymentMethodFields
        }
      }
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options,
  );
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options,
  );
}
export function usePaymentMethodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaymentMethodsQuery,
        PaymentMethodsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >(PaymentMethodsDocument, options);
}
export type PaymentMethodsQueryHookResult = ReturnType<
  typeof usePaymentMethodsQuery
>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsLazyQuery
>;
export type PaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof usePaymentMethodsSuspenseQuery
>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const ImportServiceCodesDocument = gql`
  mutation ImportServiceCodes($input: ImportServiceCodesInput!) {
    importServiceCodes(input: $input) {
      created {
        ...ServiceCodeFields
      }
      mapped {
        ...ServiceCodeFields
      }
      skipped {
        ...ServiceCodeFields
      }
    }
  }
  ${ServiceCodeFieldsFragmentDoc}
`;
export type ImportServiceCodesMutationFn = Apollo.MutationFunction<
  ImportServiceCodesMutation,
  ImportServiceCodesMutationVariables
>;

/**
 * __useImportServiceCodesMutation__
 *
 * To run a mutation, you first call `useImportServiceCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportServiceCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importServiceCodesMutation, { data, loading, error }] = useImportServiceCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportServiceCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportServiceCodesMutation,
    ImportServiceCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportServiceCodesMutation,
    ImportServiceCodesMutationVariables
  >(ImportServiceCodesDocument, options);
}
export type ImportServiceCodesMutationHookResult = ReturnType<
  typeof useImportServiceCodesMutation
>;
export type ImportServiceCodesMutationResult =
  Apollo.MutationResult<ImportServiceCodesMutation>;
export type ImportServiceCodesMutationOptions = Apollo.BaseMutationOptions<
  ImportServiceCodesMutation,
  ImportServiceCodesMutationVariables
>;
export const UpdateServiceCodesDocument = gql`
  mutation UpdateServiceCodes($input: UpdateServiceCodesInput!) {
    updateServiceCodes(input: $input) {
      ...ServiceCodeFields
    }
  }
  ${ServiceCodeFieldsFragmentDoc}
`;
export type UpdateServiceCodesMutationFn = Apollo.MutationFunction<
  UpdateServiceCodesMutation,
  UpdateServiceCodesMutationVariables
>;

/**
 * __useUpdateServiceCodesMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCodesMutation, { data, loading, error }] = useUpdateServiceCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceCodesMutation,
    UpdateServiceCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceCodesMutation,
    UpdateServiceCodesMutationVariables
  >(UpdateServiceCodesDocument, options);
}
export type UpdateServiceCodesMutationHookResult = ReturnType<
  typeof useUpdateServiceCodesMutation
>;
export type UpdateServiceCodesMutationResult =
  Apollo.MutationResult<UpdateServiceCodesMutation>;
export type UpdateServiceCodesMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceCodesMutation,
  UpdateServiceCodesMutationVariables
>;
export const ExternalServiceCodesDocument = gql`
  query ExternalServiceCodes($sourceSystem: SourceSystem!) {
    externalServiceCodes(sourceSystem: $sourceSystem) {
      ...ExternalServiceCodeFields
    }
  }
  ${ExternalServiceCodeFieldsFragmentDoc}
`;

/**
 * __useExternalServiceCodesQuery__
 *
 * To run a query within a React component, call `useExternalServiceCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalServiceCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalServiceCodesQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalServiceCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalServiceCodesQuery,
    ExternalServiceCodesQueryVariables
  > &
    (
      | { variables: ExternalServiceCodesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalServiceCodesQuery,
    ExternalServiceCodesQueryVariables
  >(ExternalServiceCodesDocument, options);
}
export function useExternalServiceCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalServiceCodesQuery,
    ExternalServiceCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalServiceCodesQuery,
    ExternalServiceCodesQueryVariables
  >(ExternalServiceCodesDocument, options);
}
export function useExternalServiceCodesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalServiceCodesQuery,
        ExternalServiceCodesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalServiceCodesQuery,
    ExternalServiceCodesQueryVariables
  >(ExternalServiceCodesDocument, options);
}
export type ExternalServiceCodesQueryHookResult = ReturnType<
  typeof useExternalServiceCodesQuery
>;
export type ExternalServiceCodesLazyQueryHookResult = ReturnType<
  typeof useExternalServiceCodesLazyQuery
>;
export type ExternalServiceCodesSuspenseQueryHookResult = ReturnType<
  typeof useExternalServiceCodesSuspenseQuery
>;
export type ExternalServiceCodesQueryResult = Apollo.QueryResult<
  ExternalServiceCodesQuery,
  ExternalServiceCodesQueryVariables
>;
export const ServiceCodesDocument = gql`
  query ServiceCodes {
    viewer {
      id
      org {
        id
        serviceCodes {
          ...ServiceCodeFields
        }
      }
    }
  }
  ${ServiceCodeFieldsFragmentDoc}
`;

/**
 * __useServiceCodesQuery__
 *
 * To run a query within a React component, call `useServiceCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceCodesQuery,
    ServiceCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceCodesQuery, ServiceCodesQueryVariables>(
    ServiceCodesDocument,
    options,
  );
}
export function useServiceCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceCodesQuery,
    ServiceCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceCodesQuery, ServiceCodesQueryVariables>(
    ServiceCodesDocument,
    options,
  );
}
export function useServiceCodesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceCodesQuery,
        ServiceCodesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ServiceCodesQuery, ServiceCodesQueryVariables>(
    ServiceCodesDocument,
    options,
  );
}
export type ServiceCodesQueryHookResult = ReturnType<
  typeof useServiceCodesQuery
>;
export type ServiceCodesLazyQueryHookResult = ReturnType<
  typeof useServiceCodesLazyQuery
>;
export type ServiceCodesSuspenseQueryHookResult = ReturnType<
  typeof useServiceCodesSuspenseQuery
>;
export type ServiceCodesQueryResult = Apollo.QueryResult<
  ServiceCodesQuery,
  ServiceCodesQueryVariables
>;
export const ImportTaxCodesDocument = gql`
  mutation ImportTaxCodes($input: ImportTaxCodesInput!) {
    importTaxCodes(import: $input) {
      created {
        ...TaxCodeFields
      }
      skipped {
        ...TaxCodeFields
      }
      mapped {
        ...TaxCodeFields
      }
    }
  }
  ${TaxCodeFieldsFragmentDoc}
`;
export type ImportTaxCodesMutationFn = Apollo.MutationFunction<
  ImportTaxCodesMutation,
  ImportTaxCodesMutationVariables
>;

/**
 * __useImportTaxCodesMutation__
 *
 * To run a mutation, you first call `useImportTaxCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTaxCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTaxCodesMutation, { data, loading, error }] = useImportTaxCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportTaxCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportTaxCodesMutation,
    ImportTaxCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportTaxCodesMutation,
    ImportTaxCodesMutationVariables
  >(ImportTaxCodesDocument, options);
}
export type ImportTaxCodesMutationHookResult = ReturnType<
  typeof useImportTaxCodesMutation
>;
export type ImportTaxCodesMutationResult =
  Apollo.MutationResult<ImportTaxCodesMutation>;
export type ImportTaxCodesMutationOptions = Apollo.BaseMutationOptions<
  ImportTaxCodesMutation,
  ImportTaxCodesMutationVariables
>;
export const UpdateTaxCodesDocument = gql`
  mutation UpdateTaxCodes($input: UpdateTaxCodesInput!) {
    updateTaxCodes(input: $input) {
      ...TaxCodeFields
    }
  }
  ${TaxCodeFieldsFragmentDoc}
`;
export type UpdateTaxCodesMutationFn = Apollo.MutationFunction<
  UpdateTaxCodesMutation,
  UpdateTaxCodesMutationVariables
>;

/**
 * __useUpdateTaxCodesMutation__
 *
 * To run a mutation, you first call `useUpdateTaxCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxCodesMutation, { data, loading, error }] = useUpdateTaxCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaxCodesMutation,
    UpdateTaxCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaxCodesMutation,
    UpdateTaxCodesMutationVariables
  >(UpdateTaxCodesDocument, options);
}
export type UpdateTaxCodesMutationHookResult = ReturnType<
  typeof useUpdateTaxCodesMutation
>;
export type UpdateTaxCodesMutationResult =
  Apollo.MutationResult<UpdateTaxCodesMutation>;
export type UpdateTaxCodesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaxCodesMutation,
  UpdateTaxCodesMutationVariables
>;
export const ExternalTaxCodesDocument = gql`
  query ExternalTaxCodes($sourceSystem: SourceSystem!) {
    externalTaxCodes(sourceSystem: $sourceSystem) {
      ...ExternalTaxCodeFields
    }
  }
  ${ExternalTaxCodeFieldsFragmentDoc}
`;

/**
 * __useExternalTaxCodesQuery__
 *
 * To run a query within a React component, call `useExternalTaxCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalTaxCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalTaxCodesQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalTaxCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalTaxCodesQuery,
    ExternalTaxCodesQueryVariables
  > &
    (
      | { variables: ExternalTaxCodesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalTaxCodesQuery, ExternalTaxCodesQueryVariables>(
    ExternalTaxCodesDocument,
    options,
  );
}
export function useExternalTaxCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalTaxCodesQuery,
    ExternalTaxCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalTaxCodesQuery,
    ExternalTaxCodesQueryVariables
  >(ExternalTaxCodesDocument, options);
}
export function useExternalTaxCodesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalTaxCodesQuery,
        ExternalTaxCodesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalTaxCodesQuery,
    ExternalTaxCodesQueryVariables
  >(ExternalTaxCodesDocument, options);
}
export type ExternalTaxCodesQueryHookResult = ReturnType<
  typeof useExternalTaxCodesQuery
>;
export type ExternalTaxCodesLazyQueryHookResult = ReturnType<
  typeof useExternalTaxCodesLazyQuery
>;
export type ExternalTaxCodesSuspenseQueryHookResult = ReturnType<
  typeof useExternalTaxCodesSuspenseQuery
>;
export type ExternalTaxCodesQueryResult = Apollo.QueryResult<
  ExternalTaxCodesQuery,
  ExternalTaxCodesQueryVariables
>;
export const ViewerTaxCodesDocument = gql`
  query ViewerTaxCodes {
    viewer {
      id
      org {
        id
        taxCodes {
          ...TaxCodeFields
          externalTaxCode {
            ...ExternalTaxCodeFields
          }
        }
      }
    }
  }
  ${TaxCodeFieldsFragmentDoc}
  ${ExternalTaxCodeFieldsFragmentDoc}
`;

/**
 * __useViewerTaxCodesQuery__
 *
 * To run a query within a React component, call `useViewerTaxCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerTaxCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerTaxCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerTaxCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewerTaxCodesQuery,
    ViewerTaxCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewerTaxCodesQuery, ViewerTaxCodesQueryVariables>(
    ViewerTaxCodesDocument,
    options,
  );
}
export function useViewerTaxCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewerTaxCodesQuery,
    ViewerTaxCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewerTaxCodesQuery, ViewerTaxCodesQueryVariables>(
    ViewerTaxCodesDocument,
    options,
  );
}
export function useViewerTaxCodesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ViewerTaxCodesQuery,
        ViewerTaxCodesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewerTaxCodesQuery,
    ViewerTaxCodesQueryVariables
  >(ViewerTaxCodesDocument, options);
}
export type ViewerTaxCodesQueryHookResult = ReturnType<
  typeof useViewerTaxCodesQuery
>;
export type ViewerTaxCodesLazyQueryHookResult = ReturnType<
  typeof useViewerTaxCodesLazyQuery
>;
export type ViewerTaxCodesSuspenseQueryHookResult = ReturnType<
  typeof useViewerTaxCodesSuspenseQuery
>;
export type ViewerTaxCodesQueryResult = Apollo.QueryResult<
  ViewerTaxCodesQuery,
  ViewerTaxCodesQueryVariables
>;
export const SystemReportDocument = gql`
  query SystemReport($excludedOrgIDs: [String!], $from: String!, $to: String!) {
    systemReport(excludedOrgIDs: $excludedOrgIDs, from: $from, to: $to)
  }
`;

/**
 * __useSystemReportQuery__
 *
 * To run a query within a React component, call `useSystemReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemReportQuery({
 *   variables: {
 *      excludedOrgIDs: // value for 'excludedOrgIDs'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSystemReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    SystemReportQuery,
    SystemReportQueryVariables
  > &
    (
      | { variables: SystemReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemReportQuery, SystemReportQueryVariables>(
    SystemReportDocument,
    options,
  );
}
export function useSystemReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SystemReportQuery,
    SystemReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemReportQuery, SystemReportQueryVariables>(
    SystemReportDocument,
    options,
  );
}
export function useSystemReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SystemReportQuery,
        SystemReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SystemReportQuery, SystemReportQueryVariables>(
    SystemReportDocument,
    options,
  );
}
export type SystemReportQueryHookResult = ReturnType<
  typeof useSystemReportQuery
>;
export type SystemReportLazyQueryHookResult = ReturnType<
  typeof useSystemReportLazyQuery
>;
export type SystemReportSuspenseQueryHookResult = ReturnType<
  typeof useSystemReportSuspenseQuery
>;
export type SystemReportQueryResult = Apollo.QueryResult<
  SystemReportQuery,
  SystemReportQueryVariables
>;
export const PreviewCustomInvoiceTemplatesDocument = gql`
  mutation PreviewCustomInvoiceTemplates(
    $input: PreviewCustomInvoiceTemplateInput!
  ) {
    previewCustomInvoiceTemplates(input: $input)
  }
`;
export type PreviewCustomInvoiceTemplatesMutationFn = Apollo.MutationFunction<
  PreviewCustomInvoiceTemplatesMutation,
  PreviewCustomInvoiceTemplatesMutationVariables
>;

/**
 * __usePreviewCustomInvoiceTemplatesMutation__
 *
 * To run a mutation, you first call `usePreviewCustomInvoiceTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewCustomInvoiceTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewCustomInvoiceTemplatesMutation, { data, loading, error }] = usePreviewCustomInvoiceTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewCustomInvoiceTemplatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewCustomInvoiceTemplatesMutation,
    PreviewCustomInvoiceTemplatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreviewCustomInvoiceTemplatesMutation,
    PreviewCustomInvoiceTemplatesMutationVariables
  >(PreviewCustomInvoiceTemplatesDocument, options);
}
export type PreviewCustomInvoiceTemplatesMutationHookResult = ReturnType<
  typeof usePreviewCustomInvoiceTemplatesMutation
>;
export type PreviewCustomInvoiceTemplatesMutationResult =
  Apollo.MutationResult<PreviewCustomInvoiceTemplatesMutation>;
export type PreviewCustomInvoiceTemplatesMutationOptions =
  Apollo.BaseMutationOptions<
    PreviewCustomInvoiceTemplatesMutation,
    PreviewCustomInvoiceTemplatesMutationVariables
  >;
export const PreviewCustomPoTemplatesDocument = gql`
  mutation PreviewCustomPoTemplates($input: PreviewCustomPoTemplateInput!) {
    previewCustomPoTemplates(input: $input)
  }
`;
export type PreviewCustomPoTemplatesMutationFn = Apollo.MutationFunction<
  PreviewCustomPoTemplatesMutation,
  PreviewCustomPoTemplatesMutationVariables
>;

/**
 * __usePreviewCustomPoTemplatesMutation__
 *
 * To run a mutation, you first call `usePreviewCustomPoTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewCustomPoTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewCustomPoTemplatesMutation, { data, loading, error }] = usePreviewCustomPoTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewCustomPoTemplatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewCustomPoTemplatesMutation,
    PreviewCustomPoTemplatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreviewCustomPoTemplatesMutation,
    PreviewCustomPoTemplatesMutationVariables
  >(PreviewCustomPoTemplatesDocument, options);
}
export type PreviewCustomPoTemplatesMutationHookResult = ReturnType<
  typeof usePreviewCustomPoTemplatesMutation
>;
export type PreviewCustomPoTemplatesMutationResult =
  Apollo.MutationResult<PreviewCustomPoTemplatesMutation>;
export type PreviewCustomPoTemplatesMutationOptions =
  Apollo.BaseMutationOptions<
    PreviewCustomPoTemplatesMutation,
    PreviewCustomPoTemplatesMutationVariables
  >;
export const PreviewInvoiceTemplateDocument = gql`
  mutation PreviewInvoiceTemplate($input: PreviewInvoiceTemplateInput!) {
    previewInvoiceTemplate(input: $input)
  }
`;
export type PreviewInvoiceTemplateMutationFn = Apollo.MutationFunction<
  PreviewInvoiceTemplateMutation,
  PreviewInvoiceTemplateMutationVariables
>;

/**
 * __usePreviewInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `usePreviewInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewInvoiceTemplateMutation, { data, loading, error }] = usePreviewInvoiceTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewInvoiceTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewInvoiceTemplateMutation,
    PreviewInvoiceTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreviewInvoiceTemplateMutation,
    PreviewInvoiceTemplateMutationVariables
  >(PreviewInvoiceTemplateDocument, options);
}
export type PreviewInvoiceTemplateMutationHookResult = ReturnType<
  typeof usePreviewInvoiceTemplateMutation
>;
export type PreviewInvoiceTemplateMutationResult =
  Apollo.MutationResult<PreviewInvoiceTemplateMutation>;
export type PreviewInvoiceTemplateMutationOptions = Apollo.BaseMutationOptions<
  PreviewInvoiceTemplateMutation,
  PreviewInvoiceTemplateMutationVariables
>;
export const PreviewPoTemplateDocument = gql`
  mutation PreviewPoTemplate($input: PreviewPoTemplateInput!) {
    previewPoTemplate(input: $input)
  }
`;
export type PreviewPoTemplateMutationFn = Apollo.MutationFunction<
  PreviewPoTemplateMutation,
  PreviewPoTemplateMutationVariables
>;

/**
 * __usePreviewPoTemplateMutation__
 *
 * To run a mutation, you first call `usePreviewPoTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewPoTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewPoTemplateMutation, { data, loading, error }] = usePreviewPoTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewPoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewPoTemplateMutation,
    PreviewPoTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreviewPoTemplateMutation,
    PreviewPoTemplateMutationVariables
  >(PreviewPoTemplateDocument, options);
}
export type PreviewPoTemplateMutationHookResult = ReturnType<
  typeof usePreviewPoTemplateMutation
>;
export type PreviewPoTemplateMutationResult =
  Apollo.MutationResult<PreviewPoTemplateMutation>;
export type PreviewPoTemplateMutationOptions = Apollo.BaseMutationOptions<
  PreviewPoTemplateMutation,
  PreviewPoTemplateMutationVariables
>;
export const ExternalCostCodesDocument = gql`
  query ExternalCostCodes($sourceSystem: SourceSystem!) {
    externalCostCodes(sourceSystem: $sourceSystem) {
      ...ExternalCostCodeFields
    }
  }
  ${ExternalCostCodeFieldsFragmentDoc}
`;

/**
 * __useExternalCostCodesQuery__
 *
 * To run a query within a React component, call `useExternalCostCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalCostCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalCostCodesQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalCostCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalCostCodesQuery,
    ExternalCostCodesQueryVariables
  > &
    (
      | { variables: ExternalCostCodesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalCostCodesQuery,
    ExternalCostCodesQueryVariables
  >(ExternalCostCodesDocument, options);
}
export function useExternalCostCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalCostCodesQuery,
    ExternalCostCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalCostCodesQuery,
    ExternalCostCodesQueryVariables
  >(ExternalCostCodesDocument, options);
}
export function useExternalCostCodesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalCostCodesQuery,
        ExternalCostCodesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalCostCodesQuery,
    ExternalCostCodesQueryVariables
  >(ExternalCostCodesDocument, options);
}
export type ExternalCostCodesQueryHookResult = ReturnType<
  typeof useExternalCostCodesQuery
>;
export type ExternalCostCodesLazyQueryHookResult = ReturnType<
  typeof useExternalCostCodesLazyQuery
>;
export type ExternalCostCodesSuspenseQueryHookResult = ReturnType<
  typeof useExternalCostCodesSuspenseQuery
>;
export type ExternalCostCodesQueryResult = Apollo.QueryResult<
  ExternalCostCodesQuery,
  ExternalCostCodesQueryVariables
>;
export const ExternalCostTypesDocument = gql`
  query ExternalCostTypes($sourceSystem: SourceSystem!) {
    externalCostTypes(sourceSystem: $sourceSystem) {
      ...ExternalCostTypeFields
    }
  }
  ${ExternalCostTypeFieldsFragmentDoc}
`;

/**
 * __useExternalCostTypesQuery__
 *
 * To run a query within a React component, call `useExternalCostTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalCostTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalCostTypesQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalCostTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalCostTypesQuery,
    ExternalCostTypesQueryVariables
  > &
    (
      | { variables: ExternalCostTypesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalCostTypesQuery,
    ExternalCostTypesQueryVariables
  >(ExternalCostTypesDocument, options);
}
export function useExternalCostTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalCostTypesQuery,
    ExternalCostTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalCostTypesQuery,
    ExternalCostTypesQueryVariables
  >(ExternalCostTypesDocument, options);
}
export function useExternalCostTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalCostTypesQuery,
        ExternalCostTypesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalCostTypesQuery,
    ExternalCostTypesQueryVariables
  >(ExternalCostTypesDocument, options);
}
export type ExternalCostTypesQueryHookResult = ReturnType<
  typeof useExternalCostTypesQuery
>;
export type ExternalCostTypesLazyQueryHookResult = ReturnType<
  typeof useExternalCostTypesLazyQuery
>;
export type ExternalCostTypesSuspenseQueryHookResult = ReturnType<
  typeof useExternalCostTypesSuspenseQuery
>;
export type ExternalCostTypesQueryResult = Apollo.QueryResult<
  ExternalCostTypesQuery,
  ExternalCostTypesQueryVariables
>;
export const ExternalLedgerAccountsDocument = gql`
  query ExternalLedgerAccounts($sourceSystem: SourceSystem!) {
    externalLedgerAccounts(sourceSystem: $sourceSystem) {
      ...ExternalLedgerAccountFields
    }
  }
  ${ExternalLedgerAccountFieldsFragmentDoc}
`;

/**
 * __useExternalLedgerAccountsQuery__
 *
 * To run a query within a React component, call `useExternalLedgerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalLedgerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalLedgerAccountsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalLedgerAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalLedgerAccountsQuery,
    ExternalLedgerAccountsQueryVariables
  > &
    (
      | { variables: ExternalLedgerAccountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalLedgerAccountsQuery,
    ExternalLedgerAccountsQueryVariables
  >(ExternalLedgerAccountsDocument, options);
}
export function useExternalLedgerAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalLedgerAccountsQuery,
    ExternalLedgerAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalLedgerAccountsQuery,
    ExternalLedgerAccountsQueryVariables
  >(ExternalLedgerAccountsDocument, options);
}
export function useExternalLedgerAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalLedgerAccountsQuery,
        ExternalLedgerAccountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalLedgerAccountsQuery,
    ExternalLedgerAccountsQueryVariables
  >(ExternalLedgerAccountsDocument, options);
}
export type ExternalLedgerAccountsQueryHookResult = ReturnType<
  typeof useExternalLedgerAccountsQuery
>;
export type ExternalLedgerAccountsLazyQueryHookResult = ReturnType<
  typeof useExternalLedgerAccountsLazyQuery
>;
export type ExternalLedgerAccountsSuspenseQueryHookResult = ReturnType<
  typeof useExternalLedgerAccountsSuspenseQuery
>;
export type ExternalLedgerAccountsQueryResult = Apollo.QueryResult<
  ExternalLedgerAccountsQuery,
  ExternalLedgerAccountsQueryVariables
>;
export const ExternalVendorsDocument = gql`
  query ExternalVendors(
    $sourceSystem: SourceSystem!
    $filter: QueryExternalVendorsFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    externalVendors(
      sourceSystem: $sourceSystem
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ExternalVendorFields
        }
      }
    }
  }
  ${ExternalVendorFieldsFragmentDoc}
`;

/**
 * __useExternalVendorsQuery__
 *
 * To run a query within a React component, call `useExternalVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalVendorsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useExternalVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalVendorsQuery,
    ExternalVendorsQueryVariables
  > &
    (
      | { variables: ExternalVendorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalVendorsQuery, ExternalVendorsQueryVariables>(
    ExternalVendorsDocument,
    options,
  );
}
export function useExternalVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalVendorsQuery,
    ExternalVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalVendorsQuery,
    ExternalVendorsQueryVariables
  >(ExternalVendorsDocument, options);
}
export function useExternalVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalVendorsQuery,
        ExternalVendorsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalVendorsQuery,
    ExternalVendorsQueryVariables
  >(ExternalVendorsDocument, options);
}
export type ExternalVendorsQueryHookResult = ReturnType<
  typeof useExternalVendorsQuery
>;
export type ExternalVendorsLazyQueryHookResult = ReturnType<
  typeof useExternalVendorsLazyQuery
>;
export type ExternalVendorsSuspenseQueryHookResult = ReturnType<
  typeof useExternalVendorsSuspenseQuery
>;
export type ExternalVendorsQueryResult = Apollo.QueryResult<
  ExternalVendorsQuery,
  ExternalVendorsQueryVariables
>;
export const InventorySummaryDocument = gql`
  query InventorySummary {
    viewer {
      id
      org {
        id
        inventoryItems {
          ...InventoryItemStrippedFields
        }
      }
    }
  }
  ${InventoryItemStrippedFieldsFragmentDoc}
`;

/**
 * __useInventorySummaryQuery__
 *
 * To run a query within a React component, call `useInventorySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventorySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventorySummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInventorySummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InventorySummaryQuery,
    InventorySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventorySummaryQuery, InventorySummaryQueryVariables>(
    InventorySummaryDocument,
    options,
  );
}
export function useInventorySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventorySummaryQuery,
    InventorySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InventorySummaryQuery,
    InventorySummaryQueryVariables
  >(InventorySummaryDocument, options);
}
export function useInventorySummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InventorySummaryQuery,
        InventorySummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InventorySummaryQuery,
    InventorySummaryQueryVariables
  >(InventorySummaryDocument, options);
}
export type InventorySummaryQueryHookResult = ReturnType<
  typeof useInventorySummaryQuery
>;
export type InventorySummaryLazyQueryHookResult = ReturnType<
  typeof useInventorySummaryLazyQuery
>;
export type InventorySummarySuspenseQueryHookResult = ReturnType<
  typeof useInventorySummarySuspenseQuery
>;
export type InventorySummaryQueryResult = Apollo.QueryResult<
  InventorySummaryQuery,
  InventorySummaryQueryVariables
>;
export const CreateLocationDocument = gql`
  mutation CreateLocation($input: AddOrgLocationInput!) {
    addNewLocation(input: $input) {
      ...OrgLocationFields
    }
  }
  ${OrgLocationFieldsFragmentDoc}
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >(CreateLocationDocument, options);
}
export type CreateLocationMutationHookResult = ReturnType<
  typeof useCreateLocationMutation
>;
export type CreateLocationMutationResult =
  Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const DeleteLocationDocument = gql`
  mutation DeleteLocation($input: RemoveOrgLocationInput!) {
    removeLocation(input: $input)
  }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >(DeleteLocationDocument, options);
}
export type DeleteLocationMutationHookResult = ReturnType<
  typeof useDeleteLocationMutation
>;
export type DeleteLocationMutationResult =
  Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
export const UpdateLocationDocument = gql`
  mutation UpdateLocation($input: UpdateOrgLocationInput!) {
    updateLocation(input: $input) {
      id
    }
  }
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UpdateLocationDocument, options);
}
export type UpdateLocationMutationHookResult = ReturnType<
  typeof useUpdateLocationMutation
>;
export type UpdateLocationMutationResult =
  Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const LocationsDocument = gql`
  query Locations($first: Int, $last: Int, $after: String, $before: String) {
    locations(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...OrgLocationExtendedFields
        }
      }
    }
  }
  ${OrgLocationExtendedFieldsFragmentDoc}
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export function useLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LocationsQuery, LocationsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<
  typeof useLocationsLazyQuery
>;
export type LocationsSuspenseQueryHookResult = ReturnType<
  typeof useLocationsSuspenseQuery
>;
export type LocationsQueryResult = Apollo.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>;
export const ImportComplianceGroupsDocument = gql`
  mutation ImportComplianceGroups($input: ImportComplianceGroupsInput!) {
    importComplianceGroups(input: $input) {
      created {
        ...ComplianceGroupFields
      }
      mapped {
        ...ComplianceGroupFields
      }
      skipped {
        ...ComplianceGroupFields
      }
    }
  }
  ${ComplianceGroupFieldsFragmentDoc}
`;
export type ImportComplianceGroupsMutationFn = Apollo.MutationFunction<
  ImportComplianceGroupsMutation,
  ImportComplianceGroupsMutationVariables
>;

/**
 * __useImportComplianceGroupsMutation__
 *
 * To run a mutation, you first call `useImportComplianceGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportComplianceGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importComplianceGroupsMutation, { data, loading, error }] = useImportComplianceGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportComplianceGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportComplianceGroupsMutation,
    ImportComplianceGroupsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportComplianceGroupsMutation,
    ImportComplianceGroupsMutationVariables
  >(ImportComplianceGroupsDocument, options);
}
export type ImportComplianceGroupsMutationHookResult = ReturnType<
  typeof useImportComplianceGroupsMutation
>;
export type ImportComplianceGroupsMutationResult =
  Apollo.MutationResult<ImportComplianceGroupsMutation>;
export type ImportComplianceGroupsMutationOptions = Apollo.BaseMutationOptions<
  ImportComplianceGroupsMutation,
  ImportComplianceGroupsMutationVariables
>;
export const UpdateComplianceGroupsDocument = gql`
  mutation UpdateComplianceGroups($input: UpdateComplianceGroupsInput!) {
    updateComplianceGroups(input: $input) {
      ...ComplianceGroupFields
    }
  }
  ${ComplianceGroupFieldsFragmentDoc}
`;
export type UpdateComplianceGroupsMutationFn = Apollo.MutationFunction<
  UpdateComplianceGroupsMutation,
  UpdateComplianceGroupsMutationVariables
>;

/**
 * __useUpdateComplianceGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateComplianceGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComplianceGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComplianceGroupsMutation, { data, loading, error }] = useUpdateComplianceGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateComplianceGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateComplianceGroupsMutation,
    UpdateComplianceGroupsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateComplianceGroupsMutation,
    UpdateComplianceGroupsMutationVariables
  >(UpdateComplianceGroupsDocument, options);
}
export type UpdateComplianceGroupsMutationHookResult = ReturnType<
  typeof useUpdateComplianceGroupsMutation
>;
export type UpdateComplianceGroupsMutationResult =
  Apollo.MutationResult<UpdateComplianceGroupsMutation>;
export type UpdateComplianceGroupsMutationOptions = Apollo.BaseMutationOptions<
  UpdateComplianceGroupsMutation,
  UpdateComplianceGroupsMutationVariables
>;
export const ComplianceGroupsDocument = gql`
  query ComplianceGroups($sourceSystem: SourceSystem!) {
    externalComplianceGroups(sourceSystem: $sourceSystem) {
      ...ExternalComplianceGroupFields
    }
  }
  ${ExternalComplianceGroupFieldsFragmentDoc}
`;

/**
 * __useComplianceGroupsQuery__
 *
 * To run a query within a React component, call `useComplianceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceGroupsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useComplianceGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceGroupsQuery,
    ComplianceGroupsQueryVariables
  > &
    (
      | { variables: ComplianceGroupsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplianceGroupsQuery, ComplianceGroupsQueryVariables>(
    ComplianceGroupsDocument,
    options,
  );
}
export function useComplianceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceGroupsQuery,
    ComplianceGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplianceGroupsQuery,
    ComplianceGroupsQueryVariables
  >(ComplianceGroupsDocument, options);
}
export function useComplianceGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ComplianceGroupsQuery,
        ComplianceGroupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ComplianceGroupsQuery,
    ComplianceGroupsQueryVariables
  >(ComplianceGroupsDocument, options);
}
export type ComplianceGroupsQueryHookResult = ReturnType<
  typeof useComplianceGroupsQuery
>;
export type ComplianceGroupsLazyQueryHookResult = ReturnType<
  typeof useComplianceGroupsLazyQuery
>;
export type ComplianceGroupsSuspenseQueryHookResult = ReturnType<
  typeof useComplianceGroupsSuspenseQuery
>;
export type ComplianceGroupsQueryResult = Apollo.QueryResult<
  ComplianceGroupsQuery,
  ComplianceGroupsQueryVariables
>;
export const ArchiveOrderTypeDocument = gql`
  mutation ArchiveOrderType($id: ID!) {
    archiveReleaseType(id: $id) {
      ...OrderTypeFields
    }
  }
  ${OrderTypeFieldsFragmentDoc}
`;
export type ArchiveOrderTypeMutationFn = Apollo.MutationFunction<
  ArchiveOrderTypeMutation,
  ArchiveOrderTypeMutationVariables
>;

/**
 * __useArchiveOrderTypeMutation__
 *
 * To run a mutation, you first call `useArchiveOrderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrderTypeMutation, { data, loading, error }] = useArchiveOrderTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveOrderTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveOrderTypeMutation,
    ArchiveOrderTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveOrderTypeMutation,
    ArchiveOrderTypeMutationVariables
  >(ArchiveOrderTypeDocument, options);
}
export type ArchiveOrderTypeMutationHookResult = ReturnType<
  typeof useArchiveOrderTypeMutation
>;
export type ArchiveOrderTypeMutationResult =
  Apollo.MutationResult<ArchiveOrderTypeMutation>;
export type ArchiveOrderTypeMutationOptions = Apollo.BaseMutationOptions<
  ArchiveOrderTypeMutation,
  ArchiveOrderTypeMutationVariables
>;
export const CreateOrderTypeDocument = gql`
  mutation CreateOrderType($input: CreateReleaseTypeInput!) {
    createReleaseType(input: $input) {
      ...OrderTypeFields
    }
  }
  ${OrderTypeFieldsFragmentDoc}
`;
export type CreateOrderTypeMutationFn = Apollo.MutationFunction<
  CreateOrderTypeMutation,
  CreateOrderTypeMutationVariables
>;

/**
 * __useCreateOrderTypeMutation__
 *
 * To run a mutation, you first call `useCreateOrderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderTypeMutation, { data, loading, error }] = useCreateOrderTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderTypeMutation,
    CreateOrderTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrderTypeMutation,
    CreateOrderTypeMutationVariables
  >(CreateOrderTypeDocument, options);
}
export type CreateOrderTypeMutationHookResult = ReturnType<
  typeof useCreateOrderTypeMutation
>;
export type CreateOrderTypeMutationResult =
  Apollo.MutationResult<CreateOrderTypeMutation>;
export type CreateOrderTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderTypeMutation,
  CreateOrderTypeMutationVariables
>;
export const UnarchiveOrderTypeDocument = gql`
  mutation UnarchiveOrderType($id: ID!) {
    unarchiveReleaseType(id: $id) {
      ...OrderTypeFields
    }
  }
  ${OrderTypeFieldsFragmentDoc}
`;
export type UnarchiveOrderTypeMutationFn = Apollo.MutationFunction<
  UnarchiveOrderTypeMutation,
  UnarchiveOrderTypeMutationVariables
>;

/**
 * __useUnarchiveOrderTypeMutation__
 *
 * To run a mutation, you first call `useUnarchiveOrderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveOrderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveOrderTypeMutation, { data, loading, error }] = useUnarchiveOrderTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveOrderTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveOrderTypeMutation,
    UnarchiveOrderTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveOrderTypeMutation,
    UnarchiveOrderTypeMutationVariables
  >(UnarchiveOrderTypeDocument, options);
}
export type UnarchiveOrderTypeMutationHookResult = ReturnType<
  typeof useUnarchiveOrderTypeMutation
>;
export type UnarchiveOrderTypeMutationResult =
  Apollo.MutationResult<UnarchiveOrderTypeMutation>;
export type UnarchiveOrderTypeMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveOrderTypeMutation,
  UnarchiveOrderTypeMutationVariables
>;
export const UpdateOrderTypeDocument = gql`
  mutation updateOrderType($input: UpdateReleaseTypeInput!) {
    updateReleaseType(input: $input) {
      ...OrderTypeFields
    }
  }
  ${OrderTypeFieldsFragmentDoc}
`;
export type UpdateOrderTypeMutationFn = Apollo.MutationFunction<
  UpdateOrderTypeMutation,
  UpdateOrderTypeMutationVariables
>;

/**
 * __useUpdateOrderTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOrderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderTypeMutation, { data, loading, error }] = useUpdateOrderTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderTypeMutation,
    UpdateOrderTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderTypeMutation,
    UpdateOrderTypeMutationVariables
  >(UpdateOrderTypeDocument, options);
}
export type UpdateOrderTypeMutationHookResult = ReturnType<
  typeof useUpdateOrderTypeMutation
>;
export type UpdateOrderTypeMutationResult =
  Apollo.MutationResult<UpdateOrderTypeMutation>;
export type UpdateOrderTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderTypeMutation,
  UpdateOrderTypeMutationVariables
>;
export const ExternalPoTypesDocument = gql`
  query ExternalPOTypes($sourceSystem: SourceSystem!) {
    externalPOTypes(sourceSystem: $sourceSystem) {
      ...ExternalPOTypeFields
    }
  }
  ${ExternalPoTypeFieldsFragmentDoc}
`;

/**
 * __useExternalPoTypesQuery__
 *
 * To run a query within a React component, call `useExternalPoTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPoTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPoTypesQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalPoTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalPoTypesQuery,
    ExternalPoTypesQueryVariables
  > &
    (
      | { variables: ExternalPoTypesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalPoTypesQuery, ExternalPoTypesQueryVariables>(
    ExternalPoTypesDocument,
    options,
  );
}
export function useExternalPoTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalPoTypesQuery,
    ExternalPoTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalPoTypesQuery,
    ExternalPoTypesQueryVariables
  >(ExternalPoTypesDocument, options);
}
export function useExternalPoTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalPoTypesQuery,
        ExternalPoTypesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalPoTypesQuery,
    ExternalPoTypesQueryVariables
  >(ExternalPoTypesDocument, options);
}
export type ExternalPoTypesQueryHookResult = ReturnType<
  typeof useExternalPoTypesQuery
>;
export type ExternalPoTypesLazyQueryHookResult = ReturnType<
  typeof useExternalPoTypesLazyQuery
>;
export type ExternalPoTypesSuspenseQueryHookResult = ReturnType<
  typeof useExternalPoTypesSuspenseQuery
>;
export type ExternalPoTypesQueryResult = Apollo.QueryResult<
  ExternalPoTypesQuery,
  ExternalPoTypesQueryVariables
>;
export const OrderTypesDocument = gql`
  query OrderTypes {
    viewer {
      id
      org {
        id
        releaseTypes {
          ...OrderTypeFields
        }
      }
    }
  }
  ${OrderTypeFieldsFragmentDoc}
`;

/**
 * __useOrderTypesQuery__
 *
 * To run a query within a React component, call `useOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrderTypesQuery,
    OrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderTypesQuery, OrderTypesQueryVariables>(
    OrderTypesDocument,
    options,
  );
}
export function useOrderTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderTypesQuery,
    OrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderTypesQuery, OrderTypesQueryVariables>(
    OrderTypesDocument,
    options,
  );
}
export function useOrderTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderTypesQuery,
        OrderTypesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrderTypesQuery, OrderTypesQueryVariables>(
    OrderTypesDocument,
    options,
  );
}
export type OrderTypesQueryHookResult = ReturnType<typeof useOrderTypesQuery>;
export type OrderTypesLazyQueryHookResult = ReturnType<
  typeof useOrderTypesLazyQuery
>;
export type OrderTypesSuspenseQueryHookResult = ReturnType<
  typeof useOrderTypesSuspenseQuery
>;
export type OrderTypesQueryResult = Apollo.QueryResult<
  OrderTypesQuery,
  OrderTypesQueryVariables
>;
export const AddVendorPriceDocument = gql`
  mutation addVendorPrice($input: AddVendorPriceInput!) {
    addVendorPrice(input: $input) {
      ...VendorPriceFields
    }
  }
  ${VendorPriceFieldsFragmentDoc}
`;
export type AddVendorPriceMutationFn = Apollo.MutationFunction<
  AddVendorPriceMutation,
  AddVendorPriceMutationVariables
>;

/**
 * __useAddVendorPriceMutation__
 *
 * To run a mutation, you first call `useAddVendorPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorPriceMutation, { data, loading, error }] = useAddVendorPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVendorPriceMutation,
    AddVendorPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddVendorPriceMutation,
    AddVendorPriceMutationVariables
  >(AddVendorPriceDocument, options);
}
export type AddVendorPriceMutationHookResult = ReturnType<
  typeof useAddVendorPriceMutation
>;
export type AddVendorPriceMutationResult =
  Apollo.MutationResult<AddVendorPriceMutation>;
export type AddVendorPriceMutationOptions = Apollo.BaseMutationOptions<
  AddVendorPriceMutation,
  AddVendorPriceMutationVariables
>;
export const SetVendorPricesDocument = gql`
  mutation SetVendorPrices($input: SetVendorPricesInput!) {
    setVendorPrices(input: $input) {
      ...VendorPriceFields
    }
  }
  ${VendorPriceFieldsFragmentDoc}
`;
export type SetVendorPricesMutationFn = Apollo.MutationFunction<
  SetVendorPricesMutation,
  SetVendorPricesMutationVariables
>;

/**
 * __useSetVendorPricesMutation__
 *
 * To run a mutation, you first call `useSetVendorPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVendorPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVendorPricesMutation, { data, loading, error }] = useSetVendorPricesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetVendorPricesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVendorPricesMutation,
    SetVendorPricesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVendorPricesMutation,
    SetVendorPricesMutationVariables
  >(SetVendorPricesDocument, options);
}
export type SetVendorPricesMutationHookResult = ReturnType<
  typeof useSetVendorPricesMutation
>;
export type SetVendorPricesMutationResult =
  Apollo.MutationResult<SetVendorPricesMutation>;
export type SetVendorPricesMutationOptions = Apollo.BaseMutationOptions<
  SetVendorPricesMutation,
  SetVendorPricesMutationVariables
>;
export const UpdateVendorPriceDocument = gql`
  mutation UpdateVendorPrice($input: UpdateVendorPriceInput!) {
    updateVendorPrice(input: $input) {
      ...VendorPriceFields
    }
  }
  ${VendorPriceFieldsFragmentDoc}
`;
export type UpdateVendorPriceMutationFn = Apollo.MutationFunction<
  UpdateVendorPriceMutation,
  UpdateVendorPriceMutationVariables
>;

/**
 * __useUpdateVendorPriceMutation__
 *
 * To run a mutation, you first call `useUpdateVendorPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorPriceMutation, { data, loading, error }] = useUpdateVendorPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorPriceMutation,
    UpdateVendorPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorPriceMutation,
    UpdateVendorPriceMutationVariables
  >(UpdateVendorPriceDocument, options);
}
export type UpdateVendorPriceMutationHookResult = ReturnType<
  typeof useUpdateVendorPriceMutation
>;
export type UpdateVendorPriceMutationResult =
  Apollo.MutationResult<UpdateVendorPriceMutation>;
export type UpdateVendorPriceMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorPriceMutation,
  UpdateVendorPriceMutationVariables
>;
export const VendorPricesDocument = gql`
  query VendorPrices {
    viewer {
      id
      org {
        id
        vendorPrices {
          ...VendorPriceFields
        }
      }
    }
  }
  ${VendorPriceFieldsFragmentDoc}
`;

/**
 * __useVendorPricesQuery__
 *
 * To run a query within a React component, call `useVendorPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorPricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorPricesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VendorPricesQuery,
    VendorPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VendorPricesQuery, VendorPricesQueryVariables>(
    VendorPricesDocument,
    options,
  );
}
export function useVendorPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorPricesQuery,
    VendorPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VendorPricesQuery, VendorPricesQueryVariables>(
    VendorPricesDocument,
    options,
  );
}
export function useVendorPricesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VendorPricesQuery,
        VendorPricesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VendorPricesQuery, VendorPricesQueryVariables>(
    VendorPricesDocument,
    options,
  );
}
export type VendorPricesQueryHookResult = ReturnType<
  typeof useVendorPricesQuery
>;
export type VendorPricesLazyQueryHookResult = ReturnType<
  typeof useVendorPricesLazyQuery
>;
export type VendorPricesSuspenseQueryHookResult = ReturnType<
  typeof useVendorPricesSuspenseQuery
>;
export type VendorPricesQueryResult = Apollo.QueryResult<
  VendorPricesQuery,
  VendorPricesQueryVariables
>;
export const ConnectOrgMaterialDocument = gql`
  mutation ConnectOrgMaterial($input: ConnectNodeInput!) {
    connectOrgMaterial(input: $input) {
      id
    }
  }
`;
export type ConnectOrgMaterialMutationFn = Apollo.MutationFunction<
  ConnectOrgMaterialMutation,
  ConnectOrgMaterialMutationVariables
>;

/**
 * __useConnectOrgMaterialMutation__
 *
 * To run a mutation, you first call `useConnectOrgMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectOrgMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectOrgMaterialMutation, { data, loading, error }] = useConnectOrgMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectOrgMaterialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectOrgMaterialMutation,
    ConnectOrgMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectOrgMaterialMutation,
    ConnectOrgMaterialMutationVariables
  >(ConnectOrgMaterialDocument, options);
}
export type ConnectOrgMaterialMutationHookResult = ReturnType<
  typeof useConnectOrgMaterialMutation
>;
export type ConnectOrgMaterialMutationResult =
  Apollo.MutationResult<ConnectOrgMaterialMutation>;
export type ConnectOrgMaterialMutationOptions = Apollo.BaseMutationOptions<
  ConnectOrgMaterialMutation,
  ConnectOrgMaterialMutationVariables
>;
export const DisconnectOrgMaterialDocument = gql`
  mutation DisconnectOrgMaterial($input: DisconnectNodeInput!) {
    disconnectOrgMaterial(input: $input) {
      id
    }
  }
`;
export type DisconnectOrgMaterialMutationFn = Apollo.MutationFunction<
  DisconnectOrgMaterialMutation,
  DisconnectOrgMaterialMutationVariables
>;

/**
 * __useDisconnectOrgMaterialMutation__
 *
 * To run a mutation, you first call `useDisconnectOrgMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectOrgMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectOrgMaterialMutation, { data, loading, error }] = useDisconnectOrgMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectOrgMaterialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectOrgMaterialMutation,
    DisconnectOrgMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectOrgMaterialMutation,
    DisconnectOrgMaterialMutationVariables
  >(DisconnectOrgMaterialDocument, options);
}
export type DisconnectOrgMaterialMutationHookResult = ReturnType<
  typeof useDisconnectOrgMaterialMutation
>;
export type DisconnectOrgMaterialMutationResult =
  Apollo.MutationResult<DisconnectOrgMaterialMutation>;
export type DisconnectOrgMaterialMutationOptions = Apollo.BaseMutationOptions<
  DisconnectOrgMaterialMutation,
  DisconnectOrgMaterialMutationVariables
>;
export const ImportOrgMaterialsDocument = gql`
  mutation ImportOrgMaterials($input: ImportOrgMaterialsInput!) {
    importOrgMaterials(input: $input) {
      created {
        ...OrgMaterialFields
      }
      mapped {
        ...OrgMaterialFields
      }
      skipped {
        ...OrgMaterialFields
      }
    }
  }
  ${OrgMaterialFieldsFragmentDoc}
`;
export type ImportOrgMaterialsMutationFn = Apollo.MutationFunction<
  ImportOrgMaterialsMutation,
  ImportOrgMaterialsMutationVariables
>;

/**
 * __useImportOrgMaterialsMutation__
 *
 * To run a mutation, you first call `useImportOrgMaterialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportOrgMaterialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importOrgMaterialsMutation, { data, loading, error }] = useImportOrgMaterialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportOrgMaterialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportOrgMaterialsMutation,
    ImportOrgMaterialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportOrgMaterialsMutation,
    ImportOrgMaterialsMutationVariables
  >(ImportOrgMaterialsDocument, options);
}
export type ImportOrgMaterialsMutationHookResult = ReturnType<
  typeof useImportOrgMaterialsMutation
>;
export type ImportOrgMaterialsMutationResult =
  Apollo.MutationResult<ImportOrgMaterialsMutation>;
export type ImportOrgMaterialsMutationOptions = Apollo.BaseMutationOptions<
  ImportOrgMaterialsMutation,
  ImportOrgMaterialsMutationVariables
>;
export const RemoveOrgMaterialDocument = gql`
  mutation RemoveOrgMaterial($id: ID!) {
    archiveOrgMaterial(id: $id) {
      id
    }
  }
`;
export type RemoveOrgMaterialMutationFn = Apollo.MutationFunction<
  RemoveOrgMaterialMutation,
  RemoveOrgMaterialMutationVariables
>;

/**
 * __useRemoveOrgMaterialMutation__
 *
 * To run a mutation, you first call `useRemoveOrgMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgMaterialMutation, { data, loading, error }] = useRemoveOrgMaterialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrgMaterialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrgMaterialMutation,
    RemoveOrgMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOrgMaterialMutation,
    RemoveOrgMaterialMutationVariables
  >(RemoveOrgMaterialDocument, options);
}
export type RemoveOrgMaterialMutationHookResult = ReturnType<
  typeof useRemoveOrgMaterialMutation
>;
export type RemoveOrgMaterialMutationResult =
  Apollo.MutationResult<RemoveOrgMaterialMutation>;
export type RemoveOrgMaterialMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrgMaterialMutation,
  RemoveOrgMaterialMutationVariables
>;
export const ExternalItemsDocument = gql`
  query ExternalItems(
    $sourceSystem: SourceSystem!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryExternalItemsFilter
  ) {
    externalItems(
      sourceSystem: $sourceSystem
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ExternalItemFields
        }
      }
    }
  }
  ${ExternalItemFieldsFragmentDoc}
`;

/**
 * __useExternalItemsQuery__
 *
 * To run a query within a React component, call `useExternalItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalItemsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExternalItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalItemsQuery,
    ExternalItemsQueryVariables
  > &
    (
      | { variables: ExternalItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalItemsQuery, ExternalItemsQueryVariables>(
    ExternalItemsDocument,
    options,
  );
}
export function useExternalItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalItemsQuery,
    ExternalItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalItemsQuery, ExternalItemsQueryVariables>(
    ExternalItemsDocument,
    options,
  );
}
export function useExternalItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalItemsQuery,
        ExternalItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalItemsQuery,
    ExternalItemsQueryVariables
  >(ExternalItemsDocument, options);
}
export type ExternalItemsQueryHookResult = ReturnType<
  typeof useExternalItemsQuery
>;
export type ExternalItemsLazyQueryHookResult = ReturnType<
  typeof useExternalItemsLazyQuery
>;
export type ExternalItemsSuspenseQueryHookResult = ReturnType<
  typeof useExternalItemsSuspenseQuery
>;
export type ExternalItemsQueryResult = Apollo.QueryResult<
  ExternalItemsQuery,
  ExternalItemsQueryVariables
>;
export const OrgMaterialDocument = gql`
  query OrgMaterial($id: ID!) {
    orgMaterial(id: $id) {
      ...OrgMaterialFields
    }
  }
  ${OrgMaterialFieldsFragmentDoc}
`;

/**
 * __useOrgMaterialQuery__
 *
 * To run a query within a React component, call `useOrgMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMaterialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgMaterialQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgMaterialQuery,
    OrgMaterialQueryVariables
  > &
    (
      | { variables: OrgMaterialQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgMaterialQuery, OrgMaterialQueryVariables>(
    OrgMaterialDocument,
    options,
  );
}
export function useOrgMaterialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgMaterialQuery,
    OrgMaterialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgMaterialQuery, OrgMaterialQueryVariables>(
    OrgMaterialDocument,
    options,
  );
}
export function useOrgMaterialSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgMaterialQuery,
        OrgMaterialQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrgMaterialQuery, OrgMaterialQueryVariables>(
    OrgMaterialDocument,
    options,
  );
}
export type OrgMaterialQueryHookResult = ReturnType<typeof useOrgMaterialQuery>;
export type OrgMaterialLazyQueryHookResult = ReturnType<
  typeof useOrgMaterialLazyQuery
>;
export type OrgMaterialSuspenseQueryHookResult = ReturnType<
  typeof useOrgMaterialSuspenseQuery
>;
export type OrgMaterialQueryResult = Apollo.QueryResult<
  OrgMaterialQuery,
  OrgMaterialQueryVariables
>;
export const OrgMaterialsDocument = gql`
  query OrgMaterials(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryOrgMaterialsFilter
  ) {
    orgMaterials(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...OrgMaterialFields
        }
      }
    }
  }
  ${OrgMaterialFieldsFragmentDoc}
`;

/**
 * __useOrgMaterialsQuery__
 *
 * To run a query within a React component, call `useOrgMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMaterialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrgMaterialsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgMaterialsQuery,
    OrgMaterialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgMaterialsQuery, OrgMaterialsQueryVariables>(
    OrgMaterialsDocument,
    options,
  );
}
export function useOrgMaterialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgMaterialsQuery,
    OrgMaterialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgMaterialsQuery, OrgMaterialsQueryVariables>(
    OrgMaterialsDocument,
    options,
  );
}
export function useOrgMaterialsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgMaterialsQuery,
        OrgMaterialsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrgMaterialsQuery, OrgMaterialsQueryVariables>(
    OrgMaterialsDocument,
    options,
  );
}
export type OrgMaterialsQueryHookResult = ReturnType<
  typeof useOrgMaterialsQuery
>;
export type OrgMaterialsLazyQueryHookResult = ReturnType<
  typeof useOrgMaterialsLazyQuery
>;
export type OrgMaterialsSuspenseQueryHookResult = ReturnType<
  typeof useOrgMaterialsSuspenseQuery
>;
export type OrgMaterialsQueryResult = Apollo.QueryResult<
  OrgMaterialsQuery,
  OrgMaterialsQueryVariables
>;
export const OrgMaterialsSummaryDocument = gql`
  query OrgMaterialsSummary {
    viewer {
      id
      org {
        id
        materials {
          ...PartialOrgMaterialFields
        }
      }
    }
  }
  ${PartialOrgMaterialFieldsFragmentDoc}
`;

/**
 * __useOrgMaterialsSummaryQuery__
 *
 * To run a query within a React component, call `useOrgMaterialsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMaterialsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMaterialsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgMaterialsSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgMaterialsSummaryQuery,
    OrgMaterialsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgMaterialsSummaryQuery,
    OrgMaterialsSummaryQueryVariables
  >(OrgMaterialsSummaryDocument, options);
}
export function useOrgMaterialsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgMaterialsSummaryQuery,
    OrgMaterialsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgMaterialsSummaryQuery,
    OrgMaterialsSummaryQueryVariables
  >(OrgMaterialsSummaryDocument, options);
}
export function useOrgMaterialsSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgMaterialsSummaryQuery,
        OrgMaterialsSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrgMaterialsSummaryQuery,
    OrgMaterialsSummaryQueryVariables
  >(OrgMaterialsSummaryDocument, options);
}
export type OrgMaterialsSummaryQueryHookResult = ReturnType<
  typeof useOrgMaterialsSummaryQuery
>;
export type OrgMaterialsSummaryLazyQueryHookResult = ReturnType<
  typeof useOrgMaterialsSummaryLazyQuery
>;
export type OrgMaterialsSummarySuspenseQueryHookResult = ReturnType<
  typeof useOrgMaterialsSummarySuspenseQuery
>;
export type OrgMaterialsSummaryQueryResult = Apollo.QueryResult<
  OrgMaterialsSummaryQuery,
  OrgMaterialsSummaryQueryVariables
>;
export const UpdateOrgSettingsDocument = gql`
  mutation UpdateOrgSettings($input: UpdateOrgSettingsInput!) {
    updateOrgSettings(input: $input) {
      ...OrgSettingsFields
    }
  }
  ${OrgSettingsFieldsFragmentDoc}
`;
export type UpdateOrgSettingsMutationFn = Apollo.MutationFunction<
  UpdateOrgSettingsMutation,
  UpdateOrgSettingsMutationVariables
>;

/**
 * __useUpdateOrgSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgSettingsMutation, { data, loading, error }] = useUpdateOrgSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables
  >(UpdateOrgSettingsDocument, options);
}
export type UpdateOrgSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrgSettingsMutation
>;
export type UpdateOrgSettingsMutationResult =
  Apollo.MutationResult<UpdateOrgSettingsMutation>;
export type UpdateOrgSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgSettingsMutation,
  UpdateOrgSettingsMutationVariables
>;
export const OrgSettingsExtendedDocument = gql`
  query OrgSettingsExtended {
    viewer {
      id
      org {
        id
        settings {
          ...OrgSettingsExtendedFields
        }
      }
    }
  }
  ${OrgSettingsExtendedFieldsFragmentDoc}
`;

/**
 * __useOrgSettingsExtendedQuery__
 *
 * To run a query within a React component, call `useOrgSettingsExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSettingsExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSettingsExtendedQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgSettingsExtendedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgSettingsExtendedQuery,
    OrgSettingsExtendedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgSettingsExtendedQuery,
    OrgSettingsExtendedQueryVariables
  >(OrgSettingsExtendedDocument, options);
}
export function useOrgSettingsExtendedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgSettingsExtendedQuery,
    OrgSettingsExtendedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgSettingsExtendedQuery,
    OrgSettingsExtendedQueryVariables
  >(OrgSettingsExtendedDocument, options);
}
export function useOrgSettingsExtendedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgSettingsExtendedQuery,
        OrgSettingsExtendedQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrgSettingsExtendedQuery,
    OrgSettingsExtendedQueryVariables
  >(OrgSettingsExtendedDocument, options);
}
export type OrgSettingsExtendedQueryHookResult = ReturnType<
  typeof useOrgSettingsExtendedQuery
>;
export type OrgSettingsExtendedLazyQueryHookResult = ReturnType<
  typeof useOrgSettingsExtendedLazyQuery
>;
export type OrgSettingsExtendedSuspenseQueryHookResult = ReturnType<
  typeof useOrgSettingsExtendedSuspenseQuery
>;
export type OrgSettingsExtendedQueryResult = Apollo.QueryResult<
  OrgSettingsExtendedQuery,
  OrgSettingsExtendedQueryVariables
>;
export const ViewerOrgNotificationsDocument = gql`
  query ViewerOrgNotifications {
    viewer {
      id
      org {
        id
        settings {
          notifications {
            ...NotificationSettingsFields
          }
        }
      }
    }
  }
  ${NotificationSettingsFieldsFragmentDoc}
`;

/**
 * __useViewerOrgNotificationsQuery__
 *
 * To run a query within a React component, call `useViewerOrgNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerOrgNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerOrgNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerOrgNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewerOrgNotificationsQuery,
    ViewerOrgNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewerOrgNotificationsQuery,
    ViewerOrgNotificationsQueryVariables
  >(ViewerOrgNotificationsDocument, options);
}
export function useViewerOrgNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewerOrgNotificationsQuery,
    ViewerOrgNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewerOrgNotificationsQuery,
    ViewerOrgNotificationsQueryVariables
  >(ViewerOrgNotificationsDocument, options);
}
export function useViewerOrgNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ViewerOrgNotificationsQuery,
        ViewerOrgNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewerOrgNotificationsQuery,
    ViewerOrgNotificationsQueryVariables
  >(ViewerOrgNotificationsDocument, options);
}
export type ViewerOrgNotificationsQueryHookResult = ReturnType<
  typeof useViewerOrgNotificationsQuery
>;
export type ViewerOrgNotificationsLazyQueryHookResult = ReturnType<
  typeof useViewerOrgNotificationsLazyQuery
>;
export type ViewerOrgNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useViewerOrgNotificationsSuspenseQuery
>;
export type ViewerOrgNotificationsQueryResult = Apollo.QueryResult<
  ViewerOrgNotificationsQuery,
  ViewerOrgNotificationsQueryVariables
>;
export const EnterprisesDocument = gql`
  query Enterprises {
    enterprises {
      ...EnterpriseFields
    }
  }
  ${EnterpriseFieldsFragmentDoc}
`;

/**
 * __useEnterprisesQuery__
 *
 * To run a query within a React component, call `useEnterprisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterprisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterprisesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnterprisesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EnterprisesQuery,
    EnterprisesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnterprisesQuery, EnterprisesQueryVariables>(
    EnterprisesDocument,
    options,
  );
}
export function useEnterprisesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnterprisesQuery,
    EnterprisesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnterprisesQuery, EnterprisesQueryVariables>(
    EnterprisesDocument,
    options,
  );
}
export function useEnterprisesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EnterprisesQuery,
        EnterprisesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EnterprisesQuery, EnterprisesQueryVariables>(
    EnterprisesDocument,
    options,
  );
}
export type EnterprisesQueryHookResult = ReturnType<typeof useEnterprisesQuery>;
export type EnterprisesLazyQueryHookResult = ReturnType<
  typeof useEnterprisesLazyQuery
>;
export type EnterprisesSuspenseQueryHookResult = ReturnType<
  typeof useEnterprisesSuspenseQuery
>;
export type EnterprisesQueryResult = Apollo.QueryResult<
  EnterprisesQuery,
  EnterprisesQueryVariables
>;
export const AdminUsersDocument = gql`
  query AdminUsers {
    users {
      ...AdminUsersUserFields
    }
  }
  ${AdminUsersUserFieldsFragmentDoc}
`;

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminUsersQuery,
    AdminUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(
    AdminUsersDocument,
    options,
  );
}
export function useAdminUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminUsersQuery,
    AdminUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(
    AdminUsersDocument,
    options,
  );
}
export function useAdminUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminUsersQuery,
        AdminUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminUsersQuery, AdminUsersQueryVariables>(
    AdminUsersDocument,
    options,
  );
}
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<
  typeof useAdminUsersLazyQuery
>;
export type AdminUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminUsersSuspenseQuery
>;
export type AdminUsersQueryResult = Apollo.QueryResult<
  AdminUsersQuery,
  AdminUsersQueryVariables
>;
export const EnterpriseUsersDocument = gql`
  query EnterpriseUsers {
    viewer {
      id
      enterprise {
        id
        users {
          id
          email
          cellPhone
          createdAt
          firstName
          lastName
          isEnterpriseAdmin
        }
      }
    }
  }
`;

/**
 * __useEnterpriseUsersQuery__
 *
 * To run a query within a React component, call `useEnterpriseUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnterpriseUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EnterpriseUsersQuery,
    EnterpriseUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnterpriseUsersQuery, EnterpriseUsersQueryVariables>(
    EnterpriseUsersDocument,
    options,
  );
}
export function useEnterpriseUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnterpriseUsersQuery,
    EnterpriseUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnterpriseUsersQuery,
    EnterpriseUsersQueryVariables
  >(EnterpriseUsersDocument, options);
}
export function useEnterpriseUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EnterpriseUsersQuery,
        EnterpriseUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EnterpriseUsersQuery,
    EnterpriseUsersQueryVariables
  >(EnterpriseUsersDocument, options);
}
export type EnterpriseUsersQueryHookResult = ReturnType<
  typeof useEnterpriseUsersQuery
>;
export type EnterpriseUsersLazyQueryHookResult = ReturnType<
  typeof useEnterpriseUsersLazyQuery
>;
export type EnterpriseUsersSuspenseQueryHookResult = ReturnType<
  typeof useEnterpriseUsersSuspenseQuery
>;
export type EnterpriseUsersQueryResult = Apollo.QueryResult<
  EnterpriseUsersQuery,
  EnterpriseUsersQueryVariables
>;
export const ImportVendorsDocument = gql`
  mutation ImportVendors($input: ImportVendorsInput!) {
    importVendors(input: $input) {
      created {
        ...OrgPreferredVendorsFields
      }
      skipped {
        ...OrgPreferredVendorsFields
      }
      mapped {
        ...OrgPreferredVendorsFields
      }
    }
  }
  ${OrgPreferredVendorsFieldsFragmentDoc}
`;
export type ImportVendorsMutationFn = Apollo.MutationFunction<
  ImportVendorsMutation,
  ImportVendorsMutationVariables
>;

/**
 * __useImportVendorsMutation__
 *
 * To run a mutation, you first call `useImportVendorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportVendorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importVendorsMutation, { data, loading, error }] = useImportVendorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportVendorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportVendorsMutation,
    ImportVendorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportVendorsMutation,
    ImportVendorsMutationVariables
  >(ImportVendorsDocument, options);
}
export type ImportVendorsMutationHookResult = ReturnType<
  typeof useImportVendorsMutation
>;
export type ImportVendorsMutationResult =
  Apollo.MutationResult<ImportVendorsMutation>;
export type ImportVendorsMutationOptions = Apollo.BaseMutationOptions<
  ImportVendorsMutation,
  ImportVendorsMutationVariables
>;
export const UpdateVendorDocument = gql`
  mutation UpdateVendor($input: UpdateOrgPreferredVendorInput!) {
    updateOrgPreferredVendor(input: $input) {
      ...AdminOrgPreferredVendorsFields
    }
  }
  ${AdminOrgPreferredVendorsFieldsFragmentDoc}
`;
export type UpdateVendorMutationFn = Apollo.MutationFunction<
  UpdateVendorMutation,
  UpdateVendorMutationVariables
>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorMutation,
    UpdateVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorMutation,
    UpdateVendorMutationVariables
  >(UpdateVendorDocument, options);
}
export type UpdateVendorMutationHookResult = ReturnType<
  typeof useUpdateVendorMutation
>;
export type UpdateVendorMutationResult =
  Apollo.MutationResult<UpdateVendorMutation>;
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorMutation,
  UpdateVendorMutationVariables
>;
export const UpdateVendorLocationDocument = gql`
  mutation UpdateVendorLocation($input: UpdateOrgLocationInput!) {
    updateVendorLocation(input: $input) {
      ...OrgLocationFields
    }
  }
  ${OrgLocationFieldsFragmentDoc}
`;
export type UpdateVendorLocationMutationFn = Apollo.MutationFunction<
  UpdateVendorLocationMutation,
  UpdateVendorLocationMutationVariables
>;

/**
 * __useUpdateVendorLocationMutation__
 *
 * To run a mutation, you first call `useUpdateVendorLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorLocationMutation, { data, loading, error }] = useUpdateVendorLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorLocationMutation,
    UpdateVendorLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorLocationMutation,
    UpdateVendorLocationMutationVariables
  >(UpdateVendorLocationDocument, options);
}
export type UpdateVendorLocationMutationHookResult = ReturnType<
  typeof useUpdateVendorLocationMutation
>;
export type UpdateVendorLocationMutationResult =
  Apollo.MutationResult<UpdateVendorLocationMutation>;
export type UpdateVendorLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorLocationMutation,
  UpdateVendorLocationMutationVariables
>;
export const RefreshExternalVendorsDocument = gql`
  mutation RefreshExternalVendors($sourceSystem: SourceSystem!) {
    refreshExternalVendors(sourceSystem: $sourceSystem)
  }
`;
export type RefreshExternalVendorsMutationFn = Apollo.MutationFunction<
  RefreshExternalVendorsMutation,
  RefreshExternalVendorsMutationVariables
>;

/**
 * __useRefreshExternalVendorsMutation__
 *
 * To run a mutation, you first call `useRefreshExternalVendorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExternalVendorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExternalVendorsMutation, { data, loading, error }] = useRefreshExternalVendorsMutation({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useRefreshExternalVendorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshExternalVendorsMutation,
    RefreshExternalVendorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshExternalVendorsMutation,
    RefreshExternalVendorsMutationVariables
  >(RefreshExternalVendorsDocument, options);
}
export type RefreshExternalVendorsMutationHookResult = ReturnType<
  typeof useRefreshExternalVendorsMutation
>;
export type RefreshExternalVendorsMutationResult =
  Apollo.MutationResult<RefreshExternalVendorsMutation>;
export type RefreshExternalVendorsMutationOptions = Apollo.BaseMutationOptions<
  RefreshExternalVendorsMutation,
  RefreshExternalVendorsMutationVariables
>;
export const ExternalVendorDocument = gql`
  query ExternalVendor($input: ExternalIdQueryInput!) {
    externalVendor(input: $input) {
      ...ExternalVendorFields
    }
  }
  ${ExternalVendorFieldsFragmentDoc}
`;

/**
 * __useExternalVendorQuery__
 *
 * To run a query within a React component, call `useExternalVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalVendorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalVendorQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalVendorQuery,
    ExternalVendorQueryVariables
  > &
    (
      | { variables: ExternalVendorQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalVendorQuery, ExternalVendorQueryVariables>(
    ExternalVendorDocument,
    options,
  );
}
export function useExternalVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalVendorQuery,
    ExternalVendorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalVendorQuery, ExternalVendorQueryVariables>(
    ExternalVendorDocument,
    options,
  );
}
export function useExternalVendorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalVendorQuery,
        ExternalVendorQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalVendorQuery,
    ExternalVendorQueryVariables
  >(ExternalVendorDocument, options);
}
export type ExternalVendorQueryHookResult = ReturnType<
  typeof useExternalVendorQuery
>;
export type ExternalVendorLazyQueryHookResult = ReturnType<
  typeof useExternalVendorLazyQuery
>;
export type ExternalVendorSuspenseQueryHookResult = ReturnType<
  typeof useExternalVendorSuspenseQuery
>;
export type ExternalVendorQueryResult = Apollo.QueryResult<
  ExternalVendorQuery,
  ExternalVendorQueryVariables
>;
export const OrgsDocument = gql`
  query Orgs($filter: QueryOrgsFilter) {
    orgs(filter: $filter) {
      ...OrgFields
    }
  }
  ${OrgFieldsFragmentDoc}
`;

/**
 * __useOrgsQuery__
 *
 * To run a query within a React component, call `useOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrgsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrgsQuery, OrgsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgsQuery, OrgsQueryVariables>(OrgsDocument, options);
}
export function useOrgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrgsQuery, OrgsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgsQuery, OrgsQueryVariables>(
    OrgsDocument,
    options,
  );
}
export function useOrgsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<OrgsQuery, OrgsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrgsQuery, OrgsQueryVariables>(
    OrgsDocument,
    options,
  );
}
export type OrgsQueryHookResult = ReturnType<typeof useOrgsQuery>;
export type OrgsLazyQueryHookResult = ReturnType<typeof useOrgsLazyQuery>;
export type OrgsSuspenseQueryHookResult = ReturnType<
  typeof useOrgsSuspenseQuery
>;
export type OrgsQueryResult = Apollo.QueryResult<OrgsQuery, OrgsQueryVariables>;
export const AdminOrgPreferredVendorsDocument = gql`
  query AdminOrgPreferredVendors(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryOrgPreferredVendorsFilter
  ) {
    orgPreferredVendorsConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...AdminOrgPreferredVendorsFields
        }
      }
    }
  }
  ${AdminOrgPreferredVendorsFieldsFragmentDoc}
`;

/**
 * __useAdminOrgPreferredVendorsQuery__
 *
 * To run a query within a React component, call `useAdminOrgPreferredVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrgPreferredVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrgPreferredVendorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminOrgPreferredVendorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminOrgPreferredVendorsQuery,
    AdminOrgPreferredVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminOrgPreferredVendorsQuery,
    AdminOrgPreferredVendorsQueryVariables
  >(AdminOrgPreferredVendorsDocument, options);
}
export function useAdminOrgPreferredVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminOrgPreferredVendorsQuery,
    AdminOrgPreferredVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminOrgPreferredVendorsQuery,
    AdminOrgPreferredVendorsQueryVariables
  >(AdminOrgPreferredVendorsDocument, options);
}
export function useAdminOrgPreferredVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminOrgPreferredVendorsQuery,
        AdminOrgPreferredVendorsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminOrgPreferredVendorsQuery,
    AdminOrgPreferredVendorsQueryVariables
  >(AdminOrgPreferredVendorsDocument, options);
}
export type AdminOrgPreferredVendorsQueryHookResult = ReturnType<
  typeof useAdminOrgPreferredVendorsQuery
>;
export type AdminOrgPreferredVendorsLazyQueryHookResult = ReturnType<
  typeof useAdminOrgPreferredVendorsLazyQuery
>;
export type AdminOrgPreferredVendorsSuspenseQueryHookResult = ReturnType<
  typeof useAdminOrgPreferredVendorsSuspenseQuery
>;
export type AdminOrgPreferredVendorsQueryResult = Apollo.QueryResult<
  AdminOrgPreferredVendorsQuery,
  AdminOrgPreferredVendorsQueryVariables
>;
export const EnterpriseVendorsDocument = gql`
  query EnterpriseVendors($excludedOrgIDs: [ID!]!) {
    viewer {
      id
      org {
        id
        enterprise {
          id
          orgPreferredVendors(excludedOrgIds: $excludedOrgIDs) {
            externalVendors {
              id
              sourceId
              sourceSystem
              externalId
            }
            ...AdminOrgPreferredVendorsFields
          }
        }
      }
    }
  }
  ${AdminOrgPreferredVendorsFieldsFragmentDoc}
`;

/**
 * __useEnterpriseVendorsQuery__
 *
 * To run a query within a React component, call `useEnterpriseVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseVendorsQuery({
 *   variables: {
 *      excludedOrgIDs: // value for 'excludedOrgIDs'
 *   },
 * });
 */
export function useEnterpriseVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnterpriseVendorsQuery,
    EnterpriseVendorsQueryVariables
  > &
    (
      | { variables: EnterpriseVendorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EnterpriseVendorsQuery,
    EnterpriseVendorsQueryVariables
  >(EnterpriseVendorsDocument, options);
}
export function useEnterpriseVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnterpriseVendorsQuery,
    EnterpriseVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnterpriseVendorsQuery,
    EnterpriseVendorsQueryVariables
  >(EnterpriseVendorsDocument, options);
}
export function useEnterpriseVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EnterpriseVendorsQuery,
        EnterpriseVendorsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EnterpriseVendorsQuery,
    EnterpriseVendorsQueryVariables
  >(EnterpriseVendorsDocument, options);
}
export type EnterpriseVendorsQueryHookResult = ReturnType<
  typeof useEnterpriseVendorsQuery
>;
export type EnterpriseVendorsLazyQueryHookResult = ReturnType<
  typeof useEnterpriseVendorsLazyQuery
>;
export type EnterpriseVendorsSuspenseQueryHookResult = ReturnType<
  typeof useEnterpriseVendorsSuspenseQuery
>;
export type EnterpriseVendorsQueryResult = Apollo.QueryResult<
  EnterpriseVendorsQuery,
  EnterpriseVendorsQueryVariables
>;
export const AddWarehouseDocument = gql`
  mutation AddWarehouse($input: AddWarehouseInput!) {
    addWarehouse(input: $input) {
      ...WarehouseFields
    }
  }
  ${WarehouseFieldsFragmentDoc}
`;
export type AddWarehouseMutationFn = Apollo.MutationFunction<
  AddWarehouseMutation,
  AddWarehouseMutationVariables
>;

/**
 * __useAddWarehouseMutation__
 *
 * To run a mutation, you first call `useAddWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWarehouseMutation, { data, loading, error }] = useAddWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWarehouseMutation,
    AddWarehouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddWarehouseMutation,
    AddWarehouseMutationVariables
  >(AddWarehouseDocument, options);
}
export type AddWarehouseMutationHookResult = ReturnType<
  typeof useAddWarehouseMutation
>;
export type AddWarehouseMutationResult =
  Apollo.MutationResult<AddWarehouseMutation>;
export type AddWarehouseMutationOptions = Apollo.BaseMutationOptions<
  AddWarehouseMutation,
  AddWarehouseMutationVariables
>;
export const ArchiveWarehouseDocument = gql`
  mutation ArchiveWarehouse($id: ID!) {
    archiveWarehouse(id: $id) {
      ...WarehouseFields
    }
  }
  ${WarehouseFieldsFragmentDoc}
`;
export type ArchiveWarehouseMutationFn = Apollo.MutationFunction<
  ArchiveWarehouseMutation,
  ArchiveWarehouseMutationVariables
>;

/**
 * __useArchiveWarehouseMutation__
 *
 * To run a mutation, you first call `useArchiveWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWarehouseMutation, { data, loading, error }] = useArchiveWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveWarehouseMutation,
    ArchiveWarehouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveWarehouseMutation,
    ArchiveWarehouseMutationVariables
  >(ArchiveWarehouseDocument, options);
}
export type ArchiveWarehouseMutationHookResult = ReturnType<
  typeof useArchiveWarehouseMutation
>;
export type ArchiveWarehouseMutationResult =
  Apollo.MutationResult<ArchiveWarehouseMutation>;
export type ArchiveWarehouseMutationOptions = Apollo.BaseMutationOptions<
  ArchiveWarehouseMutation,
  ArchiveWarehouseMutationVariables
>;
export const UpdateWarehouseDocument = gql`
  mutation UpdateWarehouse($input: UpdateWarehouseInput!) {
    updateWarehouse(input: $input) {
      ...WarehouseFields
    }
  }
  ${WarehouseFieldsFragmentDoc}
`;
export type UpdateWarehouseMutationFn = Apollo.MutationFunction<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
>;

/**
 * __useUpdateWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseMutation, { data, loading, error }] = useUpdateWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables
  >(UpdateWarehouseDocument, options);
}
export type UpdateWarehouseMutationHookResult = ReturnType<
  typeof useUpdateWarehouseMutation
>;
export type UpdateWarehouseMutationResult =
  Apollo.MutationResult<UpdateWarehouseMutation>;
export type UpdateWarehouseMutationOptions = Apollo.BaseMutationOptions<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
>;
export const WarehousesDocument = gql`
  query Warehouses($id: ID!) {
    org(id: $id) {
      id
      warehouses {
        ...WarehouseFields
      }
    }
  }
  ${WarehouseFieldsFragmentDoc}
`;

/**
 * __useWarehousesQuery__
 *
 * To run a query within a React component, call `useWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarehousesQuery(
  baseOptions: Apollo.QueryHookOptions<
    WarehousesQuery,
    WarehousesQueryVariables
  > &
    (
      | { variables: WarehousesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WarehousesQuery, WarehousesQueryVariables>(
    WarehousesDocument,
    options,
  );
}
export function useWarehousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WarehousesQuery,
    WarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WarehousesQuery, WarehousesQueryVariables>(
    WarehousesDocument,
    options,
  );
}
export function useWarehousesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WarehousesQuery,
        WarehousesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WarehousesQuery, WarehousesQueryVariables>(
    WarehousesDocument,
    options,
  );
}
export type WarehousesQueryHookResult = ReturnType<typeof useWarehousesQuery>;
export type WarehousesLazyQueryHookResult = ReturnType<
  typeof useWarehousesLazyQuery
>;
export type WarehousesSuspenseQueryHookResult = ReturnType<
  typeof useWarehousesSuspenseQuery
>;
export type WarehousesQueryResult = Apollo.QueryResult<
  WarehousesQuery,
  WarehousesQueryVariables
>;
export const BuyoutReleasesDocument = gql`
  query BuyoutReleases($id: ID!) {
    buyout(id: $id) {
      ...BuyoutReleasesFields
    }
  }
  ${BuyoutReleasesFieldsFragmentDoc}
`;

/**
 * __useBuyoutReleasesQuery__
 *
 * To run a query within a React component, call `useBuyoutReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutReleasesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyoutReleasesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuyoutReleasesQuery,
    BuyoutReleasesQueryVariables
  > &
    (
      | { variables: BuyoutReleasesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuyoutReleasesQuery, BuyoutReleasesQueryVariables>(
    BuyoutReleasesDocument,
    options,
  );
}
export function useBuyoutReleasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuyoutReleasesQuery,
    BuyoutReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuyoutReleasesQuery, BuyoutReleasesQueryVariables>(
    BuyoutReleasesDocument,
    options,
  );
}
export function useBuyoutReleasesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuyoutReleasesQuery,
        BuyoutReleasesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuyoutReleasesQuery,
    BuyoutReleasesQueryVariables
  >(BuyoutReleasesDocument, options);
}
export type BuyoutReleasesQueryHookResult = ReturnType<
  typeof useBuyoutReleasesQuery
>;
export type BuyoutReleasesLazyQueryHookResult = ReturnType<
  typeof useBuyoutReleasesLazyQuery
>;
export type BuyoutReleasesSuspenseQueryHookResult = ReturnType<
  typeof useBuyoutReleasesSuspenseQuery
>;
export type BuyoutReleasesQueryResult = Apollo.QueryResult<
  BuyoutReleasesQuery,
  BuyoutReleasesQueryVariables
>;
export const ActivateBuyoutDocument = gql`
  mutation ActivateBuyout($input: ActivateBuyoutInput!) {
    activateBuyout(input: $input) {
      ...BuyoutFields
    }
  }
  ${BuyoutFieldsFragmentDoc}
`;
export type ActivateBuyoutMutationFn = Apollo.MutationFunction<
  ActivateBuyoutMutation,
  ActivateBuyoutMutationVariables
>;

/**
 * __useActivateBuyoutMutation__
 *
 * To run a mutation, you first call `useActivateBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBuyoutMutation, { data, loading, error }] = useActivateBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateBuyoutMutation,
    ActivateBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateBuyoutMutation,
    ActivateBuyoutMutationVariables
  >(ActivateBuyoutDocument, options);
}
export type ActivateBuyoutMutationHookResult = ReturnType<
  typeof useActivateBuyoutMutation
>;
export type ActivateBuyoutMutationResult =
  Apollo.MutationResult<ActivateBuyoutMutation>;
export type ActivateBuyoutMutationOptions = Apollo.BaseMutationOptions<
  ActivateBuyoutMutation,
  ActivateBuyoutMutationVariables
>;
export const AddToBuyoutDocument = gql`
  mutation AddToBuyout($input: AddToBuyoutInput!) {
    addToBuyout(input: $input) {
      ...BuyoutItemFields
    }
  }
  ${BuyoutItemFieldsFragmentDoc}
`;
export type AddToBuyoutMutationFn = Apollo.MutationFunction<
  AddToBuyoutMutation,
  AddToBuyoutMutationVariables
>;

/**
 * __useAddToBuyoutMutation__
 *
 * To run a mutation, you first call `useAddToBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBuyoutMutation, { data, loading, error }] = useAddToBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToBuyoutMutation,
    AddToBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToBuyoutMutation, AddToBuyoutMutationVariables>(
    AddToBuyoutDocument,
    options,
  );
}
export type AddToBuyoutMutationHookResult = ReturnType<
  typeof useAddToBuyoutMutation
>;
export type AddToBuyoutMutationResult =
  Apollo.MutationResult<AddToBuyoutMutation>;
export type AddToBuyoutMutationOptions = Apollo.BaseMutationOptions<
  AddToBuyoutMutation,
  AddToBuyoutMutationVariables
>;
export const CancelBuyoutDocument = gql`
  mutation CancelBuyout($input: CancelBuyoutInput!) {
    cancelBuyout(input: $input) {
      ...BuyoutFields
    }
  }
  ${BuyoutFieldsFragmentDoc}
`;
export type CancelBuyoutMutationFn = Apollo.MutationFunction<
  CancelBuyoutMutation,
  CancelBuyoutMutationVariables
>;

/**
 * __useCancelBuyoutMutation__
 *
 * To run a mutation, you first call `useCancelBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBuyoutMutation, { data, loading, error }] = useCancelBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelBuyoutMutation,
    CancelBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelBuyoutMutation,
    CancelBuyoutMutationVariables
  >(CancelBuyoutDocument, options);
}
export type CancelBuyoutMutationHookResult = ReturnType<
  typeof useCancelBuyoutMutation
>;
export type CancelBuyoutMutationResult =
  Apollo.MutationResult<CancelBuyoutMutation>;
export type CancelBuyoutMutationOptions = Apollo.BaseMutationOptions<
  CancelBuyoutMutation,
  CancelBuyoutMutationVariables
>;
export const SubmitBuyoutDocument = gql`
  mutation SubmitBuyout($input: SubmitBuyoutInput!) {
    submitBuyout(input: $input) {
      ...BuyoutFields
    }
  }
  ${BuyoutFieldsFragmentDoc}
`;
export type SubmitBuyoutMutationFn = Apollo.MutationFunction<
  SubmitBuyoutMutation,
  SubmitBuyoutMutationVariables
>;

/**
 * __useSubmitBuyoutMutation__
 *
 * To run a mutation, you first call `useSubmitBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBuyoutMutation, { data, loading, error }] = useSubmitBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitBuyoutMutation,
    SubmitBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitBuyoutMutation,
    SubmitBuyoutMutationVariables
  >(SubmitBuyoutDocument, options);
}
export type SubmitBuyoutMutationHookResult = ReturnType<
  typeof useSubmitBuyoutMutation
>;
export type SubmitBuyoutMutationResult =
  Apollo.MutationResult<SubmitBuyoutMutation>;
export type SubmitBuyoutMutationOptions = Apollo.BaseMutationOptions<
  SubmitBuyoutMutation,
  SubmitBuyoutMutationVariables
>;
export const UpdateContractorBuyoutDocument = gql`
  mutation UpdateContractorBuyout($input: UpdateContractorBuyoutInput!) {
    updateContractorBuyout(input: $input) {
      ...BuyoutFields
    }
  }
  ${BuyoutFieldsFragmentDoc}
`;
export type UpdateContractorBuyoutMutationFn = Apollo.MutationFunction<
  UpdateContractorBuyoutMutation,
  UpdateContractorBuyoutMutationVariables
>;

/**
 * __useUpdateContractorBuyoutMutation__
 *
 * To run a mutation, you first call `useUpdateContractorBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractorBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractorBuyoutMutation, { data, loading, error }] = useUpdateContractorBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractorBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractorBuyoutMutation,
    UpdateContractorBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractorBuyoutMutation,
    UpdateContractorBuyoutMutationVariables
  >(UpdateContractorBuyoutDocument, options);
}
export type UpdateContractorBuyoutMutationHookResult = ReturnType<
  typeof useUpdateContractorBuyoutMutation
>;
export type UpdateContractorBuyoutMutationResult =
  Apollo.MutationResult<UpdateContractorBuyoutMutation>;
export type UpdateContractorBuyoutMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractorBuyoutMutation,
  UpdateContractorBuyoutMutationVariables
>;
export const BuyoutDocument = gql`
  query Buyout($id: ID!) {
    buyout(id: $id) {
      ...BuyoutFields
    }
  }
  ${BuyoutFieldsFragmentDoc}
`;

/**
 * __useBuyoutQuery__
 *
 * To run a query within a React component, call `useBuyoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyoutQuery(
  baseOptions: Apollo.QueryHookOptions<BuyoutQuery, BuyoutQueryVariables> &
    ({ variables: BuyoutQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuyoutQuery, BuyoutQueryVariables>(
    BuyoutDocument,
    options,
  );
}
export function useBuyoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuyoutQuery, BuyoutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuyoutQuery, BuyoutQueryVariables>(
    BuyoutDocument,
    options,
  );
}
export function useBuyoutSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BuyoutQuery, BuyoutQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuyoutQuery, BuyoutQueryVariables>(
    BuyoutDocument,
    options,
  );
}
export type BuyoutQueryHookResult = ReturnType<typeof useBuyoutQuery>;
export type BuyoutLazyQueryHookResult = ReturnType<typeof useBuyoutLazyQuery>;
export type BuyoutSuspenseQueryHookResult = ReturnType<
  typeof useBuyoutSuspenseQuery
>;
export type BuyoutQueryResult = Apollo.QueryResult<
  BuyoutQuery,
  BuyoutQueryVariables
>;
export const ArchiveBuyoutsDocument = gql`
  mutation ArchiveBuyouts($ids: [ID!]!) {
    archiveBuyouts(ids: $ids) {
      id
      deletedAt
      status
    }
  }
`;
export type ArchiveBuyoutsMutationFn = Apollo.MutationFunction<
  ArchiveBuyoutsMutation,
  ArchiveBuyoutsMutationVariables
>;

/**
 * __useArchiveBuyoutsMutation__
 *
 * To run a mutation, you first call `useArchiveBuyoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBuyoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBuyoutsMutation, { data, loading, error }] = useArchiveBuyoutsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveBuyoutsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveBuyoutsMutation,
    ArchiveBuyoutsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveBuyoutsMutation,
    ArchiveBuyoutsMutationVariables
  >(ArchiveBuyoutsDocument, options);
}
export type ArchiveBuyoutsMutationHookResult = ReturnType<
  typeof useArchiveBuyoutsMutation
>;
export type ArchiveBuyoutsMutationResult =
  Apollo.MutationResult<ArchiveBuyoutsMutation>;
export type ArchiveBuyoutsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBuyoutsMutation,
  ArchiveBuyoutsMutationVariables
>;
export const CreateNoteDocumentDocument = gql`
  mutation CreateNoteDocument($input: CreateNoteDocumentInput!) {
    createNoteDocument(input: $input) {
      ...NoteDocumentFields
    }
  }
  ${NoteDocumentFieldsFragmentDoc}
`;
export type CreateNoteDocumentMutationFn = Apollo.MutationFunction<
  CreateNoteDocumentMutation,
  CreateNoteDocumentMutationVariables
>;

/**
 * __useCreateNoteDocumentMutation__
 *
 * To run a mutation, you first call `useCreateNoteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteDocumentMutation, { data, loading, error }] = useCreateNoteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNoteDocumentMutation,
    CreateNoteDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNoteDocumentMutation,
    CreateNoteDocumentMutationVariables
  >(CreateNoteDocumentDocument, options);
}
export type CreateNoteDocumentMutationHookResult = ReturnType<
  typeof useCreateNoteDocumentMutation
>;
export type CreateNoteDocumentMutationResult =
  Apollo.MutationResult<CreateNoteDocumentMutation>;
export type CreateNoteDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteDocumentMutation,
  CreateNoteDocumentMutationVariables
>;
export const NoteDocumentDocument = gql`
  query NoteDocument($id: ID!) {
    noteDocument(id: $id) {
      ...NoteDocumentFields
    }
  }
  ${NoteDocumentFieldsFragmentDoc}
`;

/**
 * __useNoteDocumentQuery__
 *
 * To run a query within a React component, call `useNoteDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    NoteDocumentQuery,
    NoteDocumentQueryVariables
  > &
    (
      | { variables: NoteDocumentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NoteDocumentQuery, NoteDocumentQueryVariables>(
    NoteDocumentDocument,
    options,
  );
}
export function useNoteDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NoteDocumentQuery,
    NoteDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NoteDocumentQuery, NoteDocumentQueryVariables>(
    NoteDocumentDocument,
    options,
  );
}
export function useNoteDocumentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NoteDocumentQuery,
        NoteDocumentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NoteDocumentQuery, NoteDocumentQueryVariables>(
    NoteDocumentDocument,
    options,
  );
}
export type NoteDocumentQueryHookResult = ReturnType<
  typeof useNoteDocumentQuery
>;
export type NoteDocumentLazyQueryHookResult = ReturnType<
  typeof useNoteDocumentLazyQuery
>;
export type NoteDocumentSuspenseQueryHookResult = ReturnType<
  typeof useNoteDocumentSuspenseQuery
>;
export type NoteDocumentQueryResult = Apollo.QueryResult<
  NoteDocumentQuery,
  NoteDocumentQueryVariables
>;
export const QuoteDocumentDocument = gql`
  query QuoteDocument($id: ID!) {
    quoteDocument(id: $id) {
      ...QuoteDocumentFields
    }
  }
  ${QuoteDocumentFieldsFragmentDoc}
`;

/**
 * __useQuoteDocumentQuery__
 *
 * To run a query within a React component, call `useQuoteDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuoteDocumentQuery,
    QuoteDocumentQueryVariables
  > &
    (
      | { variables: QuoteDocumentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuoteDocumentQuery, QuoteDocumentQueryVariables>(
    QuoteDocumentDocument,
    options,
  );
}
export function useQuoteDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuoteDocumentQuery,
    QuoteDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuoteDocumentQuery, QuoteDocumentQueryVariables>(
    QuoteDocumentDocument,
    options,
  );
}
export function useQuoteDocumentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QuoteDocumentQuery,
        QuoteDocumentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QuoteDocumentQuery,
    QuoteDocumentQueryVariables
  >(QuoteDocumentDocument, options);
}
export type QuoteDocumentQueryHookResult = ReturnType<
  typeof useQuoteDocumentQuery
>;
export type QuoteDocumentLazyQueryHookResult = ReturnType<
  typeof useQuoteDocumentLazyQuery
>;
export type QuoteDocumentSuspenseQueryHookResult = ReturnType<
  typeof useQuoteDocumentSuspenseQuery
>;
export type QuoteDocumentQueryResult = Apollo.QueryResult<
  QuoteDocumentQuery,
  QuoteDocumentQueryVariables
>;
export const InventoryItemTransactionsDocument = gql`
  query InventoryItemTransactions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryInventoryItemTransactionsFilter!
  ) {
    inventoryItemTransactions(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...InventoryTransactionSummaryFields
        }
      }
    }
  }
  ${InventoryTransactionSummaryFieldsFragmentDoc}
`;

/**
 * __useInventoryItemTransactionsQuery__
 *
 * To run a query within a React component, call `useInventoryItemTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInventoryItemTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InventoryItemTransactionsQuery,
    InventoryItemTransactionsQueryVariables
  > &
    (
      | { variables: InventoryItemTransactionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InventoryItemTransactionsQuery,
    InventoryItemTransactionsQueryVariables
  >(InventoryItemTransactionsDocument, options);
}
export function useInventoryItemTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryItemTransactionsQuery,
    InventoryItemTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InventoryItemTransactionsQuery,
    InventoryItemTransactionsQueryVariables
  >(InventoryItemTransactionsDocument, options);
}
export function useInventoryItemTransactionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InventoryItemTransactionsQuery,
        InventoryItemTransactionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InventoryItemTransactionsQuery,
    InventoryItemTransactionsQueryVariables
  >(InventoryItemTransactionsDocument, options);
}
export type InventoryItemTransactionsQueryHookResult = ReturnType<
  typeof useInventoryItemTransactionsQuery
>;
export type InventoryItemTransactionsLazyQueryHookResult = ReturnType<
  typeof useInventoryItemTransactionsLazyQuery
>;
export type InventoryItemTransactionsSuspenseQueryHookResult = ReturnType<
  typeof useInventoryItemTransactionsSuspenseQuery
>;
export type InventoryItemTransactionsQueryResult = Apollo.QueryResult<
  InventoryItemTransactionsQuery,
  InventoryItemTransactionsQueryVariables
>;
export const InventoryTransactionDocument = gql`
  query InventoryTransaction($id: ID!) {
    inventoryTransaction(id: $id) {
      ...InventoryTransactionFields
    }
  }
  ${InventoryTransactionFieldsFragmentDoc}
`;

/**
 * __useInventoryTransactionQuery__
 *
 * To run a query within a React component, call `useInventoryTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    InventoryTransactionQuery,
    InventoryTransactionQueryVariables
  > &
    (
      | { variables: InventoryTransactionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InventoryTransactionQuery,
    InventoryTransactionQueryVariables
  >(InventoryTransactionDocument, options);
}
export function useInventoryTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryTransactionQuery,
    InventoryTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InventoryTransactionQuery,
    InventoryTransactionQueryVariables
  >(InventoryTransactionDocument, options);
}
export function useInventoryTransactionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InventoryTransactionQuery,
        InventoryTransactionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InventoryTransactionQuery,
    InventoryTransactionQueryVariables
  >(InventoryTransactionDocument, options);
}
export type InventoryTransactionQueryHookResult = ReturnType<
  typeof useInventoryTransactionQuery
>;
export type InventoryTransactionLazyQueryHookResult = ReturnType<
  typeof useInventoryTransactionLazyQuery
>;
export type InventoryTransactionSuspenseQueryHookResult = ReturnType<
  typeof useInventoryTransactionSuspenseQuery
>;
export type InventoryTransactionQueryResult = Apollo.QueryResult<
  InventoryTransactionQuery,
  InventoryTransactionQueryVariables
>;
export const CreateInventoryAdjustmentDocument = gql`
  mutation CreateInventoryAdjustment($input: CreateInventoryAdjustmentInput!) {
    createInventoryAdjustment(input: $input) {
      id
      items {
        ...InventoryTransactionItemSummaryFields
      }
    }
  }
  ${InventoryTransactionItemSummaryFieldsFragmentDoc}
`;
export type CreateInventoryAdjustmentMutationFn = Apollo.MutationFunction<
  CreateInventoryAdjustmentMutation,
  CreateInventoryAdjustmentMutationVariables
>;

/**
 * __useCreateInventoryAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateInventoryAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryAdjustmentMutation, { data, loading, error }] = useCreateInventoryAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInventoryAdjustmentMutation,
    CreateInventoryAdjustmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInventoryAdjustmentMutation,
    CreateInventoryAdjustmentMutationVariables
  >(CreateInventoryAdjustmentDocument, options);
}
export type CreateInventoryAdjustmentMutationHookResult = ReturnType<
  typeof useCreateInventoryAdjustmentMutation
>;
export type CreateInventoryAdjustmentMutationResult =
  Apollo.MutationResult<CreateInventoryAdjustmentMutation>;
export type CreateInventoryAdjustmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInventoryAdjustmentMutation,
    CreateInventoryAdjustmentMutationVariables
  >;
export const CreateInventoryTransferDocument = gql`
  mutation CreateInventoryTransfer($input: CreateInventoryTransferInput!) {
    createInventoryTransfer(input: $input) {
      id
      items {
        ...InventoryTransactionItemSummaryFields
      }
    }
  }
  ${InventoryTransactionItemSummaryFieldsFragmentDoc}
`;
export type CreateInventoryTransferMutationFn = Apollo.MutationFunction<
  CreateInventoryTransferMutation,
  CreateInventoryTransferMutationVariables
>;

/**
 * __useCreateInventoryTransferMutation__
 *
 * To run a mutation, you first call `useCreateInventoryTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryTransferMutation, { data, loading, error }] = useCreateInventoryTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInventoryTransferMutation,
    CreateInventoryTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInventoryTransferMutation,
    CreateInventoryTransferMutationVariables
  >(CreateInventoryTransferDocument, options);
}
export type CreateInventoryTransferMutationHookResult = ReturnType<
  typeof useCreateInventoryTransferMutation
>;
export type CreateInventoryTransferMutationResult =
  Apollo.MutationResult<CreateInventoryTransferMutation>;
export type CreateInventoryTransferMutationOptions = Apollo.BaseMutationOptions<
  CreateInventoryTransferMutation,
  CreateInventoryTransferMutationVariables
>;
export const ArchiveInventoryItemsDocument = gql`
  mutation ArchiveInventoryItems($input: ArchiveInventoryItemsInput) {
    archiveInventoryItems(input: $input) {
      id
      archivedAt
    }
  }
`;
export type ArchiveInventoryItemsMutationFn = Apollo.MutationFunction<
  ArchiveInventoryItemsMutation,
  ArchiveInventoryItemsMutationVariables
>;

/**
 * __useArchiveInventoryItemsMutation__
 *
 * To run a mutation, you first call `useArchiveInventoryItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInventoryItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInventoryItemsMutation, { data, loading, error }] = useArchiveInventoryItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveInventoryItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveInventoryItemsMutation,
    ArchiveInventoryItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveInventoryItemsMutation,
    ArchiveInventoryItemsMutationVariables
  >(ArchiveInventoryItemsDocument, options);
}
export type ArchiveInventoryItemsMutationHookResult = ReturnType<
  typeof useArchiveInventoryItemsMutation
>;
export type ArchiveInventoryItemsMutationResult =
  Apollo.MutationResult<ArchiveInventoryItemsMutation>;
export type ArchiveInventoryItemsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveInventoryItemsMutation,
  ArchiveInventoryItemsMutationVariables
>;
export const InventoryItemsDocument = gql`
  query InventoryItems(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryInventoryItemsFilter
  ) {
    inventoryItems(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...InventoryItemSummaryFields
        }
      }
    }
  }
  ${InventoryItemSummaryFieldsFragmentDoc}
`;

/**
 * __useInventoryItemsQuery__
 *
 * To run a query within a React component, call `useInventoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInventoryItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InventoryItemsQuery,
    InventoryItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryItemsQuery, InventoryItemsQueryVariables>(
    InventoryItemsDocument,
    options,
  );
}
export function useInventoryItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryItemsQuery,
    InventoryItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryItemsQuery, InventoryItemsQueryVariables>(
    InventoryItemsDocument,
    options,
  );
}
export function useInventoryItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InventoryItemsQuery,
        InventoryItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InventoryItemsQuery,
    InventoryItemsQueryVariables
  >(InventoryItemsDocument, options);
}
export type InventoryItemsQueryHookResult = ReturnType<
  typeof useInventoryItemsQuery
>;
export type InventoryItemsLazyQueryHookResult = ReturnType<
  typeof useInventoryItemsLazyQuery
>;
export type InventoryItemsSuspenseQueryHookResult = ReturnType<
  typeof useInventoryItemsSuspenseQuery
>;
export type InventoryItemsQueryResult = Apollo.QueryResult<
  InventoryItemsQuery,
  InventoryItemsQueryVariables
>;
export const InventoryTransactionsDocument = gql`
  query InventoryTransactions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryInventoryTransactionsFilter!
  ) {
    inventoryTransactions(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...InventoryTransactionSummaryFields
        }
      }
    }
  }
  ${InventoryTransactionSummaryFieldsFragmentDoc}
`;

/**
 * __useInventoryTransactionsQuery__
 *
 * To run a query within a React component, call `useInventoryTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInventoryTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InventoryTransactionsQuery,
    InventoryTransactionsQueryVariables
  > &
    (
      | { variables: InventoryTransactionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InventoryTransactionsQuery,
    InventoryTransactionsQueryVariables
  >(InventoryTransactionsDocument, options);
}
export function useInventoryTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryTransactionsQuery,
    InventoryTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InventoryTransactionsQuery,
    InventoryTransactionsQueryVariables
  >(InventoryTransactionsDocument, options);
}
export function useInventoryTransactionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InventoryTransactionsQuery,
        InventoryTransactionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InventoryTransactionsQuery,
    InventoryTransactionsQueryVariables
  >(InventoryTransactionsDocument, options);
}
export type InventoryTransactionsQueryHookResult = ReturnType<
  typeof useInventoryTransactionsQuery
>;
export type InventoryTransactionsLazyQueryHookResult = ReturnType<
  typeof useInventoryTransactionsLazyQuery
>;
export type InventoryTransactionsSuspenseQueryHookResult = ReturnType<
  typeof useInventoryTransactionsSuspenseQuery
>;
export type InventoryTransactionsQueryResult = Apollo.QueryResult<
  InventoryTransactionsQuery,
  InventoryTransactionsQueryVariables
>;
export const ArchiveInvoiceEmailsDocument = gql`
  mutation ArchiveInvoiceEmails($input: ArchiveInvoiceEmailsInput!) {
    archiveInvoiceEmails(input: $input) {
      id
      archivedAt
    }
  }
`;
export type ArchiveInvoiceEmailsMutationFn = Apollo.MutationFunction<
  ArchiveInvoiceEmailsMutation,
  ArchiveInvoiceEmailsMutationVariables
>;

/**
 * __useArchiveInvoiceEmailsMutation__
 *
 * To run a mutation, you first call `useArchiveInvoiceEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInvoiceEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInvoiceEmailsMutation, { data, loading, error }] = useArchiveInvoiceEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveInvoiceEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveInvoiceEmailsMutation,
    ArchiveInvoiceEmailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveInvoiceEmailsMutation,
    ArchiveInvoiceEmailsMutationVariables
  >(ArchiveInvoiceEmailsDocument, options);
}
export type ArchiveInvoiceEmailsMutationHookResult = ReturnType<
  typeof useArchiveInvoiceEmailsMutation
>;
export type ArchiveInvoiceEmailsMutationResult =
  Apollo.MutationResult<ArchiveInvoiceEmailsMutation>;
export type ArchiveInvoiceEmailsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveInvoiceEmailsMutation,
  ArchiveInvoiceEmailsMutationVariables
>;
export const ArchiveInvoiceEmailDocument = gql`
  mutation ArchiveInvoiceEmail($id: ID!) {
    archiveInvoiceEmail(id: $id) {
      id
      archivedAt
    }
  }
`;
export type ArchiveInvoiceEmailMutationFn = Apollo.MutationFunction<
  ArchiveInvoiceEmailMutation,
  ArchiveInvoiceEmailMutationVariables
>;

/**
 * __useArchiveInvoiceEmailMutation__
 *
 * To run a mutation, you first call `useArchiveInvoiceEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInvoiceEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInvoiceEmailMutation, { data, loading, error }] = useArchiveInvoiceEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveInvoiceEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveInvoiceEmailMutation,
    ArchiveInvoiceEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveInvoiceEmailMutation,
    ArchiveInvoiceEmailMutationVariables
  >(ArchiveInvoiceEmailDocument, options);
}
export type ArchiveInvoiceEmailMutationHookResult = ReturnType<
  typeof useArchiveInvoiceEmailMutation
>;
export type ArchiveInvoiceEmailMutationResult =
  Apollo.MutationResult<ArchiveInvoiceEmailMutation>;
export type ArchiveInvoiceEmailMutationOptions = Apollo.BaseMutationOptions<
  ArchiveInvoiceEmailMutation,
  ArchiveInvoiceEmailMutationVariables
>;
export const InvoiceEmailsDocument = gql`
  query InvoiceEmails(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryInvoiceEmailsFilter
  ) {
    invoiceEmails(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...InvoiceEmailFields
        }
      }
    }
  }
  ${InvoiceEmailFieldsFragmentDoc}
`;

/**
 * __useInvoiceEmailsQuery__
 *
 * To run a query within a React component, call `useInvoiceEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceEmailsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInvoiceEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvoiceEmailsQuery,
    InvoiceEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceEmailsQuery, InvoiceEmailsQueryVariables>(
    InvoiceEmailsDocument,
    options,
  );
}
export function useInvoiceEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceEmailsQuery,
    InvoiceEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceEmailsQuery, InvoiceEmailsQueryVariables>(
    InvoiceEmailsDocument,
    options,
  );
}
export function useInvoiceEmailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvoiceEmailsQuery,
        InvoiceEmailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvoiceEmailsQuery,
    InvoiceEmailsQueryVariables
  >(InvoiceEmailsDocument, options);
}
export type InvoiceEmailsQueryHookResult = ReturnType<
  typeof useInvoiceEmailsQuery
>;
export type InvoiceEmailsLazyQueryHookResult = ReturnType<
  typeof useInvoiceEmailsLazyQuery
>;
export type InvoiceEmailsSuspenseQueryHookResult = ReturnType<
  typeof useInvoiceEmailsSuspenseQuery
>;
export type InvoiceEmailsQueryResult = Apollo.QueryResult<
  InvoiceEmailsQuery,
  InvoiceEmailsQueryVariables
>;
export const ApproveInvoiceDocument = gql`
  mutation ApproveInvoice($input: ApproveInvoiceInput!) {
    approveInvoice(input: $input) {
      ...InvoiceFields
    }
  }
  ${InvoiceFieldsFragmentDoc}
`;
export type ApproveInvoiceMutationFn = Apollo.MutationFunction<
  ApproveInvoiceMutation,
  ApproveInvoiceMutationVariables
>;

/**
 * __useApproveInvoiceMutation__
 *
 * To run a mutation, you first call `useApproveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInvoiceMutation, { data, loading, error }] = useApproveInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveInvoiceMutation,
    ApproveInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveInvoiceMutation,
    ApproveInvoiceMutationVariables
  >(ApproveInvoiceDocument, options);
}
export type ApproveInvoiceMutationHookResult = ReturnType<
  typeof useApproveInvoiceMutation
>;
export type ApproveInvoiceMutationResult =
  Apollo.MutationResult<ApproveInvoiceMutation>;
export type ApproveInvoiceMutationOptions = Apollo.BaseMutationOptions<
  ApproveInvoiceMutation,
  ApproveInvoiceMutationVariables
>;
export const ConnectMissingPoItemsDocument = gql`
  mutation ConnectMissingPOItems(
    $poLinkId: ID!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    connectMissingPOItems(poLinkId: $poLinkId) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;
export type ConnectMissingPoItemsMutationFn = Apollo.MutationFunction<
  ConnectMissingPoItemsMutation,
  ConnectMissingPoItemsMutationVariables
>;

/**
 * __useConnectMissingPoItemsMutation__
 *
 * To run a mutation, you first call `useConnectMissingPoItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectMissingPoItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectMissingPoItemsMutation, { data, loading, error }] = useConnectMissingPoItemsMutation({
 *   variables: {
 *      poLinkId: // value for 'poLinkId'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useConnectMissingPoItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectMissingPoItemsMutation,
    ConnectMissingPoItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectMissingPoItemsMutation,
    ConnectMissingPoItemsMutationVariables
  >(ConnectMissingPoItemsDocument, options);
}
export type ConnectMissingPoItemsMutationHookResult = ReturnType<
  typeof useConnectMissingPoItemsMutation
>;
export type ConnectMissingPoItemsMutationResult =
  Apollo.MutationResult<ConnectMissingPoItemsMutation>;
export type ConnectMissingPoItemsMutationOptions = Apollo.BaseMutationOptions<
  ConnectMissingPoItemsMutation,
  ConnectMissingPoItemsMutationVariables
>;
export const CreateInvoiceIssueDocument = gql`
  mutation CreateInvoiceIssue($input: CreateInvoiceIssueInput!) {
    createInvoiceIssue(input: $input) {
      id
      status
      issues {
        ...InvoiceIssueFields
      }
    }
  }
  ${InvoiceIssueFieldsFragmentDoc}
`;
export type CreateInvoiceIssueMutationFn = Apollo.MutationFunction<
  CreateInvoiceIssueMutation,
  CreateInvoiceIssueMutationVariables
>;

/**
 * __useCreateInvoiceIssueMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceIssueMutation, { data, loading, error }] = useCreateInvoiceIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceIssueMutation,
    CreateInvoiceIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceIssueMutation,
    CreateInvoiceIssueMutationVariables
  >(CreateInvoiceIssueDocument, options);
}
export type CreateInvoiceIssueMutationHookResult = ReturnType<
  typeof useCreateInvoiceIssueMutation
>;
export type CreateInvoiceIssueMutationResult =
  Apollo.MutationResult<CreateInvoiceIssueMutation>;
export type CreateInvoiceIssueMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceIssueMutation,
  CreateInvoiceIssueMutationVariables
>;
export const CreateInvoiceKickbackDocument = gql`
  mutation CreateInvoiceKickback($input: CreateInvoiceKickbackInput!) {
    createInvoiceKickback(input: $input) {
      id
      status
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type CreateInvoiceKickbackMutationFn = Apollo.MutationFunction<
  CreateInvoiceKickbackMutation,
  CreateInvoiceKickbackMutationVariables
>;

/**
 * __useCreateInvoiceKickbackMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceKickbackMutation, { data, loading, error }] = useCreateInvoiceKickbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceKickbackMutation,
    CreateInvoiceKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceKickbackMutation,
    CreateInvoiceKickbackMutationVariables
  >(CreateInvoiceKickbackDocument, options);
}
export type CreateInvoiceKickbackMutationHookResult = ReturnType<
  typeof useCreateInvoiceKickbackMutation
>;
export type CreateInvoiceKickbackMutationResult =
  Apollo.MutationResult<CreateInvoiceKickbackMutation>;
export type CreateInvoiceKickbackMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceKickbackMutation,
  CreateInvoiceKickbackMutationVariables
>;
export const CreateInvoiceKickbackCommentDocument = gql`
  mutation CreateInvoiceKickbackComment(
    $input: CreateInvoiceKickbackCommentInput!
  ) {
    createInvoiceKickbackComment(input: $input) {
      ...InvoiceKickbackFields
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type CreateInvoiceKickbackCommentMutationFn = Apollo.MutationFunction<
  CreateInvoiceKickbackCommentMutation,
  CreateInvoiceKickbackCommentMutationVariables
>;

/**
 * __useCreateInvoiceKickbackCommentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceKickbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceKickbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceKickbackCommentMutation, { data, loading, error }] = useCreateInvoiceKickbackCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceKickbackCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceKickbackCommentMutation,
    CreateInvoiceKickbackCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceKickbackCommentMutation,
    CreateInvoiceKickbackCommentMutationVariables
  >(CreateInvoiceKickbackCommentDocument, options);
}
export type CreateInvoiceKickbackCommentMutationHookResult = ReturnType<
  typeof useCreateInvoiceKickbackCommentMutation
>;
export type CreateInvoiceKickbackCommentMutationResult =
  Apollo.MutationResult<CreateInvoiceKickbackCommentMutation>;
export type CreateInvoiceKickbackCommentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvoiceKickbackCommentMutation,
    CreateInvoiceKickbackCommentMutationVariables
  >;
export const DeleteInvoiceIssueDocument = gql`
  mutation DeleteInvoiceIssue($id: ID!) {
    deleteInvoiceIssue(id: $id) {
      id
      status
      issues {
        ...InvoiceIssueFields
      }
    }
  }
  ${InvoiceIssueFieldsFragmentDoc}
`;
export type DeleteInvoiceIssueMutationFn = Apollo.MutationFunction<
  DeleteInvoiceIssueMutation,
  DeleteInvoiceIssueMutationVariables
>;

/**
 * __useDeleteInvoiceIssueMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceIssueMutation, { data, loading, error }] = useDeleteInvoiceIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceIssueMutation,
    DeleteInvoiceIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceIssueMutation,
    DeleteInvoiceIssueMutationVariables
  >(DeleteInvoiceIssueDocument, options);
}
export type DeleteInvoiceIssueMutationHookResult = ReturnType<
  typeof useDeleteInvoiceIssueMutation
>;
export type DeleteInvoiceIssueMutationResult =
  Apollo.MutationResult<DeleteInvoiceIssueMutation>;
export type DeleteInvoiceIssueMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceIssueMutation,
  DeleteInvoiceIssueMutationVariables
>;
export const DeleteInvoiceKickbackDocument = gql`
  mutation DeleteInvoiceKickback($id: ID!) {
    deleteInvoiceKickback(id: $id) {
      id
      status
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type DeleteInvoiceKickbackMutationFn = Apollo.MutationFunction<
  DeleteInvoiceKickbackMutation,
  DeleteInvoiceKickbackMutationVariables
>;

/**
 * __useDeleteInvoiceKickbackMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceKickbackMutation, { data, loading, error }] = useDeleteInvoiceKickbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceKickbackMutation,
    DeleteInvoiceKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceKickbackMutation,
    DeleteInvoiceKickbackMutationVariables
  >(DeleteInvoiceKickbackDocument, options);
}
export type DeleteInvoiceKickbackMutationHookResult = ReturnType<
  typeof useDeleteInvoiceKickbackMutation
>;
export type DeleteInvoiceKickbackMutationResult =
  Apollo.MutationResult<DeleteInvoiceKickbackMutation>;
export type DeleteInvoiceKickbackMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceKickbackMutation,
  DeleteInvoiceKickbackMutationVariables
>;
export const DeleteInvoiceKickbackCommentDocument = gql`
  mutation DeleteInvoiceKickbackComment($id: ID!) {
    deleteInvoiceKickbackComment(id: $id) {
      ...InvoiceKickbackFields
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type DeleteInvoiceKickbackCommentMutationFn = Apollo.MutationFunction<
  DeleteInvoiceKickbackCommentMutation,
  DeleteInvoiceKickbackCommentMutationVariables
>;

/**
 * __useDeleteInvoiceKickbackCommentMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceKickbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceKickbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceKickbackCommentMutation, { data, loading, error }] = useDeleteInvoiceKickbackCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceKickbackCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceKickbackCommentMutation,
    DeleteInvoiceKickbackCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceKickbackCommentMutation,
    DeleteInvoiceKickbackCommentMutationVariables
  >(DeleteInvoiceKickbackCommentDocument, options);
}
export type DeleteInvoiceKickbackCommentMutationHookResult = ReturnType<
  typeof useDeleteInvoiceKickbackCommentMutation
>;
export type DeleteInvoiceKickbackCommentMutationResult =
  Apollo.MutationResult<DeleteInvoiceKickbackCommentMutation>;
export type DeleteInvoiceKickbackCommentMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteInvoiceKickbackCommentMutation,
    DeleteInvoiceKickbackCommentMutationVariables
  >;
export const DownloadInvoiceDocumentDocument = gql`
  mutation DownloadInvoiceDocument($input: DownloadInvoiceDocumentInput!) {
    downloadInvoiceDocument(input: $input)
  }
`;
export type DownloadInvoiceDocumentMutationFn = Apollo.MutationFunction<
  DownloadInvoiceDocumentMutation,
  DownloadInvoiceDocumentMutationVariables
>;

/**
 * __useDownloadInvoiceDocumentMutation__
 *
 * To run a mutation, you first call `useDownloadInvoiceDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoiceDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoiceDocumentMutation, { data, loading, error }] = useDownloadInvoiceDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadInvoiceDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadInvoiceDocumentMutation,
    DownloadInvoiceDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DownloadInvoiceDocumentMutation,
    DownloadInvoiceDocumentMutationVariables
  >(DownloadInvoiceDocumentDocument, options);
}
export type DownloadInvoiceDocumentMutationHookResult = ReturnType<
  typeof useDownloadInvoiceDocumentMutation
>;
export type DownloadInvoiceDocumentMutationResult =
  Apollo.MutationResult<DownloadInvoiceDocumentMutation>;
export type DownloadInvoiceDocumentMutationOptions = Apollo.BaseMutationOptions<
  DownloadInvoiceDocumentMutation,
  DownloadInvoiceDocumentMutationVariables
>;
export const DownloadInvoiceDocumentsDocument = gql`
  mutation DownloadInvoiceDocuments($input: DownloadInvoiceDocumentsInput!) {
    downloadInvoiceDocuments(input: $input)
  }
`;
export type DownloadInvoiceDocumentsMutationFn = Apollo.MutationFunction<
  DownloadInvoiceDocumentsMutation,
  DownloadInvoiceDocumentsMutationVariables
>;

/**
 * __useDownloadInvoiceDocumentsMutation__
 *
 * To run a mutation, you first call `useDownloadInvoiceDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoiceDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoiceDocumentsMutation, { data, loading, error }] = useDownloadInvoiceDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadInvoiceDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadInvoiceDocumentsMutation,
    DownloadInvoiceDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DownloadInvoiceDocumentsMutation,
    DownloadInvoiceDocumentsMutationVariables
  >(DownloadInvoiceDocumentsDocument, options);
}
export type DownloadInvoiceDocumentsMutationHookResult = ReturnType<
  typeof useDownloadInvoiceDocumentsMutation
>;
export type DownloadInvoiceDocumentsMutationResult =
  Apollo.MutationResult<DownloadInvoiceDocumentsMutation>;
export type DownloadInvoiceDocumentsMutationOptions =
  Apollo.BaseMutationOptions<
    DownloadInvoiceDocumentsMutation,
    DownloadInvoiceDocumentsMutationVariables
  >;
export const RescanInvoiceDocument = gql`
  mutation RescanInvoice($invoiceId: ID!) {
    rescanInvoice(invoiceId: $invoiceId)
  }
`;
export type RescanInvoiceMutationFn = Apollo.MutationFunction<
  RescanInvoiceMutation,
  RescanInvoiceMutationVariables
>;

/**
 * __useRescanInvoiceMutation__
 *
 * To run a mutation, you first call `useRescanInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescanInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescanInvoiceMutation, { data, loading, error }] = useRescanInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useRescanInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescanInvoiceMutation,
    RescanInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescanInvoiceMutation,
    RescanInvoiceMutationVariables
  >(RescanInvoiceDocument, options);
}
export type RescanInvoiceMutationHookResult = ReturnType<
  typeof useRescanInvoiceMutation
>;
export type RescanInvoiceMutationResult =
  Apollo.MutationResult<RescanInvoiceMutation>;
export type RescanInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RescanInvoiceMutation,
  RescanInvoiceMutationVariables
>;
export const ResolveInvoiceKickbackDocument = gql`
  mutation ResolveInvoiceKickback($input: ResolveInvoiceKickbackInput!) {
    resolveInvoiceKickback(input: $input) {
      id
      status
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type ResolveInvoiceKickbackMutationFn = Apollo.MutationFunction<
  ResolveInvoiceKickbackMutation,
  ResolveInvoiceKickbackMutationVariables
>;

/**
 * __useResolveInvoiceKickbackMutation__
 *
 * To run a mutation, you first call `useResolveInvoiceKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveInvoiceKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveInvoiceKickbackMutation, { data, loading, error }] = useResolveInvoiceKickbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveInvoiceKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveInvoiceKickbackMutation,
    ResolveInvoiceKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResolveInvoiceKickbackMutation,
    ResolveInvoiceKickbackMutationVariables
  >(ResolveInvoiceKickbackDocument, options);
}
export type ResolveInvoiceKickbackMutationHookResult = ReturnType<
  typeof useResolveInvoiceKickbackMutation
>;
export type ResolveInvoiceKickbackMutationResult =
  Apollo.MutationResult<ResolveInvoiceKickbackMutation>;
export type ResolveInvoiceKickbackMutationOptions = Apollo.BaseMutationOptions<
  ResolveInvoiceKickbackMutation,
  ResolveInvoiceKickbackMutationVariables
>;
export const UnarchiveInvoicesDocument = gql`
  mutation UnarchiveInvoices($invoiceIds: [ID!]!) {
    unarchiveInvoices(invoiceIds: $invoiceIds) {
      id
    }
  }
`;
export type UnarchiveInvoicesMutationFn = Apollo.MutationFunction<
  UnarchiveInvoicesMutation,
  UnarchiveInvoicesMutationVariables
>;

/**
 * __useUnarchiveInvoicesMutation__
 *
 * To run a mutation, you first call `useUnarchiveInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveInvoicesMutation, { data, loading, error }] = useUnarchiveInvoicesMutation({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useUnarchiveInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveInvoicesMutation,
    UnarchiveInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveInvoicesMutation,
    UnarchiveInvoicesMutationVariables
  >(UnarchiveInvoicesDocument, options);
}
export type UnarchiveInvoicesMutationHookResult = ReturnType<
  typeof useUnarchiveInvoicesMutation
>;
export type UnarchiveInvoicesMutationResult =
  Apollo.MutationResult<UnarchiveInvoicesMutation>;
export type UnarchiveInvoicesMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveInvoicesMutation,
  UnarchiveInvoicesMutationVariables
>;
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $includeDocuments: Boolean = false
  ) {
    updateInvoice(input: $input) {
      id
      number
      poNumber
      dueDate
      subtotal
      hasRelease
      chargesAmount
      taxAmount
      total
      status
      orgDuplicate
      vendorDuplicate
      issueDate
      poExists
      retentionPercentage
      retentionAmount
      discountDate
      description
      notes
      discountPercentage
      discountAmount
      discountedTotal
      headerOnly
      sellerOrgLocation {
        id
        address {
          ...AddressFields
        }
        org {
          id
          photoUrl
          name
        }
      }
      project {
        id
        name
        address {
          ...AddressFields
        }
      }
      accountingDate
      paymentMethod {
        ...PaymentMethodFields
      }
      release {
        id
        sequenceNumber
        poNumber
        version
        status
        preferredVendor {
          id
          externalCode
          contacts {
            id
            name
            cellPhone
            email
            receivesOrderNotifications
          }
        }
        sellerOrgLocation {
          id
          address {
            ...AddressFields
          }
          org {
            id
            photoUrl
            name
          }
        }
        paymentTerm
        project {
          id
          name
          address {
            ...AddressFields
          }
          location {
            id
            name
            address {
              ...AddressFields
            }
          }
          tags {
            ...TagFields
          }
        }
        permissions {
          edit
        }
        itemCount
      }
      link {
        ...InvoiceLinkFields
      }
      permissions {
        edit
        approve
        respond
        export
      }
      documents @include(if: $includeDocuments) {
        ...InvoiceDocumentFields
      }
      duplicates {
        id
        total
        number
        status
        documents {
          id
          asset {
            ...AssetFields
          }
        }
        issueDate
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${PaymentMethodFieldsFragmentDoc}
  ${TagFieldsFragmentDoc}
  ${InvoiceLinkFieldsFragmentDoc}
  ${InvoiceDocumentFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeDocuments: // value for 'includeDocuments'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceMutation,
    UpdateInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceMutation,
    UpdateInvoiceMutationVariables
  >(UpdateInvoiceDocument, options);
}
export type UpdateInvoiceMutationHookResult = ReturnType<
  typeof useUpdateInvoiceMutation
>;
export type UpdateInvoiceMutationResult =
  Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const UpdateInvoiceIssueDocument = gql`
  mutation UpdateInvoiceIssue($input: UpdateInvoiceIssueInput!) {
    updateInvoiceIssue(input: $input) {
      id
      issues {
        ...InvoiceIssueFields
      }
    }
  }
  ${InvoiceIssueFieldsFragmentDoc}
`;
export type UpdateInvoiceIssueMutationFn = Apollo.MutationFunction<
  UpdateInvoiceIssueMutation,
  UpdateInvoiceIssueMutationVariables
>;

/**
 * __useUpdateInvoiceIssueMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceIssueMutation, { data, loading, error }] = useUpdateInvoiceIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceIssueMutation,
    UpdateInvoiceIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceIssueMutation,
    UpdateInvoiceIssueMutationVariables
  >(UpdateInvoiceIssueDocument, options);
}
export type UpdateInvoiceIssueMutationHookResult = ReturnType<
  typeof useUpdateInvoiceIssueMutation
>;
export type UpdateInvoiceIssueMutationResult =
  Apollo.MutationResult<UpdateInvoiceIssueMutation>;
export type UpdateInvoiceIssueMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceIssueMutation,
  UpdateInvoiceIssueMutationVariables
>;
export const UpdateInvoiceKickbackDocument = gql`
  mutation UpdateInvoiceKickback($input: UpdateInvoiceKickbackInput!) {
    updateInvoiceKickback(input: $input) {
      id
      status
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type UpdateInvoiceKickbackMutationFn = Apollo.MutationFunction<
  UpdateInvoiceKickbackMutation,
  UpdateInvoiceKickbackMutationVariables
>;

/**
 * __useUpdateInvoiceKickbackMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceKickbackMutation, { data, loading, error }] = useUpdateInvoiceKickbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceKickbackMutation,
    UpdateInvoiceKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceKickbackMutation,
    UpdateInvoiceKickbackMutationVariables
  >(UpdateInvoiceKickbackDocument, options);
}
export type UpdateInvoiceKickbackMutationHookResult = ReturnType<
  typeof useUpdateInvoiceKickbackMutation
>;
export type UpdateInvoiceKickbackMutationResult =
  Apollo.MutationResult<UpdateInvoiceKickbackMutation>;
export type UpdateInvoiceKickbackMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceKickbackMutation,
  UpdateInvoiceKickbackMutationVariables
>;
export const UpdateInvoiceKickbackCommentDocument = gql`
  mutation UpdateInvoiceKickbackComment(
    $input: UpdateInvoiceKickbackCommentInput!
  ) {
    updateInvoiceKickbackComment(input: $input) {
      ...InvoiceKickbackFields
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type UpdateInvoiceKickbackCommentMutationFn = Apollo.MutationFunction<
  UpdateInvoiceKickbackCommentMutation,
  UpdateInvoiceKickbackCommentMutationVariables
>;

/**
 * __useUpdateInvoiceKickbackCommentMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceKickbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceKickbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceKickbackCommentMutation, { data, loading, error }] = useUpdateInvoiceKickbackCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceKickbackCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceKickbackCommentMutation,
    UpdateInvoiceKickbackCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceKickbackCommentMutation,
    UpdateInvoiceKickbackCommentMutationVariables
  >(UpdateInvoiceKickbackCommentDocument, options);
}
export type UpdateInvoiceKickbackCommentMutationHookResult = ReturnType<
  typeof useUpdateInvoiceKickbackCommentMutation
>;
export type UpdateInvoiceKickbackCommentMutationResult =
  Apollo.MutationResult<UpdateInvoiceKickbackCommentMutation>;
export type UpdateInvoiceKickbackCommentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateInvoiceKickbackCommentMutation,
    UpdateInvoiceKickbackCommentMutationVariables
  >;
export const InvoiceDocument = gql`
  query Invoice($id: ID!) {
    invoice(id: $id) {
      ...InvoiceFields
    }
  }
  ${InvoiceFieldsFragmentDoc}
`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables> &
    ({ variables: InvoiceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options,
  );
}
export function useInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceQuery,
    InvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options,
  );
}
export function useInvoiceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options,
  );
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceSuspenseQueryHookResult = ReturnType<
  typeof useInvoiceSuspenseQuery
>;
export type InvoiceQueryResult = Apollo.QueryResult<
  InvoiceQuery,
  InvoiceQueryVariables
>;
export const InvoiceKickbackEligibleUsersDocument = gql`
  query InvoiceKickbackEligibleUsers($id: ID!) {
    invoice(id: $id) {
      id
      kickbackEligibleUsers {
        ...UsersUserFields
      }
    }
  }
  ${UsersUserFieldsFragmentDoc}
`;

/**
 * __useInvoiceKickbackEligibleUsersQuery__
 *
 * To run a query within a React component, call `useInvoiceKickbackEligibleUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceKickbackEligibleUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceKickbackEligibleUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceKickbackEligibleUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoiceKickbackEligibleUsersQuery,
    InvoiceKickbackEligibleUsersQueryVariables
  > &
    (
      | {
          variables: InvoiceKickbackEligibleUsersQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvoiceKickbackEligibleUsersQuery,
    InvoiceKickbackEligibleUsersQueryVariables
  >(InvoiceKickbackEligibleUsersDocument, options);
}
export function useInvoiceKickbackEligibleUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceKickbackEligibleUsersQuery,
    InvoiceKickbackEligibleUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvoiceKickbackEligibleUsersQuery,
    InvoiceKickbackEligibleUsersQueryVariables
  >(InvoiceKickbackEligibleUsersDocument, options);
}
export function useInvoiceKickbackEligibleUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvoiceKickbackEligibleUsersQuery,
        InvoiceKickbackEligibleUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvoiceKickbackEligibleUsersQuery,
    InvoiceKickbackEligibleUsersQueryVariables
  >(InvoiceKickbackEligibleUsersDocument, options);
}
export type InvoiceKickbackEligibleUsersQueryHookResult = ReturnType<
  typeof useInvoiceKickbackEligibleUsersQuery
>;
export type InvoiceKickbackEligibleUsersLazyQueryHookResult = ReturnType<
  typeof useInvoiceKickbackEligibleUsersLazyQuery
>;
export type InvoiceKickbackEligibleUsersSuspenseQueryHookResult = ReturnType<
  typeof useInvoiceKickbackEligibleUsersSuspenseQuery
>;
export type InvoiceKickbackEligibleUsersQueryResult = Apollo.QueryResult<
  InvoiceKickbackEligibleUsersQuery,
  InvoiceKickbackEligibleUsersQueryVariables
>;
export const InvoicePermissionsDocument = gql`
  query InvoicePermissions(
    $id: ID!
    $projectId: ID
    $includeApprove: Boolean!
  ) {
    invoice(id: $id) {
      id
      permissions {
        edit
        approve(projectId: $projectId) @include(if: $includeApprove)
        respond
        export
        archive
      }
    }
  }
`;

/**
 * __useInvoicePermissionsQuery__
 *
 * To run a query within a React component, call `useInvoicePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      includeApprove: // value for 'includeApprove'
 *   },
 * });
 */
export function useInvoicePermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoicePermissionsQuery,
    InvoicePermissionsQueryVariables
  > &
    (
      | { variables: InvoicePermissionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvoicePermissionsQuery,
    InvoicePermissionsQueryVariables
  >(InvoicePermissionsDocument, options);
}
export function useInvoicePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoicePermissionsQuery,
    InvoicePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvoicePermissionsQuery,
    InvoicePermissionsQueryVariables
  >(InvoicePermissionsDocument, options);
}
export function useInvoicePermissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvoicePermissionsQuery,
        InvoicePermissionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvoicePermissionsQuery,
    InvoicePermissionsQueryVariables
  >(InvoicePermissionsDocument, options);
}
export type InvoicePermissionsQueryHookResult = ReturnType<
  typeof useInvoicePermissionsQuery
>;
export type InvoicePermissionsLazyQueryHookResult = ReturnType<
  typeof useInvoicePermissionsLazyQuery
>;
export type InvoicePermissionsSuspenseQueryHookResult = ReturnType<
  typeof useInvoicePermissionsSuspenseQuery
>;
export type InvoicePermissionsQueryResult = Apollo.QueryResult<
  InvoicePermissionsQuery,
  InvoicePermissionsQueryVariables
>;
export const CreateInvoiceFolderDocument = gql`
  mutation CreateInvoiceFolder($input: CreateInvoiceFolderInput!) {
    createInvoiceFolder(input: $input) {
      ...InvoiceFolderFields
    }
  }
  ${InvoiceFolderFieldsFragmentDoc}
`;
export type CreateInvoiceFolderMutationFn = Apollo.MutationFunction<
  CreateInvoiceFolderMutation,
  CreateInvoiceFolderMutationVariables
>;

/**
 * __useCreateInvoiceFolderMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceFolderMutation, { data, loading, error }] = useCreateInvoiceFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceFolderMutation,
    CreateInvoiceFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceFolderMutation,
    CreateInvoiceFolderMutationVariables
  >(CreateInvoiceFolderDocument, options);
}
export type CreateInvoiceFolderMutationHookResult = ReturnType<
  typeof useCreateInvoiceFolderMutation
>;
export type CreateInvoiceFolderMutationResult =
  Apollo.MutationResult<CreateInvoiceFolderMutation>;
export type CreateInvoiceFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceFolderMutation,
  CreateInvoiceFolderMutationVariables
>;
export const DeleteInvoiceFolderDocument = gql`
  mutation DeleteInvoiceFolder($id: ID!) {
    deleteInvoiceFolder(id: $id) {
      id
    }
  }
`;
export type DeleteInvoiceFolderMutationFn = Apollo.MutationFunction<
  DeleteInvoiceFolderMutation,
  DeleteInvoiceFolderMutationVariables
>;

/**
 * __useDeleteInvoiceFolderMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceFolderMutation, { data, loading, error }] = useDeleteInvoiceFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceFolderMutation,
    DeleteInvoiceFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceFolderMutation,
    DeleteInvoiceFolderMutationVariables
  >(DeleteInvoiceFolderDocument, options);
}
export type DeleteInvoiceFolderMutationHookResult = ReturnType<
  typeof useDeleteInvoiceFolderMutation
>;
export type DeleteInvoiceFolderMutationResult =
  Apollo.MutationResult<DeleteInvoiceFolderMutation>;
export type DeleteInvoiceFolderMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceFolderMutation,
  DeleteInvoiceFolderMutationVariables
>;
export const UpdateInvoiceFolderDocument = gql`
  mutation UpdateInvoiceFolder($input: UpdateInvoiceFolderInput!) {
    updateInvoiceFolder(input: $input) {
      ...InvoiceFolderFields
    }
  }
  ${InvoiceFolderFieldsFragmentDoc}
`;
export type UpdateInvoiceFolderMutationFn = Apollo.MutationFunction<
  UpdateInvoiceFolderMutation,
  UpdateInvoiceFolderMutationVariables
>;

/**
 * __useUpdateInvoiceFolderMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceFolderMutation, { data, loading, error }] = useUpdateInvoiceFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceFolderMutation,
    UpdateInvoiceFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceFolderMutation,
    UpdateInvoiceFolderMutationVariables
  >(UpdateInvoiceFolderDocument, options);
}
export type UpdateInvoiceFolderMutationHookResult = ReturnType<
  typeof useUpdateInvoiceFolderMutation
>;
export type UpdateInvoiceFolderMutationResult =
  Apollo.MutationResult<UpdateInvoiceFolderMutation>;
export type UpdateInvoiceFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceFolderMutation,
  UpdateInvoiceFolderMutationVariables
>;
export const OrgFoldersDocument = gql`
  query OrgFolders {
    viewer {
      id
      org {
        id
        invoiceFolders {
          ...InvoiceFolderFields
        }
      }
    }
  }
  ${InvoiceFolderFieldsFragmentDoc}
`;

/**
 * __useOrgFoldersQuery__
 *
 * To run a query within a React component, call `useOrgFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgFoldersQuery,
    OrgFoldersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgFoldersQuery, OrgFoldersQueryVariables>(
    OrgFoldersDocument,
    options,
  );
}
export function useOrgFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgFoldersQuery,
    OrgFoldersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgFoldersQuery, OrgFoldersQueryVariables>(
    OrgFoldersDocument,
    options,
  );
}
export function useOrgFoldersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgFoldersQuery,
        OrgFoldersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrgFoldersQuery, OrgFoldersQueryVariables>(
    OrgFoldersDocument,
    options,
  );
}
export type OrgFoldersQueryHookResult = ReturnType<typeof useOrgFoldersQuery>;
export type OrgFoldersLazyQueryHookResult = ReturnType<
  typeof useOrgFoldersLazyQuery
>;
export type OrgFoldersSuspenseQueryHookResult = ReturnType<
  typeof useOrgFoldersSuspenseQuery
>;
export type OrgFoldersQueryResult = Apollo.QueryResult<
  OrgFoldersQuery,
  OrgFoldersQueryVariables
>;
export const ArchiveInvoiceDocument = gql`
  mutation ArchiveInvoice($id: ID!) {
    archiveInvoice(id: $id) {
      id
      archivedAt
      status
    }
  }
`;
export type ArchiveInvoiceMutationFn = Apollo.MutationFunction<
  ArchiveInvoiceMutation,
  ArchiveInvoiceMutationVariables
>;

/**
 * __useArchiveInvoiceMutation__
 *
 * To run a mutation, you first call `useArchiveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInvoiceMutation, { data, loading, error }] = useArchiveInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveInvoiceMutation,
    ArchiveInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveInvoiceMutation,
    ArchiveInvoiceMutationVariables
  >(ArchiveInvoiceDocument, options);
}
export type ArchiveInvoiceMutationHookResult = ReturnType<
  typeof useArchiveInvoiceMutation
>;
export type ArchiveInvoiceMutationResult =
  Apollo.MutationResult<ArchiveInvoiceMutation>;
export type ArchiveInvoiceMutationOptions = Apollo.BaseMutationOptions<
  ArchiveInvoiceMutation,
  ArchiveInvoiceMutationVariables
>;
export const ArchiveInvoicesDocument = gql`
  mutation ArchiveInvoices($ids: [ID!]!) {
    archiveInvoices(invoiceIds: $ids) {
      id
      archivedAt
      status
    }
  }
`;
export type ArchiveInvoicesMutationFn = Apollo.MutationFunction<
  ArchiveInvoicesMutation,
  ArchiveInvoicesMutationVariables
>;

/**
 * __useArchiveInvoicesMutation__
 *
 * To run a mutation, you first call `useArchiveInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInvoicesMutation, { data, loading, error }] = useArchiveInvoicesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveInvoicesMutation,
    ArchiveInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveInvoicesMutation,
    ArchiveInvoicesMutationVariables
  >(ArchiveInvoicesDocument, options);
}
export type ArchiveInvoicesMutationHookResult = ReturnType<
  typeof useArchiveInvoicesMutation
>;
export type ArchiveInvoicesMutationResult =
  Apollo.MutationResult<ArchiveInvoicesMutation>;
export type ArchiveInvoicesMutationOptions = Apollo.BaseMutationOptions<
  ArchiveInvoicesMutation,
  ArchiveInvoicesMutationVariables
>;
export const ClearPoNumberDocument = gql`
  mutation ClearPoNumber($invoiceId: ID!) {
    clearPoNumber(invoiceId: $invoiceId) {
      id
      poNumber
      release {
        id
        poNumber
      }
    }
  }
`;
export type ClearPoNumberMutationFn = Apollo.MutationFunction<
  ClearPoNumberMutation,
  ClearPoNumberMutationVariables
>;

/**
 * __useClearPoNumberMutation__
 *
 * To run a mutation, you first call `useClearPoNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPoNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPoNumberMutation, { data, loading, error }] = useClearPoNumberMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useClearPoNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearPoNumberMutation,
    ClearPoNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearPoNumberMutation,
    ClearPoNumberMutationVariables
  >(ClearPoNumberDocument, options);
}
export type ClearPoNumberMutationHookResult = ReturnType<
  typeof useClearPoNumberMutation
>;
export type ClearPoNumberMutationResult =
  Apollo.MutationResult<ClearPoNumberMutation>;
export type ClearPoNumberMutationOptions = Apollo.BaseMutationOptions<
  ClearPoNumberMutation,
  ClearPoNumberMutationVariables
>;
export const CreateInvoicesDocument = gql`
  mutation CreateInvoices($input: CreateInvoicesInput!) {
    createInvoices(input: $input) {
      ...InvoiceFields
    }
  }
  ${InvoiceFieldsFragmentDoc}
`;
export type CreateInvoicesMutationFn = Apollo.MutationFunction<
  CreateInvoicesMutation,
  CreateInvoicesMutationVariables
>;

/**
 * __useCreateInvoicesMutation__
 *
 * To run a mutation, you first call `useCreateInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoicesMutation, { data, loading, error }] = useCreateInvoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoicesMutation,
    CreateInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoicesMutation,
    CreateInvoicesMutationVariables
  >(CreateInvoicesDocument, options);
}
export type CreateInvoicesMutationHookResult = ReturnType<
  typeof useCreateInvoicesMutation
>;
export type CreateInvoicesMutationResult =
  Apollo.MutationResult<CreateInvoicesMutation>;
export type CreateInvoicesMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoicesMutation,
  CreateInvoicesMutationVariables
>;
export const ExportInvoicesDocument = gql`
  mutation ExportInvoices($input: ExportInvoicesInput!) {
    exportInvoices(input: $input) {
      fileURLs
    }
  }
`;
export type ExportInvoicesMutationFn = Apollo.MutationFunction<
  ExportInvoicesMutation,
  ExportInvoicesMutationVariables
>;

/**
 * __useExportInvoicesMutation__
 *
 * To run a mutation, you first call `useExportInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportInvoicesMutation, { data, loading, error }] = useExportInvoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportInvoicesMutation,
    ExportInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportInvoicesMutation,
    ExportInvoicesMutationVariables
  >(ExportInvoicesDocument, options);
}
export type ExportInvoicesMutationHookResult = ReturnType<
  typeof useExportInvoicesMutation
>;
export type ExportInvoicesMutationResult =
  Apollo.MutationResult<ExportInvoicesMutation>;
export type ExportInvoicesMutationOptions = Apollo.BaseMutationOptions<
  ExportInvoicesMutation,
  ExportInvoicesMutationVariables
>;
export const LinkInvoiceDocument = gql`
  mutation LinkInvoice($input: LinkInvoiceInput!) {
    linkInvoice(input: $input) {
      id
      link {
        ...InvoiceLinkFields
      }
    }
  }
  ${InvoiceLinkFieldsFragmentDoc}
`;
export type LinkInvoiceMutationFn = Apollo.MutationFunction<
  LinkInvoiceMutation,
  LinkInvoiceMutationVariables
>;

/**
 * __useLinkInvoiceMutation__
 *
 * To run a mutation, you first call `useLinkInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkInvoiceMutation, { data, loading, error }] = useLinkInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkInvoiceMutation,
    LinkInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkInvoiceMutation, LinkInvoiceMutationVariables>(
    LinkInvoiceDocument,
    options,
  );
}
export type LinkInvoiceMutationHookResult = ReturnType<
  typeof useLinkInvoiceMutation
>;
export type LinkInvoiceMutationResult =
  Apollo.MutationResult<LinkInvoiceMutation>;
export type LinkInvoiceMutationOptions = Apollo.BaseMutationOptions<
  LinkInvoiceMutation,
  LinkInvoiceMutationVariables
>;
export const SplitInvoiceDocumentDocument = gql`
  mutation SplitInvoiceDocument($input: SplitInvoiceDocumentInput!) {
    splitInvoiceDocument(input: $input) {
      ...PageRangeFields
    }
  }
  ${PageRangeFieldsFragmentDoc}
`;
export type SplitInvoiceDocumentMutationFn = Apollo.MutationFunction<
  SplitInvoiceDocumentMutation,
  SplitInvoiceDocumentMutationVariables
>;

/**
 * __useSplitInvoiceDocumentMutation__
 *
 * To run a mutation, you first call `useSplitInvoiceDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplitInvoiceDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitInvoiceDocumentMutation, { data, loading, error }] = useSplitInvoiceDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSplitInvoiceDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SplitInvoiceDocumentMutation,
    SplitInvoiceDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SplitInvoiceDocumentMutation,
    SplitInvoiceDocumentMutationVariables
  >(SplitInvoiceDocumentDocument, options);
}
export type SplitInvoiceDocumentMutationHookResult = ReturnType<
  typeof useSplitInvoiceDocumentMutation
>;
export type SplitInvoiceDocumentMutationResult =
  Apollo.MutationResult<SplitInvoiceDocumentMutation>;
export type SplitInvoiceDocumentMutationOptions = Apollo.BaseMutationOptions<
  SplitInvoiceDocumentMutation,
  SplitInvoiceDocumentMutationVariables
>;
export const UnlinkInvoiceDocument = gql`
  mutation UnlinkInvoice($invoiceLinkId: ID!) {
    unlinkInvoice(invoiceLinkId: $invoiceLinkId) {
      id
      link {
        ...InvoiceLinkFields
      }
    }
  }
  ${InvoiceLinkFieldsFragmentDoc}
`;
export type UnlinkInvoiceMutationFn = Apollo.MutationFunction<
  UnlinkInvoiceMutation,
  UnlinkInvoiceMutationVariables
>;

/**
 * __useUnlinkInvoiceMutation__
 *
 * To run a mutation, you first call `useUnlinkInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkInvoiceMutation, { data, loading, error }] = useUnlinkInvoiceMutation({
 *   variables: {
 *      invoiceLinkId: // value for 'invoiceLinkId'
 *   },
 * });
 */
export function useUnlinkInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkInvoiceMutation,
    UnlinkInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkInvoiceMutation,
    UnlinkInvoiceMutationVariables
  >(UnlinkInvoiceDocument, options);
}
export type UnlinkInvoiceMutationHookResult = ReturnType<
  typeof useUnlinkInvoiceMutation
>;
export type UnlinkInvoiceMutationResult =
  Apollo.MutationResult<UnlinkInvoiceMutation>;
export type UnlinkInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UnlinkInvoiceMutation,
  UnlinkInvoiceMutationVariables
>;
export const UpdateInvoiceEmailAttachmentDocument = gql`
  mutation UpdateInvoiceEmailAttachment(
    $input: UpdateInvoiceEmailAttachmentInput!
  ) {
    updateInvoiceEmailAttachment(input: $input) {
      id
    }
  }
`;
export type UpdateInvoiceEmailAttachmentMutationFn = Apollo.MutationFunction<
  UpdateInvoiceEmailAttachmentMutation,
  UpdateInvoiceEmailAttachmentMutationVariables
>;

/**
 * __useUpdateInvoiceEmailAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceEmailAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceEmailAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceEmailAttachmentMutation, { data, loading, error }] = useUpdateInvoiceEmailAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceEmailAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceEmailAttachmentMutation,
    UpdateInvoiceEmailAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceEmailAttachmentMutation,
    UpdateInvoiceEmailAttachmentMutationVariables
  >(UpdateInvoiceEmailAttachmentDocument, options);
}
export type UpdateInvoiceEmailAttachmentMutationHookResult = ReturnType<
  typeof useUpdateInvoiceEmailAttachmentMutation
>;
export type UpdateInvoiceEmailAttachmentMutationResult =
  Apollo.MutationResult<UpdateInvoiceEmailAttachmentMutation>;
export type UpdateInvoiceEmailAttachmentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateInvoiceEmailAttachmentMutation,
    UpdateInvoiceEmailAttachmentMutationVariables
  >;
export const UpdateInvoicesDocument = gql`
  mutation UpdateInvoices($input: UpdateInvoicesInput!) {
    updateInvoices(input: $input) {
      ...InvoiceSummaryFields
    }
  }
  ${InvoiceSummaryFieldsFragmentDoc}
`;
export type UpdateInvoicesMutationFn = Apollo.MutationFunction<
  UpdateInvoicesMutation,
  UpdateInvoicesMutationVariables
>;

/**
 * __useUpdateInvoicesMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicesMutation, { data, loading, error }] = useUpdateInvoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoicesMutation,
    UpdateInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoicesMutation,
    UpdateInvoicesMutationVariables
  >(UpdateInvoicesDocument, options);
}
export type UpdateInvoicesMutationHookResult = ReturnType<
  typeof useUpdateInvoicesMutation
>;
export type UpdateInvoicesMutationResult =
  Apollo.MutationResult<UpdateInvoicesMutation>;
export type UpdateInvoicesMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoicesMutation,
  UpdateInvoicesMutationVariables
>;
export const AssetDocument = gql`
  query Asset($url: String!) {
    asset(url: $url) {
      id
      isPrivate
      pages {
        url
        thumbnailUrl
      }
    }
  }
`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useAssetQuery(
  baseOptions: Apollo.QueryHookOptions<AssetQuery, AssetQueryVariables> &
    ({ variables: AssetQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    options,
  );
}
export function useAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssetQuery, AssetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    options,
  );
}
export function useAssetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AssetQuery, AssetQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    options,
  );
}
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetSuspenseQueryHookResult = ReturnType<
  typeof useAssetSuspenseQuery
>;
export type AssetQueryResult = Apollo.QueryResult<
  AssetQuery,
  AssetQueryVariables
>;
export const InvoiceStatsDocument = gql`
  query InvoiceStats {
    invoiceStats {
      emails {
        requireAttention
      }
      pendingReceipts
    }
  }
`;

/**
 * __useInvoiceStatsQuery__
 *
 * To run a query within a React component, call `useInvoiceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvoiceStatsQuery,
    InvoiceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceStatsQuery, InvoiceStatsQueryVariables>(
    InvoiceStatsDocument,
    options,
  );
}
export function useInvoiceStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceStatsQuery,
    InvoiceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceStatsQuery, InvoiceStatsQueryVariables>(
    InvoiceStatsDocument,
    options,
  );
}
export function useInvoiceStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvoiceStatsQuery,
        InvoiceStatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceStatsQuery, InvoiceStatsQueryVariables>(
    InvoiceStatsDocument,
    options,
  );
}
export type InvoiceStatsQueryHookResult = ReturnType<
  typeof useInvoiceStatsQuery
>;
export type InvoiceStatsLazyQueryHookResult = ReturnType<
  typeof useInvoiceStatsLazyQuery
>;
export type InvoiceStatsSuspenseQueryHookResult = ReturnType<
  typeof useInvoiceStatsSuspenseQuery
>;
export type InvoiceStatsQueryResult = Apollo.QueryResult<
  InvoiceStatsQuery,
  InvoiceStatsQueryVariables
>;
export const InvoiceValidationDocument = gql`
  query InvoiceValidation($input: LinkInvoiceInput!) {
    invoiceValidation(input: $input) {
      ...InvoiceValidationFields
    }
  }
  ${InvoiceValidationFieldsFragmentDoc}
`;

/**
 * __useInvoiceValidationQuery__
 *
 * To run a query within a React component, call `useInvoiceValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceValidationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoiceValidationQuery,
    InvoiceValidationQueryVariables
  > &
    (
      | { variables: InvoiceValidationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvoiceValidationQuery,
    InvoiceValidationQueryVariables
  >(InvoiceValidationDocument, options);
}
export function useInvoiceValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceValidationQuery,
    InvoiceValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvoiceValidationQuery,
    InvoiceValidationQueryVariables
  >(InvoiceValidationDocument, options);
}
export function useInvoiceValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvoiceValidationQuery,
        InvoiceValidationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvoiceValidationQuery,
    InvoiceValidationQueryVariables
  >(InvoiceValidationDocument, options);
}
export type InvoiceValidationQueryHookResult = ReturnType<
  typeof useInvoiceValidationQuery
>;
export type InvoiceValidationLazyQueryHookResult = ReturnType<
  typeof useInvoiceValidationLazyQuery
>;
export type InvoiceValidationSuspenseQueryHookResult = ReturnType<
  typeof useInvoiceValidationSuspenseQuery
>;
export type InvoiceValidationQueryResult = Apollo.QueryResult<
  InvoiceValidationQuery,
  InvoiceValidationQueryVariables
>;
export const InvoicesDocument = gql`
  query Invoices(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryInvoicesFilter
    $sort: QueryInvoicesSort
  ) {
    invoices(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...InvoiceSummaryFields
        }
      }
    }
  }
  ${InvoiceSummaryFieldsFragmentDoc}
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options,
  );
}
export function useInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options,
  );
}
export function useInvoicesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options,
  );
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<
  typeof useInvoicesLazyQuery
>;
export type InvoicesSuspenseQueryHookResult = ReturnType<
  typeof useInvoicesSuspenseQuery
>;
export type InvoicesQueryResult = Apollo.QueryResult<
  InvoicesQuery,
  InvoicesQueryVariables
>;
export const InvoicesByIdsDocument = gql`
  query InvoicesByIds(
    $filter: QueryInvoicesFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    invoices(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...InvoiceToExportFields
        }
      }
    }
  }
  ${InvoiceToExportFieldsFragmentDoc}
`;

/**
 * __useInvoicesByIdsQuery__
 *
 * To run a query within a React component, call `useInvoicesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesByIdsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useInvoicesByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvoicesByIdsQuery,
    InvoicesByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesByIdsQuery, InvoicesByIdsQueryVariables>(
    InvoicesByIdsDocument,
    options,
  );
}
export function useInvoicesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoicesByIdsQuery,
    InvoicesByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesByIdsQuery, InvoicesByIdsQueryVariables>(
    InvoicesByIdsDocument,
    options,
  );
}
export function useInvoicesByIdsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvoicesByIdsQuery,
        InvoicesByIdsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvoicesByIdsQuery,
    InvoicesByIdsQueryVariables
  >(InvoicesByIdsDocument, options);
}
export type InvoicesByIdsQueryHookResult = ReturnType<
  typeof useInvoicesByIdsQuery
>;
export type InvoicesByIdsLazyQueryHookResult = ReturnType<
  typeof useInvoicesByIdsLazyQuery
>;
export type InvoicesByIdsSuspenseQueryHookResult = ReturnType<
  typeof useInvoicesByIdsSuspenseQuery
>;
export type InvoicesByIdsQueryResult = Apollo.QueryResult<
  InvoicesByIdsQuery,
  InvoicesByIdsQueryVariables
>;
export const ConnectProjectDocument = gql`
  mutation ConnectProject($input: ConnectNodeInput!) {
    connectProject(input: $input) {
      id
      mappings {
        ...SourceSystemMappingFields
      }
      budgetLink {
        ...ProjectBudgetLinkFields
      }
      costCodes {
        ...CostCodeFields
      }
      allowance {
        ...ProjectAllowanceFields
      }
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
  ${ProjectBudgetLinkFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${ProjectAllowanceFieldsFragmentDoc}
`;
export type ConnectProjectMutationFn = Apollo.MutationFunction<
  ConnectProjectMutation,
  ConnectProjectMutationVariables
>;

/**
 * __useConnectProjectMutation__
 *
 * To run a mutation, you first call `useConnectProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectProjectMutation, { data, loading, error }] = useConnectProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectProjectMutation,
    ConnectProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectProjectMutation,
    ConnectProjectMutationVariables
  >(ConnectProjectDocument, options);
}
export type ConnectProjectMutationHookResult = ReturnType<
  typeof useConnectProjectMutation
>;
export type ConnectProjectMutationResult =
  Apollo.MutationResult<ConnectProjectMutation>;
export type ConnectProjectMutationOptions = Apollo.BaseMutationOptions<
  ConnectProjectMutation,
  ConnectProjectMutationVariables
>;
export const CopyEstimatedItemsDocument = gql`
  mutation CopyEstimatedItems($input: CopyEstimatedItemsInput!) {
    copyEstimatedItems(input: $input) {
      id
    }
  }
`;
export type CopyEstimatedItemsMutationFn = Apollo.MutationFunction<
  CopyEstimatedItemsMutation,
  CopyEstimatedItemsMutationVariables
>;

/**
 * __useCopyEstimatedItemsMutation__
 *
 * To run a mutation, you first call `useCopyEstimatedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEstimatedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEstimatedItemsMutation, { data, loading, error }] = useCopyEstimatedItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyEstimatedItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyEstimatedItemsMutation,
    CopyEstimatedItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CopyEstimatedItemsMutation,
    CopyEstimatedItemsMutationVariables
  >(CopyEstimatedItemsDocument, options);
}
export type CopyEstimatedItemsMutationHookResult = ReturnType<
  typeof useCopyEstimatedItemsMutation
>;
export type CopyEstimatedItemsMutationResult =
  Apollo.MutationResult<CopyEstimatedItemsMutation>;
export type CopyEstimatedItemsMutationOptions = Apollo.BaseMutationOptions<
  CopyEstimatedItemsMutation,
  CopyEstimatedItemsMutationVariables
>;
export const DisconnectProjectDocument = gql`
  mutation DisconnectProject($input: DisconnectNodeInput!) {
    disconnectProject(input: $input) {
      id
      mappings {
        ...SourceSystemMappingFields
      }
      externalProjects {
        ...ExternalProjectEssentialFields
      }
      costCodes {
        ...CostCodeFields
      }
    }
  }
  ${SourceSystemMappingFieldsFragmentDoc}
  ${ExternalProjectEssentialFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
`;
export type DisconnectProjectMutationFn = Apollo.MutationFunction<
  DisconnectProjectMutation,
  DisconnectProjectMutationVariables
>;

/**
 * __useDisconnectProjectMutation__
 *
 * To run a mutation, you first call `useDisconnectProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectProjectMutation, { data, loading, error }] = useDisconnectProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectProjectMutation,
    DisconnectProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectProjectMutation,
    DisconnectProjectMutationVariables
  >(DisconnectProjectDocument, options);
}
export type DisconnectProjectMutationHookResult = ReturnType<
  typeof useDisconnectProjectMutation
>;
export type DisconnectProjectMutationResult =
  Apollo.MutationResult<DisconnectProjectMutation>;
export type DisconnectProjectMutationOptions = Apollo.BaseMutationOptions<
  DisconnectProjectMutation,
  DisconnectProjectMutationVariables
>;
export const ImportEstimateDocument = gql`
  mutation ImportEstimate(
    $input: ImportEstimateInput!
    $excludePhantoms: Boolean = false
  ) {
    importEstimate(input: $input) {
      ...ProjectExtendedFields
    }
  }
  ${ProjectExtendedFieldsFragmentDoc}
`;
export type ImportEstimateMutationFn = Apollo.MutationFunction<
  ImportEstimateMutation,
  ImportEstimateMutationVariables
>;

/**
 * __useImportEstimateMutation__
 *
 * To run a mutation, you first call `useImportEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEstimateMutation, { data, loading, error }] = useImportEstimateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      excludePhantoms: // value for 'excludePhantoms'
 *   },
 * });
 */
export function useImportEstimateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportEstimateMutation,
    ImportEstimateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportEstimateMutation,
    ImportEstimateMutationVariables
  >(ImportEstimateDocument, options);
}
export type ImportEstimateMutationHookResult = ReturnType<
  typeof useImportEstimateMutation
>;
export type ImportEstimateMutationResult =
  Apollo.MutationResult<ImportEstimateMutation>;
export type ImportEstimateMutationOptions = Apollo.BaseMutationOptions<
  ImportEstimateMutation,
  ImportEstimateMutationVariables
>;
export const ImportProjectTagsDocument = gql`
  mutation ImportProjectTags($input: ImportProjectTagsInput!) {
    importProjectTags(input: $input) {
      id
      tags(deleted: false) {
        ...TagFields
      }
    }
  }
  ${TagFieldsFragmentDoc}
`;
export type ImportProjectTagsMutationFn = Apollo.MutationFunction<
  ImportProjectTagsMutation,
  ImportProjectTagsMutationVariables
>;

/**
 * __useImportProjectTagsMutation__
 *
 * To run a mutation, you first call `useImportProjectTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProjectTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProjectTagsMutation, { data, loading, error }] = useImportProjectTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportProjectTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportProjectTagsMutation,
    ImportProjectTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportProjectTagsMutation,
    ImportProjectTagsMutationVariables
  >(ImportProjectTagsDocument, options);
}
export type ImportProjectTagsMutationHookResult = ReturnType<
  typeof useImportProjectTagsMutation
>;
export type ImportProjectTagsMutationResult =
  Apollo.MutationResult<ImportProjectTagsMutation>;
export type ImportProjectTagsMutationOptions = Apollo.BaseMutationOptions<
  ImportProjectTagsMutation,
  ImportProjectTagsMutationVariables
>;
export const ImportZonesDocument = gql`
  mutation ImportZones($input: ImportZonesInput!) {
    importZones(input: $input) {
      id
      zones {
        ...ZoneFields
      }
    }
  }
  ${ZoneFieldsFragmentDoc}
`;
export type ImportZonesMutationFn = Apollo.MutationFunction<
  ImportZonesMutation,
  ImportZonesMutationVariables
>;

/**
 * __useImportZonesMutation__
 *
 * To run a mutation, you first call `useImportZonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportZonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importZonesMutation, { data, loading, error }] = useImportZonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportZonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportZonesMutation,
    ImportZonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportZonesMutation, ImportZonesMutationVariables>(
    ImportZonesDocument,
    options,
  );
}
export type ImportZonesMutationHookResult = ReturnType<
  typeof useImportZonesMutation
>;
export type ImportZonesMutationResult =
  Apollo.MutationResult<ImportZonesMutation>;
export type ImportZonesMutationOptions = Apollo.BaseMutationOptions<
  ImportZonesMutation,
  ImportZonesMutationVariables
>;
export const RefreshExternalProjectsDocument = gql`
  mutation RefreshExternalProjects($sourceSystem: SourceSystem!) {
    refreshExternalProjects(sourceSystem: $sourceSystem)
  }
`;
export type RefreshExternalProjectsMutationFn = Apollo.MutationFunction<
  RefreshExternalProjectsMutation,
  RefreshExternalProjectsMutationVariables
>;

/**
 * __useRefreshExternalProjectsMutation__
 *
 * To run a mutation, you first call `useRefreshExternalProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExternalProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExternalProjectsMutation, { data, loading, error }] = useRefreshExternalProjectsMutation({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useRefreshExternalProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshExternalProjectsMutation,
    RefreshExternalProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshExternalProjectsMutation,
    RefreshExternalProjectsMutationVariables
  >(RefreshExternalProjectsDocument, options);
}
export type RefreshExternalProjectsMutationHookResult = ReturnType<
  typeof useRefreshExternalProjectsMutation
>;
export type RefreshExternalProjectsMutationResult =
  Apollo.MutationResult<RefreshExternalProjectsMutation>;
export type RefreshExternalProjectsMutationOptions = Apollo.BaseMutationOptions<
  RefreshExternalProjectsMutation,
  RefreshExternalProjectsMutationVariables
>;
export const UpdateTagsDocument = gql`
  mutation UpdateTags($input: UpdateTagsInput!) {
    updateTags(input: $input) {
      ...TagFields
    }
  }
  ${TagFieldsFragmentDoc}
`;
export type UpdateTagsMutationFn = Apollo.MutationFunction<
  UpdateTagsMutation,
  UpdateTagsMutationVariables
>;

/**
 * __useUpdateTagsMutation__
 *
 * To run a mutation, you first call `useUpdateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagsMutation, { data, loading, error }] = useUpdateTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagsMutation,
    UpdateTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagsMutation, UpdateTagsMutationVariables>(
    UpdateTagsDocument,
    options,
  );
}
export type UpdateTagsMutationHookResult = ReturnType<
  typeof useUpdateTagsMutation
>;
export type UpdateTagsMutationResult =
  Apollo.MutationResult<UpdateTagsMutation>;
export type UpdateTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagsMutation,
  UpdateTagsMutationVariables
>;
export const LinkBudgetDocument = gql`
  mutation LinkBudget($input: LinkBudgetInput!) {
    linkBudget(input: $input) {
      ...ProjectBudgetLinkFields
      project {
        id
        allowance {
          ...ProjectAllowanceFields
        }
        costCodes {
          ...CostCodeFields
        }
        estimatedItemGroups {
          ...EstimatedItemGroupFields
        }
        usesProjectCostCodes
      }
    }
  }
  ${ProjectBudgetLinkFieldsFragmentDoc}
  ${ProjectAllowanceFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${EstimatedItemGroupFieldsFragmentDoc}
`;
export type LinkBudgetMutationFn = Apollo.MutationFunction<
  LinkBudgetMutation,
  LinkBudgetMutationVariables
>;

/**
 * __useLinkBudgetMutation__
 *
 * To run a mutation, you first call `useLinkBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkBudgetMutation, { data, loading, error }] = useLinkBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkBudgetMutation,
    LinkBudgetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkBudgetMutation, LinkBudgetMutationVariables>(
    LinkBudgetDocument,
    options,
  );
}
export type LinkBudgetMutationHookResult = ReturnType<
  typeof useLinkBudgetMutation
>;
export type LinkBudgetMutationResult =
  Apollo.MutationResult<LinkBudgetMutation>;
export type LinkBudgetMutationOptions = Apollo.BaseMutationOptions<
  LinkBudgetMutation,
  LinkBudgetMutationVariables
>;
export const SyncBudgetDocument = gql`
  mutation SyncBudget($budgetLinkId: ID!) {
    syncBudget(budgetLinkId: $budgetLinkId) {
      id
      allowance {
        ...ProjectAllowanceFields
      }
      budgetLink {
        ...ProjectBudgetLinkFields
      }
      costCodes {
        ...CostCodeFields
      }
    }
  }
  ${ProjectAllowanceFieldsFragmentDoc}
  ${ProjectBudgetLinkFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
`;
export type SyncBudgetMutationFn = Apollo.MutationFunction<
  SyncBudgetMutation,
  SyncBudgetMutationVariables
>;

/**
 * __useSyncBudgetMutation__
 *
 * To run a mutation, you first call `useSyncBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncBudgetMutation, { data, loading, error }] = useSyncBudgetMutation({
 *   variables: {
 *      budgetLinkId: // value for 'budgetLinkId'
 *   },
 * });
 */
export function useSyncBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncBudgetMutation,
    SyncBudgetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncBudgetMutation, SyncBudgetMutationVariables>(
    SyncBudgetDocument,
    options,
  );
}
export type SyncBudgetMutationHookResult = ReturnType<
  typeof useSyncBudgetMutation
>;
export type SyncBudgetMutationResult =
  Apollo.MutationResult<SyncBudgetMutation>;
export type SyncBudgetMutationOptions = Apollo.BaseMutationOptions<
  SyncBudgetMutation,
  SyncBudgetMutationVariables
>;
export const UnlinkBudgetDocument = gql`
  mutation UnlinkBudget($budgetLinkId: ID!) {
    unlinkBudget(budgetLinkId: $budgetLinkId) {
      id
      usesProjectCostCodes
      allowance {
        ...ProjectAllowanceFields
      }
      costCodes {
        ...CostCodeFields
      }
      estimatedItemGroups {
        ...EstimatedItemGroupFields
      }
    }
  }
  ${ProjectAllowanceFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${EstimatedItemGroupFieldsFragmentDoc}
`;
export type UnlinkBudgetMutationFn = Apollo.MutationFunction<
  UnlinkBudgetMutation,
  UnlinkBudgetMutationVariables
>;

/**
 * __useUnlinkBudgetMutation__
 *
 * To run a mutation, you first call `useUnlinkBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkBudgetMutation, { data, loading, error }] = useUnlinkBudgetMutation({
 *   variables: {
 *      budgetLinkId: // value for 'budgetLinkId'
 *   },
 * });
 */
export function useUnlinkBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkBudgetMutation,
    UnlinkBudgetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkBudgetMutation,
    UnlinkBudgetMutationVariables
  >(UnlinkBudgetDocument, options);
}
export type UnlinkBudgetMutationHookResult = ReturnType<
  typeof useUnlinkBudgetMutation
>;
export type UnlinkBudgetMutationResult =
  Apollo.MutationResult<UnlinkBudgetMutation>;
export type UnlinkBudgetMutationOptions = Apollo.BaseMutationOptions<
  UnlinkBudgetMutation,
  UnlinkBudgetMutationVariables
>;
export const UpdateBudgetLinkDocument = gql`
  mutation UpdateBudgetLink($input: UpdateBudgetLinkInput!) {
    updateBudgetLink(input: $input) {
      ...ProjectBudgetLinkFields
      project {
        id
        allowance {
          ...ProjectAllowanceFields
        }
        costCodes {
          ...CostCodeFields
        }
        usesProjectCostCodes
      }
    }
  }
  ${ProjectBudgetLinkFieldsFragmentDoc}
  ${ProjectAllowanceFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
`;
export type UpdateBudgetLinkMutationFn = Apollo.MutationFunction<
  UpdateBudgetLinkMutation,
  UpdateBudgetLinkMutationVariables
>;

/**
 * __useUpdateBudgetLinkMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetLinkMutation, { data, loading, error }] = useUpdateBudgetLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBudgetLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBudgetLinkMutation,
    UpdateBudgetLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBudgetLinkMutation,
    UpdateBudgetLinkMutationVariables
  >(UpdateBudgetLinkDocument, options);
}
export type UpdateBudgetLinkMutationHookResult = ReturnType<
  typeof useUpdateBudgetLinkMutation
>;
export type UpdateBudgetLinkMutationResult =
  Apollo.MutationResult<UpdateBudgetLinkMutation>;
export type UpdateBudgetLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateBudgetLinkMutation,
  UpdateBudgetLinkMutationVariables
>;
export const UpdateProjectAllowanceDocument = gql`
  mutation UpdateProjectAllowance($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      allowance {
        ...ProjectAllowanceFields
      }
      restrictCostCodes
    }
  }
  ${ProjectAllowanceFieldsFragmentDoc}
`;
export type UpdateProjectAllowanceMutationFn = Apollo.MutationFunction<
  UpdateProjectAllowanceMutation,
  UpdateProjectAllowanceMutationVariables
>;

/**
 * __useUpdateProjectAllowanceMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAllowanceMutation, { data, loading, error }] = useUpdateProjectAllowanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectAllowanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectAllowanceMutation,
    UpdateProjectAllowanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectAllowanceMutation,
    UpdateProjectAllowanceMutationVariables
  >(UpdateProjectAllowanceDocument, options);
}
export type UpdateProjectAllowanceMutationHookResult = ReturnType<
  typeof useUpdateProjectAllowanceMutation
>;
export type UpdateProjectAllowanceMutationResult =
  Apollo.MutationResult<UpdateProjectAllowanceMutation>;
export type UpdateProjectAllowanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectAllowanceMutation,
  UpdateProjectAllowanceMutationVariables
>;
export const ProjectAllowanceDocument = gql`
  query ProjectAllowance($id: ID!) {
    project(id: $id) {
      id
      allowance {
        ...ProjectAllowanceFields
      }
      budgetLink {
        ...ProjectBudgetLinkFields
      }
    }
  }
  ${ProjectAllowanceFieldsFragmentDoc}
  ${ProjectBudgetLinkFieldsFragmentDoc}
`;

/**
 * __useProjectAllowanceQuery__
 *
 * To run a query within a React component, call `useProjectAllowanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAllowanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAllowanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectAllowanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectAllowanceQuery,
    ProjectAllowanceQueryVariables
  > &
    (
      | { variables: ProjectAllowanceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectAllowanceQuery, ProjectAllowanceQueryVariables>(
    ProjectAllowanceDocument,
    options,
  );
}
export function useProjectAllowanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectAllowanceQuery,
    ProjectAllowanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectAllowanceQuery,
    ProjectAllowanceQueryVariables
  >(ProjectAllowanceDocument, options);
}
export function useProjectAllowanceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectAllowanceQuery,
        ProjectAllowanceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectAllowanceQuery,
    ProjectAllowanceQueryVariables
  >(ProjectAllowanceDocument, options);
}
export type ProjectAllowanceQueryHookResult = ReturnType<
  typeof useProjectAllowanceQuery
>;
export type ProjectAllowanceLazyQueryHookResult = ReturnType<
  typeof useProjectAllowanceLazyQuery
>;
export type ProjectAllowanceSuspenseQueryHookResult = ReturnType<
  typeof useProjectAllowanceSuspenseQuery
>;
export type ProjectAllowanceQueryResult = Apollo.QueryResult<
  ProjectAllowanceQuery,
  ProjectAllowanceQueryVariables
>;
export const ProjectReportPerMaterialDocument = gql`
  query ProjectReportPerMaterial($input: ProjectReportPerMaterialInput) {
    projectReportPerMaterial(input: $input) {
      ...ProjectReportPerMaterialFields
    }
  }
  ${ProjectReportPerMaterialFieldsFragmentDoc}
`;

/**
 * __useProjectReportPerMaterialQuery__
 *
 * To run a query within a React component, call `useProjectReportPerMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReportPerMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReportPerMaterialQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectReportPerMaterialQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectReportPerMaterialQuery,
    ProjectReportPerMaterialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectReportPerMaterialQuery,
    ProjectReportPerMaterialQueryVariables
  >(ProjectReportPerMaterialDocument, options);
}
export function useProjectReportPerMaterialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectReportPerMaterialQuery,
    ProjectReportPerMaterialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectReportPerMaterialQuery,
    ProjectReportPerMaterialQueryVariables
  >(ProjectReportPerMaterialDocument, options);
}
export function useProjectReportPerMaterialSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectReportPerMaterialQuery,
        ProjectReportPerMaterialQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectReportPerMaterialQuery,
    ProjectReportPerMaterialQueryVariables
  >(ProjectReportPerMaterialDocument, options);
}
export type ProjectReportPerMaterialQueryHookResult = ReturnType<
  typeof useProjectReportPerMaterialQuery
>;
export type ProjectReportPerMaterialLazyQueryHookResult = ReturnType<
  typeof useProjectReportPerMaterialLazyQuery
>;
export type ProjectReportPerMaterialSuspenseQueryHookResult = ReturnType<
  typeof useProjectReportPerMaterialSuspenseQuery
>;
export type ProjectReportPerMaterialQueryResult = Apollo.QueryResult<
  ProjectReportPerMaterialQuery,
  ProjectReportPerMaterialQueryVariables
>;
export const ProjectReportPerMaterialZoneDocument = gql`
  query ProjectReportPerMaterialZone(
    $input: ProjectReportPerMaterialZoneInput
  ) {
    projectReportPerMaterialZone(input: $input) {
      ...ProjectReportPerMaterialZoneFields
    }
  }
  ${ProjectReportPerMaterialZoneFieldsFragmentDoc}
`;

/**
 * __useProjectReportPerMaterialZoneQuery__
 *
 * To run a query within a React component, call `useProjectReportPerMaterialZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReportPerMaterialZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReportPerMaterialZoneQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectReportPerMaterialZoneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectReportPerMaterialZoneQuery,
    ProjectReportPerMaterialZoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectReportPerMaterialZoneQuery,
    ProjectReportPerMaterialZoneQueryVariables
  >(ProjectReportPerMaterialZoneDocument, options);
}
export function useProjectReportPerMaterialZoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectReportPerMaterialZoneQuery,
    ProjectReportPerMaterialZoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectReportPerMaterialZoneQuery,
    ProjectReportPerMaterialZoneQueryVariables
  >(ProjectReportPerMaterialZoneDocument, options);
}
export function useProjectReportPerMaterialZoneSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectReportPerMaterialZoneQuery,
        ProjectReportPerMaterialZoneQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectReportPerMaterialZoneQuery,
    ProjectReportPerMaterialZoneQueryVariables
  >(ProjectReportPerMaterialZoneDocument, options);
}
export type ProjectReportPerMaterialZoneQueryHookResult = ReturnType<
  typeof useProjectReportPerMaterialZoneQuery
>;
export type ProjectReportPerMaterialZoneLazyQueryHookResult = ReturnType<
  typeof useProjectReportPerMaterialZoneLazyQuery
>;
export type ProjectReportPerMaterialZoneSuspenseQueryHookResult = ReturnType<
  typeof useProjectReportPerMaterialZoneSuspenseQuery
>;
export type ProjectReportPerMaterialZoneQueryResult = Apollo.QueryResult<
  ProjectReportPerMaterialZoneQuery,
  ProjectReportPerMaterialZoneQueryVariables
>;
export const ProjectReportPerVendorDocument = gql`
  query ProjectReportPerVendor($input: ProjectReportPerVendorInput) {
    projectReportPerVendor(input: $input) {
      ...ProjectReportPerVendorFields
    }
  }
  ${ProjectReportPerVendorFieldsFragmentDoc}
`;

/**
 * __useProjectReportPerVendorQuery__
 *
 * To run a query within a React component, call `useProjectReportPerVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReportPerVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReportPerVendorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectReportPerVendorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectReportPerVendorQuery,
    ProjectReportPerVendorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectReportPerVendorQuery,
    ProjectReportPerVendorQueryVariables
  >(ProjectReportPerVendorDocument, options);
}
export function useProjectReportPerVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectReportPerVendorQuery,
    ProjectReportPerVendorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectReportPerVendorQuery,
    ProjectReportPerVendorQueryVariables
  >(ProjectReportPerVendorDocument, options);
}
export function useProjectReportPerVendorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectReportPerVendorQuery,
        ProjectReportPerVendorQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectReportPerVendorQuery,
    ProjectReportPerVendorQueryVariables
  >(ProjectReportPerVendorDocument, options);
}
export type ProjectReportPerVendorQueryHookResult = ReturnType<
  typeof useProjectReportPerVendorQuery
>;
export type ProjectReportPerVendorLazyQueryHookResult = ReturnType<
  typeof useProjectReportPerVendorLazyQuery
>;
export type ProjectReportPerVendorSuspenseQueryHookResult = ReturnType<
  typeof useProjectReportPerVendorSuspenseQuery
>;
export type ProjectReportPerVendorQueryResult = Apollo.QueryResult<
  ProjectReportPerVendorQuery,
  ProjectReportPerVendorQueryVariables
>;
export const ProjectReportReleasesDocument = gql`
  query ProjectReportReleases($input: ProjectReportReleasesInput) {
    projectReportReleases(input: $input) {
      ...ProjectReportReleasesFields
    }
  }
  ${ProjectReportReleasesFieldsFragmentDoc}
`;

/**
 * __useProjectReportReleasesQuery__
 *
 * To run a query within a React component, call `useProjectReportReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReportReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReportReleasesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectReportReleasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectReportReleasesQuery,
    ProjectReportReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectReportReleasesQuery,
    ProjectReportReleasesQueryVariables
  >(ProjectReportReleasesDocument, options);
}
export function useProjectReportReleasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectReportReleasesQuery,
    ProjectReportReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectReportReleasesQuery,
    ProjectReportReleasesQueryVariables
  >(ProjectReportReleasesDocument, options);
}
export function useProjectReportReleasesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectReportReleasesQuery,
        ProjectReportReleasesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectReportReleasesQuery,
    ProjectReportReleasesQueryVariables
  >(ProjectReportReleasesDocument, options);
}
export type ProjectReportReleasesQueryHookResult = ReturnType<
  typeof useProjectReportReleasesQuery
>;
export type ProjectReportReleasesLazyQueryHookResult = ReturnType<
  typeof useProjectReportReleasesLazyQuery
>;
export type ProjectReportReleasesSuspenseQueryHookResult = ReturnType<
  typeof useProjectReportReleasesSuspenseQuery
>;
export type ProjectReportReleasesQueryResult = Apollo.QueryResult<
  ProjectReportReleasesQuery,
  ProjectReportReleasesQueryVariables
>;
export const ExternalProjectDocument = gql`
  query ExternalProject($input: ExternalIdQueryInput!) {
    externalProject(input: $input) {
      ...ExternalProjectFields
    }
  }
  ${ExternalProjectFieldsFragmentDoc}
`;

/**
 * __useExternalProjectQuery__
 *
 * To run a query within a React component, call `useExternalProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalProjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalProjectQuery,
    ExternalProjectQueryVariables
  > &
    (
      | { variables: ExternalProjectQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalProjectQuery, ExternalProjectQueryVariables>(
    ExternalProjectDocument,
    options,
  );
}
export function useExternalProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalProjectQuery,
    ExternalProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalProjectQuery,
    ExternalProjectQueryVariables
  >(ExternalProjectDocument, options);
}
export function useExternalProjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalProjectQuery,
        ExternalProjectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalProjectQuery,
    ExternalProjectQueryVariables
  >(ExternalProjectDocument, options);
}
export type ExternalProjectQueryHookResult = ReturnType<
  typeof useExternalProjectQuery
>;
export type ExternalProjectLazyQueryHookResult = ReturnType<
  typeof useExternalProjectLazyQuery
>;
export type ExternalProjectSuspenseQueryHookResult = ReturnType<
  typeof useExternalProjectSuspenseQuery
>;
export type ExternalProjectQueryResult = Apollo.QueryResult<
  ExternalProjectQuery,
  ExternalProjectQueryVariables
>;
export const ExternalProjectsDocument = gql`
  query ExternalProjects(
    $sourceSystem: SourceSystem!
    $filter: QueryExternalProjectsFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    externalProjects(
      sourceSystem: $sourceSystem
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ExternalProjectFields
        }
      }
    }
  }
  ${ExternalProjectFieldsFragmentDoc}
`;

/**
 * __useExternalProjectsQuery__
 *
 * To run a query within a React component, call `useExternalProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalProjectsQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useExternalProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalProjectsQuery,
    ExternalProjectsQueryVariables
  > &
    (
      | { variables: ExternalProjectsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalProjectsQuery, ExternalProjectsQueryVariables>(
    ExternalProjectsDocument,
    options,
  );
}
export function useExternalProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalProjectsQuery,
    ExternalProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalProjectsQuery,
    ExternalProjectsQueryVariables
  >(ExternalProjectsDocument, options);
}
export function useExternalProjectsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalProjectsQuery,
        ExternalProjectsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalProjectsQuery,
    ExternalProjectsQueryVariables
  >(ExternalProjectsDocument, options);
}
export type ExternalProjectsQueryHookResult = ReturnType<
  typeof useExternalProjectsQuery
>;
export type ExternalProjectsLazyQueryHookResult = ReturnType<
  typeof useExternalProjectsLazyQuery
>;
export type ExternalProjectsSuspenseQueryHookResult = ReturnType<
  typeof useExternalProjectsSuspenseQuery
>;
export type ExternalProjectsQueryResult = Apollo.QueryResult<
  ExternalProjectsQuery,
  ExternalProjectsQueryVariables
>;
export const ProjectDocument = gql`
  query Project($id: ID!, $excludePhantoms: Boolean = false) {
    project(id: $id) {
      ...ProjectExtendedFields
    }
  }
  ${ProjectExtendedFieldsFragmentDoc}
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      excludePhantoms: // value for 'excludePhantoms'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables> &
    ({ variables: ProjectQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options,
  );
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectQuery,
    ProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options,
  );
}
export function useProjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectDocument,
    options,
  );
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectSuspenseQueryHookResult = ReturnType<
  typeof useProjectSuspenseQuery
>;
export type ProjectQueryResult = Apollo.QueryResult<
  ProjectQuery,
  ProjectQueryVariables
>;
export const ProjectStoreDocument = gql`
  query ProjectStore($id: ID!) {
    project(id: $id) {
      id
      zones {
        ...ZoneExtendedFields
      }
      tags(deleted: false) {
        ...TagExtendedFields
      }
      costCodes {
        ...CostCodeFields
      }
      location {
        ...OrgLocationFields
      }
      restrictCostCodes
      allowance {
        ...ProjectAllowanceFields
      }
      budgetLink {
        ...ProjectBudgetLinkFields
      }
      estimatedItemGroups {
        ...EstimatedItemGroupFields
      }
      usesProjectCostCodes
      releaseInstructions {
        ...InstructionFields
      }
    }
  }
  ${ZoneExtendedFieldsFragmentDoc}
  ${TagExtendedFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${ProjectAllowanceFieldsFragmentDoc}
  ${ProjectBudgetLinkFieldsFragmentDoc}
  ${EstimatedItemGroupFieldsFragmentDoc}
  ${InstructionFieldsFragmentDoc}
`;

/**
 * __useProjectStoreQuery__
 *
 * To run a query within a React component, call `useProjectStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectStoreQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectStoreQuery,
    ProjectStoreQueryVariables
  > &
    (
      | { variables: ProjectStoreQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectStoreQuery, ProjectStoreQueryVariables>(
    ProjectStoreDocument,
    options,
  );
}
export function useProjectStoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectStoreQuery,
    ProjectStoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectStoreQuery, ProjectStoreQueryVariables>(
    ProjectStoreDocument,
    options,
  );
}
export function useProjectStoreSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectStoreQuery,
        ProjectStoreQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectStoreQuery, ProjectStoreQueryVariables>(
    ProjectStoreDocument,
    options,
  );
}
export type ProjectStoreQueryHookResult = ReturnType<
  typeof useProjectStoreQuery
>;
export type ProjectStoreLazyQueryHookResult = ReturnType<
  typeof useProjectStoreLazyQuery
>;
export type ProjectStoreSuspenseQueryHookResult = ReturnType<
  typeof useProjectStoreSuspenseQuery
>;
export type ProjectStoreQueryResult = Apollo.QueryResult<
  ProjectStoreQuery,
  ProjectStoreQueryVariables
>;
export const ArchiveProjectsDocument = gql`
  mutation ArchiveProjects($ids: [ID!]!) {
    archiveProjects(ids: $ids) {
      id
      deletedAt
      status
    }
  }
`;
export type ArchiveProjectsMutationFn = Apollo.MutationFunction<
  ArchiveProjectsMutation,
  ArchiveProjectsMutationVariables
>;

/**
 * __useArchiveProjectsMutation__
 *
 * To run a mutation, you first call `useArchiveProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectsMutation, { data, loading, error }] = useArchiveProjectsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveProjectsMutation,
    ArchiveProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveProjectsMutation,
    ArchiveProjectsMutationVariables
  >(ArchiveProjectsDocument, options);
}
export type ArchiveProjectsMutationHookResult = ReturnType<
  typeof useArchiveProjectsMutation
>;
export type ArchiveProjectsMutationResult =
  Apollo.MutationResult<ArchiveProjectsMutation>;
export type ArchiveProjectsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveProjectsMutation,
  ArchiveProjectsMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createNewProject(input: $input) {
      ...ProjectListOptionFields
    }
  }
  ${ProjectListOptionFieldsFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateProjectsDocument = gql`
  mutation CreateProjects($input: CreateProjectsInput!) {
    createProjects(input: $input) {
      id
      name
    }
  }
`;
export type CreateProjectsMutationFn = Apollo.MutationFunction<
  CreateProjectsMutation,
  CreateProjectsMutationVariables
>;

/**
 * __useCreateProjectsMutation__
 *
 * To run a mutation, you first call `useCreateProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectsMutation, { data, loading, error }] = useCreateProjectsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectsMutation,
    CreateProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectsMutation,
    CreateProjectsMutationVariables
  >(CreateProjectsDocument, options);
}
export type CreateProjectsMutationHookResult = ReturnType<
  typeof useCreateProjectsMutation
>;
export type CreateProjectsMutationResult =
  Apollo.MutationResult<CreateProjectsMutation>;
export type CreateProjectsMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectsMutation,
  CreateProjectsMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation DeleteProject($id: ID!) {
    removeProject(id: $id)
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
      status
      budget
      startDate
      percentageOfBudgetSpent
      jobNumber
      taxExempt
      team {
        id
        firstName
        lastName
        email
        photoUrl
      }
      releaseInstructions {
        ...InstructionFields
      }
      address {
        ...AddressFields
      }
      location {
        ...OrgLocationFields
      }
      poJobNumber
      usesProjectCostCodes
      costCodes {
        ...CostCodeFields
      }
      allowance {
        ...ProjectAllowanceFields
      }
    }
  }
  ${InstructionFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${OrgLocationFieldsFragmentDoc}
  ${CostCodeFieldsFragmentDoc}
  ${ProjectAllowanceFieldsFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const ProjectEstimatedItemsCountDocument = gql`
  query ProjectEstimatedItemsCount($id: ID!) {
    project(id: $id) {
      id
      estimatedItemsCount
    }
  }
`;

/**
 * __useProjectEstimatedItemsCountQuery__
 *
 * To run a query within a React component, call `useProjectEstimatedItemsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectEstimatedItemsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectEstimatedItemsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectEstimatedItemsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectEstimatedItemsCountQuery,
    ProjectEstimatedItemsCountQueryVariables
  > &
    (
      | { variables: ProjectEstimatedItemsCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectEstimatedItemsCountQuery,
    ProjectEstimatedItemsCountQueryVariables
  >(ProjectEstimatedItemsCountDocument, options);
}
export function useProjectEstimatedItemsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectEstimatedItemsCountQuery,
    ProjectEstimatedItemsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectEstimatedItemsCountQuery,
    ProjectEstimatedItemsCountQueryVariables
  >(ProjectEstimatedItemsCountDocument, options);
}
export function useProjectEstimatedItemsCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectEstimatedItemsCountQuery,
        ProjectEstimatedItemsCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectEstimatedItemsCountQuery,
    ProjectEstimatedItemsCountQueryVariables
  >(ProjectEstimatedItemsCountDocument, options);
}
export type ProjectEstimatedItemsCountQueryHookResult = ReturnType<
  typeof useProjectEstimatedItemsCountQuery
>;
export type ProjectEstimatedItemsCountLazyQueryHookResult = ReturnType<
  typeof useProjectEstimatedItemsCountLazyQuery
>;
export type ProjectEstimatedItemsCountSuspenseQueryHookResult = ReturnType<
  typeof useProjectEstimatedItemsCountSuspenseQuery
>;
export type ProjectEstimatedItemsCountQueryResult = Apollo.QueryResult<
  ProjectEstimatedItemsCountQuery,
  ProjectEstimatedItemsCountQueryVariables
>;
export const ProjectsDocument = gql`
  query Projects(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryProjectsFilter
  ) {
    projects(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ProjectProjectsFields
        }
      }
    }
  }
  ${ProjectProjectsFieldsFragmentDoc}
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsQuery,
    ProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export function useProjectsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<
  typeof useProjectsLazyQuery
>;
export type ProjectsSuspenseQueryHookResult = ReturnType<
  typeof useProjectsSuspenseQuery
>;
export type ProjectsQueryResult = Apollo.QueryResult<
  ProjectsQuery,
  ProjectsQueryVariables
>;
export const ApproveReceiptDocument = gql`
  mutation ApproveReceipt($input: ApproveInvoiceInput!) {
    approveReceipt(input: $input) {
      ...ReceiptFields
    }
  }
  ${ReceiptFieldsFragmentDoc}
`;
export type ApproveReceiptMutationFn = Apollo.MutationFunction<
  ApproveReceiptMutation,
  ApproveReceiptMutationVariables
>;

/**
 * __useApproveReceiptMutation__
 *
 * To run a mutation, you first call `useApproveReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveReceiptMutation, { data, loading, error }] = useApproveReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveReceiptMutation,
    ApproveReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveReceiptMutation,
    ApproveReceiptMutationVariables
  >(ApproveReceiptDocument, options);
}
export type ApproveReceiptMutationHookResult = ReturnType<
  typeof useApproveReceiptMutation
>;
export type ApproveReceiptMutationResult =
  Apollo.MutationResult<ApproveReceiptMutation>;
export type ApproveReceiptMutationOptions = Apollo.BaseMutationOptions<
  ApproveReceiptMutation,
  ApproveReceiptMutationVariables
>;
export const ArchiveReceiptDocument = gql`
  mutation ArchiveReceipt($id: ID!) {
    archiveReceipt(id: $id) {
      id
      archivedAt
      status
    }
  }
`;
export type ArchiveReceiptMutationFn = Apollo.MutationFunction<
  ArchiveReceiptMutation,
  ArchiveReceiptMutationVariables
>;

/**
 * __useArchiveReceiptMutation__
 *
 * To run a mutation, you first call `useArchiveReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveReceiptMutation, { data, loading, error }] = useArchiveReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveReceiptMutation,
    ArchiveReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveReceiptMutation,
    ArchiveReceiptMutationVariables
  >(ArchiveReceiptDocument, options);
}
export type ArchiveReceiptMutationHookResult = ReturnType<
  typeof useArchiveReceiptMutation
>;
export type ArchiveReceiptMutationResult =
  Apollo.MutationResult<ArchiveReceiptMutation>;
export type ArchiveReceiptMutationOptions = Apollo.BaseMutationOptions<
  ArchiveReceiptMutation,
  ArchiveReceiptMutationVariables
>;
export const CreateReceiptDocument = gql`
  mutation CreateReceipt($input: CreateReceiptInput!) {
    createReceipt(input: $input) {
      ...ReceiptSummaryFields
    }
  }
  ${ReceiptSummaryFieldsFragmentDoc}
`;
export type CreateReceiptMutationFn = Apollo.MutationFunction<
  CreateReceiptMutation,
  CreateReceiptMutationVariables
>;

/**
 * __useCreateReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptMutation, { data, loading, error }] = useCreateReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReceiptMutation,
    CreateReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReceiptMutation,
    CreateReceiptMutationVariables
  >(CreateReceiptDocument, options);
}
export type CreateReceiptMutationHookResult = ReturnType<
  typeof useCreateReceiptMutation
>;
export type CreateReceiptMutationResult =
  Apollo.MutationResult<CreateReceiptMutation>;
export type CreateReceiptMutationOptions = Apollo.BaseMutationOptions<
  CreateReceiptMutation,
  CreateReceiptMutationVariables
>;
export const CreateReceiptKickbackDocument = gql`
  mutation CreateReceiptKickback($input: CreateReceiptKickbackInput!) {
    createReceiptKickback(input: $input) {
      id
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type CreateReceiptKickbackMutationFn = Apollo.MutationFunction<
  CreateReceiptKickbackMutation,
  CreateReceiptKickbackMutationVariables
>;

/**
 * __useCreateReceiptKickbackMutation__
 *
 * To run a mutation, you first call `useCreateReceiptKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptKickbackMutation, { data, loading, error }] = useCreateReceiptKickbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReceiptKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReceiptKickbackMutation,
    CreateReceiptKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReceiptKickbackMutation,
    CreateReceiptKickbackMutationVariables
  >(CreateReceiptKickbackDocument, options);
}
export type CreateReceiptKickbackMutationHookResult = ReturnType<
  typeof useCreateReceiptKickbackMutation
>;
export type CreateReceiptKickbackMutationResult =
  Apollo.MutationResult<CreateReceiptKickbackMutation>;
export type CreateReceiptKickbackMutationOptions = Apollo.BaseMutationOptions<
  CreateReceiptKickbackMutation,
  CreateReceiptKickbackMutationVariables
>;
export const DeleteReceiptKickbackDocument = gql`
  mutation DeleteReceiptKickback($id: ID!) {
    deleteReceiptKickback(id: $id) {
      id
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type DeleteReceiptKickbackMutationFn = Apollo.MutationFunction<
  DeleteReceiptKickbackMutation,
  DeleteReceiptKickbackMutationVariables
>;

/**
 * __useDeleteReceiptKickbackMutation__
 *
 * To run a mutation, you first call `useDeleteReceiptKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceiptKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceiptKickbackMutation, { data, loading, error }] = useDeleteReceiptKickbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReceiptKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReceiptKickbackMutation,
    DeleteReceiptKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReceiptKickbackMutation,
    DeleteReceiptKickbackMutationVariables
  >(DeleteReceiptKickbackDocument, options);
}
export type DeleteReceiptKickbackMutationHookResult = ReturnType<
  typeof useDeleteReceiptKickbackMutation
>;
export type DeleteReceiptKickbackMutationResult =
  Apollo.MutationResult<DeleteReceiptKickbackMutation>;
export type DeleteReceiptKickbackMutationOptions = Apollo.BaseMutationOptions<
  DeleteReceiptKickbackMutation,
  DeleteReceiptKickbackMutationVariables
>;
export const ExportReceiptsDocument = gql`
  mutation ExportReceipts($input: ExportInvoicesInput!) {
    exportReceipts(input: $input) {
      fileURLs
    }
  }
`;
export type ExportReceiptsMutationFn = Apollo.MutationFunction<
  ExportReceiptsMutation,
  ExportReceiptsMutationVariables
>;

/**
 * __useExportReceiptsMutation__
 *
 * To run a mutation, you first call `useExportReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportReceiptsMutation, { data, loading, error }] = useExportReceiptsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportReceiptsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportReceiptsMutation,
    ExportReceiptsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportReceiptsMutation,
    ExportReceiptsMutationVariables
  >(ExportReceiptsDocument, options);
}
export type ExportReceiptsMutationHookResult = ReturnType<
  typeof useExportReceiptsMutation
>;
export type ExportReceiptsMutationResult =
  Apollo.MutationResult<ExportReceiptsMutation>;
export type ExportReceiptsMutationOptions = Apollo.BaseMutationOptions<
  ExportReceiptsMutation,
  ExportReceiptsMutationVariables
>;
export const LinkReceiptDocument = gql`
  mutation LinkReceipt($input: LinkInvoiceInput!) {
    linkReceipt(input: $input) {
      id
      link {
        ...ReceiptLinkFields
      }
    }
  }
  ${ReceiptLinkFieldsFragmentDoc}
`;
export type LinkReceiptMutationFn = Apollo.MutationFunction<
  LinkReceiptMutation,
  LinkReceiptMutationVariables
>;

/**
 * __useLinkReceiptMutation__
 *
 * To run a mutation, you first call `useLinkReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkReceiptMutation, { data, loading, error }] = useLinkReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkReceiptMutation,
    LinkReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkReceiptMutation, LinkReceiptMutationVariables>(
    LinkReceiptDocument,
    options,
  );
}
export type LinkReceiptMutationHookResult = ReturnType<
  typeof useLinkReceiptMutation
>;
export type LinkReceiptMutationResult =
  Apollo.MutationResult<LinkReceiptMutation>;
export type LinkReceiptMutationOptions = Apollo.BaseMutationOptions<
  LinkReceiptMutation,
  LinkReceiptMutationVariables
>;
export const ResolveReceiptKickbackDocument = gql`
  mutation ResolveReceiptKickback($input: ResolveReceiptKickbackInput!) {
    resolveReceiptKickback(input: $input) {
      id
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type ResolveReceiptKickbackMutationFn = Apollo.MutationFunction<
  ResolveReceiptKickbackMutation,
  ResolveReceiptKickbackMutationVariables
>;

/**
 * __useResolveReceiptKickbackMutation__
 *
 * To run a mutation, you first call `useResolveReceiptKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveReceiptKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveReceiptKickbackMutation, { data, loading, error }] = useResolveReceiptKickbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveReceiptKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveReceiptKickbackMutation,
    ResolveReceiptKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResolveReceiptKickbackMutation,
    ResolveReceiptKickbackMutationVariables
  >(ResolveReceiptKickbackDocument, options);
}
export type ResolveReceiptKickbackMutationHookResult = ReturnType<
  typeof useResolveReceiptKickbackMutation
>;
export type ResolveReceiptKickbackMutationResult =
  Apollo.MutationResult<ResolveReceiptKickbackMutation>;
export type ResolveReceiptKickbackMutationOptions = Apollo.BaseMutationOptions<
  ResolveReceiptKickbackMutation,
  ResolveReceiptKickbackMutationVariables
>;
export const UpdateReceiptDocument = gql`
  mutation UpdateReceipt(
    $input: UpdateInvoiceInput!
    $includeDocuments: Boolean = false
  ) {
    updateReceipt(input: $input) {
      id
      number
      poNumber
      dueDate
      subtotal
      chargesAmount
      taxAmount
      total
      status
      issueDescription
      issueDate
      resolutionDescription
      rejectionReason
      issueResponseDate
      prePaid
      link {
        ...ReceiptLinkFields
      }
      permissions {
        edit
        approve
        respond
        export
      }
      documents @include(if: $includeDocuments) {
        ...ReceiptDocumentFields
      }
      equipmentItem {
        id
      }
      serviceCode {
        id
      }
      notes
      release {
        id
        status
      }
    }
  }
  ${ReceiptLinkFieldsFragmentDoc}
  ${ReceiptDocumentFieldsFragmentDoc}
`;
export type UpdateReceiptMutationFn = Apollo.MutationFunction<
  UpdateReceiptMutation,
  UpdateReceiptMutationVariables
>;

/**
 * __useUpdateReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceiptMutation, { data, loading, error }] = useUpdateReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeDocuments: // value for 'includeDocuments'
 *   },
 * });
 */
export function useUpdateReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReceiptMutation,
    UpdateReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReceiptMutation,
    UpdateReceiptMutationVariables
  >(UpdateReceiptDocument, options);
}
export type UpdateReceiptMutationHookResult = ReturnType<
  typeof useUpdateReceiptMutation
>;
export type UpdateReceiptMutationResult =
  Apollo.MutationResult<UpdateReceiptMutation>;
export type UpdateReceiptMutationOptions = Apollo.BaseMutationOptions<
  UpdateReceiptMutation,
  UpdateReceiptMutationVariables
>;
export const UpdateReceiptKickbackDocument = gql`
  mutation UpdateReceiptKickback($input: UpdateReceiptKickbackInput!) {
    updateReceiptKickback(input: $input) {
      id
      kickbacks {
        ...InvoiceKickbackFields
      }
    }
  }
  ${InvoiceKickbackFieldsFragmentDoc}
`;
export type UpdateReceiptKickbackMutationFn = Apollo.MutationFunction<
  UpdateReceiptKickbackMutation,
  UpdateReceiptKickbackMutationVariables
>;

/**
 * __useUpdateReceiptKickbackMutation__
 *
 * To run a mutation, you first call `useUpdateReceiptKickbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceiptKickbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceiptKickbackMutation, { data, loading, error }] = useUpdateReceiptKickbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReceiptKickbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReceiptKickbackMutation,
    UpdateReceiptKickbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReceiptKickbackMutation,
    UpdateReceiptKickbackMutationVariables
  >(UpdateReceiptKickbackDocument, options);
}
export type UpdateReceiptKickbackMutationHookResult = ReturnType<
  typeof useUpdateReceiptKickbackMutation
>;
export type UpdateReceiptKickbackMutationResult =
  Apollo.MutationResult<UpdateReceiptKickbackMutation>;
export type UpdateReceiptKickbackMutationOptions = Apollo.BaseMutationOptions<
  UpdateReceiptKickbackMutation,
  UpdateReceiptKickbackMutationVariables
>;
export const ReceiptDocument = gql`
  query Receipt($id: ID!) {
    receipt(id: $id) {
      ...ReceiptFields
    }
  }
  ${ReceiptFieldsFragmentDoc}
`;

/**
 * __useReceiptQuery__
 *
 * To run a query within a React component, call `useReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReceiptQuery(
  baseOptions: Apollo.QueryHookOptions<ReceiptQuery, ReceiptQueryVariables> &
    ({ variables: ReceiptQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceiptQuery, ReceiptQueryVariables>(
    ReceiptDocument,
    options,
  );
}
export function useReceiptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReceiptQuery,
    ReceiptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceiptQuery, ReceiptQueryVariables>(
    ReceiptDocument,
    options,
  );
}
export function useReceiptSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReceiptQuery, ReceiptQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReceiptQuery, ReceiptQueryVariables>(
    ReceiptDocument,
    options,
  );
}
export type ReceiptQueryHookResult = ReturnType<typeof useReceiptQuery>;
export type ReceiptLazyQueryHookResult = ReturnType<typeof useReceiptLazyQuery>;
export type ReceiptSuspenseQueryHookResult = ReturnType<
  typeof useReceiptSuspenseQuery
>;
export type ReceiptQueryResult = Apollo.QueryResult<
  ReceiptQuery,
  ReceiptQueryVariables
>;
export const ReceiptsDocument = gql`
  query Receipts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryInvoicesFilter
    $sort: QueryInvoicesSort
  ) {
    receipts(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ReceiptSummaryFields
        }
      }
    }
  }
  ${ReceiptSummaryFieldsFragmentDoc}
`;

/**
 * __useReceiptsQuery__
 *
 * To run a query within a React component, call `useReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceiptsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReceiptsQuery(
  baseOptions?: Apollo.QueryHookOptions<ReceiptsQuery, ReceiptsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceiptsQuery, ReceiptsQueryVariables>(
    ReceiptsDocument,
    options,
  );
}
export function useReceiptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReceiptsQuery,
    ReceiptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceiptsQuery, ReceiptsQueryVariables>(
    ReceiptsDocument,
    options,
  );
}
export function useReceiptsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReceiptsQuery, ReceiptsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReceiptsQuery, ReceiptsQueryVariables>(
    ReceiptsDocument,
    options,
  );
}
export type ReceiptsQueryHookResult = ReturnType<typeof useReceiptsQuery>;
export type ReceiptsLazyQueryHookResult = ReturnType<
  typeof useReceiptsLazyQuery
>;
export type ReceiptsSuspenseQueryHookResult = ReturnType<
  typeof useReceiptsSuspenseQuery
>;
export type ReceiptsQueryResult = Apollo.QueryResult<
  ReceiptsQuery,
  ReceiptsQueryVariables
>;
export const ReceiptsByIdsDocument = gql`
  query ReceiptsByIds(
    $filter: QueryInvoicesFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    receipts(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ReceiptToExportFields
        }
      }
    }
  }
  ${ReceiptToExportFieldsFragmentDoc}
`;

/**
 * __useReceiptsByIdsQuery__
 *
 * To run a query within a React component, call `useReceiptsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceiptsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceiptsByIdsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useReceiptsByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReceiptsByIdsQuery,
    ReceiptsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceiptsByIdsQuery, ReceiptsByIdsQueryVariables>(
    ReceiptsByIdsDocument,
    options,
  );
}
export function useReceiptsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReceiptsByIdsQuery,
    ReceiptsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceiptsByIdsQuery, ReceiptsByIdsQueryVariables>(
    ReceiptsByIdsDocument,
    options,
  );
}
export function useReceiptsByIdsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReceiptsByIdsQuery,
        ReceiptsByIdsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReceiptsByIdsQuery,
    ReceiptsByIdsQueryVariables
  >(ReceiptsByIdsDocument, options);
}
export type ReceiptsByIdsQueryHookResult = ReturnType<
  typeof useReceiptsByIdsQuery
>;
export type ReceiptsByIdsLazyQueryHookResult = ReturnType<
  typeof useReceiptsByIdsLazyQuery
>;
export type ReceiptsByIdsSuspenseQueryHookResult = ReturnType<
  typeof useReceiptsByIdsSuspenseQuery
>;
export type ReceiptsByIdsQueryResult = Apollo.QueryResult<
  ReceiptsByIdsQuery,
  ReceiptsByIdsQueryVariables
>;
export const CreateExternalBatchDocument = gql`
  mutation CreateExternalBatch($input: CreateExternalBatchInput!) {
    createExternalBatch(input: $input) {
      ...ExternalBatchFields
    }
  }
  ${ExternalBatchFieldsFragmentDoc}
`;
export type CreateExternalBatchMutationFn = Apollo.MutationFunction<
  CreateExternalBatchMutation,
  CreateExternalBatchMutationVariables
>;

/**
 * __useCreateExternalBatchMutation__
 *
 * To run a mutation, you first call `useCreateExternalBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalBatchMutation, { data, loading, error }] = useCreateExternalBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalBatchMutation,
    CreateExternalBatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalBatchMutation,
    CreateExternalBatchMutationVariables
  >(CreateExternalBatchDocument, options);
}
export type CreateExternalBatchMutationHookResult = ReturnType<
  typeof useCreateExternalBatchMutation
>;
export type CreateExternalBatchMutationResult =
  Apollo.MutationResult<CreateExternalBatchMutation>;
export type CreateExternalBatchMutationOptions = Apollo.BaseMutationOptions<
  CreateExternalBatchMutation,
  CreateExternalBatchMutationVariables
>;
export const CreateMaterialConversionsDocument = gql`
  mutation CreateMaterialConversions(
    $input: [CreateMaterialConversionInput!]!
  ) {
    createMaterialConversions(input: $input) {
      ...MaterialConversionFields
    }
  }
  ${MaterialConversionFieldsFragmentDoc}
`;
export type CreateMaterialConversionsMutationFn = Apollo.MutationFunction<
  CreateMaterialConversionsMutation,
  CreateMaterialConversionsMutationVariables
>;

/**
 * __useCreateMaterialConversionsMutation__
 *
 * To run a mutation, you first call `useCreateMaterialConversionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialConversionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialConversionsMutation, { data, loading, error }] = useCreateMaterialConversionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMaterialConversionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMaterialConversionsMutation,
    CreateMaterialConversionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMaterialConversionsMutation,
    CreateMaterialConversionsMutationVariables
  >(CreateMaterialConversionsDocument, options);
}
export type CreateMaterialConversionsMutationHookResult = ReturnType<
  typeof useCreateMaterialConversionsMutation
>;
export type CreateMaterialConversionsMutationResult =
  Apollo.MutationResult<CreateMaterialConversionsMutation>;
export type CreateMaterialConversionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMaterialConversionsMutation,
    CreateMaterialConversionsMutationVariables
  >;
export const LinkPoDocument = gql`
  mutation LinkPO($input: LinkPOInput!) {
    linkPO(input: $input) {
      ...POLinkFields
    }
  }
  ${PoLinkFieldsFragmentDoc}
`;
export type LinkPoMutationFn = Apollo.MutationFunction<
  LinkPoMutation,
  LinkPoMutationVariables
>;

/**
 * __useLinkPoMutation__
 *
 * To run a mutation, you first call `useLinkPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPoMutation, { data, loading, error }] = useLinkPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkPoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkPoMutation,
    LinkPoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkPoMutation, LinkPoMutationVariables>(
    LinkPoDocument,
    options,
  );
}
export type LinkPoMutationHookResult = ReturnType<typeof useLinkPoMutation>;
export type LinkPoMutationResult = Apollo.MutationResult<LinkPoMutation>;
export type LinkPoMutationOptions = Apollo.BaseMutationOptions<
  LinkPoMutation,
  LinkPoMutationVariables
>;
export const ReverseSyncPoDocument = gql`
  mutation ReverseSyncPO(
    $poLinkId: ID!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    reverseSyncPO(poLinkId: $poLinkId) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;
export type ReverseSyncPoMutationFn = Apollo.MutationFunction<
  ReverseSyncPoMutation,
  ReverseSyncPoMutationVariables
>;

/**
 * __useReverseSyncPoMutation__
 *
 * To run a mutation, you first call `useReverseSyncPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseSyncPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseSyncPoMutation, { data, loading, error }] = useReverseSyncPoMutation({
 *   variables: {
 *      poLinkId: // value for 'poLinkId'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useReverseSyncPoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReverseSyncPoMutation,
    ReverseSyncPoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReverseSyncPoMutation,
    ReverseSyncPoMutationVariables
  >(ReverseSyncPoDocument, options);
}
export type ReverseSyncPoMutationHookResult = ReturnType<
  typeof useReverseSyncPoMutation
>;
export type ReverseSyncPoMutationResult =
  Apollo.MutationResult<ReverseSyncPoMutation>;
export type ReverseSyncPoMutationOptions = Apollo.BaseMutationOptions<
  ReverseSyncPoMutation,
  ReverseSyncPoMutationVariables
>;
export const SyncPoDocument = gql`
  mutation SyncPO(
    $poLinkId: ID!
    $invoiceId: ID
    $invoiceStatuses: [InvoiceStatus!]
  ) {
    syncPO(poLinkId: $poLinkId) {
      ...ReleaseFields
    }
  }
  ${ReleaseFieldsFragmentDoc}
`;
export type SyncPoMutationFn = Apollo.MutationFunction<
  SyncPoMutation,
  SyncPoMutationVariables
>;

/**
 * __useSyncPoMutation__
 *
 * To run a mutation, you first call `useSyncPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPoMutation, { data, loading, error }] = useSyncPoMutation({
 *   variables: {
 *      poLinkId: // value for 'poLinkId'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceStatuses: // value for 'invoiceStatuses'
 *   },
 * });
 */
export function useSyncPoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncPoMutation,
    SyncPoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncPoMutation, SyncPoMutationVariables>(
    SyncPoDocument,
    options,
  );
}
export type SyncPoMutationHookResult = ReturnType<typeof useSyncPoMutation>;
export type SyncPoMutationResult = Apollo.MutationResult<SyncPoMutation>;
export type SyncPoMutationOptions = Apollo.BaseMutationOptions<
  SyncPoMutation,
  SyncPoMutationVariables
>;
export const UnlinkPoDocument = gql`
  mutation UnlinkPO($poLinkId: ID!) {
    unlinkPO(poLinkId: $poLinkId) {
      ...POLinkFields
    }
  }
  ${PoLinkFieldsFragmentDoc}
`;
export type UnlinkPoMutationFn = Apollo.MutationFunction<
  UnlinkPoMutation,
  UnlinkPoMutationVariables
>;

/**
 * __useUnlinkPoMutation__
 *
 * To run a mutation, you first call `useUnlinkPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkPoMutation, { data, loading, error }] = useUnlinkPoMutation({
 *   variables: {
 *      poLinkId: // value for 'poLinkId'
 *   },
 * });
 */
export function useUnlinkPoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkPoMutation,
    UnlinkPoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnlinkPoMutation, UnlinkPoMutationVariables>(
    UnlinkPoDocument,
    options,
  );
}
export type UnlinkPoMutationHookResult = ReturnType<typeof useUnlinkPoMutation>;
export type UnlinkPoMutationResult = Apollo.MutationResult<UnlinkPoMutation>;
export type UnlinkPoMutationOptions = Apollo.BaseMutationOptions<
  UnlinkPoMutation,
  UnlinkPoMutationVariables
>;
export const UpdateContractorReleasesDocument = gql`
  mutation UpdateContractorReleases($input: UpdateContractorReleasesInput!) {
    updateContractorReleases(input: $input) {
      id
      status
      useSourceSystemPO
      poLink {
        ...POLinkFields
      }
    }
  }
  ${PoLinkFieldsFragmentDoc}
`;
export type UpdateContractorReleasesMutationFn = Apollo.MutationFunction<
  UpdateContractorReleasesMutation,
  UpdateContractorReleasesMutationVariables
>;

/**
 * __useUpdateContractorReleasesMutation__
 *
 * To run a mutation, you first call `useUpdateContractorReleasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractorReleasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractorReleasesMutation, { data, loading, error }] = useUpdateContractorReleasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractorReleasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractorReleasesMutation,
    UpdateContractorReleasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractorReleasesMutation,
    UpdateContractorReleasesMutationVariables
  >(UpdateContractorReleasesDocument, options);
}
export type UpdateContractorReleasesMutationHookResult = ReturnType<
  typeof useUpdateContractorReleasesMutation
>;
export type UpdateContractorReleasesMutationResult =
  Apollo.MutationResult<UpdateContractorReleasesMutation>;
export type UpdateContractorReleasesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateContractorReleasesMutation,
    UpdateContractorReleasesMutationVariables
  >;
export const UpdatePoLinkDocument = gql`
  mutation UpdatePOLink($input: UpdatePOLinkInput!) {
    updatePOLink(input: $input) {
      ...POLinkFields
    }
  }
  ${PoLinkFieldsFragmentDoc}
`;
export type UpdatePoLinkMutationFn = Apollo.MutationFunction<
  UpdatePoLinkMutation,
  UpdatePoLinkMutationVariables
>;

/**
 * __useUpdatePoLinkMutation__
 *
 * To run a mutation, you first call `useUpdatePoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoLinkMutation, { data, loading, error }] = useUpdatePoLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePoLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePoLinkMutation,
    UpdatePoLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePoLinkMutation,
    UpdatePoLinkMutationVariables
  >(UpdatePoLinkDocument, options);
}
export type UpdatePoLinkMutationHookResult = ReturnType<
  typeof useUpdatePoLinkMutation
>;
export type UpdatePoLinkMutationResult =
  Apollo.MutationResult<UpdatePoLinkMutation>;
export type UpdatePoLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdatePoLinkMutation,
  UpdatePoLinkMutationVariables
>;
export const ExternalBatchDocument = gql`
  query ExternalBatch($input: ExternalBatchQueryInput!) {
    externalBatch(input: $input) {
      ...ExternalBatchFields
    }
  }
  ${ExternalBatchFieldsFragmentDoc}
`;

/**
 * __useExternalBatchQuery__
 *
 * To run a query within a React component, call `useExternalBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalBatchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalBatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalBatchQuery,
    ExternalBatchQueryVariables
  > &
    (
      | { variables: ExternalBatchQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalBatchQuery, ExternalBatchQueryVariables>(
    ExternalBatchDocument,
    options,
  );
}
export function useExternalBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalBatchQuery,
    ExternalBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalBatchQuery, ExternalBatchQueryVariables>(
    ExternalBatchDocument,
    options,
  );
}
export function useExternalBatchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalBatchQuery,
        ExternalBatchQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalBatchQuery,
    ExternalBatchQueryVariables
  >(ExternalBatchDocument, options);
}
export type ExternalBatchQueryHookResult = ReturnType<
  typeof useExternalBatchQuery
>;
export type ExternalBatchLazyQueryHookResult = ReturnType<
  typeof useExternalBatchLazyQuery
>;
export type ExternalBatchSuspenseQueryHookResult = ReturnType<
  typeof useExternalBatchSuspenseQuery
>;
export type ExternalBatchQueryResult = Apollo.QueryResult<
  ExternalBatchQuery,
  ExternalBatchQueryVariables
>;
export const ExternalWarehousesDocument = gql`
  query ExternalWarehouses($sourceSystem: SourceSystem!) {
    externalWarehouses(sourceSystem: $sourceSystem) {
      ...ExternalWarehouseFields
    }
  }
  ${ExternalWarehouseFieldsFragmentDoc}
`;

/**
 * __useExternalWarehousesQuery__
 *
 * To run a query within a React component, call `useExternalWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalWarehousesQuery({
 *   variables: {
 *      sourceSystem: // value for 'sourceSystem'
 *   },
 * });
 */
export function useExternalWarehousesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalWarehousesQuery,
    ExternalWarehousesQueryVariables
  > &
    (
      | { variables: ExternalWarehousesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalWarehousesQuery,
    ExternalWarehousesQueryVariables
  >(ExternalWarehousesDocument, options);
}
export function useExternalWarehousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalWarehousesQuery,
    ExternalWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalWarehousesQuery,
    ExternalWarehousesQueryVariables
  >(ExternalWarehousesDocument, options);
}
export function useExternalWarehousesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExternalWarehousesQuery,
        ExternalWarehousesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExternalWarehousesQuery,
    ExternalWarehousesQueryVariables
  >(ExternalWarehousesDocument, options);
}
export type ExternalWarehousesQueryHookResult = ReturnType<
  typeof useExternalWarehousesQuery
>;
export type ExternalWarehousesLazyQueryHookResult = ReturnType<
  typeof useExternalWarehousesLazyQuery
>;
export type ExternalWarehousesSuspenseQueryHookResult = ReturnType<
  typeof useExternalWarehousesSuspenseQuery
>;
export type ExternalWarehousesQueryResult = Apollo.QueryResult<
  ExternalWarehousesQuery,
  ExternalWarehousesQueryVariables
>;
export const PoValidationDocument = gql`
  query POValidation($input: LinkPOInput!) {
    poValidation(input: $input) {
      ...POValidationFields
    }
  }
  ${PoValidationFieldsFragmentDoc}
`;

/**
 * __usePoValidationQuery__
 *
 * To run a query within a React component, call `usePoValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoValidationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePoValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PoValidationQuery,
    PoValidationQueryVariables
  > &
    (
      | { variables: PoValidationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoValidationQuery, PoValidationQueryVariables>(
    PoValidationDocument,
    options,
  );
}
export function usePoValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PoValidationQuery,
    PoValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoValidationQuery, PoValidationQueryVariables>(
    PoValidationDocument,
    options,
  );
}
export function usePoValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PoValidationQuery,
        PoValidationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PoValidationQuery, PoValidationQueryVariables>(
    PoValidationDocument,
    options,
  );
}
export type PoValidationQueryHookResult = ReturnType<
  typeof usePoValidationQuery
>;
export type PoValidationLazyQueryHookResult = ReturnType<
  typeof usePoValidationLazyQuery
>;
export type PoValidationSuspenseQueryHookResult = ReturnType<
  typeof usePoValidationSuspenseQuery
>;
export type PoValidationQueryResult = Apollo.QueryResult<
  PoValidationQuery,
  PoValidationQueryVariables
>;
export const ReleasesByIdsDocument = gql`
  query ReleasesByIds(
    $filter: QueryReleasesFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    releases(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ReleaseSummaryFields
        }
      }
    }
  }
  ${ReleaseSummaryFieldsFragmentDoc}
`;

/**
 * __useReleasesByIdsQuery__
 *
 * To run a query within a React component, call `useReleasesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesByIdsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useReleasesByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReleasesByIdsQuery,
    ReleasesByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReleasesByIdsQuery, ReleasesByIdsQueryVariables>(
    ReleasesByIdsDocument,
    options,
  );
}
export function useReleasesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasesByIdsQuery,
    ReleasesByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReleasesByIdsQuery, ReleasesByIdsQueryVariables>(
    ReleasesByIdsDocument,
    options,
  );
}
export function useReleasesByIdsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleasesByIdsQuery,
        ReleasesByIdsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleasesByIdsQuery,
    ReleasesByIdsQueryVariables
  >(ReleasesByIdsDocument, options);
}
export type ReleasesByIdsQueryHookResult = ReturnType<
  typeof useReleasesByIdsQuery
>;
export type ReleasesByIdsLazyQueryHookResult = ReturnType<
  typeof useReleasesByIdsLazyQuery
>;
export type ReleasesByIdsSuspenseQueryHookResult = ReturnType<
  typeof useReleasesByIdsSuspenseQuery
>;
export type ReleasesByIdsQueryResult = Apollo.QueryResult<
  ReleasesByIdsQuery,
  ReleasesByIdsQueryVariables
>;
export const ArchiveSiteContactDocument = gql`
  mutation ArchiveSiteContact($id: ID!) {
    archiveSiteContact(id: $id) {
      id
      archivedAt
    }
  }
`;
export type ArchiveSiteContactMutationFn = Apollo.MutationFunction<
  ArchiveSiteContactMutation,
  ArchiveSiteContactMutationVariables
>;

/**
 * __useArchiveSiteContactMutation__
 *
 * To run a mutation, you first call `useArchiveSiteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSiteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSiteContactMutation, { data, loading, error }] = useArchiveSiteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveSiteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSiteContactMutation,
    ArchiveSiteContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveSiteContactMutation,
    ArchiveSiteContactMutationVariables
  >(ArchiveSiteContactDocument, options);
}
export type ArchiveSiteContactMutationHookResult = ReturnType<
  typeof useArchiveSiteContactMutation
>;
export type ArchiveSiteContactMutationResult =
  Apollo.MutationResult<ArchiveSiteContactMutation>;
export type ArchiveSiteContactMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSiteContactMutation,
  ArchiveSiteContactMutationVariables
>;
export const CreateSiteContactDocument = gql`
  mutation CreateSiteContact($input: CreateSiteContactInput!) {
    createSiteContact(input: $input) {
      ...SiteContactFields
    }
  }
  ${SiteContactFieldsFragmentDoc}
`;
export type CreateSiteContactMutationFn = Apollo.MutationFunction<
  CreateSiteContactMutation,
  CreateSiteContactMutationVariables
>;

/**
 * __useCreateSiteContactMutation__
 *
 * To run a mutation, you first call `useCreateSiteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteContactMutation, { data, loading, error }] = useCreateSiteContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSiteContactMutation,
    CreateSiteContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSiteContactMutation,
    CreateSiteContactMutationVariables
  >(CreateSiteContactDocument, options);
}
export type CreateSiteContactMutationHookResult = ReturnType<
  typeof useCreateSiteContactMutation
>;
export type CreateSiteContactMutationResult =
  Apollo.MutationResult<CreateSiteContactMutation>;
export type CreateSiteContactMutationOptions = Apollo.BaseMutationOptions<
  CreateSiteContactMutation,
  CreateSiteContactMutationVariables
>;
export const SiteContactsDocument = gql`
  query SiteContacts($archived: Boolean = false) {
    siteContacts(archived: $archived) {
      ...SiteContactFields
    }
  }
  ${SiteContactFieldsFragmentDoc}
`;

/**
 * __useSiteContactsQuery__
 *
 * To run a query within a React component, call `useSiteContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteContactsQuery({
 *   variables: {
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useSiteContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SiteContactsQuery,
    SiteContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiteContactsQuery, SiteContactsQueryVariables>(
    SiteContactsDocument,
    options,
  );
}
export function useSiteContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteContactsQuery,
    SiteContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiteContactsQuery, SiteContactsQueryVariables>(
    SiteContactsDocument,
    options,
  );
}
export function useSiteContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SiteContactsQuery,
        SiteContactsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SiteContactsQuery, SiteContactsQueryVariables>(
    SiteContactsDocument,
    options,
  );
}
export type SiteContactsQueryHookResult = ReturnType<
  typeof useSiteContactsQuery
>;
export type SiteContactsLazyQueryHookResult = ReturnType<
  typeof useSiteContactsLazyQuery
>;
export type SiteContactsSuspenseQueryHookResult = ReturnType<
  typeof useSiteContactsSuspenseQuery
>;
export type SiteContactsQueryResult = Apollo.QueryResult<
  SiteContactsQuery,
  SiteContactsQueryVariables
>;
export const ReleasePredictedPoNumberDocument = gql`
  query ReleasePredictedPoNumber($id: ID!) {
    release(id: $id) {
      id
      predictedPoNumber
    }
  }
`;

/**
 * __useReleasePredictedPoNumberQuery__
 *
 * To run a query within a React component, call `useReleasePredictedPoNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasePredictedPoNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasePredictedPoNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleasePredictedPoNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleasePredictedPoNumberQuery,
    ReleasePredictedPoNumberQueryVariables
  > &
    (
      | { variables: ReleasePredictedPoNumberQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReleasePredictedPoNumberQuery,
    ReleasePredictedPoNumberQueryVariables
  >(ReleasePredictedPoNumberDocument, options);
}
export function useReleasePredictedPoNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasePredictedPoNumberQuery,
    ReleasePredictedPoNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleasePredictedPoNumberQuery,
    ReleasePredictedPoNumberQueryVariables
  >(ReleasePredictedPoNumberDocument, options);
}
export function useReleasePredictedPoNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleasePredictedPoNumberQuery,
        ReleasePredictedPoNumberQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleasePredictedPoNumberQuery,
    ReleasePredictedPoNumberQueryVariables
  >(ReleasePredictedPoNumberDocument, options);
}
export type ReleasePredictedPoNumberQueryHookResult = ReturnType<
  typeof useReleasePredictedPoNumberQuery
>;
export type ReleasePredictedPoNumberLazyQueryHookResult = ReturnType<
  typeof useReleasePredictedPoNumberLazyQuery
>;
export type ReleasePredictedPoNumberSuspenseQueryHookResult = ReturnType<
  typeof useReleasePredictedPoNumberSuspenseQuery
>;
export type ReleasePredictedPoNumberQueryResult = Apollo.QueryResult<
  ReleasePredictedPoNumberQuery,
  ReleasePredictedPoNumberQueryVariables
>;
export const ReleaseItemDeliveredItemsDocument = gql`
  query ReleaseItemDeliveredItems($id: ID!, $itemId: String!) {
    release(id: $id) {
      id
      item(itemId: $itemId) {
        id
        deliveredItems(
          deliverySlipStatuses: [PROCESSED]
          archivedDeliverySlips: false
        ) {
          id
          quantity
          deliverySlip {
            id
            status
            createdAt
            createdBy {
              id
              firstName
              lastName
            }
            asset {
              ...AssetFields
            }
          }
        }
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`;

/**
 * __useReleaseItemDeliveredItemsQuery__
 *
 * To run a query within a React component, call `useReleaseItemDeliveredItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseItemDeliveredItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseItemDeliveredItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useReleaseItemDeliveredItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleaseItemDeliveredItemsQuery,
    ReleaseItemDeliveredItemsQueryVariables
  > &
    (
      | { variables: ReleaseItemDeliveredItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReleaseItemDeliveredItemsQuery,
    ReleaseItemDeliveredItemsQueryVariables
  >(ReleaseItemDeliveredItemsDocument, options);
}
export function useReleaseItemDeliveredItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleaseItemDeliveredItemsQuery,
    ReleaseItemDeliveredItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleaseItemDeliveredItemsQuery,
    ReleaseItemDeliveredItemsQueryVariables
  >(ReleaseItemDeliveredItemsDocument, options);
}
export function useReleaseItemDeliveredItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleaseItemDeliveredItemsQuery,
        ReleaseItemDeliveredItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleaseItemDeliveredItemsQuery,
    ReleaseItemDeliveredItemsQueryVariables
  >(ReleaseItemDeliveredItemsDocument, options);
}
export type ReleaseItemDeliveredItemsQueryHookResult = ReturnType<
  typeof useReleaseItemDeliveredItemsQuery
>;
export type ReleaseItemDeliveredItemsLazyQueryHookResult = ReturnType<
  typeof useReleaseItemDeliveredItemsLazyQuery
>;
export type ReleaseItemDeliveredItemsSuspenseQueryHookResult = ReturnType<
  typeof useReleaseItemDeliveredItemsSuspenseQuery
>;
export type ReleaseItemDeliveredItemsQueryResult = Apollo.QueryResult<
  ReleaseItemDeliveredItemsQuery,
  ReleaseItemDeliveredItemsQueryVariables
>;
export const ReleaseItemInvoiceItemsDocument = gql`
  query ReleaseItemInvoiceItems($id: ID!, $itemId: String!) {
    release(id: $id) {
      id
      item(itemId: $itemId) {
        id
        invoiceItems {
          id
          quantity
          invoice {
            id
            number
            createdAt
            documents {
              id
              asset {
                ...AssetFields
              }
            }
          }
        }
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`;

/**
 * __useReleaseItemInvoiceItemsQuery__
 *
 * To run a query within a React component, call `useReleaseItemInvoiceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseItemInvoiceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseItemInvoiceItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useReleaseItemInvoiceItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReleaseItemInvoiceItemsQuery,
    ReleaseItemInvoiceItemsQueryVariables
  > &
    (
      | { variables: ReleaseItemInvoiceItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReleaseItemInvoiceItemsQuery,
    ReleaseItemInvoiceItemsQueryVariables
  >(ReleaseItemInvoiceItemsDocument, options);
}
export function useReleaseItemInvoiceItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleaseItemInvoiceItemsQuery,
    ReleaseItemInvoiceItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleaseItemInvoiceItemsQuery,
    ReleaseItemInvoiceItemsQueryVariables
  >(ReleaseItemInvoiceItemsDocument, options);
}
export function useReleaseItemInvoiceItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleaseItemInvoiceItemsQuery,
        ReleaseItemInvoiceItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleaseItemInvoiceItemsQuery,
    ReleaseItemInvoiceItemsQueryVariables
  >(ReleaseItemInvoiceItemsDocument, options);
}
export type ReleaseItemInvoiceItemsQueryHookResult = ReturnType<
  typeof useReleaseItemInvoiceItemsQuery
>;
export type ReleaseItemInvoiceItemsLazyQueryHookResult = ReturnType<
  typeof useReleaseItemInvoiceItemsLazyQuery
>;
export type ReleaseItemInvoiceItemsSuspenseQueryHookResult = ReturnType<
  typeof useReleaseItemInvoiceItemsSuspenseQuery
>;
export type ReleaseItemInvoiceItemsQueryResult = Apollo.QueryResult<
  ReleaseItemInvoiceItemsQuery,
  ReleaseItemInvoiceItemsQueryVariables
>;
export const ArchiveReleasesDocument = gql`
  mutation ArchiveReleases($ids: [ID!]!) {
    archiveReleases(ids: $ids) {
      id
      deletedAt
      status
    }
  }
`;
export type ArchiveReleasesMutationFn = Apollo.MutationFunction<
  ArchiveReleasesMutation,
  ArchiveReleasesMutationVariables
>;

/**
 * __useArchiveReleasesMutation__
 *
 * To run a mutation, you first call `useArchiveReleasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveReleasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveReleasesMutation, { data, loading, error }] = useArchiveReleasesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveReleasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveReleasesMutation,
    ArchiveReleasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveReleasesMutation,
    ArchiveReleasesMutationVariables
  >(ArchiveReleasesDocument, options);
}
export type ArchiveReleasesMutationHookResult = ReturnType<
  typeof useArchiveReleasesMutation
>;
export type ArchiveReleasesMutationResult =
  Apollo.MutationResult<ArchiveReleasesMutation>;
export type ArchiveReleasesMutationOptions = Apollo.BaseMutationOptions<
  ArchiveReleasesMutation,
  ArchiveReleasesMutationVariables
>;
export const CreateOrderGroupDocument = gql`
  mutation CreateOrderGroup($input: CreateReleaseGroupInput!) {
    createReleaseGroup(input: $input) {
      ...ReleaseGroupFields
    }
  }
  ${ReleaseGroupFieldsFragmentDoc}
`;
export type CreateOrderGroupMutationFn = Apollo.MutationFunction<
  CreateOrderGroupMutation,
  CreateOrderGroupMutationVariables
>;

/**
 * __useCreateOrderGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrderGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderGroupMutation, { data, loading, error }] = useCreateOrderGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderGroupMutation,
    CreateOrderGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrderGroupMutation,
    CreateOrderGroupMutationVariables
  >(CreateOrderGroupDocument, options);
}
export type CreateOrderGroupMutationHookResult = ReturnType<
  typeof useCreateOrderGroupMutation
>;
export type CreateOrderGroupMutationResult =
  Apollo.MutationResult<CreateOrderGroupMutation>;
export type CreateOrderGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderGroupMutation,
  CreateOrderGroupMutationVariables
>;
export const DeleteOrderGroupDocument = gql`
  mutation DeleteOrderGroup($id: ID!) {
    deleteReleaseGroup(id: $id) {
      id
    }
  }
`;
export type DeleteOrderGroupMutationFn = Apollo.MutationFunction<
  DeleteOrderGroupMutation,
  DeleteOrderGroupMutationVariables
>;

/**
 * __useDeleteOrderGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOrderGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderGroupMutation, { data, loading, error }] = useDeleteOrderGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrderGroupMutation,
    DeleteOrderGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrderGroupMutation,
    DeleteOrderGroupMutationVariables
  >(DeleteOrderGroupDocument, options);
}
export type DeleteOrderGroupMutationHookResult = ReturnType<
  typeof useDeleteOrderGroupMutation
>;
export type DeleteOrderGroupMutationResult =
  Apollo.MutationResult<DeleteOrderGroupMutation>;
export type DeleteOrderGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrderGroupMutation,
  DeleteOrderGroupMutationVariables
>;
export const UpdateOrderGroupDocument = gql`
  mutation UpdateOrderGroup($input: UpdateReleaseGroupInput!) {
    updateReleaseGroup(input: $input) {
      ...ReleaseGroupFields
    }
  }
  ${ReleaseGroupFieldsFragmentDoc}
`;
export type UpdateOrderGroupMutationFn = Apollo.MutationFunction<
  UpdateOrderGroupMutation,
  UpdateOrderGroupMutationVariables
>;

/**
 * __useUpdateOrderGroupMutation__
 *
 * To run a mutation, you first call `useUpdateOrderGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderGroupMutation, { data, loading, error }] = useUpdateOrderGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderGroupMutation,
    UpdateOrderGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderGroupMutation,
    UpdateOrderGroupMutationVariables
  >(UpdateOrderGroupDocument, options);
}
export type UpdateOrderGroupMutationHookResult = ReturnType<
  typeof useUpdateOrderGroupMutation
>;
export type UpdateOrderGroupMutationResult =
  Apollo.MutationResult<UpdateOrderGroupMutation>;
export type UpdateOrderGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderGroupMutation,
  UpdateOrderGroupMutationVariables
>;
export const UpdateReleasesDocument = gql`
  mutation UpdateReleases($input: UpdateContractorReleasesInput!) {
    updateContractorReleases(input: $input) {
      ...ReleasePartialFields
    }
  }
  ${ReleasePartialFieldsFragmentDoc}
`;
export type UpdateReleasesMutationFn = Apollo.MutationFunction<
  UpdateReleasesMutation,
  UpdateReleasesMutationVariables
>;

/**
 * __useUpdateReleasesMutation__
 *
 * To run a mutation, you first call `useUpdateReleasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReleasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReleasesMutation, { data, loading, error }] = useUpdateReleasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReleasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReleasesMutation,
    UpdateReleasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReleasesMutation,
    UpdateReleasesMutationVariables
  >(UpdateReleasesDocument, options);
}
export type UpdateReleasesMutationHookResult = ReturnType<
  typeof useUpdateReleasesMutation
>;
export type UpdateReleasesMutationResult =
  Apollo.MutationResult<UpdateReleasesMutation>;
export type UpdateReleasesMutationOptions = Apollo.BaseMutationOptions<
  UpdateReleasesMutation,
  UpdateReleasesMutationVariables
>;
export const ReleasesToCsvExportDocument = gql`
  query ReleasesToCsvExport(
    $filter: QueryReleasesFilter
    $first: Int
    $sort: QueryReleasesSort
  ) {
    releases(filter: $filter, first: $first, sort: $sort) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ReleaseToCsvExportFields
        }
      }
    }
  }
  ${ReleaseToCsvExportFieldsFragmentDoc}
`;

/**
 * __useReleasesToCsvExportQuery__
 *
 * To run a query within a React component, call `useReleasesToCsvExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesToCsvExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesToCsvExportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReleasesToCsvExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReleasesToCsvExportQuery,
    ReleasesToCsvExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReleasesToCsvExportQuery,
    ReleasesToCsvExportQueryVariables
  >(ReleasesToCsvExportDocument, options);
}
export function useReleasesToCsvExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReleasesToCsvExportQuery,
    ReleasesToCsvExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReleasesToCsvExportQuery,
    ReleasesToCsvExportQueryVariables
  >(ReleasesToCsvExportDocument, options);
}
export function useReleasesToCsvExportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReleasesToCsvExportQuery,
        ReleasesToCsvExportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReleasesToCsvExportQuery,
    ReleasesToCsvExportQueryVariables
  >(ReleasesToCsvExportDocument, options);
}
export type ReleasesToCsvExportQueryHookResult = ReturnType<
  typeof useReleasesToCsvExportQuery
>;
export type ReleasesToCsvExportLazyQueryHookResult = ReturnType<
  typeof useReleasesToCsvExportLazyQuery
>;
export type ReleasesToCsvExportSuspenseQueryHookResult = ReturnType<
  typeof useReleasesToCsvExportSuspenseQuery
>;
export type ReleasesToCsvExportQueryResult = Apollo.QueryResult<
  ReleasesToCsvExportQuery,
  ReleasesToCsvExportQueryVariables
>;
export const ArchiveDeliverySlipDocument = gql`
  mutation ArchiveDeliverySlip($id: ID!) {
    archiveDeliverySlip(id: $id) {
      id
      archivedAt
      status
    }
  }
`;
export type ArchiveDeliverySlipMutationFn = Apollo.MutationFunction<
  ArchiveDeliverySlipMutation,
  ArchiveDeliverySlipMutationVariables
>;

/**
 * __useArchiveDeliverySlipMutation__
 *
 * To run a mutation, you first call `useArchiveDeliverySlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveDeliverySlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveDeliverySlipMutation, { data, loading, error }] = useArchiveDeliverySlipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveDeliverySlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveDeliverySlipMutation,
    ArchiveDeliverySlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveDeliverySlipMutation,
    ArchiveDeliverySlipMutationVariables
  >(ArchiveDeliverySlipDocument, options);
}
export type ArchiveDeliverySlipMutationHookResult = ReturnType<
  typeof useArchiveDeliverySlipMutation
>;
export type ArchiveDeliverySlipMutationResult =
  Apollo.MutationResult<ArchiveDeliverySlipMutation>;
export type ArchiveDeliverySlipMutationOptions = Apollo.BaseMutationOptions<
  ArchiveDeliverySlipMutation,
  ArchiveDeliverySlipMutationVariables
>;
export const CreateDeliverySlipDocument = gql`
  mutation CreateDeliverySlip($input: CreateDeliverySlipInput!) {
    createDeliverySlip(input: $input) {
      ...DeliverySlipFields
    }
  }
  ${DeliverySlipFieldsFragmentDoc}
`;
export type CreateDeliverySlipMutationFn = Apollo.MutationFunction<
  CreateDeliverySlipMutation,
  CreateDeliverySlipMutationVariables
>;

/**
 * __useCreateDeliverySlipMutation__
 *
 * To run a mutation, you first call `useCreateDeliverySlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliverySlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliverySlipMutation, { data, loading, error }] = useCreateDeliverySlipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliverySlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeliverySlipMutation,
    CreateDeliverySlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeliverySlipMutation,
    CreateDeliverySlipMutationVariables
  >(CreateDeliverySlipDocument, options);
}
export type CreateDeliverySlipMutationHookResult = ReturnType<
  typeof useCreateDeliverySlipMutation
>;
export type CreateDeliverySlipMutationResult =
  Apollo.MutationResult<CreateDeliverySlipMutation>;
export type CreateDeliverySlipMutationOptions = Apollo.BaseMutationOptions<
  CreateDeliverySlipMutation,
  CreateDeliverySlipMutationVariables
>;
export const ReplaceDeliverySlipDocument = gql`
  mutation ReplaceDeliverySlip($input: UpdateDeliverySlipInput!) {
    updateDeliverySlip(input: $input) {
      id
      status
      items {
        ...DeliverySlipItemFields
      }
      deliveredReleaseItems {
        ...DeliveredReleaseItemsFields
      }
      releaseItemHints {
        ...ReleaseItemHintFragment
      }
      asset {
        ...AssetFields
      }
    }
  }
  ${DeliverySlipItemFieldsFragmentDoc}
  ${DeliveredReleaseItemsFieldsFragmentDoc}
  ${ReleaseItemHintFragmentFragmentDoc}
  ${AssetFieldsFragmentDoc}
`;
export type ReplaceDeliverySlipMutationFn = Apollo.MutationFunction<
  ReplaceDeliverySlipMutation,
  ReplaceDeliverySlipMutationVariables
>;

/**
 * __useReplaceDeliverySlipMutation__
 *
 * To run a mutation, you first call `useReplaceDeliverySlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceDeliverySlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceDeliverySlipMutation, { data, loading, error }] = useReplaceDeliverySlipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceDeliverySlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceDeliverySlipMutation,
    ReplaceDeliverySlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReplaceDeliverySlipMutation,
    ReplaceDeliverySlipMutationVariables
  >(ReplaceDeliverySlipDocument, options);
}
export type ReplaceDeliverySlipMutationHookResult = ReturnType<
  typeof useReplaceDeliverySlipMutation
>;
export type ReplaceDeliverySlipMutationResult =
  Apollo.MutationResult<ReplaceDeliverySlipMutation>;
export type ReplaceDeliverySlipMutationOptions = Apollo.BaseMutationOptions<
  ReplaceDeliverySlipMutation,
  ReplaceDeliverySlipMutationVariables
>;
export const RescanDeliverySlipDocument = gql`
  mutation RescanDeliverySlip($deliverySlipId: ID!) {
    rescanDeliverySlip(deliverySlipId: $deliverySlipId)
  }
`;
export type RescanDeliverySlipMutationFn = Apollo.MutationFunction<
  RescanDeliverySlipMutation,
  RescanDeliverySlipMutationVariables
>;

/**
 * __useRescanDeliverySlipMutation__
 *
 * To run a mutation, you first call `useRescanDeliverySlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescanDeliverySlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescanDeliverySlipMutation, { data, loading, error }] = useRescanDeliverySlipMutation({
 *   variables: {
 *      deliverySlipId: // value for 'deliverySlipId'
 *   },
 * });
 */
export function useRescanDeliverySlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescanDeliverySlipMutation,
    RescanDeliverySlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescanDeliverySlipMutation,
    RescanDeliverySlipMutationVariables
  >(RescanDeliverySlipDocument, options);
}
export type RescanDeliverySlipMutationHookResult = ReturnType<
  typeof useRescanDeliverySlipMutation
>;
export type RescanDeliverySlipMutationResult =
  Apollo.MutationResult<RescanDeliverySlipMutation>;
export type RescanDeliverySlipMutationOptions = Apollo.BaseMutationOptions<
  RescanDeliverySlipMutation,
  RescanDeliverySlipMutationVariables
>;
export const UpdateDeliverySlipDocument = gql`
  mutation UpdateDeliverySlip($input: UpdateDeliverySlipInput!) {
    updateDeliverySlip(input: $input) {
      id
      status
      fulfillmentDate
      items {
        ...DeliverySlipItemFields
      }
      releaseItemHints {
        ...ReleaseItemHintFragment
      }
      deliveredReleaseItems {
        ...DeliveredReleaseItemsFields
      }
      release {
        id
        itemCount
      }
    }
  }
  ${DeliverySlipItemFieldsFragmentDoc}
  ${ReleaseItemHintFragmentFragmentDoc}
  ${DeliveredReleaseItemsFieldsFragmentDoc}
`;
export type UpdateDeliverySlipMutationFn = Apollo.MutationFunction<
  UpdateDeliverySlipMutation,
  UpdateDeliverySlipMutationVariables
>;

/**
 * __useUpdateDeliverySlipMutation__
 *
 * To run a mutation, you first call `useUpdateDeliverySlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliverySlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliverySlipMutation, { data, loading, error }] = useUpdateDeliverySlipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliverySlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDeliverySlipMutation,
    UpdateDeliverySlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDeliverySlipMutation,
    UpdateDeliverySlipMutationVariables
  >(UpdateDeliverySlipDocument, options);
}
export type UpdateDeliverySlipMutationHookResult = ReturnType<
  typeof useUpdateDeliverySlipMutation
>;
export type UpdateDeliverySlipMutationResult =
  Apollo.MutationResult<UpdateDeliverySlipMutation>;
export type UpdateDeliverySlipMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeliverySlipMutation,
  UpdateDeliverySlipMutationVariables
>;
export const DeliverySlipDocument = gql`
  query DeliverySlip($id: ID!) {
    deliverySlip(id: $id) {
      ...DeliverySlipFields
    }
  }
  ${DeliverySlipFieldsFragmentDoc}
`;

/**
 * __useDeliverySlipQuery__
 *
 * To run a query within a React component, call `useDeliverySlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverySlipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverySlipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliverySlipQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeliverySlipQuery,
    DeliverySlipQueryVariables
  > &
    (
      | { variables: DeliverySlipQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeliverySlipQuery, DeliverySlipQueryVariables>(
    DeliverySlipDocument,
    options,
  );
}
export function useDeliverySlipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliverySlipQuery,
    DeliverySlipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeliverySlipQuery, DeliverySlipQueryVariables>(
    DeliverySlipDocument,
    options,
  );
}
export function useDeliverySlipSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DeliverySlipQuery,
        DeliverySlipQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DeliverySlipQuery, DeliverySlipQueryVariables>(
    DeliverySlipDocument,
    options,
  );
}
export type DeliverySlipQueryHookResult = ReturnType<
  typeof useDeliverySlipQuery
>;
export type DeliverySlipLazyQueryHookResult = ReturnType<
  typeof useDeliverySlipLazyQuery
>;
export type DeliverySlipSuspenseQueryHookResult = ReturnType<
  typeof useDeliverySlipSuspenseQuery
>;
export type DeliverySlipQueryResult = Apollo.QueryResult<
  DeliverySlipQuery,
  DeliverySlipQueryVariables
>;
export const DeliverySlipStatsDocument = gql`
  query DeliverySlipStats {
    deliverySlipStats {
      pendingCount
    }
  }
`;

/**
 * __useDeliverySlipStatsQuery__
 *
 * To run a query within a React component, call `useDeliverySlipStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverySlipStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverySlipStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeliverySlipStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeliverySlipStatsQuery,
    DeliverySlipStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DeliverySlipStatsQuery,
    DeliverySlipStatsQueryVariables
  >(DeliverySlipStatsDocument, options);
}
export function useDeliverySlipStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliverySlipStatsQuery,
    DeliverySlipStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DeliverySlipStatsQuery,
    DeliverySlipStatsQueryVariables
  >(DeliverySlipStatsDocument, options);
}
export function useDeliverySlipStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DeliverySlipStatsQuery,
        DeliverySlipStatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DeliverySlipStatsQuery,
    DeliverySlipStatsQueryVariables
  >(DeliverySlipStatsDocument, options);
}
export type DeliverySlipStatsQueryHookResult = ReturnType<
  typeof useDeliverySlipStatsQuery
>;
export type DeliverySlipStatsLazyQueryHookResult = ReturnType<
  typeof useDeliverySlipStatsLazyQuery
>;
export type DeliverySlipStatsSuspenseQueryHookResult = ReturnType<
  typeof useDeliverySlipStatsSuspenseQuery
>;
export type DeliverySlipStatsQueryResult = Apollo.QueryResult<
  DeliverySlipStatsQuery,
  DeliverySlipStatsQueryVariables
>;
export const DeliverySlipsDocument = gql`
  query DeliverySlips(
    $filter: QueryDeliverySlipsFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sort: QueryDeliverySlipsSort
  ) {
    deliverySlips(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...DeliverySlipPartialFields
        }
      }
    }
  }
  ${DeliverySlipPartialFieldsFragmentDoc}
`;

/**
 * __useDeliverySlipsQuery__
 *
 * To run a query within a React component, call `useDeliverySlipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverySlipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverySlipsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDeliverySlipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeliverySlipsQuery,
    DeliverySlipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeliverySlipsQuery, DeliverySlipsQueryVariables>(
    DeliverySlipsDocument,
    options,
  );
}
export function useDeliverySlipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliverySlipsQuery,
    DeliverySlipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeliverySlipsQuery, DeliverySlipsQueryVariables>(
    DeliverySlipsDocument,
    options,
  );
}
export function useDeliverySlipsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DeliverySlipsQuery,
        DeliverySlipsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DeliverySlipsQuery,
    DeliverySlipsQueryVariables
  >(DeliverySlipsDocument, options);
}
export type DeliverySlipsQueryHookResult = ReturnType<
  typeof useDeliverySlipsQuery
>;
export type DeliverySlipsLazyQueryHookResult = ReturnType<
  typeof useDeliverySlipsLazyQuery
>;
export type DeliverySlipsSuspenseQueryHookResult = ReturnType<
  typeof useDeliverySlipsSuspenseQuery
>;
export type DeliverySlipsQueryResult = Apollo.QueryResult<
  DeliverySlipsQuery,
  DeliverySlipsQueryVariables
>;
export const OrgMaterialsFilteredDocument = gql`
  query OrgMaterialsFiltered(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: QueryOrgMaterialsFilter
  ) {
    orgMaterials(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...PartialOrgMaterialFields
          defaultEstimateUom {
            ...UOMFields
          }
        }
      }
    }
  }
  ${PartialOrgMaterialFieldsFragmentDoc}
  ${UomFieldsFragmentDoc}
`;

/**
 * __useOrgMaterialsFilteredQuery__
 *
 * To run a query within a React component, call `useOrgMaterialsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMaterialsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMaterialsFilteredQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrgMaterialsFilteredQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgMaterialsFilteredQuery,
    OrgMaterialsFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgMaterialsFilteredQuery,
    OrgMaterialsFilteredQueryVariables
  >(OrgMaterialsFilteredDocument, options);
}
export function useOrgMaterialsFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgMaterialsFilteredQuery,
    OrgMaterialsFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgMaterialsFilteredQuery,
    OrgMaterialsFilteredQueryVariables
  >(OrgMaterialsFilteredDocument, options);
}
export function useOrgMaterialsFilteredSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrgMaterialsFilteredQuery,
        OrgMaterialsFilteredQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrgMaterialsFilteredQuery,
    OrgMaterialsFilteredQueryVariables
  >(OrgMaterialsFilteredDocument, options);
}
export type OrgMaterialsFilteredQueryHookResult = ReturnType<
  typeof useOrgMaterialsFilteredQuery
>;
export type OrgMaterialsFilteredLazyQueryHookResult = ReturnType<
  typeof useOrgMaterialsFilteredLazyQuery
>;
export type OrgMaterialsFilteredSuspenseQueryHookResult = ReturnType<
  typeof useOrgMaterialsFilteredSuspenseQuery
>;
export type OrgMaterialsFilteredQueryResult = Apollo.QueryResult<
  OrgMaterialsFilteredQuery,
  OrgMaterialsFilteredQueryVariables
>;
export const ArchiveRfqsDocument = gql`
  mutation ArchiveRfqs($ids: [ID!]!) {
    archiveRfqs(ids: $ids) {
      id
      deletedAt
      status
    }
  }
`;
export type ArchiveRfqsMutationFn = Apollo.MutationFunction<
  ArchiveRfqsMutation,
  ArchiveRfqsMutationVariables
>;

/**
 * __useArchiveRfqsMutation__
 *
 * To run a mutation, you first call `useArchiveRfqsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRfqsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRfqsMutation, { data, loading, error }] = useArchiveRfqsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveRfqsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveRfqsMutation,
    ArchiveRfqsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveRfqsMutation, ArchiveRfqsMutationVariables>(
    ArchiveRfqsDocument,
    options,
  );
}
export type ArchiveRfqsMutationHookResult = ReturnType<
  typeof useArchiveRfqsMutation
>;
export type ArchiveRfqsMutationResult =
  Apollo.MutationResult<ArchiveRfqsMutation>;
export type ArchiveRfqsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveRfqsMutation,
  ArchiveRfqsMutationVariables
>;
export const CreateRfqDocument = gql`
  mutation CreateRfq($input: CreateRfqInput!) {
    createRfq(input: $input) {
      ...RfqFields
    }
  }
  ${RfqFieldsFragmentDoc}
`;
export type CreateRfqMutationFn = Apollo.MutationFunction<
  CreateRfqMutation,
  CreateRfqMutationVariables
>;

/**
 * __useCreateRfqMutation__
 *
 * To run a mutation, you first call `useCreateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfqMutation, { data, loading, error }] = useCreateRfqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRfqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRfqMutation,
    CreateRfqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRfqMutation, CreateRfqMutationVariables>(
    CreateRfqDocument,
    options,
  );
}
export type CreateRfqMutationHookResult = ReturnType<
  typeof useCreateRfqMutation
>;
export type CreateRfqMutationResult = Apollo.MutationResult<CreateRfqMutation>;
export type CreateRfqMutationOptions = Apollo.BaseMutationOptions<
  CreateRfqMutation,
  CreateRfqMutationVariables
>;
export const UpdateRfqDocument = gql`
  mutation UpdateRfq($input: UpdateRfqInput!) {
    updateRfq(input: $input) {
      ...RfqFields
    }
  }
  ${RfqFieldsFragmentDoc}
`;
export type UpdateRfqMutationFn = Apollo.MutationFunction<
  UpdateRfqMutation,
  UpdateRfqMutationVariables
>;

/**
 * __useUpdateRfqMutation__
 *
 * To run a mutation, you first call `useUpdateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqMutation, { data, loading, error }] = useUpdateRfqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRfqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRfqMutation,
    UpdateRfqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRfqMutation, UpdateRfqMutationVariables>(
    UpdateRfqDocument,
    options,
  );
}
export type UpdateRfqMutationHookResult = ReturnType<
  typeof useUpdateRfqMutation
>;
export type UpdateRfqMutationResult = Apollo.MutationResult<UpdateRfqMutation>;
export type UpdateRfqMutationOptions = Apollo.BaseMutationOptions<
  UpdateRfqMutation,
  UpdateRfqMutationVariables
>;
export const ViewerNotificationsDocument = gql`
  query ViewerNotifications {
    viewer {
      id
      getEmailNotifications
      getSmsNotifications
      excludedReleaseEvents
      excludedInvoiceEvents
      excludedBuyoutEvents
      excludedQuoteEvents
      excludedMessageContexts
    }
  }
`;

/**
 * __useViewerNotificationsQuery__
 *
 * To run a query within a React component, call `useViewerNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewerNotificationsQuery,
    ViewerNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewerNotificationsQuery,
    ViewerNotificationsQueryVariables
  >(ViewerNotificationsDocument, options);
}
export function useViewerNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewerNotificationsQuery,
    ViewerNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewerNotificationsQuery,
    ViewerNotificationsQueryVariables
  >(ViewerNotificationsDocument, options);
}
export function useViewerNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ViewerNotificationsQuery,
        ViewerNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewerNotificationsQuery,
    ViewerNotificationsQueryVariables
  >(ViewerNotificationsDocument, options);
}
export type ViewerNotificationsQueryHookResult = ReturnType<
  typeof useViewerNotificationsQuery
>;
export type ViewerNotificationsLazyQueryHookResult = ReturnType<
  typeof useViewerNotificationsLazyQuery
>;
export type ViewerNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useViewerNotificationsSuspenseQuery
>;
export type ViewerNotificationsQueryResult = Apollo.QueryResult<
  ViewerNotificationsQuery,
  ViewerNotificationsQueryVariables
>;
export const AcceptBuyoutDocument = gql`
  mutation AcceptBuyout($input: AcceptBuyoutInput!) {
    acceptBuyout(input: $input) {
      ...DistributorBuyoutFields
    }
  }
  ${DistributorBuyoutFieldsFragmentDoc}
`;
export type AcceptBuyoutMutationFn = Apollo.MutationFunction<
  AcceptBuyoutMutation,
  AcceptBuyoutMutationVariables
>;

/**
 * __useAcceptBuyoutMutation__
 *
 * To run a mutation, you first call `useAcceptBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBuyoutMutation, { data, loading, error }] = useAcceptBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptBuyoutMutation,
    AcceptBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptBuyoutMutation,
    AcceptBuyoutMutationVariables
  >(AcceptBuyoutDocument, options);
}
export type AcceptBuyoutMutationHookResult = ReturnType<
  typeof useAcceptBuyoutMutation
>;
export type AcceptBuyoutMutationResult =
  Apollo.MutationResult<AcceptBuyoutMutation>;
export type AcceptBuyoutMutationOptions = Apollo.BaseMutationOptions<
  AcceptBuyoutMutation,
  AcceptBuyoutMutationVariables
>;
export const DeclineBuyoutDocument = gql`
  mutation DeclineBuyout($input: DeclineBuyoutInput!) {
    declineBuyout(input: $input) {
      ...DistributorBuyoutFields
    }
  }
  ${DistributorBuyoutFieldsFragmentDoc}
`;
export type DeclineBuyoutMutationFn = Apollo.MutationFunction<
  DeclineBuyoutMutation,
  DeclineBuyoutMutationVariables
>;

/**
 * __useDeclineBuyoutMutation__
 *
 * To run a mutation, you first call `useDeclineBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineBuyoutMutation, { data, loading, error }] = useDeclineBuyoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineBuyoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineBuyoutMutation,
    DeclineBuyoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineBuyoutMutation,
    DeclineBuyoutMutationVariables
  >(DeclineBuyoutDocument, options);
}
export type DeclineBuyoutMutationHookResult = ReturnType<
  typeof useDeclineBuyoutMutation
>;
export type DeclineBuyoutMutationResult =
  Apollo.MutationResult<DeclineBuyoutMutation>;
export type DeclineBuyoutMutationOptions = Apollo.BaseMutationOptions<
  DeclineBuyoutMutation,
  DeclineBuyoutMutationVariables
>;
export const DistributorBuyoutDocument = gql`
  query DistributorBuyout($id: ID!) {
    buyout(id: $id) {
      ...DistributorBuyoutFields
    }
  }
  ${DistributorBuyoutFieldsFragmentDoc}
`;

/**
 * __useDistributorBuyoutQuery__
 *
 * To run a query within a React component, call `useDistributorBuyoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorBuyoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorBuyoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorBuyoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorBuyoutQuery,
    DistributorBuyoutQueryVariables
  > &
    (
      | { variables: DistributorBuyoutQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorBuyoutQuery,
    DistributorBuyoutQueryVariables
  >(DistributorBuyoutDocument, options);
}
export function useDistributorBuyoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorBuyoutQuery,
    DistributorBuyoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorBuyoutQuery,
    DistributorBuyoutQueryVariables
  >(DistributorBuyoutDocument, options);
}
export function useDistributorBuyoutSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorBuyoutQuery,
        DistributorBuyoutQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorBuyoutQuery,
    DistributorBuyoutQueryVariables
  >(DistributorBuyoutDocument, options);
}
export type DistributorBuyoutQueryHookResult = ReturnType<
  typeof useDistributorBuyoutQuery
>;
export type DistributorBuyoutLazyQueryHookResult = ReturnType<
  typeof useDistributorBuyoutLazyQuery
>;
export type DistributorBuyoutSuspenseQueryHookResult = ReturnType<
  typeof useDistributorBuyoutSuspenseQuery
>;
export type DistributorBuyoutQueryResult = Apollo.QueryResult<
  DistributorBuyoutQuery,
  DistributorBuyoutQueryVariables
>;
export const CorrectInvoiceDocumentDocument = gql`
  mutation correctInvoiceDocument($input: CorrectInvoiceDocumentInput!) {
    correctInvoiceDocument(input: $input) {
      id
      issues {
        ...DistributorInvoiceIssueFields
      }
    }
  }
  ${DistributorInvoiceIssueFieldsFragmentDoc}
`;
export type CorrectInvoiceDocumentMutationFn = Apollo.MutationFunction<
  CorrectInvoiceDocumentMutation,
  CorrectInvoiceDocumentMutationVariables
>;

/**
 * __useCorrectInvoiceDocumentMutation__
 *
 * To run a mutation, you first call `useCorrectInvoiceDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorrectInvoiceDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [correctInvoiceDocumentMutation, { data, loading, error }] = useCorrectInvoiceDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCorrectInvoiceDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CorrectInvoiceDocumentMutation,
    CorrectInvoiceDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CorrectInvoiceDocumentMutation,
    CorrectInvoiceDocumentMutationVariables
  >(CorrectInvoiceDocumentDocument, options);
}
export type CorrectInvoiceDocumentMutationHookResult = ReturnType<
  typeof useCorrectInvoiceDocumentMutation
>;
export type CorrectInvoiceDocumentMutationResult =
  Apollo.MutationResult<CorrectInvoiceDocumentMutation>;
export type CorrectInvoiceDocumentMutationOptions = Apollo.BaseMutationOptions<
  CorrectInvoiceDocumentMutation,
  CorrectInvoiceDocumentMutationVariables
>;
export const ResolveInvoiceIssueDocument = gql`
  mutation resolveInvoiceIssue($input: ResolveInvoiceIssueInput!) {
    resolveInvoiceIssue(input: $input) {
      id
      status
      issues {
        ...DistributorInvoiceIssueFields
      }
    }
  }
  ${DistributorInvoiceIssueFieldsFragmentDoc}
`;
export type ResolveInvoiceIssueMutationFn = Apollo.MutationFunction<
  ResolveInvoiceIssueMutation,
  ResolveInvoiceIssueMutationVariables
>;

/**
 * __useResolveInvoiceIssueMutation__
 *
 * To run a mutation, you first call `useResolveInvoiceIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveInvoiceIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveInvoiceIssueMutation, { data, loading, error }] = useResolveInvoiceIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveInvoiceIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveInvoiceIssueMutation,
    ResolveInvoiceIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResolveInvoiceIssueMutation,
    ResolveInvoiceIssueMutationVariables
  >(ResolveInvoiceIssueDocument, options);
}
export type ResolveInvoiceIssueMutationHookResult = ReturnType<
  typeof useResolveInvoiceIssueMutation
>;
export type ResolveInvoiceIssueMutationResult =
  Apollo.MutationResult<ResolveInvoiceIssueMutation>;
export type ResolveInvoiceIssueMutationOptions = Apollo.BaseMutationOptions<
  ResolveInvoiceIssueMutation,
  ResolveInvoiceIssueMutationVariables
>;
export const DistributorInvoiceDocument = gql`
  query DistributorInvoice($id: ID!) {
    invoice(id: $id) {
      ...DistributorInvoiceFields
    }
  }
  ${DistributorInvoiceFieldsFragmentDoc}
`;

/**
 * __useDistributorInvoiceQuery__
 *
 * To run a query within a React component, call `useDistributorInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorInvoiceQuery,
    DistributorInvoiceQueryVariables
  > &
    (
      | { variables: DistributorInvoiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorInvoiceQuery,
    DistributorInvoiceQueryVariables
  >(DistributorInvoiceDocument, options);
}
export function useDistributorInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorInvoiceQuery,
    DistributorInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorInvoiceQuery,
    DistributorInvoiceQueryVariables
  >(DistributorInvoiceDocument, options);
}
export function useDistributorInvoiceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DistributorInvoiceQuery,
        DistributorInvoiceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributorInvoiceQuery,
    DistributorInvoiceQueryVariables
  >(DistributorInvoiceDocument, options);
}
export type DistributorInvoiceQueryHookResult = ReturnType<
  typeof useDistributorInvoiceQuery
>;
export type DistributorInvoiceLazyQueryHookResult = ReturnType<
  typeof useDistributorInvoiceLazyQuery
>;
export type DistributorInvoiceSuspenseQueryHookResult = ReturnType<
  typeof useDistributorInvoiceSuspenseQuery
>;
export type DistributorInvoiceQueryResult = Apollo.QueryResult<
  DistributorInvoiceQuery,
  DistributorInvoiceQueryVariables
>;
export const namedOperations = {
  Query: {
    Currencies: "Currencies",
    OrgNotificationCount: "OrgNotificationCount",
    MessageStats: "MessageStats",
    ExternalNotifications: "ExternalNotifications",
    Notifications: "Notifications",
    OrderTypeSummaries: "OrderTypeSummaries",
    TaxCodesSummary: "TaxCodesSummary",
    orgPreferredVendors: "orgPreferredVendors",
    AssignableBuyouts: "AssignableBuyouts",
    BuyoutMessagesContext: "BuyoutMessagesContext",
    BuyoutDistributorMessagesContext: "BuyoutDistributorMessagesContext",
    BuyoutReleaseDetails: "BuyoutReleaseDetails",
    buyoutStockings: "buyoutStockings",
    Buyouts: "Buyouts",
    CustomFileFormats: "CustomFileFormats",
    DistributorBuyoutReleases: "DistributorBuyoutReleases",
    DistributorBuyoutStockings: "DistributorBuyoutStockings",
    DistributorQuote: "DistributorQuote",
    DistributorQuoteDeliveries: "DistributorQuoteDeliveries",
    DistributorQuoteMessagesContext: "DistributorQuoteMessagesContext",
    DistributorRelease: "DistributorRelease",
    DownloadAsset: "DownloadAsset",
    Categories: "Categories",
    MasterSkus: "MasterSkus",
    EstimatedPrices: "EstimatedPrices",
    ExternalPO: "ExternalPO",
    ExternalPOs: "ExternalPOs",
    FacetsByCategory: "FacetsByCategory",
    FieldsPreview: "FieldsPreview",
    Manufacturers: "Manufacturers",
    MasterProducts: "MasterProducts",
    MasterSku: "MasterSku",
    MasterSkusByKeyword: "MasterSkusByKeyword",
    Messages: "Messages",
    PredictedReceiptNumber: "PredictedReceiptNumber",
    PredictedReleaseSequenceNumber: "PredictedReleaseSequenceNumber",
    PredictedTax: "PredictedTax",
    ProjectExternalProjectsWithEstimates:
      "ProjectExternalProjectsWithEstimates",
    ProjectListOptions: "ProjectListOptions",
    ProjectPredictedPoNumber: "ProjectPredictedPoNumber",
    QuoteMessagesContext: "QuoteMessagesContext",
    Release: "Release",
    ReleaseDistributorMessagesContext: "ReleaseDistributorMessagesContext",
    ReleaseMessagesContext: "ReleaseMessagesContext",
    ReleasePOLink: "ReleasePOLink",
    ReleaseProject: "ReleaseProject",
    Releases: "Releases",
    ReleasesSelector: "ReleasesSelector",
    Rfq: "Rfq",
    RfqQuotes: "RfqQuotes",
    RfqVendors: "RfqVendors",
    Rfqs: "Rfqs",
    StartupData: "StartupData",
    Uom: "Uom",
    Uoms: "Uoms",
    Users: "Users",
    Viewer: "Viewer",
    ViewerOrg: "ViewerOrg",
    CostTypes: "CostTypes",
    EquipmentItems: "EquipmentItems",
    ExternalEquipmentItems: "ExternalEquipmentItems",
    ExternalPaymentMethods: "ExternalPaymentMethods",
    PaymentMethods: "PaymentMethods",
    ExternalServiceCodes: "ExternalServiceCodes",
    ServiceCodes: "ServiceCodes",
    ExternalTaxCodes: "ExternalTaxCodes",
    ViewerTaxCodes: "ViewerTaxCodes",
    SystemReport: "SystemReport",
    ExternalCostCodes: "ExternalCostCodes",
    ExternalCostTypes: "ExternalCostTypes",
    ExternalLedgerAccounts: "ExternalLedgerAccounts",
    ExternalVendors: "ExternalVendors",
    InventorySummary: "InventorySummary",
    Locations: "Locations",
    ComplianceGroups: "ComplianceGroups",
    ExternalPOTypes: "ExternalPOTypes",
    OrderTypes: "OrderTypes",
    VendorPrices: "VendorPrices",
    ExternalItems: "ExternalItems",
    OrgMaterial: "OrgMaterial",
    OrgMaterials: "OrgMaterials",
    OrgMaterialsSummary: "OrgMaterialsSummary",
    OrgSettingsExtended: "OrgSettingsExtended",
    ViewerOrgNotifications: "ViewerOrgNotifications",
    Enterprises: "Enterprises",
    AdminUsers: "AdminUsers",
    EnterpriseUsers: "EnterpriseUsers",
    ExternalVendor: "ExternalVendor",
    Orgs: "Orgs",
    AdminOrgPreferredVendors: "AdminOrgPreferredVendors",
    EnterpriseVendors: "EnterpriseVendors",
    Warehouses: "Warehouses",
    BuyoutReleases: "BuyoutReleases",
    Buyout: "Buyout",
    NoteDocument: "NoteDocument",
    QuoteDocument: "QuoteDocument",
    InventoryItemTransactions: "InventoryItemTransactions",
    InventoryTransaction: "InventoryTransaction",
    InventoryItems: "InventoryItems",
    InventoryTransactions: "InventoryTransactions",
    InvoiceEmails: "InvoiceEmails",
    Invoice: "Invoice",
    InvoiceKickbackEligibleUsers: "InvoiceKickbackEligibleUsers",
    InvoicePermissions: "InvoicePermissions",
    OrgFolders: "OrgFolders",
    Asset: "Asset",
    InvoiceStats: "InvoiceStats",
    InvoiceValidation: "InvoiceValidation",
    Invoices: "Invoices",
    InvoicesByIds: "InvoicesByIds",
    ProjectAllowance: "ProjectAllowance",
    ProjectReportPerMaterial: "ProjectReportPerMaterial",
    ProjectReportPerMaterialZone: "ProjectReportPerMaterialZone",
    ProjectReportPerVendor: "ProjectReportPerVendor",
    ProjectReportReleases: "ProjectReportReleases",
    ExternalProject: "ExternalProject",
    ExternalProjects: "ExternalProjects",
    Project: "Project",
    ProjectStore: "ProjectStore",
    ProjectEstimatedItemsCount: "ProjectEstimatedItemsCount",
    Projects: "Projects",
    Receipt: "Receipt",
    Receipts: "Receipts",
    ReceiptsByIds: "ReceiptsByIds",
    ExternalBatch: "ExternalBatch",
    ExternalWarehouses: "ExternalWarehouses",
    POValidation: "POValidation",
    ReleasesByIds: "ReleasesByIds",
    SiteContacts: "SiteContacts",
    ReleasePredictedPoNumber: "ReleasePredictedPoNumber",
    ReleaseItemDeliveredItems: "ReleaseItemDeliveredItems",
    ReleaseItemInvoiceItems: "ReleaseItemInvoiceItems",
    ReleasesToCsvExport: "ReleasesToCsvExport",
    DeliverySlip: "DeliverySlip",
    DeliverySlipStats: "DeliverySlipStats",
    DeliverySlips: "DeliverySlips",
    OrgMaterialsFiltered: "OrgMaterialsFiltered",
    ViewerNotifications: "ViewerNotifications",
    DistributorBuyout: "DistributorBuyout",
    DistributorInvoice: "DistributorInvoice",
  },
  Mutation: {
    UpdateInvoiceOptions: "UpdateInvoiceOptions",
    MarkMessageThreadAsRead: "MarkMessageThreadAsRead",
    DeleteNotification: "DeleteNotification",
    ReadNotification: "ReadNotification",
    ResendExternalNotification: "ResendExternalNotification",
    AcceptQuote: "AcceptQuote",
    AddEstimatedItem: "AddEstimatedItem",
    AddToRelease: "AddToRelease",
    AddVendorLocation: "AddVendorLocation",
    AddVendorOrg: "AddVendorOrg",
    AddZone: "AddZone",
    AddZones: "AddZones",
    ArchiveRelease: "ArchiveRelease",
    CancelRfq: "CancelRfq",
    cancelRFQBuyouts: "cancelRFQBuyouts",
    ConnectOrgPreferredVendor: "ConnectOrgPreferredVendor",
    ConnectSourceSystem: "ConnectSourceSystem",
    CreateAsset: "CreateAsset",
    CreateBuyout: "CreateBuyout",
    CreateCustomFileFormat: "CreateCustomFileFormat",
    CreateEditCopyRfqForChanges: "CreateEditCopyRfqForChanges",
    CreateManufacturer: "CreateManufacturer",
    CreateManufacturers: "CreateManufacturers",
    CreateOrg: "CreateOrg",
    CreateOrgCatalogSku: "CreateOrgCatalogSku",
    CreateOrgMaterial: "CreateOrgMaterial",
    CreateQuoteDocument: "CreateQuoteDocument",
    CreateReservedRelease: "CreateReservedRelease",
    CreateStandaloneRelease: "CreateStandaloneRelease",
    CreateUser: "CreateUser",
    CreateVendor: "CreateVendor",
    ArchiveBuyout: "ArchiveBuyout",
    DeleteCustomFileFormat: "DeleteCustomFileFormat",
    DeleteUser: "DeleteUser",
    DisconnectOrgPreferredVendor: "DisconnectOrgPreferredVendor",
    DisconnectSourceSystem: "DisconnectSourceSystem",
    ExportQuoteSelection: "ExportQuoteSelection",
    ExportReleases: "ExportReleases",
    ImportBuyout: "ImportBuyout",
    ImportOrgCatalog: "ImportOrgCatalog",
    InitializeSMSVerification: "InitializeSMSVerification",
    InitializeSourceSystemConnection: "InitializeSourceSystemConnection",
    RefreshExternalPOs: "RefreshExternalPOs",
    RemoveEstimatedItem: "RemoveEstimatedItem",
    RemoveEstimatedItems: "RemoveEstimatedItems",
    removeFromBuyout: "removeFromBuyout",
    RemoveOrgCatalogSku: "RemoveOrgCatalogSku",
    RemoveRfq: "RemoveRfq",
    RemoveVendor: "RemoveVendor",
    RemoveVendorPrice: "RemoveVendorPrice",
    RemoveZone: "RemoveZone",
    ResendQuote: "ResendQuote",
    ResendSMSVerification: "ResendSMSVerification",
    ResolveReleaseIssues: "ResolveReleaseIssues",
    scheduleRelease: "scheduleRelease",
    SendMessage: "SendMessage",
    SubmitQuote: "SubmitQuote",
    SubmitRfq: "SubmitRfq",
    SubmitSMSVerification: "SubmitSMSVerification",
    UnarchiveReleases: "UnarchiveReleases",
    UpdateAsset: "UpdateAsset",
    UpdateContractorRelease: "UpdateContractorRelease",
    UpdateEstimatedItems: "UpdateEstimatedItems",
    UpdateEstimatedItemsTags: "UpdateEstimatedItemsTags",
    UpdateEstimatesWithFile: "UpdateEstimatesWithFile",
    UpdateOrg: "UpdateOrg",
    updateOrgMaterials: "updateOrgMaterials",
    UpdateQuote: "UpdateQuote",
    UpdateUser: "UpdateUser",
    UpdateVendorBuyout: "UpdateVendorBuyout",
    UpdateVendorOrg: "UpdateVendorOrg",
    UpdateVendorRelease: "UpdateVendorRelease",
    UpdateViewerProfile: "UpdateViewerProfile",
    UpdateZone: "UpdateZone",
    WithdrawQuote: "WithdrawQuote",
    AddCostCode: "AddCostCode",
    ConnectCostCodeLedgerAccount: "ConnectCostCodeLedgerAccount",
    DisconnectCostCodeLedgerAccount: "DisconnectCostCodeLedgerAccount",
    ImportCostCodes: "ImportCostCodes",
    RemoveCostCode: "RemoveCostCode",
    UpdateCostCode: "UpdateCostCode",
    UpdateCostCodes: "UpdateCostCodes",
    UpdateCostCodesWithFile: "UpdateCostCodesWithFile",
    ImportCostTypes: "ImportCostTypes",
    UpdateCostTypes: "UpdateCostTypes",
    ImportEquipmentItems: "ImportEquipmentItems",
    UpdateEquipmentItems: "UpdateEquipmentItems",
    ImportPaymentMethods: "ImportPaymentMethods",
    UpdatePaymentMethods: "UpdatePaymentMethods",
    ImportServiceCodes: "ImportServiceCodes",
    UpdateServiceCodes: "UpdateServiceCodes",
    ImportTaxCodes: "ImportTaxCodes",
    UpdateTaxCodes: "UpdateTaxCodes",
    PreviewCustomInvoiceTemplates: "PreviewCustomInvoiceTemplates",
    PreviewCustomPoTemplates: "PreviewCustomPoTemplates",
    PreviewInvoiceTemplate: "PreviewInvoiceTemplate",
    PreviewPoTemplate: "PreviewPoTemplate",
    CreateLocation: "CreateLocation",
    DeleteLocation: "DeleteLocation",
    UpdateLocation: "UpdateLocation",
    ImportComplianceGroups: "ImportComplianceGroups",
    UpdateComplianceGroups: "UpdateComplianceGroups",
    ArchiveOrderType: "ArchiveOrderType",
    CreateOrderType: "CreateOrderType",
    UnarchiveOrderType: "UnarchiveOrderType",
    updateOrderType: "updateOrderType",
    addVendorPrice: "addVendorPrice",
    SetVendorPrices: "SetVendorPrices",
    UpdateVendorPrice: "UpdateVendorPrice",
    ConnectOrgMaterial: "ConnectOrgMaterial",
    DisconnectOrgMaterial: "DisconnectOrgMaterial",
    ImportOrgMaterials: "ImportOrgMaterials",
    RemoveOrgMaterial: "RemoveOrgMaterial",
    UpdateOrgSettings: "UpdateOrgSettings",
    ImportVendors: "ImportVendors",
    UpdateVendor: "UpdateVendor",
    UpdateVendorLocation: "UpdateVendorLocation",
    RefreshExternalVendors: "RefreshExternalVendors",
    AddWarehouse: "AddWarehouse",
    ArchiveWarehouse: "ArchiveWarehouse",
    UpdateWarehouse: "UpdateWarehouse",
    ActivateBuyout: "ActivateBuyout",
    AddToBuyout: "AddToBuyout",
    CancelBuyout: "CancelBuyout",
    SubmitBuyout: "SubmitBuyout",
    UpdateContractorBuyout: "UpdateContractorBuyout",
    ArchiveBuyouts: "ArchiveBuyouts",
    CreateNoteDocument: "CreateNoteDocument",
    CreateInventoryAdjustment: "CreateInventoryAdjustment",
    CreateInventoryTransfer: "CreateInventoryTransfer",
    ArchiveInventoryItems: "ArchiveInventoryItems",
    ArchiveInvoiceEmails: "ArchiveInvoiceEmails",
    ArchiveInvoiceEmail: "ArchiveInvoiceEmail",
    ApproveInvoice: "ApproveInvoice",
    ConnectMissingPOItems: "ConnectMissingPOItems",
    CreateInvoiceIssue: "CreateInvoiceIssue",
    CreateInvoiceKickback: "CreateInvoiceKickback",
    CreateInvoiceKickbackComment: "CreateInvoiceKickbackComment",
    DeleteInvoiceIssue: "DeleteInvoiceIssue",
    DeleteInvoiceKickback: "DeleteInvoiceKickback",
    DeleteInvoiceKickbackComment: "DeleteInvoiceKickbackComment",
    DownloadInvoiceDocument: "DownloadInvoiceDocument",
    DownloadInvoiceDocuments: "DownloadInvoiceDocuments",
    RescanInvoice: "RescanInvoice",
    ResolveInvoiceKickback: "ResolveInvoiceKickback",
    UnarchiveInvoices: "UnarchiveInvoices",
    UpdateInvoice: "UpdateInvoice",
    UpdateInvoiceIssue: "UpdateInvoiceIssue",
    UpdateInvoiceKickback: "UpdateInvoiceKickback",
    UpdateInvoiceKickbackComment: "UpdateInvoiceKickbackComment",
    CreateInvoiceFolder: "CreateInvoiceFolder",
    DeleteInvoiceFolder: "DeleteInvoiceFolder",
    UpdateInvoiceFolder: "UpdateInvoiceFolder",
    ArchiveInvoice: "ArchiveInvoice",
    ArchiveInvoices: "ArchiveInvoices",
    ClearPoNumber: "ClearPoNumber",
    CreateInvoices: "CreateInvoices",
    ExportInvoices: "ExportInvoices",
    LinkInvoice: "LinkInvoice",
    SplitInvoiceDocument: "SplitInvoiceDocument",
    UnlinkInvoice: "UnlinkInvoice",
    UpdateInvoiceEmailAttachment: "UpdateInvoiceEmailAttachment",
    UpdateInvoices: "UpdateInvoices",
    ConnectProject: "ConnectProject",
    CopyEstimatedItems: "CopyEstimatedItems",
    DisconnectProject: "DisconnectProject",
    ImportEstimate: "ImportEstimate",
    ImportProjectTags: "ImportProjectTags",
    ImportZones: "ImportZones",
    RefreshExternalProjects: "RefreshExternalProjects",
    UpdateTags: "UpdateTags",
    LinkBudget: "LinkBudget",
    SyncBudget: "SyncBudget",
    UnlinkBudget: "UnlinkBudget",
    UpdateBudgetLink: "UpdateBudgetLink",
    UpdateProjectAllowance: "UpdateProjectAllowance",
    ArchiveProjects: "ArchiveProjects",
    CreateProject: "CreateProject",
    CreateProjects: "CreateProjects",
    DeleteProject: "DeleteProject",
    UpdateProject: "UpdateProject",
    ApproveReceipt: "ApproveReceipt",
    ArchiveReceipt: "ArchiveReceipt",
    CreateReceipt: "CreateReceipt",
    CreateReceiptKickback: "CreateReceiptKickback",
    DeleteReceiptKickback: "DeleteReceiptKickback",
    ExportReceipts: "ExportReceipts",
    LinkReceipt: "LinkReceipt",
    ResolveReceiptKickback: "ResolveReceiptKickback",
    UpdateReceipt: "UpdateReceipt",
    UpdateReceiptKickback: "UpdateReceiptKickback",
    CreateExternalBatch: "CreateExternalBatch",
    CreateMaterialConversions: "CreateMaterialConversions",
    LinkPO: "LinkPO",
    ReverseSyncPO: "ReverseSyncPO",
    SyncPO: "SyncPO",
    UnlinkPO: "UnlinkPO",
    UpdateContractorReleases: "UpdateContractorReleases",
    UpdatePOLink: "UpdatePOLink",
    ArchiveSiteContact: "ArchiveSiteContact",
    CreateSiteContact: "CreateSiteContact",
    ArchiveReleases: "ArchiveReleases",
    CreateOrderGroup: "CreateOrderGroup",
    DeleteOrderGroup: "DeleteOrderGroup",
    UpdateOrderGroup: "UpdateOrderGroup",
    UpdateReleases: "UpdateReleases",
    ArchiveDeliverySlip: "ArchiveDeliverySlip",
    CreateDeliverySlip: "CreateDeliverySlip",
    ReplaceDeliverySlip: "ReplaceDeliverySlip",
    RescanDeliverySlip: "RescanDeliverySlip",
    UpdateDeliverySlip: "UpdateDeliverySlip",
    ArchiveRfqs: "ArchiveRfqs",
    CreateRfq: "CreateRfq",
    UpdateRfq: "UpdateRfq",
    AcceptBuyout: "AcceptBuyout",
    DeclineBuyout: "DeclineBuyout",
    correctInvoiceDocument: "correctInvoiceDocument",
    resolveInvoiceIssue: "resolveInvoiceIssue",
  },
  Fragment: {
    CurrencyFields: "CurrencyFields",
    ExternalNotificationFields: "ExternalNotificationFields",
    NotificationBuyoutChangeContextFields:
      "NotificationBuyoutChangeContextFields",
    NotificationFields: "NotificationFields",
    NotificationInvoiceChangeContextFields:
      "NotificationInvoiceChangeContextFields",
    NotificationMessageContextFields: "NotificationMessageContextFields",
    NotificationQuoteChangeContextFields:
      "NotificationQuoteChangeContextFields",
    NotificationReleaseChangeContextFields:
      "NotificationReleaseChangeContextFields",
    OrderTypeSummaryFields: "OrderTypeSummaryFields",
    TaxCodeSummaryFields: "TaxCodeSummaryFields",
    ExternalInsuranceFields: "ExternalInsuranceFields",
    OrgPreferredVendorsFields: "OrgPreferredVendorsFields",
    PreferredVendorExternalVendorFields: "PreferredVendorExternalVendorFields",
    InvoicedReleaseItemFields: "InvoicedReleaseItemFields",
    AdditionalChargesFields: "AdditionalChargesFields",
    AddressFields: "AddressFields",
    AggregatedCostCodeFields: "AggregatedCostCodeFields",
    AggregatedItemFields: "AggregatedItemFields",
    AggregatedZoneFields: "AggregatedZoneFields",
    AssetFields: "AssetFields",
    BuyoutDistributorMessagesContextFields:
      "BuyoutDistributorMessagesContextFields",
    BuyoutMessagesContextFields: "BuyoutMessagesContextFields",
    BuyoutQuoteFields: "BuyoutQuoteFields",
    BuyoutReleaseDetailsFields: "BuyoutReleaseDetailsFields",
    BuyoutReleaseDetailsItemFields: "BuyoutReleaseDetailsItemFields",
    BuyoutReleaseFields: "BuyoutReleaseFields",
    BuyoutReleaseItemFields: "BuyoutReleaseItemFields",
    BuyoutStockingPriceFields: "BuyoutStockingPriceFields",
    BuyoutsBuyoutFields: "BuyoutsBuyoutFields",
    CostCodeSummaryFields: "CostCodeSummaryFields",
    DistributorBuyoutFields: "DistributorBuyoutFields",
    DistributorBuyoutItemFields: "DistributorBuyoutItemFields",
    DistributorBuyoutQuoteFields: "DistributorBuyoutQuoteFields",
    DistributorBuyoutReleaseFields: "DistributorBuyoutReleaseFields",
    DistributorBuyoutReleasesFields: "DistributorBuyoutReleasesFields",
    DistributorBuyoutStockingPriceFields:
      "DistributorBuyoutStockingPriceFields",
    DistributorCostCodeFields: "DistributorCostCodeFields",
    DistributorProjectItemFields: "DistributorProjectItemFields",
    DistributorProjectMaterialFields: "DistributorProjectMaterialFields",
    DistributorQuotesAuxiliaryQuoteItemFields:
      "DistributorQuotesAuxiliaryQuoteItemFields",
    DistributorQuoteCommonFields: "DistributorQuoteCommonFields",
    DistributorQuoteFields: "DistributorQuoteFields",
    DistributorQuoteItemFields: "DistributorQuoteItemFields",
    DistributorQuoteItemGroupFields: "DistributorQuoteItemGroupFields",
    DistributorQuoteItemGroupRfqItemFields:
      "DistributorQuoteItemGroupRfqItemFields",
    DistributorQuoteMessageContextFields:
      "DistributorQuoteMessageContextFields",
    DistributorReleaseFields: "DistributorReleaseFields",
    DistributorReleaseInvoiceFields: "DistributorReleaseInvoiceFields",
    DistributorReleaseItemFields: "DistributorReleaseItemFields",
    EstimatedItemExtendedFields: "EstimatedItemExtendedFields",
    EstimatedItemFields: "EstimatedItemFields",
    ExternalPOBaseFields: "ExternalPOBaseFields",
    ExternalPOFields: "ExternalPOFields",
    ExternalPOItemFields: "ExternalPOItemFields",
    InstructionFields: "InstructionFields",
    ManufacturerFields: "ManufacturerFields",
    MasterProductsProductFields: "MasterProductsProductFields",
    MasterSkuSkuFields: "MasterSkuSkuFields",
    MasterSkusByKeywordSkuFields: "MasterSkusByKeywordSkuFields",
    MasterSkusFacetFields: "MasterSkusFacetFields",
    MasterSkusProductFields: "MasterSkusProductFields",
    MasterSkusSkuFields: "MasterSkusSkuFields",
    MessageFields: "MessageFields",
    OrgFields: "OrgFields",
    OrgLocationFields: "OrgLocationFields",
    OrgMaterialSummaryFields: "OrgMaterialSummaryFields",
    OrgSkuFields: "OrgSkuFields",
    OrganizationFields: "OrganizationFields",
    OrgsOrgLocationFields: "OrgsOrgLocationFields",
    PageInfoFields: "PageInfoFields",
    PageRangeFields: "PageRangeFields",
    PartialProjectItemFields: "PartialProjectItemFields",
    PartialProjectItemReleaseItemFields: "PartialProjectItemReleaseItemFields",
    PaymentMethodFields: "PaymentMethodFields",
    ProjectExtendedFields: "ProjectExtendedFields",
    ProjectExternalProjectsWithEstimatesFields:
      "ProjectExternalProjectsWithEstimatesFields",
    ProjectFields: "ProjectFields",
    ProjectItemExtendedFields: "ProjectItemExtendedFields",
    ProjectItemFields: "ProjectItemFields",
    ProjectListOptionFields: "ProjectListOptionFields",
    ProjectPredictedPoNumberFields: "ProjectPredictedPoNumberFields",
    QuoteMessageContextFields: "QuoteMessageContextFields",
    QuoteStockingPriceFields: "QuoteStockingPriceFields",
    ReleaseApprovalFields: "ReleaseApprovalFields",
    ReleaseApprovalProgressFields: "ReleaseApprovalProgressFields",
    ReleaseApprovalSlotFields: "ReleaseApprovalSlotFields",
    ReleaseDeliverySlipFields: "ReleaseDeliverySlipFields",
    ReleaseDistributorMessagesContextFields:
      "ReleaseDistributorMessagesContextFields",
    ReleaseFields: "ReleaseFields",
    ReleaseIssueResolutionFields: "ReleaseIssueResolutionFields",
    ReleaseIssuesFields: "ReleaseIssuesFields",
    ReleaseItemFields: "ReleaseItemFields",
    ReleaseMessagesContextFields: "ReleaseMessagesContextFields",
    ReleasePartialFields: "ReleasePartialFields",
    ReleaseProjectFields: "ReleaseProjectFields",
    ReleaseProjectItemFields: "ReleaseProjectItemFields",
    ReleaseReassignmentFields: "ReleaseReassignmentFields",
    ReleaseSelectorFields: "ReleaseSelectorFields",
    RfqFields: "RfqFields",
    RfqItemFields: "RfqItemFields",
    RfqQuotesAuxiliaryQuoteItemFields: "RfqQuotesAuxiliaryQuoteItemFields",
    RfqQuotesFields: "RfqQuotesFields",
    RfqQuotesPreferredVendorFields: "RfqQuotesPreferredVendorFields",
    RfqQuotesQuoteFields: "RfqQuotesQuoteFields",
    RfqQuotesQuoteItemFields: "RfqQuotesQuoteItemFields",
    RfqQuotesQuoteItemGroupFields: "RfqQuotesQuoteItemGroupFields",
    RfqVendorFields: "RfqVendorFields",
    RfqsRfqFields: "RfqsRfqFields",
    ServicePriceFields: "ServicePriceFields",
    SkuFields: "SkuFields",
    SMSVerificationFields: "SMSVerificationFields",
    StartupDataFields: "StartupDataFields",
    TagFields: "TagFields",
    TagExtendedFields: "TagExtendedFields",
    TagSummaryFields: "TagSummaryFields",
    UOMFields: "UOMFields",
    UpdateEstimatesWithFileOutputFields: "UpdateEstimatesWithFileOutputFields",
    UpdateVendorReleaseFields: "UpdateVendorReleaseFields",
    UserViewerFields: "UserViewerFields",
    UsersUserFields: "UsersUserFields",
    VendorContactFields: "VendorContactFields",
    ViewerOrgFields: "ViewerOrgFields",
    ViewerOrgLocationFields: "ViewerOrgLocationFields",
    WarehouseFields: "WarehouseFields",
    ZoneExtendedFields: "ZoneExtendedFields",
    ZoneFields: "ZoneFields",
    CostCodeFields: "CostCodeFields",
    CostTypeFields: "CostTypeFields",
    EquipmentItemFields: "EquipmentItemFields",
    ExternalEquipmentItemFields: "ExternalEquipmentItemFields",
    ExternalPaymentMethodFields: "ExternalPaymentMethodFields",
    ExternalServiceCodeFields: "ExternalServiceCodeFields",
    ServiceCodeFields: "ServiceCodeFields",
    ExternalTaxCodeFields: "ExternalTaxCodeFields",
    TaxCodeFields: "TaxCodeFields",
    ExternalCostCodeFields: "ExternalCostCodeFields",
    ExternalCostTypeFields: "ExternalCostTypeFields",
    ExternalLedgerAccountFields: "ExternalLedgerAccountFields",
    ExternalLedgerSubaccountFields: "ExternalLedgerSubaccountFields",
    ExternalVendorFields: "ExternalVendorFields",
    InventoryItemFields: "InventoryItemFields",
    InventoryItemStateFields: "InventoryItemStateFields",
    InventoryItemStrippedFields: "InventoryItemStrippedFields",
    OrgLocationExtendedFields: "OrgLocationExtendedFields",
    ComplianceGroupFields: "ComplianceGroupFields",
    ExternalComplianceGroupFields: "ExternalComplianceGroupFields",
    ExternalPOTypeFields: "ExternalPOTypeFields",
    MaterialSummaryFields: "MaterialSummaryFields",
    OrderTypeFields: "OrderTypeFields",
    VendorPriceFields: "VendorPriceFields",
    ExternalItemFields: "ExternalItemFields",
    OrgMaterialFields: "OrgMaterialFields",
    PartialOrgMaterialFields: "PartialOrgMaterialFields",
    ProjectMaterialExtendedFields: "ProjectMaterialExtendedFields",
    ProjectMaterialFields: "ProjectMaterialFields",
    ProjectMaterialOrgCatalogSkuFields: "ProjectMaterialOrgCatalogSkuFields",
    ProjectMaterialProductFields: "ProjectMaterialProductFields",
    ProjectMaterialSkuFields: "ProjectMaterialSkuFields",
    AccountingSettingsFields: "AccountingSettingsFields",
    IntegrationSettingsExtendedFields: "IntegrationSettingsExtendedFields",
    IntegrationSettingsFields: "IntegrationSettingsFields",
    LedgerAccountFields: "LedgerAccountFields",
    OrgSettingsExtendedFields: "OrgSettingsExtendedFields",
    OrgSettingsFields: "OrgSettingsFields",
    ProgressFields: "ProgressFields",
    SourceSystemMappingFields: "SourceSystemMappingFields",
    SourceSystemSettingExtendedFields: "SourceSystemSettingExtendedFields",
    SourceSystemSettingFields: "SourceSystemSettingFields",
    NotificationSettingsFields: "NotificationSettingsFields",
    EnterpriseOrgFields: "EnterpriseOrgFields",
    EnterpriseFields: "EnterpriseFields",
    AdminUsersUserFields: "AdminUsersUserFields",
    AdminOrgPreferredVendorsFields: "AdminOrgPreferredVendorsFields",
    BuyoutReleasesFields: "BuyoutReleasesFields",
    BuyoutFields: "BuyoutFields",
    BuyoutItemFields: "BuyoutItemFields",
    BuyoutItemReleaseItemFields: "BuyoutItemReleaseItemFields",
    BuyoutStandaloneReleaseItemFields: "BuyoutStandaloneReleaseItemFields",
    NoteDocumentFields: "NoteDocumentFields",
    NoteDocumentItemFields: "NoteDocumentItemFields",
    NoteDocumentSummaryFields: "NoteDocumentSummaryFields",
    QuoteDocumentFields: "QuoteDocumentFields",
    QuoteDocumentItemFields: "QuoteDocumentItemFields",
    QuoteDocumentSummaryFields: "QuoteDocumentSummaryFields",
    InventoryTransactionFields: "InventoryTransactionFields",
    InventoryTransactionItemFields: "InventoryTransactionItemFields",
    InventoryTransactionSummaryFields: "InventoryTransactionSummaryFields",
    InventoryItemPendingFlowFields: "InventoryItemPendingFlowFields",
    InventoryItemStateSummaryFields: "InventoryItemStateSummaryFields",
    InventoryItemSummaryFields: "InventoryItemSummaryFields",
    InventoryTransactionItemSummaryFields:
      "InventoryTransactionItemSummaryFields",
    InvoiceDocumentFields: "InvoiceDocumentFields",
    InvoiceEmailAttachmentFields: "InvoiceEmailAttachmentFields",
    InvoiceEmailFields: "InvoiceEmailFields",
    InvoiceFields: "InvoiceFields",
    InvoiceIssueFields: "InvoiceIssueFields",
    InvoiceItemFields: "InvoiceItemFields",
    InvoiceKickbackCommentFields: "InvoiceKickbackCommentFields",
    InvoiceKickbackFields: "InvoiceKickbackFields",
    ReleaseItemHintFragment: "ReleaseItemHintFragment",
    InvoiceFolderFields: "InvoiceFolderFields",
    InvoiceLinkFields: "InvoiceLinkFields",
    InvoiceSummaryFields: "InvoiceSummaryFields",
    InvoiceToExportFields: "InvoiceToExportFields",
    InvoiceValidationFields: "InvoiceValidationFields",
    KickbackSummaryFields: "KickbackSummaryFields",
    ExternalAddressFields: "ExternalAddressFields",
    ExternalEstimateFields: "ExternalEstimateFields",
    ExternalProjectEssentialFields: "ExternalProjectEssentialFields",
    ExternalProjectFields: "ExternalProjectFields",
    ExternalProjectWithEstimatesFields: "ExternalProjectWithEstimatesFields",
    ProjectCostCodeFields: "ProjectCostCodeFields",
    EstimatedItemGroupFields: "EstimatedItemGroupFields",
    ProjectAllowanceFields: "ProjectAllowanceFields",
    ProjectBudgetLinkFields: "ProjectBudgetLinkFields",
    ProjectReportCostCodeFields: "ProjectReportCostCodeFields",
    ProjectReportCostCodeVendorFields: "ProjectReportCostCodeVendorFields",
    ProjectReportMaterialFields: "ProjectReportMaterialFields",
    ProjectReportPerMaterialFields: "ProjectReportPerMaterialFields",
    ProjectReportPerMaterialZoneFields: "ProjectReportPerMaterialZoneFields",
    ProjectReportPerVendorFields: "ProjectReportPerVendorFields",
    ProjectReportReleasesFields: "ProjectReportReleasesFields",
    ProjectReportVendorFields: "ProjectReportVendorFields",
    ProjectReportZoneFields: "ProjectReportZoneFields",
    ProjectProjectsFields: "ProjectProjectsFields",
    ReceiptDocumentFields: "ReceiptDocumentFields",
    ReceiptFields: "ReceiptFields",
    ReceiptItemFields: "ReceiptItemFields",
    ReceiptLinkFields: "ReceiptLinkFields",
    ReceiptSummaryFields: "ReceiptSummaryFields",
    ReceiptToExportFields: "ReceiptToExportFields",
    ExternalBatchFields: "ExternalBatchFields",
    ExternalWarehouseFields: "ExternalWarehouseFields",
    MaterialConversionFields: "MaterialConversionFields",
    POLinkFields: "POLinkFields",
    POMaterialConversionFields: "POMaterialConversionFields",
    POValidationFields: "POValidationFields",
    ReleaseSummaryFields: "ReleaseSummaryFields",
    SiteContactFields: "SiteContactFields",
    ReleaseGroupFields: "ReleaseGroupFields",
    ReleaseToCsvExportFields: "ReleaseToCsvExportFields",
    DeliveredReleaseItemsFields: "DeliveredReleaseItemsFields",
    DeliverySlipFields: "DeliverySlipFields",
    DeliverySlipItemFields: "DeliverySlipItemFields",
    DeliverySlipPartialFields: "DeliverySlipPartialFields",
    DistributorDeliverySlipFields: "DistributorDeliverySlipFields",
    DistributorInvoiceDocumentFields: "DistributorInvoiceDocumentFields",
    DistributorInvoiceFields: "DistributorInvoiceFields",
    DistributorInvoiceIssueFields: "DistributorInvoiceIssueFields",
    DistributorInvoiceItemFields: "DistributorInvoiceItemFields",
  },
};
