import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Dialog } from "@/common/components/dialog/Dialog";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { PrintButton } from "@/common/components/print-button/PrintButton";
import { Switch } from "@/common/components/switch/Switch";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import { ExpandedRelease } from "../../providers/ReleaseProvider";
import { useReleaseStore } from "../../store/useReleaseStore";
import { ReleaseItemFilter } from "../../types/ReleaseItemsFilters";
import { usePrintView } from "./hooks/usePrintView";

const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-50 hover:cursor-pointer
`;

const ContentContainer = tw.div`flex flex-col mt-4 gap-6 self-center`;
const Label = tw.span``;
const Row = tw.div`flex-1 flex items-center justify-between gap-4`;

type PrintButtonProps = {
  release: ExpandedRelease;
  canGroupPrintData: boolean;
};

export const ReleasePrintButton: FC<PrintButtonProps> = ({
  release,
  canGroupPrintData,
}) => {
  const { groupedByCostCode, filter, filteredTags } =
    useReleaseItemsZoneProvider();
  const { savePDF } = usePrintView(
    release,
    groupedByCostCode && canGroupPrintData,
    filter[ReleaseItemFilter.FULLY_RECEIVED],
    filter[ReleaseItemFilter.FULLY_INVOICED],
    filteredTags,
  );
  const { hasChanges } = useReleaseStore();
  const { openDialog, cancelDialog: handleCancel } = useDialog();
  const intl = useIntl();
  const [includeSignatureBlock, setIncludeSignatureBlock] = useState(false);
  const [includePrices, setIncludePrices] = useState(true);
  const [includeCostCodeAndCostType, setIncludeCostCodeAndCostType] =
    useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const { settings } = useOrgSettings();

  const closeModalDialog = useCallback(() => setShowDialog(false), []);

  const openModalDialog = useCallback(() => setShowDialog(true), []);

  const showUnsavedChangesDialog = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PRINT_WITHOUT_SAVING" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "UNSAVED_CHANGES_PRINT_TITLE" }),
      text: intl.$t({ id: "UNSAVED_CHANGES_PRINT_DESCRIPTION" }),
      closeOnConfirm: true,
      closeOnCustom: true,
      handleCancel,
      handleConfirm: openModalDialog,
    });
  }, [openModalDialog, handleCancel, intl, openDialog]);

  const onClick = useCallback(() => {
    if (hasChanges) {
      return showUnsavedChangesDialog();
    }

    setShowDialog(true);
  }, [hasChanges, showUnsavedChangesDialog]);

  const handlePrint = useCallback(() => {
    savePDF({
      includePrices,
      includeSignatureBlock,
      includeCostCodeAndCostType,
    });
    closeModalDialog();
  }, [
    savePDF,
    includePrices,
    includeSignatureBlock,
    includeCostCodeAndCostType,
    closeModalDialog,
  ]);

  return (
    <>
      <IconButtonStyled onClick={onClick}>
        <PrintButton />
      </IconButtonStyled>
      <Dialog
        show={showDialog}
        handleCancel={closeModalDialog}
        handleConfirm={handlePrint}
        confirmButtonText={intl.$t({ id: "PRINT" })}
        cancelButtonText={intl.$t({ id: "CANCEL" })}
        title={intl.$t({ id: "PRINT_ORDER" })}
        includeInfoIcon
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "480px",
          },
        }}
        content={
          <ContentContainer>
            <Row>
              <Label className="font-medium">
                <FormattedMessage id="ADD_SIGNATURE_BLOCK" />
              </Label>
              <Switch
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                onChange={setIncludeSignatureBlock}
                value={includeSignatureBlock}
              />
            </Row>
            <Row>
              <Label className="font-medium">
                <FormattedMessage id="INCLUDE_PRICES" />
              </Label>
              <Switch
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                onChange={setIncludePrices}
                value={includePrices}
              />
            </Row>
            <Row>
              <Label className="font-medium">
                <FormattedMessage
                  id={
                    settings?.display?.itemCostTypes
                      ? "INCLUDE_COST_CODE_AND_COST_TYPE"
                      : "INCLUDE_COST_CODE"
                  }
                />
              </Label>
              <Switch
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                onChange={setIncludeCostCodeAndCostType}
                value={includeCostCodeAndCostType}
              />
            </Row>
          </ContentContainer>
        }
      />
    </>
  );
};
