import { DecimalSafe } from "@/common/utils/decimalSafe";
import { AddToRfqItemInput } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

export type AddToRfqItem = AddToRfqItemInput & {
  itemId: string;
  estimatedItemIds: string[];
  isIncluded: boolean;
};

type ContextType = {
  updates: AddToRfqItem[];
  setUpdates: (updates: AddToRfqItem[]) => void;
};

const ProjectItemContext = createContext<ContextType>({
  updates: [],
  setUpdates: NoFunction,
});

export const RfqProjectItemsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [bulkUpdates, setBulkUpdates] = useState<AddToRfqItem[]>([]);
  const setUpdateForBulkUpdate = (updates: AddToRfqItem[]) => {
    const updatedBulkUpdates = bulkUpdates.map((bu) => {
      const newItem =
        updates.find(
          (u) =>
            u.itemId === bu.itemId &&
            u.estimatedItemIds.some((ei) => bu.estimatedItemIds.includes(ei)),
        ) || ({} as AddToRfqItem);
      return {
        ...bu,
        ...newItem,
        estimatedItemIds: newItem.estimatedItemIds
          ? [...newItem.estimatedItemIds, ...bu.estimatedItemIds]
          : [...bu.estimatedItemIds],
        quantityDecimal: new DecimalSafe(newItem.quantityDecimal || 0)
          .plus(bu.quantityDecimal || 0)
          .toString(),
      };
    });
    const existingUpdates = bulkUpdates.filter((u) =>
      updates.some(
        (existing) =>
          existing.itemId === u.itemId &&
          existing.estimatedItemIds.some((ei) =>
            u.estimatedItemIds.includes(ei),
          ),
      ),
    );
    const newBulkUpdates = [
      ...updatedBulkUpdates,
      ...updates.filter(
        (u) =>
          !existingUpdates.some(
            (existing) =>
              existing.itemId === u.itemId &&
              existing.estimatedItemIds.includes(u.estimatedItemIds?.[0]),
          ),
      ),
    ].filter((u) => u.isIncluded);

    setBulkUpdates(newBulkUpdates);
  };

  return (
    <ProjectItemContext.Provider
      value={{
        updates: bulkUpdates,
        setUpdates: setUpdateForBulkUpdate,
      }}
    >
      {children}
    </ProjectItemContext.Provider>
  );
};

export const useRfqProjectItemsWithUpdates = () => {
  return useContext(ProjectItemContext);
};
