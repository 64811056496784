import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  DECIMAL_MAX_FRACTION_DIGITS,
  MIN_QUANTITY_DECIMALS,
} from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const RemainingItemQtyValue = ({
  item,
}: {
  item: ProjectItemFieldsFragment;
}) => {
  const intl = useIntl();

  const estimatedItem = getEstimatedItem({ item });
  const remainingQuantity = useMemo(
    () =>
      estimatedItem
        ? new DecimalSafe(estimatedItem?.quantityDecimal ?? 0).minus(
            estimatedItem?.orderedQuantity ?? 0,
          )
        : new DecimalSafe(0),
    [estimatedItem],
  );

  return (
    <ValueUnit
      value={remainingQuantity.abs().toNumber()}
      uom={item.estimateUom}
      classNames={{
        container: remainingQuantity.lt(0) ? "text-orange-500" : "",
      }}
      suffix={remainingQuantity.lt(0) ? intl.$t({ id: "OVER" }) : ""}
      tooltip={
        remainingQuantity.lt(0)
          ? intl.$t(
              { id: "OVER_BOM_QUANTITY_TOOLTIP" },
              {
                quantity: intl.formatNumber(
                  remainingQuantity.abs().toNumber(),
                  {
                    maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
                    minimumFractionDigits: MIN_QUANTITY_DECIMALS,
                  },
                ),
                br: "\n",
              },
            )
          : ""
      }
    />
  );
};
