import { getLetterFromIndex } from "@/common/components/circle-counter/getLetterFromIndex";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  LgContainer,
  MdContainer,
  RequestedItemsContainer,
  SmdFixedContainer,
  SmWideContainer,
} from "@/common/layout/ResponsiveClasses";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IncludeGroupedItems } from "@/contractor/pages/home/project/components/project-items/IncludeGroupedItems";
import { ProjectItemMaterialEditableView } from "@/contractor/pages/home/project/components/project-items/ProjectItemMaterialEditableView";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
  ReleaseProjectItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { filterByMaterialName } from "../../../utils/filters/filterByMaterialName";
import { getBuyoutItemAlternative } from "../../../utils/getBuyoutItemAlternative";
import { AddEstimatedItemCheckbox } from "./components/AddEstimatedItemCheckbox";
import { AddEstimatedItemGroupCheckbox } from "./components/AddEstimatedItemGroupCheckbox";
import { AddEstimatedItemVendorView } from "./components/AddEstimatedItemVendorView";
import { AddToReleaseTagsPicker } from "./components/AddToReleaseTagsPicker";
import { ReleaseItemIncludedInBuyout } from "./components/ReleaseItemIncludedInBuyout";
import { RemainingItemQtyValue } from "./components/RemainingItemQtyValue";
import { ViewRelatedBuyouts } from "./components/ViewRelatedBuyouts";

const EstimatedWrapper = tw(SmdFixedContainer)`
    flex flex-col justify-center
  `;

const TagsWrapper = tw(SmdFixedContainer)`
    col-span-4
  `;

export const useAddToReleaseFromEstimatesConfiguration = (
  buyoutId?: string,
  projectId?: string,
) => {
  const { hasPhaseCodes } = useOrgSettings();
  const intl = useIntl();

  const configuration: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <AddEstimatedItemCheckbox
              estimatedItem={estimatedItem}
              estimatedItems={item.estimatedItems}
              item={item}
              quantityDecimal={estimatedItem?.quantityDecimal}
              zoneId={estimatedItem?.zone?.id}
            />
          );
        },
        group: (category) => <AddEstimatedItemGroupCheckbox group={category} />,
        subgroup: (category) => (
          <AddEstimatedItemGroupCheckbox group={category} />
        ),
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <AddEstimatedItemCheckbox
              item={item}
              estimatedItem={estimatedItem}
              quantityDecimal={estimatedItem?.quantityDecimal}
              zoneId={estimatedItem?.zone?.id}
            />
          );
        },
        subdetails: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <AddEstimatedItemCheckbox
              item={item}
              estimatedItem={estimatedItem}
              quantityDecimal={estimatedItem?.quantityDecimal}
              zoneId={estimatedItem?.zone?.id}
            />
          );
        },
      },
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ProjectItemMaterialEditableView
            estimatedItem={getEstimatedItem({ item })}
            item={item}
            count={count}
            groupedByCostCode
            manufacturer={getEstimatedItem({ item })?.manufacturer}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={projectId}
          />
        ),
        header: <FormattedMessage id="PROJECT_ITEM_ESTIMATED_ITEMS" />,
        details: ({ item, index }) => (
          <ProjectItemMaterialView
            item={item}
            className="pl-8 before:content-['•']"
            estimatedItem={getEstimatedItem({ item, index })}
            includeCounter={false}
          />
        ),
        subdetails: ({ item, index = 0 }) => (
          <ProjectItemMaterialView
            item={item}
            className={item.estimatedItems.length > 1 ? "pl-12" : ""}
            count={getLetterFromIndex(index)}
            estimatedItem={getEstimatedItem({ item, index })}
            expandable={false}
          />
        ),
        includesCounter: true,
        searchItemFn: filterByMaterialName,
      },
      {
        wrapper: LgContainer,
        header: <FormattedMessage id="VENDOR_NAME" />,
        item: ({ item }) => <AddEstimatedItemVendorView item={item} />,
      },
      {
        wrapper: TagsWrapper,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <IncludeGroupedItems item={item}>
              <AddToReleaseTagsPicker estimatedItem={estimatedItem} />
            </IncludeGroupedItems>
          );
        },
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return <AddToReleaseTagsPicker estimatedItem={estimatedItem} />;
        },
        subdetails: () => <span />,
      },
      {
        wrapper: EstimatedWrapper,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <ValueUnit
              value={estimatedItem?.quantityDecimal ?? "0"}
              uom={item.estimateUom}
            />
          );
        },
        header: <FormattedMessage id="ESTIMATED_QUANTITY" />,
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <ValueUnit
              value={estimatedItem?.quantityDecimal || "0"}
              uom={item.estimateUom}
            />
          );
        },
        subdetails: ({ item, index = 0 }) => {
          return (
            <ValueUnit
              value={
                getBuyoutItemAlternative(
                  item as ReleaseProjectItemFieldsFragment,
                  buyoutId,
                  index,
                ).quantityDecimal ?? "0"
              }
              uom={item.estimateUom}
            />
          );
        },
      },
      {
        wrapper: EstimatedWrapper,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <ValueUnit
              value={estimatedItem?.orderedQuantity ?? "0"}
              uom={item.estimateUom}
            />
          );
        },
        header: <FormattedMessage id="ORDERED_QTY" />,
        subdetails: () => <span />,
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          const duplicateCount = estimatedItem?.duplicateCount ?? 1;
          const ordered = new DecimalSafe(
            estimatedItem?.orderedQuantity ?? "0",
          );
          const value = ordered.dividedBy(duplicateCount).toString();
          return (
            <ValueUnit
              value={value}
              uom={item.estimateUom}
              showTilde={duplicateCount > 1 && !ordered.isZero()}
              tooltip={
                duplicateCount > 1
                  ? intl.$t(
                      { id: "ADD_FROM_BOM_ORDERED_QTY_DUPLICATES" },
                      { duplicateCount },
                    )
                  : undefined
              }
            />
          );
        },
      },
      {
        wrapper: EstimatedWrapper,
        header: <FormattedMessage id="REMAINING_QTY" />,
        item: ({ item }) => <RemainingItemQtyValue item={item} />,
        subdetails: () => <span />,
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          const duplicateCount = estimatedItem?.duplicateCount ?? 1;
          const ordered = new DecimalSafe(
            estimatedItem?.orderedQuantity ?? "0",
          );
          const remainingQuantity = Math.max(
            0,
            Number(
              new DecimalSafe(estimatedItem?.quantityDecimal ?? "0").minus(
                new DecimalSafe(
                  estimatedItem?.orderedQuantity ?? "0'",
                ).dividedBy(duplicateCount),
              ),
            ),
          ).toString();
          return (
            <ValueUnit
              value={remainingQuantity}
              uom={item.estimateUom}
              showTilde={duplicateCount > 1 && !ordered.isZero()}
              tooltip={
                duplicateCount > 1
                  ? intl.$t(
                      { id: "ADD_FROM_BOM_REMAINING_QTY_DUPLICATES" },
                      { duplicateCount },
                    )
                  : undefined
              }
            />
          );
        },
      },
      {
        wrapper: SmWideContainer,
        header: <FormattedMessage id="INCLUDED_IN_BUYOUT_QUESTION" />,
        item: ({ item }) => (
          <ReleaseItemIncludedInBuyout
            item={item as ReleaseProjectItemFieldsFragment}
            buyoutId={buyoutId}
          />
        ),
        details: ({ item }) => (
          <ReleaseItemIncludedInBuyout
            item={item as ReleaseProjectItemFieldsFragment}
            buyoutId={buyoutId}
          />
        ),
        subdetails: ({ item }) => (
          <ReleaseItemIncludedInBuyout
            item={item as ReleaseProjectItemFieldsFragment}
            buyoutId={buyoutId}
          />
        ),
        hidden: !buyoutId,
        position: "center",
      },
      {
        wrapper: MdContainer,
        position: "center",
        item: ({ item }) => (
          <ViewRelatedBuyouts item={item as ReleaseProjectItemFieldsFragment} />
        ),
        header: <FormattedMessage id="RELATED_BUYOUTS" />,
        subdetails: () => <span />,
        details: ({ item }) => (
          <ViewRelatedBuyouts item={item as ReleaseProjectItemFieldsFragment} />
        ),
      },
    ],
    [hasPhaseCodes, buyoutId, projectId, intl],
  );

  return { configuration };
};
