import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { RendererFunctionType } from "@/common/components/spreadsheet-table/renderers/base-renderer/types/RendererFunctionType";
import { useItemRenderer } from "@/common/components/spreadsheet-table/renderers/material-renderer/useItemRenderer";
import { useMaterialRenderer } from "@/common/components/spreadsheet-table/renderers/material-renderer/useMaterialRenderer";
import { isValueEmpty } from "@/common/components/spreadsheet-table/renderers/utils/isValueEmpty";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useIsWarehouseRelease } from "@/contractor/pages/home/release/hooks/useIsWarehouseRelease";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { AuthorizationStatus, ReleaseStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useIsRestockRelease } from "../../../../hooks/useIsRestockRelease";
import { useSwitchInventoryItems } from "../../components/switch-inventory-item/hooks/useSwitchInventoryItems";
import {
  OrderItemRowType,
  useOrderItemsRendererHelpers,
} from "./useOrderItemRendererHelpers";

export const useOrderItemRenderer = () => {
  const intl = useIntl();
  const materialRenderer = useMaterialRenderer();
  const { hasPhaseCodes } = useOrgSettings();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { release } = useRelease();
  const { getInventoryItemAlternatives, tableHasColumn } = useTableHelpers();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const { getItemTypes } = useOrderItemsRendererHelpers();
  const { showItemAlternatives } = useSwitchInventoryItems();
  const { isRestockRelease } = useIsRestockRelease();
  const { allInventoryItems, loading: inventoryItemsLoading } =
    useInventoryItems();
  const { estimatedItems } = useProjectStore(
    useShallow((state) => ({
      estimatedItems: state.estimatedItems,
    })),
  );

  const requiresApproval = useMemo(() => {
    return (
      release?.id &&
      release?.permissions.submitDirectly !== AuthorizationStatus.Authorized &&
      release?.permissions.submit === AuthorizationStatus.Authorized &&
      release?.status !== ReleaseStatus.Requested
    );
  }, [release?.id, release?.permissions, release?.status]);

  const translations = useMemo(
    () => ({
      buyoutNotice: intl.$t({ id: "BUYOUT_ITEM_NOTICE" }),
      bomItemNotice: intl.$t({ id: "BOM_ITEM_NOTICE" }),
      inventoryNotice: intl.$t({ id: "INVENTORY_ITEM_NOTICE" }),
    }),
    [intl],
  );

  const hasSupplierColumn = useMemo(
    () => tableHasColumn(COLUMN_TYPE.Supplier),
    [tableHasColumn],
  );

  const { setItemRenderer } = useItemRenderer();

  const hasInventoryItemOptions = useMemo(() => {
    return allInventoryItems.length > 1 && !inventoryItemsLoading;
  }, [allInventoryItems.length, inventoryItemsLoading]);

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      materialRenderer(instance, td, row, col, prop, value, cellProperties);
      if (isValueEmpty(value)) {
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const { buyoutItem, bomItem, inventoryItem, rowType } = getItemTypes(
        instance,
        rowData,
      );

      const icons = [];

      switch (rowType) {
        case OrderItemRowType.BuyoutItem:
          if (buyoutItem) {
            icons.push({
              icon: "fa-circle",
              tooltip: translations.buyoutNotice,
              iconClasses: "text-green-800",
            });
          }
          break;
        case OrderItemRowType.BOMItem:
          if (bomItem) {
            icons.push({
              icon: "fa-circle",
              tooltip: translations.bomItemNotice,
              iconClasses: "text-blue-500",
            });
          }
          break;
        case OrderItemRowType.InventoryItem:
          if (inventoryItem) {
            icons.push({
              icon: "fa-circle",
              tooltip: intl.$t({ id: "INVENTORY_ITEM" }),
              iconClasses: "text-blue-800",
            });
          }
          break;
      }

      const inventoryItemOptions = getInventoryItemAlternatives({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        supplier:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
      });

      if (!inventoryItem && hasInventoryItemOptions && hasSupplierColumn) {
        if (!release?.sellerOrgLocation) {
          icons.push({
            icon: "fa-solid fa-retweet",
            tooltip: intl.$t(
              { id: "INVENTORY_ITEM_ALTERNATIVES" },
              { br: "\n" },
            ),
            iconClasses: "text-orange-500",
            onClick: () =>
              showItemAlternatives({
                itemName:
                  rowData[
                    getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
                  ],
                row,
                quantity:
                  rowData[
                    getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)
                  ],
                limitToCurrentWarehouse: !hasSupplierColumn || isRestockRelease,
              }),
          });
        } else {
          const nameHasExtraDetails = value?.match(/ ⟮(.*)⟯/);
          const hasSupplier =
            rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)];
          if (!hasSupplier && !nameHasExtraDetails) {
            if (requiresApproval) {
              icons.push({
                icon: "fa-circle",
                tooltip: intl.$t({ id: "ITEM_NOT_FOUND_IN_STOCK" }),
                iconClasses: "text-gray-600",
              });
            } else {
              icons.push({
                icon: "fa-solid fa-retweet",
                tooltip: intl.$t(
                  {
                    id: isWarehouseRelease
                      ? "ITEM_NOT_FOUND_IN_STOCK"
                      : "REPLACE_WITH_INVENTORY_ITEM",
                  },
                  { br: "\n" },
                ),
                iconClasses: "text-orange-500",
                onClick: () =>
                  showItemAlternatives({
                    itemName:
                      rowData[
                        getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
                      ],
                    row,
                    quantity:
                      rowData[
                        getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)
                      ],
                    limitToCurrentWarehouse:
                      !hasSupplierColumn || isRestockRelease,
                  }),
              });
            }
          }
        }
      }

      setItemRenderer(td, {
        item: buyoutItem || bomItem || inventoryItem || inventoryItemOptions,
        textGetter: () => {
          if (buyoutItem) {
            return `${hasPhaseCodes ? buyoutItem.tags[0]?.name : buyoutItem.costCode?.description || unassignedCostCode.description}, ${buyoutItem.quantityDecimal} ${buyoutItem.projectItem.estimateUom.pluralDescription}`;
          }
          if (bomItem) {
            const matchingEstimatedItems = estimatedItems.filter(
              (item) =>
                item.material.id === bomItem.material.id &&
                item.uom.id === bomItem.uom.id &&
                item.sellerOrgLocation?.id === bomItem.sellerOrgLocation?.id &&
                item.zone?.id === bomItem.zone?.id,
            );
            const quantity = matchingEstimatedItems.reduce(
              (acc, item) => acc.plus(item.quantityDecimal),
              new DecimalSafe(0),
            );
            return `${hasPhaseCodes ? bomItem.tags[0]?.name : bomItem.costCode?.description || unassignedCostCode.description}, ${quantity.toNumber()}`;
          }
          if (inventoryItem) {
            const remainingQuantity =
              getInventoryItemRemainingQuantity(inventoryItem);
            return `${remainingQuantity} ${inventoryItem.uom.pluralDescription}`;
          }
          return "";
        },
        icons,
      });
    },
    [
      intl,
      setItemRenderer,
      hasPhaseCodes,
      unassignedCostCode,
      translations,
      getInventoryItemAlternatives,
      materialRenderer,
      hasSupplierColumn,
      requiresApproval,
      release?.sellerOrgLocation,
      getItemTypes,
      isWarehouseRelease,
      showItemAlternatives,
      isRestockRelease,
      hasInventoryItemOptions,
      estimatedItems,
    ],
  );

  return renderer;
};
