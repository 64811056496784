import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { AddToRfqItemInput } from "@/generated/graphql";
import { useCallback } from "react";

export const useAddItemsToRfqSpreadsheet = () => {
  const { handsonInstance } = useColumnMapper();
  const { projectCostCodes, formatCostCode } = useProjectCostCodes();
  const { materials } = useMaterials();
  const { manufacturers } = useManufacturers();
  const { getPreferredCostCode } = useTableHelpers();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { hasPhaseCodes } = useOrgSettings();

  const transformInputData = useCallback(
    (itemsToAdd: AddToRfqItemInput[]) => {
      const items = itemsToAdd.map((item) => {
        const material = materials.find(
          (material) =>
            material.material.id === item.projectItem?.orgCatalogSkuId ||
            item.projectItem?.masterProductId ||
            item.projectItem?.masterSkuId,
        );

        const costCode = projectCostCodes.find(
          (costCode) => costCode.id === item.costCodeId,
        );

        const projectItem = item.projectItem;
        const manufacturer = manufacturers.find(
          (manufacturer) => manufacturer.id === item.manufacturerId,
        );

        const selectedCostCode = getPreferredCostCode(costCode);

        return {
          [COLUMN_TYPE.Material]: material
            ? getFormattedMaterialName(material)
            : "",
          [COLUMN_TYPE.PositiveQuantity]: item?.quantityDecimal ?? "",
          ...(!hasPhaseCodes
            ? { [COLUMN_TYPE.CostCode]: formatCostCode(selectedCostCode) }
            : {}),
          [COLUMN_TYPE.Manufacturer]: manufacturer?.name ?? "",
          [COLUMN_TYPE.UOM]: projectItem?.estimateUom ?? "",
          [COLUMN_TYPE.Notes]: item?.instructions?.text ?? "",
        };
      });
      return items;
    },
    [
      materials,
      projectCostCodes,
      manufacturers,
      getPreferredCostCode,
      getFormattedMaterialName,
      hasPhaseCodes,
      formatCostCode,
    ],
  );

  const addItemsToRfqSpreadsheet = useCallback(
    (itemsToAdd: AddToRfqItemInput[]) => {
      const items = transformInputData(itemsToAdd);
      appendTableRows(items, handsonInstance);
    },

    [handsonInstance, transformInputData],
  );

  return { addItemsToRfqSpreadsheet };
};
