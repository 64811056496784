import { useCallback } from "react";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";

type Icon = {
  icon: string;
  tooltip: string;
  iconClasses: string;
  onClick?: () => void;
};
interface ItemRendererParams<T> {
  item: T | undefined;
  textGetter: (item: T) => string;
  icons?: Icon[];
}

export const useItemRenderer = () => {
  const { addIconWithTooltip } = useRenderHelpers();

  const setItemRenderer = useCallback(
    <T>(td: HTMLTableCellElement, params: ItemRendererParams<T>) => {
      const { item, textGetter, icons } = params;
      if (item) {
        const textElement = document.createElement("span");
        textElement.className = "text-gray-500";
        const textDetails = textGetter(item);
        if (textDetails) {
          textElement.innerText = ` ⟮${textDetails}⟯`;
        }
        applyClasses(td, "pr-5");
        td.appendChild(textElement);
        icons?.forEach((icon, index) =>
          addIconWithTooltip({
            element: td,
            tooltipText: icon.tooltip,
            icon: icon.icon,
            iconClasses: `absolute ${icon.iconClasses} ${index === 0 ? "right-0" : "right-5"}`,
            onClick: icon.onClick,
          }),
        );
      }
    },
    [addIconWithTooltip],
  );

  return { setItemRenderer };
};
