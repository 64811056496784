import { Header } from "@/common/components/header/Header";
import "@/common/polyfills/toSorted";
import { createRef, useMemo } from "react";
import { pdfjs } from "react-pdf";
import {
  createBrowserRouter,
  matchPath,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router";
import tw from "tailwind-styled-components";
import { OutdatedVersionInfo } from "./common/components/outdate-version-info/OutdateVersionInfo";
import { LOCAL_STORAGE_KEYS } from "./common/const";
import { AppProvider } from "./common/providers/AppProvider";
import { LocaleProvider } from "./common/providers/LocaleProvider";
import { readValue } from "./common/utils/localStorage";
import { SentryBoundary } from "./components/sentry-boundry/SentryBoundary";
import { routes } from "./config/routes";
import { contractorRoutes } from "./contractor/Routes";
import { distributorRoutes } from "./distributor/Routes";

const PageContainer = tw.div`
  px-1 xl:px-16 pb-10 pt-27 h-screen overflow-auto print:px-4 print:pt-20 print:h-full overscroll-none
`;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const allowedOverflowXRoutes = [routes.bidsWithId];

const Container = () => {
  const containerRef = createRef<HTMLDivElement>();
  const location = useLocation();

  const shouldAllowOverflowX = useMemo(() => {
    return allowedOverflowXRoutes.some((route) =>
      matchPath(route, location.pathname),
    );
  }, [location.pathname]);

  return (
    <SentryBoundary>
      <LocaleProvider>
        <AppProvider ref={containerRef}>
          <OutdatedVersionInfo />
          <Header />
          <PageContainer
            ref={containerRef}
            className={shouldAllowOverflowX ? "" : "overflow-x-hidden"}
          >
            <Outlet />
          </PageContainer>
        </AppProvider>
      </LocaleProvider>
    </SentryBoundary>
  );
};

const DefaultRoute = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const defaultPage = useMemo(
    () => readValue(LOCAL_STORAGE_KEYS.DEFAULT_PAGE) ?? routes.projects,
    [],
  );

  if (!["/home", "/admin"].some((prefix) => pathname.startsWith(prefix))) {
    return <Navigate replace to={`/home${location.pathname}`} />;
  }

  return <Navigate to={defaultPage} />;
};

const router = createBrowserRouter([
  {
    element: <Container />,
    children: [
      ...contractorRoutes,
      ...distributorRoutes,

      { path: "/admin", element: <Navigate to={routes.orgProfile} /> },
      { path: "*", element: <DefaultRoute /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
