import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import {
  AddToReleaseItemInput,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useReleaseProject } from "../../add-items-to-release/release-items-list/ReleaseProjectProvider";

export const useAddItemsToReleaseSpreadsheet = () => {
  const { handsonInstance } = useColumnMapper();
  const { vendors, getVendorCode } = useVendors();
  const { projectCostCodes, formatCostCode } = useProjectCostCodes();
  const { tags } = useProjectTags();
  const { project } = useReleaseProject();
  const { estimatedItemsMap } = useProjectMaps(project);
  const { buyout } = useContractorBuyout();
  const { getPreferredCostCode } = useTableHelpers();
  const getFormattedMaterialName = useFormattedMaterialName();

  const transformInputData = useCallback(
    (itemsToAdd: AddToReleaseItemInput[]) => {
      const items = itemsToAdd.map((item) => {
        const buyoutItem = buyout?.items.find(
          (buyoutItem) => buyoutItem.id === item.buyoutItemId,
        );

        const vendor = vendors.find(
          (vendor) => vendor.sellerOrgLocation.id === item.sellerOrgLocationId,
        );

        const tag = tags.find((tag) => tag.id === item.tags?.[0]);

        const estimatedItem = item.estimatedItemId
          ? estimatedItemsMap.get(item.estimatedItemId)
          : undefined;

        const costCode =
          buyoutItem?.costCode ??
          projectCostCodes.find(
            (costCode) => costCode.id === estimatedItem?.costCode?.id,
          );

        const projectItem =
          buyoutItem?.projectItem ??
          project?.items.find((projectItem) =>
            projectItem.estimatedItems.find(
              (ei) => ei.id === item.estimatedItemId,
            ),
          );

        const supplier = vendor
          ? vendorLabelFormatter(vendor?.sellerOrgLocation, [], {
              vendorCode: getVendorCode(vendor),
            })
          : "";
        const selectedCostCode = getPreferredCostCode(costCode);

        const estimatedQuantity = estimatedItem
          ? Math.max(
              0,
              Number(
                new DecimalSafe(estimatedItem.quantityDecimal).minus(
                  new DecimalSafe(estimatedItem.orderedQuantity).dividedBy(
                    estimatedItem.duplicateCount,
                  ),
                ),
              ),
            ).toString()
          : undefined;

        return {
          [COLUMN_TYPE.Material]:
            buyoutItem?.description ??
            getFormattedMaterialName(
              (projectItem?.material as OrgMaterialFieldsFragment) ?? "",
            ) ??
            "",
          [COLUMN_TYPE.Quantity]:
            buyoutItem?.quantityDecimal ??
            estimatedQuantity ??
            item.quantityDecimal ??
            "",
          [COLUMN_TYPE.PrefilledPrice]:
            buyoutItem?.unitPrice ?? estimatedItem?.unitPrice ?? "",
          [COLUMN_TYPE.Supplier]: supplier,
          [COLUMN_TYPE.CostCode]: formatCostCode(selectedCostCode),
          [COLUMN_TYPE.Taxable]: "true",
          [COLUMN_TYPE.Tag]: tag?.name ?? "",
          [COLUMN_TYPE.Manufacturer]:
            buyoutItem?.manufacturer?.name ??
            estimatedItem?.manufacturer?.name ??
            "",
          [COLUMN_TYPE.UOM]: projectItem?.estimateUom?.pluralDescription ?? "",
          [COLUMN_TYPE.Zone]: estimatedItem?.zone?.name ?? "",
          [COLUMN_TYPE.BuyoutItemId]: buyoutItem?.id ?? "",
          [COLUMN_TYPE.Notes]: estimatedItem?.notes ?? "",
          [COLUMN_TYPE.EstimatedItemId]: estimatedItem?.id ?? "",
        };
      });
      return items;
    },
    [
      buyout?.items,
      projectCostCodes,
      estimatedItemsMap,
      formatCostCode,
      getVendorCode,
      project?.items,
      tags,
      vendors,
      getFormattedMaterialName,
      getPreferredCostCode,
    ],
  );

  const addItemsToReleaseSpreadsheet = useCallback(
    (itemsToAdd: AddToReleaseItemInput[]) => {
      const items = transformInputData(itemsToAdd);
      appendTableRows(items, handsonInstance);
    },

    [handsonInstance, transformInputData],
  );

  return { addItemsToReleaseSpreadsheet };
};
