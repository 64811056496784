import { If } from "@/common/components/if/If";
import { SupplierPicker } from "@/common/components/supplier-picker/SupplierPicker";
import { SupplierSelectorCustomRendererWithIcon } from "@/common/components/supplier-picker/components/renderers/SupplierSelectorCustomRendererWithIcon";
import { useWarehouseSupplier } from "@/common/components/supplier-picker/hooks/useWarehouseSupplier";
import { useSupplierOptions } from "@/common/components/supplier-picker/useSupplierOptions";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { WarehouseSelectorCustomRendererWithIcon } from "@/common/components/warehouse-selector/renderer/WarehouseSelectorCustomRendererWithIcon";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ReleaseStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useIsRestockRelease } from "../../hooks/useIsRestockRelease";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../providers/ReleaseActionsProvider";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { useCanEditReleaseVendor } from "./hooks/useCanEditReleaseVendor";

type Props = {
  disabled?: boolean;
};

const VendorItem = tw.div`
  w-full py-[6px] grid grid-flow-col gap-x-2 justify-start items-center text-sm
`;

export const ReleaseVendorSelector: FC<Props> = ({ disabled }) => {
  const intl = useIntl();
  const { release } = useRelease();
  const {
    vendors,
    findOrderTypeByLocationId,
    findDefaultPaymentTermByLocationId,
  } = useVendors();
  const { updateRelease } = useReleaseUpdate();
  const { vendorId, setVendorId, inputErrors } = useReleaseActions();
  const { setGlobalVendorId } = useVendorPrices();
  const { settings } = useOrgSettings();
  const { canEditVendor } = useCanEditReleaseVendor();
  const { isWarehouseSupplier } = useWarehouseSupplier();
  const { findVendorById, findWarehouseById } = useSupplierOptions({
    includeWarehouses: settings?.inventory.enabled,
  });

  const { isRestockRelease } = useIsRestockRelease();

  const selectedSourceWarehouse = useMemo(
    () => findWarehouseById(vendorId),
    [findWarehouseById, vendorId],
  );

  const selectedVendor = useMemo(
    () => findVendorById(vendorId),
    [findVendorById, vendorId],
  );

  const update = useCallback(async () => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
      };
      const isWarehouseOrder = isWarehouseSupplier(vendorId);

      if (vendorId) {
        if (isWarehouseOrder) {
          input.vendorContactIds = [];
          input.sourceWarehouseID = vendorId;
          input.sellerOrgLocationId = null;
          input.clearWarehouse = true;
          input.poNumber = "";
        } else {
          const newVendor = vendors?.find(
            (v) => v.sellerOrgLocation.id === vendorId,
          );
          input.sellerOrgLocationId = vendorId;
          input.sourceWarehouseID = null;
          input.vendorContactIds =
            newVendor?.contacts.filter((c) => c.receivesOrderNotifications)
              .length === 1
              ? newVendor?.contacts
                  .filter((c) => c.receivesOrderNotifications)
                  .map((c) => c.id) || []
              : [];
        }
      }

      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        input.typeId = orderTypeId;
      }

      if (release.sellerOrgLocation?.id !== vendorId && !isWarehouseOrder) {
        const paymentTerm = findDefaultPaymentTermByLocationId(vendorId);
        input.paymentTerm = paymentTerm ?? 30;
      }

      if (setGlobalVendorId && !isWarehouseOrder) {
        setGlobalVendorId(vendorId);
      }

      await updateRelease(input);
    }
  }, [
    release,
    isWarehouseSupplier,
    vendorId,
    findOrderTypeByLocationId,
    setGlobalVendorId,
    updateRelease,
    vendors,
    findDefaultPaymentTermByLocationId,
  ]);

  const requiresVendor = useMemo(() => {
    if (settings?.releaseApproval.enabled) {
      if (isAuthorized(release?.permissions.submitDirectly)) {
        return true;
      }
      return (
        release?.status &&
        ![ReleaseStatus.AwaitingApproval, ReleaseStatus.Draft].includes(
          release?.status,
        )
      );
    } else {
      return true;
    }
  }, [
    release?.permissions.submitDirectly,
    release?.status,
    settings?.releaseApproval.enabled,
  ]);

  return (
    <>
      <If isTrue={canEditVendor}>
        <SupplierPicker
          setSupplierId={(vendorId) => vendorId && setVendorId(vendorId)}
          supplierId={vendorId}
          contactsFilter={(contact) => contact.receivesOrderNotifications}
          required={requiresVendor}
          disabled={disabled}
          error={inputErrors.includes(ReleaseErrorType.SUPPLIER)}
          selectedOptionCustomView={
            selectedSourceWarehouse ? (
              <WarehouseSelectorCustomRendererWithIcon
                item={selectedSourceWarehouse}
              />
            ) : selectedVendor ? (
              <SupplierSelectorCustomRendererWithIcon item={selectedVendor} />
            ) : null
          }
          placeholder={intl.$t({
            id:
              release?.status === ReleaseStatus.AwaitingApproval ||
              isAuthorized(release?.permissions.submitDirectly)
                ? "SUPPLIER"
                : "SUPPLIER_OPTIONAL",
          })}
          onBlur={update}
          xs={false}
          includeAllOption={false}
          inputPropsClassName="text-sm"
          includeWarehouses
          withAdditionalAdornment
          disableWarehouses={!!release?.poLink || isRestockRelease}
          disableWarehousesTooltip={
            release?.poLink
              ? intl.$t({ id: "DISABLED_WAREHOUSE_OPTION" })
              : isRestockRelease
                ? intl.$t({ id: "ORDER_RESTOCK_TOOLTIP" })
                : undefined
          }
        />
      </If>
      <If isTrue={!canEditVendor}>
        <VendorItem>
          <VendorLogo
            logoImageUrl={release?.sellerOrgLocation?.org?.photoUrl}
            address={release?.sellerOrgLocation?.address}
            contactName={getVendorContacts(
              release?.preferredVendor?.contacts.filter(
                (c) => c.receivesOrderNotifications,
              ),
            )}
            orgName={release?.sellerOrgLocation?.org.name}
          />
          {vendorLabelFormatter(release?.sellerOrgLocation) ||
            release?.sourceWarehouse?.name}
        </VendorItem>
      </If>
    </>
  );
};
