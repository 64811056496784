import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { EstimatedItem } from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

export const useReleaseItemBomItemQuantity = () => {
  const { estimatedItems } = useProjectStore(
    useShallow((state) => ({
      estimatedItems: state.estimatedItems,
    })),
  );

  const getMatchingEstimatedItems = useCallback(
    (estimatedItem: EstimatedItem) =>
      estimatedItems.filter(
        (item) =>
          item.material.id === estimatedItem?.material?.id &&
          item.uom.id === estimatedItem.uom.id &&
          item.costCode?.id === estimatedItem.costCode?.id &&
          item.zone?.id === estimatedItem.zone?.id,
      ),
    [estimatedItems],
  );

  const getBOMItemRemainingQuantity = useCallback(
    (estimatedItem: EstimatedItem, currentValue: number | string) => {
      const matchingEstimatedItems = getMatchingEstimatedItems(estimatedItem);

      if (matchingEstimatedItems.length === 0) {
        return new DecimalSafe(0);
      }

      const sumQuantity = matchingEstimatedItems.reduce((acc, item) => {
        return acc.plus(item.quantityDecimal);
      }, new DecimalSafe(0));

      return sumQuantity
        .minus(estimatedItem.orderedQuantity)
        .minus(currentValue);
    },
    [getMatchingEstimatedItems],
  );

  return { getBOMItemRemainingQuantity, getMatchingEstimatedItems };
};
