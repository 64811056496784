import { Address } from "@/common/components/address/Address";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PROJECT_STATUSES } from "@/common/components/project-statuses/ProjectStatuses";
import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { ProjectStatus, RfqFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectNavigate } from "../../projects/hooks/useProjectNavigate";

type RFQInfoProps = {
  rfq: RfqFieldsFragment;
  hideVendors?: boolean;
};

const CreatorData = tw.div`flex flex-col`;
const CreatorName = tw.span``;
const CreationDate = tw.span`font-light text-xs`;

export const RFQInfo: FC<RFQInfoProps> = ({ rfq }) => {
  const { navigateToProject } = useProjectNavigate();
  return (
    <InfoHeader>
      <InfoHeaderItem
        title={
          rfq.project.status === ProjectStatus.Active ||
          rfq.project.status === ProjectStatus.Awarded ? (
            <FormattedMessage id={PROJECT_STATUSES[rfq.project.status].label} />
          ) : (
            <FormattedMessage id="RFQ_INFO_PROJECT" />
          )
        }
      >
        <LinkLike
          onClick={() =>
            navigateToProject({
              id: rfq.project.id,
              permissions: rfq.project.permissions,
            })
          }
        >
          {rfq.project.name}
        </LinkLike>
        {rfq.project.jobNumber ? ` (${rfq.project.jobNumber})` : ""}
      </InfoHeaderItem>
      <InfoHeaderItem
        title={<FormattedMessage id="RFQ_INFO_DELIVERY_ADDRESS" />}
      >
        <Address address={rfq.project.address} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="RFQ_INFO_JOB_START_DATE" />}>
        <DateView date={rfq.project.startDate} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="CREATED_BY" />}>
        <UserAvatar
          firstName={rfq.createdBy?.firstName}
          lastName={rfq.createdBy?.lastName}
          photoUrl={rfq.createdBy.photoUrl}
        />
        <CreatorData>
          <CreatorName>{getUserName(rfq.createdBy)}</CreatorName>
          <CreationDate>
            <DateView date={rfq.createdAt} />
          </CreationDate>
        </CreatorData>
      </InfoHeaderItem>
    </InfoHeader>
  );
};
