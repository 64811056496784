import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { RendererFunctionType } from "@/common/components/spreadsheet-table/renderers/base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "@/common/components/spreadsheet-table/renderers/base-renderer/useBaseRenderer";
import { useRenderHelpers } from "@/common/components/spreadsheet-table/renderers/helpers/useRenderHelpers";
import { useBestVendorForItem } from "@/common/components/spreadsheet-table/renderers/quantity-renderer/useBestVendorForItem";
import { applyClasses } from "@/common/components/spreadsheet-table/renderers/utils/applyClasses";
import { applyTooltip } from "@/common/components/spreadsheet-table/renderers/utils/applyTooltip";
import { isValueEmpty } from "@/common/components/spreadsheet-table/renderers/utils/isValueEmpty";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalRowIndex";
import { insertTableRows } from "@/common/components/spreadsheet-table/utils/insertTableRows";
import { setTableCells } from "@/common/components/spreadsheet-table/utils/setTableCells";
import {
  DECIMAL_MAX_FRACTION_DIGITS,
  MIN_QUANTITY_DECIMALS,
} from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useIsRestockRelease } from "@/contractor/pages/home/release/hooks/useIsRestockRelease";
import {
  EstimatedItem,
  EstimatedItemFieldsFragment,
  InventoryItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import Handsontable from "handsontable";
import { CellValue } from "handsontable/common";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { useReleaseItemBomItemQuantity } from "../useReleaseItemBomItemQuantity";
import {
  OrderItemRowType,
  useOrderItemsRendererHelpers,
} from "./useOrderItemRendererHelpers";

export const useOrderItemQuantityRenderer = () => {
  const intl = useIntl();
  const baseRenderer = useBaseRenderer();
  const { warehouses } = useWarehouses();
  const { release } = useRelease();
  const { addIconWithTooltip } = useRenderHelpers();
  const { tableHasColumn } = useTableHelpers();
  const getBestVendorForItem = useBestVendorForItem();
  const { isRestockRelease } = useIsRestockRelease();
  const { currentWarehouseId } = useInventoryItems();
  const { getItemTypes, getMatchingBomItemRows, getMatchingInventoryItemRows } =
    useOrderItemsRendererHelpers();
  const { getBOMItemRemainingQuantity } = useReleaseItemBomItemQuantity();

  const releaseIsSubmitted = useMemo(() => {
    return checkReleaseStatus(release, [
      ReleaseStatus.Scheduled,
      ReleaseStatus.Requested,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Received,
    ]);
  }, [release]);

  const getTotalQuantityForRelease = useCallback(
    (bomItem: EstimatedItemFieldsFragment) => {
      if (!release) {
        return 0;
      }

      return release.items
        .filter((item) =>
          item.projectItem?.estimatedItems.find(
            (estimatedItem) => estimatedItem.id === bomItem.id,
          ),
        )
        .reduce(
          (acc, item) => acc.plus(item.quantityDecimal),
          new DecimalSafe(0),
        );
    },
    [release],
  );

  const splitQuantity = useCallback(
    (
      sourceRow: Record<string, string>,
      rowData: CellValue[],
      instance: Handsontable,
      row: number,
      remainingQuantity: number,
    ) => {
      const value = Number(
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)],
      );
      const currentRow: [number, number, string | number | null][] = [
        [
          row,
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity),
          remainingQuantity,
        ],
      ];
      setTableCells(currentRow, instance);
      const bestVendorPrice = getBestVendorForItem({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        manufacturer:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });
      const newRow = {
        ...sourceRow,
        [COLUMN_TYPE.Supplier]: bestVendorPrice?.vendorName ?? "",
        [COLUMN_TYPE.UOM]:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        [COLUMN_TYPE.PrefilledPrice]: bestVendorPrice?.price ?? "",
        [COLUMN_TYPE.Quantity]: String(value - remainingQuantity),
      };
      insertTableRows([newRow], instance, row + 1);
    },
    [getBestVendorForItem],
  );

  const hasSupplier = useMemo(
    () => tableHasColumn(COLUMN_TYPE.Supplier),
    [tableHasColumn],
  );

  const renderBomItemQuantity = useCallback(
    ({
      bomItem,
      value,
      td,
      matchingRows,
      instance,
    }: {
      bomItem: EstimatedItemFieldsFragment;
      value: string;
      td: HTMLTableCellElement;
      matchingRows: Record<string, string>[];
      instance: Handsontable;
    }) => {
      let totalQuantity = matchingRows.reduce((acc, row) => {
        return acc.plus(
          row[getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)],
        );
      }, new DecimalSafe(0));

      if (releaseIsSubmitted) {
        totalQuantity = totalQuantity.minus(
          getTotalQuantityForRelease(bomItem),
        );
      }

      const remainingQuantity = getBOMItemRemainingQuantity(
        bomItem as EstimatedItem,
        totalQuantity.toNumber(),
      );

      if (remainingQuantity.lt(0)) {
        const formattedOverQuantity = intl.formatNumber(
          remainingQuantity.abs().toNumber(),
          {
            maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
            minimumFractionDigits: MIN_QUANTITY_DECIMALS,
          },
        );
        const remainingQuantityEl = document.createElement("div");
        remainingQuantityEl.className =
          "text-orange-500 text-3xs select-none mb-1";
        remainingQuantityEl.innerText = intl.$t(
          { id: "OVER_BOM_QUANTITY" },
          { quantity: formattedOverQuantity },
        );
        td.appendChild(remainingQuantityEl);

        const moreThanRemainingQuantity = remainingQuantity.lessThan(value);
        if (moreThanRemainingQuantity) {
          applyTooltip(
            remainingQuantityEl,
            intl.$t(
              { id: "OVER_BOM_QUANTITY_TOOLTIP" },
              {
                quantity: formattedOverQuantity,
              },
            ),
          );
        }
      }
    },
    [
      getBOMItemRemainingQuantity,
      intl,
      releaseIsSubmitted,
      getTotalQuantityForRelease,
    ],
  );

  const renderInventoryItemQuantity = useCallback(
    ({
      inventoryItem,
      td,
      value,
      matchingRows,
      rowData,
      sourceRow,
      instance,
      row,
    }: {
      inventoryItem: InventoryItemFieldsFragment;
      td: HTMLTableCellElement;
      value: string;
      matchingRows: Record<string, string>[];
      rowData: CellValue[];
      sourceRow: Record<string, string>;
      instance: Handsontable;
      row: number;
    }) => {
      const supplier =
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)];
      const warehouseId = warehouses.find(
        (warehouse) => warehouse.name === supplier,
      )?.id;

      const remainingQuantity = getInventoryItemRemainingQuantity(
        inventoryItem,
        warehouseId ?? currentWarehouseId ?? undefined,
      );

      const isPartOfWarehouse = inventoryItem.state.some(
        (state) =>
          state.warehouse.id === warehouseId ||
          state.warehouse.id === currentWarehouseId,
      );

      if (remainingQuantity.gte(0) && isPartOfWarehouse) {
        const remainingQuantityEl = document.createElement("div");
        remainingQuantityEl.className =
          "text-gray-500 text-3xs select-none mb-1";
        remainingQuantityEl.innerText = intl.$t(
          { id: "QUANTITY_IN_STOCK" },
          { quantity: remainingQuantity.toNumber() },
        );
        td.appendChild(remainingQuantityEl);

        const formattedRemainingQuantity = intl.formatNumber(
          remainingQuantity.toNumber(),
          {
            maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
            minimumFractionDigits: MIN_QUANTITY_DECIMALS,
          },
        );

        const totalQuantity = matchingRows.reduce((acc, row) => {
          return acc.plus(
            row[getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)],
          );
        }, new DecimalSafe(0));

        const moreThanRemainingQuantity =
          totalQuantity.greaterThan(remainingQuantity);
        if (moreThanRemainingQuantity && !isRestockRelease) {
          applyClasses(td, "bg-red-200");
          applyTooltip(
            remainingQuantityEl,
            intl.$t(
              { id: "QUANTITY_EXCEEDS_STOCK_WITH_VALUE" },
              {
                quantity: formattedRemainingQuantity,
                br: "\n",
              },
            ),
          );
          if (hasSupplier) {
            addIconWithTooltip({
              element: td,
              icon: "fa-arrows-split-up-and-left",
              tooltipText: intl.$t(
                { id: "ORDER_MISSING_QUANTITY_FROM_VENDOR" },
                {
                  quantity: formattedRemainingQuantity,
                  br: "\n",
                },
              ),
              iconClasses: "text-red-500 scale-y-[-1] cursor-pointer",
              onClick: () =>
                splitQuantity(
                  sourceRow as Record<string, string>,
                  rowData,
                  instance,
                  row,
                  remainingQuantity
                    .minus(totalQuantity.minus(value))
                    .toNumber(),
                ),
            });
          }
        }
      }
    },
    [
      currentWarehouseId,
      hasSupplier,
      intl,
      isRestockRelease,
      addIconWithTooltip,
      splitQuantity,
      warehouses,
    ],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const formattedValue = intl.formatNumber(Number(value), {
        maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
        minimumFractionDigits: MIN_QUANTITY_DECIMALS,
      });
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      const rowData = instance?.getDataAtRow(row);
      const sourceRow = instance?.getSourceDataAtRow(
        getPhysicalRowIndex(instance, row),
      );

      const { bomItem, inventoryItem, rowType } = getItemTypes(
        instance,
        rowData,
      );

      switch (rowType) {
        case OrderItemRowType.BOMItem:
          if (bomItem) {
            renderBomItemQuantity({
              bomItem,
              value,
              td,
              matchingRows: getMatchingBomItemRows(instance, rowData),
              instance,
            });
          }
          break;
        case OrderItemRowType.InventoryItem:
          if (inventoryItem) {
            renderInventoryItemQuantity({
              inventoryItem,
              td,
              value,
              matchingRows: getMatchingInventoryItemRows(instance, rowData),
              rowData,
              sourceRow: sourceRow as Record<string, string>,
              instance,
              row,
            });
          }
          break;
      }
    },
    [
      intl,
      baseRenderer,
      getItemTypes,
      renderBomItemQuantity,
      renderInventoryItemQuantity,
      getMatchingBomItemRows,
      getMatchingInventoryItemRows,
    ],
  );

  return renderer;
};
