import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useMaterialRenderer } from "@/common/components/spreadsheet-table/renderers/material-renderer/useMaterialRenderer";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";
import { removeTooltip } from "../utils/removeTooltip";
import { useItemRenderer } from "./useItemRenderer";

export const useInventoryItemRenderer = () => {
  const intl = useIntl();
  const materialRenderer = useMaterialRenderer();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();

  const { setItemRenderer } = useItemRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      materialRenderer(instance, td, row, col, prop, value, cellProperties);

      if (isValueEmpty(value)) {
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const uom = rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      const inventoryItem = findInventoryItemByNameAndUom(value, uom);

      if (!inventoryItem) {
        applyTooltip(td, intl.$t({ id: "NOT_FOUND_IN_INVENTORY" }));
        return;
      }

      if (inventoryItem.state.length === 0) {
        applyTooltip(
          td,
          intl.$t(
            { id: "OUT_OF_STOCK_FOR_SELECTED_WAREHOUSE" },
            { material: value },
          ),
        );
      } else {
        removeTooltip(td);
      }

      if (inventoryItem) {
        const remainingQuantity =
          getInventoryItemRemainingQuantity(inventoryItem);
        setItemRenderer(td, {
          item: inventoryItem,
          textGetter: () =>
            `${remainingQuantity} ${inventoryItem.uom.pluralDescription}`,
          icons: [
            {
              icon: "fa-circle",
              tooltip: intl.$t({ id: "INVENTORY_ITEM" }),
              iconClasses:
                remainingQuantity.toNumber() > 0
                  ? "text-blue-800"
                  : "text-blue-200",
            },
          ],
        });
      }
    },
    [materialRenderer, intl, findInventoryItemByNameAndUom, setItemRenderer],
  );

  return renderer;
};
