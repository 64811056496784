import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import { useRfqGroupedItems } from "../hooks/useRfqGroupedItems";
import { useRfqItemsListConfiguration } from "./RfqItemsList.configuration";
import { RfqItemsSideActions } from "./bulk-actions/RfqItemsSideActions";

type Props = PropsWithChildren<{
  readonly?: boolean;
  loading?: boolean;
}>;

export const RfqItemsList: FC<Props> = ({ loading, readonly = false }) => {
  const { rfqGroupedItems, toggleCostCode } = useRfqGroupedItems();
  const { isRfqWithErrors, expandedItems } = useRfqGroupedItems();
  const { configuration } = useRfqItemsListConfiguration(readonly);

  if (!rfqGroupedItems) {
    return null;
  }

  return (
    <ListRenderer>
      <GridTable<RfqItemFieldsFragment>
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-[48px]",
            category: "top-[36px] lg:top-[86px]",
            subCategory: "top-[36px] lg:top-[86px]",
          },
          toggle: {
            category: toggleCostCode,
          },
        }}
        expandedItems={(item) => getExpandedItems(item, expandedItems)}
        items={rfqGroupedItems}
        readonly={readonly}
        loading={loading}
        error={isRfqWithErrors}
      />
      <RfqItemsSideActions />
    </ListRenderer>
  );
};
