import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  DECIMAL_MAX_FRACTION_DIGITS,
  LUMP_SUM_UOM,
  MIN_QUANTITY_DECIMALS,
} from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { FC } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getEstimatedItem } from "../../../project/utils/getEstimatedItem";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";

const FloatingText = tw.span`absolute w-96 text-center text-xs text-gray-600`;
const Container = tw.span`grid grid-flow-col gap-2 items-center mr-2`;
const ErrorIcon = tw(
  BsExclamationCircle,
)`mr-1.5 left-5 xl:absolute top-1/4 items-center text-lg fill-orange-500`;

type Props = {
  item: ExpandedReleaseItem;
};

export const ReleaseItemRemainingQty: FC<Props> = ({ item }) => {
  const intl = useIntl();
  const estimatedItem = getEstimatedItem({ item });
  const remainingQuantity = estimatedItem
    ? new DecimalSafe(estimatedItem?.quantityDecimal ?? 0).minus(
        estimatedItem?.orderedQuantity ?? 0,
      )
    : new DecimalSafe(0);

  if (isLumpSumItem(item)) {
    return <FloatingText>{LUMP_SUM_UOM}</FloatingText>;
  }

  if (!item.isIncluded) {
    return (
      <FormattedMessage id="NOT_INCLUDED_BY_VENDOR" tagName={FloatingText} />
    );
  }

  return (
    <Container>
      <If isTrue={remainingQuantity.lt(0)}>
        <Tooltip id="received-quantity-tooltip" element={<ErrorIcon />}>
          <FormattedMessage
            id={"OVER_BOM_QUANTITY_TOOLTIP"}
            values={{
              quantity: intl.formatNumber(remainingQuantity.abs().toNumber(), {
                maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
                minimumFractionDigits: MIN_QUANTITY_DECIMALS,
              }),
              br: "\n",
            }}
          />
        </Tooltip>
      </If>
      <ValueUnit
        value={item.quantityDecimal}
        uom={item.uom || item.projectItem?.estimateUom}
        size="xs"
      />
    </Container>
  );
};
