import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import Handsontable from "handsontable";
import { CellValue } from "handsontable/common";
import { useCallback } from "react";

export enum OrderItemRowType {
  BuyoutItem = "buyoutItem",
  BOMItem = "bomItem",
  InventoryItem = "inventoryItem",
}

export const useOrderItemsRendererHelpers = () => {
  const { getBuyoutItem, getBOMItem, getInventoryItem } = useTableHelpers();

  const getItemTypes = useCallback(
    (instance: Handsontable, rowData: CellValue[]) => {
      let rowType = undefined;

      const bomItem = getBOMItem({
        id: rowData[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.EstimatedItemId)
        ],
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        vendor: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
      });
      if (bomItem) {
        rowType = OrderItemRowType.BOMItem;
      }

      const buyoutItem = getBuyoutItem({
        id: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.BuyoutItemId)],
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });
      if (buyoutItem) {
        rowType = OrderItemRowType.BuyoutItem;
      }

      const inventoryItem = getInventoryItem({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        supplier:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
      });
      if (inventoryItem) {
        rowType = OrderItemRowType.InventoryItem;
      }

      return {
        buyoutItem,
        bomItem,
        inventoryItem,
        rowType,
      };
    },
    [getBuyoutItem, getBOMItem, getInventoryItem],
  );

  const areValuesEqual = useCallback(
    (
      value1: string | null | undefined,
      value2: string | null | undefined,
    ): boolean =>
      value1 === value2 ||
      ((!value1 || value1 === "") && (!value2 || value2 === "")),
    [],
  );

  const getMatchingBomItemRows = useCallback(
    (instance: Handsontable, rowData: CellValue[]) => {
      const materialIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Material,
      );
      const uomIndex = getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM);
      const costCodeIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.CostCode,
      );
      const zoneIndex = getPhysicalColumnIndex(instance, COLUMN_TYPE.Zone);

      const matchingRows = instance
        ?.getData()
        .filter(
          (row) =>
            areValuesEqual(row[materialIndex], rowData[materialIndex]) &&
            areValuesEqual(row[uomIndex], rowData[uomIndex]) &&
            areValuesEqual(row[costCodeIndex], rowData[costCodeIndex]) &&
            areValuesEqual(row[zoneIndex], rowData[zoneIndex]),
        );

      return matchingRows;
    },
    [areValuesEqual],
  );

  const getMatchingInventoryItemRows = useCallback(
    (instance: Handsontable, rowData: CellValue[]) => {
      const materialIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Material,
      );
      const uomIndex = getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM);
      const supplierIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Supplier,
      );

      return instance
        ?.getData()
        .filter(
          (row) =>
            areValuesEqual(row[materialIndex], rowData[materialIndex]) &&
            areValuesEqual(row[uomIndex], rowData[uomIndex]) &&
            areValuesEqual(row[supplierIndex], rowData[supplierIndex]),
        );
    },
    [areValuesEqual],
  );

  return { getItemTypes, getMatchingBomItemRows, getMatchingInventoryItemRows };
};
