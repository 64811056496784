import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";

import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrderItemQuantityRenderer } from "@/contractor/pages/home/release/pages/specify-details/hooks/release-items-renderers/useOrderItemQuantityRenderer";
import {
  ReleaseFieldsFragment,
  ReleaseStatus,
  TransactionKind,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useIsRestockRelease } from "../../hooks/useIsRestockRelease";
import { useOrderItemPriceRenderer } from "./hooks/release-items-renderers/useOrderItemPriceRenderer";
import { useOrderItemRenderer } from "./hooks/release-items-renderers/useOrderItemRenderer";
import { useReleaseItemsDecorator } from "./hooks/useReleaseItemsDecorator";
import { useReleaseTableConfigHelpers } from "./hooks/useReleaseTableConfigHelpers";

const SubmittedStatuses = [
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
];

export const useSpecifyDetailsConfiguration = (
  release?: ReleaseFieldsFragment | null,
) => {
  const { extraOptions, extraColumns } = useReleaseItemsDecorator();
  const orderItemRenderer = useOrderItemRenderer();
  const orderQuantityRenderer = useOrderItemQuantityRenderer();
  const orderItemPriceRenderer = useOrderItemPriceRenderer();
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { isRestockRelease } = useIsRestockRelease();
  const { vendorOptions, supplierOptions } = useColumnMapper();
  const { uomIsDisabled, isPriceDisabled } = useReleaseTableConfigHelpers();

  const limitSupplierToVendors = useMemo(() => {
    return isRestockRelease || release?.warehouse;
  }, [isRestockRelease, release?.warehouse]);

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const itemIsPartOfPoOrAssignedToInvoice = useCallback(
    (row: Record<string, string>) => {
      const assignedToInvoiceItem = release?.items.find(
        (item) =>
          item.id === row?.id &&
          !!item.poItemLink &&
          !!release?.poLink?.retroactive,
      );
      if (assignedToInvoiceItem) {
        return assignedToInvoiceItem.projectItem?.material
          ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_PO" })
          : "";
      }

      return "";
    },
    [intl, release?.items, release?.poLink?.retroactive],
  );

  const itemIsPartOfBuyout = useCallback(
    (row: Record<string, string>) => {
      return release?.items.find(
        (item) => item.id === row?.id && !!item.buyoutItem,
      )
        ? intl.$t({ id: "DISABLED_RELEASE_ITEM_AS_IT_IS_PART_OF_BUYOUT" })
        : "";
    },
    [intl, release?.items],
  );

  const getMaterialColumnId = useCallback(
    (id: string) => {
      return isLumpSumItem(release?.items.find((itm) => itm.id === id))
        ? "name"
        : "projectItem.material";
    },
    [release?.items],
  );

  const vendorColumnHidden = useMemo(() => {
    if (
      release?.status === ReleaseStatus.Requested &&
      release?.sourceWarehouse
    ) {
      return false;
    } else {
      return SubmittedStatuses.includes(release?.status as ReleaseStatus);
    }
  }, [release?.status, release?.sourceWarehouse]);

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: getMaterialColumnId,
        columnType: COLUMN_TYPE.Material,
        extraOptions,
        renderer: orderItemRenderer,
        readOnlyFn: itemIsPartOfPoOrAssignedToInvoice,
      },
      {
        header: intl.$t({ id: "SUPPLIER" }),
        columnId: "vendorName",
        columnType: COLUMN_TYPE.Supplier,
        options: limitSupplierToVendors ? vendorOptions : supplierOptions,
        hidden: vendorColumnHidden,
        readOnlyFn: itemIsPartOfBuyout,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
        readOnlyFn: uomIsDisabled,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
        renderer: orderQuantityRenderer,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
        validator: requiredValidator,
        readOnlyFn: isPriceDisabled,
        renderer: orderItemPriceRenderer,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "COST_TYPE" }),
        columnId: "costType.code",
        columnType: COLUMN_TYPE.CostType,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
      ...(hasEndDate
        ? [
            {
              header: intl.$t({ id: "END_DATE" }),
              columnId: "deliveryDate",
              columnType: COLUMN_TYPE.EndDate,
            },
          ]
        : [
            {
              header: intl.$t({ id: "DELIVERY_DATE" }),
              columnId: "deliveryDate",
              columnType: COLUMN_TYPE.DeliveryDate,
            },
          ]),
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        columnType: COLUMN_TYPE.Notes,
      },
      {
        header: intl.$t({ id: "TAX" }),
        columnId: "taxable",
        columnType: COLUMN_TYPE.Taxable,
      },
      ...extraColumns,
    ],
    [
      intl,
      getMaterialColumnId,
      extraOptions,
      orderItemRenderer,
      itemIsPartOfPoOrAssignedToInvoice,
      vendorColumnHidden,
      itemIsPartOfBuyout,
      uomIsDisabled,
      orderQuantityRenderer,
      requiredValidator,
      isPriceDisabled,
      hasEndDate,
      extraColumns,
      vendorOptions,
      supplierOptions,
      limitSupplierToVendors,
      orderItemPriceRenderer,
    ],
  );

  return spreadsheetConfig;
};
