import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { If } from "@/common/components/if/If";
import { FC, useCallback, useState } from "react";
import { AddEstimatedItemsDialog } from "../../pages/add-item-to-rfq/AddEstimatedItemsDialog";
import { RfqProjectItemsProvider } from "../import-project-items/RfqProjectItemsProvider";

type RfqProductMenuProps = {
  isOpen?: boolean;
};

export const RfqProductMenu: FC<RfqProductMenuProps> = ({ isOpen = false }) => {
  const [showEstimatedItemsDialog, setShowEstimatedItemsDialog] =
    useState(isOpen);

  const gotoItemSelection = useCallback(() => {
    setShowEstimatedItemsDialog(true);
  }, []);

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      <If isTrue={showEstimatedItemsDialog}>
        <RfqProjectItemsProvider>
          <AddEstimatedItemsDialog
            setShowEstimatedItemsDialog={setShowEstimatedItemsDialog}
          />
        </RfqProjectItemsProvider>
      </If>
    </>
  );
};
