import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { RendererFunctionType } from "@/common/components/spreadsheet-table/renderers/base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "@/common/components/spreadsheet-table/renderers/base-renderer/useBaseRenderer";
import { useRenderHelpers } from "@/common/components/spreadsheet-table/renderers/helpers/useRenderHelpers";
import { applyClasses } from "@/common/components/spreadsheet-table/renderers/utils/applyClasses";
import { isValueEmpty } from "@/common/components/spreadsheet-table/renderers/utils/isValueEmpty";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import {
  OrderItemRowType,
  useOrderItemsRendererHelpers,
} from "./useOrderItemRendererHelpers";

export const useOrderItemPriceRenderer = () => {
  const intl = useIntl();
  const { formatPrice, addIconWithTooltip } = useRenderHelpers();
  const { getPrefilledValue } = useTableHelpers();
  const baseRenderer = useBaseRenderer();
  const { getItemTypes } = useOrderItemsRendererHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value) || isNaN(Number(value))) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const formattedValue = formatPrice(rowData, instance, value);
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      const { buyoutItem, bomItem, rowType } = getItemTypes(instance, rowData);

      const price = getPrefilledValue({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        vendor: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
        manufacturer:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });

      switch (rowType) {
        case OrderItemRowType.BuyoutItem:
          if (buyoutItem) {
            applyClasses(td, "pr-4");
            if (new DecimalSafe(buyoutItem.unitPrice).equals(value)) {
              addIconWithTooltip({
                element: td,
                tooltipText: intl.$t({
                  id: "BUYOUT_ITEM_PRICE_NOTICE",
                }),
                iconClasses: "text-green-800",
              });
            }
          }
          break;
        case OrderItemRowType.BOMItem:
          if (bomItem) {
            applyClasses(td, "pr-4");
            if (
              price.isEstimatedPrice &&
              new DecimalSafe(price.value).equals(value)
            ) {
              addIconWithTooltip({
                element: td,
                tooltipText: intl.$t({
                  id: "BOM_ITEM_PRICE_NOTICE",
                }),
              });
            }
          }
          break;
        case OrderItemRowType.InventoryItem:
          break;
        default:
          if (
            price.isVendorPrice &&
            new DecimalSafe(price.value).equals(value)
          ) {
            addIconWithTooltip({
              element: td,
              tooltipText: intl.$t({
                id: "VENDOR_PRICE_NOTICE",
              }),
            });
          }
          break;
      }
    },
    [
      baseRenderer,
      addIconWithTooltip,
      formatPrice,
      getPrefilledValue,
      intl,
      getItemTypes,
    ],
  );

  return renderer;
};
